export const ANNUAL_SCORE_OPTION = 15;
export const months = [
  {
    value: 11,
    label: 'nov'
  },
  {
    value: 12,
    label: 'dec'
  },
  {
    value: 1,
    label: 'jan'
  },
  {
    value: 2,
    label: 'feb'
  },
  {
    value: 3,
    label: 'mar'
  },
  {
    value: 4,
    label: 'apr'
  },
  {
    value: 5,
    label: 'may'
  },
  {
    value: 6,
    label: 'jun'
  },
  {
    value: 7,
    label: 'jul'
  },
  {
    value: 8,
    label: 'aug'
  },
  {
    value: 9,
    label: 'sep'
  },
  {
    value: 10,
    label: 'oct'
  }
];
export const PRE_SCHOOL_SCORES = {
  0: 'low',
  5: 'intermediate',
  10: 'good'
};
