import React, { useState, useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import ImageCropperStyle from 'assets/sts/jss/components/imageCropperStyle';
import { makeStyles } from '@material-ui/core';

import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from 'utilities/canvasUtils';
import PropTypes from 'prop-types';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
};

const useStyles = makeStyles(ImageCropperStyle);

export default function ImageCropper (props) {
  const {
    file,
    setCroppedImage,
    onBeforeClose
  } = props;
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const translate = getTranslate(localize);

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onConfirm = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setImageSrc(null);
      setIsOpenDialog(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation, setCroppedImage]);

  const onClose = useCallback(() => {
    setImageSrc(null);
    setIsOpenDialog(false);
    if (onBeforeClose) {
      onBeforeClose();
    }
  }, [onBeforeClose]);

  const processFile = useCallback(async () => {
    if (file) {
      setIsOpenDialog(true);
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  }, [file]);

  useEffect(() => {
    processFile();
  }, [processFile]);

  return (
    <LoadablePaper
      rendered
    >
      <LoadablePaperContent>
        <Dialog
          open={isOpenDialog}
          className={classes.dialogHelp}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
        >
          <DialogTitle>
            <div className={classes.secondGrid}>
              <h6 className={classes.confirmDialogHeader}>
                {translate('imageCropper.uploadImage')}
              </h6>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div>
              {imageSrc && (
                <>
                  <div className={classes.cropContainer}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={4 / 6}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div className={classes.controls}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      { translate('imageCropper.zoom') }
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      { translate('imageCropper.rotation') }
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </div>
                </>
              )}
            </div>
          </DialogContent>

          <DialogActions
            className={classes.dialogAction}
          >
            <div className={classes.normalSaveClose}>
              <ThemeButton
                variant="contained"
                className={classes.buttonDefault}
                onClick={onClose}
              >
                {translate('common.button.close')}
              </ThemeButton>
              <ThemeButton
                color="primary"
                variant="contained"
                onClick={onConfirm}
              >
                {translate('common.button.confirm')}
              </ThemeButton>
            </div>
          </DialogActions>
        </Dialog>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

function readFile (file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

ImageCropper.propTypes = {
  file: PropTypes.object,
  setCroppedImage: PropTypes.func,
  onBeforeClose: PropTypes.func
};
