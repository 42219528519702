import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification, showSuccessNotification
} from 'redux/actions/notification';

function getStudentListRequested () {
  return {
    type: actionTypes.GET_STUDENT_LIST_REQUEST
  };
}

function getStudentListSuccess (studentList, studentFilter) {
  return {
    type: actionTypes.GET_STUDENT_LIST_SUCCESS,
    studentList,
    studentFilter
  };
}

function getStudentListFail () {
  return {
    type: actionTypes.GET_STUDENT_LIST_FAIL
  };
}

const getStudentDetailRequested = () => ({
  type: actionTypes.GET_STUDENT_DETAIL_REQUEST
});

const getStudentDetailSuccess = student => ({
  type: actionTypes.GET_STUDENT_DETAIL_SUCCESS,
  student
});

const getStudentDetailFail = () => ({
  type: actionTypes.GET_STUDENT_DETAIL_FAIL
});

const editStudentRequested = () => ({
  type: actionTypes.EDIT_STUDENT_REQUEST
});

const editStudentSuccess = student => ({
  type: actionTypes.EDIT_STUDENT_SUCCESS,
  student
});

const editStudentFail = () => ({
  type: actionTypes.EDIT_STUDENT_FAIL
});

const changeClassroomRequest = () => {
  return {
    type: actionTypes.CHANGE_CLASSROOM_REQUEST
  };
};

const changeClassroomSuccess = () => {
  return {
    type: actionTypes.CHANGE_CLASSROOM_SUCCESS
  };
};

const changeClassroomFail = () => {
  return {
    type: actionTypes.CHANGE_CLASSROOM_FAIL
  };
};

const dropOutRequest = () => {
  return {
    type: actionTypes.STUDENT_DROP_OUT_REQUEST
  };
};

const dropOutSuccess = () => {
  return {
    type: actionTypes.STUDENT_DROP_OUT_SUCCESS
  };
};

const dropOutFail = () => {
  return {
    type: actionTypes.STUDENT_DROP_OUT_FAIL
  };
};

const autoFillFilterByGazetteerRequest = () => {
  return {
    type: actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_REQUEST
  };
};

const autoFillFilterByGazetteerSuccess = (gazetteers) => {
  return {
    type: actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_SUCCESS,
    gazetteers
  };
};

const autoFillFilterByGazetteerFail = () => {
  return {
    type: actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_FAIL
  };
};

const deleteStudentRequest = () => {
  return {
    type: actionTypes.DELETE_STUDENT_REQUEST
  };
};

const deleteStudentSuccess = () => {
  return {
    type: actionTypes.DELETE_STUDENT_SUCCESS
  };
};

const deleteStudentFail = () => {
  return {
    type: actionTypes.DELETE_STUDENT_FAIL
  };
};

export const getStudentList = (rawParams, gazetteerId) => {
  return (dispatch) => {
    const params = {
      academic_year_id: rawParams.academicYear,
      school: rawParams.school,
      page: rawParams.page,
      limit: rawParams.limit,
      keyword: rawParams.keyword
    };

    params.suffix = rawParams.suffix && rawParams.suffix !== 'all' ? rawParams.suffix : undefined;
    params.grade = rawParams.grade !== '' && rawParams.grade !== 'all' ? rawParams.grade : undefined;
    params.duplicate_record = rawParams.isDuplicateRecord || undefined;

    dispatch(getStudentListRequested());
    axios.get('/students', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          dispatch(getStudentListSuccess(data, rawParams));
          if (gazetteerId) {
            dispatch(autoFillFilterByGazetteer(gazetteerId));
          }
        }
      )
      .catch(e => {
        dispatch(getStudentListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getStudentDetailAsync = (id) => {
  return (dispatch) => {
    dispatch(getStudentDetailRequested());
    axios.get(`/students/${id}`)
      .then(
        res => {
          const student = res.data.data;
          dispatch(getStudentDetailSuccess(student));
        }
      )
      .catch(e => {
        dispatch(getStudentDetailFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const editStudent = (id, data, successfulMessage) => {
  return (dispatch) => {
    dispatch(editStudentRequested());
    axios.put(`/students/${id}`, data)
      .then(
        res => {
          if (res.data.error) {
            dispatch(showErrorNotification(res.data.message));
            dispatch(editStudentSuccess());
          } else {
            const student = res.data.data;
            dispatch(editStudentSuccess(student));
            dispatch(showSuccessNotification(successfulMessage));
            dispatch(getStudentDetailAsync(id));
          }
        }
      )
      .catch(e => {
        dispatch(editStudentFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const changeClassroom = (data, successfulMessage) => {
  return (dispatch, getState) => {
    dispatch(changeClassroomRequest());
    axios.post('/enrollments/assign-classroom', data)
      .then(
        res => {
          dispatch(changeClassroomSuccess());
          dispatch(showSuccessNotification(successfulMessage));

          const studentFilter = getState().student.studentFilter;
          dispatch(getStudentList(studentFilter));
        }
      )
      .catch(e => {
        dispatch(changeClassroomFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const dropOut = (id, data, successfulMessage) => {
  return (dispatch, getState) => {
    dispatch(dropOutRequest());
    axios.post(`/students/drop-out/${id}`, data)
      .then(
        res => {
          dispatch(dropOutSuccess());
          dispatch(showSuccessNotification(successfulMessage));

          const studentFilter = getState().student.studentFilter;
          dispatch(getStudentList(studentFilter));
        }
      )
      .catch(e => {
        dispatch(dropOutFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const autoFillFilterByGazetteer = (id) => {
  return (dispatch) => {
    dispatch(autoFillFilterByGazetteerRequest());
    const params = { id };
    axios.get('/gazetteer/get-parents', { params })
      .then(
        res => {
          dispatch(autoFillFilterByGazetteerSuccess(res.data));
        }
      )
      .catch(e => {
        dispatch(autoFillFilterByGazetteerFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const deleteStudent = (id) => {
  return (dispatch, getState) => {
    dispatch(deleteStudentRequest());
    axios.delete(`/students/${id}`)
      .then(
        res => {
          dispatch(deleteStudentSuccess());
          dispatch(showSuccessNotification('student.deleteSuccess'));

          const studentFilter = getState().student.studentFilter;
          dispatch(getStudentList(studentFilter));
        }
      )
      .catch(e => {
        dispatch(deleteStudentFail());
        dispatch(showErrorNotification('student.deleteFailed'));
      });
  };
};
