import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import TransferIcon from '@material-ui/icons/SwapHorizontalCircle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import Select from 'components/common/Select/Select';
import * as moment from 'moment';
import settings from 'settings';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import { getSchoolName } from 'utilities/helpers';
import * as VARIABLES from 'variables/transfer';
import {
  clearUnits,
  getClassroomListForFilter,
  getDistricts,
  getProvinces
} from 'redux/actions';
import { getGazetteerChoices } from 'utilities/gazetteer';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';

const useStyles = makeStyles(styles);
const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const TransferDialog = props => {
  const classes = useStyles();
  const {
    academicYear,
    school,
    stsId,
    confirmStsId,
    transferStudent,
    reason,
    transferAcademicYear,
    transferSchool,
    transferGrade,
    isOpenDialog,
    dialogTitle,
    errorStsId,
    errorConfirmStsId,
    errorReason,
    errorTransferAcademicYear,
    errorTransferSchool,
    errorTransferGrade,
    onChangeHandler,
    onHandleConfirm,
    onHandleSave,
    onHandleClose,
    province,
    district,
    errorProvince,
    errorDistrict,
    setTransferSchool,
    setTransferSchoolCode,
    selectedTransferSchool,
    setSelectedTransferSchool,
    isShowGrade,
    setIsShowGrade,
    setTransferGrade
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.transfer.transferToSchoolList);
  const allSchool = useSelector(state => state.school.schoolList);
  const allGrades = useSelector(state => state.studentOption.grade);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [transferSchoolDegree, setTransferSchoolDegree] = useState('');

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears.map(academic => {
        const academicStartDate = moment(academic.start_date, settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        return {
          value: academic.id,
          label: studyYear
        };
      });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch provinces
  useEffect(() => {
    dispatch(getProvinces());
    dispatch(clearUnits());
  }, [dispatch]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
  }, [provinces, localize]);

  // fetch districts after province is selected
  useEffect(() => {
    dispatch(getDistricts(province));
    dispatch(clearUnits('district'));
  }, [dispatch, province]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
  }, [districts, localize]);

  // set school choices
  useEffect(() => {
    if (transferAcademicYear && schoolList.length > 0) {
      const currentSchool = allSchool.find(c => c.id === school);
      let matchDegrees = [];

      switch (currentSchool.degree.number) {
        case SCHOOL_DEGREE.UPPER_SECONDARY_G7_12 || SCHOOL_DEGREE.LOWER_SECONDARY:
          matchDegrees = [SCHOOL_DEGREE.LOWER_SECONDARY, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
          break;
        case SCHOOL_DEGREE.UPPER_SECONDARY_G10_12:
          matchDegrees = [SCHOOL_DEGREE.UPPER_SECONDARY_G10_12, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
          break;
        case SCHOOL_DEGREE.PRE:
          matchDegrees = [SCHOOL_DEGREE.PRE];
          break;
        case SCHOOL_DEGREE.PRIMARY:
          matchDegrees = [SCHOOL_DEGREE.PRIMARY];
          break;
        default:
          matchDegrees = [SCHOOL_DEGREE.PRE, SCHOOL_DEGREE.PRIMARY, SCHOOL_DEGREE.LOWER_SECONDARY, SCHOOL_DEGREE.UPPER_SECONDARY_G10_12, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
      }
      let choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school),
          code: school.emis_code,
          degree: school.degree.number
        };
      });
      choice = choice.filter(schoolChoice => schoolChoice.value !== school && matchDegrees.includes(schoolChoice.degree));
      Object.entries(VARIABLES.unknownSchools).forEach(school => {
        choice.push({
          value: school[1],
          label: translate(`transfer.${school[0]}`)
        });
      });
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
    }
  }, [transferAcademicYear, schoolList, localize, translate, school, allSchool]);

  useEffect(() => {
    if (transferSchool) {
      const param = {
        school: transferSchool
      };

      dispatch(getClassroomListForFilter(param));
    }
  }, [dispatch, transferSchool]);

  // set grade choices
  useEffect(() => {
    if (transferSchool !== VARIABLES.unknownSchools.PRIVATE_SCHOOL && transferSchool !== VARIABLES.unknownSchools.NONE_STS_SCHOOL) {
      if (transferSchool && allGrades.length > 0) {
        const choices = [];
        const classrooms = classroomList.filter(classroom => classroom.grade !== null);
        classrooms.forEach(classroom => {
          if (choices.filter(c => c.value === classroom.grade).length === 0) {
            const grade = allGrades.find(g => g.number === classroom.grade);
            choices.push({
              value: classroom.grade,
              // eslint-disable-next-line
              label: getActiveLanguage(localize).code === 'en' ? grade?.name_en : grade?.name
            });
          }
        });
        setGradeChoices(choices);
      }
    }
  }, [transferSchool, schoolList, allGrades, localize, classroomList]);

  useEffect(() => {
    if (transferSchoolDegree !== SCHOOL_DEGREE.PRE) {
      const grade = gradeChoices.find((grade) => grade.value === transferStudent.classroom.grade);
      if (grade) {
        setTransferGrade(grade.value);
      } else {
        setTransferGrade('');
      }
    } else {
      setTransferGrade('');
    }
    // eslint-disable-next-line
  }, [gradeChoices, transferStudent]);

  const renderAcademicYearLabel = () => {
    const selectedAcademic = academicYears.find(a => a.id === academicYear);
    if (selectedAcademic) {
      const academicStartDate = moment(selectedAcademic.start_date, settings.dateFormat);
      const academicEndDate = moment(selectedAcademic.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${selectedAcademic.status}`).toUpperCase();
      return (selectedAcademic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
    }
    return '';
  };

  const renderSchoolLabel = () => {
    const selectedSchool = allSchool.find(c => c.id === school);
    if (selectedSchool) {
      return getSchoolName(getActiveLanguage(localize).code, translate, selectedSchool);
    }
    return '';
  };

  const renderClassroomLabel = () => {
    const { classroom } = transferStudent;
    const selectedGrade = allGrades.find(g => g.number === classroom.grade);
    if (selectedGrade !== undefined) {
      const gradeLabel = getActiveLanguage(localize).code === 'en' ? selectedGrade.name_en : selectedGrade.name;

      if (classroom.suffix) {
        return `${gradeLabel} (${classroom.suffix})`;
      } else {
        return gradeLabel;
      }
    }
    return '';
  };

  const onSchoolChangeHandler = (event, school) => {
    if (school) {
      const isShowGradeTmp = school.value !== VARIABLES.unknownSchools.PRIVATE_SCHOOL && school.value !== VARIABLES.unknownSchools.NONE_STS_SCHOOL;
      setIsShowGrade(isShowGradeTmp);
      setSelectedTransferSchool(school);
      setTransferSchool(school.value);
      setTransferSchoolCode(school.code);
      setTransferSchoolDegree(school.degree);
    } else {
      setSelectedTransferSchool('');
      setTransferSchool('');
      setTransferSchoolCode('');
      setTransferSchoolDegree('');
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <TransferIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {dialogTitle}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!transferStudent ? (
          <GridContainer>
            <GridItem
              xs={12}
              md={6}
            >
              <Input
                require
                label={translate('common.stsId')}
                placeholder={translate('transfer.placeholder.stsId')}
                value={stsId}
                name="stsId"
                onChange={e => onChangeHandler(e, 'stsId')}
                error={errorStsId}
                helperText={errorStsId ? translate('common.error.require') : ''}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Input
                require
                label={translate('transfer.confirmStsId')}
                placeholder={translate('transfer.placeholder.stsId')}
                value={confirmStsId}
                name="confirmStsId"
                onChange={e => onChangeHandler(e, 'confirmStsId')}
                error={errorConfirmStsId}
                helperText={errorConfirmStsId
                  ? confirmStsId ? translate('transfer.invalidConfirmStsId') : translate('common.error.require')
                  : ''
                }
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem
              xs={12}
            >
              <h4>{translate('transfer.from')}</h4>
              <GridItem
                xs={12}
                className={
                  classes.boxGrid
                }
              >
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={7}
                  >
                    <p>
                      <span className={classes.summaryLabel}>{translate('enrollment.academicYear')}</span>
                      <strong>{renderAcademicYearLabel()}</strong>
                    </p>
                    <p>
                      <span className={classes.summaryLabel}>{translate('graduation.school')}</span>
                      <strong>{renderSchoolLabel()}</strong>
                    </p>
                    <p>
                      <span className={classes.summaryLabel}>{translate('classroom.classroom')}</span>
                      <strong>{renderClassroomLabel()}</strong>
                    </p>
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={5}
                  >
                    <p>
                      <span className={classes.summaryLabel}>{translate('common.stsId')}</span>
                      <strong>{stsId}</strong>
                    </p>
                    <p>
                      <span className={classes.summaryLabel}>{translate('common.name')}</span>
                      <strong>{transferStudent.student.last_name} {transferStudent.student.first_name}</strong>
                    </p>
                    <p>
                      <span className={classes.summaryLabel}>{translate('common.gender')}</span>
                      <strong>
                        {transferStudent.student.gender === 'm' ? translate('common.genderType.m') : transferStudent.student.gender === 'f' ? translate('common.genderType.f') : ''}
                      </strong>
                    </p>
                    <p>
                      <span className={classes.summaryLabel}>{translate('enrollment.birthDate')}</span>
                      <strong>{transferStudent.student.birth_date}</strong>
                    </p>
                  </GridItem>

                  <GridItem
                    xs={12}
                  >
                    <Input
                      require
                      label={translate('transfer.reason')}
                      placeholder={translate('transfer.placeholder.reason')}
                      value={reason}
                      name="reason"
                      onChange={e => onChangeHandler(e, 'reason')}
                      error={errorReason}
                      helperText={errorReason ? translate('common.error.require') : ''}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <h4>{translate('transfer.to')}</h4>
              <GridItem
                xs={12}
                className={
                  classes.boxGrid
                }
              >
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('enrollment.academicYear')}
                      placeholder={translate('enrollment.placeholder.academicYear')}
                      value={transferAcademicYear}
                      name="transferAcademicYear"
                      onChange={e => onChangeHandler(e, 'transferAcademicYear')}
                      choices={academicYearChoices}
                      disabled
                      require
                      error={errorTransferAcademicYear}
                      helperText={errorTransferAcademicYear ? translate('common.error.require') : ''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.province')}
                      placeholder={translate('gazetteer.placeholder.province')}
                      value={province}
                      name="province"
                      onChange={e => onChangeHandler(e, 'province')}
                      choices={provinceChoices}
                      disabled={provinceChoices.length === 0}
                      require
                      error={errorProvince}
                      helperText={errorProvince ? translate('common.error.require') : ''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.district')}
                      placeholder={translate('gazetteer.placeholder.district')}
                      value={district}
                      name="district"
                      onChange={e => onChangeHandler(e, 'district')}
                      choices={districtChoices}
                      disabled={!province}
                      require
                      error={errorDistrict}
                      helperText={errorDistrict ? translate('common.error.require') : ''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Autocomplete
                      value={selectedTransferSchool}
                      name="selectedPromoteSchool"
                      require
                      label={translate('graduation.school')}
                      placeholder={translate('graduation.placeholder.school')}
                      options={schoolChoices}
                      filterOptions={schoolFilterOptions}
                      onChange={onSchoolChangeHandler}
                      error={errorTransferSchool}
                      helperText={errorTransferSchool ? translate('common.error.require') : ''}
                      noOptionsText={translate('report.noSchoolChoice')}
                      disabled={!district}
                    />
                  </GridItem>
                  {
                    isShowGrade && (
                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('enrollment.grade')}
                          placeholder={translate('enrollment.placeholder.grade')}
                          value={transferGrade}
                          name="transferGrade"
                          choices={gradeChoices}
                          onChange={(e) => onChangeHandler(e, 'transferGrade')}
                          disabled={transferSchoolDegree !== SCHOOL_DEGREE.PRE}
                          error={errorTransferGrade}
                          require
                          helperText={errorTransferGrade ? translate('common.error.require') : ''}/>
                      </GridItem>
                    )
                  }
                </GridContainer>
              </GridItem>
            </GridItem>
          </GridContainer>
        )}
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onHandleClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          {!transferStudent ? (
            <ThemeButton
              onClick={onHandleConfirm}
              color="primary"
              variant="contained"
            >
              {translate('common.button.next')}
            </ThemeButton>
          ) : (
            <ThemeButton
              onClick={onHandleSave}
              color="primary"
              variant="contained"
            >
              {translate('common.button.save')}
            </ThemeButton>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

TransferDialog.propTypes = {
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  stsId: PropTypes.string,
  confirmStsId: PropTypes.string,
  transferStudent: PropTypes.object,
  transferGrade: PropTypes.string,
  reason: PropTypes.string,
  transferAcademicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  transferSchool: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  province: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  district: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedTransferSchool: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isOpenDialog: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
  errorStsId: PropTypes.bool,
  errorConfirmStsId: PropTypes.bool,
  errorReason: PropTypes.bool,
  errorTransferAcademicYear: PropTypes.bool,
  errorTransferSchool: PropTypes.bool,
  errorProvince: PropTypes.bool,
  errorDistrict: PropTypes.bool,
  errorTransferGrade: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onHandleConfirm: PropTypes.func,
  onHandleSave: PropTypes.func,
  onHandleClose: PropTypes.func,
  setTransferSchool: PropTypes.func,
  setTransferSchoolCode: PropTypes.func,
  setSelectedTransferSchool: PropTypes.func,
  isShowGrade: PropTypes.bool,
  setIsShowGrade: PropTypes.func,
  setTransferGrade: PropTypes.func
};

export default TransferDialog;
