export const GET_MONTHLY_SCORE_LIST_REQUEST = 'GET_MONTHLY_SCORE_LIST_REQUEST';
export const GET_MONTHLY_SCORE_LIST_SUCCESS = 'GET_MONTHLY_SCORE_LIST_SUCCESS';
export const GET_MONTHLY_SCORE_LIST_FAIL = 'GET_MONTHLY_SCORE_LIST_FAIL';

export const GET_ANNUAL_SCORE_LIST_REQUEST = 'GET_ANNUAL_SCORE_LIST_REQUEST';
export const GET_ANNUAL_SCORE_LIST_SUCCESS = 'GET_ANNUAL_SCORE_LIST_SUCCESS';
export const GET_ANNUAL_SCORE_LIST_FAIL = 'GET_ANNUAL_SCORE_LIST_FAIL';

export const SAVE_MONTHLY_SCORES_REQUEST = 'SAVE_MONTHLY_SCORES_REQUEST';
export const SAVE_MONTHLY_SCORES_SUCCESS = 'SAVE_MONTHLY_SCORES_SUCCESS';
export const SAVE_MONTHLY_SCORES_FAIL = 'SAVE_MONTHLY_SCORES_FAIL';

export const SAVE_ANNUAL_SCORES_REQUEST = 'SAVE_ANNUAL_SCORES_REQUEST';
export const SAVE_ANNUAL_SCORES_SUCCESS = 'SAVE_ANNUAL_SCORES_SUCCESS';
export const SAVE_ANNUAL_SCORES_FAIL = 'SAVE_ANNUAL_SCORES_FAIL';

export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAIL = 'GET_SUBJECTS_FAIL';

export const ADD_VALIDATION_STATUS_SUCCESS = 'ADD_VALIDATION_STATUS_SUCCESS';

export const RESET_SCORE = 'RESET_SCORE';

export const CALCULATE_SEMESTER_SCORE_REQUEST = 'CALCULATE_SEMESTER_SCORE_REQUEST';
export const CALCULATE_SEMESTER_SCORE_SUCCESS = 'CALCULATE_SEMESTER_SCORE_SUCCESS';
export const CALCULATE_SEMESTER_SCORE_FAIL = 'CALCULATE_SEMESTER_SCORE_FAIL';

export const IMPORT_STUDENT_SCORE_REQUEST = 'IMPORT_STUDENT_SCORE_REQUEST';
export const IMPORT_STUDENT_SCORE_SUCCESS = 'IMPORT_STUDENT_SCORE_SUCCESS';
export const IMPORT_STUDENT_SCORE_FAIL = 'IMPORT_STUDENT_SCORE_FAIL';

export const RESET_IMPORT_SCORE_STATE_REQUEST = 'RESET_IMPORT_SCORE_STATE_REQUEST';
