import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/graduation/dialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import GraduationIcon from '@material-ui/icons/CardMembership';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import * as moment from 'moment';
import settings from 'settings';
import {
  clearUnits,
  getDistricts,
  getPromoteToSchoolList,
  getProvinces
} from 'redux/actions';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import * as STATUS from 'variables/academicYearStatus';
import { getSchoolName } from 'utilities/helpers';
import { getGazetteerChoices } from 'utilities/gazetteer';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as VARIABLES from 'variables/graduation';

const useStyles = makeStyles(styles);
const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

export default function PromoteDialog (props) {
  const classes = useStyles();
  const {
    isOpenDialog,
    academicYear,
    school,
    grade,
    suffix,
    promoteAcademicYear,
    promoteSchool,
    promoteGrade,
    errorPromoteAcademicYear,
    errorPromoteSchool,
    errorPromoteGrade,
    isPromoteAction,
    onChangeHandler,
    onHandleSave,
    onHandleClose,
    setPromoteSchool,
    setPromoteGrade,
    province,
    district,
    errorProvince,
    errorDistrict,
    selectedStudents
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const graduationList = useSelector(state => state.graduation.graduationList);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const promoteToSchoolList = useSelector(state => state.graduation.promoteToSchoolList);
  const allGrades = useSelector(state => state.studentOption.grade);
  const graduationListByStatus = useSelector(state => state.graduation.graduationListByStatus);

  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);

  let totalFemales = 0;

  if (graduationListByStatus.data && graduationListByStatus.data.length > 0) {
    const femaleStudents = graduationListByStatus.data.filter(graduationList => {
      return selectedStudents.includes(graduationList.id) && graduationList.student.gender === 'f';
    });

    totalFemales = femaleStudents.length;
  }

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [selectedPromoteSchool, setSelectedPromoteSchool] = useState('');

  const selectedStudentsInfo = graduationList.data.filter(graduation => selectedStudents.includes(graduation.id));
  const studentsSkipClass = selectedStudentsInfo.filter(a => a.reason_for_annual_result_changed === VARIABLES.skipClass);
  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears
        .filter(a => a.status === STATUS.PRE)
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch schools list
  useEffect(() => {
    if (district) {
      const gazetteer = district.split(',')[1];
      dispatch(getPromoteToSchoolList({
        academic_year: promoteAcademicYear,
        gazetteer: gazetteer,
        orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
        order: 'ASC',
        limit: 9999
      }));
    }
    // eslint-disable-next-line
  }, [dispatch, district]);

  // fetch provinces
  useEffect(() => {
    dispatch(getProvinces());
    dispatch(clearUnits());
  }, [dispatch]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
  }, [provinces, localize]);

  // fetch districts after province is selected
  useEffect(() => {
    dispatch(getDistricts(province));
    dispatch(clearUnits('district'));
  }, [dispatch, province]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
  }, [districts, localize]);

  // set school choices
  useEffect(() => {
    if (promoteToSchoolList.length > 0) {
      const selectedSchool = schoolList.find(c => c.id === school);
      const selectedGrade = allGrades.find(g => g.number === grade);
      const selectedSchoolDegree = selectedSchool.degree.number;
      const gradePromotionNumber = [6, 9];
      if (isPromoteAction) {
        let choice = [];
        if (gradePromotionNumber.includes(selectedGrade.number) && selectedSchoolDegree !== SCHOOL_DEGREE.UPPER_SECONDARY_G7_12) {
          let matchDegrees = [SCHOOL_DEGREE.UPPER_SECONDARY_G7_12, SCHOOL_DEGREE.UPPER_SECONDARY_G10_12];
          if (selectedGrade.number === 6) {
            matchDegrees = [SCHOOL_DEGREE.LOWER_SECONDARY, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
          }
          choice = promoteToSchoolList.filter(s => matchDegrees.includes(s.degree.number)).map(school => {
            return {
              value: school.id,
              label: getSchoolName(getActiveLanguage(localize).code, translate, school),
              code: school.emis_code
            };
          });
        } else {
          let matchDegrees = [selectedSchoolDegree];
          if ([7, 8].includes(selectedGrade.number)) {
            matchDegrees = [SCHOOL_DEGREE.LOWER_SECONDARY, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
          } else if ([10, 11, 12].includes(selectedGrade.number)) {
            matchDegrees = [SCHOOL_DEGREE.UPPER_SECONDARY_G10_12, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
          } else if (selectedSchoolDegree === SCHOOL_DEGREE.PRE) {
            if ([0].includes(selectedGrade.number)) {
              matchDegrees = [SCHOOL_DEGREE.PRIMARY];
            } else {
              matchDegrees.push(SCHOOL_DEGREE.PRIMARY);
            }
          }
          choice = promoteToSchoolList.filter(a => {
            return matchDegrees.includes(a.degree.number);
          }).map(school => {
            return {
              value: school.id,
              label: getSchoolName(getActiveLanguage(localize).code, translate, school),
              code: school.emis_code
            };
          });
        }
        Object.entries(VARIABLES.unknownSchools).forEach(school => {
          choice.push({
            value: school[1],
            label: translate(`transfer.${school[0]}`)
          });
        });
        setSchoolChoices(choice);
      } else {
        let matchDegrees = [selectedSchoolDegree];
        if ([7, 8, 9].includes(selectedGrade.number)) {
          matchDegrees = [SCHOOL_DEGREE.UPPER_SECONDARY_G7_12, SCHOOL_DEGREE.LOWER_SECONDARY];
        } else if ([10, 11, 12].includes(selectedGrade.number)) {
          matchDegrees = [SCHOOL_DEGREE.UPPER_SECONDARY_G10_12, SCHOOL_DEGREE.UPPER_SECONDARY_G7_12];
        }
        const choice = promoteToSchoolList.filter(a => matchDegrees.includes(a.degree.number)).map(school => {
          return {
            value: school.id,
            label: getSchoolName(getActiveLanguage(localize).code, translate, school),
            code: school.emis_code
          };
        });
        setSchoolChoices(choice);
      }
    } else {
      setSchoolChoices([]);
      setSelectedPromoteSchool('');
      setPromoteSchool('');
    }
    // eslint-disable-next-line
  }, [promoteToSchoolList, localize, translate]);

  // set grade choices
  useEffect(() => {
    if (promoteSchool && promoteToSchoolList.length > 0 && allGrades.length > 0) {
      const choices = [];
      const promoteSchoolObj = promoteToSchoolList.find(s => s.id === promoteSchool);
      let degreeGrades = promoteSchoolObj ? SCHOOL_DEGREE[promoteSchoolObj.degree.number].grade : [];
      if (degreeGrades.length < 1) {
        degreeGrades = VARIABLES.degree.grade;
      }
      degreeGrades.forEach(gradeNumber => {
        const grade = allGrades.find(g => g.number === gradeNumber);
        choices.push({
          value: gradeNumber,
          label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
        });
      });
      setGradeChoices(choices);
    } else {
      setGradeChoices([]);
    }
  }, [promoteSchool, promoteToSchoolList, allGrades, localize]);

  const renderAcademicYearLabel = () => {
    const selectedAcademic = academicYears.find(a => a.id === academicYear);
    if (selectedAcademic) {
      const academicStartDate = moment(selectedAcademic.start_date, settings.dateFormat);
      const academicEndDate = moment(selectedAcademic.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${selectedAcademic.status}`).toUpperCase();
      return (selectedAcademic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
    }
    return '';
  };

  const renderSchoolLabel = () => {
    const selectedSchool = schoolList.find(c => c.id === school);
    if (selectedSchool) {
      return getSchoolName(getActiveLanguage(localize).code, translate, selectedSchool);
    }
    return '';
  };

  const renderGradeLabel = () => {
    const selectedGrade = allGrades.find(g => g.number === grade);
    if (selectedGrade !== undefined) {
      return getActiveLanguage(localize).code === 'en' ? selectedGrade.name_en : selectedGrade.name;
    }
    return '';
  };

  const onSchoolChangeHandler = (event, selectedSchool) => {
    if (selectedSchool) {
      setSelectedPromoteSchool(selectedSchool);
      setPromoteSchool(selectedSchool.value);
      if (isPromoteAction) {
        const schoolObj = schoolList.find(s => s.id === school);
        const promoteSchoolObj = promoteToSchoolList.find(s => s.id === selectedSchool.value);
        if (schoolObj.degree.number === SCHOOL_DEGREE.PRE && promoteSchoolObj && promoteSchoolObj.degree.number === SCHOOL_DEGREE.PRIMARY) {
          setPromoteGrade(1);
        } else {
          setPromoteGrade(grade + 1);
        }
      }
    } else {
      setSelectedPromoteSchool('');
      setPromoteSchool('');
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <GraduationIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('graduation.title')}
            </h6>
            <p className={classes.dialogDescription}>
              {isPromoteAction ? translate('graduation.promote') : translate('graduation.repeat')}
            </p>
          </div>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <h4>{translate('graduation.summary')}</h4>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
                md={7}
              >
                <p>
                  <span className={classes.summaryLabel}>{translate('enrollment.academicYear')}</span>
                  <strong>{renderAcademicYearLabel()}</strong>
                </p>
                <p>
                  <span className={classes.summaryLabel}>{translate('graduation.school')}</span>
                  <strong>{renderSchoolLabel()}</strong>
                </p>
                <p>
                  <span className={classes.summaryLabel}>{translate('classroom.classroom')}</span>
                  <strong>{renderGradeLabel()} ({suffix})</strong>
                </p>
              </GridItem>
              <GridItem
                xs={12}
                md={5}
              >
                <p>{translate('graduation.totalPassedHeader')}</p>
                <strong>{translate('graduation.totalPassed', { total: selectedStudents.length, female: totalFemales })}</strong>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>

        <h4>{isPromoteAction ? translate('graduation.promoteTo') : translate('graduation.repeatTo')}</h4>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('enrollment.academicYear')}
                  placeholder={translate('enrollment.placeholder.academicYear')}
                  value={promoteAcademicYear}
                  name="promoteAcademicYear"
                  onChange={e => onChangeHandler(e, 'promoteAcademicYear')}
                  choices={academicYearChoices}
                  disabled={!academicYears.length}
                  require
                  error={errorPromoteAcademicYear}
                  helperText={errorPromoteAcademicYear ? translate('common.error.require') : ''}
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.province')}
                  placeholder={translate('gazetteer.placeholder.province')}
                  value={province}
                  name="province"
                  onChange={e => onChangeHandler(e, 'province')}
                  choices={provinceChoices}
                  disabled={!provinceChoices.length}
                  require
                  error={errorProvince}
                  helperText={errorPromoteSchool ? translate('common.error.require') : ''}
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.district')}
                  placeholder={translate('gazetteer.placeholder.district')}
                  value={district}
                  name="district"
                  onChange={e => onChangeHandler(e, 'district')}
                  choices={districtChoices}
                  disabled={!province}
                  require
                  error={errorDistrict}
                  helperText={errorPromoteSchool ? translate('common.error.require') : ''}
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Autocomplete
                  value={selectedPromoteSchool}
                  name="selectedPromoteSchool"
                  require
                  label={translate('graduation.school')}
                  placeholder={translate('graduation.placeholder.school')}
                  options={schoolChoices}
                  filterOptions={schoolFilterOptions}
                  onChange={onSchoolChangeHandler}
                  error={errorPromoteSchool}
                  helperText={errorPromoteSchool ? translate('common.error.require') : ''}
                  noOptionsText={translate('report.noSchoolChoice')}
                  disabled={!district}
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('enrollment.grade')}
                  placeholder={translate('enrollment.placeholder.grade')}
                  value={promoteGrade}
                  name="promoteGrade"
                  onChange={e => onChangeHandler(e, 'promoteGrade')}
                  choices={gradeChoices}
                  disabled={!gradeChoices.length || !isPromoteAction || !studentsSkipClass.length}
                  require
                  error={errorPromoteGrade}
                  helperText={errorPromoteGrade
                    ? translate('common.error.require')
                    : studentsSkipClass.length ? translate('enrollment.helperText.skipClass')
                      : ''}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.actionButtons}>
          <ThemeButton
            onClick={onHandleClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          <ThemeButton
            onClick={onHandleSave}
            color="primary"
            variant="contained"
          >
            {translate('common.button.save')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

PromoteDialog.propTypes = {
  isOpenDialog: PropTypes.bool.isRequired,
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string,
  promoteAcademicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  promoteSchool: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  promoteGrade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  province: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  district: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errorPromoteAcademicYear: PropTypes.bool,
  errorPromoteSchool: PropTypes.bool,
  errorPromoteGrade: PropTypes.bool,
  errorProvince: PropTypes.bool,
  errorDistrict: PropTypes.bool,
  isPromoteAction: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onHandleSave: PropTypes.func,
  onHandleClose: PropTypes.func,
  setPromoteSchool: PropTypes.func,
  selectedStudents: PropTypes.array,
  setPromoteGrade: PropTypes.func
};
