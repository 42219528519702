import {
  goldColor
} from 'assets/theme/jss/material-dashboard-pro-react.js';

const dialogInputStyle = (theme) => ({
  avatar: {
    background: 'none'
  },
  avatarIcon: {
    width: 38,
    height: 38,
    color: goldColor[0]
  },
  actionButtons: {
    float: 'right',
    display: 'inline-block'
  },
  buttonDefault: {
    color: 'white',
    backgroundColor: '#616161',
    marginRight: 20
  },
  dialogAction: {
    display: 'unset',
    padding: '8px 24px',
    marginBottom: 20
  },
  dialogHelp: {
    minWidth: '600px',
    minHeight: '500px'
  },
  dialogHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: window.safari ? 'Roboto,sans-serif' : 'Roboto,Siemreap,sans-serif',
    fontWeight: 400,
    margin: 0
  },
  dialogDescription: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'normal',
    margin: 0
  },
  dialogContent: {
    overflow: 'auto'
  },
  firstGrid: {
    width: 55,
    float: 'left',
    display: 'inline-block'
  },
  secondGrid: {
    float: 'left'
  },
  summaryLabel: {
    display: 'inline-block',
    marginRight: 10,
    width: '95px',
    textAlign: 'right'
  }
});

export default dialogInputStyle;
