import Keycloak from 'keycloak-js';
import settings from 'settings';

const keycloak = new Keycloak({
  realm: settings.keycloak.realm,
  url: settings.keycloak.url,
  clientId: settings.keycloak.clientId
});

export default keycloak;
