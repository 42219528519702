import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import settings from 'settings';
import {
  deleteScholarshipFunding,
  getScholarshipFundingList
} from 'redux/actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';
import SweetAlert from 'react-bootstrap-sweetalert';
import { formatCurrency, getAvailableScholarshipFunding } from 'utilities/helpers';
import moment from 'moment';

const useStyles = makeStyles(styles);

const FundingTable = ({ onEditRowHandler }) => {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const isLoading = useSelector(state => state.scholarshipFunding.isLoading);
  const fundingList = useSelector(state => state.scholarshipFunding.scholarshipFundingList);
  const fundingFilter = useSelector(state => state.scholarshipFunding.scholarshipFundingFilter);

  const [school, setSchool] = useState('');
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [deleteId, setDeleteId] = useState('');
  const [confirmBoxOpen, setConfirmBoxOpen] = useState(false);

  useEffect(() => {
    if (fundingFilter) {
      setSchool(fundingFilter.school);
      setLimit(fundingFilter.limit);
      setPage(fundingFilter.page);
    }
  }, [fundingFilter]);

  useEffect(() => {
    if (fundingFilter !== undefined && school) {
      const params = {
        school,
        page,
        limit
      };
      dispatch(getScholarshipFundingList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, page, limit]);

  useEffect(() => {
    if (fundingList.data && fundingList.data.length > 0) {
      const showRows = fundingList.data.map(
        (funding) => ({
          id: funding.id,
          round: funding.round,
          received: funding.received,
          leftover: funding.leftover,
          available: getAvailableScholarshipFunding(funding.round, fundingList.data),
          receivedAt: moment(funding.received_at).format(settings.dateFormat)
        })
      );
      setCount(fundingList && fundingList.total ? fundingList.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [fundingList]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const handleDeleteRow = (id) => {
    setDeleteId(id);
    setConfirmBoxOpen(true);
  };

  const onHandleConfirm = () => {
    dispatch(deleteScholarshipFunding(deleteId));
    setDeleteId('');
    setConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setDeleteId('');
    setConfirmBoxOpen(false);
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        { confirmBoxOpen && (
          <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
            title={translate('common.modal.confirmTitle')}
            onConfirm={() => onHandleConfirm()}
            onCancel={() => onHandelCancel()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={translate('common.modal.yes')}
            cancelBtnText={translate('common.modal.no')}
            showCancel
          >
            {translate('common.modal.describe')}
          </SweetAlert>
        )}

        <Card>
          <CardBody>
            <Table
              size='small'
              tableHead={[
                { id: 'round', isNeedSort: false, label: translate('scholarshipRemittance.round') },
                { id: 'received', isNeedSort: false, label: translate('scholarshipFunding.received') },
                { id: 'leftover', isNeedSort: false, label: translate('scholarshipFunding.leftover') },
                { id: 'available', isNeedSort: false, label: translate('scholarshipFunding.available') },
                { id: 'receivedAt', isNeedSort: false, label: translate('scholarshipFunding.receivedAt') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const editButton = (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={`edit-${row.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        className={classes.tableIconButton}
                        onClick={() => onEditRowHandler(row.id)}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                const deleteButton = (
                  <Tooltip
                    title={translate('common.button.delete')}
                    key={`delete-${row.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        className={classes.tableIconButton}
                        onClick={() => handleDeleteRow(row.id)}
                      >
                        <Delete className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                return [
                  translate(`scholarshipRemittance.round.option.${row.round}`),
                  formatCurrency(row.received),
                  formatCurrency(row.leftover),
                  formatCurrency(row.available),
                  row.receivedAt,
                  [editButton, deleteButton]
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customClassesForCells={[0]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

FundingTable.propTypes = {
  onEditRowHandler: PropTypes.func
};

export default FundingTable;
