/* eslint-disable no-console */
import React, { PureComponent } from 'react';

export default class Branding extends PureComponent {
    static propTypes = {};

    render () {
      return (
        <div>
          {console.log('%cSTS - Developed by Web Essentials', 'border-radius:4px;color:#fff;line-height:40px;padding:10px 32px;background:#3BC2D7;font-weight:900;font-size:14pt')}
        </div>
      );
    }
}
