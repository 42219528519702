import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  startEnrollment: undefined,
  school: undefined,
  students: {},
  isEnrolling: false,
  isSearching: false,
  studentResult: undefined,
  isAssigning: false,
  isImporting: false,
  isImported: false,
  importedResult: {}
};

const getStartEnrollmentRequested = (state) => ({
  ...state,
  isLoading: true,
  school: undefined,
  students: {}
});

const getStartEnrollmentSuccess = (state, action) => ({
  ...state,
  students: action.students,
  school: action.school,
  startEnrollment: action.startEnrollment,
  isLoading: false
});

const getStartEnrollmentFail = (state) => ({
  ...state,
  isLoading: false
});

const enrollStudentRequest = (state) => ({
  ...state,
  isEnrolling: true
});

const enrollStudentSuccess = (state, action) => {
  return {
    ...state,
    isEnrolling: false
  };
};

const enrollStudentFail = (state) => ({
  ...state,
  isEnrolling: false
});

const deleteEnrollmentRequest = (state) => ({
  ...state
});

const deleteEnrollmentSuccess = (state) => {
  return {
    ...state
  };
};

const deleteEnrollmentFail = (state) => ({
  ...state
});

const searchStudentByStsIdRequest = (state) => ({
  ...state,
  isSearching: true
});

const searchStudentByStsIdSuccess = (state, action) => {
  return {
    ...state,
    isSearching: false,
    studentResult: action.studentResult
  };
};

const searchStudentByStsIdFail = (state) => ({
  ...state,
  isSearching: false
});

const resetStudentResult = (state, action) => {
  return {
    ...state,
    studentResult: action.studentResult
  };
};

const assignClassroomRequest = (state) => {
  return {
    ...state,
    isAssigning: true
  };
};

const assignClassroomSuccess = (state) => {
  return {
    ...state,
    isAssigning: false
  };
};

const assignClassroomFail = (state) => {
  return {
    ...state,
    isAssigning: false
  };
};

const importEnrollStudentRequest = (state) => {
  return {
    ...state,
    isImporting: true
  };
};

const importEnrollStudentSuccess = (state, action) => {
  return {
    ...state,
    isImporting: false,
    isImported: true,
    importedResult: action.result
  };
};

const importEnrollStudentFail = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: true
  };
};

const resetImportStatesRequest = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: false,
    importedResult: {}
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_START_ENROLLMENT_REQUEST:
      return getStartEnrollmentRequested(state);
    case actionTypes.CHECK_START_ENROLLMENT_SUCCESS:
      return getStartEnrollmentSuccess(state, action);
    case actionTypes.CHECK_START_ENROLLMENT_FAIL:
      return getStartEnrollmentFail(state);

    case actionTypes.ENROLL_STUDENT_REQUEST:
      return enrollStudentRequest(state);
    case actionTypes.ENROLL_STUDENT_SUCCESS:
      return enrollStudentSuccess(state, action);
    case actionTypes.ENROLL_STUDENT_FAIL:
      return enrollStudentFail(state);

    case actionTypes.DELETE_ENROLLMENT_REQUEST:
      return deleteEnrollmentRequest(state);
    case actionTypes.DELETE_ENROLLMENT_SUCCESS:
      return deleteEnrollmentSuccess(state);
    case actionTypes.DELETE_ENROLLMENT_FAIL:
      return deleteEnrollmentFail(state);

    case actionTypes.SEARCH_STUDENT_BY_ID_REQUEST:
      return searchStudentByStsIdRequest(state);
    case actionTypes.SEARCH_STUDENT_BY_ID_SUCCESS:
      return searchStudentByStsIdSuccess(state, action);
    case actionTypes.SEARCH_STUDENT_BY_ID_FAIL:
      return searchStudentByStsIdFail(state);
    case actionTypes.RESET_SEARCH_STUDENT_BY_ID:
      return resetStudentResult(state, action);

    case actionTypes.ASSIGN_CLASSROOM_REQUEST:
      return assignClassroomRequest(state);
    case actionTypes.ASSIGN_CLASSROOM_SUCCESS:
      return assignClassroomSuccess(state, action);
    case actionTypes.ASSIGN_CLASSROOM_FAIL:
      return assignClassroomFail(state);

    case actionTypes.IMPORT_ENROLL_STUDENT_REQUEST:
      return importEnrollStudentRequest(state);
    case actionTypes.IMPORT_ENROLL_STUDENT_SUCCESS:
      return importEnrollStudentSuccess(state, action);
    case actionTypes.IMPORT_ENROLL_STUDENT_FAIL:
      return importEnrollStudentFail(state);

    case actionTypes.RESET_IMPORT_STATE_REQUEST:
      return resetImportStatesRequest(state);

    default:
      return state;
  }
};

export default reducer;
