import * as actionTypes from 'redux/actionTypes';
import settings from 'settings';

export const initialState = {
  isLoading: false,
  accountList: [],
  account: undefined,
  filters: {
    stsId: '',
    lastName: '',
    firstName: '',
    limit: settings.rowsPerPage,
    page: settings.startPage
  },
  searchedStudent: undefined,
  isLoadingSearch: false
};

const getStudentAccountListRequested = (state) => ({
  ...state,
  isLoading: true
});

const getStudentAccountListSuccess = (state, action) => ({
  ...state,
  accountList: action.accountList,
  filters: action.filters,
  isLoading: false
});

const getStudentAccountListFail = (state) => ({
  ...state,
  isLoading: false
});

const searchStudentRequested = (state) => ({
  ...state,
  isLoadingSearch: true
});

const searchStudentSuccess = (state, action) => ({
  ...state,
  searchedStudent: action.searchedStudent,
  isLoadingSearch: false
});

const searchStudentFail = (state) => ({
  ...state,
  isLoadingSearch: false
});

const resetStudentSearchResult = (state) => ({
  ...state,
  searchedStudent: undefined
});

const createEditAccRequest = state => ({
  ...state,
  isLoading: true,
  isLoadingSearch: true
});

const createEditAccSuccess = state => ({
  ...state,
  isLoading: false,
  isLoadingSearch: false
});

const createEditAccFail = state => ({
  ...state,
  isLoading: false,
  isLoadingSearch: false
});

const getAccRequested = (state) => ({
  ...state,
  account: undefined,
  isLoading: true
});

const getAccSuccess = (state, action) => ({
  ...state,
  account: action.account,
  isLoading: false
});

const getAccFail = (state) => ({
  ...state,
  account: undefined,
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_ACCOUNT_LIST_REQUEST:
      return getStudentAccountListRequested(state);
    case actionTypes.GET_STUDENT_ACCOUNT_LIST_SUCCESS:
      return getStudentAccountListSuccess(state, action);
    case actionTypes.GET_STUDENT_ACCOUNT_LIST_FAIL:
      return getStudentAccountListFail(state);

    case actionTypes.SEARCH_STUDENT_REQUEST:
      return searchStudentRequested(state);
    case actionTypes.SEARCH_STUDENT_SUCCESS:
      return searchStudentSuccess(state, action);
    case actionTypes.SEARCH_STUDENT_FAIL:
      return searchStudentFail(state);

    case actionTypes.RESET_STUDENT_SEARCH_RESULT:
      return resetStudentSearchResult(state);

    case actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_REQUEST:
      return createEditAccRequest(state);
    case actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_SUCCESS:
      return createEditAccSuccess(state, action);
    case actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_FAIL:
      return createEditAccFail(state);

    case actionTypes.GET_ACC_REQUEST:
      return getAccRequested(state);
    case actionTypes.GET_ACC_SUCCESS:
      return getAccSuccess(state, action);
    case actionTypes.GET_ACC_FAIL:
      return getAccFail(state);

    default:
      return state;
  }
};

export default reducer;
