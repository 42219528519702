import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isRequestCreateEdit: false,
  isLoadingGet: false,
  academicYears: [],
  academicYearDetail: undefined
};

const createAcademicYearRequest = (state) => ({
  ...state,
  academicYearDetail: undefined,
  isRequestCreateEdit: true
});

const createAcademicYearSuccess = (state) => ({
  ...state,
  isRequestCreateEdit: false
});

const createAcademicYearFail = (state) => ({
  ...state,
  isRequestCreateEdit: false
});

const getAcademicYearListRequest = (state) => ({
  ...state,
  isLoadingGet: true
});

const getAcademicYearListSuccess = (state, action) => ({
  ...state,
  isLoadingGet: false,
  academicYears: action.academicYears
});
const getAcademicYearListFail = (state) => ({
  ...state,
  isLoadingGet: false
});

const getAcademicYearRequest = (state) => ({
  ...state,
  isRequestCreateEdit: true
});

const getAcademicYearSuccess = (state, action) => ({
  ...state,
  isRequestCreateEdit: false,
  academicYearDetail: action.academicYearDetail
});
const getAcademicYearFail = (state) => ({
  ...state,
  isRequestCreateEdit: false
});

const deleteAcademicYearRequest = (state) => ({
  ...state,
  isLoadingGet: true
});

const deleteAcademicYearSuccess = (state) => ({
  ...state
});

const deleteAcademicYearFail = (state) => ({
  ...state,
  isLoadingGet: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACADEMIC_YEAR_LIST_GET_REQUEST:
      return getAcademicYearListRequest(state);
    case actionTypes.ACADEMIC_YEAR_LIST_GET_SUCCESS:
      return getAcademicYearListSuccess(state, action);
    case actionTypes.ACADEMIC_YEAR_LIST_GET_FAIL:
      return getAcademicYearListFail(state);

    case actionTypes.ACADEMIC_YEAR_GET_REQUEST:
      return getAcademicYearRequest(state);
    case actionTypes.ACADEMIC_YEAR_GET_SUCCESS:
      return getAcademicYearSuccess(state, action);
    case actionTypes.ACADEMIC_YEAR_GET_FAIL:
      return getAcademicYearFail(state);

    case actionTypes.ACADEMIC_YEAR_CREAT_EDIT_REQUEST:
      return createAcademicYearRequest(state, action);
    case actionTypes.ACADEMIC_YEAR_CREAT_EDIT_SUCCESS:
      return createAcademicYearSuccess(state, action);
    case actionTypes.ACADEMIC_YEAR_CREAT_EDIT_FAIL:
      return createAcademicYearFail(state, action);

    case actionTypes.ACADEMIC_YEAR_DELETE_REQUEST:
      return deleteAcademicYearRequest(state);
    case actionTypes.ACADEMIC_YEAR_DELETE_SUCCESS:
      return deleteAcademicYearSuccess(state);
    case actionTypes.ACADEMIC_YEAR_DELETE_FAIL:
      return deleteAcademicYearFail(state);

    case actionTypes.RESET_ACADEMIC_YEAR:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
