import * as actionTypes from 'redux/actionTypes';
import keycloak from 'utilities/keycloak';
import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions';
import { getActiveLanguage } from 'react-localize-redux';

export const HEARTBEAT_INTERVAL = 60000;
export const TOKEN_VALIDITY = 900;

function loginSucceeded () {
  return {
    type: actionTypes.LOGIN_SUCCEEDED
  };
}

function loginFailed (e) {
  return {
    type: actionTypes.LOGIN_FAILED,
    logInError: e.message
  };
}

function logoutSucceeded () {
  return {
    type: actionTypes.LOGOUT_SUCCEEDED
  };
}

function logoutFailed (e) {
  return {
    type: actionTypes.LOGOUT_FAILED,
    logOutError: e.message
  };
}

function loadProfileRequested () {
  return {
    type: actionTypes.PROFILE_LOAD_REQUESTED
  };
}

function loadProfileSucceeded (profile) {
  return {
    type: actionTypes.PROFILE_LOAD_SUCCEEDED,
    profile
  };
}

function loadProfileFailed () {
  return {
    type: actionTypes.PROFILE_LOAD_FAILED
  };
}

function refreshIntervalActivated (timer) {
  return {
    type: actionTypes.HEARTBEAT_ACTIVATED,
    heartBeatTimer: timer
  };
}

function refreshIntervalDeactivated () {
  return {
    type: actionTypes.HEARTBEAT_DEACTIVATED
  };
}

function refreshTokenSucceeded () {
  return {
    type: actionTypes.TOKEN_REFRESH_SUCCEEDED
  };
}

function refreshTokenFailed () {
  return {
    type: actionTypes.TOKEN_REFRESH_FAILED
  };
}

export function login () {
  return (dispatch, getState) => {
    if (!keycloak.authenticated) {
      keycloak
        .login({ locale: getActiveLanguage(getState().localize).code })
        .then((rs) => {
          dispatch(loginSucceeded(rs));
          dispatch(loadProfile());
        })
        .catch((e) => dispatch(loginFailed(e)));
    }
  };
}

export function logout () {
  return (dispatch) => {
    if (keycloak.authenticated) {
      keycloak
        .logout({ redirectUri: window.location.origin })
        .then((rs) => dispatch(logoutSucceeded(rs)))
        .catch((e) => dispatch(logoutFailed(e)));
    } else {
      window.location.reload();
    }
  };
}

export function loadProfile () {
  return (dispatch) => {
    dispatch(loadProfileRequested());
    if (keycloak.authenticated) {
      const tokenParsed = keycloak.tokenParsed;
      axios.get(`/profile/${tokenParsed.preferred_username}`)
        .then(
          res => {
            const data = res.data.data;
            data.realmRoles = tokenParsed.realm_access.roles;
            dispatch(loadProfileSucceeded(data));
          }
        )
        .catch(e => {
          dispatch(loadProfileFailed());
          dispatch(showErrorNotification(e.message));
        });
    } else {
      dispatch(loadProfileFailed());
      dispatch(showErrorNotification('common.error.unauthorizedUser'));
    }
  };
}

export function activateHeartbeat () {
  return (dispatch, getState) => {
    const { heartbeatActive } = getState().auth;
    if (!heartbeatActive) {
      const timer = setInterval(
        () => dispatch(refreshToken()),
        HEARTBEAT_INTERVAL
      );
      dispatch(refreshIntervalActivated(timer));
    }
  };
}

export function deactivateHeartbeat () {
  return (dispatch, getState) => {
    const { heartbeatTimer } = getState().user;
    if (heartbeatTimer) {
      clearInterval(heartbeatTimer);
      dispatch(refreshIntervalDeactivated());
    }
  };
}

export function refreshToken () {
  return (dispatch) => {
    if (keycloak.authenticated) {
      keycloak
        .updateToken(TOKEN_VALIDITY)
        .then((tokenRefreshed) => {
          if (tokenRefreshed) {
            dispatch(refreshTokenSucceeded());
          }
        })
        .catch((e) => {
          dispatch(refreshTokenFailed(e));
          dispatch(logout());
        });
    }
  };
}
