import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getAttendanceListRequested () {
  return {
    type: actionTypes.GET_ATTENDANCE_LIST_REQUEST
  };
}

function getAttendanceListSuccess (attendanceList, attendanceFilter) {
  return {
    type: actionTypes.GET_ATTENDANCE_LIST_SUCCESS,
    attendanceList,
    attendanceFilter
  };
}

function getAttendanceListFail () {
  return {
    type: actionTypes.GET_ATTENDANCE_LIST_FAIL
  };
}

function saveAllAttendancesRequested () {
  return {
    type: actionTypes.SAVE_ALL_ATTENDANCES_REQUEST
  };
}

function saveAllAttendancesSuccess (attendanceList) {
  return {
    type: actionTypes.SAVE_ALL_ATTENDANCES_SUCCESS,
    attendanceList
  };
}

function saveAllAttendancesFail () {
  return {
    type: actionTypes.SAVE_ALL_ATTENDANCES_FAIL
  };
}

const importStudentAttendanceRequest = () => {
  return {
    type: actionTypes.IMPORT_STUDENT_ATTENDANCE_REQUEST
  };
};

const importStudentAttendanceSuccess = (result) => {
  return {
    type: actionTypes.IMPORT_STUDENT_ATTENDANCE_SUCCESS,
    result
  };
};

const importStudentAttendanceFail = () => {
  return {
    type: actionTypes.IMPORT_STUDENT_ATTENDANCE_FAIL
  };
};

const resetImportAttendanceStatesRequest = () => {
  return {
    type: actionTypes.RESET_IMPORT_ATTENDANCE_STATES_REQUEST
  };
};

export const getAttendances = (filterParams) => {
  return (dispatch) => {
    dispatch(getAttendanceListRequested());
    const params = {
      classroom_id: filterParams.classroomId,
      student_id: filterParams.studentId ? filterParams.studentId : undefined
    };
    axios.get('/attendances', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getAttendanceListSuccess(data, filterParams));
        }
      )
      .catch(e => {
        dispatch(getAttendanceListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const saveAttendances = (data) => {
  return (dispatch) => {
    dispatch(saveAllAttendancesRequested());
    axios.post('/attendances', { data })
      .then(
        res => {
          dispatch(saveAllAttendancesSuccess(data.attendances));
          dispatch(showSuccessNotification('attendance.saveSuccessfulMessage'));
        }
      )
      .catch(e => {
        dispatch(saveAllAttendancesFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const importAttendance = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('classroomId', data.classroomId);
    dispatch(importStudentAttendanceRequest());
    axios.post('import/attendances', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(
        res => {
          dispatch(importStudentAttendanceSuccess(res.data));
          if (res.data.success) {
            dispatch(showSuccessNotification('attendance.saveSuccessfulMessage'));
          } else {
            dispatch(showErrorNotification('enrollment.importFailed'));
          }
        }
      )
      .catch(e => {
        dispatch(importStudentAttendanceFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetImportAttendanceStates = () => {
  return (dispatch) => {
    dispatch(resetImportAttendanceStatesRequest());
  };
};
