import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getScholarshipListRequested () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_LIST_REQUEST
  };
}

function getScholarshipListSuccess (scholarshipList, scholarshipFilter) {
  return {
    type: actionTypes.GET_SCHOLARSHIP_LIST_SUCCESS,
    scholarshipList,
    scholarshipFilter
  };
}

function getScholarshipListFail () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_LIST_FAIL
  };
}

function getScholarshipStudentRequested () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_REQUEST
  };
}

function getScholarshipStudentSuccess (student) {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_SUCCESS,
    student
  };
}

function getScholarshipStudentFail () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_FAIL
  };
}

function updateScholarshipStudentRequested () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_REQUEST
  };
}

function updateScholarshipStudentSuccess (student) {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_SUCCESS,
    student
  };
}

function updateScholarshipStudentFail () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_STUDENT_FAIL
  };
}

export const getScholarshipList = (rawParams) => {
  return (dispatch) => {
    const params = {
      school_id: rawParams.school,
      page: rawParams.page + 1,
      limit: rawParams.limit
    };

    dispatch(getScholarshipListRequested());
    axios.get('/scholarships', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          data.validTotal = res.data.validTotal;
          dispatch(getScholarshipListSuccess(data, rawParams));
        }
      )
      .catch(e => {
        dispatch(getScholarshipListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getScholarshipStudent = (school, stsId) => {
  return async (dispatch) => {
    dispatch(getScholarshipStudentRequested());
    const result = await axios.get(`/enrollments/get-by-student?academic_year_school_id=${school}&emis_student_id=${stsId}`)
      .then(
        res => {
          const student = res.data.data;
          dispatch(getScholarshipStudentSuccess(student));
          return student;
        }
      )
      .catch(() => {
        dispatch(getScholarshipStudentFail());
        dispatch(showErrorNotification('scholarship.studentNotFound'));
      });
    return result;
  };
};

export const updateScholarshipStudent = (id, data) => {
  return async (dispatch, getState) => {
    dispatch(updateScholarshipStudentRequested());
    const result = await axios.put(`/scholarships/${id}`, data)
      .then(
        () => {
          dispatch(updateScholarshipStudentSuccess());
          const filter = getState().scholarship.scholarshipFilter;
          dispatch(getScholarshipList(filter));
          dispatch(showSuccessNotification('scholarship.updateSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(updateScholarshipStudentFail());
        dispatch(showErrorNotification('scholarship.updateError'));
      });
    return result;
  };
};
