import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification
} from 'redux/actions/notification';

function getStaffsRequested () {
  return {
    type: actionTypes.GET_STAFFS_REQUEST
  };
}

function getStaffsSuccess (staffs) {
  return {
    type: actionTypes.GET_STAFFS_SUCCESS,
    staffs
  };
}

function getStaffsFail () {
  return {
    type: actionTypes.GET_STAFFS_FAIL
  };
}

export const getStaffs = (role, academicYearSchoolId, rawSchoolId) => {
  return (dispatch) => {
    const params = {
      staff_role: role,
      year_school_id: academicYearSchoolId,
      raw_school_id: rawSchoolId
    };
    dispatch(getStaffsRequested());
    axios.get('/staffs', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getStaffsSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getStaffsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
