export const REPORT_STAGES = {
  1: {
    code: 'runningEndOfFirstMonth'
  },
  2: {
    code: 'runningEndOfSemester1'
  },
  3: {
    code: 'post'
  }
};

export const REPORT_TYPES = {
  classroom: 1,
  school: 2,
  student: 3
};

export const BACKGROUND_TASK_STATUS = {
  finished: 'finished',
  success: 'success'
};

export const STS_REPORTS = {
  1: {
    name: 'class.classroomStudentList',
    outputFormat: 'XLSX',
    outputFile: 'StudentList.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  2: {
    name: 'class.studentListByClassroom',
    outputFormat: 'XLSX',
    outputFile: 'StudentList.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  3: {
    name: 'class.classroomStudentMonthlyScore',
    outputFormat: 'XLSX',
    outputFile: 'StudentMonthlyScore.xlsx',
    enableClassroomSelection: true,
    enableMonthSelection: true,
    type: REPORT_TYPES.classroom
  },
  4: {
    name: 'class.classroomStudentSubjectScore',
    outputFormat: 'XLSX',
    outputFile: 'StudentSubjectScore.xlsx',
    enableClassroomSelection: true,
    enableMonthSelection: true,
    type: REPORT_TYPES.classroom
  },
  5: {
    name: 'class.classroomStudentMonthlyScoreCard',
    outputFormat: 'XLSX',
    outputFile: 'StudentMonthlyScoreCard.xlsx',
    enableClassroomSelection: true,
    enableMonthSelection: true,
    type: REPORT_TYPES.classroom
  },
  6: {
    name: 'class.classroomStudentAbsenteeismList',
    outputFormat: 'XLSX',
    outputFile: 'StudentAbsenteeismList.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  7: {
    name: 'class.classroomStudentAnnualScore',
    outputFormat: 'XLSX',
    outputFile: 'StudentAnnualScore.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  8: {
    name: 'class.classroomStudentAnnualScoreCard',
    outputFormat: 'XLSX',
    outputFile: 'StudentAnnualScoreCard.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  9: {
    name: 'class.classroomScholarshipStudentList',
    outputFormat: 'XLSX',
    outputFile: 'StudentListByScholarship.xlsx',
    enableScholarshipSelection: true,
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  10: {
    name: 'class.classroomStudentWithDisabilityList',
    outputFormat: 'XLSX',
    outputFile: 'StudentWithDisabilityList.xlsx',
    enableDisabilitySelection: true,
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  11: {
    name: 'class.classroomStudentWithHealthProblemList',
    outputFormat: 'XLSX',
    outputFile: 'StudentWithHealthProblemList.xlsx',
    enableHealthProblemSelection: true,
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  12: {
    name: 'class.classroomStudentWithPersonalProblemList',
    outputFormat: 'XLSX',
    outputFile: 'StudentWithPersonalProblemList.xlsx',
    enablePersonalProblemSelection: true,
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  13: {
    name: 'class.classroomStudentEthnicityList',
    outputFormat: 'XLSX',
    outputFile: 'StudentEthnicityList.xlsx',
    enableEthnicitySelection: true,
    enableClassroomSelection: true,
    type: REPORT_TYPES.classroom
  },
  14: {
    name: 'class.classroomTransferredStudentList',
    outputFormat: 'XLSX',
    outputFile: 'TransferredStudentList.xlsx',
    enableClassroomSelection: true,
    enableReportStageSelection: true,
    reportStages: [
      {
        label: 'report.reportStage.runningEndOfFirstMonth',
        value: 1
      },
      {
        label: 'report.reportStage.runningEndOfSemester1',
        value: 2
      },
      {
        label: 'report.reportStage.annual',
        value: 'all'
      }
    ],
    type: REPORT_TYPES.classroom
  },
  15: {
    name: 'class.classroomStudentDropoutList',
    outputFormat: 'XLSX',
    outputFile: 'StudentDropoutList.xlsx',
    enableReportStageSelection: true,
    enableClassroomSelection: true,
    reportStages: [
      {
        label: 'report.reportStage.semester1',
        value: 2
      },
      {
        label: 'report.reportStage.semester2',
        value: 3
      },
      {
        label: 'report.reportStage.annual',
        value: 'all'
      }
    ],
    type: REPORT_TYPES.classroom
  },
  100: {
    name: 'school.reportCensus',
    outputFormat: 'XLSX',
    outputFile: 'ReportCensus.xlsx',
    type: REPORT_TYPES.school
  }
};
