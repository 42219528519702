import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';

import authReducer from 'redux/reducers/auth';
import academicYearReducer from 'redux/reducers/academicYear';
import notificationsReducer from 'redux/reducers/notification';
import schoolReducer from 'redux/reducers/school';
import classroomReducer from 'redux/reducers/classroom';
import gazetteerCodeReducer from 'redux/reducers/gazetteerCode';
import staffReducer from 'redux/reducers/staff';
import studentOptionReducer from 'redux/reducers/studentOption';
import studentReducer from 'redux/reducers/student';
import enrollmentReducer from 'redux/reducers/enrollment';
import transferReducer from 'redux/reducers/transfer';
import graduationReducer from 'redux/reducers/graduation';
import errorReducer from 'redux/reducers/error';
import attendanceReducer from 'redux/reducers/attendance';
import userManagementReducer from 'redux/reducers/userManagement';
import studentAccountManagementReducer from 'redux/reducers/studentAccountManagement';
import dataQueryReducer from 'redux/reducers/dataQuery';
import scoreReducer from 'redux/reducers/score';
import reportReducer from 'redux/reducers/report';
import scholarshipReducer from 'redux/reducers/scholarship';
import scholarshipRemittanceReducer from 'redux/reducers/scholarshipRemittance';
import scholarshipFundingReducer from 'redux/reducers/scholarshipFunding';
import exportHistoryReducer from 'redux/reducers/exportHistory';

const reducer = combineReducers({
  auth: authReducer,
  academicYear: academicYearReducer,
  notification: notificationsReducer,
  school: schoolReducer,
  classroom: classroomReducer,
  gazetteerCode: gazetteerCodeReducer,
  studentOption: studentOptionReducer,
  student: studentReducer,
  localize: localizeReducer,
  staff: staffReducer,
  enrollment: enrollmentReducer,
  transfer: transferReducer,
  graduation: graduationReducer,
  error: errorReducer,
  attendance: attendanceReducer,
  user: userManagementReducer,
  score: scoreReducer,
  studentAccount: studentAccountManagementReducer,
  dataQuery: dataQueryReducer,
  report: reportReducer,
  scholarship: scholarshipReducer,
  scholarshipRemittance: scholarshipRemittanceReducer,
  scholarshipFunding: scholarshipFundingReducer,
  exportHistory: exportHistoryReducer
});

export default reducer;
