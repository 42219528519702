import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import Branding from 'layout/branding/Branding';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from 'layout/footer/Footer';
import Header from 'layout/header/Header';
import Main from 'layout/main/Main';
import Navigation from './drawer/Navigation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core';
import Notification from 'components/common/Notification/Notification';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const styles = () => ({

  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: 'inherit'
  },
  scrollbar: {
    width: '100%'
  }

});

const useStyles = makeStyles(styles);

const Layout = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(window.innerWidth >= 960);
  const { pathname } = useLocation();
  const authProfile = useSelector(state => state.auth.profile);
  const userGroup = authProfile ? authProfile.groups[0] : '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (

    <div className={classes.root}>

      <CssBaseline/>

      <Branding/>

      <Navigation
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        userGroup={userGroup}
      />

      <PerfectScrollbar className={classes.scrollbar}>

        <Header
          drawerOpen={drawerOpen}
          onToggleDrawer={handleToggleDrawer}
        />

        <Main/>

      </PerfectScrollbar>

      <Footer/>

      <Notification/>

    </div>
  );
};

export default Layout;
