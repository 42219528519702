import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Datetime from 'react-datetime';
import styles from 'assets/sts/jss/components/dateTimePickerStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import settings from 'settings';
import 'translations/km-sts';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const DateTimePicker = (props) => {
  const {
    require,
    name,
    placeholder,
    label,
    className,
    labelClassName,
    disabled,
    helperText,
    error,
    ...rest
  } = props;
  const classes = makeStyles(styles)();
  let inputClasses = disabled ? 'form-control disabled' : 'form-control';
  inputClasses = error ? 'form-control error' : inputClasses;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const helpTextClasses = cx({
    [classes.labelRootError]: error
  });
  return (
    <div
      className={className}
    >
      <InputLabel
        required={require}
        htmlFor={name}
        className={`${cx({
            [labelClassName]: true
          })}`}
      >
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <Datetime
          {...rest}
          locale={getActiveLanguage(localize).code === 'en' ? 'en' : 'km-sts'}
          dateFormat={rest.dateFormat || settings.dateFormat}
          className={`${classes.dataTime} ${cx({
            [classes.disable]: disabled
          })}`}
          inputProps={
            {
              id: name,
              name: name,
              placeholder: `${placeholder}`,
              disabled: disabled,
              autoComplete: 'off',
              className: inputClasses
            }
          }
          closeOnSelect={true}
          renderMonth={ (props, month) => {
            const displayMonth = translate(`common.month.${month.toString()}`);
            return <td {...props}>{displayMonth}</td>;
          }
          }
        />
        {helperText !== undefined ? (
          <FormHelperText id={name} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

DateTimePicker.defaultProps = {
  disabled: false,
  placeholder: '',
  error: false,
  require: false,
  label: undefined
};

DateTimePicker.propTypes = {
  require: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string
};

export default DateTimePicker;
