import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const Contact = () => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const changeBrandText = useCallback(
    () => {
      document.getElementById('brandText').innerHTML = translate('contact.title');
    }, [translate]
  );

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);
  return (
    <div>Contact</div>
  );
};

export default Contact;
