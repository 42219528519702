import { createStore, applyMiddleware, compose } from 'redux';
import reducer from 'redux/reducers';
import thunk from 'redux-thunk';
import {
  addTranslationForLanguage,
  initialize
} from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import en from 'translations/en.locale.json';
import km from 'translations/km.locale.json';
import settings from 'settings';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = settings.stage === 'local' ? createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
) : createStore(
  reducer,
  applyMiddleware(thunk)
);

const languages = settings.languages.options;

store.dispatch(initialize({
  languages,
  options: {
    renderToStaticMarkup,
    defaultLanguage: settings.languages.defaultLanguage,
    onMissingTranslation: settings.languages.onMissingTranslation
  }
}));

store.dispatch(addTranslationForLanguage(en, 'en'));
store.dispatch(addTranslationForLanguage(km, 'km'));

export default store;
