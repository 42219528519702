import moment from 'moment';

export default moment.defineLocale('km-sts', {
  months: 'មករា_កុម្ភៈ_មីនា_មេសា_ឧសភា_មិថុនា_កក្កដា_សីហា_កញ្ញា_តុលា_វិច្ឆិកា_ធ្នូ'.split(
    '_'
  ),
  monthsShort: 'មករា_កុម្ភៈ_មីនា_មេសា_ឧសភា_មិថុនា_កក្កដា_សីហា_កញ្ញា_តុលា_វិច្ឆិកា_ធ្នូ'.split(
    '_'
  ),
  weekdays: 'អាទិត្យ_ច័ន្ទ_អង្គារ_ពុធ_ព្រហស្បតិ៍_សុក្រ_សៅរ៍'.split(
    '_'
  ),
  weekdaysShort: 'អាទិត្យ_ច័ន្ទ_អង្គារ_ពុធ_ព្រហស្បតិ៍_សុក្រ_សៅរ៍'.split('_'),
  weekdaysMin: 'អាទិត្យ_ច័ន្ទ_អង្គារ_ពុធ_ព្រហស្បតិ៍_សុក្រ_សៅរ៍'.split('_'),
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY h:mm A',
    LLLL: 'dddd, D MMMM YYYY h:mm A'
  },
  meridiemParse: /ព្រឹក|ល្ងាច/,
  isPM: function (input) {
    return input === 'ល្ងាច';
  },
  meridiem: function (hour, minute, isLower) {
    if (hour < 12) {
      return 'ព្រឹក';
    } else {
      return 'ល្ងាច';
    }
  },
  calendar: {
    sameDay: '[ថ្ងៃនេះ ម៉ោង] LT',
    nextDay: '[ស្អែក ម៉ោង] LT',
    nextWeek: 'dddd [ម៉ោង] LT',
    lastDay: '[ម្សិលមិញ ម៉ោង] LT',
    lastWeek: 'dddd [សប្តាហ៍មុន] [ម៉ោង] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: '%sទៀត',
    past: '%sមុន',
    s: 'ប៉ុន្មានវិនាទី',
    ss: '%d វិនាទី',
    m: 'មួយនាទី',
    mm: '%d នាទី',
    h: 'មួយម៉ោង',
    hh: '%d ម៉ោង',
    d: 'មួយថ្ងៃ',
    dd: '%d ថ្ងៃ',
    M: 'មួយខែ',
    MM: '%d ខែ',
    y: 'មួយឆ្នាំ',
    yy: '%d ឆ្នាំ'
  },
  dayOfMonthOrdinalParse: /ទី\d{1,2}/,
  ordinal: 'ទី%d',
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
});
