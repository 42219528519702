import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getMonthlyScoreListRequested () {
  return {
    type: actionTypes.GET_MONTHLY_SCORE_LIST_REQUEST
  };
}

function getMonthlyScoreListSuccess (monthlyScoreList, scoreFilter) {
  return {
    type: actionTypes.GET_MONTHLY_SCORE_LIST_SUCCESS,
    monthlyScoreList,
    scoreFilter
  };
}

function getMonthlyScoreListFail () {
  return {
    type: actionTypes.GET_MONTHLY_SCORE_LIST_FAIL
  };
}

function getAnnualScoreListRequested () {
  return {
    type: actionTypes.GET_ANNUAL_SCORE_LIST_REQUEST
  };
}

function getAnnualScoreListSuccess (annualScoreList, scoreFilter) {
  return {
    type: actionTypes.GET_ANNUAL_SCORE_LIST_SUCCESS,
    annualScoreList,
    scoreFilter
  };
}

function getAnnualScoreListFail () {
  return {
    type: actionTypes.GET_ANNUAL_SCORE_LIST_FAIL
  };
}

function saveMonthlyScoresRequested () {
  return {
    type: actionTypes.SAVE_MONTHLY_SCORES_REQUEST
  };
}

function saveMonthlyScoresSuccess (monthlyScoreList) {
  return {
    type: actionTypes.SAVE_MONTHLY_SCORES_SUCCESS,
    monthlyScoreList
  };
}

function saveMonthlyScoresFail () {
  return {
    type: actionTypes.SAVE_MONTHLY_SCORES_FAIL
  };
}

function saveAnnualScoresRequested () {
  return {
    type: actionTypes.SAVE_ANNUAL_SCORES_REQUEST
  };
}

function saveAnnualScoresSuccess (annualScoreList) {
  return {
    type: actionTypes.SAVE_ANNUAL_SCORES_SUCCESS,
    annualScoreList
  };
}

function saveAnnualScoresFail () {
  return {
    type: actionTypes.SAVE_ANNUAL_SCORES_FAIL
  };
}

function getSubjectsSuccess (subjects, excludedSubjects) {
  return {
    type: actionTypes.GET_SUBJECTS_SUCCESS,
    subjects,
    excludedSubjects
  };
}

function getSubjectsFail () {
  return {
    type: actionTypes.GET_SUBJECTS_FAIL
  };
}

function addValidationStatusSuccess (validationStatus) {
  return {
    type: actionTypes.ADD_VALIDATION_STATUS_SUCCESS,
    validationStatus
  };
}

function calculateSemesterScoreRequested () {
  return {
    type: actionTypes.CALCULATE_SEMESTER_SCORE_REQUEST
  };
}

function calculateSemesterScoreSuccess () {
  return {
    type: actionTypes.CALCULATE_SEMESTER_SCORE_SUCCESS
  };
}

function calculateSemesterScoreFail () {
  return {
    type: actionTypes.CALCULATE_SEMESTER_SCORE_FAIL
  };
}

const importStudentScoreRequest = () => {
  return {
    type: actionTypes.IMPORT_STUDENT_SCORE_REQUEST
  };
};

const importStudentScoreSuccess = (result) => {
  return {
    type: actionTypes.IMPORT_STUDENT_SCORE_SUCCESS,
    result
  };
};

const importStudentScoreFail = () => {
  return {
    type: actionTypes.IMPORT_STUDENT_SCORE_FAIL
  };
};

const resetImportScoreStatesRequest = () => {
  return {
    type: actionTypes.RESET_IMPORT_SCORE_STATE_REQUEST
  };
};

export const getMonthlyScores = (filterParams) => {
  return (dispatch) => {
    dispatch(getMonthlyScoreListRequested());
    const params = {
      classroom_id: filterParams.classroomId,
      academic_year_id: filterParams.academicYear,
      month: filterParams.month,
      limit: filterParams.limit
    };

    if (filterParams.studentId) {
      params.student_id = filterParams.studentId;
    }

    axios.get('/scores', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getMonthlyScoreListSuccess(data, filterParams));
        }
      )
      .catch(e => {
        dispatch(getMonthlyScoreListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getAnnualScores = (filterParams) => {
  return (dispatch) => {
    dispatch(getAnnualScoreListRequested());
    const params = {
      classroom_id: filterParams.classroomId,
      academic_year_id: filterParams.academicYear
    };

    if (filterParams.studentId) {
      params.student_id = filterParams.studentId;
    }

    axios.get('/annual-scores', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getAnnualScoreListSuccess(data, filterParams));
        }
      )
      .catch(e => {
        dispatch(getAnnualScoreListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getSubjects = (classroomId) => {
  return (dispatch) => {
    axios.get(`/classrooms/${classroomId}`)
      .then(
        res => {
          const subjects = res.data.data.subjects;
          const excludedSubjects = res.data.data.excluded_subjects;
          dispatch(getSubjectsSuccess(subjects, excludedSubjects));
        }
      )
      .catch(e => {
        dispatch(getSubjectsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const saveMonthlyScores = (data, scores) => {
  return (dispatch) => {
    dispatch(saveMonthlyScoresRequested());
    axios.post('/scores', data)
      .then(
        res => {
          dispatch(saveMonthlyScoresSuccess(scores));
          dispatch(showSuccessNotification('score.saveSuccessfulMessage'));
        }
      )
      .catch(e => {
        dispatch(saveMonthlyScoresFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const saveAnnualScores = (data) => {
  return (dispatch) => {
    dispatch(saveAnnualScoresRequested());
    axios.post('/annual-scores', { data })
      .then(
        res => {
          dispatch(saveAnnualScoresSuccess(data));
          dispatch(showSuccessNotification('score.saveSuccessfulMessage'));
        }
      )
      .catch(e => {
        dispatch(saveAnnualScoresFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const addValidationStatus = (data) => {
  return (dispatch) => {
    dispatch(addValidationStatusSuccess(data));
  };
};

export const resetScore = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_SCORE });
  };
};

export const calculateSemesterScore = (semester, filterParams, months) => {
  return (dispatch) => {
    dispatch(calculateSemesterScoreRequested());
    const data = {
      semester,
      grade: filterParams.grade,
      classroom_id: filterParams.classroomId,
      months
    };
    axios.post('/scores/calculate-semester-score', data)
      .then(
        res => {
          dispatch(getAnnualScores(filterParams));
          dispatch(calculateSemesterScoreSuccess());
          dispatch(showSuccessNotification('score.saveSuccessfulMessage'));
        }
      )
      .catch(e => {
        dispatch(calculateSemesterScoreFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const importStudentScore = (data, successfulMessage) => {
  return (dispatch) => {
    const importInfo = {
      academic_year_id: data.academic_year_id,
      classroom_id: data.classroom_id,
      month: data.month
    };
    const formData = new FormData();
    formData.append('import_info', JSON.stringify(importInfo));
    formData.append('file', data.file);
    dispatch(importStudentScoreRequest());
    axios.post('/import/monthly-score', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(
        res => {
          dispatch(importStudentScoreSuccess(res.data));

          if (res.data.success) {
            dispatch(showSuccessNotification(successfulMessage));
          } else {
            dispatch(showErrorNotification('score.importFailed'));
          }
        }
      )
      .catch(e => {
        dispatch(importStudentScoreFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetImportScoreStates = () => {
  return (dispatch) => {
    dispatch(resetImportScoreStatesRequest());
  };
};
