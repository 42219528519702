export const status = {
  0: 'inactive',
  1: 'active'
};

export const specialEducationFlag = {
  0: 'no',
  1: 'yes'
};

export const bilingualClass = {
  1: 'no',
  2: 'english',
  3: 'french',
  4: 'other'
};

export const classType = {
  1: 'science',
  2: 'socialScience'
};
