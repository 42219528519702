import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  provinces: [],
  districts: [],
  communes: [],
  villages: []
};

const getProvincesRequest = (state) => ({
  ...state,
  isLoading: true
});

const getProvincesSuccess = (state, action) => ({
  ...state,
  provinces: action.provinces,
  isLoading: false
});

const getProvincesFail = (state) => ({
  ...state,
  isLoading: false
});

const getDistrictsRequest = (state) => ({
  ...state,
  isLoading: true
});

const getDistrictsSuccess = (state, action) => ({
  ...state,
  districts: action.districts,
  isLoading: false
});

const getDistrictsFail = (state) => ({
  ...state,
  isLoading: false
});

const getCommunesRequest = (state) => ({
  ...state,
  isLoading: true
});

const getCommunesSuccess = (state, action) => ({
  ...state,
  communes: action.communes,
  isLoading: false
});

const getCommunesFail = (state) => ({
  ...state,
  isLoading: false
});

const getVillagesRequest = (state) => ({
  ...state,
  isLoading: true
});

const getVillagesSuccess = (state, action) => ({
  ...state,
  villages: action.villages,
  isLoading: false
});

const getVillagesFail = (state) => ({
  ...state,
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GAZETTER_CODE_PROVINCES_REQUEST:
      return getProvincesRequest(state);
    case actionTypes.GET_GAZETTER_CODE_PROVINCES_SUCCESS:
      return getProvincesSuccess(state, action);
    case actionTypes.GET_GAZETTER_CODE_PROVINCES_FAIL:
      return getProvincesFail(state);

    case actionTypes.GET_GAZETTER_CODE_DISTRICTS_REQUEST:
      return getDistrictsRequest(state);
    case actionTypes.GET_GAZETTER_CODE_DISTRICTS_SUCCESS:
      return getDistrictsSuccess(state, action);
    case actionTypes.GET_GAZETTER_CODE_DISTRICTS_FAIL:
      return getDistrictsFail(state);

    case actionTypes.GET_GAZETTER_CODE_COMMUNES_REQUEST:
      return getCommunesRequest(state);
    case actionTypes.GET_GAZETTER_CODE_COMMUNES_SUCCESS:
      return getCommunesSuccess(state, action);
    case actionTypes.GET_GAZETTER_CODE_COMMUNES_FAIL:
      return getCommunesFail(state);

    case actionTypes.GET_GAZETTER_CODE_VILLAGES_REQUEST:
      return getVillagesRequest(state);
    case actionTypes.GET_GAZETTER_CODE_VILLAGES_SUCCESS:
      return getVillagesSuccess(state, action);
    case actionTypes.GET_GAZETTER_CODE_VILLAGES_FAIL:
      return getVillagesFail(state);

    default:
      return state;
  }
};

export default reducer;
