import { dangerColor } from 'assets/theme/jss/material-dashboard-pro-react';

const dateTimePickerStyle = (theme) => ({
  labelRootError: {
    color: `${dangerColor[0]} !important`
  },
  disable: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    pointerEvents: 'none'
  },
  disableInput: {
    backgroundImage: 'none !important'
  }
});

export default dateTimePickerStyle;
