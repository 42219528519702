import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/graduation/dialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import GraduationIcon from '@material-ui/icons/CardMembership';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import Select from 'components/common/Select/Select';
import { getTranslate } from 'react-localize-redux';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import * as VARIABLES from 'variables/graduation';

const useStyles = makeStyles(styles);

export default function ChangeResultDialog (props) {
  const classes = useStyles();
  const {
    isOpenDialog,
    stsId,
    firstName,
    lastName,
    result,
    reasonForChanged,
    otherReasonForChanged,
    errorResult,
    errorReasonForChanged,
    errorOtherReasonForChanged,
    onChangeHandler,
    onHandleSave,
    onHandleClose
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  let reasonChoices = '';
  if (result === VARIABLES.result.FAILED) {
    reasonChoices = [
      { label: translate(`graduation.reasonChoice.${VARIABLES.reason['3']}`), value: 3 }
    ];
  } else if (result === VARIABLES.result.PASSED) {
    reasonChoices = [
      { label: translate(`graduation.reasonChoice.${VARIABLES.reason['1']}`), value: 1 },
      { label: translate(`graduation.reasonChoice.${VARIABLES.reason['2']}`), value: 2 },
      { label: translate(`graduation.reasonChoice.${VARIABLES.reason['4']}`), value: 4 },
      { label: translate(`graduation.reasonChoice.${VARIABLES.reason['3']}`), value: 3 }
    ];
  }
  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <GraduationIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('graduation.title')}
            </h6>
            <p className={classes.dialogDescription}>
              {translate('graduation.changeResult')}
            </p>
          </div>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <h4>{translate('graduation.changeResult')}</h4>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
                md={6}
              >
                <Input
                  label={translate('common.stsId')}
                  value={stsId}
                  name="stsId"
                  helperText=""
                  disabled
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Input
                  label={translate('common.name')}
                  value={`${lastName} ${firstName}`}
                  name="name"
                  helperText=""
                  disabled
                />
              </GridItem>
              <GridItem
                xs={12}
              >
                <Select
                  require
                  label={translate('graduation.result')}
                  placeholder={translate('graduation.placeholder.result')}
                  value={result}
                  name="result"
                  onChange={e => onChangeHandler(e, 'result')}
                  choices={
                    [
                      { label: translate(`graduation.resultChoice.${VARIABLES.result['1']}`), value: 1 },
                      { label: translate(`graduation.resultChoice.${VARIABLES.result['0']}`), value: 0 }
                    ]
                  }
                  error={errorResult}
                  helperText={errorResult ? translate('common.error.require') : ''}
                />
              </GridItem>
              <GridItem
                xs={12}
              >
                <Select
                  require
                  label={translate('graduation.reasonForChanged')}
                  placeholder={translate('graduation.placeholder.reasonForChanged')}
                  value={reasonForChanged}
                  name="reasonForChanged"
                  onChange={e => onChangeHandler(e, 'reasonForChanged')}
                  choices={reasonChoices}
                  error={errorReasonForChanged}
                  helperText={errorReasonForChanged ? translate('common.error.require') : ''}
                />
              </GridItem>
              <GridItem
                xs={12}
              >
                {reasonForChanged === VARIABLES.otherReason
                  ? <Input
                    require
                    label={translate('graduation.otherReasonForChanged')}
                    placeholder={translate('graduation.placeholder.otherReasonForChanged')}
                    value={otherReasonForChanged}
                    name="otherReasonForChanged"
                    onChange={e => onChangeHandler(e, 'otherReasonForChanged')}
                    error={errorOtherReasonForChanged}
                    helperText={errorOtherReasonForChanged ? translate('common.error.require') : ''}
                  /> : ''
                }
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.actionButtons}>
          <ThemeButton
            onClick={onHandleClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          <ThemeButton
            onClick={onHandleSave}
            color="primary"
            variant="contained"
          >
            {translate('common.button.save')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ChangeResultDialog.propTypes = {
  isOpenDialog: PropTypes.bool.isRequired,
  stsId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  result: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  reasonForChanged: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  otherReasonForChanged: PropTypes.string,
  errorResult: PropTypes.bool,
  errorReasonForChanged: PropTypes.bool,
  errorOtherReasonForChanged: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onHandleSave: PropTypes.func,
  onHandleClose: PropTypes.func
};
