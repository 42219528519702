import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';
import {
  stsBlueColor,
  additionalColor
} from 'assets/sts/jss/sts-react';

const createEditUserStyle = (theme) => ({
  ...defaultFormStyle(theme),
  keyboardSwitch: {
    textTransform: 'uppercase',
    color: additionalColor,
    fontWeight: 'bold'
  },
  passwordTempCheckBox: {
    marginTop: '20px'
  },
  checkBoxPass: {
    color: `${stsBlueColor} !important`
  }
});

export default createEditUserStyle;
