import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  getStudentList,
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions';
import { batch } from 'react-redux';
import settings from 'settings';

function getStudentAccountsRequested () {
  return {
    type: actionTypes.GET_STUDENT_ACCOUNT_LIST_REQUEST
  };
}

function getStudentAccountsSuccess (accountList, filters) {
  return {
    type: actionTypes.GET_STUDENT_ACCOUNT_LIST_SUCCESS,
    accountList,
    filters
  };
}

function getStudentAccountsFail () {
  return {
    type: actionTypes.GET_STUDENT_ACCOUNT_LIST_FAIL
  };
}

function searchStudentRequested () {
  return {
    type: actionTypes.SEARCH_STUDENT_REQUEST
  };
}

function searchStudentSuccess (searchedStudent) {
  return {
    type: actionTypes.SEARCH_STUDENT_SUCCESS,
    searchedStudent
  };
}

function searchStudentFail () {
  return {
    type: actionTypes.SEARCH_STUDENT_FAIL
  };
}

const createEditAccRequest = () => {
  return {
    type: actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_REQUEST
  };
};

const createEditAccSuccess = () => {
  return {
    type: actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_SUCCESS
  };
};

const createEditAccFail = () => {
  return {
    type: actionTypes.CREATE_EDIT_STUDENT_ACCOUNT_FAIL
  };
};

function getAccRequested () {
  return {
    type: actionTypes.GET_ACC_REQUEST
  };
}

function getAccSuccess (account) {
  return {
    type: actionTypes.GET_ACC_SUCCESS,
    account
  };
}

function getAccFail () {
  return {
    type: actionTypes.GET_ACC_FAIL
  };
}

export const getStudentAccounts = (filters) => {
  return (dispatch) => {
    dispatch(getStudentAccountsRequested());
    const params = {
      sts_id: filters.stsId || undefined,
      last_name: filters.lastName || undefined,
      first_name: filters.firstName || undefined,
      limit: filters.limit !== undefined ? filters.limit : settings.rowsPerPage,
      page: filters.page !== undefined ? filters.page : settings.startPage
    };
    axios.get('/student-accounts', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta.total;
          dispatch(getStudentAccountsSuccess(data, filters));
        }
      )
      .catch(e => {
        dispatch(getStudentAccountsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const searchStudent = (params) => {
  return (dispatch) => {
    dispatch(searchStudentRequested());
    axios.get('/student-accounts/find', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(searchStudentSuccess(data));
        }
      )
      .catch(e => {
        dispatch(searchStudentFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetSearchResult = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_STUDENT_SEARCH_RESULT
    });
  };
};

export const createEditAcc = (data, id) => {
  return (dispatch, getState) => {
    dispatch(createEditAccRequest());
    if (id) {
      axios.put(`/student-accounts/${id}`, data)
        .then(
          res => {
            batch(() => {
              dispatch(createEditAccSuccess());
              dispatch(showSuccessNotification('userManagement.editSuccessfulMessage'));
            });
          }
        )
        .catch(e => {
          batch(() => {
            dispatch(createEditAccFail(e));
            dispatch(showErrorNotification(e.response.data.message));
          });
        });
    } else {
      axios.post('/student-accounts', data)
        .then(
          res => {
            const filter = getState().student.studentFilter;
            batch(() => {
              dispatch(createEditAccSuccess());
              dispatch(showSuccessNotification('userManagement.createSuccessfulMessage'));
              dispatch(resetSearchResult());
              dispatch(getStudentList(filter));
            });
          }
        )
        .catch(e => {
          batch(() => {
            dispatch(createEditAccFail(e));
            dispatch(showErrorNotification(e.response.data.message));
          });
        });
    }
  };
};

export const getAcc = (id) => {
  return (dispatch) => {
    dispatch(getAccRequested());
    axios.get(`/student-accounts/${id}`)
      .then(
        res => {
          const data = res.data.data;
          dispatch(getAccSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getAccFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
