import React from 'react';
import PropTypes from 'prop-types';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import GridContainer from 'components/theme/Grid/GridContainer';

const useStyles = makeStyles(dialogInputStyle);

export default function ChangeClassroom (props) {
  const {
    open,
    handleClose,
    studentNum,
    classrooms,
    classroom,
    grade,
    suffix,
    errorClassroom,
    onChangeHandler,
    onHandleAssign
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const classroomChoice = classrooms.filter(c => c.status === 1 && c.grade === grade && c.suffix !== suffix)
    .map(currentClass => ({
      label: `${getActiveLanguage(localize).code === 'en' ? currentClass.grade_name_en : currentClass.grade_name} ${currentClass.suffix}`,
      value: currentClass.id
    }));

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.secondGrid}>
            <h6 className={classes.confirmDialogHeader}>
              {translate('enrollment.assignNumToClassroom', { number: studentNum })}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
          >
            <Select
              label={translate('classroom.classroom')}
              placeholder={translate('enrollment.placeholder.classroom')}
              value={classroom}
              name="classroom"
              onChange={e => onChangeHandler(e, 'classroom')}
              choices={classroomChoice}
              disabled={classroomChoice.length === 0}
              require
              error={errorClassroom}
              helperText={errorClassroom ? translate('common.error.require') : ''}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={handleClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          <ThemeButton
            onClick={onHandleAssign}
            color="primary"
            variant="contained"
          >
            {translate('common.button.assign')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ChangeClassroom.propTypes = {
  open: PropTypes.bool,
  classrooms: PropTypes.array,
  studentNum: PropTypes.number,
  handleClose: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onHandleAssign: PropTypes.func,
  classroom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string,
  errorClassroom: PropTypes.bool
};
