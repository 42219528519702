const settings = {
  stage:  'live', //dev: local
  baseURL: 'https://sts.moeys.gov.kh/system/api', //dev: http://localhost/api
  keycloak: {
    realm: 'sts',
    url: 'https://sts.moeys.gov.kh/auth', //dev: http://localhost:8080/auth
    clientId: 'sts_frontend',
    initConfig: {
      onLoad: 'check-sso',
      checkLoginIframe: false
    }
  },
  idle: {
    timeout: 30, // in minute
    debounce: 2 // in second
  },
  languages: {
    defaultLanguage: 'km',
    options: [
      { name: 'ភាសាខ្មែរ', code: 'km' },
      { name: 'English', code: 'en' },
    ],
    onMissingTranslation: ({ translationId, languageCode }) => {
      return translationId;
    }
  },
  dateFormat: 'DD-MM-YYYY',
  dateTimeFormat: 'DD-MMM-YYYY HH:mm:ss',
  rowsPerPage: 10,
  startPage: 0,
};

export default settings;
