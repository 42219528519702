export const GET_GAZETTER_CODE_PROVINCES_REQUEST = 'GET_GAZETTER_CODE_PROVINCES_REQUEST';
export const GET_GAZETTER_CODE_PROVINCES_SUCCESS = 'GET_GAZETTER_CODE_PROVINCES_SUCCESS';
export const GET_GAZETTER_CODE_PROVINCES_FAIL = 'GET_GAZETTER_CODE_PROVINCES_FAIL';

export const GET_GAZETTER_CODE_DISTRICTS_REQUEST = 'GET_GAZETTER_CODE_DISTRICTS_REQUEST';
export const GET_GAZETTER_CODE_DISTRICTS_SUCCESS = 'GET_GAZETTER_CODE_DISTRICTS_SUCCESS';
export const GET_GAZETTER_CODE_DISTRICTS_FAIL = 'GET_GAZETTER_CODE_DISTRICTS_FAIL';

export const GET_GAZETTER_CODE_COMMUNES_REQUEST = 'GET_GAZETTER_CODE_COMMUNES_REQUEST';
export const GET_GAZETTER_CODE_COMMUNES_SUCCESS = 'GET_GAZETTER_CODE_COMMUNES_SUCCESS';
export const GET_GAZETTER_CODE_COMMUNES_FAIL = 'GET_GAZETTER_CODE_COMMUNES_FAIL';

export const GET_GAZETTER_CODE_VILLAGES_REQUEST = 'GET_GAZETTER_CODE_VILLAGES_REQUEST';
export const GET_GAZETTER_CODE_VILLAGES_SUCCESS = 'GET_GAZETTER_CODE_VILLAGES_SUCCESS';
export const GET_GAZETTER_CODE_VILLAGES_FAIL = 'GET_GAZETTER_CODE_VILLAGES_FAIL';
