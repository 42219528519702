import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';

const attendanceStyle = (theme) => ({
  ...defaultFormStyle(theme),
  alignCenter: {
    textAlign: 'center'
  },
  textRight: {
    float: 'right'
  },
  input: {
    fontSize: '14px',
    width: '90px'
  },
  label: {
    fontSize: '14px'
  },
  container: {
    maxWidth: '18em'
  },
  selector: {
    height: '32px'
  },
  totalRecord: {
    width: '150px',
    height: '40px',
    display: 'inline-block',
    border: '1px solid #E0E0DE',
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: '10px',
    marginLeft: '5px'
  },
  totalFemaleRecord: {
    marginLeft: '10px'
  }
});

export default attendanceStyle;
