import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import DropOut from '@material-ui/icons/CallMissedOutgoing';
import PersonIcon from '@material-ui/icons/Person';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { DROP_OUT_REASONS } from 'variables/dropOutReason';
import { REPORT_STAGES } from 'variables/report';
import Input from 'components/common/Input/Input';
import { ENROLL_STATUS } from 'variables/enrollStatus';
import { groups as GROUPS, roles as ROLES } from 'variables/user';
import { Link } from 'react-router-dom';
import * as ROUTES from 'variables/routeNames';
import cx from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import { activateUser, deleteStudent } from 'redux/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useKeycloak } from '@react-keycloak/web';
import ScoreIcon from '@material-ui/icons/Spellcheck';
import AttendanceIcon from '@material-ui/icons/HowToRegRounded';
import View from '@material-ui/icons/Visibility';

const StudentTable = (props) => {
  const {
    classes,
    count,
    rows,
    limit,
    page,
    selection,
    handleChangePage,
    handleChangeRowsPerPage,
    onEditRowHandler,
    oneHandleDropOut,
    onSelect,
    onSelectAllClick,
    changeClassroom,
    isOpenDropOut,
    handleClose,
    reportStage,
    errorReportStage,
    dropOutReason,
    errorDropOutReason,
    onChangeHandler,
    handleOpenDropOut,
    generateGotoLink,
    isCanShowAcc,
    filters,
    isPostOrArchivedAcademicYear,
    isPreparationAcademicYear
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const authProfile = useSelector(state => state.auth.profile);
  const isSelected = (id) => selection.indexOf(id) !== -1;
  const [keycloak] = useKeycloak();
  const isLoading = useSelector(state => state.student.isLoading);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const allGrades = useSelector(state => state.studentOption.grade);

  const [stsId, setStsId] = useState('');
  const [name, setName] = useState('');
  const [target, setTarget] = useState('');
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [currentTargetStatus, setCurrentTargetStatus] = useState(true);
  const [action, setAction] = useState('');
  const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
  const isSuperDataUser = authProfile && (authProfile.groups[0] === GROUPS.SUPER_DATA_USER);

  const onHandleConfirm = () => {
    switch (action) {
      case 'deactivate':
        dispatch(activateUser(target, currentTargetStatus, true, filters));
        break;
      case 'delete':
        dispatch(deleteStudent(target));
        break;
      default:
    }

    setTarget('');
    setIsConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setIsConfirmBoxOpen(false);
    setTarget('');
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading || isLoadingAcademic || isLoadingStudentOption}
    >
      <LoadablePaperContent>
        {
          isConfirmBoxOpen && (
            <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
              title={translate('common.modal.confirmTitle')}
              onConfirm={onHandleConfirm}
              onCancel={onHandelCancel}
              confirmBtnCssClass={classes.button + ' ' + classes.success}
              cancelBtnCssClass={classes.button + ' ' + classes.danger}
              confirmBtnText={translate('common.modal.yes')}
              cancelBtnText={translate('common.modal.no')}
              showCancel
            />
          )
        }
        {
          isOpenDropOut && (
            <Dialog
              open={isOpenDropOut}
              className={classes.dialogHelp}
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="md"
            >
              <DialogTitle>
                <div>
                  <div className={classes.secondGrid}>
                    <h6 className={classes.confirmDialogHeader}>
                      {translate('student.dropOutStudent')}
                    </h6>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <Input
                      label={translate('common.stsId')}
                      placeholder={translate('enrollment.placeholder.stsId')}
                      value={stsId}
                      name="stsId"
                      disabled
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <Input
                      require
                      label={translate('common.name')}
                      value={name}
                      name="name"
                      disabled
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Select
                      label={translate('student.dropOutReason')}
                      placeholder={translate('student.placeholder.dropOutReason')}
                      value={dropOutReason}
                      name="classroom"
                      onChange={e => onChangeHandler(e, 'dropOutReason')}
                      choices={[
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['1'].code}`), value: 1 },
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['2'].code}`), value: 2 },
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['3'].code}`), value: 3 },
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['4'].code}`), value: 4 },
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['5'].code}`), value: 5 },
                        { label: translate(`dropOut.dropOutReason.${DROP_OUT_REASONS['6'].code}`), value: 6 }
                      ]}
                      error={errorDropOutReason}
                      helperText={errorDropOutReason ? translate('common.error.require') : ''}
                      require
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Select
                      label={translate('enrollment.reportStage')}
                      placeholder={translate('enrollment.placeholder.reportStage')}
                      value={reportStage}
                      name="classroom"
                      onChange={e => onChangeHandler(e, 'reportStage')}
                      choices={[
                        { label: translate(`report.dropoutStage.${REPORT_STAGES['2'].code}`), value: 2 },
                        { label: translate(`report.dropoutStage.${REPORT_STAGES['3'].code}`), value: 3 }
                      ]}
                      error={errorReportStage}
                      helperText={errorReportStage ? translate('common.error.require') : ''}
                      require
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>

              <DialogActions
                className={classes.dialogAction}
              >
                <div className={classes.normalSaveClose}>
                  <ThemeButton
                    onClick={handleClose}
                    variant="contained"
                    className={classes.buttonDefault}
                    style={{ marginRight: 0 }}
                  >
                    {translate('common.button.close')}
                  </ThemeButton>

                  <ThemeButton
                    onClick={oneHandleDropOut}
                    color="primary"
                    variant="contained"
                  >
                    {translate('common.button.dropOut')}
                  </ThemeButton>
                </div>
              </DialogActions>
            </Dialog>
          )
        }
        <Card>
          <CardBody>
            <Toolbar
              className={classes.toolbar}
            >
              <Typography color="inherit" variant="subtitle1" component="div">
                {translate('common.numberSelected', { number: selection.length })}
              </Typography>

              { !isTeacher && !isSuperDataUser && !isPostOrArchivedAcademicYear &&
                <Tooltip title={translate('student.changeClassroom')}>
                  <div>
                    <IconButton aria-label="change-classroom"
                      className={classes.tableTopEdit}
                      onClick={changeClassroom}
                      disabled={!selection.length}> <Edit/>
                      <Typography>
                        {translate('student.changeClassroom')}
                      </Typography> </IconButton>
                  </div>
                </Tooltip>
              }
            </Toolbar>

            <Divider />

            <Table
              size='small'
              tableHead={[
                { id: 'stsId', isNeedSort: false, label: translate('common.stsId') },
                { id: 'name', isNeedSort: false, label: translate('common.name') },
                { id: 'gender', isNeedSort: false, label: translate('common.gender') },
                { id: 'birthDate', isNeedSort: false, label: translate('enrollment.birthDate') },
                { id: 'enrollmentStatus', label: `${translate('enrollment.enrollmentStatus')}`, isNeedSort: false },
                { id: 'classroom', isNeedSort: false, label: translate('school.classroom') },
                { id: 'scholarship', isNeedSort: false, label: translate('student.scholarship') },
                { id: 'phone', isNeedSort: false, label: translate('student.tabProfile.phone') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const isItemSelected = isSelected(row.id);
                const checkBox = (
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': row.id }}
                    onChange={e => onSelect(e, row.id)}
                    classes={{
                      checked: classes.tableCheckbox
                    }}
                  />
                );
                const editButton = !isSuperDataUser && !isPostOrArchivedAcademicYear && (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={translate('common.button.edit')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        onClick={() => onEditRowHandler(row.id)}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const viewButton = (
                  <Tooltip
                    title={translate('common.button.view')}
                    key={translate('common.button.view')}
                    className={classes.tooltip}
                  >
                    <span>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        component={Link}
                        to={`${ROUTES.VIEW_STUDENT_INFO_ROOT}/${row.id}`}
                      >
                        <View className={classes.icon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                );

                const dropOutButton = !isSuperDataUser && !isPostOrArchivedAcademicYear && (
                  <Tooltip
                    title={translate('common.button.dropOut')}
                    key={translate('common.button.dropOut')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='secondary'
                        aria-label='dropOut'
                        className={classes.tableIconButton}
                        onClick={() => {
                          handleOpenDropOut(row.id);
                          setStsId(row.stsId);
                          setName(row.name);
                        }}
                        disabled={row.enrollmentStatus === 3}
                      >
                        <DropOut className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const accountButton = isCanShowAcc && (keycloak.hasRealmRole(ROLES.MANAGE_STUDENT_ACCOUNT) || keycloak.hasRealmRole(ROLES.MANAGE_OWN_STUDENT_ACCOUNT)) && (
                  <Tooltip
                    title={translate('common.button.account')}
                    key={translate('common.button.account')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='dropOut'
                        className={classes.tableIconButton}
                        component={Link}
                        to={row.isKeycloakUser ? `${ROUTES.STUDENT_ACCOUNT_EDIT_ROOT}/${row.username}` : `${ROUTES.ERROR_PAGE_ROOT}/404`}
                        disabled={!row.isKeycloakUser}
                      >
                        <PersonIcon className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const deactivateButton = isCanShowAcc && keycloak.hasRealmRole(ROLES.MANAGE_STUDENT_ACCOUNT) && (
                  <Tooltip
                    title={row.status ? translate('common.button.deactivate') : translate('common.button.activate')}
                    key={row.status ? translate('common.button.deactivate') : translate('common.button.activate')}
                    className={classes.tooltip}
                    placement="bottom"
                  >
                    <span>
                      <IconButton
                        aria-label='deactivate'
                        className={classes.tableIconButton}
                        onClick={() => {
                          setTarget(row.userId);
                          setCurrentTargetStatus(row.status);
                          setIsConfirmBoxOpen(true);
                          setAction('deactivate');
                        }}
                        disabled={!row.isKeycloakUser}
                      >
                        {
                          row.status
                            ? (<RemoveCircleIcon className={cx({ [classes.inactive]: row.isKeycloakUser, [classes.icon]: true })} />)
                            : (<CheckCircleIcon className={cx({ [classes.successButton]: row.isKeycloakUser, [classes.icon]: true })} />)
                        }
                      </IconButton>
                    </span>
                  </Tooltip>
                );

                const scoreLink = row.enrollmentStatus !== 3 && !isPreparationAcademicYear && (
                  <Tooltip
                    title={translate('score.title')}
                    key={`view-score-${row.classroom.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label={translate('score.title')}
                        className={cx({
                          [classes.tableIconButton]: true,
                          [classes.openInNewButton]: true
                        })}
                        component={Link}
                        to={generateGotoLink(ROUTES.SCORE_MANAGEMENT, row.classroom, row.userId)}
                      >
                        <ScoreIcon className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const attendanceLink = row.enrollmentStatus !== 3 && !isPreparationAcademicYear && (
                  <Tooltip
                    title={translate('attendance.title')}
                    key={`view-attendance-${row.classroom.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label={translate('attendance.title')}
                        className={cx({
                          [classes.tableIconButton]: true,
                          [classes.openInNewButton]: true
                        })}
                        component={Link}
                        to={generateGotoLink(ROUTES.ATTENDANCE_MANAGEMENT, row.classroom, row.userId)}
                      >
                        <AttendanceIcon className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const deleteButton = !isPostOrArchivedAcademicYear && (
                  <Tooltip
                    title={translate('common.button.delete')}
                    key={translate('common.button.delete')}
                    className={classes.tooltip}
                    placement="bottom"
                  >
                    <span>
                      <IconButton
                        aria-label='delete'
                        name='delete'
                        className={classes.tableIconButton}
                        onClick={() => {
                          setTarget(row.id);
                          setIsConfirmBoxOpen(true);
                          setAction('delete');
                        }}
                      >
                        {
                          (<DeleteIcon color='error' />)
                        }
                      </IconButton>
                    </span>
                  </Tooltip>
                );

                const gradeIndex = allGrades.findIndex(checkGrade => checkGrade.number === row.classroom.grade);
                const gradeName = row.classroom.grade !== '' && allGrades && allGrades[gradeIndex]
                  ? getActiveLanguage(localize).code === 'en' ? allGrades[gradeIndex].name_en : allGrades[gradeIndex].name
                  : '';
                return [
                  checkBox,
                  row.stsId,
                  row.name,
                  row.gender === 'm' ? translate('common.genderType.m') : row.gender === 'f' ? translate('common.genderType.f') : '',
                  row.birthDate,
                  translate(`enrollment.status.${ENROLL_STATUS[row.enrollmentStatus]}`),
                  `${gradeName} ${row.classroom.suffix}`,
                  row.scholarships && row.scholarships.length > 0
                    ? row.scholarships.map(scholarship => {
                      if (allScholarships) {
                        const index = allScholarships.findIndex(
                          one => scholarship === one.id);
                        return allScholarships[index]?.type;
                      }
                      return '';
                    }).join(', ')
                    : '',
                  row.phone,
                  [editButton, viewButton, accountButton, attendanceLink, scoreLink, deactivateButton, dropOutButton, deleteButton]
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              isNeedCheckBox
              numSelected={selection.length}
              onSelectAllClick={onSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customCellClasses={['center']}
              customClassesForCells={[0, 1]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

StudentTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  isConfirmDialog: PropTypes.bool,
  onHandleConfirm: PropTypes.func,
  onHandelCancel: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array,
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  onEditRowHandler: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  oneHandleDropOut: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  selection: PropTypes.array,
  onSelect: PropTypes.func,
  changeClassroom: PropTypes.func,
  isOpenDropOut: PropTypes.bool,
  handleClose: PropTypes.func,
  reportStage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errorReportStage: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  handleOpenDropOut: PropTypes.func,
  dropOutReason: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  generateGotoLink: PropTypes.func,
  errorDropOutReason: PropTypes.bool,
  isCanShowAcc: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  isPostOrArchivedAcademicYear: PropTypes.bool,
  isPreparationAcademicYear: PropTypes.bool
};

export default StudentTable;
