import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  isLoadingGroup: false,
  groups: [],
  userList: [],
  user: undefined,
  userFilter: {}
};

const getUserGroupsRequested = (state) => ({
  ...state,
  isLoadingGroup: true
});

const getUserGroupsSuccess = (state, action) => ({
  ...state,
  groups: action.groups,
  isLoadingGroup: false
});

const getUserGroupsFail = (state) => ({
  ...state,
  isLoadingGroup: false
});

const getUserListRequested = (state) => ({
  ...state,
  isLoading: true
});

const getUserListSuccess = (state, action) => ({
  ...state,
  userList: action.userList,
  userFilter: action.userFilter,
  isLoading: false
});

const getUserListFail = (state) => ({
  ...state,
  isLoading: false
});

const getUserRequested = (state) => ({
  ...state,
  user: undefined,
  isLoading: true
});

const getUserSuccess = (state, action) => ({
  ...state,
  user: action.user,
  isLoading: false
});

const getUserFail = (state) => ({
  ...state,
  user: undefined,
  isLoading: false
});

const createEditUserRequest = state => ({
  ...state,
  isLoading: true
});

const createEditUserSuccess = state => ({
  ...state,
  isLoading: false
});

const createEditUserFail = state => ({
  ...state,
  isLoading: false
});

const activateUserRequest = state => ({
  ...state,
  isLoading: true
});

const activateUserSuccess = state => ({
  ...state,
  isLoading: false
});

const activateUserFail = state => ({
  ...state,
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_GROUPS_REQUEST:
      return getUserGroupsRequested(state);
    case actionTypes.GET_USER_GROUPS_SUCCESS:
      return getUserGroupsSuccess(state, action);
    case actionTypes.GET_USER_GROUPS_FAIL:
      return getUserGroupsFail(state);

    case actionTypes.GET_USER_LIST_REQUEST:
      return getUserListRequested(state);
    case actionTypes.GET_USER_LIST_SUCCESS:
      return getUserListSuccess(state, action);
    case actionTypes.GET_USER_LIST_FAIL:
      return getUserListFail(state);

    case actionTypes.GET_USER_REQUEST:
      return getUserRequested(state);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state);

    case actionTypes.CREATE_EDIT_USER_REQUEST:
      return createEditUserRequest(state);
    case actionTypes.CREATE_EDIT_USER_SUCCESS:
      return createEditUserSuccess(state, action);
    case actionTypes.CREATE_EDIT_USER_FAIL:
      return createEditUserFail(state);

    case actionTypes.ACTIVATE_USER_REQUEST:
      return activateUserRequest(state);
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return activateUserSuccess(state, action);
    case actionTypes.ACTIVATE_USER_FAIL:
      return activateUserFail(state);

    default:
      return state;
  }
};

export default reducer;
