import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  scholarshipFundingList: {},
  scholarshipFundingFilter: undefined,
  isLoading: false
};

function getScholarshipFundingListRequest (state) {
  return {
    ...state,
    scholarshipFundingList: {},
    scholarshipFundingFilter: undefined,
    isLoading: true
  };
}

function getScholarshipFundingListSuccess (state, action) {
  return {
    ...state,
    scholarshipFundingList: action.scholarshipFundingList,
    scholarshipFundingFilter: action.scholarshipFundingFilter,
    isLoading: false
  };
}

function getScholarshipFundingListFail (state) {
  return {
    ...state,
    scholarshipFundingList: {},
    scholarshipFundingFilter: undefined,
    isLoading: false
  };
}

const scholarshipFundingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_REQUEST:
      return getScholarshipFundingListRequest(state);
    case actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_SUCCESS:
      return getScholarshipFundingListSuccess(state, action);
    case actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_FAIL:
      return getScholarshipFundingListFail(state);
    default:
      return state;
  }
};

export default scholarshipFundingReducer;
