const styles = () => ({
  root: {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%',
    marginTop: '150px'
  },
  container: {
    textAlign: 'center'
  },
  logo: {
    width: 160
  }

});

export default styles;
