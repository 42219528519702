export const ACADEMIC_YEAR_LIST_GET_REQUEST = 'ACADEMIC_YEAR_LIST_GET_REQUEST';
export const ACADEMIC_YEAR_LIST_GET_SUCCESS = 'ACADEMIC_YEAR_LIST_GET_SUCCESS';
export const ACADEMIC_YEAR_LIST_GET_FAIL = 'ACADEMIC_YEAR_LIST_GET_FAIL';

export const ACADEMIC_YEAR_GET_REQUEST = 'ACADEMIC_YEAR_GET_REQUEST';
export const ACADEMIC_YEAR_GET_SUCCESS = 'ACADEMIC_YEAR_GET_SUCCESS';
export const ACADEMIC_YEAR_GET_FAIL = 'ACADEMIC_YEAR_GET_FAIL';

export const ACADEMIC_YEAR_CREAT_EDIT_REQUEST = 'ACADEMIC_YEAR_CREAT_EDIT_REQUEST';
export const ACADEMIC_YEAR_CREAT_EDIT_SUCCESS = 'ACADEMIC_YEAR_CREAT_EDIT_SUCCESS';
export const ACADEMIC_YEAR_CREAT_EDIT_FAIL = 'ACADEMIC_YEAR_CREAT_EDIT_FAIL';

export const ACADEMIC_YEAR_DELETE_REQUEST = 'ACADEMIC_YEAR_DELETE_REQUEST';
export const ACADEMIC_YEAR_DELETE_SUCCESS = 'ACADEMIC_YEAR_DELETE_SUCCESS';
export const ACADEMIC_YEAR_DELETE_FAIL = 'ACADEMIC_YEAR_DELETE_FAIL';

export const RESET_ACADEMIC_YEAR = 'RESET_ACADEMIC_YEAR';
