import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import styles from 'assets/sts/jss/views/dataQuery/dataQueryStyle';
import Select from 'components/common/Select/Select';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import { setDefaultParamValue } from 'utilities/indicator';
import { changeFilter } from 'redux/actions';
import {
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup
} from 'utilities/permission';

const useStyles = makeStyles(styles);

const FilterIndicator = (props) => {
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const authProfile = useSelector(state => state.auth.profile);
  const dispatch = useDispatch();
  const translate = getTranslate(localize);
  const isEn = getActiveLanguage(localize).code === 'en';
  const filter = useSelector(state => state.dataQuery.filter);
  const [result, setResult] = useState('');
  const [round, setRound] = useState('');
  const [fileType, setFileType] = useState('xlsx');

  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);

  const { templates, indicator, isDataUser, isScholarshipRound } = props;
  const template = _.find(templates, { key: indicator.template });
  const indicatorChoices = templates.map(template => ({
    label: isEn ? template.label.en : template.label.km,
    value: template.key
  }));
  const fileTypeChoices = [
    { label: translate('managementReport.file.xlsx'), value: 'xlsx' },
    { label: translate('managementReport.file.pdf'), value: 'pdf' }
  ];

  let aggregateByDefaultSelected = 'national';
  let aggregateByChoices = [
    { label: translate('school.title'), value: 'school' },
    { label: translate('classroom.grade'), value: 'grade' },
    { label: translate('classroom.title'), value: 'class' }
  ];
  if (isSchoolManagement) {
    aggregateByDefaultSelected = 'school';
  } else if (isDistrictOfficer) {
    aggregateByDefaultSelected = 'district';
    const moreChoices = [
      { label: translate('gazetteer.district'), value: 'district' },
      { label: translate('gazetteer.village'), value: 'village' }
    ];
    aggregateByChoices = moreChoices.concat(aggregateByChoices);
  } else if (isProvincialOfficer) {
    aggregateByDefaultSelected = 'province';
    const moreChoices = [
      { label: translate('gazetteer.province'), value: 'province' },
      { label: translate('gazetteer.district'), value: 'district' },
      { label: translate('gazetteer.village'), value: 'village' }
    ];
    aggregateByChoices = moreChoices.concat(aggregateByChoices);
  } else {
    const moreChoices = [
      { label: translate('common.national'), value: 'national' },
      { label: translate('gazetteer.province'), value: 'province' },
      { label: translate('gazetteer.district'), value: 'district' },
      { label: translate('gazetteer.village'), value: 'village' }
    ];
    aggregateByChoices = moreChoices.concat(aggregateByChoices);
  }

  const [aggregateBy, setAggregateBy] = useState(aggregateByDefaultSelected);

  useEffect(() => {
    if (isScholarshipRound && !round) {
      setRound('round_1');
    }
  }, [round, isScholarshipRound]);

  if (result || aggregateBy || round) {
    // apply data query filter changed
    if (result !== filter.result || aggregateBy !== filter.aggregateBy || round !== filter.round || fileType !== filter.fileType) {
      dispatch(changeFilter({ result, aggregateBy, round, fileType }));
    }
  }

  const handleSelection = (event) => {
    const newIndicator = {
      template: event.target.value,
      params: {}
    };
    setDefaultParamValue(newIndicator);

    props.onChange(newIndicator);
  };

  const handleParamChange = (event) => {
    const params = {
      ...indicator.params,
      [event.target.name]: event.target.value
    };

    const newIndicator = {
      ...indicator,
      params
    };

    props.onChange(newIndicator);
  };

  const handleRemove = () => {
    props.onRemove(indicator);
  };

  const onChangeHandler = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'round':
        setRound(value);
        break;
      case 'result':
        setResult(value);
        break;
      case 'aggregateBy':
        setAggregateBy(value);
        break;
      case 'fileType':
        setFileType(value);
        break;
      default:
    }
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={5}>
          <Select
            label={translate('managementReport.reportType')}
            value={indicator.template}
            name={indicator.template}
            onChange={handleSelection}
            choices={indicatorChoices}
            helperText=""
            disabled={!!isDataUser}
          />
        </GridItem>

        <GridItem xs={5}>
          <GridContainer>
            {
              template && template.params.map(param => {
                const gridWidth = 12 / template.params.length;
                return (
                  <GridItem xs={gridWidth} key={param.key}>
                    { param.type === 'SELECT' ? (
                      <Select
                        label={isEn ? param.label.en : param.label.km}
                        value={indicator.params[param.name] ? indicator.params[param.name] : ''}
                        name={param.name}
                        onChange={handleParamChange}
                        choices={
                          param.options ? param.options.map(option => ({
                            label: isEn ? option.title.en : option.title.km,
                            value: option.value
                          })) : []
                        }
                        helperText=""
                      />
                    ) : param.type === 'MULTI' ? (
                      <Select
                        multiple
                        label={isEn ? param.label.en : param.label.km}
                        value={indicator.params[param.name] ? indicator.params[param.name] : []}
                        name={param.name}
                        onChange={handleParamChange}
                        choices={
                          param.options ? param.options.map(option => ({
                            label: isEn ? option.title.en : option.title.km,
                            value: option.value,
                            isRenderAsParents: option.isRenderAsParents,
                            disabled: option.disabled
                          })) : []
                        }
                        helperText=""
                      />
                    ) : param.type === 'NUMBER' ? (
                      <Input
                        name={param.name}
                        label={isEn ? param.label.en : param.label.km}
                        type="number"
                        value={indicator.params[param.name]}
                        onChange={handleParamChange}
                        inputProps={{ inputProps: { min: '1' } }}
                      />
                    ) : 'Invalid Type'}
                  </GridItem>
                );
              })
            }
          </GridContainer>
        </GridItem>
        {
          isScholarshipRound && (
            <GridItem xs={5}>
              <Select
                label={translate('scholarshipRemittance.round')}
                placeholder={translate('scholarshipRemittance.placeholder.round')}
                value={round}
                name="round"
                onChange={onChangeHandler}
                choices={[
                  { label: translate('scholarshipRemittance.round.option.round_1'), value: 'round_1' },
                  { label: translate('scholarshipRemittance.round.option.round_2'), value: 'round_2' },
                  { label: translate('scholarshipRemittance.round.option.round_3'), value: 'round_3' }
                ]}
                require
              />
            </GridItem>
          )
        }
        {
          props.isGradeResult && (
            <GridItem xs={5}>
              <Select
                label={translate('managementReport.result')}
                placeholder={translate('graduation.placeholder.result')}
                value={result}
                name="result"
                onChange={onChangeHandler}
                choices={
                  [
                    { label: translate('common.semester1'), value: 1 },
                    { label: translate('common.semester2'), value: 2 },
                    { label: translate('common.annual'), value: 3 }
                  ]
                }
                require
              />
            </GridItem>
          )
        }
        {
          props.isAggregate && (
            <GridItem xs={5}>
              <Select
                label={translate('managementReport.aggregateBy')}
                value={aggregateBy}
                name="aggregateBy"
                onChange={onChangeHandler}
                choices={aggregateByChoices}
                require
              />
            </GridItem>
          )
        }
        { props.onRemove && (
          <GridItem xs={2}>
            <IconButton
              color="primary"
              aria-label="remove"
              onClick={handleRemove}
              className={classes.removeButton}
            >
              <RemoveIcon />
            </IconButton>
          </GridItem>
        )}
      </GridContainer>
      {
        props.isAggregate && (<label>&nbsp;</label>)
      }
      <GridContainer>
        <GridItem xs={5}>
          <Select
            label={translate('managementReport.fileType')}
            value={fileType}
            name="fileType"
            onChange={onChangeHandler}
            choices={fileTypeChoices}
            helperText=""
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

FilterIndicator.propTypes = {
  templates: PropTypes.array,
  indicator: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  isGradeResult: PropTypes.bool,
  isAggregate: PropTypes.bool,
  isScholarshipRound: PropTypes.bool,
  isDataUser: PropTypes.bool
};

export default FilterIndicator;
