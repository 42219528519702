import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  scholarshipList: {},
  scholarshipFilter: undefined,
  student: {},
  isLoading: false
};

function getScholarshipListRequested (state) {
  return {
    ...state,
    scholarshipList: {},
    scholarshipFilter: undefined,
    isLoading: true
  };
}

function getScholarshipListSuccess (state, action) {
  return {
    ...state,
    scholarshipList: action.scholarshipList,
    scholarshipFilter: action.scholarshipFilter,
    isLoading: false
  };
}

function getScholarshipListFail (state) {
  return {
    ...state,
    scholarshipList: {},
    scholarshipFilter: undefined,
    isLoading: false
  };
}

function getScholarshipStudentRequested (state) {
  return {
    ...state,
    student: null
  };
}

function getScholarshipStudentSuccess (state, action) {
  return {
    ...state,
    student: action.student
  };
}

function getScholarshipStudentFail (state) {
  return {
    ...state,
    student: null
  };
}

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHOLARSHIP_LIST_REQUEST:
      return getScholarshipListRequested(state);
    case actionTypes.GET_SCHOLARSHIP_LIST_SUCCESS:
      return getScholarshipListSuccess(state, action);
    case actionTypes.GET_SCHOLARSHIP_LIST_FAIL:
      return getScholarshipListFail(state);

    case actionTypes.GET_SCHOLARSHIP_STUDENT_REQUEST:
      return getScholarshipStudentRequested(state);
    case actionTypes.GET_SCHOLARSHIP_STUDENT_SUCCESS:
      return getScholarshipStudentSuccess(state, action);
    case actionTypes.GET_SCHOLARSHIP_STUDENT_FAIL:
      return getScholarshipStudentFail(state);

    default:
      return state;
  }
};

export default studentReducer;
