import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import styles from 'assets/sts/jss/views/error/errorPageStyles';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

const ErrorPage = (props) => {
  const classes = useStyles();
  const { match } = props;
  const code = match.params.code;
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>{code || 404}</h1>
          <h2 className={classes.subTitle}>{code ? translate(`common.error.${code}SubTitle`) : translate('common.error.404SubTitle')}</h2>
          <h4 className={classes.description}>
            {code ? translate(`common.error.${code}Description`) : translate('common.error.404Description')}
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
};

ErrorPage.propTypes = {
  match: PropTypes.instanceOf(Object)
};

export default ErrorPage;
