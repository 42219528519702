import * as PropTypes from 'prop-types';
import routes from 'layout/routes';
import React, { Component } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link, NavLink, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import { withCookies } from 'react-cookie';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';
import { withKeycloak } from '@react-keycloak/web';
import MediaQuery from 'react-responsive';
import Render from 'components/hoc/Render/Render';
import {
  stsBlueColor
} from 'assets/sts/jss/sts-react';
import { defaultFont } from 'assets/theme/jss/material-dashboard-pro-react';
import * as ROUTES from 'variables/routeNames';

const DRAWER_WIDTH = 250;
const styles = theme => ({
  drawer: {
    height: 'inherit'
  },
  drawerPaper: {
    position: 'relative',
    minHeight: '100%',
    height: 'inherit',
    whiteSpace: 'nowrap',
    background: stsBlueColor,
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  logo: {
    width: 40
  },
  label: {
    color: theme.palette.primary.contrastText
  },
  drawerClose: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  hide: {
    display: 'none'
  },
  menuRoot: {
    height: '100%'
  },
  topMenu: {
    overflow: 'hidden',
    paddingTop: '16px'
  },
  toolbar: {
    background: stsBlueColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mixins.toolbar,
    '&::before': {
      content: "''",
      display: 'block'
    },
    '&::after': {
      content: "''",
      display: 'block'
    }
  },
  scrollbar: {
    height: 'calc(100% - 65px)'
  },
  customLogoNormal: {
    ...defaultFont,
    textAlign: 'center',
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0px, 0, 0)',
    textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '15px',
    whiteSpace: 'nowrap',
    fontWeight: '400',
    lineHeight: '30px',
    overflow: 'hidden',
    color: `${theme.palette.primary.contrastText} !important`,
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  customLogoNormalSidebarMini: {
    display: 'none',
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)'
  },
  imgMini: {
    width: '40px !important'
  },
  customImg: {
    width: '60px',
    verticalAlign: 'middle',
    border: '0'
  },
  customLogo: {
    margin: '0',
    marginTop: '15px',
    display: 'block',
    position: 'relative',
    zIndex: '4',
    width: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)'
    }
  },
  customLogoClose: {
    margin: '0',
    marginTop: '15px',
    display: 'block',
    position: 'relative',
    zIndex: '4',
    width: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-8px',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)'
    }
  },
  customLogoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    width: '60px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-8px',
    color: 'inherit'
  },
  customWhite: {
    '&,&:hover,&:focus': {
      backgroundColor: 'rgba(238, 238, 238, 0.35) !important',
      boxSizing: 'border-box',
      borderRadius: '2px'
    }
  }
});

class Navigation extends Component {
    static propTypes = {
      open: PropTypes.bool,
      onOpen: PropTypes.func,
      onClose: PropTypes.func,
      classes: PropTypes.object,
      translate: PropTypes.func,
      keycloak: PropTypes.instanceOf(Object),
      location: PropTypes.instanceOf(Object),
      userGroup: PropTypes.string
    };

    constructor (props) {
      super(props);
      this.state = {
        left: false,
        ...this.getCollapseStates(routes)
      };
    }

    // this creates the initial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
      let initialState = {};
      routes.map(prop => {
        if (prop.collapse) {
          initialState = {
            [prop.state]: this.getCollapseInitialState(prop.views),
            ...this.getCollapseStates(prop.views),
            ...initialState
          };
        }
        return null;
      });
      return initialState;
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState (routes) {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
          return true;
        } else if (window.location.href.indexOf(routes[i].path) !== -1) {
          return true;
        }
      }
      return false;
    }

    openCollapse (collapse) {
      var st = {};
      st[collapse] = !this.state[collapse];
      this.setState(st);
    }

    activeRoute = routeName => {
      const { location } = this.props;
      if (location.pathname === routeName) {
        return 'active';
      } else if (routeName !== ROUTES.HOME) {
        return location.pathname.indexOf(routeName) === 0 ? 'active' : '';
      }
    };

    createLinks = (routes, insideCollapse = false) => {
      const { classes, open, keycloak, translate, userGroup } = this.props;
      return routes.map((prop, key) => {
        const visible = prop.sidebarVisible;
        if (prop.private) {
          if (keycloak.authenticated === false) {
            return null;
          }

          if (prop.hideFromGroups && userGroup && prop.hideFromGroups.includes(userGroup)) {
            return null;
          }

          if (prop.roles) {
            const role =
                        typeof prop.roles === 'string'
                          ? keycloak.hasRealmRole(prop.roles)
                          : prop.roles.find(role => {
                            return keycloak.hasRealmRole(role);
                          });
            if (!role) {
              return null;
            }
          }
        }
        if (visible) {
          if (prop.redirect) {
            return null;
          }
          if (prop.title) {
            return (
              <ListSubheader
                key={key}
                classes={{ root: classes.label }}
                className={classes.drawerClose}
              >
                {open ? translate(`${prop.title}.title`) : '\u00A0'}
              </ListSubheader>
            );
          }
          if (prop.collapse) {
            const st = {};
            st[prop.name] = !this.state[prop.name];
            return (
              <Render
                key={key}
                value
              >
                <MediaQuery minWidth={600}>
                  <ListItem
                    button
                    dense
                    onClick={
                      e => {
                        e.preventDefault();
                        this.setState(st);
                      }
                    }
                    className={classes.drawerClose}
                  >
                    <ListItemIcon title={translate(`${prop.name}.title`)}>
                      <prop.icon classes={{ root: classes.label }}/>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.label }}
                      primary={translate(`${prop.name}.title`)}
                    />
                    {this.state[prop.state]
                      ? (<ExpandLess classes={{ root: classes.label }}/>)
                      : (<ExpandMore classes={{ root: classes.label }}/>)}

                  </ListItem>
                </MediaQuery>
                <MediaQuery maxWidth={599}>
                  <ListItem
                    button
                    dense
                    onClick={
                      e => {
                        e.preventDefault();
                        this.setState(st);
                      }
                    }
                  >
                    <ListItemIcon title={translate(`${prop.name}.title`)}>
                      <prop.icon classes={{ root: classes.label }}/>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.label }}
                      primary={translate(`${prop.name}.title`)}
                    />
                    {this.state[prop.state]
                      ? (<ExpandLess classes={{ root: classes.label }}/>)
                      : (<ExpandMore classes={{ root: classes.label }}/>)}

                  </ListItem>
                </MediaQuery>
                <Collapse
                  in={this.state[prop.name]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {this.createLinks(prop.views, true)}
                  </List>
                </Collapse>
              </Render>
            );
          }
          const isActive = this.activeRoute(prop.path);
          return (
            <Link
              to={prop.path}
              key={key}
            >
              <MediaQuery minWidth={600}>
                <ListItem
                  button
                  dense
                  className={
                    isActive
                      ? insideCollapse
                        ? open ? `${classes.nested} ${classes.drawerClose} ${classes.customWhite}` : `${classes.drawerClose} ${classes.customWhite}`
                        : `${classes.drawerClose} ${classes.customWhite}`
                      : insideCollapse
                        ? open ? `${classes.nested} ${classes.drawerClose}` : classes.drawerClose
                        : classes.drawerClose
                  }
                >
                  <ListItemIcon title={translate(`${prop.name}.title`)}>
                    <prop.icon classes={{ root: classes.label }}/>
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.label }}
                    primary={translate(`${prop.name}.title`)}
                  />
                </ListItem>
              </MediaQuery>

              <MediaQuery maxWidth={599}>
                <ListItem
                  button
                  dense
                  className={
                    isActive
                      ? insideCollapse ? `${classNames(open && classes.nested)} ${classes.customWhite}` : classes.customWhite
                      : insideCollapse ? classNames(open && classes.nested) : ''
                  }
                >
                  <ListItemIcon title={translate(`${prop.name}.title`)}>
                    <prop.icon classes={{ root: classes.label }}/>
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.label }}
                    primary={translate(`${prop.name}.title`)}
                  />
                </ListItem>
              </MediaQuery>
            </Link>
          );
        }
        return null;
      });
    };

    render () {
      const { open, classes, translate } = this.props;

      const brand = (
        <Render
          value
        >
          <MediaQuery minWidth={600}>
            <div className={open ? classes.customLogo : classes.customLogoClose}>
              <NavLink
                to='/'
                className={classes.customLogoMini}
              >
                <img
                  src={'/images/MoEYS_LOGO_SMALL.png'}
                  alt="logo"
                  className={classes.customImg}
                />
              </NavLink>
              {
                open && (
                  <NavLink
                    to='/'
                    className={classes.customLogoNormal}
                  >
                    {translate('sts')}
                  </NavLink>
                )
              }
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={599}>
            <div className={open ? classes.customLogo : classes.customLogoClose}>
              <NavLink
                to='/'
                className={`${!open && classes.imgMini} ${classes.customLogoMini}`}
              >
                <img
                  src={'/images/MoEYS_LOGO_SMALL.png'}
                  alt="logo"
                  className={`${!open && classes.imgMini} ${classes.customImg}`}
                />
              </NavLink>
              {
                open && (
                  <NavLink
                    to='/'
                    className={classes.customLogoNormal}
                  >
                    {translate('sts')}
                  </NavLink>
                )
              }
            </div>
          </MediaQuery>
        </Render>
      );

      return (

        <Drawer
          open={open}
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
        >
          <div className={classes.toolbar}>
            {brand}
          </div>

          <PerfectScrollbar className={classes.scrollbar}>

            <div className={classes.menuRoot}>

              <List
                className={classes.topMenu}
                classes={{
                  subheader: classes.subheader
                }}
              >
                {this.createLinks(routes)}
              </List>

            </div>

          </PerfectScrollbar>

        </Drawer>
      );
    }
}

export default withKeycloak(withCookies(withRouter(withLocalize(withStyles(styles)(Navigation)))));
