import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  staffs: []
};

const getStaffsRequested = (state) => ({
  ...state,
  staffs: [],
  isLoading: true
});

const getStaffsSuccess = (state, action) => ({
  ...state,
  staffs: action.staffs,
  isLoading: false
});

const getStaffsFail = (state) => ({
  ...state,
  staffs: [],
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STAFFS_REQUEST:
      return getStaffsRequested(state);
    case actionTypes.GET_STAFFS_SUCCESS:
      return getStaffsSuccess(state, action);
    case actionTypes.GET_STAFFS_FAIL:
      return getStaffsFail(state);

    default:
      return state;
  }
};

export default reducer;
