import React, { useEffect, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import ThemeButton from 'components/theme/CustomButtons/Button';
import styles from 'assets/sts/jss/views/graduation/graduationStyle';
import GraduationFilter from 'views/Graduation/GraduationlFilter/GraduationFilter';
import GraduationTable from 'views/Graduation/GraduationlTable/GraduationTable';
import PromoteDialog from 'views/Graduation/PromoteDialog/PromoteDialog';
import ChangeResultDialog from 'views/Graduation/ChangeResultDialog/ChangeResultDialog';
import settings from 'settings';
import {
  changeResult,
  getGraduationList,
  promoteGraduation
} from 'redux/actions';
import * as VARIABLES from 'variables/graduation';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import * as moment from 'moment';

const useStyles = makeStyles(styles);

const Graduation = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const promoteToSchoolList = useSelector(state => state.graduation.promoteToSchoolList);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const graduationList = useSelector(state => state.graduation.graduationList);
  const graduationFilter = useSelector(state => state.graduation.graduationFilter);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYear, setAcademicYear] = useState(graduationFilter?.academicYear || '');
  const [school, setSchool] = useState(graduationFilter?.school || '');
  const [grade, setGrade] = useState(graduationFilter?.grade !== undefined ? graduationFilter.grade : '');
  const [suffix, setSuffix] = useState(graduationFilter?.suffix || '');
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorSchool, setErrorSchool] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorSuffix, setErrorSuffix] = useState(false);
  const [filterResult, setFilterResult] = useState('all');

  const [promoteAcademicYear, setPromoteAcademicYear] = useState('');
  const [errorPromoteAcademicYear, setErrorPromoteAcademicYear] = useState(false);
  const [promoteSchool, setPromoteSchool] = useState('');
  const [errorPromoteSchool, setErrorPromoteSchool] = useState(false);
  const [promoteGrade, setPromoteGrade] = useState('');
  const [errorPromoteGrade, setErrorPromoteGrade] = useState(false);
  const [graduationAction, setGraduationAction] = useState(null);

  const [changedId, setChangedId] = useState('');
  const [stsId, setStsId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [result, setResult] = useState('');
  const [reasonForChanged, setReasonForChanged] = useState('');
  const [otherReasonForChanged, setOtherReasonForChanged] = useState('');
  const [errorResult, setErrorResult] = useState(false);
  const [errorReasonForChanged, setErrorReasonForChanged] = useState(false);
  const [errorOtherReasonForChanged, setErrorOtherReasonForChanged] = useState(false);

  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(settings.startPage);
  const [rows, setRows] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenChangeDialog, setIsOpenChangeDialog] = useState(false);
  const [selection, setSelection] = useState([]);
  const [isRepeatDisabled, setIsRepeatDisabled] = useState(true);
  const [isPromoteDisabled, setIsPromoteDisabled] = useState(true);
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [errorProvince, setErrorProvince] = useState(false);
  const [errorDistrict, setErrorDistrict] = useState(false);

  // set title
  useEffect(() => {
    document.title = `${translate('graduation.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('graduation.pageTitle');
  }, [translate]);

  // format student data rows
  useEffect(() => {
    if (graduationList.data && graduationList.data.length > 0) {
      const showRows = graduationList.data.map(graduation => {
        const student = graduation.student;

        return {
          id: graduation.id,
          result: graduation.annual_result,
          resultChanged: graduation.annual_result_changed,
          reasonForChanged: graduation.reason_for_annual_result_changed,
          otherReason: graduation.other_reason_description,
          graduationStatus: graduation.graduation_status,
          stsId: student.emis_student_id,
          firstName: student.first_name,
          lastName: student.last_name,
          name: `${student.last_name} ${student.first_name}`,
          gender: student.gender,
          birthDate: student.birth_date,
          scoreSemester1: graduation.score_semester_1,
          scoreSemester2: graduation.score_semester_2,
          ranking: graduation.ranking
        };
      });
      setCount(graduationList && graduationList.total ? graduationList.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [graduationList]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'school':
        setSchool(value);
        break;
      case 'grade':
        setGrade(value);
        break;
      case 'suffix':
        setSuffix(value);
        break;
      case 'promoteAcademicYear':
        setPromoteAcademicYear(value);
        break;
      case 'promoteSchool':
        setPromoteSchool(value);
        break;
      case 'promoteGrade':
        setPromoteGrade(value);
        break;
      case 'result':
        setResult(value);
        break;
      case 'reasonForChanged':
        setReasonForChanged(value);
        setOtherReasonForChanged('');
        break;
      case 'otherReasonForChanged':
        setOtherReasonForChanged(value);
        break;
      case 'filterResult':
        setFilterResult(value);
        break;
      case 'province':
        setProvince(value);
        setDistrict('');
        break;
      case 'district':
        setDistrict(value);
        break;
      default:
    }
  };

  const handleFilterGraduation = e => {
    e.preventDefault();
    let canFiller = true;

    if (!academicYear) {
      canFiller = false;
      setErrorAcademicYear(true);
    } else {
      setErrorAcademicYear(false);
    }

    if (!school) {
      canFiller = false;
      setErrorSchool(true);
    } else {
      setErrorSchool(false);
    }

    if (grade === '') {
      canFiller = false;
      setErrorGrade(true);
    } else {
      setErrorGrade(false);
    }

    if (!suffix) {
      canFiller = false;
      setErrorSuffix(true);
    } else {
      setErrorSuffix(false);
    }

    if (canFiller) {
      const schoolObj = schoolList.find(s => s.id === school);
      const params = {
        academicYear,
        emisSchoolCode: schoolObj.emis_code,
        school,
        grade,
        suffix,
        filterResult,
        page: page + 1,
        limit,
        isOnline: true
      };
      dispatch(getGraduationList(params));
      setSelection([]);
    }
  };

  const handleGraduationDialogOpen = (isPromotion) => {
    const schoolObj = schoolList.find(s => s.id === school);
    if (isPromotion) {
      setGraduationAction(VARIABLES.graduationAction.promotion);
    } else {
      setPromoteGrade(grade);
      setGraduationAction(VARIABLES.graduationAction.repetition);
    }

    // get graduation list by status passed/failed
    const params = {
      academicYear,
      emisSchoolCode: schoolObj.emis_code,
      school,
      grade,
      suffix,
      filterResult: isPromotion ? VARIABLES.result.PASSED : VARIABLES.result.FAILED,
      page: 0,
      limit: 999,
      isOnline: true
    };
    dispatch(getGraduationList(params, true));
    setIsOpenDialog(true);
  };

  const handlePromotionSave = e => {
    e.preventDefault();

    let canPromote = true;
    const promoteSchoolObj = promoteToSchoolList.find(s => s.id === promoteSchool);
    let degreeGrades = promoteSchoolObj ? SCHOOL_DEGREE[promoteSchoolObj.degree.number].grade : [];
    if (degreeGrades.length < 1) {
      degreeGrades = VARIABLES.degree.grade;
    }

    if (!promoteAcademicYear) {
      canPromote = false;
      setErrorPromoteAcademicYear(true);
    } else {
      setErrorPromoteAcademicYear(false);
    }

    if (!promoteSchool) {
      canPromote = false;
      setErrorPromoteSchool(true);
    } else {
      setErrorPromoteSchool(false);
    }

    // promoteGrade mandatory and it is in school degree
    if (promoteGrade === '' || !degreeGrades.includes(promoteGrade)) {
      canPromote = false;
      setErrorPromoteGrade(true);
    } else {
      setErrorPromoteGrade(false);
    }

    if (!province) {
      canPromote = false;
      setErrorProvince(true);
    } else {
      setErrorProvince(false);
    }

    if (!district) {
      canPromote = false;
      setErrorDistrict(true);
    } else {
      setErrorDistrict(false);
    }

    if (canPromote) {
      const data = {
        grade,
        selection,
        promoteAcademicYear,
        promoteSchoolCode: promoteSchoolObj ? promoteSchoolObj.emis_code : '',
        promoteGrade,
        graduationAction,
        enrollmentDate: moment().format(settings.dateFormat),
        enrollmentBy: authProfile.id
      };

      if (graduationAction === VARIABLES.graduationAction.promotion) {
        dispatch(promoteGraduation(data, translate('graduation.promoteSuccess')));
      } else {
        dispatch(promoteGraduation(data, translate('graduation.repeatSuccess')));
      }
      setSelection([]);
      setIsOpenDialog(false);
      reset();
    }
  };

  const handleChangeResult = e => {
    e.preventDefault();
    let canChange = true;

    if (result === '') {
      canChange = false;
      setErrorResult(true);
    } else {
      setErrorResult(false);
    }

    if (!reasonForChanged) {
      canChange = false;
      setErrorReasonForChanged(true);
    } else if (reasonForChanged === VARIABLES.otherReason) {
      if (otherReasonForChanged === '') {
        canChange = false;
        setErrorOtherReasonForChanged(true);
      } else {
        setErrorOtherReasonForChanged(false);
      }
    } else {
      setErrorReasonForChanged(false);
    }
    if (canChange) {
      const data = {
        result,
        reasonForChanged,
        otherReasonForChanged
      };
      dispatch(changeResult(changedId, data, translate('graduation.changeResultSuccess')));
      setIsOpenChangeDialog(false);
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const reset = () => {
    setPromoteSchool('');
    setPromoteAcademicYear('');
    setPromoteGrade('');
    setDistrict('');
    setProvince('');
    setErrorPromoteSchool(false);
    setErrorPromoteAcademicYear(false);
    setErrorPromoteGrade(false);
    setErrorDistrict(false);
    setErrorProvince(false);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
    reset();
  };

  const columns = [
    { id: 'stsId', label: `${translate('common.stsId')}`, isNeedSort: false },
    { id: 'Name', label: `${translate('common.name')}`, isNeedSort: false },
    { id: 'gender', label: `${translate('common.gender')}`, isNeedSort: false },
    { id: 'birthDate', label: `${translate('enrollment.birthDate')}`, isNeedSort: false },
    { id: 'scoreSemester1', label: `${translate('graduation.semester1')}`, isNeedSort: false },
    { id: 'scoreSemester2', label: `${translate('graduation.semester2')}`, isNeedSort: false },
    { id: 'scoreAnnual', label: `${translate('graduation.annual')}`, isNeedSort: false },
    { id: 'rank', label: `${translate('graduation.rank')}`, isNeedSort: false },
    { id: 'result', label: `${translate('graduation.result')}`, isNeedSort: false },
    { id: 'graduated', label: `${translate('graduation.graduated')}`, isNeedSort: false },
    { id: 'reasonForChanged', label: `${translate('graduation.reasonForChanged')}`, isNeedSort: false },
    { id: 'action', isNeedSort: false, label: translate('common.action') }
  ];

  const onChangeRowHandler = (id) => {
    const selectedGraduation = rows.find(r => r.id === id);
    setChangedId(id);
    setStsId(selectedGraduation.stsId);
    setFirstName(selectedGraduation.firstName);
    setLastName(selectedGraduation.lastName);
    setReasonForChanged('');
    setOtherReasonForChanged('');

    if (selectedGraduation.resultChanged !== null) {
      setResult(selectedGraduation.resultChanged);
    } else {
      setResult(selectedGraduation.result);
    }
    setIsOpenChangeDialog(true);
  };

  // check if students already had annual result, annual result changed and graduation status
  const checkAnnualResultGraduationStatus = (newSelections) => {
    const gradList = graduationList.data.filter(graduation => newSelections.includes(graduation.id));
    const annualResult = gradList.map(result => (result.annual_result !== null && result.annual_result_changed === null) ? result.annual_result : result.annual_result_changed);
    const allPass = annualResult.every(result => result === 1);
    const allFail = annualResult.every(result => result === 0);
    const sumGradStatus = gradList.map(gradStatus => gradStatus.graduation_status)
      .reduce((sum, result) => sum + result);
    setIsPromoteDisabled(sumGradStatus !== 0 || !allPass);
    setIsRepeatDisabled(sumGradStatus !== 0 || !allFail);
  };

  const handleSelect = (event, id) => {
    let newSelections;
    if (event.target.checked) {
      newSelections = [...selection];
      newSelections.push(id);
      setSelection(newSelections);
      checkAnnualResultGraduationStatus(newSelections);
      return;
    }
    newSelections = selection.filter(selected => selected !== id);
    setSelection(newSelections);
    if (newSelections.length > 0) {
      checkAnnualResultGraduationStatus(newSelections);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelections = rows.map(row => row.id);
      setSelection(newSelections);
      checkAnnualResultGraduationStatus(newSelections);
      return;
    }
    setSelection([]);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <GraduationFilter
            classes={classes}
            isLoadingAcademic={isLoadingAcademic}
            isLoadingStudentOption={isLoadingStudentOption}
            academicYears={academicYears}
            academicYear={academicYear}
            school={school}
            setSchool={setSchool}
            grade={grade}
            suffix={suffix}
            filterResult={filterResult}
            onChangeHandler={onChangeHandler}
            errorAcademicYear={errorAcademicYear}
            errorSchool={errorSchool}
            errorGrade={errorGrade}
            errorSuffix={errorSuffix}
            handleFilterGraduation={handleFilterGraduation}
          />

          {isOpenDialog &&
            <PromoteDialog
              isOpenDialog={isOpenDialog}
              academicYear={academicYear}
              school={school}
              grade={grade}
              suffix={suffix}
              promoteAcademicYear={promoteAcademicYear}
              promoteSchool={promoteSchool}
              promoteGrade={promoteGrade}
              setPromoteGrade={setPromoteGrade}
              setPromoteSchool={setPromoteSchool}
              errorPromoteAcademicYear={errorPromoteAcademicYear}
              errorPromoteSchool={errorPromoteSchool}
              errorPromoteGrade={errorPromoteGrade}
              onChangeHandler={onChangeHandler}
              onHandleSave={handlePromotionSave}
              onHandleClose={handleClose}
              isPromoteAction={graduationAction === VARIABLES.graduationAction.promotion}
              province={province}
              district={district}
              errorProvince={errorProvince}
              errorDistrict={errorDistrict}
              selectedStudents={selection}
            />
          }

          {isOpenChangeDialog &&
            <ChangeResultDialog
              isOpenDialog={isOpenChangeDialog}
              stsId={stsId}
              firstName={firstName}
              lastName={lastName}
              result={result}
              reasonForChanged={reasonForChanged}
              otherReasonForChanged={otherReasonForChanged}
              errorResult={errorResult}
              errorReasonForChanged={errorReasonForChanged}
              errorOtherReasonForChanged={errorOtherReasonForChanged}
              onChangeHandler={onChangeHandler}
              onHandleSave={handleChangeResult}
              onHandleClose={() => setIsOpenChangeDialog(false)}
            />
          }

          <GraduationTable
            classes={classes}
            columns={columns}
            rows={rows}
            count={count}
            limit={limit}
            page={page}
            onChangeRowHandler={onChangeRowHandler}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            selection={selection}
            onSelect={handleSelect}
            onSelectAllClick={handleSelectAllClick}
          />
        </GridItem>
      </GridContainer>

      <div className={classes.actionContainer}>
        <ThemeButton
          color="primary"
          type="submit"
          onClick={() => handleGraduationDialogOpen(false)}
          disabled={isRepeatDisabled || selection.length === 0}
        >
          {translate('graduation.repeat')}
        </ThemeButton>

        <ThemeButton
          color="primary"
          type="submit"
          onClick={() => handleGraduationDialogOpen(true)}
          disabled={isPromoteDisabled || selection.length === 0}
        >
          {translate('graduation.promote')}
        </ThemeButton>
      </div>
    </>
  );
};

export default Graduation;
