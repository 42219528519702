import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';

const schoolStyle = (theme) => ({
  ...defaultListingStyle(theme),
  schoolCopyStatusContainer: {
    float: 'right',
    display: 'flex'
  },
  schoolCopyStatusChip: {
    alignSelf: 'center',
    marginRight: '5px'
  },
  circularProgress: {
    marginLeft: '10px'
  }
});

export default schoolStyle;
