import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';
import {
  defaultFont,
  grayColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const enrollmentStyle = (theme) => ({
  ...defaultListingStyle(theme),
  customTableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    fontSize: '1em',
    borderBottom: 'none',
    borderTop: `1px solid ${grayColor[5]}`,
    position: 'relative',
    padding: '3px 24px 3px 16px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '24px',
      minWidth: '32px'
    }
  },
  enrollmentDate: {
    maxWidth: '500px',
    marginBottom: '3px'
  },
  buttonContainer: {
    width: '100%'
  },
  popoverRoot: {
    minWidth: '300px',
    margin: theme.spacing(1)
  },
  popperStyle: {
    zIndex: '999999999 !important'
  },
  schoolNotFound: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  startEnrollButton: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default enrollmentStyle;
