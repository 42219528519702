import {
  goldColor
} from 'assets/theme/jss/material-dashboard-pro-react.js';

const dialogInputStyle = (theme) => ({
  avatar: {
    background: 'none'
  },
  avatarIcon: {
    width: 46,
    height: 46,
    color: goldColor[0]
  },
  body2: {
    fontSize: 16
  },
  normalSaveClose: {
    float: 'right',
    display: 'inline-block'
  },
  buttonSave: {
    float: 'right',
    color: 'white'
  },
  buttonDefault: {
    color: 'white',
    backgroundColor: '#616161',
    marginRight: 20
  },
  contentTexthelpDialog: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'normal',
    margin: 0
  },
  dialogAction: {
    display: 'unset',
    padding: '8px 24px',
    marginBottom: 20
  },
  dialogHelp: {
    minWidth: '600px',
    minHeight: '500px'
  },
  dialogHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: window.safari ? 'Roboto,sans-serif' : 'Roboto,Siemreap,sans-serif',
    fontWeight: 400,
    margin: 0
  },
  confirmDialogHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.2rem',
    fontFamily: window.safari ? 'Roboto,sans-serif' : 'Roboto,Siemreap,sans-serif',
    fontWeight: 600,
    margin: 0
  },
  dialogDescription: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'normal',
    margin: 0
  },
  dialogContent: {
    overflow: 'auto'
  },
  dialogContentLoading: {
    minWidth: '500px',
    minHeight: '500px',
    overflow: 'hidden !important',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  loading: {
    position: 'unset',
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  loadingBoxClass: {
    position: 'unset'
  },
  firstGrid: {
    width: 55,
    float: 'left',
    display: 'inline-block'
  },
  helpImage: {
    maxWidth: 910
  },
  listFilterHelp: {
    listStyle: 'none',
    padding: 0
  },
  paragraph: {
    marginBottom: theme.spacing(1),
    fontSize: 16
  },
  paragraphCustom: {
    marginBottom: theme.spacing(1),
    display: 'inline-block',
    fontSize: 16
  },
  pBottom10: {
    paddingBottom: 10
  },
  pBottom20: {
    paddingBottom: 20
  },
  pBottom5: {
    paddingBottom: 5
  },
  secondGrid: {
    float: 'left'
  },
  titleBold: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
    fontSize: 16
  },
  choicesStyles: {
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    marginBottom: '5px'
  },
  listItemText: {
    maxWidth: '500px',
    minWidth: '300px'
  },
  toggleStyle: {
    marginTop: '35px'
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2)
  }
});

export default dialogInputStyle;
