import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles/index';

const styles = () => ({
  logo: {
    margin: '0 auto',
    width: '350px',
    height: '200px',
    minHeight: '200px',
    background: 'url(/images/footer/logo.png) center center no-repeat'
  },
  link: {
    display: 'block',
    width: '100%',
    height: '100%'
  }
});

class Footer extends PureComponent {
    static propTypes = {
      loggedIn: PropTypes.bool,
      classes: PropTypes.object
    };

    render () {
      const { loggedIn, classes } = this.props;

      return (
        loggedIn
          ? <div className={classes.logo}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              href="http://www.mainlevel-consulting.de/"
            >{''}</a>
          </div> : null
      );
    }
}

const mapStateToProps = () => {
  return {
    loggedIn: false
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Footer));
