import * as actionTypes from 'redux/actionTypes/notification';

export function openNotification () {
  return {
    type: actionTypes.OPEN_NOTIFICATION
  };
}

export function closeNotification () {
  return {
    type: actionTypes.CLOSE_NOTIFICATION
  };
}

export function setAutoHideDuration (autoHideDuration) {
  return {
    type: actionTypes.SET_AUTO_HIDE_DURATION,
    autoHideDuration
  };
}

export function setActionMessage (node) {
  return {
    type: actionTypes.SET_ACTION_MESSAGE,
    actionNode: node
  };
}

export function setNotificationMessage (node, params) {
  return {
    type: actionTypes.SET_NOTIFICATION_MESSAGE,
    messageNode: node,
    messageParams: params
  };
}

export function setRequestCloseCallback (callback) {
  return {
    type: actionTypes.SET_REQUEST_CLOSE_CALLBACK,
    requestCloseCallback: callback
  };
}

export function setColor (color) {
  return {
    type: actionTypes.SET_COLOR,
    color
  };
}

export function requestCloseCallback () {
  return (dispatch, getState) => {
    dispatch(getState().notification.requestCloseCallback());
  };
}

export function showNotification (messageNode = '', messageParams = {}, actionNode = '', requestCloseCallback = closeNotification, duration = actionTypes.DEFAULT_DURATION) {
  return (dispatch) => {
    dispatch(setColor('black'));
    dispatch(setNotificationMessage(messageNode, messageParams));
    dispatch(setActionMessage(actionNode));
    dispatch(setRequestCloseCallback(requestCloseCallback));
    dispatch(setAutoHideDuration(duration));
    dispatch(openNotification());
  };
}

export function showSuccessNotification (messageNode = '', messageParams = {}, actionNode = '', requestCloseCallback = closeNotification, duration = actionTypes.DEFAULT_DURATION) {
  return (dispatch) => {
    dispatch(setColor('#43a047'));
    dispatch(setNotificationMessage(messageNode, messageParams));
    dispatch(setActionMessage(actionNode));
    dispatch(setRequestCloseCallback(requestCloseCallback));
    dispatch(setAutoHideDuration(duration));
    dispatch(openNotification());
  };
}

export function showErrorNotification (messageNode = '', messageParams = {}, actionNode = '', requestCloseCallback = closeNotification) {
  return (dispatch) => {
    dispatch(setColor('#d32f2f'));
    dispatch(setNotificationMessage(messageNode, messageParams));
    dispatch(setActionMessage(actionNode));
    dispatch(setRequestCloseCallback(requestCloseCallback));
    dispatch(setAutoHideDuration(null));
    dispatch(openNotification());
  };
}
