import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ReportIcon from '@material-ui/icons/Map';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';

import styles from 'assets/sts/jss/views/report/reportStyle';
import loadingStyle from 'assets/sts/jss/views/report/reportLoadingStyle';
import Select from 'components/common/Select/Select';
import * as ACADEMICYEARSTATUS from 'variables/academicYearStatus';
import { getAcademicYears } from 'redux/actions/academicYear';
import { getSchoolList } from 'redux/actions/school';
import * as moment from 'moment';
import settings from 'settings';
import {
  downloadReport,
  getClassroomListForFilter,
  getStudentOptions
} from 'redux/actions';
import { STS_REPORTS, REPORT_TYPES } from 'variables/report';
import { STS_STUDENT_REPORTS } from 'variables/studentReport';
import { getSchoolName } from 'utilities/helpers';
import * as SCORE from 'variables/score';
import { roles as ROLES, groups as GROUPS } from '../../variables/user';
import { allChoices, getGazetteerChoices } from 'utilities/gazetteer';
import { getProvinces, getDistricts, getCommunes, getVillages, clearUnits } from 'redux/actions/gazetteerCode';
import { LinearProgress } from '@material-ui/core';
import {
  dataUserGroup,
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup,
  teacherGroup
} from 'utilities/permission';

const useStyles = makeStyles(styles);
const useLoadingStyle = makeStyles(loadingStyle);
const ALL = 'all';
const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const Report = () => {
  const classes = useStyles();
  const reportLoadingStyle = useLoadingStyle();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const [keycloak] = useKeycloak();
  const translate = getTranslate(localize);
  const allGrades = useSelector(state => state.studentOption.grade);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const scholarships = useSelector(state => state.studentOption.scholarship);
  const disabilities = useSelector(state => state.studentOption.disability);
  const healthProblems = useSelector(state => state.studentOption.healthProblem);
  const personalProblems = useSelector(state => state.studentOption.personalProblem);
  const ethnicities = useSelector(state => state.studentOption.ethnicity);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);
  const isDownloading = useSelector(state => state.report.isDownloading);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState(allChoices(translate));
  const [districtChoices, setDistrictChoices] = useState(allChoices(translate));
  const [communeChoices, setCommuneChoices] = useState(allChoices(translate));
  const [villageChoices, setVillageChoices] = useState(allChoices(translate));
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const [months, setMonths] = useState([]);
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState(ALL);
  const [commune, setCommune] = useState(ALL);
  const [village, setVillage] = useState(ALL);
  const [selectedSchool, setSelectedSchool] = useState('');

  const [filterParams, setFilterParams] = useState({
    academicYear: '',
    school: '',
    report: '',
    grade: '',
    suffix: '',
    month: '',
    scholarship: [],
    disability: [],
    healthProblem: [],
    personalProblem: [],
    ethnicity: [],
    reportStage: ''
  });

  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorSchool, setErrorSchool] = useState(false);
  const [errorReport, setErrorReport] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorSuffix, setErrorSuffix] = useState(false);
  const [errorMonth, setErrorMonth] = useState(false);
  const [errorScholarship, setErrorScholarship] = useState(false);
  const [errorDisability, setErrorDisability] = useState(false);
  const [errorHealthProblem, setErrorHealthProblem] = useState(false);
  const [errorPersonalProblem, setErrorPersonalProblem] = useState(false);
  const [errorEthnicity, setErrorEthnicity] = useState(false);
  const [errorReportStage, setErrorReportStage] = useState(false);
  const [errorProvince, setErrorProvince] = useState(false);

  const reportInfo = STS_REPORTS[filterParams.report];
  const enableClassroomSelection = reportInfo && reportInfo.enableClassroomSelection;
  const enableMonthSelection = reportInfo && reportInfo.enableMonthSelection;
  const enableScholarshipSelection = reportInfo && reportInfo.enableScholarshipSelection;
  const enableDisabilitySelection = reportInfo && reportInfo.enableDisabilitySelection;
  const enableHealthProblemSelection = reportInfo && reportInfo.enableHealthProblemSelection;
  const enablePersonalProblemSelection = reportInfo && reportInfo.enablePersonalProblemSelection;
  const enableEthnicitySelection = reportInfo && reportInfo.enableEthnicitySelection;
  const enableReportStageSelection = reportInfo && reportInfo.enableReportStageSelection;
  const reportStages = reportInfo && reportInfo.reportStages;

  const isDataUser = dataUserGroup(authProfile);
  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);
  const isTeacher = teacherGroup(authProfile);
  const isManagementOrTeacher = isSchoolManagement || isTeacher;
  const isStudent = keycloak.hasRealmRole(ROLES.STUDENT_MANAGE_OWN_ACCOUNT);
  const showAdvancedFilter = isManagementOrTeacher !== undefined && !isManagementOrTeacher && isStudent !== undefined && !isStudent;

  const reportChoices = [];
  if (isStudent) {
    Object.entries(STS_STUDENT_REPORTS).forEach(([key, item]) => {
      reportChoices.push({
        label: translate(`report.reportChoice.${item.name}`),
        value: key
      });
    });
  } else {
    Object.entries(STS_REPORTS).forEach(([key, item]) => {
      if (
        (item.type === REPORT_TYPES.school && keycloak.hasRealmRole(ROLES.VIEW_SCHOOL_REPORT)) ||
        (item.type === REPORT_TYPES.classroom && keycloak.hasRealmRole(ROLES.VIEW_CLASSROOM_REPORT))
      ) {
        reportChoices.push({
          label: translate(`report.reportChoice.${item.name}`),
          value: key
        });
      }
    });
  }

  // set title
  useEffect(() => {
    document.title = `${translate('report.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('report.pageTitle');
  }, [translate]);

  // set default gazetteer selection
  useEffect(() => {
    if (authProfile) {
      if (authProfile.province) {
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }

      if (authProfile.district) {
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
    }
  }, [authProfile]);

  // fetch Academic Years
  useEffect(() => {
    dispatch(getAcademicYears());
    dispatch(getStudentOptions());
  }, [dispatch]);

  // set academic year choices & months
  useEffect(() => {
    if (academicYears.length > 0) {
      let defaultRunningAcademicYearId = null;
      const choice = academicYears.map(academic => {
        const academicStartDate = moment(academic.start_date, settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        if (academic.status === ACADEMICYEARSTATUS.RUNNING) {
          defaultRunningAcademicYearId = academic.id;
        }
        return {
          value: academic.id,
          label: studyYear
        };
      });
      setAcademicYearChoices(choice);

      // set default selected academicYear
      if (choice.length > 0 && filterParams.academicYear === '') {
        setFilterParams({
          ...filterParams,
          academicYear: defaultRunningAcademicYearId || choice[0].value
        });
      }

      const defaultMonths = SCORE.months.map(month => {
        return {
          value: month.value,
          label: translate('common.month.' + month.label)
        };
      });

      const additionalMonths = [
        {
          value: 13,
          label: translate('common.semester1')
        },
        {
          value: 14,
          label: translate('common.semester2')
        }
      ];

      setMonths([...defaultMonths, ...additionalMonths]);
    }
    // eslint-disable-next-line
  }, [academicYears, translate]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingGazetteer) {
      fetchProvincesData();
    }
    // eslint-disable-next-line
  }, [fetchProvincesData]);

  useEffect(() => {
    if (provinces) {
      setProvinceChoices(provinces.map(unit => {
        return {
          label: getActiveLanguage(localize).code === 'en' ? unit.name_en : unit.name,
          value: `${unit.id},${unit.code}`
        };
      }));
    }
  }, [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== '') {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
    },
    [dispatch, province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
  }, [districts, localize, translate]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(
    () => {
      if (district !== ALL) {
        dispatch(getCommunes(district));
      }
      dispatch(clearUnits('district'));
    },
    [dispatch, district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes, translate));
  }, [communes, localize, translate]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(
    () => {
      if (commune !== ALL) {
        dispatch(getVillages(commune));
      }
      dispatch(clearUnits('commune'));
    },
    [dispatch, commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages, translate));
  }, [villages, localize, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (filterParams.academicYear && (province !== '' || isManagementOrTeacher || isStudent)) {
      let gazetteer = province && province !== '' ? province.split(',')[1] : '';
      if (village && village !== 'all') {
        gazetteer = village.split(',')[1];
      } else if (commune && commune !== 'all') {
        gazetteer = commune.split(',')[1];
      } else if (district && district !== 'all') {
        gazetteer = district.split(',')[1];
      }
      const params = isManagementOrTeacher
        ? {
          academic_year_id: filterParams.academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: filterParams.academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };

      if (gazetteer) {
        params.gazetteer = gazetteer;
      }

      setSelectedSchool(null);
      setFilterParams({
        ...filterParams,
        school: '',
        grade: '',
        suffix: ''
      });
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, filterParams.academicYear, province, district, commune, village]);

  // set school choices
  useEffect(() => {
    if (filterParams.academicYear && schoolList.length > 0 && (province !== '' || isManagementOrTeacher || isStudent)) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school),
          code: school.emis_code
        };
      });
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
    }
  }, [filterParams.academicYear, schoolList, localize, translate, isManagementOrTeacher, isStudent, province]);

  // fetch classes by school
  useEffect(() => {
    if (filterParams.school && !isDataUser) {
      const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
      const param = {
        school: filterParams.school
      };
      if (isTeacher) {
        param.teacher_staff_id = authProfile.userable_id;
      }
      dispatch(getClassroomListForFilter(param, authProfile));
    }
  }, [dispatch, filterParams.school, isDataUser, authProfile]);

  // set grade choices
  useEffect(() => {
    if (filterParams.school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          choices.push({
            value: classroom.grade,
            label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
          });
        }
      });
      setGradeChoices(choices);
    } else {
      setGradeChoices([]);
    }
  }, [filterParams.school, classroomList, allGrades, localize]);

  // set suffix choices
  useEffect(() => {
    if (filterParams.grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === filterParams.grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);
    } else {
      setSuffixChoices([]);
    }
  }, [filterParams.grade, classroomList]);

  // set default school for director or teacher
  useEffect(() => {
    if (filterParams.academicYear && isManagementOrTeacher && schoolChoices.length > 0 && schoolChoices[0].value !== filterParams.school) {
      setFilterParams({ ...filterParams, school: schoolChoices[0].value });
      setSelectedSchool(schoolChoices[0]);
    }
  }, [authProfile, schoolChoices, filterParams, isManagementOrTeacher]);

  const onChangeHandler = e => {
    const { name, value } = e.target;
    setFilterParams({ ...filterParams, [name]: value });

    switch (name) {
      case 'province':
        setProvince(value);
        setDistrict(ALL);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'district':
        setDistrict(value);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'commune':
        setCommune(value);
        setVillage(ALL);
        break;
      case 'village':
        setVillage(value);
        break;
      default:
    }
  };

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setFilterParams({ ...filterParams, school: school.value });
      setSelectedSchool(school);
    } else {
      setFilterParams({
        ...filterParams,
        school: '',
        grade: '',
        suffix: ''
      });
      setSelectedSchool(null);
    }
  };

  const handleCreateReport = e => {
    e.preventDefault();
    const reportFileNameData = {
      schoolName: '',
      academicYear: '',
      grade: '',
      suffix: '',
      report: ''
    };

    let canDownload = true;
    let classroomId = null;

    if (province === '' && !isManagementOrTeacher && !isStudent) {
      canDownload = false;
      setErrorProvince(true);
    } else {
      setErrorProvince(false);
    }

    if (filterParams.academicYear === '') {
      canDownload = false;
      setErrorAcademicYear(true);
    } else {
      const academicYear = academicYearChoices.filter(yearValue => yearValue.value === filterParams.academicYear);
      reportFileNameData.academicYear = academicYear[0].label.substring(0, academicYear[0].label.indexOf('(')).trim();
      setErrorAcademicYear(false);
    }

    if (filterParams.school === '') {
      canDownload = false;
      setErrorSchool(true);
    } else {
      const school = schoolChoices.filter(schoolValue => schoolValue.value === filterParams.school);
      reportFileNameData.schoolName = school[0].label.substring(0, school[0].label.indexOf('(')).trim();
      setErrorSchool(false);
    }

    if (filterParams.report === '') {
      canDownload = false;
      setErrorReport(true);
    } else {
      const report = reportChoices.filter(reportValue => reportValue.value === filterParams.report);
      let reportName = report[0].label.substring(report[0].label.indexOf('-') + 1).trim();
      reportName = reportName.replace('តាមថ្នាក់', '');
      if (reportName !== 'បញ្ជីឈ្មោះសិស្ស') {
        reportName = reportName.replace('ឈ្មោះ', '');
      }
      if (reportName === 'បញ្ជីសិស្សមានបញ្ហាផ្ទាល់ខ្លួន') {
        reportName = 'បញ្ជីសិស្សបញ្ហាផ្ទាល់ខ្លួន';
      }
      reportFileNameData.report = reportName.trim();
      setErrorReport(false);
    }

    if (enableClassroomSelection && filterParams.grade === '') {
      canDownload = false;
      setErrorGrade(true);
    } else {
      setErrorGrade(false);
    }

    if (enableClassroomSelection && filterParams.suffix === '') {
      canDownload = false;
      setErrorSuffix(true);
    } else {
      setErrorSuffix(false);
    }

    if (enableClassroomSelection && filterParams.grade !== '' && filterParams.suffix !== '') {
      const classroom = classroomList.find(c => c.grade === filterParams.grade && c.suffix === filterParams.suffix);
      if (!classroom) {
        setErrorGrade(true);
        setErrorSuffix(true);
        canDownload = false;
      } else {
        classroomId = classroom.id;
        const grade = gradeChoices.filter(gradeValue => gradeValue.value === filterParams.grade);
        reportFileNameData.grade = grade[0].label;
        reportFileNameData.suffix = filterParams.suffix;
      }
    }

    if (enableMonthSelection && filterParams.month === '') {
      canDownload = false;
      setErrorMonth(true);
    } else {
      setErrorMonth(false);
    }

    if (enableScholarshipSelection && !filterParams.scholarship.length) {
      canDownload = false;
      setErrorScholarship(true);
    } else {
      setErrorScholarship(false);
    }

    if (enableDisabilitySelection && !filterParams.disability.length) {
      canDownload = false;
      setErrorDisability(true);
    } else {
      setErrorDisability(false);
    }

    if (enableHealthProblemSelection && !filterParams.healthProblem.length) {
      canDownload = false;
      setErrorHealthProblem(true);
    } else {
      setErrorHealthProblem(false);
    }

    if (enablePersonalProblemSelection && !filterParams.personalProblem.length) {
      canDownload = false;
      setErrorPersonalProblem(true);
    } else {
      setErrorPersonalProblem(false);
    }

    if (enableEthnicitySelection && !filterParams.ethnicity.length) {
      canDownload = false;
      setErrorEthnicity(true);
    } else {
      setErrorEthnicity(false);
    }

    if (enableReportStageSelection && !filterParams.reportStage) {
      canDownload = false;
      setErrorReportStage(true);
    } else {
      setErrorReportStage(false);
    }

    if (canDownload) {
      dispatch(downloadReport(filterParams, reportFileNameData, classroomId, getActiveLanguage(localize).code));
    }
  };

  return (
    <Card className={classes.cardFilter}>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <ReportIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate('report.pageTitle')}</h4>
      </CardHeader>
      <CardBody>
        <div
          className={classes.gridContainerStyle}
        >
          <GridContainer>
            {
              showAdvancedFilter && (
                <>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.province')}
                      placeholder={translate('gazetteer.placeholder.province')}
                      value={province}
                      name="province"
                      onChange={onChangeHandler}
                      choices={provinceChoices}
                      require
                      error={errorProvince}
                      disabled={isLoadingGazetteer || isProvincialOfficer || isDistrictOfficer}
                      helperText={errorProvince ? translate('common.error.require') : ''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.district')}
                      placeholder={translate('gazetteer.placeholder.district')}
                      value={district}
                      name="district"
                      onChange={onChangeHandler}
                      choices={districtChoices}
                      disabled={isLoadingGazetteer || districts.length === 0 || isDistrictOfficer}
                      helperText=""
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.commune')}
                      placeholder={translate('gazetteer.placeholder.commune')}
                      value={commune}
                      name="commune"
                      onChange={onChangeHandler}
                      choices={communeChoices}
                      disabled={isLoadingGazetteer || communes.length === 0}
                      helperText=""
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.village')}
                      placeholder={translate('gazetteer.placeholder.village')}
                      value={village}
                      name="village"
                      onChange={onChangeHandler}
                      choices={villageChoices}
                      disabled={isLoadingGazetteer || villages.length === 0}
                      helperText=""
                    />
                  </GridItem>
                </>
              )
            }

            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('school.academicYear')}
                placeholder={translate('school.placeholder.academicYear')}
                value={filterParams.academicYear}
                name="academicYear"
                onChange={onChangeHandler}
                choices={academicYearChoices}
                disabled={academicYearChoices.length === 0}
                require
                error={errorAcademicYear}
                helperText={errorAcademicYear ? translate('common.error.require') : ''}
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Autocomplete
                value={selectedSchool}
                name="school"
                require
                label={translate('classroom.school')}
                placeholder={translate('classroom.placeholder.school')}
                options={schoolChoices}
                filterOptions={schoolFilterOptions}
                onChange={onSchoolCodeChangeHandler}
                error={errorSchool}
                helperText={errorSchool ? translate('common.error.require') : ''}
                noOptionsText={translate('report.noSchoolChoice')}
                disabled={schoolChoices.length === 0 || isManagementOrTeacher}
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('report.report')}
                placeholder={translate('report.placeholder.report')}
                value={filterParams.report}
                name="report"
                onChange={onChangeHandler}
                choices={reportChoices}
                require
                error={errorReport}
                helperText={errorReport ? translate('common.error.require') : ''}
              />
            </GridItem>

            { enableClassroomSelection &&
              <GridItem
                xs={12}
                md={6}
              >
                <GridContainer>
                  <GridItem
                    xs={6}
                  >
                    <Select
                      label={translate('enrollment.grade')}
                      placeholder={translate('enrollment.placeholder.grade')}
                      value={filterParams.grade}
                      name="grade"
                      onChange={e => onChangeHandler(e, 'grade')}
                      choices={gradeChoices}
                      disabled={gradeChoices.length === 0}
                      require
                      error={errorGrade}
                      helperText={errorGrade ? translate('common.error.require') : ''}
                    />
                  </GridItem>

                  <GridItem
                    xs={6}
                  >
                    <Select
                      label={translate('common.suffix')}
                      placeholder={translate('graduation.placeholder.suffix')}
                      value={filterParams.suffix}
                      name="suffix"
                      onChange={e => onChangeHandler(e, 'suffix')}
                      choices={suffixChoices}
                      disabled={suffixChoices.length === 0}
                      require
                      error={errorSuffix}
                      helperText={errorSuffix ? translate('common.error.require') : ''}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            }

            { enableMonthSelection &&
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('score.monthOrSemester')}
                  placeholder={translate('graduation.placeholder.result')}
                  value={filterParams.month}
                  name="month"
                  onChange={onChangeHandler}
                  choices={months}
                  disabled={months.length === 0}
                  require
                  error={errorMonth}
                  helperText={errorMonth ? translate('common.error.require') : ''}
                />
              </GridItem>
            }

            { enableScholarshipSelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                multiple
                label={translate('enrollment.scholarship')}
                placeholder={translate('enrollment.placeholder.scholarship')}
                value={filterParams.scholarship}
                name="scholarship"
                onChange={onChangeHandler}
                choices={scholarships.map(scholarship => ({
                  label: scholarship.type,
                  value: scholarship.id
                }))}
                disabled={scholarships.length === 0}
                require
                error={errorScholarship}
                helperText={errorScholarship ? translate('common.error.require') : ''}
              />
            </GridItem>
            }

            { enableDisabilitySelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                multiple
                label={translate('enrollment.disability')}
                placeholder={translate('enrollment.placeholder.disability')}
                value={filterParams.disability}
                name="disability"
                onChange={onChangeHandler}
                choices={disabilities.map(disability => ({
                  label: getActiveLanguage(localize).code === 'en' ? disability.name_en : disability.name,
                  value: disability.id,
                  isRenderAsParents: disability.parent_id === 0 && disability.all_children.length === 0,
                  disabled: disability.all_children.length !== 0
                }))}
                disabled={disabilities.length === 0}
                require
                error={errorDisability}
                helperText={errorDisability ? translate('common.error.require') : ''}
              />
            </GridItem>
            }

            { enableHealthProblemSelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                multiple
                label={translate('enrollment.healthProblem')}
                placeholder={translate('enrollment.placeholder.healthProblem')}
                value={filterParams.healthProblem}
                name="healthProblem"
                onChange={onChangeHandler}
                choices={healthProblems.map(healthProblem => ({
                  label: getActiveLanguage(localize).code === 'en' ? healthProblem.name_en : healthProblem.name,
                  value: healthProblem.id
                }))}
                disabled={healthProblems.length === 0}
                require
                error={errorHealthProblem}
                helperText={errorHealthProblem ? translate('common.error.require') : ''}
              />
            </GridItem>
            }

            { enablePersonalProblemSelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                multiple
                label={translate('enrollment.personalProblem')}
                placeholder={translate('enrollment.placeholder.personalProblem')}
                value={filterParams.personalProblem}
                name="personalProblem"
                onChange={onChangeHandler}
                choices={personalProblems.map(personalProblem => ({
                  label: getActiveLanguage(localize).code === 'en' ? personalProblem.name_en : personalProblem.name,
                  value: personalProblem.id
                }))}
                disabled={personalProblems.length === 0}
                require
                error={errorPersonalProblem}
                helperText={errorPersonalProblem ? translate('common.error.require') : ''}
              />
            </GridItem>
            }

            { enableEthnicitySelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                multiple
                label={translate('enrollment.ethnicity')}
                placeholder={translate('enrollment.placeholder.ethnicity')}
                value={filterParams.ethnicity}
                name="ethnicity"
                onChange={onChangeHandler}
                choices={ethnicities.map(ethnicity => ({
                  label: getActiveLanguage(localize).code === 'en' ? ethnicity.name_en : ethnicity.name,
                  value: ethnicity.id
                }))}
                disabled={ethnicities.length === 0}
                require
                error={errorEthnicity}
                helperText={errorEthnicity ? translate('common.error.require') : ''}
              />
            </GridItem>
            }

            { enableReportStageSelection &&
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('enrollment.reportStage')}
                placeholder={translate('enrollment.placeholder.reportStage')}
                value={filterParams.reportStage}
                name="reportStage"
                onChange={onChangeHandler}
                choices={reportStages.map(reportStage => ({
                  label: translate(reportStage.label),
                  value: reportStage.value
                }))}
                disabled={reportStages.length === 0}
                require
                error={errorReportStage}
                helperText={errorReportStage ? translate('common.error.require') : ''}
              />
            </GridItem>
            }
          </GridContainer>

          <div>
            <ThemeButton
              color="primary"
              type="submit"
              onClick={handleCreateReport}
            >
              {translate('report.createReport')}
            </ThemeButton>
          </div>
        </div>
        {
          isDownloading && (
            <div className={reportLoadingStyle.loadingStyle}>
              <LinearProgress className={reportLoadingStyle.barStyle}/>
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};

export default Report;
