import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getTransferListRequested () {
  return {
    type: actionTypes.GET_TRANSFER_LIST_REQUEST
  };
}

function getTransferListSuccess (transferList, transferFilter) {
  return {
    type: actionTypes.GET_TRANSFER_LIST_SUCCESS,
    transferList,
    transferFilter
  };
}

function getTransferListFail () {
  return {
    type: actionTypes.GET_TRANSFER_LIST_FAIL
  };
}

function getTransferStudentRequested () {
  return {
    type: actionTypes.GET_TRANSFER_STUDENT_REQUEST
  };
}

function getTransferStudentSuccess (student) {
  return {
    type: actionTypes.GET_TRANSFER_STUDENT_SUCCESS,
    student
  };
}

function getTransferStudentFail () {
  return {
    type: actionTypes.GET_TRANSFER_STUDENT_FAIL
  };
}

function transferStudentRequested () {
  return {
    type: actionTypes.TRANSFER_STUDENT_REQUEST
  };
}

function transferStudentSuccess () {
  return {
    type: actionTypes.TRANSFER_STUDENT_SUCCESS
  };
}

function transferStudentFail () {
  return {
    type: actionTypes.TRANSFER_STUDENT_FAIL
  };
}

function getTransferReviewRequested () {
  return {
    type: actionTypes.GET_TRANSFER_REVIEW_REQUEST
  };
}

function getTransferReviewSuccess (transfer) {
  return {
    type: actionTypes.GET_TRANSFER_REVIEW_SUCCESS,
    transfer
  };
}

function getTransferReviewFail () {
  return {
    type: actionTypes.GET_TRANSFER_REVIEW_FAIL
  };
}

function approveTransferRequested () {
  return {
    type: actionTypes.APPROVE_TRANSFER_REQUEST
  };
}

function approveTransferSuccess (transfer) {
  return {
    type: actionTypes.APPROVE_TRANSFER_SUCCESS,
    transfer
  };
}

function approveTransferFail () {
  return {
    type: actionTypes.APPROVE_TRANSFER_FAIL
  };
}

function getTransferToSchoolListRequested () {
  return {
    type: actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_REQUEST
  };
}

function getTransferToSchoolListSuccess (transferToSchoolList) {
  return {
    type: actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_SUCCESS,
    transferToSchoolList
  };
}

function getTransferToSchoolListFail () {
  return {
    type: actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_FAIL
  };
}

export const getTransferList = (rawParams) => {
  return (dispatch) => {
    const params = {
      academic_year_id: rawParams.academicYear,
      emis_school_code: rawParams.emisSchoolCode,
      transfer: rawParams.transfer,
      grade: rawParams.grade && rawParams.grade !== 'all' ? rawParams.grade : undefined,
      page: rawParams.page,
      limit: rawParams.limit
    };
    if (rawParams.suffix) {
      params.suffix = rawParams.suffix;
    }

    dispatch(getTransferListRequested());
    axios.get('/transfer', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          dispatch(getTransferListSuccess(data, rawParams));
        }
      )
      .catch(e => {
        dispatch(getTransferListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getTransferStudent = (school, stsId, failedMessage) => {
  return (dispatch) => {
    dispatch(getTransferStudentRequested());
    axios.get(`/enrollments/get-by-student?academic_year_school_id=${school}&emis_student_id=${stsId}`)
      .then(
        res => {
          const student = res.data.data;
          dispatch(getTransferStudentSuccess(student));
        }
      )
      .catch(e => {
        dispatch(getTransferStudentFail(e));
        dispatch(showErrorNotification(failedMessage));
      });
  };
};

export const resetTransferStudent = () => {
  return {
    type: actionTypes.RESET_TRANSFER_STUDENT
  };
};

export const submitTransferStudent = (rawParams, successfulMessage) => {
  return (dispatch) => {
    dispatch(transferStudentRequested());
    const params = {
      enrollment_id: rawParams.enrollmentId,
      emis_school_code: rawParams.transferSchoolCode,
      report_stage: rawParams.transferReportStage,
      transfer_reason: rawParams.reason,
      school_id: rawParams.transferSchool,
      grade: rawParams.transferGrade
    };
    axios.post('/transfer', params)
      .then(
        res => {
          const student = res.data.data;
          dispatch(transferStudentSuccess(student));
          dispatch(showSuccessNotification(successfulMessage));
        }
      )
      .catch(e => {
        dispatch(transferStudentFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getTransferReview = (id) => {
  return (dispatch) => {
    dispatch(getTransferReviewRequested());
    axios.get(`/transfer/${id}`)
      .then(
        res => {
          const transfer = res.data.data;
          dispatch(getTransferReviewSuccess(transfer));
        }
      )
      .catch(e => {
        dispatch(getTransferReviewFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const approveTransfer = (data, successfulMessage) => {
  return (dispatch, getState) => {
    dispatch(approveTransferRequested());
    axios.post('/enrollments/assign-classroom', data)
      .then(
        res => {
          dispatch(approveTransferSuccess());
          dispatch(showSuccessNotification(successfulMessage));

          const transferFilter = getState().transfer.transferFilter;
          dispatch(getTransferList(transferFilter));
        }
      )
      .catch(e => {
        dispatch(approveTransferFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getTransferToSchoolList = (params) => {
  return (dispatch) => {
    dispatch(getTransferToSchoolListRequested());
    axios.get('/schools', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta.total;
          dispatch(getTransferToSchoolListSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getTransferToSchoolListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
