import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';
import tableStyle from 'assets/theme/jss/material-dashboard-pro-react/components/tableStyle';
import { goldColor, grayColor } from 'assets/theme/jss/material-dashboard-pro-react.js';

const dataQueryStyle = (theme) => ({
  ...defaultListingStyle(theme),
  ...tableStyle(theme),

  cardBody: {
    marginTop: theme.spacing(1)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filters: {
    marginBottom: theme.spacing(2)
  },
  chip: {
    backgroundColor: goldColor[1],
    margin: 4,
    marginBottom: 0,
    height: 30,
    overflow: 'hidden',
    maxWidth: '100%'
  },
  border: {
    border: `1px solid ${grayColor[8]}`
  },
  noBorder: {
    borderBottom: 'none'
  },
  removeButton: {
    marginTop: theme.spacing(2)
  }
});

export default dataQueryStyle;
