import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import customStyles from 'assets/sts/jss/components/InputStyle';
import styles from 'assets/theme/jss/material-dashboard-pro-react/components/customInputStyle';

const finalStyle = (theme) => (
  {
    ...customStyles(theme),
    ...styles
  }
);

export default function customInput (props) {
  const classes = makeStyles(finalStyle)();
  const {
    name,
    value,
    onChange,
    placeholder,
    label,
    className,
    labelClassName,
    disabled,
    helperText,
    error,
    require,
    inputProps,
    ...rest
  } = props;

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underline]: true
  });
  const inputClasses = classNames({
    [classes.input]: true
  });
  const helpTextClasses = classNames({
    [classes.labelRootError]: error
  });
  const divClass = classNames({
    [className]: true,
    [classes.divClass]: true
  });

  return (
    <div className={divClass}>
      <InputLabel
        required={require}
        htmlFor={name}
        className={`${classNames({
                    [labelClassName]: true
                })}`}
      >
        {label}
      </InputLabel>
      <FormControl
        fullWidth
        htmlFor={name}
        error={error}
        className={classes.formControl}
      >
        <Input
          value={value}
          onChange={onChange}
          classes={{
            input: inputClasses,
            disabled: classes.disabled,
            underline: underlineClasses
          }}
          id={name}
          name={name}
          inputProps={{ autoComplete: 'new-password' }}
          {...inputProps}
          disabled={disabled}
          placeholder={placeholder}
          {...rest}
        />
        {helperText !== undefined ? (
          <FormHelperText id={`${name}-text`} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

customInput.propTypes = {
  require: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object)
};
