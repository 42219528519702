import { saveAs } from 'file-saver';
import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions/notification';
import * as actionTypes from '../actionTypes';

function getExportHistoryRequested () {
  return {
    type: actionTypes.GET_EXPORT_HISTORY_REQUEST
  };
}

function getExportHistorySuccess (exportHistoryList) {
  return {
    type: actionTypes.GET_EXPORT_HISTORY_SUCCESS,
    exportHistoryList
  };
}

function getExportHistoryFail () {
  return {
    type: actionTypes.GET_EXPORT_HISTORY_FAIL
  };
}

export const getExportHistoryList = () => {
  return (dispatch) => {
    dispatch(getExportHistoryRequested());
    axios.get('/export_history')
      .then(
        res => {
          const data = res.data.data;
          dispatch(getExportHistorySuccess(data));
        }
      )
      .catch(e => {
        dispatch(getExportHistoryFail());
        dispatch(showErrorNotification('managementReport.error.request'));
      });
  };
};

export const download = (exportHistory) => {
  return (dispatch) => {
    axios.get(`/export_history/file/${exportHistory.id}`, { responseType: 'blob' })
      .then(res => {
        const fileType = exportHistory.file_type || 'xlsx';
        saveAs(res.data, `${exportHistory.name}.${fileType}`);
      })
      .catch(e => {
        dispatch(showErrorNotification('report.error.noReportType'));
      });
  };
};
