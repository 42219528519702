export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const ABOUT = '/about';
export const CONTACT = '/contact';

// Management

export const ACADEMIC_YEAR = '/academic-year';
export const ACADEMIC_YEAR_NEW = `${ACADEMIC_YEAR}/new`;
export const ACADEMIC_YEAR_EDIT_ROOT = `${ACADEMIC_YEAR}/edit`;
export const ACADEMIC_YEAR_EDIT = `${ACADEMIC_YEAR_EDIT_ROOT}/:academicYearId`;

export const NATIONAL_PUBLIC_HOLIDAY = '/holiday';

export const SCHOOL = '/school';
export const SCHOOL_NEW = '/school/new';
export const SCHOOL_VIEW_ROOT = '/school/view';
export const SCHOOL_VIEW = `${SCHOOL_VIEW_ROOT}/:schoolId`;
export const SCHOOL_EDIT_ROOT = '/school/edit';
export const SCHOOL_EDIT = `${SCHOOL_EDIT_ROOT}/:schoolId`;

export const STUDENT = '/student';
export const STUDENT_EDIT = `${STUDENT}/:studentId`;
export const STUDENT_CLASSROOM_ROOT = '/student/classroom';
export const STUDENT_CLASSROOM = `${STUDENT_CLASSROOM_ROOT}/:classroomId`;
export const VIEW_STUDENT = `/profile${STUDENT}`;
export const VIEW_STUDENT_INFO_ROOT = '/student/view';
export const VIEW_STUDENT_INFO = `${VIEW_STUDENT_INFO_ROOT}/:studentId`;

export const CLASSROOM = '/classroom';
export const CLASSROOM_SCHOOL_ROOT = '/classroom/school';
export const CLASSROOM_SCHOOL = `${CLASSROOM_SCHOOL_ROOT}/:schoolId`;
export const CLASSROOM_NEW = `${CLASSROOM_SCHOOL}/new`;
export const CLASSROOM_VIEW_ROOT = '/classroom/view';
export const CLASSROOM_VIEW = `${CLASSROOM_VIEW_ROOT}/:classroomId`;
export const CLASSROOM_EDIT_ROOT = `${CLASSROOM}/edit`;
export const CLASSROOM_EDIT = `${CLASSROOM_EDIT_ROOT}/:classroomId`;

export const GRADUATION = '/graduation';
export const TRANSFER = '/transfer';
export const ENROLLMENT = '/enrollment';

export const SCHOLARSHIP = '/scholarship/student';
export const SCHOLARSHIP_REMITTANCE = '/scholarship/remittance';
export const SCHOLARSHIP_FUNDING = '/scholarship/funding';

export const SCORE = '/score';
export const SCORE_MANAGEMENT = '/score-management';

export const ATTENDANCE = '/attendance';
export const ATTENDANCE_MANAGEMENT = '/attendance-management';

// STS Data
export const REPORT = '/report';
export const MANAGEMENT_REPORT = '/management-report';

// Administration
export const USER = '/user';
export const USER_NEW = '/user/new';
export const USER_EDIT_ROOT = `${USER}/edit`;
export const USER_EDIT = `${USER_EDIT_ROOT}/:userId`;
export const STUDENT_ACCOUNT = '/account/student';
export const STUDENT_ACCOUNT_EDIT_ROOT = `${STUDENT_ACCOUNT}/edit`;
export const STUDENT_ACCOUNT_EDIT = `${STUDENT_ACCOUNT_EDIT_ROOT}/:studentAccId`;

// other
export const ERRORS = '/errors';
export const ERROR_PAGE_ROOT = '/error-page';
export const ERROR_PAGE = `${ERROR_PAGE_ROOT}/:code`;
