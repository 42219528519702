import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  graduationFilter: undefined,
  graduationList: {},
  graduationListByStatus: {},
  promoteToSchoolList: []
};

const getGraduationListRequested = (state) => ({
  ...state,
  isLoading: true,
  graduationFilter: undefined,
  graduationList: {},
  graduationListByStatus: {}
});

const getGraduationListByStatusRequested = (state) => ({
  ...state,
  isLoading: true,
  graduationListByStatus: {}
});

const getGraduationListSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  graduationFilter: action.graduationFilter,
  graduationList: action.graduationList
});

const getGraduationListByStatusSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  graduationListByStatus: action.graduationListByStatus
});

const getGraduationListFail = (state) => ({
  ...state,
  isLoading: false,
  graduationFilter: undefined,
  graduationList: {},
  graduationListByStatus: {}
});

const getPromoteToSchoolListRequested = (state) => ({
  ...state
});

const getPromoteToSchoolListSuccess = (state, action) => ({
  ...state,
  promoteToSchoolList: action.promoteToSchoolList
});

const getPromoteToSchoolListFail = (state) => ({
  ...state
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GRADUATION_LIST_REQUEST:
      return getGraduationListRequested(state);
    case actionTypes.GET_GRADUATION_LIST_BY_STATUS_REQUEST:
      return getGraduationListByStatusRequested(state);
    case actionTypes.GET_GRADUATION_LIST_SUCCESS:
      return getGraduationListSuccess(state, action);
    case actionTypes.GET_GRADUATION_LIST_BY_STATUS_SUCCESS:
      return getGraduationListByStatusSuccess(state, action);
    case actionTypes.GET_GRADUATION_LIST_FAIL:
      return getGraduationListFail(state);

    case actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_REQUEST:
      return getPromoteToSchoolListRequested(state);
    case actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_SUCCESS:
      return getPromoteToSchoolListSuccess(state, action);
    case actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_FAIL:
      return getPromoteToSchoolListFail(state);

    default:
      return state;
  }
};

export default reducer;
