import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showSuccessNotification,
  showErrorNotification
} from 'redux/actions/notification';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';

function createEditAcademicRequested () {
  return {
    type: actionTypes.ACADEMIC_YEAR_CREAT_EDIT_REQUEST
  };
}

function createEditAcademicSuccess (payload) {
  return {
    type: actionTypes.ACADEMIC_YEAR_CREAT_EDIT_SUCCESS,
    payload
  };
}

function createEditAcademicFail () {
  return {
    type: actionTypes.ACADEMIC_YEAR_CREAT_EDIT_FAIL
  };
}

function getAcademicListRequested () {
  return {
    type: actionTypes.ACADEMIC_YEAR_LIST_GET_REQUEST
  };
}

function getAcademicListSuccess (academicYears) {
  return {
    type: actionTypes.ACADEMIC_YEAR_LIST_GET_SUCCESS,
    academicYears
  };
}

function getAcademicListFail () {
  return {
    type: actionTypes.ACADEMIC_YEAR_LIST_GET_FAIL
  };
}

function getAcademicRequested () {
  return {
    type: actionTypes.ACADEMIC_YEAR_GET_REQUEST
  };
}

function getAcademicSuccess (academicYearDetail) {
  return {
    type: actionTypes.ACADEMIC_YEAR_GET_SUCCESS,
    academicYearDetail
  };
}

function getAcademicFail () {
  return {
    type: actionTypes.ACADEMIC_YEAR_GET_FAIL
  };
}

function deleteAcademicRequested () {
  return {
    type: actionTypes.ACADEMIC_YEAR_DELETE_REQUEST
  };
}

function deleteAcademicSuccess () {
  return {
    type: actionTypes.ACADEMIC_YEAR_DELETE_SUCCESS
  };
}

function deleteAcademicFail () {
  return {
    type: actionTypes.ACADEMIC_YEAR_DELETE_FAIL
  };
}

export const createEditAcademicYear = (data, id = false, successfulMessage) => {
  return (dispatch) => {
    dispatch(createEditAcademicRequested());
    if (id) {
      axios.put(`/academic-years/${id}`, data)
        .then(
          res => {
            dispatch(createEditAcademicSuccess(res.data.data));
            dispatch(showSuccessNotification(successfulMessage));
            dispatch(getAcademicYear(id));
          }
        )
        .catch(e => {
          dispatch(createEditAcademicFail(e));
          let params = {};
          if (e.response.data.message === 'academicYear.status.conflict') {
            params = {
              status: data.status.toUpperCase()
            };
          }
          dispatch(showErrorNotification(e.response.data.message, params));
          dispatch(getAcademicYear(id));
        });
    } else {
      axios.post('/academic-years', data)
        .then(
          res => {
            dispatch(createEditAcademicSuccess(res.data.data));
            dispatch(showSuccessNotification(successfulMessage));
            history.push(ROUTES.ACADEMIC_YEAR);
          }
        )
        .catch(e => {
          dispatch(createEditAcademicFail(e));
          dispatch(showErrorNotification(e.message));
        });
    }
  };
};

export const getAcademicYears = (params) => {
  return (dispatch) => {
    dispatch(getAcademicListRequested());
    axios.get('/academic-years', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta ? res.data.meta.total : 0;
          dispatch(getAcademicListSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getAcademicListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getAcademicYear = (id) => {
  return (dispatch) => {
    dispatch(getAcademicRequested());
    axios.get(`/academic-years/${id}`)
      .then(
        res => {
          dispatch(getAcademicSuccess(res.data.data));
        }
      )
      .catch(e => {
        dispatch(getAcademicFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const deleteAcademicYear = (id, successfulMessage) => {
  return (dispatch) => {
    dispatch(deleteAcademicRequested());
    axios.delete(`/academic-years/${id}`)
      .then(
        res => {
          dispatch(deleteAcademicSuccess());
          dispatch(showSuccessNotification(successfulMessage));
          dispatch(getAcademicYears());
        }
      )
      .catch(e => {
        dispatch(deleteAcademicFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetAcademicYear = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_ACADEMIC_YEAR });
  };
};

export const getQueueStatus = (params) => {
  return () => {
    return axios.get('/get-queue-status', { params });
  };
};
