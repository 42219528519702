import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardHeader from 'components/theme/Card/CardHeader';

import styles from 'assets/sts/jss/views/dataQuery/dataQueryStyle';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { INDICATOR_TEMPLATES } from 'variables/indicator';
import FilterIndicator from './FilterIndicator';
import {
  changeFilter, download, getExportHistoryList,
  showErrorNotification
} from 'redux/actions';
import { setDefaultParamValue } from 'utilities/indicator';
import axios from 'utilities/httpService';
import { dataUserGroup } from 'utilities/permission';
import BackgroundTaskProgressDialog from './BackgroundTaskProgressDialog';

const useStyles = makeStyles(styles);

const ReportTypeCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const filter = useSelector(state => state.dataQuery.filter);
  const isLoading = useSelector(state => state.dataQuery.isLoading);
  const authProfile = useSelector(state => state.auth.profile);

  const [indicatorTemplates, setIndicatorTemplates] = useState(INDICATOR_TEMPLATES);
  const [indicators, setIndicators] = useState([]);
  const [isScholarshipRound, setIsScholarshipRound] = useState(false);
  const [isGradeResult] = useState(false);
  const [isAggregate, setIsAggregate] = useState(false);
  const [backgroundTaskId, setBackgroundTaskId] = useState(undefined);

  const isDataUser = dataUserGroup(authProfile);

  useEffect(() => {
    if (authProfile && indicatorTemplates.length > 0 && indicators.length === 0) {
      const newTemplates = [...indicatorTemplates];
      const newIndicators = [...indicators];

      let indicatorIndex = 0;
      if (isDataUser) {
        indicatorIndex = 1;
        setIsAggregate(true);
      }
      const newIndicator = {
        template: indicatorTemplates[indicatorIndex].key,
        params: {}
      };
      setDefaultParamValue(newIndicator);

      // add indicator
      newIndicators.push(newIndicator);

      // remove template
      newTemplates.splice(0, 1);

      setIndicators(newIndicators);
      setIndicatorTemplates(newTemplates);
      dispatch(changeFilter({ indicators: newIndicators }));
    }
  }, [dispatch, indicatorTemplates, indicators, authProfile, isDataUser]);

  const handleChangeIndicator = (value, index) => {
    if (index !== -1) {
      const newIndicators = [...indicators];
      const old = newIndicators.splice(index, 1, value);
      const newTemplates = [...indicatorTemplates];
      const templateIndex = _.findIndex(indicatorTemplates, { key: value.template });
      if (templateIndex !== -1) {
        newTemplates.splice(templateIndex, 1, _.find(INDICATOR_TEMPLATES, { key: old[0].template }));
      }

      setIsScholarshipRound(false);
      if (value.template === 'AG') {
        setIsAggregate(true);
        if (value.params.aggregateReport === '014') {
          setIsScholarshipRound(true);
        }
      } else {
        setIsAggregate(false);
      }

      setIndicators(newIndicators);
      setIndicatorTemplates(newTemplates);
    }
  };

  const handleDownloadReportByTemplate = () => {
    if (indicators.length) {
      const template = _.find(INDICATOR_TEMPLATES, { key: indicators[0].template });
      const report = _.find(template.params[0].options, { value: indicators[0].params?.individualStudentReport || indicators[0].params?.aggregateReport });
      const reportName = getActiveLanguage(localize).code === 'km' ? report.title.km : report.title.en;
      const params = { ...filter, ...{ indicators }, reportName, type: 'reportByTemplate', language: getActiveLanguage(localize).code };
      axios.get('/data-query/export', { params })
        .then(
          res => {
            setBackgroundTaskId(res.data);
          }
        )
        .catch(e => {
          dispatch(showErrorNotification('report.error.noReportType'));
        });
    }
  };

  const handleDownloadTask = (backgroundTask, success = true) => {
    if (success) {
      dispatch(download(backgroundTask));
    } else {
      dispatch(showErrorNotification('report.error.noReportType'));
    }
    dispatch(getExportHistoryList());
    setBackgroundTaskId(undefined);
  };

  const handleDialogClose = () => {
    setBackgroundTaskId(undefined);
  };

  return (
    <>
      <Card>
        <CardHeader color="gold" icon>
          <h4 className={classes.cardIconTitle}>{translate('managementReport.report')}</h4>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <>
            {
              indicators.map((indicator, index) => (
                <FilterIndicator
                  key={indicator.template}
                  indicator={indicator}
                  templates={[_.find(INDICATOR_TEMPLATES, { key: indicator.template }), ...indicatorTemplates]}
                  onChange={(value) => handleChangeIndicator(value, index)}
                  isGradeResult={isGradeResult}
                  isAggregate={isAggregate}
                  isScholarshipRound={isScholarshipRound}
                  isDataUser={isDataUser}
                />
              ))
            }
          </>

          <div className={classes.buttons}>
            <ThemeButton
              color="primary"
              type="submit"
              onClick={ handleDownloadReportByTemplate }
              disabled={isLoading}
            >
              {translate('common.download')}
            </ThemeButton>
          </div>
        </CardBody>
      </Card>

      { backgroundTaskId !== undefined &&
        <BackgroundTaskProgressDialog
          backgroundTaskId={backgroundTaskId}
          onTaskDownloadHandler={handleDownloadTask}
          onDialogCloseHandler={handleDialogClose}
        />
      }
    </>
  );
};

export default ReportTypeCard;
