import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import history from 'utilities/history';
import settings from 'settings';
import styles from 'assets/sts/jss/views/student/studentStyle';
import StudentListFilter from 'views/Student/Partial/StudentListFilter';
import StudentTable from 'views/Student/Partial/StudentTable';
import ChangeClassroom from 'views/Student/Partial/ChangeClassroom';
import {
  changeClassroom, dropOut, getSchool,
  getStudentList, resetSchool
} from 'redux/actions';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { groups as GROUPS, roles as ROLES } from 'variables/user';
import * as ROUTES from 'variables/routeNames';
import NewAccountDialog from 'views/StudentAccountManagement/Partial/NewAccountDialog';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { RUNNING, POST, ARCHIVED, PRE } from 'variables/academicYearStatus';
import { useKeycloak } from '@react-keycloak/web';
import {
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup,
  teacherGroup
} from 'utilities/permission';

const useStyles = makeStyles(styles);
const ALL = 'all';

const Student = (props) => {
  const { location } = props;
  const academicYearId = queryString.parse(location.search).academicYear;
  const schoolId = queryString.parse(location.search).school;
  const gradeValue = queryString.parse(location.search).grade;
  const suffixValue = queryString.parse(location.search).suffix;
  const gazetteerId = queryString.parse(location.search).gazetteerId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [keycloak] = useKeycloak();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const students = useSelector(state => state.student.studentList);
  const studentFilter = useSelector(state => state.student.studentFilter);
  const schoolList = useSelector(state => state.school.schoolList);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const isLoadingStudentList = useSelector(state => state.student.isLoading);
  const isLoadingSchool = useSelector(state => state.school.isLoading);
  const authProfile = useSelector(state => state.auth.profile);
  const currentSchool = useSelector((state) => state.school.school);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);
  const isTeacher = teacherGroup(authProfile);
  const isManagementOrTeacher = isSchoolManagement || isTeacher;

  const [selection, setSelection] = useState([]);
  const [academicYear, setAcademicYear] = useState(academicYearId !== undefined ? parseInt(academicYearId) : studentFilter?.academicYear || '');
  const [school, setSchool] = useState(schoolId !== undefined ? parseInt(schoolId) : studentFilter?.school || '');
  const [grade, setGrade] = useState(gradeValue !== undefined ? parseInt(gradeValue) : studentFilter?.grade !== undefined ? studentFilter.grade : isManagementOrTeacher ? '' : 'all');
  const [suffix, setSuffix] = useState(suffixValue !== undefined ? suffixValue : studentFilter?.suffix || 'all');
  const [classroom, setClassroom] = useState('');
  const [isOpenDropOut, setIsOpenDropOut] = useState(false);
  const [reportStage, setReportStage] = useState('');
  const [errorReportStage, setErrorReportStage] = useState(false);
  const [dropOutReason, setDropOutReason] = useState('');
  const [errorDropOutReason, setErrorDropOutReason] = useState(false);
  const [dropOutRowId, setDropOutRowId] = useState('');
  const [keyword, setKeyword] = useState(studentFilter?.keyword || '');
  const [province, setProvince] = useState(studentFilter?.province || '');
  const [district, setDistrict] = useState(studentFilter?.district || ALL);
  const [commune, setCommune] = useState(studentFilter?.commune || ALL);
  const [village, setVillage] = useState(studentFilter?.village || ALL);

  const [errorSchool, setErrorSchool] = useState(false);
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorClassroom, setErrorClassroom] = useState(false);
  const [errorProvince, setErrorProvince] = useState(false);

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [isOpenAssignClass, setIsOpenAssignClass] = useState(false);
  const [isOpenNewDialog, setIsOpenNewDialog] = useState(false);
  const [isNewLogInAcc, setIsNewLogInAcc] = useState(false);
  const [isPostOrArchivedAcademicYear, setIsPostOrArchivedAcademicYear] = useState(false);
  const [isPreparationAcademicYear, setIsPreparationAcademicYear] = useState(false);
  const [isDuplicateRecord, setIsDuplicateRecord] = useState(studentFilter?.isDuplicateRecord || false);

  // set title
  const changeBrandText = useCallback(() => {
    document.title = `${translate('student.pageListTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('student.pageListTitle');
  }, [translate]);

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  const fetchData = useCallback(
    () => {
      if (schoolId) {
        dispatch(getSchool(schoolId));
      } else {
        dispatch(resetSchool());
      }
    },
    [dispatch, schoolId]
  );

  useEffect(() => {
    if (studentFilter) {
      setProvince(studentFilter.province ? studentFilter.province : '');
      setDistrict(studentFilter.district ? studentFilter.district : '');
      setCommune(studentFilter.commune ? studentFilter.commune : '');
      setVillage(studentFilter.village ? studentFilter.village : '');
    }
  }, [studentFilter]);

  useEffect(
    () => {
      if (!isLoadingSchool) {
        fetchData();
      }
    },
    // eslint-disable-next-line
      [fetchData]);

  useEffect(() => {
    if (authProfile && currentSchool && schoolId) {
      if (isManagementOrTeacher && authProfile.school.emis_code !== currentSchool.emis_code) {
        history.push(ROUTES.HOME);
      }

      if (isProvincialOfficer) {
        if (authProfile.province.code !== currentSchool.province.code) {
          history.push(ROUTES.HOME);
        }
      }

      if (isDistrictOfficer) {
        if (authProfile.district.code !== currentSchool.district.code) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [schoolId, currentSchool, authProfile, isProvincialOfficer, isDistrictOfficer, isManagementOrTeacher]);

  useEffect(() => {
    if (authProfile && gradeValue && suffixValue && classroomList) {
      if (authProfile.groups[0] === GROUPS.TEACHER) {
        const classroom = classroomList.find(c => c.grade.toString() === gradeValue && suffixValue === c.suffix);
        if (!classroom) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [classroomList, gradeValue, suffixValue, authProfile]);

  useEffect(() => {
    if (students.data && students.data.length > 0) {
      const showRows = students.data.map(
        (student) => ({
          id: student.id,
          username: student.student.username,
          stsId: student.student.emis_student_id,
          enrollmentStatus: student.entry_status,
          lastName: student.student.last_name,
          firstName: student.student.first_name,
          name: `${student.student.last_name} ${student.student.first_name}`,
          gender: student.student.gender,
          birthDate: student.student.birth_date,
          classroom: student.classroom,
          scholarships: student.scholarships.map(scholarship => scholarship.scholarship_id),
          phone: student.student.phone_number_1,
          isKeycloakUser: student.student.keycloak_user_uuid || false,
          status: student.student.status,
          userId: student.student.user_id
        })
      );
      setCount(students && students.total ? students.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [students]);

  const fetchStudents = useCallback(
    () => {
      if (!isLoadingStudentList) {
        const params = {
          school,
          academicYear,
          grade,
          suffix,
          keyword,
          province,
          district,
          commune,
          village,
          isDuplicateRecord
        };
        params.page = page + 1;
        params.limit = limit;
        dispatch(getStudentList(params, gazetteerId));
      }
    },
    // eslint-disable-next-line
      [dispatch, page, limit, isLoadingStudentList]
  );

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line
  }, [changeBrandText, page, limit]);

  useEffect(() => {
    if (((studentFilter && studentFilter.academicYear) || academicYearId) && academicYears && academicYears.length > 0) {
      const id = academicYearId || studentFilter?.academicYear;
      const index = academicYears.findIndex(year => parseInt(id) === year.id);
      if (academicYears[index].status === RUNNING) {
        setIsNewLogInAcc(true);
      } else {
        setIsNewLogInAcc(false);
      }
      if (academicYears[index].status === POST || academicYears[index].status === ARCHIVED) {
        setIsPostOrArchivedAcademicYear(true);
      } else {
        setIsPostOrArchivedAcademicYear(false);
      }
      if (academicYears[index].status === PRE) {
        setIsPreparationAcademicYear(true);
      } else {
        setIsPreparationAcademicYear(false);
      }
    }
    // eslint-disable-next-line
  }, [academicYearId, academicYears]);

  // set default gazetteer selection
  useEffect(() => {
    if (authProfile && !isManagementOrTeacher) {
      if (authProfile.province && !province) {
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }
      if (authProfile.district && (district && district === ALL)) {
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
    }
  }, [authProfile, province, district, isManagementOrTeacher]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'academicYear': {
        setAcademicYear(value);
        const index = academicYears.findIndex(year => value === year.id);
        if (academicYears[index].status === RUNNING) {
          setIsNewLogInAcc(true);
        } else {
          setIsNewLogInAcc(false);
        }
        break;
      }
      case 'grade':
        setGrade(value);
        setSuffix('all');
        break;
      case 'suffix':
        setSuffix(value);
        break;
      case 'school':
        setSchool(value);
        break;
      case 'classroom':
        setClassroom(value);
        break;
      case 'reportStage':
        setReportStage(value);
        break;
      case 'dropOutReason':
        setDropOutReason(value);
        break;
      case 'keyword':
        setKeyword(value);
        break;
      default:
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    let canFilter = true;
    setPage(settings.startPage);

    let schoolObj;
    if (!school) {
      canFilter = false;
      setErrorSchool(true);
    } else {
      schoolObj = schoolList.find(s => s.id === school);
      if (schoolObj) {
        setErrorSchool(false);
      } else {
        canFilter = false;
        setErrorSchool(true);
        setSchool('');
        setGrade('');
        setSuffix('');
      }
    }

    if (!academicYear) {
      canFilter = false;
      setErrorAcademicYear(true);
    } else {
      setErrorAcademicYear(false);
      const index = academicYears.findIndex(year => parseInt(academicYear) === year.id);
      if (academicYears[index].status === POST || academicYears[index].status === ARCHIVED) {
        setIsPostOrArchivedAcademicYear(true);
      } else {
        setIsPostOrArchivedAcademicYear(false);
      }
      if (academicYears[index].status === PRE) {
        setIsPreparationAcademicYear(true);
      } else {
        setIsPreparationAcademicYear(false);
      }
    }

    if (grade === '') {
      canFilter = false;
      setErrorGrade(true);
    } else {
      let classroomObj;
      if (suffix && suffix !== 'all') {
        classroomObj = classroomList.find(
          c => c.grade === grade && c.suffix === suffix);
      } else {
        if (grade === 'all') {
          classroomObj = classroomList;
        } else {
          classroomObj = classroomList.find(c => c.grade === grade);
        }
      }
      if (classroomObj) {
        setErrorGrade(false);
      } else {
        canFilter = false;
        setErrorGrade(true);
      }
    }

    if (province === '' && !isManagementOrTeacher) {
      canFilter = false;
      setErrorProvince(true);
    } else {
      setErrorProvince(false);
    }

    if (canFilter) {
      const data = {
        school,
        academicYear,
        grade,
        suffix,
        page: settings.startPage,
        limit,
        keyword,
        province,
        district,
        commune,
        village,
        isDuplicateRecord
      };
      dispatch(getStudentList(data));
      setSelection([]);
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const onEditRowHandler = (rowId) => {
    history.push(`/student/${rowId}`);
  };

  const handleSelect = (event, id) => {
    let newSelections;
    if (event.target.checked) {
      newSelections = [...selection];
      newSelections.push(id);
      setSelection(newSelections);
      return;
    }
    newSelections = selection.filter(selected => selected !== id);
    setSelection(newSelections);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelections = rows.map(row => row.id);
      setSelection(newSelections);
      return;
    }
    setSelection([]);
  };

  const handleAssignClassroom = () => {
    let canAssign = true;

    if (!classroom) {
      canAssign = false;
      setErrorClassroom(true);
    }

    if (canAssign) {
      const data = {
        enrollments: selection,
        classroom_id: classroom
      };
      const classroomObject = classroomList.find(currentClass => currentClass.id === classroom);
      const classroomName = `${getActiveLanguage(localize).code === 'en' ? classroomObject.grade_name_en : classroomObject.grade_name} ${classroomObject.suffix}`;

      dispatch(changeClassroom(data, translate('enrollment.assignSuccess',
        {
          number: selection.length,
          classroom: classroomName
        })
      ));
      setSelection([]);
      setClassroom('');
      setIsOpenAssignClass(false);
    }
  };

  const handleDropOut = () => {
    let canDropOut = true;
    if (!reportStage) {
      canDropOut = false;
      setErrorReportStage(true);
    } else {
      setErrorReportStage(false);
    }
    if (!dropOutReason) {
      canDropOut = false;
      setErrorDropOutReason(true);
    } else {
      setErrorDropOutReason(false);
    }

    if (canDropOut) {
      const data = {
        drop_out_reason: dropOutReason,
        report_stage: reportStage
      };
      dispatch(dropOut(dropOutRowId, data, translate('dropOut.dropOutSuccessfulMessage')));
      setIsOpenDropOut(false);
      setReportStage('');
      setDropOutReason('');
      setDropOutRowId('');
    }
  };

  const generateGotoLink = (routeName, classroom, studentId) => {
    return `${routeName}?academicYear=${academicYear}&school=${school}&grade=${classroom.grade}&suffix=${classroom.suffix}&studentId=${studentId}`;
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <StudentListFilter
          classes={classes}
          schoolId={schoolId}
          school={school}
          setSchool={setSchool}
          academicYear={academicYear}
          grade={grade}
          suffix={suffix}
          keyword={keyword}
          errorSchool={errorSchool}
          errorAcademicYear={errorAcademicYear}
          errorGrade={errorGrade}
          errorProvince={errorProvince}
          onChangeHandler={onChangeHandler}
          handleFilter={handleFilter}
          setProvince={setProvince}
          setDistrict={setDistrict}
          setCommune={setCommune}
          setVillage={setVillage}
          province={province}
          district={district}
          commune={commune}
          village={village}
          setGrade={setGrade}
          setSuffix={setSuffix}
          setIsDuplicateRecord={setIsDuplicateRecord}
          isProvincialOfficer={isProvincialOfficer}
          isDistrictOfficer={isDistrictOfficer}
          isManagementOrTeacher={isManagementOrTeacher}
          isDuplicateRecord={isDuplicateRecord}
        />

        <StudentTable
          classes={classes}
          count={count}
          rows={rows}
          limit={limit}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditRowHandler={onEditRowHandler}
          oneHandleDropOut={handleDropOut}
          selection={selection}
          onSelect={handleSelect}
          onSelectAllClick={handleSelectAllClick}
          changeClassroom={() => setIsOpenAssignClass(true)}
          isOpenDropOut={isOpenDropOut}
          reportStage={reportStage}
          errorReportStage={errorReportStage}
          dropOutReason={dropOutReason}
          errorDropOutReason={errorDropOutReason}
          onChangeHandler={onChangeHandler}
          handleClose={() => {
            setIsOpenDropOut(false);
            setReportStage('');
            setDropOutReason('');
            setDropOutRowId('');
          }}
          handleOpenDropOut={(rowId) => {
            setIsOpenDropOut(true);
            setDropOutRowId(rowId);
          }}
          generateGotoLink={generateGotoLink}
          isCanShowAcc={isNewLogInAcc}
          filters={studentFilter}
          isPostOrArchivedAcademicYear={isPostOrArchivedAcademicYear}
          isPreparationAcademicYear={isPreparationAcademicYear}
        />

        <ChangeClassroom
          open={isOpenAssignClass}
          handleClose={() => setIsOpenAssignClass(false)}
          onChangeHandler={onChangeHandler}
          studentNum={selection.length}
          classrooms={classroomList}
          classroom={classroom}
          grade={grade}
          suffix={suffix}
          errorClassroom={errorClassroom}
          onHandleAssign={handleAssignClassroom}
        />

        {
          isOpenNewDialog && (
            <NewAccountDialog
              isOpenDialog={isOpenNewDialog}
              onHandleClose={() => { setIsOpenNewDialog(false); }}
            />
          )
        }

        {
          isNewLogInAcc && keycloak.hasRealmRole(ROLES.MANAGE_STUDENT_ACCOUNT) && (
            <ThemeButton
              color="primary"
              className={classes.allButton}
              onClick={() => { setIsOpenNewDialog(true); }}
            >
              {translate('common.button.createStuAcc')}
            </ThemeButton>
          )
        }
      </GridItem>
    </GridContainer>
  );
};

Student.propTypes = {
  location: PropTypes.instanceOf(Object)
};

export default Student;
