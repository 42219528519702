import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions/notification';
import { saveAs } from 'file-saver';

export const downloadTemplate = (params, templateFileName) => {
  return (dispatch) => {
    axios.get('/template/export', { responseType: 'blob', params })
      .then(
        res => {
          saveAs(res.data, `${templateFileName}.xlsx`);
        }
      )
      .catch(e => {
        dispatch(showErrorNotification('report.error.noTemplate'));
      });
  };
};
