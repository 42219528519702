import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  isSaving: false,
  isImporting: false,
  isImported: false,
  importedResult: {},
  attendanceList: [],
  attendanceFilter: undefined
};

const getAttendanceListRequest = (state) => ({
  ...state,
  isLoading: true
});

const getAttendanceListSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  attendanceList: action.attendanceList,
  attendanceFilter: action.attendanceFilter
});

const getAttendanceListFail = (state) => ({
  ...state,
  isLoading: false
});

const saveAllAttendancesRequest = (state) => ({
  ...state,
  isSaving: true
});

const saveAllAttendancesSuccess = (state, action) => ({
  ...state,
  isSaving: false,
  attendanceList: action.attendanceList
});

const saveAllAttendancesFail = (state) => ({
  ...state,
  isSaving: false
});

const importStudentAttendanceRequest = (state) => {
  return {
    ...state,
    isImporting: true
  };
};

const importStudentAttendanceSuccess = (state, action) => {
  return {
    ...state,
    isImporting: false,
    isImported: true,
    importedResult: action.result
  };
};

const importStudentAttendanceFail = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: true
  };
};

const resetImportAttendanceStatesRequest = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: false,
    importedResult: {}
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ATTENDANCE_LIST_REQUEST:
      return getAttendanceListRequest(state);
    case actionTypes.GET_ATTENDANCE_LIST_SUCCESS:
      return getAttendanceListSuccess(state, action);
    case actionTypes.GET_ATTENDANCE_LIST_FAIL:
      return getAttendanceListFail(state);

    case actionTypes.SAVE_ALL_ATTENDANCES_REQUEST:
      return saveAllAttendancesRequest(state);
    case actionTypes.SAVE_ALL_ATTENDANCES_SUCCESS:
      return saveAllAttendancesSuccess(state, action);
    case actionTypes.SAVE_ALL_ATTENDANCES_FAIL:
      return saveAllAttendancesFail(state);
    case actionTypes.IMPORT_STUDENT_ATTENDANCE_REQUEST:
      return importStudentAttendanceRequest(state);
    case actionTypes.IMPORT_STUDENT_ATTENDANCE_SUCCESS:
      return importStudentAttendanceSuccess(state, action);
    case actionTypes.IMPORT_STUDENT_ATTENDANCE_FAIL:
      return importStudentAttendanceFail(state);
    case actionTypes.RESET_IMPORT_ATTENDANCE_STATES_REQUEST:
      return resetImportAttendanceStatesRequest(state);
    default:
      return state;
  }
};

export default reducer;
