import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import settings from 'settings';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';

import {
  getAcademicYears,
  getClassroomListForFilter,
  getSchoolList,
  getStudentOptions
} from 'redux/actions';
import * as moment from 'moment';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import TransferIcon from '@material-ui/icons/SwapHorizontalCircle';
import CardBody from 'components/theme/Card/CardBody';
import Card from 'components/theme/Card/Card';
import * as VARIABLES from 'variables/transfer';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';

const TransferFilter = (props) => {
  const {
    classes,
    school,
    setSchool,
    academicYear,
    grade,
    suffix,
    transfer,
    errorSchool,
    errorGrade,
    errorAcademicYear,
    onChangeHandler,
    handleFilter
  } = props;
  const dispatch = useDispatch();

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const schoolList = useSelector(state => state.school.schoolList);
  const allGrades = useSelector(state => state.studentOption.grade);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const isLoadingSchoolList = useSelector(state => state.school.isLoading);
  const isLoadingClassroomList = useSelector(state => state.classroom.isLoading);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);

  const schoolFilterOptions = createFilterOptions({
    stringify: (option) => option.code + option.label
  });

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setSelectedSchool(school.value);
      setSchool(school.value);
    } else {
      setSelectedSchool('');
      setSchool('');
    }
  };

  // fetch Academic Years
  useEffect(() => {
    if (!isLoadingAcademic) {
      dispatch(getAcademicYears());
    }
    if (!isLoadingStudentOption) {
      dispatch(getStudentOptions());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears.filter(academic => academic.status === ACADEMIC_STATUS.PRE || academic.status === ACADEMIC_STATUS.RUNNING)
        .map(academic => {
          const academicStartDate = moment(academic.start_date,
            settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      const params = isManagementOrTeacher
        ? {
          academic_year_id: academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, academicYear, authProfile]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      if (isManagementOrTeacher) {
        setSchool(schoolList[0].id);
      }
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, authProfile, setSchool, translate]);

  // fetch classes by school
  useEffect(() => {
    if (school) {
      const foundSchool = schoolList.find(item => item.id === school);
      if (!foundSchool) {
        setSchool('');
        setSelectedSchool('');
      } else {
        setSelectedSchool({
          label: getSchoolName(getActiveLanguage(localize).code, translate, foundSchool),
          value: foundSchool.id,
          code: foundSchool.emis_code
        });
      }
      dispatch(getClassroomListForFilter({ school: school }));
    }
    // eslint-disable-next-line
  }, [dispatch, school, schoolList]);

  // set grade choices
  useEffect(() => {
    if (school && schoolList.length > 0 && allGrades.length > 0) {
      const choices = [];
      const promoteSchoolObj = schoolList.find(s => s.id === school);
      const degreeGrades = promoteSchoolObj ? SCHOOL_DEGREE[promoteSchoolObj.degree.number].grade : [];
      degreeGrades.forEach(gradeNumber => {
        const grade = allGrades.find(g => g.number === gradeNumber);
        choices.push({
          value: gradeNumber,
          label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
        });
      });
      choices.unshift({ value: 'all', label: translate('common.all') });
      setGradeChoices(choices);
    }
  }, [school, schoolList, allGrades, localize, translate]);

  // set suffix choices
  useEffect(() => {
    if (grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);
    } else {
      setSuffixChoices([]);
    }
  }, [grade, classroomList]);

  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic || isLoadingStudentOption || isLoadingClassroomList || isLoadingSchoolList}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <TransferIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('transfer.pageTitle')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="academicYear"
                    onChange={e => onChangeHandler(e, 'academicYear')}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Autocomplete
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={selectedSchool}
                    name="school"
                    onChange={onSchoolCodeChangeHandler}
                    options={schoolChoices}
                    disableClearable={managementOrTeacher}
                    freeSolo={managementOrTeacher}
                    disabled={schoolChoices.length === 0 || managementOrTeacher}
                    require
                    error={errorSchool}
                    filterOptions={schoolFilterOptions}
                    noOptionsText={translate('report.noSchoolChoice')}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('transfer.title')}
                    placeholder={translate('transfer.placeholder.transfer')}
                    value={transfer}
                    name="transfer"
                    onChange={e => onChangeHandler(e, 'transfer')}
                    choices={
                      [
                        { label: translate(`transfer.transferChoice.${VARIABLES.transfer['0']}`), value: 0 },
                        { label: translate(`transfer.transferChoice.${VARIABLES.transfer['1']}`), value: 1 }
                      ]
                    }
                  />
                </GridItem>

                <GridItem
                  xs={6}
                  md={3}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={grade}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={ gradeChoices }
                    disabled={gradeChoices.length === 0}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={6}
                  md={3}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('student.placeholder.suffix')}
                    value={suffix}
                    name="suffix"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton}
              >
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilter}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

TransferFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string,
  transfer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChangeHandler: PropTypes.func,
  errorSchool: PropTypes.bool,
  errorAcademicYear: PropTypes.bool,
  errorGrade: PropTypes.bool,
  handleFilter: PropTypes.func,
  setSchool: PropTypes.func
};

export default TransferFilter;
