export const CHECK_START_ENROLLMENT_REQUEST = 'CHECK_START_ENROLLMENT_REQUEST';
export const CHECK_START_ENROLLMENT_SUCCESS = 'CHECK_START_ENROLLMENT_SUCCESS';
export const CHECK_START_ENROLLMENT_FAIL = 'CHECK_START_ENROLLMENT_FAIL';

export const ENROLL_STUDENT_REQUEST = 'ENROLL_STUDENT_REQUEST';
export const ENROLL_STUDENT_SUCCESS = 'ENROLL_STUDENT_SUCCESS';
export const ENROLL_STUDENT_FAIL = 'ENROLL_STUDENT_FAIL';

export const DELETE_ENROLLMENT_REQUEST = 'DELETE_ENROLLMENT_REQUEST';
export const DELETE_ENROLLMENT_SUCCESS = 'DELETE_ENROLLMENT_SUCCESS';
export const DELETE_ENROLLMENT_FAIL = 'DELETE_ENROLLMENT_FAIL';

export const SEARCH_STUDENT_BY_ID_REQUEST = 'SEARCH_STUDENT_BY_ID_REQUEST';
export const SEARCH_STUDENT_BY_ID_SUCCESS = 'SEARCH_STUDENT_BY_ID_SUCCESS';
export const SEARCH_STUDENT_BY_ID_FAIL = 'SEARCH_STUDENT_BY_ID_FAIL';
export const RESET_SEARCH_STUDENT_BY_ID = 'RESET_SEARCH_STUDENT_BY_ID';

export const ASSIGN_CLASSROOM_REQUEST = 'ASSIGN_CLASSROOM_REQUEST';
export const ASSIGN_CLASSROOM_SUCCESS = 'ASSIGN_CLASSROOM_SUCCESS';
export const ASSIGN_CLASSROOM_FAIL = 'ASSIGN_CLASSROOM_FAIL';

export const IMPORT_ENROLL_STUDENT_REQUEST = 'IMPORT_ENROLL_STUDENT_REQUEST';
export const IMPORT_ENROLL_STUDENT_SUCCESS = 'IMPORT_ENROLL_STUDENT_SUCCESS';
export const IMPORT_ENROLL_STUDENT_FAIL = 'IMPORT_ENROLL_STUDENT_FAIL';

export const RESET_IMPORT_STATE_REQUEST = 'RESET_IMPORT_STATE_REQUEST';
