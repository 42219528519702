import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { makeStyles } from '@material-ui/core/styles';

import ThemeButton from 'components/theme/CustomButtons/Button';
import FundingFilter from 'views/ScholarshipFunding/Partial/FundingFilter';
import FundingTable from 'views/ScholarshipFunding/Partial/FundingTable';
import AddFundDialog from 'views/ScholarshipFunding/Partial/AddFundDialog';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';

const useStyles = makeStyles(styles);
const Funding = () => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const fundingList = useSelector(state => state.scholarshipFunding.scholarshipFundingList);
  const totalRecords = fundingList?.total || 0;
  const fundingFilter = useSelector(state => state.scholarshipFunding.scholarshipFundingFilter);
  const school = fundingFilter?.school;
  const [openAddFundDialog, setOpenAddFundDialog] = useState(false);
  const [editId, setEditId] = useState(0);

  // set title
  useEffect(() => {
    document.title = `${translate('scholarshipFunding.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('scholarshipFunding.pageTitle');
  }, [translate]);

  const handleAddFund = () => {
    setOpenAddFundDialog(true);
  };

  const handleCloseDialog = () => {
    setEditId(0);
    setOpenAddFundDialog(false);
  };

  const handleEditRow = (id) => {
    setEditId(id);
    setOpenAddFundDialog(true);
  };

  return (
    <>
      <FundingFilter />
      <FundingTable onEditRowHandler={handleEditRow} />
      { openAddFundDialog && (school || editId) &&
        <AddFundDialog school={school} editId={editId} open={openAddFundDialog} onClose={handleCloseDialog} />
      }
      <div className={classes.actionContainer}>
        <ThemeButton
          color="primary"
          type="submit"
          onClick={handleAddFund}
          disabled={!fundingList.data || totalRecords === 3}
        >
          {translate('scholarshipFunding.addFund')}
        </ThemeButton>
      </div>
    </>
  );
};

export default Funding;
