export const GET_CLASSROOM_LIST_REQUEST = 'GET_CLASSROOM_LIST_REQUEST';
export const GET_CLASSROOM_LIST_SUCCESS = 'GET_CLASSROOM_LIST_SUCCESS';
export const GET_CLASSROOM_LIST_FAIL = 'GET_CLASSROOM_LIST_FAIL';

export const GET_CLASSROOM_LIST_FOR_FILTER_SUCCESS = 'GET_CLASSROOM_LIST_FOR_FILTER_SUCCESS';

export const GET_CLASSROOM_REQUEST = 'GET_CLASSROOM_REQUEST';
export const GET_CLASSROOM_SUCCESS = 'GET_CLASSROOM_SUCCESS';
export const GET_CLASSROOM_FAIL = 'GET_CLASSROOM_FAIL';

export const CREATE_EDIT_CLASSROOM_REQUEST = 'CREATE_EDIT_CLASSROOM_REQUEST';
export const CREATE_EDIT_CLASSROOM_SUCCESS = 'CREATE_EDIT_CLASSROOM_SUCCESS';
export const CREATE_EDIT_CLASSROOM_FAIL = 'CREATE_EDIT_CLASSROOM_FAIL';

export const DELETE_CLASSROOM_REQUEST = 'DELETE_CLASSROOM_REQUEST';
export const DELETE_CLASSROOM_SUCCESS = 'DELETE_CLASSROOM_SUCCESS';
export const DELETE_CLASSROOM_FAIL = 'DELETE_CLASSROOM_FAIL';

export const RESET_CLASSROOM = 'RESET_CLASSROOM';

export const GET_CLASSROOM_SUBJECTS_REQUEST = 'GET_CLASSROOM_SUBJECTS_REQUEST';
export const GET_CLASSROOM_SUBJECTS_SUCCESS = 'GET_CLASSROOM_SUBJECTS_SUCCESS';
export const GET_CLASSROOM_SUBJECTS_FAIL = 'GET_CLASSROOM_SUBJECTS_FAIL';
