import React from 'react';

import PropTypes from 'prop-types';
import Select from 'components/common/Select/Select';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const SelectSex = (props) => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const { value, errorGender, onChangeHandler, require, disabled } = props;

  return (
    <>
      {
        require ? <Select
          require={require}
          label={translate('common.gender')}
          placeholder={translate('common.placeholder.gender')}
          value={value}
          name="gender"
          onChange={e => onChangeHandler(e, 'gender')}
          choices={[
            { label: translate('common.genderType.f'), value: 'f' },
            { label: translate('common.genderType.m'), value: 'm' }
          ]}
          error={errorGender}
          helperText={errorGender ? translate('common.error.require') : ''}
          disabled={disabled}
        /> : <Select
          label={translate('common.gender')}
          placeholder={translate('common.placeholder.gender')}
          value={value}
          name="gender"
          onChange={e => onChangeHandler(e, 'gender')}
          choices={[
            { label: translate('common.genderType.f'), value: 'f' },
            { label: translate('common.genderType.m'), value: 'm' }
          ]}
          disabled={disabled}
        />
      }
    </>
  );
};

SelectSex.propTypes = {
  require: PropTypes.bool,
  value: PropTypes.string,
  errorGender: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool
};

export default SelectSex;
