import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from 'components/theme/CustomLinearProgress/CustomLinearProgress';
import styles from 'assets/sts/jss/views/splashScreenStyle';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import Render from 'components/hoc/Render/Render';

const splashScreenProvider = (props) => {
  const { children } = props;
  const classes = makeStyles(styles)();
  // eslint-disable-next-line
  const [, initialized] = useKeycloak();

  const splash = (<div className={classes.root}>

    <div>

      <img
        className={classes.logo}
        src={'/images/MoEYS_LOGO.png'}
        alt='MoEYS Logo'
      />

      <Typography
        variant={'h6'}
        style={{ marginRight: 30 }}
      >
        {'Loading STS ...'}
        <LinearProgress color="primary"/>
      </Typography>
    </div>
  </div>);

  return (
    <Render>
      {
        initialized ? children : splash
      }
    </Render>
  );
};

splashScreenProvider.propTypes = {
  children: PropTypes.array
};

export default splashScreenProvider;
