import React, { useState, useEffect, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Academic from '@material-ui/icons/CalendarToday';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';
import Select from 'components/common/Select/Select';

import { createEditAcademicYear, getAcademicYear } from 'redux/actions/academicYear';
import { getSchoolList } from 'redux/actions/school';
import { showErrorNotification } from 'redux/actions';

import * as STATUS from 'variables/academicYearStatus';
import * as ROUTES from 'variables/routeNames';
import * as SCHOOL from 'variables/school';
import * as ACADEMIC_YEAR from 'variables/academicYear';

import settings from 'settings';
import * as moment from 'moment';
import _ from 'lodash';

import styles from 'assets/sts/jss/views/academicYear/createEditAcademicYearStyle';
import StudyDayTable from './StudyDayTable/StudyDayTable';
import Input from '../../components/common/Input/Input';

const useStyles = makeStyles(styles);

const allStatusChoice = [
  STATUS.DRAFT,
  STATUS.PRE,
  STATUS.RUNNING,
  STATUS.POST,
  STATUS.ARCHIVED
];

const CreateEditAcademic = (props) => {
  const { history } = props;
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const isLoading = useSelector((state) => state.academicYear.isRequestCreateEdit);
  const academicYearDetail = useSelector((state) => state.academicYear.academicYearDetail);
  const accomplishmentSchoolList = useSelector(state => state.school.schoolList);
  const dispatch = useDispatch();
  const translate = getTranslate(localize);
  const academicYearId = props.match.params.academicYearId;

  const [name, setName] = useState();
  const [errorName, setErrorName] = useState(false);

  const [startDate, setStartDate] = useState();
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [endDate, setEndDate] = useState();
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorDateRange, setErrorDateRange] = useState(false);

  const [shortStartDate, setShortStartDate] = useState();
  const [errorShortStartDate, setErrorShortStartDate] = useState(false);
  const [errorShortStartText, setErrorShortStartText] = useState('');

  const [shortEndDate, setShortEndDate] = useState();
  const [errorShortEndDate, setErrorShortEndDate] = useState(false);
  const [errorShortEndText, setErrorShortEndText] = useState('');

  const [longStartDate, setLongStartDate] = useState();
  const [errorLongStartDate, setErrorLongStartDate] = useState(false);
  const [errorLongStartText, setErrorLongStartText] = useState('');

  const [longEndDate, setLongEndDate] = useState();
  const [errorLongEndDate, setErrorLongEndDate] = useState(false);
  const [errorLongEndText, setErrorLongEndText] = useState('');

  const [status, setStatus] = useState(STATUS.DRAFT);
  const [errorStatus, setErrorStatus] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [cannotMoveStatus, setCannotMoveStatus] = useState(false);
  const [accomplishmentStatus, setAccomplishmentStatus] = useState(0);

  const [studyMonths, setStudyMonths] = useState([]);
  const [rows, setRows] = useState([]);

  // set title
  const changeBrandText = useCallback(() => {
    document.getElementById('brandText').innerHTML = translate('academicYear.title');
  }, [translate]);

  const checkInvalidDate = (date) => {
    let isInvalid = false;
    if (date && !moment(date, settings.dateFormat, true).isValid()) {
      isInvalid = true;
    }
    return isInvalid;
  };

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  useEffect(() => {
    if (academicYearId) {
      dispatch(getAcademicYear(academicYearId));
    }
  }, [dispatch, academicYearId]);

  useEffect(() => {
    if (academicYearDetail && academicYearId) {
      setStartDate(moment(academicYearDetail.start_date, settings.dateFormat));
      setEndDate(moment(academicYearDetail.end_date, settings.dateFormat));
      if (academicYearDetail.short_vacation) {
        setShortStartDate(moment(academicYearDetail.short_vacation.start_date, settings.dateFormat));
        setShortEndDate(moment(academicYearDetail.short_vacation.end_date, settings.dateFormat));
      }
      if (academicYearDetail.long_vacation) {
        setLongStartDate(moment(academicYearDetail.long_vacation.start_date, settings.dateFormat));
        setLongEndDate(moment(academicYearDetail.long_vacation.end_date, settings.dateFormat));
      }
      setStatus(academicYearDetail.status);
      setName(academicYearDetail.name);
    } else {
      setName('');
      setStartDate('');
      setEndDate('');
      setShortStartDate('');
      setShortEndDate('');
      setLongStartDate('');
      setLongEndDate('');
    }
  }, [academicYearDetail, academicYearId]);

  useEffect(() => {
    const statusToFetchSchoolList = [STATUS.PRE, STATUS.RUNNING, STATUS.POST];
    if (academicYearId && statusToFetchSchoolList.includes(status)) {
      let accomplishmentStatusBackward = 0;
      switch (status) {
        case STATUS.PRE:
          setAccomplishmentStatus(SCHOOL.accomplishmentStatus['1']);
          accomplishmentStatusBackward = SCHOOL.accomplishmentStatus.COMPLETED_PRE - 1;
          break;
        case STATUS.RUNNING:
          setAccomplishmentStatus(SCHOOL.accomplishmentStatus['2']);
          accomplishmentStatusBackward = SCHOOL.accomplishmentStatus.COMPLETED_RUNNING - 1;
          break;
        case STATUS.POST:
          setAccomplishmentStatus(SCHOOL.accomplishmentStatus['3']);
          accomplishmentStatusBackward = SCHOOL.accomplishmentStatus.COMPLETED_CLOSING - 1;
          break;
        default:
          break;
      }

      const params = {
        status: SCHOOL.status.ACTIVE,
        accomplishment_status: accomplishmentStatusBackward,
        academic_year: academicYearId,
        page: 0,
        limit: 0
      };
      dispatch(getSchoolList(params));
    }
  }, [dispatch, status, academicYearId]);

  useEffect(() => {
    if (!checkInvalidDate(startDate) && !checkInvalidDate(endDate)) {
      if (startDate && endDate && startDate.isBefore(endDate)) {
        const result = [];
        const start = _.cloneDeep(startDate);
        while (start.isBefore(endDate)) {
          result.push(start.locale('en-US').format('MMM').toLowerCase());
          start.add(1, 'month');
        }
        setStudyMonths(result);
      }
    } else {
      setStudyMonths([]);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (academicYearDetail && academicYearDetail.study_days) {
      const studyDays = JSON.parse(academicYearDetail.study_days);
      studyDays.forEach(studyDay => {
        studyDay.id = academicYearId;
      });
      setRows(studyDays);
    } else {
      const decoyRow = { id: 0 };
      studyMonths.forEach(studyMonth => {
        decoyRow[studyMonth] = ACADEMIC_YEAR.DEFAULT_STUDY_DAYS;
      });

      setRows([decoyRow]);
    }
    // eslint-disable-next-line
  }, [academicYearDetail, studyMonths]);

  const commitChanges = ({ changed }) => {
    let changedRows;
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  const handleCreateEdit = (e, isMoveToNextStep = false) => {
    if (e) {
      e.preventDefault();
    }

    let isCanSave = true;
    if (!name) {
      isCanSave = false;
      setErrorName(true);
    } else {
      setErrorName(false);
    }

    if (!startDate) {
      isCanSave = false;
      setErrorStartDate(true);
    } else {
      setErrorStartDate(false);
    }

    if (!endDate) {
      isCanSave = false;
      setErrorEndDate(true);
    } else {
      setErrorEndDate(false);
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        isCanSave = false;
        setErrorDateRange(true);
      } else {
        setErrorDateRange(false);
      }
    }

    if (!status) {
      isCanSave = false;
      setErrorStatus(true);
    } else {
      setErrorStatus(false);
    }

    if (!shortStartDate && shortEndDate) {
      isCanSave = false;
      setErrorShortStartDate(true);
      setErrorShortEndDate(false);
      setErrorShortStartText(translate('common.error.requireStartDate'));
    } else if (shortStartDate && !shortEndDate) {
      isCanSave = false;
      setErrorShortStartDate(false);
      setErrorShortEndDate(true);
      setErrorShortEndText(translate('common.error.requireEndDate'));
    } else {
      setErrorShortStartDate(false);
      setErrorShortEndDate(false);
    }

    if (shortStartDate && shortEndDate) {
      if (shortStartDate > shortEndDate) {
        isCanSave = false;
        setErrorShortStartDate(true);
        setErrorShortEndDate(true);
        setErrorShortStartText(translate('common.error.startDateRange'));
        setErrorShortEndText(translate('common.error.endDateRange'));
      } else {
        setErrorShortStartDate(false);
        setErrorShortEndDate(false);
      }
    }

    if (!longStartDate && longEndDate) {
      isCanSave = false;
      setErrorLongStartDate(true);
      setErrorLongEndDate(false);
      setErrorLongStartText(translate('common.error.requireStartDate'));
    } else if (longStartDate && !longEndDate) {
      isCanSave = false;
      setErrorLongStartDate(false);
      setErrorLongEndDate(true);
      setErrorLongEndText(translate('common.error.requireEndDate'));
    } else {
      setErrorLongStartDate(false);
      setErrorLongEndDate(false);
    }

    if (longStartDate && longEndDate) {
      if (longStartDate > longEndDate) {
        isCanSave = false;
        setErrorLongStartDate(true);
        setErrorLongEndDate(true);
        setErrorLongStartText(translate('common.error.startDateRange'));
        setErrorLongEndText(translate('common.error.endDateRange'));
      } else {
        setErrorLongStartDate(false);
        setErrorLongEndDate(false);
      }
    }

    if (checkInvalidDate(startDate) || checkInvalidDate(endDate) || checkInvalidDate(shortStartDate) || checkInvalidDate(shortEndDate) || checkInvalidDate(longStartDate) || checkInvalidDate(longEndDate)) {
      isCanSave = false;
    }

    if (isCanSave) {
      const data = {
        name: name,
        start_date: startDate.format(settings.dateFormat),
        end_date: endDate.format(settings.dateFormat),
        short_vacation_start: shortStartDate ? shortStartDate.format(settings.dateFormat) : shortStartDate,
        short_vacation_end: shortEndDate ? shortEndDate.format(settings.dateFormat) : shortEndDate,
        long_vacation_start: longStartDate ? longStartDate.format(settings.dateFormat) : longStartDate,
        long_vacation_end: longEndDate ? longEndDate.format(settings.dateFormat) : longEndDate
      };

      const studyDays = _.cloneDeep(rows);
      if (studyDays) {
        delete studyDays[0].id;
        data.study_days = [_.pick(studyDays[0], studyMonths)];
      }
      if (academicYearId === undefined) {
        data.status = STATUS.DRAFT;
        dispatch(createEditAcademicYear(data, false,
          translate('academicYear.createSuccessfulMessage')));
      } else {
        data.status = isMoveToNextStep ? allStatusChoice[nextStatus] : academicYearDetail.status;
        dispatch(createEditAcademicYear(data, academicYearId, translate('academicYear.editSuccessfulMessage')));
      }
    } else {
      dispatch(showErrorNotification(translate('common.error.form')));
    }
  };

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'name':
        setName(value);
        break;
      default:
    }
  };

  const onChangeDateHandler = (value, dateParams) => {
    switch (dateParams) {
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'shortStartDate':
        setShortStartDate(value);
        break;
      case 'shortEndDate':
        setShortEndDate(value);
        break;
      case 'longStartDate':
        setLongStartDate(value);
        break;
      case 'longEndDate':
        setLongEndDate(value);
        break;
      default:
    }
  };

  const onChangeSelectHandler = (e) => {
    setStatus(e.target.value);
  };

  const onHandleConfirm = () => {
    handleCreateEdit(undefined, true);
    setIsOpenConfirm(false);
    setCannotMoveStatus(false);
  };

  const onHandelCancel = () => {
    setIsOpenConfirm(false);
    setCannotMoveStatus(false);
  };

  const onHandleMoveStatus = () => {
    if (accomplishmentSchoolList.total > 0) {
      setCannotMoveStatus(true);
    } else {
      setIsOpenConfirm(true);
    }
  };

  let statusChoice = [
    { label: translate(`academicYear.status.${STATUS.DRAFT}`).toUpperCase(), value: STATUS.DRAFT }
  ];

  if (academicYearId && academicYearDetail) {
    switch (academicYearDetail.status) {
      case STATUS.PRE:
        statusChoice = [{ label: translate(`academicYear.status.${STATUS.PRE}`).toUpperCase(), value: STATUS.PRE }];
        break;
      case STATUS.RUNNING:
        statusChoice = [{ label: translate(`academicYear.status.${STATUS.RUNNING}`).toUpperCase(), value: STATUS.RUNNING }];
        break;
      case STATUS.POST:
        statusChoice = [{ label: translate(`academicYear.status.${STATUS.POST}`).toUpperCase(), value: STATUS.POST }];
        break;
      case STATUS.ARCHIVED:
        statusChoice = [{ label: translate(`academicYear.status.${STATUS.ARCHIVED}`).toUpperCase(), value: STATUS.ARCHIVED }];
        break;
      default:
    }
  }

  const nextStatus = academicYearDetail ? allStatusChoice.indexOf(academicYearDetail.status) + 1 : -1;
  const isDisableStartingDate = academicYearId && academicYearDetail && (academicYearDetail.status === STATUS.RUNNING || academicYearDetail.status === STATUS.POST || academicYearDetail.status === STATUS.ARCHIVED);

  const form = (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <Academic />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate(academicYearId ? 'academicYear.edit' : 'academicYear.new')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Input
                    require
                    label={translate('academicYear.name')}
                    placeholder={translate('academicYear.placeholder.name')}
                    value={name}
                    name="name"
                    onChange={e => onChangeHandler(e, 'name')}
                    error={errorName}
                    helperText={errorName ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    require
                    disabled={isDisableStartingDate}
                    name='startDate'
                    value={startDate}
                    onChange={(e) => onChangeDateHandler(e, 'startDate')}
                    timeFormat={false}
                    label={translate('academicYear.startDate')}
                    placeholder={translate('common.placeholder.startDate')}
                    error={errorStartDate || errorDateRange || checkInvalidDate(startDate)}
                    helperText={errorStartDate
                      ? translate('common.error.require')
                      : checkInvalidDate(startDate)
                        ? translate('common.error.invalidDate')
                        : errorDateRange
                          ? translate('common.error.startDateRange')
                          : ''
                    }
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    require
                    name='endDate'
                    timeFormat={false}
                    value={endDate}
                    onChange={(e) => onChangeDateHandler(e, 'endDate')}
                    label={translate('academicYear.finishDate')}
                    placeholder={translate('common.placeholder.endDate')}
                    error={errorEndDate || errorDateRange || checkInvalidDate(endDate)}
                    helperText={errorEndDate
                      ? translate('common.error.require')
                      : checkInvalidDate(endDate)
                        ? translate('common.error.invalidDate')
                        : errorDateRange
                          ? translate('common.error.endDateRange')
                          : ''
                    }
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    name='shortStartDate'
                    timeFormat={false}
                    value={shortStartDate}
                    onChange={(e) => onChangeDateHandler(e, 'shortStartDate')}
                    label={translate('academicYear.vacation.shortStart')}
                    placeholder={translate('common.placeholder.startDate')}
                    error={errorShortStartDate || checkInvalidDate(shortStartDate)}
                    helperText={errorShortStartDate
                      ? errorShortStartText
                      : checkInvalidDate(shortStartDate)
                        ? translate('common.error.invalidDate')
                        : ''
                    }
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    name='shortEndDate'
                    timeFormat={false}
                    value={shortEndDate}
                    onChange={(e) => onChangeDateHandler(e, 'shortEndDate')}
                    label={translate('academicYear.vacation.shortEnd')}
                    placeholder={translate('common.placeholder.endDate')}
                    error={errorShortEndDate || checkInvalidDate(shortEndDate)}
                    helperText={errorShortEndDate
                      ? errorShortEndText
                      : checkInvalidDate(shortEndDate)
                        ? translate('common.error.invalidDate')
                        : ''
                    }
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    name='longStartDate'
                    timeFormat={false}
                    value={longStartDate}
                    onChange={(e) => onChangeDateHandler(e, 'longStartDate')}
                    label={translate('academicYear.vacation.longStart')}
                    placeholder={translate('common.placeholder.startDate')}
                    error={errorLongStartDate || checkInvalidDate(longStartDate)}
                    helperText={errorLongStartDate
                      ? errorLongStartText
                      : checkInvalidDate(longStartDate)
                        ? translate('common.error.invalidDate')
                        : ''
                    }
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <DateTimePicker
                    name='longEndDate'
                    timeFormat={false}
                    value={longEndDate}
                    onChange={(e) => onChangeDateHandler(e, 'longEndDate')}
                    label={translate('academicYear.vacation.longEnd')}
                    placeholder={translate('common.placeholder.endDate')}
                    error={errorLongEndDate || checkInvalidDate(longEndDate)}
                    helperText={errorLongEndDate
                      ? errorLongEndText
                      : checkInvalidDate(longEndDate)
                        ? translate('common.error.invalidDate')
                        : ''
                    }
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    require
                    disabled
                    label={translate('common.status')}
                    placeholder={translate('common.placeholder.status')}
                    value={status}
                    name="status"
                    onChange={onChangeSelectHandler}
                    choices={statusChoice}
                    error={errorStatus}
                    helperText={errorStatus ? translate('common.error.require') : ''}
                  />
                </GridItem>

              </GridContainer>

              <span>
                {translate('academicYear.studyDays')}
              </span>
              <StudyDayTable
                classes={classes}
                studyMonths={studyMonths}
                rows={rows}
                isLoadingTable={isLoading}
                commitChanges={commitChanges}
              />

              <div
                className={classes.moveToNextStatus}
              >
                {
                  (academicYearId && (academicYearDetail && academicYearDetail.status !== STATUS.ARCHIVED)) && (
                    <Button color="warning" className={classes.nextStatusButton}
                      onClick={onHandleMoveStatus}
                    >
                      {translate(`academicYear.moveToNextStep.${allStatusChoice[nextStatus]}`)}
                      <ArrowForwardIos className={classes.firstArrow}/>
                      <ArrowForwardIos className={classes.secondArrow}/>
                    </Button>
                  )
                }
              </div>
              <div
                className={classes.submitButton}
              >
                <Button
                  className={classes.cancelButton}
                  onClick={() => history.push(ROUTES.ACADEMIC_YEAR)}
                >
                  {translate('common.button.cancel')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleCreateEdit}
                >
                  {academicYearId ? translate('common.button.save') : translate('common.button.create')}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <div>
      {
        cannotMoveStatus && (
          <SweetAlert
            info
            style={{ display: 'block', marginTop: '-200px' }}
            title={translate('academicYear.modal.cannotMoveStatus.tittle')}
            onCancel={() => onHandelCancel()}
            cancelBtnCssClass={classes.button + ' ' + classes.default}
            cancelBtnText={translate('common.button.close')}
            showConfirm={false}
            showCancel
          >
            {translate(`academicYear.modal.cannotMoveStatus.desc.not.${accomplishmentStatus}`, { schools: accomplishmentSchoolList.total })}
          </SweetAlert>
        )
      }
      {
        isOpenConfirm && (
          <SweetAlert
            warning
            style={{ display: 'block', marginTop: '-200px' }}
            title={translate('common.modal.confirmTitle')}
            onConfirm={() => onHandleConfirm()}
            onCancel={() => onHandelCancel()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.default}
            confirmBtnText={translate('common.modal.yes')}
            cancelBtnText={translate('common.modal.no')}
            showConfirm
            showCancel
          >
            {translate('common.modal.describe')}
          </SweetAlert>
        )
      }
      {isLoading && <LoadingSpinner/>}
      {form}
    </div>
  );
};

CreateEditAcademic.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default CreateEditAcademic;
