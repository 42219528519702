import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import StudentAccountIcon from '@material-ui/icons/SupervisedUserCircle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import DialogActions from '@material-ui/core/DialogActions';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import GridContainer from 'components/theme/Grid/GridContainer';
import { searchStudent, resetSearchResult, createEditAcc } from 'redux/actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'components/common/Select/Select';
import DateTimePicker
  from 'components/common/DateTimePicker/DateTimePicker';
import Render from 'components/hoc/Render/Render';
import LoadingSpinner
  from 'components/common/LoadingSpinner/LoadingSpinner';

const useStyles = makeStyles(styles);
const accDetail = {
  username: '',
  password: '',
  confirmPassword: '',
  isPasswordTemporary: false,
  firstName: '',
  lastName: '',
  firstNameEn: '',
  lastNameEn: '',
  gender: '',
  dob: ''
};

const NewAccountDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const searchedStudent = useSelector(state => state.studentAccount.searchedStudent);
  const isLoadingSearch = useSelector(state => state.studentAccount.isLoadingSearch);

  const [stsId, setStsId] = useState('');
  const [errorStsId, setErrorStsId] = useState(false);
  const [isSearchNotFound, setIsSearchNotFound] = useState(false);
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);
  const [isCanCreateAcc, setIsCanCreateAcc] = useState(false);
  const [studentAcc, setStudentAcc] = useState(accDetail);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorConfirmMessage, setErrorConfirmMessage] = useState('');
  const {
    isOpenDialog,
    onHandleClose
  } = props;

  useEffect(() => {
    if (searchedStudent !== undefined) {
      switch (searchedStudent) {
        case '':
          setIsSearchNotFound(true);
          break;
        case 'created':
          setIsAlreadyCreated(true);
          break;
        default:
          setIsCanCreateAcc(true);
          setStudentAcc({
            ...studentAcc,
            username: searchedStudent.username,
            firstName: searchedStudent.first_name,
            lastName: searchedStudent.last_name,
            firstNameEn: searchedStudent.first_name_en || '',
            lastNameEn: searchedStudent.last_name_en || '',
            gender: searchedStudent.gender,
            dob: searchedStudent.birth_date
          });
      }
    }
    // eslint-disable-next-line
  }, [searchedStudent]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    setStsId(value);
  };

  const onHandleSearch = () => {
    const trimStsId = stsId.trim();
    if (!trimStsId) {
      setErrorStsId(true);
    } else {
      setErrorStsId(false);
      dispatch(searchStudent({ sts_id: stsId }));
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setStudentAcc({ ...studentAcc, [name]: value });
  };

  const onHandleCreate = () => {
    let isCanSave = true;
    if (studentAcc.password) {
      if (studentAcc.password.length < 6) {
        setErrorPassword(true);
        isCanSave = false;
      } else {
        setErrorPassword(false);
        if (!studentAcc.confirmPassword) {
          setErrorConfirmPassword(true);
          setErrorConfirmMessage(translate('common.error.require'));
          isCanSave = false;
        } else {
          if (studentAcc.password !== studentAcc.confirmPassword) {
            setErrorConfirmPassword(true);
            setErrorConfirmMessage(translate('common.error.confirmPassword'));
            isCanSave = false;
          } else {
            setErrorConfirmPassword(false);
            setErrorConfirmMessage('');
          }
        }
      }
    }
    if (isCanSave) {
      const data = {
        sts_id: studentAcc.username,
        password: studentAcc.password || undefined,
        is_password_temporary: studentAcc.isPasswordTemporary
      };
      dispatch(createEditAcc(data));
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <StudentAccountIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('studentAccountManagement.newStudentAcc')}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={isLoadingSearch ? classes.dialogContentLoading : classes.dialogContentStuAcc}>
        {
          isLoadingSearch ? (
            <LoadingSpinner
              classes={classes.loading}
              loadingBoxClass={classes.loadingBoxClass}
            />
          ) : (
            <GridContainer>
              {
                !isSearchNotFound && !isAlreadyCreated && !isCanCreateAcc && (
                  <GridItem
                    xs={12}
                  >
                    <Input
                      require
                      label={translate('common.stsId')}
                      placeholder={translate('transfer.placeholder.stsId')}
                      value={stsId}
                      name="stsId"
                      onChange={e => onChangeHandler(e, 'stsId')}
                      error={errorStsId}
                      helperText={errorStsId ? translate('common.error.require') : ''}
                    />
                  </GridItem>
                )
              }

              {
                isSearchNotFound && (
                  <GridItem
                    xs={12}
                  >
                    <p className={classes.studentNotFound}>
                      {translate('enrollment.studentNotFound')}
                    </p>
                  </GridItem>
                )
              }

              {
                isAlreadyCreated && (
                  <GridItem
                    xs={12}
                  >
                    <p className={classes.studentNotFound}>
                      {translate('studentAccountManagement.studentCreated')}
                    </p>
                  </GridItem>
                )
              }

              {
                isCanCreateAcc && (
                  <Render>
                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        classes.addressGrid
                      }
                    >
                      <GridContainer
                        className={
                          classes.innerGridContainer
                        }
                      >
                        <GridItem
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <Input
                            label={translate('userManagement.username')}
                            placeholder={translate('userManagement.placeholder.username')}
                            value={studentAcc.username}
                            name="username"
                            autoComplete="off"
                            disabled
                          />
                        </GridItem>

                        <GridItem
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <Input
                            label={translate('userManagement.password')}
                            placeholder={translate('userManagement.placeholder.password')}
                            value={studentAcc.password}
                            name="password"
                            onChange={handleInputChange}
                            type="password"
                            autoComplete="off"
                            error={errorPassword}
                            helperText={translate('common.error.password6')}
                          />
                        </GridItem>

                        <GridItem
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <Input
                            label={translate('userManagement.confirmPassword')}
                            placeholder={translate('userManagement.placeholder.confirmPassword')}
                            value={studentAcc.confirmPassword}
                            name="confirmPassword"
                            onChange={handleInputChange}
                            type="password"
                            autoComplete="off"
                            error={errorConfirmPassword}
                            helperText={errorConfirmMessage}
                          />
                        </GridItem>

                        <GridItem
                          xs={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <FormControlLabel
                            className={classes.passwordTempCheckBox}
                            control={
                              <Checkbox
                                checked={studentAcc.isPasswordTemporary}
                                onChange={(e) => setStudentAcc({ ...studentAcc, isPasswordTemporary: e.target.checked })}
                                name="isPasswordTemporary"
                                classes={{
                                  checked: classes.checkBoxPass
                                }}
                              />
                            }
                            label={translate('userManagement.temporaryPassword')}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('common.lastName')}
                        placeholder={translate('common.placeholder.lastName')}
                        value={studentAcc.lastName}
                        name="lastName"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('common.firstName')}
                        placeholder={translate('common.placeholder.firstName')}
                        value={studentAcc.firstName}
                        name="firstName"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastNameEN')}
                        value={studentAcc.lastNameEn}
                        name="lastNameEn"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.firstNameEN')}
                        value={studentAcc.firstNameEn}
                        name="firstNameEn"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        require
                        label={translate('common.gender')}
                        placeholder={translate('common.placeholder.gender')}
                        value={studentAcc.gender}
                        name="gender"
                        choices={[
                          { label: translate('common.genderType.f'), value: 'f' },
                          { label: translate('common.genderType.m'), value: 'm' }
                        ]}
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <DateTimePicker
                        label={translate('enrollment.birthDate')}
                        name="birthDate"
                        value={studentAcc.dob}
                        timeFormat={false}
                        placeholder={translate('enrollment.placeholder.birthDate')}
                        disabled
                      />
                    </GridItem>
                  </Render>
                )
              }
            </GridContainer>
          )
        }
      </DialogContent>

      {
        !isLoadingSearch && (
          <DialogActions
            className={classes.dialogAction}
          >
            {
              isCanCreateAcc && (
                <ThemeButton
                  onClick={() => {
                    dispatch(resetSearchResult());
                    setStudentAcc(accDetail);
                    setStsId('');
                    setIsCanCreateAcc(false);
                    onHandleCreate();
                  }}
                  color="primary"
                  variant="contained"
                  className="fLeft"
                >
                  {translate('common.button.createNew')}
                </ThemeButton>
              )
            }
            <div className={classes.normalSaveClose}>
              {
                !isSearchNotFound && !isAlreadyCreated && !isCanCreateAcc && (
                  <ThemeButton
                    color="primary"
                    onClick={onHandleSearch}
                    variant={'contained'}
                    style={{ marginRight: '8px', float: 'right' }}
                  >
                    {translate('common.button.search')}
                  </ThemeButton>
                )
              }

              {
                (isSearchNotFound || isAlreadyCreated) && (
                  <ThemeButton
                    color="primary"
                    onClick={() => {
                      dispatch(resetSearchResult());
                      setIsSearchNotFound(false);
                      setIsAlreadyCreated(false);
                    }}
                    variant={'contained'}
                    style={{ marginRight: '8px', float: 'right' }}
                  >
                    {translate('common.button.searchAgain')}
                  </ThemeButton>
                )
              }

              {
                (isCanCreateAcc) && (
                  <ThemeButton
                    color="primary"
                    onClick={() => {
                      onHandleCreate();
                      onHandleClose();
                    }}
                    variant={'contained'}
                    style={{ marginRight: '8px', float: 'right' }}
                  >
                    {translate('common.button.create')}
                  </ThemeButton>
                )
              }

              <ThemeButton
                onClick={() => {
                  dispatch(resetSearchResult());
                  onHandleClose();
                }}
                variant="contained"
                className={classes.buttonDefault}
              >
                {translate('common.button.close')}
              </ThemeButton>
            </div>
          </DialogActions>
        )
      }
    </Dialog>
  );
};

NewAccountDialog.propTypes = {
  isOpenDialog: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func
};

export default NewAccountDialog;
