import { dangerColor } from 'assets/theme/jss/material-dashboard-pro-react';

const InputStyle = (theme) => ({
  labelRootError: {
    color: `${dangerColor[0]} !important`
  },
  divClass: {
    height: '81px'
  }
});

export default InputStyle;
