import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import DataQueryIcon from '@material-ui/icons/InsertChart';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';

import styles from 'assets/sts/jss/views/dataQuery/dataQueryStyle';
import Select from 'components/common/Select/Select';
import {
  changeFilter,
  getAcademicYears,
  getStudentOptions,
  clearUnits,
  getDistricts,
  getProvinces,
  getDataQuerySchoolFilterOption,
  getCommunes,
  getVillages,
  getClassroomListForFilter
} from 'redux/actions';
import { allChoices, getGazetteerChoices } from 'utilities/gazetteer';
import * as ACADEMICYEARSTATUS from 'variables/academicYearStatus';
import { DEGREES } from 'variables/user';
import { getAcademicYearLabel, getSchoolName } from 'utilities/helpers';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  dataUserGroup,
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup
} from 'utilities/permission';

const useStyles = makeStyles(styles);
const ALL = 'all';
const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const DataSourceCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);
  const filter = useSelector(state => state.dataQuery.filter);
  const schoolList = useSelector(state => state.dataQuery.schoolFilterOptions);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const allGrades = useSelector(state => state.studentOption.grade);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState(allChoices(translate));
  const [districtChoices, setDistrictChoices] = useState(allChoices(translate));
  const [dataSource, setDataSource] = useState({
    academicYear: filter.dataSource.academicYear
  });
  const [errorAcademicYear] = useState(false);
  const [province, setProvince] = useState(filter.province);
  const [district, setDistrict] = useState(filter.district);
  const [communeChoices, setCommuneChoices] = useState(allChoices(translate));
  const [villageChoices, setVillageChoices] = useState(allChoices(translate));
  const [degree, setDegree] = useState(filter.degree);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(filter.selectedSchool ? filter.selectedSchool : null);
  const [school, setSchool] = useState(filter.school ? filter.school : '');
  const [gradeChoices, setGradeChoices] = useState([]);
  const [grade, setGrade] = useState(filter.grade ? filter.grade : ALL);
  const [commune, setCommune] = useState(filter.commune);
  const [village, setVillage] = useState(filter.village);

  const isDataUser = dataUserGroup(authProfile);
  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);
  const hasSchoolDegree = authProfile && (authProfile.school_degree !== '0' || isSchoolManagement);

  const degreeChoices = DEGREES.map(degree => {
    return {
      label: translate(degree.label),
      value: degree.value
    };
  });

  // set default selection
  useEffect(() => {
    if (authProfile) {
      if (authProfile.province) {
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }
      if (authProfile.district) {
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
      if (authProfile.commune) {
        setCommune(`${authProfile.commune.id},${authProfile.commune.code}`);
      }
      if (authProfile.village) {
        setVillage(`${authProfile.village.id},${authProfile.village.code}`);
      }
      if (hasSchoolDegree) {
        setDegree(authProfile.school?.degree.number || authProfile.school_degree);
      }
      if (isSchoolManagement && schoolList.length > 0) {
        const school = _.findLast(schoolList, { emis_code: authProfile.school.emis_code });
        setSchool(school.id);
        setSelectedSchool({
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school),
          code: school.emis_code
        });
      }
    }
  }, [authProfile, hasSchoolDegree, isSchoolManagement, schoolList, localize, translate]);

  // fetch academic years and provinces
  useEffect(() => {
    dispatch(getAcademicYears());
    dispatch(getStudentOptions());
    dispatch(clearUnits());
    dispatch(getProvinces());
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      let defaultRunningAcademicYearId = null;
      const choice = academicYears.map(academic => {
        if (academic.status === ACADEMICYEARSTATUS.RUNNING) {
          defaultRunningAcademicYearId = academic.id;
        }
        return {
          value: academic.id,
          label: getAcademicYearLabel(translate, academic)
        };
      });
      setAcademicYearChoices(choice);

      // set default selected academicYear
      if (choice.length > 0 && dataSource.academicYear === '') {
        setDataSource({
          ...dataSource,
          academicYear: defaultRunningAcademicYearId || choice[0].value
        });
      }
    }
  }, [academicYears, translate, dataSource]);

  // set province choices
  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces, translate));
  }, [provinces, localize, translate]);

  // fetch districts after province is selected
  useEffect(() => {
    dispatch(clearUnits());
    if (province !== ALL) {
      dispatch(getDistricts(province));
    }
  }, [dispatch, province]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
  }, [districts, localize, translate]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(
    () => {
      if (district !== ALL) {
        dispatch(getCommunes(district));
      }
      dispatch(clearUnits('district'));
    },
    [dispatch, district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes, translate));
  }, [communes, localize, translate]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(
    () => {
      if (commune !== ALL) {
        dispatch(getVillages(commune));
      }
      dispatch(clearUnits('commune'));
    },
    [dispatch, commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages, translate));
  }, [villages, localize, translate]);

  // fetch schools
  useEffect(() => {
    if (province !== '' && district !== '') {
      let gazetteer = province && province !== ALL ? province.split(',')[1] : '';
      if (district && district !== ALL) {
        gazetteer = district.split(',')[1];
      }
      if (commune && commune !== ALL) {
        gazetteer = commune.split(',')[1];
      }
      if (village && village !== ALL) {
        gazetteer = village.split(',')[1];
      }
      const params = {
        academic_year: filter.dataSource.academicYear,
        orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
        order: 'ASC',
        degree,
        limit: 9999
      };

      if (gazetteer) {
        params.gazetteer = gazetteer;
      }
      dispatch(getDataQuerySchoolFilterOption(params));
    }
    // eslint-disable-next-line
  }, [dispatch, filter.dataSource.academicYear, province, district, commune, village, degree]);

  useEffect(() => {
    if (filter.dataSource.academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school),
          code: school.emis_code
        };
      });

      if (!isSchoolManagement) {
        const selectedSchoolObj = _.find(choice, { value: selectedSchool?.value });
        if (_.isEmpty(selectedSchoolObj)) {
          setSelectedSchool(null);
          setSchool('');
        }
      }
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
      setSelectedSchool(null);
      setSchool('');
    }
  }, [filter.dataSource.academicYear, schoolList, localize, translate, selectedSchool, isSchoolManagement]);

  // fetch classes by school
  useEffect(() => {
    if (school && authProfile && !isDataUser) {
      const param = {
        school: school
      };
      dispatch(getClassroomListForFilter(param));
    }
  }, [dispatch, school, authProfile, isDataUser]);

  // set grade choices
  useEffect(() => {
    if (school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          choices.push({
            value: classroom.grade,
            label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
          });
        }
      });
      setGradeChoices(allChoices(translate).concat(choices));
    } else if (school && authProfile && isDataUser) {
      setGradeChoices(allChoices(translate));
      setGrade(ALL);
    } else {
      setGradeChoices([]);
    }
  }, [school, classroomList, allGrades, localize, translate, authProfile, isDataUser]);

  // apply data query filter changed
  useEffect(() => {
    if (!_.isEqual(dataSource, filter.dataSource) || province !== filter.province || district !== filter.district || commune !== filter.commune || village !== filter.village || degree !== filter.degree || school !== filter.school || grade !== filter.grade) {
      dispatch(changeFilter({ dataSource, province, district, commune, village, degree, grade, school, selectedSchool }));
    }
    // eslint-disable-next-line
  }, [dispatch, dataSource, province, district, commune, village, degree, grade, school, selectedSchool]);

  // TODO if we can move academic year to normal filter (filter.academicYear)
  const onChangeDataSourceHandler = e => {
    const { name, value } = e.target;
    setDataSource({ ...dataSource, [name]: value });
  };

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setSchool(school.value);
      setSelectedSchool(school);
    } else {
      setSchool('');
      setSelectedSchool(null);
    }
  };

  const onChangeHandler = e => {
    const { name, value } = e.target;

    switch (name) {
      case 'province':
        setProvince(value);
        setDistrict(ALL);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'district':
        setDistrict(value);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'commune':
        setCommune(value);
        setVillage(ALL);
        break;
      case 'village':
        setVillage(value);
        break;
      case 'degree':
        setDegree(value);
        break;
      case 'grade':
        setGrade(value);
        break;
      default:
    }
  };

  return (
    <Card>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <DataQueryIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate('dataQuery.dataSource')}</h4>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        <Select
          label={translate('school.academicYear')}
          placeholder={translate('school.placeholder.academicYear')}
          value={dataSource.academicYear}
          name="academicYear"
          onChange={onChangeDataSourceHandler}
          choices={academicYearChoices}
          disabled={academicYearChoices.length === 0}
          require
          error={errorAcademicYear}
          helperText={errorAcademicYear ? translate('common.error.require') : ''}
        />
        {!isSchoolManagement && (
          <>
            <Select
              label={translate('gazetteer.province')}
              placeholder={translate('gazetteer.placeholder.province')}
              value={province}
              name="province"
              onChange={onChangeHandler}
              choices={provinceChoices}
              disabled={isLoadingGazetteer || provinces.length === 0 || isProvincialOfficer || isDistrictOfficer}
              helperText=""
            />
            <Select
              label={translate('gazetteer.district')}
              placeholder={translate('gazetteer.placeholder.district')}
              value={district}
              name="district"
              onChange={onChangeHandler}
              choices={districtChoices}
              disabled={isLoadingGazetteer || districts.length === 0 || isDistrictOfficer}
              helperText=""
            />
            <Select
              label={translate('gazetteer.commune')}
              placeholder={translate('gazetteer.placeholder.commune')}
              value={commune}
              name="commune"
              onChange={onChangeHandler}
              choices={communeChoices}
              disabled={isLoadingGazetteer || communes.length === 0 || isSchoolManagement}
              helperText=""
            />
            <Select
              label={translate('gazetteer.village')}
              placeholder={translate('gazetteer.placeholder.village')}
              value={village}
              name="village"
              onChange={onChangeHandler}
              choices={villageChoices}
              disabled={isLoadingGazetteer || villages.length === 0 || isSchoolManagement}
              helperText=""
            />
            <Select
              label={translate('userManagement.degree')}
              placeholder={translate('userManagement.placeholder.degree')}
              value={degree}
              name="degree"
              onChange={onChangeHandler}
              choices={degreeChoices}
              disabled={degreeChoices.length === 0 || hasSchoolDegree || isSchoolManagement}
              helperText=""
            />
          </>
        )}
        <Autocomplete
          value={selectedSchool}
          name="school"
          label={translate('classroom.school')}
          placeholder={translate('classroom.placeholder.school')}
          options={schoolChoices}
          filterOptions={schoolFilterOptions}
          onChange={onSchoolCodeChangeHandler}
          noOptionsText={translate('report.noSchoolChoice')}
          disabled={schoolChoices.length === 0 || isSchoolManagement}
        />
        <label>&nbsp;</label>
        <Select
          label={translate('classroom.grade')}
          placeholder={translate('classroom.placeholder.grade')}
          value={gradeChoices.length ? grade : ''}
          name="grade"
          onChange={onChangeHandler}
          choices={gradeChoices}
          disabled={gradeChoices.length === 0 || isDataUser}
          helperText={''}
        />
      </CardBody>
    </Card>
  );
};

export default DataSourceCard;
