import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DataTypeProvider,
  EditingState,
  PagingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableInlineCellEditing
} from '@devexpress/dx-react-grid-material-ui';
import settings from 'settings';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ScoreTableCell from 'views/Score/Patials/ScoreTable/ScoreTableCell';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as SCORE from 'variables/score';
import { roles as ROLES } from 'variables/user';
import { useKeycloak } from '@react-keycloak/web';

const getRowId = row => row.id;

const StepFormatter = ({ value }) => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  return <Input value={value !== undefined && value !== null ? translate('score.' + SCORE.PRE_SCHOOL_SCORES[value]) : ''} />;
};
StepFormatter.propTypes = {
  value: PropTypes.number
};

const StepEditor = ({ value, onValueChange, onBlur, autoFocus }) => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const stepItems = [];
  // eslint-disable-next-line
  for (const [value, key] of Object.entries(SCORE.PRE_SCHOOL_SCORES)) {
    stepItems.push(
      <MenuItem value={value} key={key}>
        {translate('score.' + key)}
      </MenuItem>
    );
  }
  return (
    <Select
      input={<Input />}
      value={value !== undefined && value !== null ? value : ''}
      onChange={event => onValueChange(event.target.value)}
      onBlur={onBlur}
      autoFocus={autoFocus}
      style={{ width: '100%' }}
    >
      {stepItems}
    </Select>);
};
StepEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool
};

const StepTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={StepFormatter}
    editorComponent={StepEditor}
    {...props}
  />
);

const ScoreInlineEditTable = (props) => {
  const {
    classes,
    columns,
    columnBands,
    cellBandHeaderComponent,
    rows,
    columnExtensions,
    commitChanges,
    editingStateColumnExtensions,
    stepColumns,
    isPostOrArchivedAcademicYear
  } = props;

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const [keycloak] = useKeycloak();
  const [genderColumns] = useState(['gender']);
  const [consolidateSemester1Column] = useState(['consolidate_semester_1']);
  const [consolidateSemester2Column] = useState(['consolidate_semester_2']);

  const GenderFormatter = ({ value }) => ['f', 'm'].includes(value) ? translate('common.genderType.' + value) : '';
  const numberFormatter = ({ value }) => value !== null ? parseFloat(value).toFixed(2) : '';

  return (
    <div className={classes.tableResponsive}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <DataTypeProvider
          for={genderColumns}
          formatterComponent={GenderFormatter}
          {...props}
        />
        <DataTypeProvider
          for={consolidateSemester1Column}
          formatterComponent={numberFormatter}
          {...props}
        />
        <DataTypeProvider
          for={consolidateSemester2Column}
          formatterComponent={numberFormatter}
          {...props}
        />
        <StepTypeProvider
          for={stepColumns}
        />
        <PagingState
          defaultCurrentPage={settings.startPage}
          defaultPageSize={settings.rowsPerPage}
        />

        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />
        <Table
          cellComponent={ScoreTableCell}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow />

        {
          (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM) || keycloak.hasRealmRole(ROLES.MANAGE_OWNED_CLASSROOM)) && !isPostOrArchivedAcademicYear && (
            <TableInlineCellEditing
              startEditAction="click"
              selectTextOnEditStart={true}
            />
          )
        }

        <TableBandHeader
          columnBands={columnBands}
          cellComponent={cellBandHeaderComponent}
        />
      </Grid>
    </div>
  );
};

ScoreInlineEditTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  columnBands: PropTypes.array,
  columnExtensions: PropTypes.array,
  commitChanges: PropTypes.func,
  classes: PropTypes.instanceOf(Object),
  cellBandHeaderComponent: PropTypes.any,
  editingStateColumnExtensions: PropTypes.instanceOf(Object),
  stepColumns: PropTypes.array,
  isPostOrArchivedAcademicYear: PropTypes.bool
};

export default ScoreInlineEditTable;
