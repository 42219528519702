import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions/notification';
import * as actionTypes from '../actionTypes';
import { saveAs } from 'file-saver';

function changeFilterRequested () {
  return {
    type: actionTypes.CHANGE_FILTER_REQUEST
  };
}

function changeFilterSuccess (filter) {
  return {
    type: actionTypes.CHANGE_FILTER_SUCCESS,
    filter
  };
}

function getStatisticsRequested () {
  return {
    type: actionTypes.GET_STATISTICS_REQUEST
  };
}

function getStatisticsSuccess (statistics) {
  return {
    type: actionTypes.GET_STATISTICS_SUCCESS,
    statistics
  };
}

function getStatisticsFail () {
  return {
    type: actionTypes.GET_STATISTICS_FAIL
  };
}

function getDataQuerySchoolFilterOptionRequested () {
  return {
    type: actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_REQUEST
  };
}

function getDataQuerySchoolFilterOptionSuccess (schoolFilterOptions) {
  return {
    type: actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_SUCCESS,
    schoolFilterOptions
  };
}

function getDataQuerySchoolFilterOptionFail () {
  return {
    type: actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_FAIL
  };
}

function downloadStaticalReportRequested () {
  return {
    type: actionTypes.DOWNLOAD_STATICAL_REPORT_REQUEST
  };
}

function downloadStaticalReportSuccess () {
  return {
    type: actionTypes.DOWNLOAD_STATICAL_REPORT_SUCCESS
  };
}

function downloadStaticalReportFail () {
  return {
    type: actionTypes.DOWNLOAD_STATICAL_REPORT_FAIL
  };
}

export const changeFilter = (newFilter) => {
  return (dispatch, getState) => {
    dispatch(changeFilterRequested());

    const filter = getState().dataQuery.filter;
    dispatch(changeFilterSuccess({ ...filter, ...newFilter }));
  };
};

export const getStatistics = (filter) => {
  return (dispatch) => {
    dispatch(getStatisticsRequested());
    axios.get('/data-query/statistics', { params: filter })
      .then(
        res => {
          dispatch(getStatisticsSuccess(res.data));
        }
      )
      .catch(e => {
        dispatch(getStatisticsFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getDataQuerySchoolFilterOption = (filter) => {
  return (dispatch) => {
    dispatch(getDataQuerySchoolFilterOptionRequested());
    axios.get('/data-query/getSchools', { params: filter })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getDataQuerySchoolFilterOptionSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getDataQuerySchoolFilterOptionFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const downloadStaticalReport = (params, fileName) => {
  return (dispatch) => {
    dispatch(downloadStaticalReportRequested());
    axios.get('/data-query/export', { responseType: 'blob', params })
      .then(
        res => {
          saveAs(res.data, `${fileName}.xlsx`);
          dispatch(downloadStaticalReportSuccess());
        }
      )
      .catch(e => {
        // todo: correct "e.response.reportFileNameData.message"
        dispatch(downloadStaticalReportFail());
        dispatch(showErrorNotification('report.error.noReportType'));
      });
  };
};
