import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { requestCloseCallback } from 'redux/actions/notification';
import { getTranslate } from 'react-localize-redux';

export default function Notification () {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const open = useSelector(state => state.notification.open);
  const color = useSelector(state => state.notification.color);
  const autoHideDuration = useSelector(state => state.notification.autoHideDuration);
  const actionNode = useSelector(state => state.notification.actionNode);
  const messageNode = useSelector(state => state.notification.messageNode);
  const messageParams = useSelector(state => state.notification.messageParams);

  let action = actionNode;
  if (!action) {
    action = [
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => { dispatch(requestCloseCallback()); }}
      >
        <CloseIcon/>
      </IconButton>
    ];
  }

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        message={typeof messageNode === 'object' ? messageNode : translate(messageNode, messageParams)}
        autoHideDuration={autoHideDuration}
        action={action}
        onClose={() => { dispatch(requestCloseCallback()); }}
        ContentProps={{ style: { backgroundColor: color } }}
        style={{ backgroundColor: color }}
      />
    </Portal>
  );
}
