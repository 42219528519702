import React, { Component } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MediaQuery from 'react-responsive';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ScreenSize from 'variables/screenSize';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles/index';
import settings from 'settings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorSelected } from 'redux/actions';

const styles = (theme) => ({
  root: {},
  titleResponsive: {
    marginTop: '1em'
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(3)
  },
  errorIcon: {
    color: 'red'
  },
  errorMessage: {
    whiteSpace: 'pre-wrap'
  }
});

class ErrorView extends Component {
  static propTypes = {
    errors: PropTypes.array,
    selectedError: PropTypes.object,
    errorSelected: PropTypes.func,
    classes: PropTypes.object
  };

  constructor (props) {
    super(props);

    this.handleSelection = this.handleSelection.bind(this);
  }

  handleSelection (index) {
    this.props.errorSelected(index);
  }

  render () {
    const { errors, selectedError, classes } = this.props;

    return (
      <div className={classes.root}>
        <MediaQuery maxDeviceWidth={ScreenSize.MOBILE}>
          {(matches) => {
            if (matches) {
              return (
                <Typography
                  variant={'h5'}
                  className={classes.titleResponsive}
                >
                  {'An error occurred!'}
                </Typography>
              );
            } else {
              return (
                <Typography
                  variant={'h5'}
                >
                  {'An error occurred!'}
                </Typography>
              );
            }
          }}
        </MediaQuery>

        <Grid
          className={classes.grid}
          container
          spacing={10}
        >

          <Grid
            item
            xs={4}
          >

            <Paper className={classes.paper}>

              <Typography variant={'subtitle1'}>
                {'List of Errors'}
              </Typography>

              <List>
                {errors && errors.map((error, index) => (

                  <ListItem
                    key={index}
                    button
                    onClick={() => this.handleSelection(index)}
                  >
                    <ListItemIcon className={classes.errorIcon}>
                      <ErrorIcon color={'error'}/>
                    </ListItemIcon>
                    <ListItemText
                      primary={error.name}
                      secondary={error.date.format(settings.dateTimeFormat)}
                    />
                  </ListItem>
                ))}

              </List>

            </Paper>

          </Grid>

          <Grid
            item
            xs={8}
          >

            {selectedError && (

              <Paper className={classes.paper}>

                <Typography variant={'subtitle1'}>
                  {`${selectedError.name} (${selectedError.date.format(settings.dateTimeFormat)})`}
                </Typography>

                <Typography variant={'caption'}>
                  <pre className={classes.errorMessage}>
                    {selectedError.message}
                  </pre>
                </Typography>

                <Typography variant={'body1'}>
                  {'Stack Trace'}
                </Typography>

                <Typography variant={'caption'}>
                  <pre className={classes.errorMessage}>
                    {selectedError.stack ? selectedError.stack : 'n/a'}
                  </pre>
                </Typography>

              </Paper>
            )}

          </Grid>

        </Grid>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.error.errors,
    selectedError: state.error.selectedError
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ errorSelected }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorView));
