import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';

const ImageCropperStyle = (theme) => ({
  ...dialogInputStyle(theme),
  cropContainer: {
    position: 'relative',
    width: '100%',
    minWidth: 500,
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400
    }
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16
  },
  controls: {
    padding: 16,
    alignItems: 'stretch'
  },
  sliderLabel: {
    fontSize: '1rem',
    minWidth: 65
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16
  }
});

export default ImageCropperStyle;
