import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';
import {
  additionalColor
} from 'assets/sts/jss/sts-react';

const createEditSchoolStyle = (theme) => ({
  ...defaultFormStyle(theme),

  keyboardSwitch: {
    marginTop: '-30.3px',
    marginBottom: '6px',
    textTransform: 'uppercase',
    color: additionalColor,
    fontWeight: 'bold'
  },
  keyboardSwitch600: {
    marginBottom: '6px',
    textTransform: 'uppercase',
    color: additionalColor,
    fontWeight: 'bold'
  },
  addressTitle: {
    fontSize: '1rem',
    textTransform: 'none'
  }
});

export default createEditSchoolStyle;
