import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SchoolIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import {
  getProvinces,
  getDistricts,
  getCommunes,
  getVillages,
  clearUnits
} from 'redux/actions/gazetteerCode';

import styles from 'assets/sts/jss/views/school/createEditSchoolStyle';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';
import PropTypes from 'prop-types';
import { getSchool } from 'redux/actions/school';
import { getAcademicYears, getAcademicYear } from 'redux/actions/academicYear';
import { getStaffs } from 'redux/actions/staff';

import * as VARIABLES from 'variables/school';
import * as helpers from 'utilities/helpers';
import { groups as GROUPS } from 'variables/user';
import { getGazetteerChoices } from 'utilities/gazetteer';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import * as moment from 'moment';
import settings from 'settings';
import * as ACADEMICSTATUS from 'variables/academicYearStatus';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(styles);

const CreateEditSchool = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const isLoading = useSelector(state => state.school.isLoading);
  const schoolId = props.match.params.schoolId;
  const school = useSelector((state) => state.school.school);
  const schoolManagers = useSelector((state) => state.staff.staffs);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const academicYearDetail = useSelector(state => state.academicYear.academicYearDetail);
  const authProfile = useSelector(state => state.auth.profile);

  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [communeChoices, setCommuneChoices] = useState([]);
  const [villageChoices, setVillageChoices] = useState([]);
  const [schoolManagerChoices, setSchoolManagerChoices] = useState([]);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);

  const [schoolName, setSchoolName] = useState('');
  const [schoolNameEn, setSchoolNameEn] = useState('');
  const [emisSchoolCode, setEmisSchoolCode] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [schoolManager, setSchoolManager] = useState('');

  const [degree, setDegree] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [status, setStatus] = useState(helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']));
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [commune, setCommune] = useState('');
  const [village, setVillage] = useState('');
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [phoneNumber3, setPhoneNumber3] = useState('');
  const [isShowPhone, setIsShowPhone] = useState(false);

  const [accomplishmentStatus, setAccomplishmentStatus] = useState(0);

  // set title
  useEffect(() => {
    document.title = `${translate('school.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('school.pageTitle');
  }, [translate]);

  useEffect(
    () => {
      if (school && schoolId) {
        setSchoolCode(school.code);
        setSchoolName(school.name || '');
        setSchoolNameEn(school.name_en);
        setEmisSchoolCode(school.emis_code);
        setStatus(school.status);
        setDegree(school.degree.number);
        setPhoneNumber1(school.phone_number_1);
        setPhoneNumber2(school.phone_number_2 || '');
        setPhoneNumber3(school.phone_number_3 || '');
        setAcademicYear(school.academic_year_id);
        setSchoolManager(school.principle ? school.principle.staff_id : '');
        setProvince(`${school.province.id},${school.province.code}`);
        setDistrict(`${school.district.id},${school.district.code}`);
        setCommune(`${school.commune.id},${school.commune.code}`);
        setVillage(`${school.village.id},${school.village.code}`);
        setAccomplishmentStatus(school.accomplishment_status || 0);
      } else {
        setSchoolCode('');
        setSchoolName('');
        setSchoolNameEn('');
        setEmisSchoolCode('');
        setStatus(helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']));
        setDegree('');
        setPhoneNumber1('');
        setPhoneNumber2('');
        setPhoneNumber3('');
        setAcademicYear('');
        setSchoolManager('');
        setProvince('');
        setDistrict('');
        setCommune('');
        setVillage('');
        setAccomplishmentStatus(0);
      }
      if (authProfile) {
        if (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER) {
          if (school && authProfile.school_id === school.school_id) {
            setIsShowPhone(true);
          } else {
            setIsShowPhone(false);
          }
        } else {
          setIsShowPhone(authProfile.groups[0] !== GROUPS.SCHOOL_MANAGEMENT && authProfile.groups[0] !== GROUPS.TEACHER);
        }
      }
    }
    // eslint-disable-next-line
    , [school, schoolId, authProfile]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    fetchProvincesData();
  }, [fetchProvincesData]);

  useEffect(
    () => {
      setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
    }
    // eslint-disable-next-line
      , [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== '') {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
    },
    [dispatch, province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(
    () => {
      setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
    }
    // eslint-disable-next-line
      , [districts, localize]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(
    () => {
      if (district !== '') {
        dispatch(getCommunes(district));
      }
      dispatch(clearUnits('district'));
    },
    [dispatch, district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(
    () => {
      setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes));
    }
    // eslint-disable-next-line
      , [communes, localize]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(
    () => {
      if (commune !== '') {
        dispatch(getVillages(commune));
      }
      dispatch(clearUnits('commune'));
    },
    [dispatch, commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(
    () => {
      setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages));
    }
    // eslint-disable-next-line
      , [villages, localize]);

  const fetchStaffs = useCallback(
    () => {
      dispatch(getStaffs(GROUPS.SCHOOL_MANAGEMENT, schoolId));
    },
    [dispatch, schoolId]
  );

  useEffect(
    () => {
      fetchStaffs();
    },
    // eslint-disable-next-line
      [fetchStaffs]);

  useEffect(
    () => {
      setSchoolManagerChoices(schoolManagers.map(manager => {
        return {
          label: manager.user ? `${manager.user.last_name} ${manager.user.first_name}` : '',
          value: manager.id
        };
      }));
    }
    // eslint-disable-next-line
      , [schoolManagers]);

  const fetchData = useCallback(
    () => {
      if (schoolId) {
        dispatch(getSchool(schoolId));
      }
    },
    [dispatch, schoolId]
  );

  // fetch Academic Years School
  const fetchAcademicYears = useCallback(
    () => {
      if (!schoolId) {
        dispatch(getAcademicYears());
      }
    },
    [dispatch, schoolId]
  );

  const fetchAcademicYear = useCallback(
    () => {
      if (school) {
        dispatch(getAcademicYear(school.academic_year_id));
      }
    },
    [dispatch, school]
  );

  useEffect(() => {
    fetchAcademicYears();
    fetchAcademicYear();
  }, [fetchAcademicYears, fetchAcademicYear]);

  useEffect(() => {
    let choice;
    if (academicYears.length > 0) {
      choice = academicYears.filter(academic =>
        academic.status === ACADEMICSTATUS.DRAFT || academic.status === ACADEMICSTATUS.PRE
      ).map(academic => {
        const academicStartDate = moment(academic.start_date, settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        return {
          value: academic.id,
          label: studyYear
        };
      });
    }
    if (academicYearDetail) {
      const academicStartDate = moment(academicYearDetail.start_date, settings.dateFormat);
      const academicEndDate = moment(academicYearDetail.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${academicYearDetail.status}`).toUpperCase();
      const studyYear = (academicYearDetail.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
      choice = [{
        value: academicYearDetail.id,
        label: studyYear
      }];
    }
    setAcademicYearChoices(choice);
  }, [academicYears, academicYearDetail, translate]);

  useEffect(
    () => {
      if (!isLoading) {
        fetchData();
      }
    },
    // eslint-disable-next-line
      [fetchData]);

  const form = (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <SchoolIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('school.view')}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
                lg={8}
              >
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Input
                      label={translate('school.schoolName')}
                      placeholder={translate('school.placeholder.schoolName')}
                      value={schoolName}
                      name="schoolName"
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Input
                      label={translate('school.schoolNameEn')}
                      placeholder={translate('school.placeholder.schoolNameEn')}
                      value={schoolNameEn}
                      name="schoolNameEn"
                      disabled
                      helperText={''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Input
                      label={translate('school.emisSchoolCode')}
                      placeholder={translate('school.placeholder.emisSchoolCode')}
                      value={emisSchoolCode}
                      name="emisSchoolCode"
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Input
                      label={translate('school.schoolCode')}
                      placeholder={translate('school.placeholder.schoolCode')}
                      value={schoolCode}
                      name="schoolCode"
                      disabled
                      helperText={''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('school.schoolManager')}
                      placeholder={translate('school.placeholder.schoolManager')}
                      value={schoolManager}
                      name="schoolManager"
                      choices={schoolManagerChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('school.degree')}
                      placeholder={translate('school.placeholder.degree')}
                      value={degree}
                      name="degree"
                      choices={Object.keys(VARIABLES.degree).map(degree => (
                        {
                          label: translate(`school.schoolDegree.${VARIABLES.degree[degree].code}`),
                          value: degree
                        }
                      ))}
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('common.status')}
                      placeholder={translate('common.placeholder.status')}
                      value={status}
                      name="status"
                      disabled
                      choices={
                        [
                          { label: translate(`school.schoolStatus.${VARIABLES.status['1']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']) },
                          { label: translate(`school.schoolStatus.${VARIABLES.status['0']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['0']) }
                        ]
                      }
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('school.academicYear')}
                      placeholder={translate('school.placeholder.academicYear')}
                      value={academicYear}
                      name="academicYear"
                      choices={academicYearChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem
                xs={12}
                lg={4}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">{translate('school.accomplishmentStatus.title')}</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={accomplishmentStatus >= 1}
                          color="primary"
                          disabled
                        />
                      }
                      label={translate('school.accomplishmentStatus.completedPreparation')}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={accomplishmentStatus >= 2}
                          color="primary"
                          disabled
                        />
                      }
                      label={translate('school.accomplishmentStatus.completedRunning')}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={accomplishmentStatus >= 3}
                          color="primary"
                          disabled
                        />
                      }
                      label={translate('school.accomplishmentStatus.completedClosing')}
                    />
                  </FormGroup>
                </FormControl>
              </GridItem>

              <GridItem
                xs={12}
              >
                <Typography
                  variant="h5"
                  classes={{
                    h5: classes.addressTitle
                  }}
                >
                  {translate('school.addressContact')}
                </Typography>
              </GridItem>
              <GridItem
                xs={12}
                className={
                  classes.addressGrid
                }
              >
                <GridContainer
                  className={
                    classes.innerGridContainer
                  }
                >
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.province')}
                      placeholder={translate('gazetteer.placeholder.province')}
                      value={province}
                      name="province"
                      choices={provinceChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.district')}
                      placeholder={translate('gazetteer.placeholder.district')}
                      value={district}
                      name="district"
                      choices={districtChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.commune')}
                      placeholder={translate('gazetteer.placeholder.commune')}
                      value={commune}
                      name="commune"
                      choices={communeChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.village')}
                      placeholder={translate('gazetteer.placeholder.village')}
                      value={village}
                      name="village"
                      choices={villageChoices}
                      disabled
                      helperText={''}
                    />
                  </GridItem>
                  {
                    (isShowPhone) && (
                      <>
                        <GridItem
                          xs={12}
                          md={6}
                        >
                          <Input
                            label={translate('school.phoneNumber1')}
                            placeholder={translate('school.placeholder.phoneNumber1')}
                            value={phoneNumber1}
                            name="phoneNumber1"
                            disabled
                            helperText={''}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={6}
                        >
                          <Input
                            label={translate('school.phoneNumber2')}
                            placeholder={translate('school.placeholder.phoneNumber2')}
                            value={phoneNumber2}
                            name="phoneNumber2"
                            disabled
                            helperText={''}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={6}
                        >
                          <Input
                            label={translate('school.phoneNumber3')}
                            placeholder={translate('school.placeholder.phoneNumber3')}
                            value={phoneNumber3}
                            name="phoneNumber3"
                            disabled
                            helperText={''}
                          />
                        </GridItem>
                      </>
                    )
                  }
                </GridContainer>
              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
  return (
    <div>
      {isLoading && <LoadingSpinner/>}
      {form}
    </div>
  );
};

CreateEditSchool.propTypes = {
  match: PropTypes.instanceOf(Object)
};

export default CreateEditSchool;
