export const ENROLL_STATUS = {
  0: 'enrollment',
  1: 'promotion',
  2: 'repetition',
  3: 'dropout',
  4: 're-enroll'
};

export const TRANSFER_STATUS = {
  0: 'not-transfer',
  1: 'transfer-in'
};
