export const GET_TRANSFER_LIST_REQUEST = 'GET_TRANSFER_LIST_REQUEST';
export const GET_TRANSFER_LIST_SUCCESS = 'GET_TRANSFER_LIST_SUCCESS';
export const GET_TRANSFER_LIST_FAIL = 'GET_TRANSFER_LIST_FAIL';

export const GET_TRANSFER_STUDENT_REQUEST = 'GET_TRANSFER_STUDENT_REQUEST';
export const GET_TRANSFER_STUDENT_SUCCESS = 'GET_TRANSFER_STUDENT_SUCCESS';
export const GET_TRANSFER_STUDENT_FAIL = 'GET_TRANSFER_STUDENT_FAIL';
export const RESET_TRANSFER_STUDENT = 'RESET_TRANSFER_STUDENT';

export const TRANSFER_STUDENT_REQUEST = 'TRANSFER_STUDENT_REQUEST';
export const TRANSFER_STUDENT_SUCCESS = 'TRANSFER_STUDENT_SUCCESS';
export const TRANSFER_STUDENT_FAIL = 'TRANSFER_STUDENT_FAIL';

export const GET_TRANSFER_REVIEW_REQUEST = 'GET_TRANSFER_REVIEW_REQUEST';
export const GET_TRANSFER_REVIEW_SUCCESS = 'GET_TRANSFER_REVIEW_SUCCESS';
export const GET_TRANSFER_REVIEW_FAIL = 'GET_TRANSFER_REVIEW_FAIL';

export const APPROVE_TRANSFER_REQUEST = 'APPROVE_TRANSFER_REQUEST';
export const APPROVE_TRANSFER_SUCCESS = 'APPROVE_TRANSFER_SUCCESS';
export const APPROVE_TRANSFER_FAIL = 'APPROVE_TRANSFER_FAIL';

export const GET_TRANSFER_TO_SCHOOL_LIST_REQUEST = 'GET_TRANSFER_TO_SCHOOL_LIST_REQUEST';
export const GET_TRANSFER_TO_SCHOOL_LIST_SUCCESS = 'GET_TRANSFER_TO_SCHOOL_LIST_SUCCESS';
export const GET_TRANSFER_TO_SCHOOL_LIST_FAIL = 'GET_TRANSFER_TO_SCHOOL_LIST_FAIL';
