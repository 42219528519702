import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import TransferIcon from '@material-ui/icons/SwapHorizontalCircle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import Select from 'components/common/Select/Select';
import * as moment from 'moment';
import settings from 'settings';
import { getClassroomList } from 'redux/actions';
import { getSchoolName } from 'utilities/helpers';
import { degree as SCHOOL_DEGREE } from 'variables/school';

const useStyles = makeStyles(styles);

const TransferDialog = props => {
  const classes = useStyles();
  const {
    transferStudent,
    academicYear,
    isOpenDialog,
    dialogTitle,
    transferGrade,
    errorTransferGrade,
    transferSuffix,
    errorTransferSuffix,
    onChangeHandler,
    onHandleSave,
    onHandleClose
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const classroomList = useSelector(state => state.classroom.classroomList);
  const allGrades = useSelector(state => state.studentOption.grade);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);

  const [transferSchool, setTransferSchool] = useState('');

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears.map(academic => {
        const academicStartDate = moment(academic.start_date, settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        return {
          value: academic.id,
          label: studyYear
        };
      });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // set school choices
  useEffect(() => {
    if (transferStudent && transferStudent.transfer_to_school) {
      const school = transferStudent.transfer_to_school;
      const choice = [{
        value: school.id,
        label: getSchoolName(getActiveLanguage(localize).code, translate, school)
      }];
      setSchoolChoices(choice);
      setTransferSchool(school.id);
    }
    // eslint-disable-next-line
  }, [transferStudent, localize]);

  // set grade choices
  useEffect(() => {
    if (transferStudent && transferStudent.classroom && allGrades.length > 0) {
      const gradeNumber = transferStudent.classroom.grade;
      let choices = [];

      if (transferStudent.transfer_to_school.degree.number === SCHOOL_DEGREE.PRE) {
        choices = allGrades.filter(grade => grade.number < 1)
          .map(grade => ({
            value: grade.number,
            label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
          }));
      } else {
        const grade = allGrades.find(g => g.number === gradeNumber);
        choices = [{
          value: gradeNumber,
          label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
        }];
      }

      setGradeChoices(choices);
    }
  }, [transferStudent, localize, allGrades]);

  // fetch classes by school
  useEffect(() => {
    if (transferStudent && transferStudent.transfer_to_school) {
      dispatch(getClassroomList({ school: transferStudent.transfer_to_school.id }));
    }
  }, [dispatch, transferStudent]);

  // set suffix choices
  useEffect(() => {
    if (transferStudent && transferStudent.classroom && classroomList.length > 0) {
      const grade = transferStudent.transfer_to_school.degree.number === SCHOOL_DEGREE.PRE ? transferGrade : transferStudent.classroom.grade;
      const choices = classroomList
        .filter(classroom => classroom.grade === parseInt(grade))
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);
    } else {
      setSuffixChoices([]);
    }
  }, [transferStudent, classroomList, transferGrade]);

  const renderAcademicYearLabel = () => {
    const selectedAcademic = academicYears.find(a => a.id === academicYear);
    if (selectedAcademic) {
      const academicStartDate = moment(selectedAcademic.start_date, settings.dateFormat);
      const academicEndDate = moment(selectedAcademic.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${selectedAcademic.status}`).toUpperCase();
      return (selectedAcademic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
    }
    return '';
  };

  const renderSchoolLabel = () => {
    const fromSchool = transferStudent.transfer_from_school || null;
    if (fromSchool) {
      return getSchoolName(getActiveLanguage(localize).code, translate, fromSchool);
    }
    return '';
  };

  const renderClassroomLabel = () => {
    const classroom = transferStudent.from_classroom;
    if (classroom) {
      const selectedGrade = allGrades.find(g => g.number === classroom.grade);
      if (selectedGrade !== undefined) {
        const gradeLabel = getActiveLanguage(localize).code === 'en' ? selectedGrade.name_en : selectedGrade.name;
        if (classroom.suffix) {
          return `${gradeLabel} (${classroom.suffix})`;
        } else {
          return gradeLabel;
        }
      }
    }
    return '';
  };

  if (!transferStudent) {
    return null;
  }

  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <TransferIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {dialogTitle}
            </h6>
            <p className={classes.dialogDescription}>
              {translate('transfer.review')}
            </p>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
          >
            <h4>{translate('transfer.from')}</h4>
            <GridItem
              xs={12}
              className={
                classes.boxGrid
              }
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={7}
                >
                  <p>
                    <span className={classes.summaryLabel}>{translate('enrollment.academicYear')}</span>
                    <strong>{renderAcademicYearLabel()}</strong>
                  </p>
                  <p>
                    <span className={classes.summaryLabel}>{translate('graduation.school')}</span>
                    <strong>{renderSchoolLabel()}</strong>
                  </p>
                  <p>
                    <span className={classes.summaryLabel}>{translate('classroom.classroom')}</span>
                    <strong>{renderClassroomLabel()}</strong>
                  </p>
                </GridItem>
                <GridItem
                  xs={12}
                  md={5}
                >
                  <p>
                    <span className={classes.summaryLabel}>{translate('common.stsId')}</span>
                    <strong>{transferStudent.student.emis_student_id}</strong>
                  </p>
                  <p>
                    <span className={classes.summaryLabel}>{translate('common.name')}</span>
                    <strong>{transferStudent.student.last_name} {transferStudent.student.first_name}</strong>
                  </p>
                  <p>
                    <span className={classes.summaryLabel}>{translate('common.gender')}</span>
                    <strong>
                      {transferStudent.student.gender === 'm' ? translate('common.genderType.m') : transferStudent.student.gender === 'f' ? translate('common.genderType.f') : ''}
                    </strong>
                  </p>
                  <p>
                    <span className={classes.summaryLabel}>{translate('enrollment.birthDate')}</span>
                    <strong>{transferStudent.student.birth_date}</strong>
                  </p>
                </GridItem>

                <GridItem
                  xs={12}
                >
                  <Input
                    require
                    label={translate('transfer.reason')}
                    placeholder={translate('transfer.placeholder.reason')}
                    value={transferStudent.transfer_reason}
                    name="reason"
                    disabled
                    helperText=""
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <h4>{translate('transfer.to')}</h4>
            <GridItem
              xs={12}
              className={
                classes.boxGrid
              }
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="transferAcademicYear"
                    choices={academicYearChoices}
                    disabled
                    require
                    helperText=""
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('graduation.school')}
                    placeholder={translate('graduation.placeholder.school')}
                    value={transferSchool}
                    name="transferSchool"
                    choices={schoolChoices}
                    disabled
                    require
                    helperText=""
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Input
                    require
                    label={translate('graduation.schoolCode')}
                    placeholder={translate('graduation.placeholder.schoolCode')}
                    value={transferStudent.transfer_to_school.emis_code}
                    name="transferSchoolCode"
                    disabled
                    helperText=""
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={transferGrade}
                    name="transferGrade"
                    onChange={e => onChangeHandler(e, 'transferGrade')}
                    choices={gradeChoices}
                    disabled={transferStudent.transfer_to_school.degree.number !== 1}
                    require
                    error={errorTransferGrade}
                    helperText=""
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('student.placeholder.suffix')}
                    value={transferSuffix}
                    name="transferSuffix"
                    onChange={e => onChangeHandler(e, 'transferSuffix')}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0}
                    require
                    error={errorTransferSuffix}
                    helperText={errorTransferSuffix ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onHandleClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          <ThemeButton
            onClick={onHandleSave}
            color="primary"
            variant="contained"
          >
            {translate('transfer.approve')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

TransferDialog.propTypes = {
  transferStudent: PropTypes.object,
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isOpenDialog: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
  transferGrade: PropTypes.number,
  errorTransferGrade: PropTypes.bool,
  transferSuffix: PropTypes.string,
  errorTransferSuffix: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onHandleSave: PropTypes.func,
  onHandleClose: PropTypes.func
};

export default TransferDialog;
