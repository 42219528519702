import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isDownloading: false
};

function downloadReportSuccess (state, action) {
  return {
    ...state,
    isDownloading: false
  };
}

function downloadReportFail (state) {
  return {
    ...state,
    isDownloading: false
  };
}

function downloadReportRequested (state) {
  return {
    ...state,
    isDownloading: true
  };
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_REPORT_REQUEST:
      return downloadReportRequested(state);
    case actionTypes.DOWNLOAD_REPORT_SUCCESS:
      return downloadReportSuccess(state, action);
    case actionTypes.DOWNLOAD_REPORT_FAIL:
      return downloadReportFail(state);
    default:
      return state;
  }
};

export default reportReducer;
