import {
  cardTitle
  , primaryColor
  , grayColor
} from 'assets/theme/jss/material-dashboard-pro-react.js';

import {
  danger,
  openInNewColor, secondaryButton,
  success
} from 'assets/sts/jss/sts-react';

import buttonStyle from 'assets/theme/jss/material-dashboard-pro-react/components/buttonStyle';

const defaultListingStyle = (theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  right: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  },
  marginTopMd: {
    marginTop: '30px'
  },
  openInNewButton: {
    color: openInNewColor
  },
  successButton: {
    color: success
  },
  allButton: {
    float: 'right'
  },
  gridContainerStyle: {
    margin: '10px 20px'
  },
  tooltip: {
    display: 'inline-block'
  },
  tableIconButton: {
    margin: '0 0 0 5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  cancelButton: {
    backgroundColor: secondaryButton,
    marginRight: '20px'
  },
  toolbar: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  tableCheckbox: {
    color: primaryColor[0] + '!important'
  },
  tableTopEdit: {
    borderRadius: '3px',
    '&:hover': {
      borderRadius: '3px'
    }
  },
  inactive: {
    color: danger
  },
  disabledButton: {
    color: grayColor
  },
  ...buttonStyle
});

export default defaultListingStyle;
