import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  scholarship: [],
  disability: [],
  healthProblem: [],
  personalProblem: [],
  ethnicity: [],
  grade: []
};

const getStudentOptionsRequested = (state) => ({
  ...state,
  isLoading: true
});

const getStudentOptionsSuccess = (state, action) => ({
  ...state,
  scholarship: action.studentOptions.scholarship,
  disability: action.studentOptions.disability,
  healthProblem: action.studentOptions.healthProblem,
  personalProblem: action.studentOptions.personalProblem,
  ethnicity: action.studentOptions.ethnicity,
  grade: action.studentOptions.grade,
  isLoading: false
});

const getStudentOptionsFail = (state) => ({
  ...state,
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_OPTIONS_REQUEST:
      return getStudentOptionsRequested(state);
    case actionTypes.GET_STUDENT_OPTIONS_SUCCESS:
      return getStudentOptionsSuccess(state, action);
    case actionTypes.GET_STUDENT_OPTIONS_FAIL:
      return getStudentOptionsFail(state);

    default:
      return state;
  }
};

export default reducer;
