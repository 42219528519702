export const GET_SCHOLARSHIP_FUNDING_LIST_REQUEST = 'GET_SCHOLARSHIP_FUNDING_LIST_REQUEST';
export const GET_SCHOLARSHIP_FUNDING_LIST_SUCCESS = 'GET_SCHOLARSHIP_FUNDING_LIST_SUCCESS';
export const GET_SCHOLARSHIP_FUNDING_LIST_FAIL = 'GET_SCHOLARSHIP_FUNDING_LIST_FAIL';

export const ADD_SCHOLARSHIP_FUNDING_REQUEST = 'ADD_SCHOLARSHIP_FUNDING_REQUEST';
export const ADD_SCHOLARSHIP_FUNDING_SUCCESS = 'ADD_SCHOLARSHIP_FUNDING_SUCCESS';
export const ADD_SCHOLARSHIP_FUNDING_FAIL = 'ADD_SCHOLARSHIP_FUNDING_FAIL';

export const UPDATE_SCHOLARSHIP_FUNDING_REQUEST = 'UPDATE_SCHOLARSHIP_FUNDING_REQUEST';
export const UPDATE_SCHOLARSHIP_FUNDING_SUCCESS = 'UPDATE_SCHOLARSHIP_FUNDING_SUCCESS';
export const UPDATE_SCHOLARSHIP_FUNDING_FAIL = 'UPDATE_SCHOLARSHIP_FUNDING_FAIL';

export const DELETE_SCHOLARSHIP_FUNDING_REQUEST = 'DELETE_SCHOLARSHIP_FUNDING_REQUEST';
export const DELETE_SCHOLARSHIP_FUNDING_SUCCESS = 'DELETE_SCHOLARSHIP_FUNDING_SUCCESS';
export const DELETE_SCHOLARSHIP_FUNDING_FAIL = 'DELETE_SCHOLARSHIP_FUNDING_FAIL';
