import React from 'react';
import PropTypes from 'prop-types';

import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import { useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getTranslate } from 'react-localize-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ENROLL_STATUS, TRANSFER_STATUS } from 'variables/enrollStatus';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import { groups as GROUPS } from 'variables/user';

export default function EnrollTable (props) {
  const {
    classes,
    isConfirmDialog,
    onHandleConfirm,
    onHandelCancel,
    columns,
    rows,
    allScholarships,
    count,
    limit,
    page,
    onEditRowHandler,
    onDeleteRowHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    selection,
    onSelect,
    onSelectAllClick,
    isLoading,
    assignClassroom
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const authProfile = useSelector(state => state.auth.profile);
  const isSelected = (id) => selection.indexOf(id) !== -1;
  const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        {
          isConfirmDialog && (
            <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
              title={translate('common.modal.confirmTitle')}
              onConfirm={() => onHandleConfirm()}
              onCancel={() => onHandelCancel()}
              confirmBtnCssClass={classes.button + ' ' + classes.success}
              cancelBtnCssClass={classes.button + ' ' + classes.danger}
              confirmBtnText={translate('common.modal.yes')}
              cancelBtnText={translate('common.modal.no')}
              showCancel
            >
              {translate('common.modal.describe')}
            </SweetAlert>
          )
        }
        <Card>
          <CardBody>
            <Toolbar
              className={classes.toolbar}
            >
              <Typography color="inherit" variant="subtitle1" component="div">
                {translate('enrollment.selected', { number: selection.length })}
              </Typography>

              {!isTeacher &&
              (<Tooltip title={translate('enrollment.assignClassroom')}>
                <div>
                  <IconButton aria-label="assign-classroom"
                    color={'primary'}
                    className={classes.tableTopEdit}
                    onClick={assignClassroom}
                    disabled={!selection.length}> <Edit/> <Typography>
                      {translate('enrollment.assignClassroom')}
                    </Typography> </IconButton>
                </div>
              </Tooltip>)
              }
            </Toolbar>

            <Divider />

            <Table
              size='small'
              tableHead={columns}
              tableData={rows.map(row => {
                const editButton = (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={translate('common.button.edit')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        onClick={() => onEditRowHandler(row.id)}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                const deleteButton = (
                  <Tooltip
                    title={translate('common.button.delete')}
                    key={translate('common.button.delete')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='secondary'
                        aria-label='delete'
                        className={classes.tableIconButton}
                        onClick={() => onDeleteRowHandler(row.id)}
                      >
                        <Delete className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                const isItemSelected = isSelected(row.id);
                const checkBox = (
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': row.id }}
                    onChange={e => onSelect(e, row.id, row.stsId)}
                    classes={{
                      checked: classes.tableCheckbox
                    }}
                  />
                );
                return [
                  checkBox,
                  [editButton, deleteButton],
                  row.stsId,
                  row.name,
                  row.gender === 'm' ? translate('common.genderType.m') : row.gender === 'f' ? translate('common.genderType.f') : '',
                  row.birthDate || '',
                  translate(`enrollment.status.${ENROLL_STATUS[row.enrollmentStatus]}`),
                  row.transferStatus === '' ? '' : translate(`transfer.status.${TRANSFER_STATUS[row.transferStatus]}`),
                  row.scholarships && row.scholarships.length > 0
                    ? row.scholarships.map(scholarship => {
                      if (allScholarships) {
                        const index = allScholarships.findIndex(
                          one => scholarship === one.id);
                        return allScholarships[index].type;
                      }
                      return '';
                    }).join(', ')
                    : ''
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              isNeedCheckBox
              numSelected={selection.length}
              onSelectAllClick={onSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customCellClasses={['center']}
              customClassesForCells={[0]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
}

EnrollTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  isConfirmDialog: PropTypes.bool,
  onHandleConfirm: PropTypes.func,
  onHandelCancel: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array,
  allScholarships: PropTypes.array,
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  onEditRowHandler: PropTypes.func,
  onDeleteRowHandler: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  selection: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  isLoading: PropTypes.bool,
  assignClassroom: PropTypes.func
};
