export const DROP_OUT_REASONS = {
  1: {
    code: 'fellBehindWithLessons'
  },
  2: {
    code: 'poorAcademicPerformance'
  },
  3: {
    code: 'cannotPaySchoolExpenses'
  },
  4: {
    code: 'needToWorkToEarnMoney'
  },
  5: {
    code: 'helpWithChoresOrBusiness'
  },
  6: {
    code: 'other'
  }
};
