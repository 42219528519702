import * as actionTypes from 'redux/actionTypes';

const initialState = {
  errors: [],
  selectedError: undefined
};

export default function reducer (state = initialState, action) {
  let newState;

  switch (action.type) {
    case actionTypes.ERROR_RECEIVED:
      newState = {
        ...state,
        errors: [action.error, ...state.errors],
        selectedError: { ...action.error }
      };
      break;

    case actionTypes.ERROR_SELECTED:
      newState = {
        ...state,
        selectedError: action.error
      };
      break;

    default:
      newState = {
        ...state
      };
      break;
  }
  return newState;
}
