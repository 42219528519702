import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  studentList: {},
  studentFilter: [],
  student: null,
  isLoading: false
};

function getStudentListRequested (state) {
  return {
    ...state,
    studentList: {},
    studentFilter: undefined,
    isLoading: true
  };
}

function getStudentListSuccess (state, action) {
  return {
    ...state,
    studentList: action.studentList,
    studentFilter: action.studentFilter,
    isLoading: false
  };
}

function getStudentListFail (state) {
  return {
    ...state,
    studentList: {},
    studentFilter: undefined,
    isLoading: false
  };
}

const getStudentDetailRequested = (state) => ({
  ...state,
  student: null,
  isLoading: true
});

const getStudentDetailSuccess = (state, action) => ({
  ...state,
  student: action.student,
  isLoading: false
});

const getStudentDetailFail = (state) => ({
  ...state,
  isLoading: false,
  student: null
});

const editStudentRequested = (state) => ({
  ...state,
  isLoading: true
});

const editStudentSuccess = (state, action) => ({
  ...state,
  student: action.student,
  isLoading: false
});

const editStudentFail = (state) => ({
  ...state,
  isLoading: false
});

const changeClassroomRequest = (state) => {
  return {
    ...state
  };
};

const changeClassroomSuccess = (state) => {
  return {
    ...state
  };
};

const changeClassroomFail = (state) => {
  return {
    ...state
  };
};

const dropOutRequest = (state) => {
  return {
    isLoading: true,
    ...state
  };
};

const dropOutSuccess = (state) => {
  return {
    isLoading: false,
    ...state
  };
};

const dropOutFail = (state) => {
  return {
    isLoading: false,
    ...state
  };
};

const deleteStudentRequest = (state) => {
  return {
    isLoading: true,
    ...state
  };
};

const deleteStudentSuccess = (state) => {
  return {
    isLoading: false,
    ...state
  };
};

const deleteStudentFail = (state) => {
  return {
    isLoading: false,
    ...state
  };
};

const autoFillFilterByGazetteerRequest = (state) => {
  return {
    ...state,
    isLoading: true
  };
};

const autoFillFilterByGazetteerSuccess = (state, action) => {
  const gazetteers = action.gazetteers;
  const province = gazetteers.find(gazetteer => gazetteer.state === 'province');
  const district = gazetteers.find(gazetteer => gazetteer.state === 'district');
  const commune = gazetteers.find(gazetteer => gazetteer.state === 'commune');
  const village = gazetteers.find(gazetteer => gazetteer.state === 'village');
  const filter = {
    ...state.studentFilter,
    province: `${province.id},${province.code}`,
    district: `${district.id},${district.code}`,
    commune: `${commune.id},${commune.code}`,
    village: `${village.id},${village.code}`
  };
  return {
    ...state,
    studentFilter: filter,
    isLoading: false
  };
};

const autoFillFilterByGazetteerFail = (state) => {
  return {
    ...state,
    isLoading: false
  };
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_LIST_REQUEST:
      return getStudentListRequested(state);
    case actionTypes.GET_STUDENT_LIST_SUCCESS:
      return getStudentListSuccess(state, action);
    case actionTypes.GET_STUDENT_LIST_FAIL:
      return getStudentListFail(state);

    case actionTypes.GET_STUDENT_DETAIL_REQUEST:
      return getStudentDetailRequested(state);
    case actionTypes.GET_STUDENT_DETAIL_SUCCESS:
      return getStudentDetailSuccess(state, action);
    case actionTypes.GET_STUDENT_DETAIL_FAIL:
      return getStudentDetailFail(state);

    case actionTypes.EDIT_STUDENT_REQUEST:
      return editStudentRequested(state);
    case actionTypes.EDIT_STUDENT_SUCCESS:
      return editStudentSuccess(state, action);
    case actionTypes.EDIT_STUDENT_FAIL:
      return editStudentFail(state);

    case actionTypes.CHANGE_CLASSROOM_REQUEST:
      return changeClassroomRequest(state);
    case actionTypes.CHANGE_CLASSROOM_SUCCESS:
      return changeClassroomSuccess(state);
    case actionTypes.CHANGE_CLASSROOM_FAIL:
      return changeClassroomFail(state);

    case actionTypes.STUDENT_DROP_OUT_REQUEST:
      return dropOutRequest(state);
    case actionTypes.STUDENT_DROP_OUT_SUCCESS:
      return dropOutSuccess(state);
    case actionTypes.STUDENT_DROP_OUT_FAIL:
      return dropOutFail(state);

    case actionTypes.DELETE_STUDENT_REQUEST:
      return deleteStudentRequest(state);
    case actionTypes.DELETE_STUDENT_SUCCESS:
      return deleteStudentSuccess(state);
    case actionTypes.DELETE_STUDENT_FAIL:
      return deleteStudentFail(state);

    case actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_REQUEST:
      return autoFillFilterByGazetteerRequest(state);
    case actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_SUCCESS:
      return autoFillFilterByGazetteerSuccess(state, action);
    case actionTypes.AUTO_FILL_FILTER_BY_GAZETTEER_FAIL:
      return autoFillFilterByGazetteerFail(state);
    default:
      return state;
  }
};

export default studentReducer;
