import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions/notification';
import { saveAs } from 'file-saver';
import { STS_REPORTS } from 'variables/report';
import { STS_STUDENT_REPORTS } from 'variables/studentReport';
import * as actionTypes from '../actionTypes';

function downloadReportRequested () {
  return {
    type: actionTypes.DOWNLOAD_REPORT_REQUEST
  };
}

function downloadReportSuccess () {
  return {
    type: actionTypes.DOWNLOAD_REPORT_SUCCESS
  };
}

function downloadReportFail () {
  return {
    type: actionTypes.DOWNLOAD_REPORT_FAIL
  };
}

export const downloadReport = (filterParams, reportFileNameData, classroomId, language) => {
  return (dispatch) => {
    dispatch(downloadReportRequested());
    const params = {
      type: filterParams.report,
      academic_year_school_id: filterParams.school,
      month: filterParams.month,
      scholarship: filterParams.scholarship,
      disability: filterParams.disability,
      health_problem: filterParams.healthProblem,
      personal_problem: filterParams.personalProblem,
      ethnicity: filterParams.ethnicity,
      report_stage: filterParams.reportStage,
      language: language
    };
    if (classroomId) {
      params.classroom_id = classroomId;
    }
    axios.get('/reports/export', { responseType: 'blob', params })
      .then(
        res => {
          const academicYear = reportFileNameData.academicYear;
          const grade = reportFileNameData.grade;
          const suffix = reportFileNameData.suffix;
          const report = reportFileNameData.report;
          const reportTypes = { ...STS_REPORTS, ...STS_STUDENT_REPORTS };
          const fileExtension = reportTypes[params.type].outputFormat.toLowerCase();
          let fileName = academicYear.concat('_', report);
          if (grade !== '' && suffix !== '') {
            fileName = academicYear.concat('_', report, '_', grade, suffix);
          }
          saveAs(res.data, `${fileName}.${fileExtension}`);
          dispatch(downloadReportSuccess());
        }
      )
      .catch(e => {
        // todo: correct "e.response.reportFileNameData.message"
        dispatch(downloadReportFail());
        dispatch(showErrorNotification('report.error.noReportType'));
      });
  };
};
