import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { allChoices, getGazetteerChoices } from 'utilities/gazetteer';

import { useDispatch, useSelector } from 'react-redux';
import settings from 'settings';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Input from 'components/common/Input/Input';

import {
  getAcademicYears,
  getClassroomListForFilter,
  getSchoolList,
  getStudentOptions
} from 'redux/actions';
import { getProvinces, getDistricts, getCommunes, getVillages, clearUnits } from 'redux/actions/gazetteerCode';
import * as moment from 'moment';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import StudentIcon from '@material-ui/icons/AccountCircle';
import CardBody from 'components/theme/Card/CardBody';
import Card from 'components/theme/Card/Card';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import AutoComplete from 'components/common/AutocompleteInput/AutocompleteInput';

const ALL = 'all';

const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const StudentListFilter = (props) => {
  const {
    classes,
    school,
    academicYear,
    grade,
    suffix,
    errorSchool,
    errorAcademicYear,
    errorGrade,
    onChangeHandler,
    handleFilter,
    setSchool,
    schoolId,
    keyword,
    errorProvince,
    setProvince,
    setDistrict,
    setCommune,
    setVillage,
    setIsDuplicateRecord,
    province,
    district,
    commune,
    village,
    setGrade,
    setSuffix,
    isProvincialOfficer,
    isDistrictOfficer,
    isManagementOrTeacher,
    isDuplicateRecord
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const schoolList = useSelector(state => state.school.schoolList);
  const allGrades = useSelector(state => state.studentOption.grade);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const authProfile = useSelector(state => state.auth.profile);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState(allChoices(translate));
  const [districtChoices, setDistrictChoices] = useState(allChoices(translate));
  const [communeChoices, setCommuneChoices] = useState(allChoices(translate));
  const [villageChoices, setVillageChoices] = useState(allChoices(translate));
  const [selectedSchool, setSelectedSchool] = useState('');

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingGazetteer) {
      fetchProvincesData();
    }
    // eslint-disable-next-line
  }, [fetchProvincesData]);

  useEffect(
    () => {
      if (provinces) {
        setProvinceChoices(provinces.map(unit => {
          return {
            label: getActiveLanguage(localize).code === 'en' ? unit.name_en : unit.name,
            value: `${unit.id},${unit.code}`
          };
        }));
      }
    }
    // eslint-disable-next-line
    , [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== '') {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
    },
    [dispatch, province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(
    () => {
      setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
    }
    // eslint-disable-next-line
    , [districts, localize]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(
    () => {
      if (district !== ALL) {
        dispatch(getCommunes(district));
      }
      dispatch(clearUnits('district'));
    },
    [dispatch, district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(
    () => {
      setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes, translate));
    }
    // eslint-disable-next-line
    , [communes, localize]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(
    () => {
      if (commune !== ALL) {
        dispatch(getVillages(commune));
      }
      dispatch(clearUnits('commune'));
    },
    [dispatch, commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(
    () => {
      setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages, translate));
    }
    // eslint-disable-next-line
    , [villages, localize]);

  // fetch Academic Years
  useEffect(() => {
    if (!isLoadingAcademic) {
      dispatch(getAcademicYears());
    }
    if (!isLoadingStudentOption) {
      dispatch(getStudentOptions());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears.filter(academic => academic.status !== ACADEMIC_STATUS.DRAFT)
        .map(academic => {
          const academicStartDate = moment(academic.start_date,
            settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (academicYear && (province !== '' || isManagementOrTeacher)) {
      let gazetteer = province && province !== '' ? province.split(',')[1] : '';
      if (village && village !== 'all') {
        gazetteer = village.split(',')[1];
      } else if (commune && commune !== 'all') {
        gazetteer = commune.split(',')[1];
      } else if (district && district !== 'all') {
        gazetteer = district.split(',')[1];
      }

      const params = isManagementOrTeacher
        ? {
          academic_year_id: academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvincialOfficer) {
        params.gazetteer = authProfile.province.code;
      }
      if (isDistrictOfficer) {
        params.gazetteer = authProfile.district.code;
      }

      if (gazetteer) {
        params.gazetteer = gazetteer;
      }

      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, academicYear, authProfile, province, district, commune, village, isProvincialOfficer, isDistrictOfficer, isManagementOrTeacher]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      if (isManagementOrTeacher && !schoolId) {
        setSchool(schoolList[0].id);
      }
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, authProfile, setSchool, schoolId, translate]);

  // fetch classes by school
  useEffect(() => {
    if (school) {
      const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
      const param = {
        school: school
      };
      if (isTeacher) {
        param.teacher_staff_id = authProfile.userable_id;
      }
      dispatch(getClassroomListForFilter(param, authProfile));
    }
  }, [dispatch, school, authProfile]);

  // set grade choices
  useEffect(() => {
    if (school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          choices.push({
            value: classroom.grade,
            // eslint-disable-next-line
            label: getActiveLanguage(localize).code === 'en' ? grade?.name_en : grade?.name
          });
        }
      });
      if (!isManagementOrTeacher) {
        choices.unshift({ value: 'all', label: translate('common.all') });
      }
      setGradeChoices(choices);
    } else {
      setGradeChoices([]);
    }
  }, [school, classroomList, allGrades, localize, province, translate, isManagementOrTeacher]);

  // set suffix choices
  useEffect(() => {
    if (grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      choices.unshift({ value: 'all', label: translate('common.all') });
      setSuffixChoices(choices);
    } else {
      setSuffixChoices([]);
    }
  }, [grade, classroomList, translate]);

  const onChangeGazetteerHandler = e => {
    const { name, value } = e.target;
    setGrade(ALL);
    setSuffix(ALL);

    switch (name) {
      case 'province':
        setProvince(value);
        setDistrict(ALL);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'district':
        setDistrict(value);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'commune':
        setCommune(value);
        setVillage(ALL);
        break;
      case 'village':
        setVillage(value);
        break;
      default:
    }
  };
  useEffect(() => {
    if (school) {
      const foundSchool = schoolList.find(item => item.id === school);
      if (!foundSchool) {
        setSchool('');
        setSelectedSchool('');
      } else {
        setSelectedSchool({
          label: getSchoolName(getActiveLanguage(localize).code, translate, foundSchool),
          value: foundSchool.id,
          code: foundSchool.emis_code
        });
      }
    }
  }, [school, localize, schoolList, translate, setSchool]);

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setSelectedSchool(school);
      setSchool(school.value);
    } else {
      setSelectedSchool('');
      setSchool('');
    }
  };
  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic || isLoadingStudentOption}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <StudentIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('student.pageListTitle')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                {
                  !isManagementOrTeacher && (
                    <>
                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.province')}
                          placeholder={translate('gazetteer.placeholder.province')}
                          value={province}
                          name="province"
                          onChange={e => onChangeGazetteerHandler(e, 'province')}
                          choices={provinceChoices}
                          require
                          error={errorProvince}
                          disabled={isProvincialOfficer || isDistrictOfficer}
                          helperText={errorProvince ? translate('common.error.require') : ''}
                        />
                      </GridItem>

                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.district')}
                          placeholder={translate('gazetteer.placeholder.district')}
                          value={district}
                          name="district"
                          onChange={e => onChangeGazetteerHandler(e, 'district')}
                          choices={districtChoices}
                          disabled={isLoadingGazetteer || districts.length === 0 || isDistrictOfficer}
                          helperText=""
                        />
                      </GridItem>

                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.commune')}
                          placeholder={translate('gazetteer.placeholder.commune')}
                          value={commune}
                          name="commune"
                          onChange={e => onChangeGazetteerHandler(e, 'commune')}
                          choices={communeChoices}
                          disabled={isLoadingGazetteer || communes.length === 0}
                          helperText=""
                        />
                      </GridItem>

                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.village')}
                          placeholder={translate('gazetteer.placeholder.village')}
                          value={village}
                          name="village"
                          onChange={e => onChangeGazetteerHandler(e, 'village')}
                          choices={villageChoices}
                          disabled={isLoadingGazetteer || villages.length === 0}
                          helperText=""
                        />
                      </GridItem>
                    </>
                  )
                }
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="academicYear"
                    onChange={e => onChangeHandler(e, 'academicYear')}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <AutoComplete
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={selectedSchool}
                    name="school"
                    onChange={onSchoolCodeChangeHandler}
                    options={schoolChoices}
                    filterOptions={schoolFilterOptions}
                    disabled={schoolChoices.length === 0 || isManagementOrTeacher}
                    require
                    error={errorSchool}
                    noOptionsText={translate('report.noSchoolChoice')}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={gradeChoices.length ? grade : ''}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={gradeChoices}
                    disabled={gradeChoices.length === 0}
                    require={isManagementOrTeacher}
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('student.placeholder.suffix')}
                    value={suffixChoices.length ? suffix : ''}
                    name="suffix"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Input
                    label={translate('student.studentNameOrIdOrPhone')}
                    placeholder={translate('student.placeholder.studentNameOrIdOrPhone')}
                    value={keyword}
                    name="keyword"
                    onChange={e => onChangeHandler(e, 'keyword')}
                    helperText=""
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => { setIsDuplicateRecord(e.target.checked); }}
                        name="duplicateRecord"
                        color="primary"
                        checked={isDuplicateRecord}
                      />
                    }
                    label={translate('student.duplicate_record')}
                    className={classes.marginTopMd}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton}
              >
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilter}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

StudentListFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string,
  onChangeHandler: PropTypes.func,
  errorSchool: PropTypes.bool,
  errorAcademicYear: PropTypes.bool,
  errorGrade: PropTypes.bool,
  errorSuffix: PropTypes.bool,
  handleFilter: PropTypes.func,
  setSchool: PropTypes.func,
  schoolId: PropTypes.string,
  keyword: PropTypes.string,
  errorProvince: PropTypes.bool,
  setProvince: PropTypes.func,
  setDistrict: PropTypes.func,
  setCommune: PropTypes.func,
  setVillage: PropTypes.func,
  setIsDuplicateRecord: PropTypes.func,
  province: PropTypes.string,
  district: PropTypes.string,
  commune: PropTypes.string,
  village: PropTypes.string,
  setGrade: PropTypes.func,
  setSuffix: PropTypes.func,
  isProvincialOfficer: PropTypes.bool,
  isDistrictOfficer: PropTypes.bool,
  isManagementOrTeacher: PropTypes.bool,
  isDuplicateRecord: PropTypes.bool
};

export default StudentListFilter;
