export const GET_SCHOOL_LIST_REQUEST = 'GET_SCHOOL_LIST_REQUEST';
export const GET_SCHOOL_LIST_SUCCESS = 'GET_SCHOOL_LIST_SUCCESS';
export const GET_SCHOOL_LIST_FAIL = 'GET_SCHOOL_LIST_FAIL';

export const GET_SCHOOL_REQUEST = 'GET_SCHOOL_REQUEST';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_FAIL = 'GET_SCHOOL_FAIL';

export const CREATE_EDIT_SCHOOL_REQUEST = 'CREATE_EDIT_SCHOOL_REQUEST';
export const CREATE_EDIT_SCHOOL_SUCCESS = 'CREATE_EDIT_SCHOOL_SUCCESS';
export const CREATE_EDIT_SCHOOL_FAIL = 'CREATE_EDIT_SCHOOL_FAIL';

export const DELETE_SCHOOL_REQUEST = 'DELETE_SCHOOL_REQUEST';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_FAIL = 'DELETE_SCHOOL_FAIL';

export const RESET_SCHOOL = 'RESET_SCHOOL';
export const RESET_RAW_SCHOOL_LIST = 'RESET_RAW_SCHOOL_LIST';

export const GET_RAW_SCHOOL_LIST_REQUEST = 'GET_RAW_SCHOOL_LIST_REQUEST';
export const GET_RAW_SCHOOL_LIST_SUCCESS = 'GET_RAW_SCHOOL_LIST_SUCCESS';
export const GET_RAW_SCHOOL_LIST_FAIL = 'GET_RAW_SCHOOL_LIST_FAIL';

export const GET_RAW_SCHOOL_BY_EMIS_CODE_REQUEST = 'GET_RAW_SCHOOL_BY_EMIS_CODE_REQUEST';
export const GET_RAW_SCHOOL_BY_EMIS_CODE_SUCCESS = 'GET_RAW_SCHOOL_BY_EMIS_CODE_SUCCESS';
export const GET_RAW_SCHOOL_BY_EMIS_CODE_FAIL = 'GET_RAW_SCHOOL_BY_EMIS_CODE_FAIL';
