import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from 'assets/sts/jss/views/dataQuery/dataQueryStyle';
import axios from 'utilities/httpService';
import { showErrorNotification } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { BACKGROUND_TASK_STATUS } from 'variables/report';
import { getTranslate } from 'react-localize-redux';

const useStyles = makeStyles(styles);

let timer = null;
const BackgroundTaskProgressDialog = ({ backgroundTaskId, onTaskDownloadHandler, onDialogCloseHandler }) => {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const style = useStyles();

  const [progress, setProgress] = useState(0);

  const handleUpdateProgress = useCallback(() => {
    if (!backgroundTaskId) {
      clearInterval(timer);
    }

    axios.get(`/export_history/${backgroundTaskId}`)
      .then(({ data: backgroundTask }) => {
        setProgress(backgroundTask.progress);
        if (backgroundTask.status === BACKGROUND_TASK_STATUS.finished) {
          clearInterval(timer);
          onTaskDownloadHandler(backgroundTask, backgroundTask.result === BACKGROUND_TASK_STATUS.success);
        }
      })
      .catch(e => {
        clearInterval(timer);
        onDialogCloseHandler();
        dispatch(showErrorNotification('report.error.noReportType'));
      });
    // eslint-disable-next-line
  }, [backgroundTaskId, dispatch]);

  useEffect(() => {
    timer = setInterval(handleUpdateProgress, 3000);
  }, [handleUpdateProgress]);

  return (
    <Dialog open={backgroundTaskId !== undefined}>
      <DialogTitle>{ translate('report.export.progress.dialog.title') }</DialogTitle>
      <DialogContent>
        <LinearProgress className={style.barStyle} variant="determinate" value={progress} />
      </DialogContent>
    </Dialog>
  );
};

BackgroundTaskProgressDialog.propTypes = {
  backgroundTaskId: PropTypes.number,
  onTaskDownloadHandler: PropTypes.func,
  onDialogCloseHandler: PropTypes.func
};

export default BackgroundTaskProgressDialog;
