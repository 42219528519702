import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';

import AutoCompleteInputStyle from 'assets/sts/jss/components/autoCompleteInputStyle';
import { makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import cx from 'classnames';

const style = (theme) => (
  {
    ...AutoCompleteInputStyle(theme)
  }
);

export default function AutocompleteInput (props) {
  const classes = makeStyles(style)();
  const {
    name,
    label,
    options,
    onChange,
    filterOptions,
    require,
    placeholder,
    helperText,
    error,
    noOptionsText,
    disabled,
    value,
    disableClearable,
    freeSolo
  } = props;

  const helpTextClasses = cx({
    [classes.labelRootError]: error
  });

  return (
    <div>
      <InputLabel
        required={require}
        htmlFor={name}
        classes={{
          root: classes.labelRoot
        }}
      >
        {label}
      </InputLabel>
      <FormControl
        fullWidth
        error={error}
        className={error ? classes.selectFormControlError : classes.selectFormControl}
      >
        <Autocomplete
          value={value}
          id={name}
          options={options}
          getOptionLabel={option => !option ? option : option.label}
          filterOptions={filterOptions}
          freeSolo={freeSolo}
          disableClearable={disableClearable}
          onChange={onChange}
          noOptionsText={noOptionsText}
          disabled
          classes={{
            listbox: classes.listbox
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                placeholder={placeholder}
                required={require}
                error={error}
                InputProps={{
                  ...params.InputProps,
                  className: classes.input,
                  classes: {
                    underline: classes.underline,
                    disabled: classes.disabled
                  },
                  disabled
                }}
              />
            );
          }}
        />
        {helperText !== undefined ? (
          <FormHelperText id={`${name}-text`} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

AutocompleteInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  filterOptions: PropTypes.func,
  require: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  noOptionsText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  disableClearable: PropTypes.bool,
  freeSolo: PropTypes.bool
};
