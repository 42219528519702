import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { makeStyles } from '@material-ui/core/styles';

import ThemeButton from 'components/theme/CustomButtons/Button';
import ScholarshipFilter from 'views/Scholarship/Partial/ScholarshipFilter';
import ScholarshipTable from 'views/Scholarship/Partial/ScholarshipTable';
import AddStudentDialog from 'views/Scholarship/Partial/AddStudentDialog';
import EditStudentDialog from 'views/Scholarship/Partial/EditStudentDialog';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';

const useStyles = makeStyles(styles);
const Scholarship = () => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const transferFilter = useSelector(state => state.scholarship.scholarshipFilter);
  const school = transferFilter?.school;
  const [openStudentDialog, setOpenStudentDialog] = useState(false);
  const [editId, setEditId] = useState('');
  const [editStudent, setEditStudent] = useState(undefined);

  // set title
  useEffect(() => {
    document.title = `${translate('scholarship.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('scholarship.pageTitle');
  }, [translate]);

  const handleAddStudent = () => {
    setOpenStudentDialog(true);
  };

  const handleEditRow = (id) => {
    setEditId(id);
    setEditStudent(undefined);
    setOpenStudentDialog(true);
  };

  const handleCloseStudentDialog = () => {
    setEditId('');
    setEditStudent(undefined);
    setOpenStudentDialog(false);
  };

  const handleConfirmStudentDialog = (student) => {
    setEditStudent(student);
  };

  return (
    <>
      <ScholarshipFilter />
      <ScholarshipTable onEditRowHandler={handleEditRow} />

      { openStudentDialog && school && !(editId || editStudent !== undefined) &&
        <AddStudentDialog school={school} open={openStudentDialog} onClose={handleCloseStudentDialog} onConfirm={handleConfirmStudentDialog} />
      }

      { openStudentDialog && (editId || editStudent !== undefined) &&
        <EditStudentDialog id={editId} editStudent={editStudent} open={openStudentDialog} onClose={handleCloseStudentDialog} />
      }

      <div className={classes.actionContainer}>
        <ThemeButton
          color="primary"
          type="submit"
          onClick={handleAddStudent}
          disabled={!school}
        >
          {translate('scholarship.addStudent')}
        </ThemeButton>
      </div>
    </>
  );
};

export default Scholarship;
