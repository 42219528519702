import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification, showSuccessNotification
} from 'redux/actions/notification';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';

function getSchoolListRequested () {
  return {
    type: actionTypes.GET_SCHOOL_LIST_REQUEST
  };
}

function getSchoolListSuccess (schoolList, filter) {
  return {
    type: actionTypes.GET_SCHOOL_LIST_SUCCESS,
    schoolList,
    filter
  };
}

function getSchoolListFail () {
  return {
    type: actionTypes.GET_SCHOOL_LIST_FAIL
  };
}

function getSchoolRequested () {
  return {
    type: actionTypes.GET_SCHOOL_REQUEST
  };
}

function getSchoolSuccess (school) {
  return {
    type: actionTypes.GET_SCHOOL_SUCCESS,
    school
  };
}

function getSchoolFail () {
  return {
    type: actionTypes.GET_SCHOOL_FAIL
  };
}

function createEditSchoolRequested () {
  return {
    type: actionTypes.CREATE_EDIT_SCHOOL_REQUEST
  };
}

function createEditSchoolSuccess (schoolList) {
  return {
    type: actionTypes.CREATE_EDIT_SCHOOL_SUCCESS,
    schoolList
  };
}

function createEditSchoolFail () {
  return {
    type: actionTypes.CREATE_EDIT_SCHOOL_FAIL
  };
}

function deleteSchoolRequested () {
  return {
    type: actionTypes.GET_SCHOOL_REQUEST
  };
}

function deleteSchoolSuccess (school) {
  return {
    type: actionTypes.GET_SCHOOL_SUCCESS,
    school
  };
}

function deleteSchoolFail () {
  return {
    type: actionTypes.GET_SCHOOL_FAIL
  };
}

function getRawSchoolListRequested () {
  return {
    type: actionTypes.GET_RAW_SCHOOL_LIST_REQUEST
  };
}

function getRawSchoolListSuccess (rawSchoolList) {
  return {
    type: actionTypes.GET_RAW_SCHOOL_LIST_SUCCESS,
    rawSchoolList
  };
}

function getRawSchoolListFail () {
  return {
    type: actionTypes.GET_RAW_SCHOOL_LIST_FAIL
  };
}

function getRawSchoolByEmisCodeRequested () {
  return {
    type: actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_REQUEST
  };
}

function getRawSchoolByEmisCodeSuccess (school) {
  return {
    type: actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_SUCCESS,
    school
  };
}

function getRawSchoolByEmisCodeFail () {
  return {
    type: actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_FAIL
  };
}

export const getSchoolList = (params, isGetSchoolFromRaw = false) => {
  return (dispatch) => {
    dispatch(getSchoolListRequested());
    axios.get(isGetSchoolFromRaw ? '/school-from-raw' : '/schools', { params })
      .then(
        res => {
          let data;
          if (isGetSchoolFromRaw) {
            data = [res.data.data];
            data.total = 1;
          } else {
            data = res.data.data;
            data.total = res.data.meta.total;
          }
          dispatch(getSchoolListSuccess(data, params));
        }
      )
      .catch(e => {
        dispatch(getSchoolListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getRawSchoolList = (params) => {
  return (dispatch) => {
    dispatch(getRawSchoolListRequested());
    axios.get('/raw-schools', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getRawSchoolListSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getRawSchoolListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getSchool = (id) => {
  return (dispatch) => {
    dispatch(getSchoolRequested());
    axios.get(`/schools/${id}`)
      .then(
        res => {
          const data = res.data.data;
          dispatch(getSchoolSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getSchoolFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const createEditSchool = (data, id = false, successfulMessage) => {
  return (dispatch) => {
    dispatch(createEditSchoolRequested());
    if (id) {
      let params;
      let deactivated;
      let activated;
      if (data.params) {
        params = JSON.parse(JSON.stringify(data.params));
        delete data.params;
      }
      if (data.deactivated) {
        deactivated = data.deactivated;
        delete data.deactivated;
      }

      if (data.activated) {
        activated = data.activated;
        delete data.activated;
      }
      axios.put(`/schools/${id}`, data)
        .then(
          res => {
            dispatch(createEditSchoolSuccess(data));
            dispatch(showSuccessNotification(successfulMessage));
            if (deactivated) {
              dispatch(getSchoolList(params));
            } else if (activated) {
              dispatch(getSchoolList(params));
            } else {
              dispatch(getSchool(id));
            }
          }
        )
        .catch(e => {
          dispatch(createEditSchoolFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    } else {
      axios.post('/schools', data)
        .then(
          res => {
            dispatch(createEditSchoolSuccess(data));
            dispatch(showSuccessNotification(successfulMessage));
            history.push(ROUTES.SCHOOL);
          }
        )
        .catch(e => {
          dispatch(createEditSchoolFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    }
  };
};

export const deleteSchool = (id, params, successfulMessage) => {
  return (dispatch) => {
    dispatch(deleteSchoolRequested());
    axios.delete(`/schools/${id}`)
      .then(
        res => {
          dispatch(deleteSchoolSuccess());
          dispatch(showSuccessNotification(successfulMessage));
          dispatch(getSchoolList(params));
        }
      )
      .catch(e => {
        dispatch(deleteSchoolFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetSchool = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_SCHOOL });
  };
};

export const clearRawSchoolList = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_RAW_SCHOOL_LIST });
  };
};

export const requestCreateNewSchool = (params) => {
  return (dispatch) => {
    dispatch(getRawSchoolByEmisCodeRequested());
    axios.get('/get-school-by-emiscode', { params })
      .then(
        res => {
          const data = res.data;
          if (data.error) {
            dispatch(showErrorNotification('school.schoolAlreadyExists'));
            dispatch(getRawSchoolByEmisCodeSuccess(null));
          } else {
            dispatch(getRawSchoolByEmisCodeSuccess(data.data));
            history.push(ROUTES.SCHOOL_NEW);
          }
        }
      )
      .catch(e => {
        dispatch(getRawSchoolByEmisCodeFail());
        dispatch(showErrorNotification('school.notMatchEmisSchoolCode'));
      });
  };
};
