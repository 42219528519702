export const GET_STUDENT_ACCOUNT_LIST_REQUEST = 'GET_STUDENT_ACCOUNT_LIST_REQUEST';
export const GET_STUDENT_ACCOUNT_LIST_SUCCESS = 'GET_STUDENT_ACCOUNT_LIST_SUCCESS';
export const GET_STUDENT_ACCOUNT_LIST_FAIL = 'GET_STUDENT_ACCOUNT_LIST_FAIL';

export const SEARCH_STUDENT_REQUEST = 'SEARCH_STUDENT_REQUEST';
export const SEARCH_STUDENT_SUCCESS = 'SEARCH_STUDENT_SUCCESS';
export const SEARCH_STUDENT_FAIL = 'SEARCH_STUDENT_FAIL';
export const RESET_STUDENT_SEARCH_RESULT = 'RESET_STUDENT_SEARCH_RESULT';

export const CREATE_EDIT_STUDENT_ACCOUNT_REQUEST = 'CREATE_EDIT_STUDENT_ACCOUNT_REQUEST';
export const CREATE_EDIT_STUDENT_ACCOUNT_SUCCESS = 'CREATE_EDIT_STUDENT_ACCOUNT_SUCCESS';
export const CREATE_EDIT_STUDENT_ACCOUNT_FAIL = 'CREATE_EDIT_STUDENT_ACCOUNT_FAIL';

export const GET_ACC_REQUEST = 'GET_ACC_REQUEST';
export const GET_ACC_SUCCESS = 'GET_ACC_SUCCESS';
export const GET_ACC_FAIL = 'GET_ACC_FAIL';
