import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';
import { getScholarshipFundingList } from 'redux/actions/scholarshipFunding';

function getScholarshipRemittanceListRequest () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_REQUEST
  };
}

function getScholarshipRemittanceListSuccess (scholarshipRemittanceList, scholarshipRemittanceFilter) {
  return {
    type: actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_SUCCESS,
    scholarshipRemittanceList,
    scholarshipRemittanceFilter
  };
}

function getScholarshipRemittanceListFail () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_FAIL
  };
}

function remitScholarshipRemittanceRequest () {
  return {
    type: actionTypes.REMIT_SCHOLARSHIP_REMITTANCE_REQUEST
  };
}

function remitScholarshipRemittanceSuccess (student) {
  return {
    type: actionTypes.REMIT_SCHOLARSHIP_REMITTANCE_SUCCESS,
    student
  };
}

function remitScholarshipRemittanceFail () {
  return {
    type: actionTypes.REMIT_SCHOLARSHIP_REMITTANCE_FAIL
  };
}

export const getScholarshipRemittanceList = (rawParams) => {
  return (dispatch) => {
    const params = {
      school_id: rawParams.school,
      page: rawParams.page + 1,
      limit: rawParams.limit
    };

    dispatch(getScholarshipRemittanceListRequest());
    axios.get('/scholarship_remittance', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          dispatch(getScholarshipRemittanceListSuccess(data, rawParams));
        }
      )
      .catch(e => {
        dispatch(getScholarshipRemittanceListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const remitScholarshipRemittance = (data) => {
  return async (dispatch, getState) => {
    dispatch(remitScholarshipRemittanceRequest());
    data = await axios.post('/scholarship_remittance', data)
      .then(
        () => {
          dispatch(remitScholarshipRemittanceSuccess());
          const filter = getState().scholarshipRemittance.scholarshipRemittanceFilter;
          dispatch(getScholarshipRemittanceList(filter));
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipRemittance.paySuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(remitScholarshipRemittanceFail());
        dispatch(showErrorNotification('scholarshipRemittance.payError'));
      });
    return data;
  };
};

export const updateScholarshipRemittance = (id, data) => {
  return async (dispatch, getState) => {
    data = await axios.put(`/scholarship_remittance/${id}`, data)
      .then(
        () => {
          const filter = getState().scholarshipRemittance.scholarshipRemittanceFilter;
          dispatch(getScholarshipRemittanceList(filter));
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipRemittance.updateSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(showErrorNotification('scholarshipRemittance.updateError'));
      });
    return data;
  };
};

export const deleteScholarshipRemittance = (id) => {
  return (dispatch, getState) => {
    axios.delete(`/scholarship_remittance/${id}`)
      .then(
        () => {
          const filter = getState().scholarshipRemittance.scholarshipRemittanceFilter;
          dispatch(getScholarshipRemittanceList(filter));
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipRemittance.deleteSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(showErrorNotification('scholarshipRemittance.deleteError'));
      });
  };
};
