import React, { useEffect, useCallback } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Typography
} from '@material-ui/core';

import ContactIcon from '@material-ui/icons/Message';
import DataQueryIcon from '@material-ui/icons/InsertChart';
import AboutIcon from '@material-ui/icons/Info';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import styles from 'assets/sts/jss/views/home/homelStyle';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';
import { resetSchool } from 'redux/actions';

const useStyles = makeStyles(styles);

export default function Home () {
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();

  const changeBrandText = useCallback(
    () => {
      document.getElementById('brandText').innerHTML = '';
      dispatch(resetSchool());
      // eslint-disable-next-line
    }, []
  );

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  useEffect(() => {
    document.title = `${translate('home.title')} - STS`;
  });

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <div className={classes.headerTextValue}>
            <img
              className={classes.headerLogo}
              src={'/images/MoEYS_LOGO_SMALL.png'}
              alt="small logo"
            />
            <div>
              <Typography
                variant={'h2'}
                className={classes.heading}
              >
                {translate('home.slide.heading')}
              </Typography>
              <Typography
                variant={'subtitle1'}
                className={classes.subheading}
              >
                {translate('home.slide.subheading')}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={() => history.push(ROUTES.CONTACT)}
              className={classes.cardActionArea}
            >
              <CardHeader
                avatar={<ContactIcon className={classes.cardHeaderIcon} />}
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="h2"
                  className={classes.cardContentTitle}
                >
                  {translate('home.contactUsCard.title')}
                </Typography>

                <Typography
                  component="p"
                >
                  {translate('home.contactUsCard.description')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={() => history.push(ROUTES.CONTACT)}
              className={classes.cardActionArea}
            >
              <CardHeader
                avatar={<DataQueryIcon className={classes.cardHeaderIcon} />}
                className={clsx(classes.cardHeader, classes.warningBg)}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="h2"
                  className={classes.cardContentTitle}
                >
                  {translate('home.dataQueryCard.title')}
                </Typography>

                <Typography
                  component="p"
                >
                  {translate('home.dataQueryCard.description')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={() => history.push(ROUTES.ABOUT)}
              className={classes.cardActionArea}
            >
              <CardHeader
                avatar={<AboutIcon className={classes.cardHeaderIcon} />}
                className={clsx(classes.cardHeader, classes.successBg)}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="h2"
                  className={classes.cardContentTitle}
                >
                  {translate('home.aboutCard.title')}
                </Typography>

                <Typography
                  component="p"
                >
                  {translate('home.aboutCard.description')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} className={classes.logos}>
          <img
            className={classes.logo}
            src="/images/home/logo_gpe_20230619900.png"
            alt="Global Partnership for Education"
          />
          <img
            className={classes.logo}
            src="/images/home/logo_unesco_20230619900.png"
            alt="UNESCO"
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
