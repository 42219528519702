import {
  grayColor,
  defaultFont, hexToRgb, blackColor, primaryColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const tableStyle = (theme) => ({
  allCheckbox: {
    color: primaryColor[0] + '!important'
  },
  customTableRowBody: {
    height: '0px'
  },
  customTableRowHead: {
    height: '0px'
  },
  customTableHeadCell: {
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
    border: 'none !important'
  },
  customTableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    fontSize: '1em',
    borderBottom: 'none',
    borderTop: `1px solid ${grayColor[5]}`,
    position: 'relative',
    padding: '3px 24px 3px 16px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      minHeight: '24px',
      minWidth: '32px'
    }
  },
  noDataTableCell: {
    textAlign: 'center',
    height: '100px',
    fontSize: '1rem',
    fontWeight: '400'
  },
  checkbox: {
    textAlign: 'center'
  },
  inlineIconCell: {
    textAlign: 'center'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  paginationTable: {
    padding: 12
  },
  tooltip: {
    display: 'inline-block'
  },
  tableIconButton: {
    margin: '0 0 0 5px',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    }
  },
  inputStyle: {
    height: '63px !important'
  },
  datePickerStyle: {
    marginTop: '-15px'
  }
});

export default tableStyle;
