import { degree as SCHOOL_DEGREE } from 'variables/school';
import { REPORT_STAGES } from 'variables/report';
import { round as ROUND } from 'variables/scholarship';
import * as moment from 'moment';
import settings from 'settings';
import _ from 'lodash';

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getKeyArray = (object) => {
  return Object.keys(object);
};

export const getSchoolName = (activeLanguage, translate, school) => {
  const degree = translate(`school.schoolDegree.${SCHOOL_DEGREE[school.degree.number].code}`);
  const khmerName = school.name ? `${school.name} (${degree})` : `${school.name_en} (${degree})`;
  const englishName = `${school.name_en} (${degree})`;
  return activeLanguage === 'en' ? englishName : khmerName;
};

export const getAcademicYearLabel = (translate, academicYear) => {
  const academicStartDate = moment(academicYear.start_date, settings.dateFormat);
  const academicEndDate = moment(academicYear.end_date, settings.dateFormat);
  const academicStatus = translate(`academicYear.status.${academicYear.status}`).toUpperCase();
  return `${academicStartDate.year()}-${academicEndDate.year()} (${academicStatus})`;
};

export const getReportStageLabel = (translate, reportStage) => {
  return translate(`report.reportStage.${REPORT_STAGES[reportStage].code}`);
};

export const formatCurrency = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getAvailableScholarshipFunding = (curRound, foundingList) => {
  const funding = _.findLast(foundingList, { round: curRound });
  const leftover = funding?.leftover || '0';
  if (curRound === ROUND.ROUND_1) {
    return leftover;
  }

  const previousRound = curRound === ROUND.ROUND_2 ? ROUND.ROUND_1 : ROUND.ROUND_2;
  let availableFund = parseInt(leftover);
  const previousFunding = _.findLast(foundingList, { round: previousRound });
  if (previousFunding !== undefined) {
    availableFund += parseInt(previousFunding.leftover);
  }

  return availableFund.toString();
};

export const getScholarshipNextRound = (dataList) => {
  if (dataList.length) {
    if (_.findLast(dataList, { round: ROUND.ROUND_2 }) !== undefined) {
      return ROUND.ROUND_3;
    }
    return ROUND.ROUND_2;
  }

  return ROUND.ROUND_1;
};
