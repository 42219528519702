import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, MenuItem, MenuList } from '@material-ui/core';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import GridContainer from 'components/theme/Grid/GridContainer';
import Render from 'components/hoc/Render/Render';
import Input from 'components/common/Input/Input';

const useStyles = makeStyles(dialogInputStyle);

export default function AssignClassroom (props) {
  const {
    open,
    handleClose,
    studentNum,
    classrooms,
    classroom,
    onChangeHandler,
    onHandleAssign,
    grade,
    suffix
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const [isAssignToNew, setIsAssignToNew] = useState(false);
  const [isShowChoices, setIsShowChoices] = useState(true);

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.secondGrid}>
            <h6 className={classes.confirmDialogHeader}>
              {translate('enrollment.assignNumToClassroom', { number: studentNum })}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          {
            isShowChoices && (
              <GridItem
                xs={12}
              >
                <MenuList id="menu-list-select-assign-type">
                  <MenuItem
                    onClick={() => {
                      setIsAssignToNew(true);
                      setIsShowChoices(false);
                    }}
                    className={classes.choicesStyles}
                  >
                    {translate('enrollment.enrollToNewClass')}
                  </MenuItem>

                  <MenuItem
                    onClick={(e) => {
                      setIsAssignToNew(false);
                      setIsShowChoices(false);
                    }}
                    className={classes.choicesStyles}
                  >
                    {translate('enrollment.enrollToClass')}
                  </MenuItem>

                </MenuList>
              </GridItem>
            )
          }
          {
            !isShowChoices && (isAssignToNew ? (
              <Render
                value={true}
              >
                <GridItem
                  xs={12}
                >
                  <Input
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={grade}
                    name="grade"
                    require
                    disabled
                  />
                </GridItem>

                <GridItem
                  xs={12}
                >
                  <Input
                    label={translate('common.suffix')}
                    placeholder={translate('enrollment.placeholder.suffix')}
                    value={suffix}
                    name="suffix"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    require
                  />
                </GridItem>
              </Render>
            ) : (
              <GridItem
                xs={12}
              >
                <Select
                  label={translate('classroom.classroom')}
                  placeholder={translate('enrollment.placeholder.classroom')}
                  value={classroom}
                  name="classroom"
                  onChange={e => onChangeHandler(e, 'classroom')}
                  choices={classrooms.filter(currentClass => currentClass.status === 1)
                    .map(
                      currentClass => (
                        {
                          label: `${getActiveLanguage(localize).code === 'en' ? currentClass.grade_name_en : currentClass.grade_name} ${currentClass.suffix}`,
                          value: currentClass.id
                        }
                      )
                    )}
                  require
                />
              </GridItem>
            ))
          }
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={() => {
              setIsShowChoices(true);
              handleClose();
            }}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>

          <ThemeButton
            onClick={() => {
              setIsShowChoices(true);
              onHandleAssign();
            }}
            color="primary"
            variant="contained"
            disabled={ isShowChoices || isAssignToNew ? suffix === '' : classroom === ''}
          >
            {translate('common.button.assign')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

AssignClassroom.propTypes = {
  open: PropTypes.bool,
  classrooms: PropTypes.array,
  studentNum: PropTypes.number,
  handleClose: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onHandleAssign: PropTypes.func,
  classroom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string
};
