import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';
import _ from 'lodash';
import { getClassroomListForFilter } from './classroom';

function getStartEnrollmentRequested () {
  return {
    type: actionTypes.CHECK_START_ENROLLMENT_REQUEST
  };
}

function getStartEnrollmentSuccess (students, startEnrollment, school) {
  return {
    type: actionTypes.CHECK_START_ENROLLMENT_SUCCESS,
    students,
    startEnrollment,
    school
  };
}

function getStartEnrollmentFail () {
  return {
    type: actionTypes.CHECK_START_ENROLLMENT_FAIL
  };
}

const enrollStudentRequest = () => {
  return {
    type: actionTypes.ENROLL_STUDENT_REQUEST
  };
};

const enrollStudentSuccess = (student) => {
  return {
    type: actionTypes.ENROLL_STUDENT_SUCCESS,
    student
  };
};

const enrollStudentFail = () => {
  return {
    type: actionTypes.ENROLL_STUDENT_FAIL
  };
};

const searchStudentByStsIdRequest = () => {
  return {
    type: actionTypes.SEARCH_STUDENT_BY_ID_REQUEST
  };
};

const searchStudentByStsIdSuccess = (studentResult) => {
  return {
    type: actionTypes.SEARCH_STUDENT_BY_ID_SUCCESS,
    studentResult
  };
};

const searchStudentByStsIdFail = () => {
  return {
    type: actionTypes.SEARCH_STUDENT_BY_ID_FAIL
  };
};

export const resetStudentResult = () => {
  return {
    type: actionTypes.RESET_SEARCH_STUDENT_BY_ID,
    studentResult: undefined
  };
};

const assignClassroomRequest = () => {
  return {
    type: actionTypes.ASSIGN_CLASSROOM_REQUEST
  };
};

const assignClassroomSuccess = () => {
  return {
    type: actionTypes.ASSIGN_CLASSROOM_SUCCESS
  };
};

const assignClassroomFail = () => {
  return {
    type: actionTypes.ASSIGN_CLASSROOM_FAIL
  };
};

const deleteEnrollmentRequest = () => {
  return {
    type: actionTypes.DELETE_ENROLLMENT_REQUEST
  };
};

const deleteEnrollmentSuccess = () => {
  return {
    type: actionTypes.DELETE_ENROLLMENT_SUCCESS
  };
};

const deleteEnrollmentFail = () => {
  return {
    type: actionTypes.DELETE_ENROLLMENT_FAIL
  };
};

const importEnrollStudentRequest = () => {
  return {
    type: actionTypes.IMPORT_ENROLL_STUDENT_REQUEST
  };
};

const importEnrollStudentSuccess = (result) => {
  return {
    type: actionTypes.IMPORT_ENROLL_STUDENT_SUCCESS,
    result
  };
};

const importEnrollStudentFail = () => {
  return {
    type: actionTypes.IMPORT_ENROLL_STUDENT_FAIL
  };
};

const resetImportStatesRequest = () => {
  return {
    type: actionTypes.RESET_IMPORT_STATE_REQUEST
  };
};

export const startEnrollmentCheck = (rawParams) => {
  return (dispatch) => {
    const params = {
      academic_year_id: rawParams.academicYear,
      selected_school: rawParams.selectedSchool,
      emis_school_code: rawParams.emisSchoolCode,
      grade: rawParams.grade,
      report_stage: rawParams.reportStage,
      page: rawParams.page,
      limit: rawParams.limit
    };

    dispatch(getStartEnrollmentRequested());
    axios.get('/enrollments', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          const school = res.data.school;
          rawParams.emisSchoolCode = school.emis_code;
          dispatch(getStartEnrollmentSuccess(data, rawParams, school));
          const classroomParams = {
            school: res.data.school.id,
            grade: rawParams.grade
          };
          dispatch(getClassroomListForFilter(classroomParams));
        }
      )
      .catch(e => {
        dispatch(getStartEnrollmentFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const enrollStudent = (data, successfulMessage, editId = '') => {
  return (dispatch, getState) => {
    const startEnrollment = getState().enrollment.startEnrollment;
    const postData = {
      academic_year_id: startEnrollment.academicYear,
      grade: data.grade,
      emis_school_code: startEnrollment.emisSchoolCode,
      report_stage: startEnrollment.reportStage,
      enrolled_date: data.enrollmentDate,
      enrolled_by: data.enrollmentBy,
      emis_student_id: data.stsId || undefined,
      student: {
        first_name: data.firstName,
        last_name: data.lastName,
        gender: data.gender,
        birth_date: data.birthDate,
        enrollment_status: data.enrollmentStatus,
        transfer_status: data.transferStatus
      },
      options: {
        ethnicity: data.ethnicity,
        disabilities: data.disabilities,
        health_problems: data.healthProblems,
        personal_problems: data.personalProblems,
        scholarships: data.scholarships
      }
    };
    dispatch(enrollStudentRequest());
    if (editId) {
      delete postData.enrolled_date;
      delete postData.enrolled_by;
      axios.put(`/enrollments/${editId}`, postData)
        .then(
          res => {
            if (res.data.error) {
              dispatch(showErrorNotification(res.data.message));
              dispatch(enrollStudentSuccess());
            } else {
              dispatch(enrollStudentSuccess());
              dispatch(showSuccessNotification(successfulMessage));
              dispatch(startEnrollmentCheck(startEnrollment));
            }
          }
        )
        .catch(e => {
          dispatch(enrollStudentFail(e));
          dispatch(showErrorNotification(e.message));
        });
    } else {
      axios.post('/enrollments', postData)
        .then(
          res => {
            if (res.data.error) {
              dispatch(showErrorNotification(res.data.message));
              dispatch(enrollStudentSuccess());
            } else {
              dispatch(enrollStudentSuccess());
              dispatch(showSuccessNotification(successfulMessage));
              dispatch(startEnrollmentCheck(startEnrollment));
            }
          }
        )
        .catch(e => {
          dispatch(enrollStudentFail(e));
          dispatch(showErrorNotification(e.message));
        });
    }
  };
};

export const searchStudentByStsId = (params) => {
  return (dispatch, getState) => {
    dispatch(searchStudentByStsIdRequest());
    const startEnrollment = getState().enrollment.startEnrollment;
    params.academic_year_id = startEnrollment.academicYear;
    params.grade = startEnrollment.grade;
    params.report_stage = startEnrollment.reportStage;
    axios.get('/students/find', { params })
      .then(
        res => {
          const data = res.data.data;
          if (typeof data === 'object') {
            data.ethnicity = !_.isEmpty(data.ethnicity) ? data.ethnicity.id : '';
            data.disabilities = !_.isEmpty(data.disabilities) ? data.disabilities.map(disability => disability.id) : [];
            data.health_problems = !_.isEmpty(data.health_problems) ? data.health_problems.map(healthProblem => healthProblem.id) : [];
            data.personal_problems = !_.isEmpty(data.personal_problems) ? data.personal_problems.map(personalProblem => personalProblem.id) : [];
          }
          dispatch(searchStudentByStsIdSuccess(data));
        }
      )
      .catch(e => {
        dispatch(searchStudentByStsIdFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const assignClassroom = (data, successfulMessage) => {
  return (dispatch, getState) => {
    const startEnrollment = getState().enrollment.startEnrollment;
    const school = getState().enrollment.school;
    if (data.classroom_id === undefined) {
      data.academic_year_school_id = school.id;
      data.grade = startEnrollment.grade;
      data.academic_year_id = startEnrollment.academicYear;
    }
    dispatch(assignClassroomRequest());
    axios.post('enrollments/assign-classroom', data)
      .then(
        res => {
          dispatch(assignClassroomSuccess());
          dispatch(showSuccessNotification(successfulMessage));
          dispatch(startEnrollmentCheck(startEnrollment));
        }
      )
      .catch(e => {
        dispatch(assignClassroomFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const importEnrollStudent = (data, successfulMessage) => {
  return (dispatch, getState) => {
    const startEnrollment = getState().enrollment.startEnrollment;
    const enrollmentInfo = {
      academic_year_id: startEnrollment.academicYear,
      grade: startEnrollment.grade,
      emis_school_code: startEnrollment.emisSchoolCode,
      report_stage: startEnrollment.reportStage,
      enrolled_date: data.enrollmentDate
    };
    const formData = new FormData();
    formData.append('enrollmentInfo', JSON.stringify(enrollmentInfo));
    formData.append('file', data.file);
    dispatch(importEnrollStudentRequest());
    axios.post('/import/enrollments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(
        res => {
          dispatch(importEnrollStudentSuccess(res.data));

          if (res.data.success) {
            dispatch(showSuccessNotification(successfulMessage));
            dispatch(startEnrollmentCheck(startEnrollment));
          } else {
            dispatch(showErrorNotification('enrollment.importFailed'));
          }
        }
      )
      .catch(e => {
        dispatch(importEnrollStudentFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetImportStates = () => {
  return (dispatch) => {
    dispatch(resetImportStatesRequest());
  };
};

export const deleteEnrollment = (id) => {
  return (dispatch, getState) => {
    dispatch(deleteEnrollmentRequest());
    axios.delete(`/students/${id}`)
      .then(
        res => {
          dispatch(deleteEnrollmentSuccess());
          dispatch(showSuccessNotification('student.deleteSuccess'));
          const startEnrollment = getState().enrollment.startEnrollment;
          dispatch(startEnrollmentCheck(startEnrollment));
        }
      )
      .catch(e => {
        dispatch(deleteEnrollmentFail());
        dispatch(showErrorNotification('student.deleteFailed'));
      });
  };
};
