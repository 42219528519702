import React from 'react';
import PropTypes from 'prop-types';

import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';

const useStyles = makeStyles(dialogInputStyle);

export default function EnrollNotFound (props) {
  const {
    isSearchNotFound,
    studentResult,
    handleClose
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  return (
    <Dialog
      open={isSearchNotFound}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.secondGrid}>
            <h6 className={classes.confirmDialogHeader}>
              {translate('enrollment.searchNoResult')}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {studentResult === ''
          ? (
            <p className={classes.schoolNotFound}>
              {translate('enrollment.studentNotFound')}
            </p>
          )
          : (
            <p className={classes.schoolNotFound}>
              {translate('enrollment.alreadyEnrolled')}
            </p>
          )
        }
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={handleClose}
            variant="contained"
            className={classes.buttonDefault}
            style={{ marginRight: 0 }}
          >
            {translate('common.button.close')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

EnrollNotFound.propTypes = {
  isSearchNotFound: PropTypes.bool,
  studentResult: PropTypes.any,
  handleClose: PropTypes.func
};
