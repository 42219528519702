import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import AttendanceListFilter from 'views/Attendance/AttendancetListFilter';
import AttendanceTable from 'views/Attendance/AttendanceTable';
import GridItem from 'components/theme/Grid/GridItem';
import GridContainer from 'components/theme/Grid/GridContainer';
import { getAttendances, saveAttendances } from 'redux/actions/attendance';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/offline/attendanceStyle';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { groups as GROUPS } from 'variables/user';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';
import { ARCHIVED, POST } from 'variables/academicYearStatus';
import settings from 'settings';
import * as moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles(styles);

const Attendance = (props) => {
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();

  const { location } = props;
  const academicYearId = queryString.parse(location.search).academicYear;
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolId = queryString.parse(location.search).school;
  const gradeValue = queryString.parse(location.search).grade;
  const suffixValue = queryString.parse(location.search).suffix;
  const studentId = queryString.parse(location.search).studentId;
  const attendanceFilter = useSelector(state => state.attendance.attendanceFilter);
  const attendanceList = useSelector(state => state.attendance.attendanceList);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const [rows, setRows] = useState([]);
  const [months, setMonths] = useState([]);
  const [femaleStudents, setFemaleStudents] = useState([]);
  const [filterParams, setFilterParams] = useState({
    academicYear: academicYearId !== undefined ? parseInt(academicYearId) : attendanceFilter?. academicYear || '',
    school: schoolId !== undefined ? parseInt(schoolId) : attendanceFilter?.school || '',
    grade: gradeValue !== undefined ? parseInt(gradeValue) : attendanceFilter?.grade || '',
    suffix: suffixValue !== undefined ? suffixValue : attendanceFilter?.suffix || ''
  });
  let isPostOrArchivedAcademicYear = false;
  if (academicYears.length > 0 && filterParams.academicYear) {
    const index = academicYears.findIndex(year => parseInt(filterParams.academicYear) === year.id);
    if (academicYears[index].status === POST || academicYears[index].status === ARCHIVED) {
      isPostOrArchivedAcademicYear = true;
    } else {
      isPostOrArchivedAcademicYear = false;
    }
  }
  const authProfile = useSelector(state => state.auth.profile);
  const currentSchool = useSelector((state) => state.school.school);
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorSchool, setErrorSchool] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorSuffix, setErrorSuffix] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const columns = [
    { name: 'emis_student_id', title: translate('common.stsId') },
    { name: 'name', title: translate('common.name') },
    { name: 'gender', title: translate('common.gender') }
  ];
  const columnExtensions = [
    { columnName: 'emis_student_id', align: 'left', width: 140 },
    { columnName: 'name', align: 'left', width: 140 },
    { columnName: 'gender', align: 'left', width: 80 }
  ];
  const columnBands = [];
  months.forEach((month, index) => {
    columns.push(
      { name: month + '_a', title: translate('attendance.absent') }, { name: month + '_p', title: translate('attendance.permission') }
    );
    columnExtensions.push(
      { columnName: month + '_a', align: 'center', width: 60 },
      { columnName: month + '_p', align: 'center', width: 60 }
    );
    columnBands.push(
      {
        title: translate(`common.month.${month}`),
        children: [
          { columnName: month + '_a' },
          { columnName: month + '_p' }
        ]
      }
    );
  });
  const editingColumnExtensions = [
    { columnName: 'emis_student_id', editingEnabled: false },
    { columnName: 'name', editingEnabled: false },
    { columnName: 'gender', editingEnabled: false }
  ];
  const cellComponent = ({
    children, tableRow, tableColumn, column, classes, ...restProps
  }) => {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        style={{ textAlign: 'center' }}
      >
        <strong>
          {children}
        </strong>
      </TableBandHeader.Cell>
    );
  };

  cellComponent.propTypes = {
    children: PropTypes.any,
    tableRow: PropTypes.any,
    tableColumn: PropTypes.any,
    column: PropTypes.any,
    classes: PropTypes.instanceOf(Object)
  };

  useEffect(() => {
    if (academicYears.length > 0 && filterParams.academicYear) {
      const academicYear = academicYears.find(year => parseInt(filterParams.academicYear) === year.id);
      const startDate = moment(academicYear.start_date, settings.dateFormat);
      const endDate = moment(academicYear.end_date, settings.dateFormat);
      if (startDate && endDate && startDate.isBefore(endDate)) {
        const result = [];
        const start = _.cloneDeep(startDate);
        while (start.isBefore(endDate)) {
          result.push(start.locale('en-US').format('MMM').toLowerCase());
          start.add(1, 'month');
        }
        setMonths(result);
      }
    }
  }, [academicYears, filterParams.academicYear]);

  // set title
  useEffect(() => {
    document.title = `${translate('attendance.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('attendance.title');
  }, [translate]);

  useEffect(() => {
    if (authProfile && currentSchool && schoolId) {
      if ((authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER) && authProfile.school.emis_code !== currentSchool.emis_code) {
        history.push(ROUTES.HOME);
      }

      if (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        if (authProfile.province.code !== currentSchool.province.code) {
          history.push(ROUTES.HOME);
        }
      }

      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER) {
        if (authProfile.district.code !== currentSchool.district.code) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [schoolId, currentSchool, authProfile]);

  useEffect(() => {
    if (authProfile && gradeValue && suffixValue && classroomList) {
      if (authProfile.groups[0] === GROUPS.TEACHER) {
        const classroom = classroomList.find(c => c.grade.toString() === gradeValue && suffixValue === c.suffix);
        if (!classroom) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [classroomList, gradeValue, suffixValue, authProfile]);

  // set default school
  useEffect(() => {
    const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
    if (isManagementOrTeacher || (gradeValue && suffixValue) || (attendanceFilter && attendanceFilter.grade && attendanceFilter.suffix)) {
      setIsSearching(true);
    }
    // eslint-disable-next-line
  }, [dispatch, gradeValue, suffixValue, authProfile]);

  const fetchAttendances = useCallback(() => {
    if (isSearching && filterParams.suffix !== '' && classroomList) {
      const classroom = classroomList.find(c => c.grade === filterParams.grade && c.suffix === filterParams.suffix);
      if (classroom) {
        const params = {
          ...filterParams,
          classroomId: classroom.id,
          studentId
        };
        dispatch(getAttendances(params));
        setIsSearching(false);
      }
    }
  }, [dispatch, isSearching, classroomList, filterParams, studentId]);

  useEffect(() => {
    fetchAttendances();
  }, [fetchAttendances]);

  // set attendance data
  useEffect(() => {
    if (attendanceList) {
      const totalFemale = attendanceList.filter(a => a.gender === 'f');
      setRows(attendanceList);
      setFemaleStudents(totalFemale);
    }
  }, [attendanceList, setRows]);

  const onChangeHandler = event => {
    const { name, value } = event.target;
    setFilterParams({ ...filterParams, [name]: value });
  };

  const handleFilter = e => {
    e.preventDefault();
    let canFilter = true;
    if (filterParams.academicYear === '') {
      setErrorAcademicYear(true);
      canFilter = false;
    } else {
      setErrorAcademicYear(false);
    }

    if (filterParams.school === '') {
      setErrorSchool(true);
      canFilter = false;
    } else {
      setErrorSchool(false);
    }

    if (filterParams.grade === '') {
      setErrorGrade(true);
      canFilter = false;
    } else {
      setErrorGrade(false);
    }

    if (filterParams.suffix === '') {
      setErrorSuffix(true);
      canFilter = false;
    } else {
      setErrorSuffix(false);
    }

    if (filterParams.grade !== '' && filterParams.suffix !== '') {
      const classroom = classroomList.find(c => c.grade === filterParams.grade && c.suffix === filterParams.suffix);
      if (!classroom) {
        setErrorGrade(true);
        setErrorSuffix(true);
        canFilter = false;
      }
    }

    if (canFilter) {
      setIsSearching(canFilter);
    }
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };

  const handleSaveAll = () => {
    if (filterParams.suffix !== '') {
      dispatch(saveAttendances({
        academic_year_school_id: filterParams.school,
        grade: filterParams.grade,
        suffix: filterParams.suffix,
        attendances: rows
      }));
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <AttendanceListFilter
          classes={classes}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          onChangeHandler={onChangeHandler}
          handleFilter={handleFilter}
          errorAcademicYear={errorAcademicYear}
          errorSchool={errorSchool}
          errorGrade={errorGrade}
          errorSuffix={errorSuffix}
          studentId={studentId}
        />

        <AttendanceTable
          classes={classes}
          columns={columns}
          columnExtensions={columnExtensions}
          editingStateColumnExtensions={editingColumnExtensions}
          columnBands={columnBands}
          cellComponent={cellComponent}
          rows={rows}
          femaleStudents={femaleStudents}
          setRows={setRows}
          commitChanges={commitChanges}
          onHandleSaveAll={handleSaveAll}
          isPostOrArchivedAcademicYear={isPostOrArchivedAcademicYear}
        />
      </GridItem>
    </GridContainer>
  );
};

Attendance.propTypes = {
  location: PropTypes.instanceOf(Object)
};

export default Attendance;
