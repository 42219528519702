import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/AddCircle';
import {
  Popover,
  Grow,
  MenuItem,
  Popper,
  Tooltip,
  IconButton,
  ClickAwayListener,
  Paper,
  MenuList
} from '@material-ui/core';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

import styles from 'assets/sts/jss/views/enrollment/enrollmentStyle';
import DialogInput from 'views/Enrollment/Partial/DialogInput/DialogInput';
import StartEnrollInput from 'views/Enrollment/Partial/StartEnrollInput/StartEnrollInput';
import EnrollTable from 'views/Enrollment/Partial/EnrollTable/EnrollTable';
import AddNewPopOver from 'views/Enrollment/Partial/AddNewPopOver/AddNewPopOver';
import EnrollNotFound from 'views/Enrollment/Partial/EnrollNotFound/EnrollNotFound';
import AssignClassroom from 'views/Enrollment/Partial/AssignClassroom/AssignClassroom';
import ImportEnrollmentInput from 'views/Enrollment/Partial/ImportEnrollmentInput/ImportEnrollmentInput';
import {
  getAcademicYears,
  getStudentOptions,
  startEnrollmentCheck,
  searchStudentByStsId,
  resetStudentResult,
  assignClassroom,
  deleteEnrollment
} from 'redux/actions';
import * as moment from 'moment';
import settings from 'settings';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import _ from 'lodash';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import { groups as GROUPS } from 'variables/user';

const useStyles = makeStyles(styles);

const Enrollment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const allGrades = useSelector(state => state.studentOption.grade);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const authProfile = useSelector(state => state.auth.profile);
  const school = useSelector(state => state.enrollment.school);
  const isLoading = useSelector(state => state.enrollment.isLoading);
  const students = useSelector(state => state.enrollment.students);
  const startEnrollmentParams = useSelector(state => state.enrollment.startEnrollment);
  const isEnrolling = useSelector(state => state.enrollment.isEnrolling);
  const studentResult = useSelector(state => state.enrollment.studentResult);
  const isSearching = useSelector(state => state.enrollment.isSearching);
  const classrooms = useSelector(state => state.classroom.classroomListForFilter);
  const isAssigning = useSelector(state => state.enrollment.isAssigning);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);

  const [academicYear, setAcademicYear] = useState('');
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [errorSelectedSchool, setErrorSelectedSchool] = useState(false);
  const [grade, setGrade] = useState('');
  const [errorGrade, setErrorGrade] = useState(false);
  const [emisSchoolCode, setEmisSchoolCode] = useState('');
  const [errorEmisSchoolCode, setErrorEmisSchoolCode] = useState(false);
  const [errorEmisSchoolCodeText, setErrorEmisSchoolCodeText] = useState('');

  const [enrollmentDate, setEnrollmentDate] = useState(moment().format(settings.dateFormat));
  const [searchStsId, setSearchStsId] = useState('');
  const [isEnrollBySearch, setIsEnrollBySearch] = useState(false);
  const [suffix, setSuffix] = useState('');
  const [reportStage, setReportStage] = useState('');
  const [errorReportStage, setErrorReportStage] = useState(false);
  const [classroom, setClassroom] = useState('');

  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [rows, setRows] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [action, setAction] = useState('create');
  const [dialogTitle, setDialogTitle] = useState(translate('enrollment.new'));
  const [editId, setEditId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [selection, setSelection] = useState([]);
  const [anchorReference, setAnchorReference] = useState(null);
  const [open, setOpen] = useState(false);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isOpenStartEnroll, setIsOpenStartEnroll] = useState(false);
  const [isShowEnrollTable, setIsShowEnrollTable] = useState(false);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [isSearchNotFound, setIsSearchNotFound] = useState(false);
  const [isOpenAssignClass, setIsOpenAssignClass] = useState(false);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
  const [isShowImportButton, setIsShowImportButton] = useState(false);

  // set title
  const changeBrandText = useCallback(() => {
    document.title = `${translate('enrollment.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('enrollment.title');
  }, [translate]);

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  // fetch Academic Years School
  const fetchAcademicYears = useCallback(
    () => {
      dispatch(getAcademicYears());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingAcademic) {
      fetchAcademicYears();
    }
    // eslint-disable-next-line
  }, [fetchAcademicYears]);

  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears.filter(academic => academic.status === ACADEMIC_STATUS.PRE || academic.status === ACADEMIC_STATUS.RUNNING)
        .map(academic => {
          const academicStartDate = moment(academic.start_date,
            settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch Student Option
  const fetchStudentOption = useCallback(
    () => {
      dispatch(getStudentOptions());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingStudentOption) {
      fetchStudentOption();
    }
    // eslint-disable-next-line
  }, [fetchStudentOption]);

  useEffect(() => {
    if (students.data && students.data.length > 0) {
      const showRows = students.data.map(
        (student) => ({
          id: student.id,
          stsId: student.student.emis_student_id,
          firstName: student.student.first_name,
          lastName: student.student.last_name,
          name: `${student.student.last_name} ${student.student.first_name}`,
          gender: student.student.gender,
          birthDate: student.student.birth_date,
          enrollmentStatus: student.entry_status,
          transferStatus: student.transfer_status,
          ethnicity: student.student.ethnicity,
          disabilities: student.student.disabilities,
          healthProblems: student.student.health_problems,
          personalProblems: student.student.personal_problems,
          scholarships: student.scholarships.map(scholarship => scholarship.scholarship_id)
        })
      );
      setCount(students && students.total ? students.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [students]);

  const fetchStudents = useCallback(
    () => {
      const params = { ...startEnrollmentParams };
      params.page = page + 1;
      params.limit = limit;
      dispatch(startEnrollmentCheck(params));
    },
    [dispatch, startEnrollmentParams, page, limit]
  );

  useEffect(() => {
    if (!isLoading && academicYear) {
      fetchStudents();
    }
    // eslint-disable-next-line
  }, [fetchStudentOption, page, limit]);

  useEffect(() => {
    if (studentResult === '' || studentResult === 'enrolled') {
      setIsSearchNotFound(true);
    } else if (studentResult !== undefined) {
      setIsOpenDialog(true);
      setIsEnrollBySearch(true);
    }
  }, [studentResult]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'selectedSchool':
        setSelectedSchool(value);
        break;
      case 'grade':
        setGrade(value);
        break;
      case 'searchStsId':
        setSearchStsId(value);
        break;
      case 'suffix':
        setSuffix(value);
        break;
      case 'reportStage':
        setReportStage(value);
        break;
      case 'classroom':
        setClassroom(value);
        break;
      case 'emisSchoolCode':
        setEmisSchoolCode(value);
        break;
      default:
    }
  };

  const onChangeDateHandler = (value, params) => {
    const date = typeof value === 'object' ? value.format(settings.dateFormat) : value;
    setEnrollmentDate(date);
  };

  const handleStartEnrollment = e => {
    e.preventDefault();
    let isCanSave = true;

    if (!academicYear) {
      isCanSave = false;
      setErrorAcademicYear(true);
    } else {
      setErrorAcademicYear(false);
    }

    if (!selectedSchool) {
      isCanSave = false;
      setErrorSelectedSchool(true);
    } else {
      setErrorSelectedSchool(false);
    }

    if (grade === '') {
      isCanSave = false;
      setErrorGrade(true);
    } else {
      setErrorGrade(false);
    }

    if (reportStage === '') {
      isCanSave = false;
      setErrorReportStage(true);
    } else {
      setErrorReportStage(false);
    }

    if (isCanSave) {
      const params = {
        academicYear,
        selectedSchool,
        grade,
        reportStage: reportStage,
        page: page + 1,
        limit
      };
      dispatch(startEnrollmentCheck(params));
      if (!managementOrTeacher) {
        setIsOpenStartEnroll(true);
      } else {
        setIsShowEnrollTable(true);
        setIsShowImportButton(true);
      }
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const enrollPopper = (
    <Popper
      open={isPopperOpen}
      transition
      disablePortal
      anchorEl={anchorReference}
      className={classes.popperStyle}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
              <MenuList id="menu-list-grow">
                <MenuItem onClick={() => {
                  setIsOpenDialog(true);
                  setIsPopperOpen(false);
                  setIsEnrollBySearch(false);
                }}>
                  {translate('enrollment.addNew')}
                </MenuItem>

                <MenuItem
                  onClick={(e) => {
                    setOpen(true);
                    setIsPopperOpen(false);
                  }}
                >
                  {translate('enrollment.addById')}
                </MenuItem>

              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const addNewPopOver = (
    <Popover
      id="filter-Table"
      open={open}
      anchorEl={anchorReference}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <AddNewPopOver
        classes={classes}
        onChangeHandler={onChangeHandler}
        searchStsId={searchStsId}
        handleSearch={() => {
          const params = {
            emis_student_id: searchStsId.trim()
          };
          dispatch(searchStudentByStsId(params));
        }}
      />
    </Popover>
  );

  const newEnrollment = (
    <div>
      <Tooltip
        title={translate('common.button.new')}
        key={translate('common.button.new')}
        className={classes.tooltip}
      >
        <span>
          <IconButton
            color='primary'
            aria-label='new'
            className={classes.tableIconButton}
            onClick={(e) => {
              setAnchorReference(e.currentTarget);
              setIsPopperOpen(true);
            }}
          >
            <AddIcon className={classes.icon} />
          </IconButton>
        </span>
      </Tooltip>
      {enrollPopper}
      {addNewPopOver}
    </div>
  );

  const columns = [
    { id: 'action', isNeedSort: false, label: newEnrollment },
    { id: 'stsId', label: `${translate('common.stsId')}`, isNeedSort: false },
    { id: 'Name', label: `${translate('common.name')}`, isNeedSort: false },
    { id: 'gender', label: `${translate('common.gender')}`, isNeedSort: false },
    { id: 'birthDate', label: `${translate('enrollment.birthDate')}`, isNeedSort: false },
    { id: 'enrollmentStatus', label: `${translate('enrollment.enrollmentStatus')}`, isNeedSort: false },
    { id: 'transferStatus', label: `${translate('enrollment.transferStatus')}`, isNeedSort: false },
    { id: 'scholarship', label: `${translate('enrollment.scholarship')}`, isNeedSort: false }
  ];

  const commitChanges = () => {
    if (action === 'delete') {
      dispatch(deleteEnrollment(deleteId));
    }
  };

  const onHandleConfirm = () => {
    commitChanges();
    setDeleteId('');
    setIsConfirmDialog(false);
    setAction('create');
  };

  const onHandelCancel = () => {
    setIsConfirmDialog(false);
    setDeleteId('');
    setAction('create');
  };

  const onEditRowHandler = (rowId) => {
    setEditId(rowId);
    setAction('edit');
    setDialogTitle(translate('enrollment.edit'));
    setIsOpenDialog(true);
  };

  const onDeleteRowHandler = (rowId) => {
    setDeleteId(rowId);
    setAction('delete');
    setIsConfirmDialog(true);
  };

  const handleSelect = (event, id, stsId) => {
    let newSelections;
    if (event.target.checked) {
      newSelections = [...selection];
      newSelections.push(id);
      setSelection(newSelections);
      return;
    }
    newSelections = selection.filter(selected => selected !== id);
    setSelection(newSelections);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelections = rows.map(row => row.id);
      setSelection(newSelections);
      return;
    }
    setSelection([]);
  };

  const checkSchoolHaveGrade = (school) => {
    if (school) {
      const degree = school.degree.number;
      if (SCHOOL_DEGREE[degree].grade.includes(grade)) {
        return school;
      }
      return 0;
    }
    return school;
  };

  const gradeIndex = allGrades.findIndex(checkGrade => checkGrade.number === grade);
  const gradeName = grade !== ''
    ? getActiveLanguage(localize).code === 'en' ? allGrades[gradeIndex].name_en : allGrades[gradeIndex].name
    : '';

  const handleAssignClassroom = () => {
    const data = {
      enrollments: selection,
      classroom_id: classroom !== '' ? classroom : undefined,
      suffix: suffix !== '' ? suffix : undefined
    };
    let classroomName;
    if (_.isEmpty(classroom)) {
      classroomName = `${gradeName} ${suffix}`;
    } else {
      const classroomIndex = classrooms.findIndex(currentClass => currentClass.id === classroom);
      const classroomObject = classrooms[classroomIndex];
      classroomName = `${getActiveLanguage(localize).code === 'en' ? classroomObject.grade_name_en : classroomObject.grade_name} ${classroomObject.suffix}`;
    }
    dispatch(assignClassroom(data, translate('enrollment.assignSuccess',
      {
        number: selection.length,
        classroom: classroomName
      })));
    setIsOpenAssignClass(false);
    setSelection([]);
    setClassroom('');
    setSuffix('');
  };

  const handleConfirmEmisCode = () => {
    let isCanValidate = true;
    if (!emisSchoolCode) {
      isCanValidate = false;
      setErrorEmisSchoolCode(true);
      setErrorEmisSchoolCodeText('common.error.require');
    } else {
      setErrorEmisSchoolCode(false);
    }

    if (isCanValidate) {
      if (school.emis_code === emisSchoolCode.trim()) {
        setIsOpenStartEnroll(false);
        setIsShowEnrollTable(true);
        setIsShowImportButton(true);
        setErrorEmisSchoolCode(false);
      } else {
        setErrorEmisSchoolCode(true);
        setErrorEmisSchoolCodeText('enrollment.notMatchEmisSchoolCode');
      }
    }
  };

  return (
    <GridContainer>
      {
        ((!isSaveAndNew && isEnrolling) || isSearching || isAssigning) && <LoadingSpinner/>
      }
      <GridItem xs={12}>
        <StartEnrollInput
          classes={classes}
          isOpenDialog={isOpenStartEnroll}
          isLoadingAcademic={isLoadingAcademic}
          isLoadingStudentOption={isLoadingStudentOption}
          academicYearChoices={academicYearChoices}
          allGrades={allGrades}
          academicYears={academicYears}
          academicYear={academicYear}
          selectedSchool={selectedSchool}
          emisSchoolCode={emisSchoolCode}
          grade={grade}
          onChangeHandler={onChangeHandler}
          errorAcademicYear={errorAcademicYear}
          errorEmisSchoolCode={errorEmisSchoolCode}
          errorEmisSchoolCodeText={errorEmisSchoolCodeText}
          errorSelectedSchool={errorSelectedSchool}
          errorGrade={errorGrade}
          handleStartEnrollment={handleStartEnrollment}
          onHandleClose={() => setIsOpenStartEnroll(false)}
          school={checkSchoolHaveGrade(school)}
          setSchool={setSelectedSchool}
          isLoading={isLoading}
          isDisableInput={isShowEnrollTable}
          onHandleConfirm={handleConfirmEmisCode}
          handleReset={() => {
            setIsShowEnrollTable(false);
            setIsShowImportButton(false);
            setAcademicYear('');
            setGrade('');
            setSelectedSchool('');
            setReportStage('');
            setSelection([]);
            setErrorEmisSchoolCode('');
          }}
          reportStage={reportStage}
          errorReportStage={errorReportStage}
          enrollmentDate={enrollmentDate}
          onChangeDateHandler={onChangeDateHandler}
        />

        {
          isSearchNotFound && (
            <EnrollNotFound
              isSearchNotFound={isSearchNotFound}
              handleClose={() => {
                dispatch(resetStudentResult());
                setIsSearchNotFound(false);
              }}
              studentResult={studentResult}
            />
          )
        }

        {
          isOpenDialog && (
            <DialogInput
              dialogTitle={dialogTitle}
              setDialogTitle={setDialogTitle}
              isOpenDialog={isOpenDialog}
              setIsOpenDialog={setIsOpenDialog}
              action={action}
              enrollmentDate={enrollmentDate}
              isEnrollBySearch={isEnrollBySearch}
              setIsSaveAndNew={setIsSaveAndNew}
              setAction={setAction}
              setOpen={setOpen}
              editId={editId}
              setEditId={setEditId}
              rows={rows}
              allGrades={allGrades}
              currentGrade={grade}
              school={school}
              errorGrade={errorGrade}
              academicYear={academicYear}
              academicYears={academicYears}
            />
          )
        }

        {
          isOpenAssignClass && (
            <AssignClassroom
              open={isOpenAssignClass}
              handleClose={() => setIsOpenAssignClass(false)}
              onChangeHandler={onChangeHandler}
              studentNum={selection.length}
              classrooms={classrooms}
              classroom={classroom}
              onHandleAssign={handleAssignClassroom}
              suffix={suffix}
              grade={gradeName}
            />)
        }

        {
          isShowEnrollTable && (
            <EnrollTable
              classes={classes}
              isConfirmDialog={isConfirmDialog}
              onHandleConfirm={onHandleConfirm}
              onHandelCancel={onHandelCancel}
              columns={columns}
              rows={rows}
              allScholarships={allScholarships}
              count={count}
              limit={limit} page={page}
              onEditRowHandler={onEditRowHandler}
              onDeleteRowHandler={onDeleteRowHandler}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selection={selection} onSelect={handleSelect}
              onSelectAllClick={handleSelectAllClick}
              isLoading={isLoading}
              assignClassroom={() => setIsOpenAssignClass(true)}
            />
          )
        }
        {
          isShowImportButton && (
            <ImportEnrollmentInput
              enrollmentDate={enrollmentDate}
            />
          )
        }
      </GridItem>
    </GridContainer>
  );
};

export default Enrollment;
