import Table from 'components/common/Table/Table';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import settings from 'settings';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import styles from 'assets/sts/jss/views/attendance/importDetailStyle';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(styles);

export default function ImportAttendanceDetail (props) {
  const {
    brokenRecords,
    totalGoodRecords,
    missingFields,
    noMatchedFields,
    missingRowNumber,
    wrongTemplate
  } = props;

  const classes = useStyles();
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [errorRows, setErrorRows] = useState([]);
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const columns = [
    { id: 'rowNumber', label: `${translate('enrollment.rowNumber')}`, isNeedSort: false },
    { id: 'student_emis_code', label: `${translate('common.stsId')}`, isNeedSort: false },
    { id: 'fullName', label: `${translate('common.name')}`, isNeedSort: false },
    { id: 'gender', label: `${translate('common.gender')}`, isNeedSort: false }
  ];

  useEffect(() => {
    if (brokenRecords.length) {
      setErrorRows(_.map(_.keys(_.pickBy(brokenRecords.slice(page * limit, page * limit + limit), (row) => {
        return row.isDuplicate;
      })), Number));
    }
  }, [brokenRecords, page, limit]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  return (
    <Box mt={2}>
      {
        !!(brokenRecords.length) && (
          <>
            <p>{translate('attendance.brokenRecords')}:</p>
            <Table
              size='small'
              tableHead={columns}
              errorRows={errorRows}
              tableData={brokenRecords
                .slice(page * limit, page * limit + limit)
                .map(row => {
                  return [
                    row.rowNumber,
                    row.student_emis_code,
                    row.full_name,
                    row.gender
                  ];
                })}
              count={brokenRecords.length || 0}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customErrorClasses={classes}
              customClassesForCells={[0]}
            />
            <p>{translate('attendance.importErrorMessage')}</p>
            <Chip
              size="small"
              label={translate('attendance.duplicatedRecords')}
              className={classes.errorDuplicate}
            />
            <Chip
              size="small"
              label={translate('attendance.notMatchedRecords')}
              variant="outlined"
            />
          </>
        )
      }
      {
        !!(missingFields.length || noMatchedFields.length) && (
          <p>{translate('attendance.missingFieldsError')}</p>
        )
      }
      {
        !!totalGoodRecords && (
          <p>{translate('attendance.successfulImportMessage', { number: totalGoodRecords })}</p>
        )
      }
      {
        !!missingRowNumber && (
          <p>{translate('attendance.missingRowsError', { number: missingRowNumber })}</p>
        )
      }
      {
        wrongTemplate && (
          <p>{translate('attendance.wrongTemplateError')}</p>
        )
      }
    </Box>
  );
};

ImportAttendanceDetail.propTypes = {
  brokenRecords: PropTypes.array,
  totalGoodRecords: PropTypes.number,
  missingFields: PropTypes.array,
  noMatchedFields: PropTypes.array,
  missingRowNumber: PropTypes.number,
  wrongTemplate: PropTypes.bool
};
