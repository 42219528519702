import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Academic from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';

import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as ROUTES from 'variables/routeNames';
import { getAcademicYears, deleteAcademicYear } from 'redux/actions/academicYear';
import { showErrorNotification } from 'redux/actions';
import settings from 'settings';
import * as STATUS from 'variables/academicYearStatus';

import styles from 'assets/sts/jss/views/academicYear/academicYearStyle';
import * as moment from 'moment';

const useStyles = makeStyles(styles);

const AcademicYear = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const isLoading = useSelector(state => state.academicYear.isLoadingGet);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const translate = getTranslate(localize);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);

  // set title
  useEffect(() => {
    document.title = `${translate('academicYear.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('academicYear.title');
  }, [translate]);

  const fetchData = useCallback(
    () => {
      const params = {
        page: page + 1,
        limit
      };
      dispatch(getAcademicYears(params));
    },
    [dispatch, page, limit]
  );

  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }
  // eslint-disable-next-line
  }, [fetchData, page, limit]);

  useEffect(() => {
    setCount(academicYears.total);
  }, [academicYears]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const onHandleConfirm = () => {
    dispatch(deleteAcademicYear(deleteId, translate('academicYear.deleteSuccessfulMessage')));
    setDeleteId('');
    setIsConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setIsConfirmBoxOpen(false);
    setDeleteId('');
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        {
          isConfirmBoxOpen && (
            <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
              title={translate('common.modal.confirmTitle')}
              onConfirm={() => onHandleConfirm()}
              onCancel={() => onHandelCancel()}
              confirmBtnCssClass={classes.button + ' ' + classes.success}
              cancelBtnCssClass={classes.button + ' ' + classes.danger}
              confirmBtnText={translate('common.modal.yes')}
              cancelBtnText={translate('common.modal.no')}
              showCancel
            >
              {translate('common.modal.describe')}
            </SweetAlert>
          )
        }
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="gold" icon>
                <CardIcon color="gold">
                  <Academic />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{translate('academicYear.title')}</h4>
              </CardHeader>
              <CardBody>
                <Table
                  size='small'
                  tableHead={[
                    { id: 'start_year', isNeedSort: false, label: translate('academicYear.year') },
                    { id: 'start_date', isNeedSort: false, label: translate('academicYear.start') },
                    { id: 'end_date', isNeedSort: false, label: translate('academicYear.finish') },
                    { id: 'status', isNeedSort: false, label: translate('common.status') },
                    { id: 'short_vacation', isNeedSort: false, label: translate('academicYear.vacation.short') },
                    { id: 'long_vacation', isNeedSort: false, label: translate('academicYear.vacation.long') },
                    { id: 'action', isNeedSort: false, label: translate('common.action') }
                  ]}
                  tableData={
                    academicYears.map(academic => {
                      const academicStartDate = moment(academic.start_date, settings.dateFormat);
                      const academicEndDate = moment(academic.end_date, settings.dateFormat);
                      const studyYear = `${academicStartDate.year()}-${academicEndDate.year()}`;
                      const editButton = (
                        <Tooltip
                          title={translate('common.button.edit')}
                          key={translate('common.button.edit')}
                          className={classes.tooltip}
                        >
                          <div>
                            <IconButton
                              color='primary'
                              aria-label='edit'
                              className={classes.tableIconButton}
                              component={Link}
                              to={`${ROUTES.ACADEMIC_YEAR_EDIT_ROOT}/${academic.id}`}
                            >
                              <Edit className={classes.icon} />
                            </IconButton>
                          </div>
                        </Tooltip>
                      );
                      const deleteButton = (
                        <Tooltip
                          title={translate('common.button.delete')}
                          key={translate('common.button.delete')}
                          className={classes.tooltip}
                        >
                          <div>
                            <IconButton
                              color='secondary'
                              aria-label='delete'
                              className={classes.tableIconButton}
                              onClick={() => {
                                if (academic.status === STATUS.DRAFT) {
                                  setIsConfirmBoxOpen(true);
                                  setDeleteId(academic.id);
                                } else {
                                  dispatch(showErrorNotification(
                                    translate('academicYear.cannotDelete')));
                                }
                              }}
                              disabled={academic.status !== STATUS.DRAFT}
                            >
                              <Delete className={classes.icon} />
                            </IconButton>
                          </div>
                        </Tooltip>
                      );
                      return [
                        academic.name || studyYear,
                        academic.start_date,
                        academic.end_date,
                        translate(`academicYear.status.${academic.status}`).toUpperCase(),
                        academic.short_vacation ? `${academic.short_vacation.start_date} - ${academic.short_vacation.end_date}` : '',
                        academic.long_vacation ? `${academic.long_vacation.start_date} - ${academic.long_vacation.end_date}` : '',
                        [editButton, deleteButton]
                      ];
                    })}
                  customCellClasses={['center', 'center', 'center']}
                  customClassesForCells={[3, 4, 5]}
                  customHeadCellClasses={['center', 'center', 'center']}
                  customHeadClassesForCells={[3, 4, 5]}
                  count={count}
                  limit={limit}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </CardBody>
            </Card>
            <Link to={ROUTES.ACADEMIC_YEAR_NEW}>
              <ThemeButton
                color="primary"
                className={classes.allButton}
              >
                {translate('common.button.new')}
              </ThemeButton>
            </Link>
          </GridItem>
        </GridContainer>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

export default AcademicYear;
