import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  classroomFilter: undefined,
  isLoading: false,
  classroomList: [],
  classroom: undefined,
  classroomListForFilter: [],
  subjects: []
};

const getClassroomListRequested = (state) => ({
  ...state,
  isLoading: true
});

const getClassroomListSuccess = (state, action) => ({
  ...state,
  classroomFilter: action.classroomFilter,
  classroomList: action.classroomList,
  isLoading: false
});

const getClassroomListFail = (state) => ({
  ...state,
  isLoading: false
});

const getClassroomListForFilterSuccess = (state, action) => ({
  ...state,
  classroomListForFilter: action.classroomListForFilter
});

const getClassroomRequested = (state) => ({
  ...state,
  isLoading: true
});

const getClassroomSuccess = (state, action) => ({
  ...state,
  classroom: action.classroom,
  isLoading: false
});

const getClassroomFail = (state) => ({
  ...state,
  isLoading: false
});

const createEditClassroomRequested = (state) => ({
  ...state,
  isLoading: true
});

const createEditClassroomSuccess = (state) => ({
  ...state,
  isLoading: false
});

const createEditClassroomFail = (state) => ({
  ...state,
  isLoading: false
});

const deleteClassroomRequested = (state) => ({
  ...state,
  isLoading: true
});

const deleteClassroomSuccess = (state) => ({
  ...state,
  isLoading: false
});

const deleteClassroomFail = (state) => ({
  ...state,
  isLoading: false
});

const getSubjectsRequested = (state) => ({
  ...state,
  isLoadingSubject: true
});

const getSubjectsSuccess = (state, action) => ({
  ...state,
  subjects: action.subjects,
  isLoadingSubject: false
});

const getSubjectsFail = (state) => ({
  ...state,
  isLoadingSubject: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLASSROOM_LIST_REQUEST:
      return getClassroomListRequested(state);
    case actionTypes.GET_CLASSROOM_LIST_SUCCESS:
      return getClassroomListSuccess(state, action);
    case actionTypes.GET_CLASSROOM_LIST_FAIL:
      return getClassroomListFail(state);

    case actionTypes.GET_CLASSROOM_LIST_FOR_FILTER_SUCCESS:
      return getClassroomListForFilterSuccess(state, action);

    case actionTypes.GET_CLASSROOM_REQUEST:
      return getClassroomRequested(state);
    case actionTypes.GET_CLASSROOM_SUCCESS:
      return getClassroomSuccess(state, action);
    case actionTypes.GET_CLASSROOM_FAIL:
      return getClassroomFail(state);

    case actionTypes.CREATE_EDIT_CLASSROOM_REQUEST:
      return createEditClassroomRequested(state);
    case actionTypes.CREATE_EDIT_CLASSROOM_SUCCESS:
      return createEditClassroomSuccess(state);
    case actionTypes.CREATE_EDIT_CLASSROOM_FAIL:
      return createEditClassroomFail(state);

    case actionTypes.DELETE_CLASSROOM_REQUEST:
      return deleteClassroomRequested(state);
    case actionTypes.DELETE_CLASSROOM_SUCCESS:
      return deleteClassroomSuccess(state);
    case actionTypes.DELETE_CLASSROOM_FAIL:
      return deleteClassroomFail(state);

    case actionTypes.GET_CLASSROOM_SUBJECTS_REQUEST:
      return getSubjectsRequested(state);
    case actionTypes.GET_CLASSROOM_SUBJECTS_SUCCESS:
      return getSubjectsSuccess(state, action);
    case actionTypes.GET_CLASSROOM_SUBJECTS_FAIL:
      return getSubjectsFail(state);

    case actionTypes.RESET_CLASSROOM:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
