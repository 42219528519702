import React, { useRef, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import {
  importStudentScore,
  resetImportScoreStates,
  downloadTemplate,
  getMonthlyScores
} from 'redux/actions';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as moment from 'moment';
import settings from 'settings';
import * as SCORE from 'variables/score';
import ImportScoreDetail from 'views/Score/Patials/ImportScoreButton/ImportScoreDetail';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(dialogInputStyle);

export default function ImportScoreButton (props) {
  const {
    academicYear,
    school,
    grade,
    suffix,
    month,
    isImportingScore,
    setIsImportingScore
  } = props;

  const dialogStyle = useStyles();
  const dispatch = useDispatch();

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const isImporting = useSelector(state => state.score.isImporting);
  const isImported = useSelector(state => state.score.isImported);
  const importedResult = useSelector(state => state.score.importedResult);
  const localize = useSelector((state) => state.localize);
  const scoreFilter = useSelector(state => state.score.scoreFilter);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isAgreeToImport, setIsAgreeToImport] = useState(false);
  const inputFile = useRef(null);
  const translate = getTranslate(localize);
  const [brokenRecords, setBrokenRecords] = useState([]);
  const [totalGoodRecords, setTotalGoodRecords] = useState(0);
  const [missingFields, setMissingFields] = useState([]);
  const [noMatchedFields, setNoMatchedFields] = useState([]);
  const [missingRowNumber, setMissingRowNumber] = useState(0);
  const [wrongTemplate, setWrongTemplate] = useState(false);

  const onBrowseClickHandler = () => {
    inputFile.current.click();
  };

  const onChangeFileHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const importFile = event.target.files[0];
    if (importFile) {
      setFile(importFile);
      setFileName(importFile.name);
      setIsOpenDialog(true);
      event.target.value = '';
    }
  };

  const onCloseDialog = () => {
    if (isImported && !brokenRecords.length && !missingFields.length && !noMatchedFields.length && !missingRowNumber && !wrongTemplate) {
      dispatch(getMonthlyScores(scoreFilter));
    }
    setIsOpenDialog(false);
    setFile(null);
    setIsAgreeToImport(false);
    dispatch(resetImportScoreStates());
  };

  const onConfirmImport = () => {
    const classroomObj = classroomList.find(
      c => c.grade === grade && c.suffix === suffix);
    const data = {
      academic_year_id: academicYear,
      classroom_id: classroomObj.id,
      month,
      file: file
    };
    dispatch(importStudentScore(data, translate('enrollment.importSuccess')));
    setFile(null);
    setIsImportingScore(true);
  };

  useEffect(() => {
    if (Object.keys(importedResult).length) {
      setBrokenRecords(importedResult.brokenRecords || []);
      setTotalGoodRecords(importedResult.totalGoodRecords || 0);
      setMissingFields(importedResult.missingFields || []);
      setNoMatchedFields(importedResult.notMatchedFields || []);
      setMissingRowNumber(importedResult.missingRowNumber || 0);
      setWrongTemplate(importedResult.wrongTemplate || false);
    }
  }, [importedResult]);

  useEffect(() => {
    if (isImported) {
      setIsImportingScore(false);
    }
    // eslint-disable-next-line
  }, [isImported]);

  useEffect(() => {
    if (isImported && Object.keys(importedResult).length) {
      if (!importedResult.brokenRecords && !importedResult.missingFields && !importedResult.noMatchedFields && !importedResult.missingRowNumber && !importedResult.wrongTemplate) {
        onCloseDialog();
      }
    }
    // eslint-disable-next-line
  }, [isImported, importedResult]);

  const handleOnClickDownloadTemplate = (e) => {
    e.preventDefault();
    const selectedAcademicYear = academicYears.find(academic => academic.id === academicYear);
    const academicStartDate = moment(selectedAcademicYear.start_date, settings.dateFormat);
    const academicEndDate = moment(selectedAcademicYear.end_date, settings.dateFormat);
    const classroomObj = classroomList.find(c => c.grade === grade && c.suffix === suffix);
    let monthName;
    if (month === 13) {
      monthName = translate('common.semester1');
    } else if (month === 14) {
      monthName = translate('common.semester2');
    } else {
      const selectedMonth = SCORE.months.find(monthObj => monthObj.value === month);
      monthName = translate('common.month.' + selectedMonth.label);
    }
    const templateName = academicStartDate.year() + '-' + academicEndDate.year() + '_' +
      classroomObj.grade_name + suffix + '_' + monthName + '_' + moment().format('YYYYMMDD');
    const filterParams = {
      type: 'monthly_score',
      academic_year_school_id: school,
      grade,
      suffix,
      month
    };
    dispatch(downloadTemplate(filterParams, templateName));
  };

  return (
    <>
      <Dialog
        open={isOpenDialog}
        className={dialogStyle.dialogHelp}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
      >
        <DialogTitle>
          <div className={dialogStyle.secondGrid}>
            <h6 className={dialogStyle.confirmDialogHeader}>
              {translate('common.import')}
            </h6>
          </div>
        </DialogTitle>
        <DialogContent className={dialogStyle.dialogContent}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText className={dialogStyle.listItemText}
                primary={fileName}
              />
            </ListItem>
          </List>
          {
            !isImporting && !isImported && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => { setIsAgreeToImport(e.target.checked); }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translate('score.importAgreement')}
              />
            )
          }
          {
            isImporting && (
              <LinearProgress />
            )
          }
          {
            isImported && (
              <ImportScoreDetail
                brokenRecords={brokenRecords}
                totalGoodRecords={totalGoodRecords}
                missingFields={missingFields}
                noMatchedFields={noMatchedFields}
                missingRowNumber={missingRowNumber}
                wrongTemplate={wrongTemplate}
              />
            )
          }
          <Divider />
        </DialogContent>

        <DialogActions
          className={dialogStyle.dialogAction}
        >
          <div className={dialogStyle.normalSaveClose}>
            <ThemeButton
              variant="contained"
              className={dialogStyle.buttonDefault}
              onClick={onCloseDialog}
            >
              {translate('common.button.close')}
            </ThemeButton>
            {
              !isImported && !isImporting && (
                <ThemeButton
                  color="primary"
                  variant="contained"
                  onClick={onConfirmImport}
                  disabled={!isAgreeToImport}
                >
                  {translate('common.button.confirm')}
                </ThemeButton>
              )
            }
          </div>
        </DialogActions>
      </Dialog>
      <input id="myInput"
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xlsx, .xls"
        onChange={onChangeFileHandler}
      />
      {isImportingScore ? (
        <ThemeButton
          color="info"
          disabled
        >
          <CircularProgress size={20} className={dialogStyle.circularProgress}/>
          {translate('common.importing')}
        </ThemeButton>
      ) : (
        <ThemeButton
          color="info"
          type="submit"
          onClick={onBrowseClickHandler}
        >
          {translate('common.import')}
        </ThemeButton>
      )}

      <FormHelperText>
        {translate('score.scoreTemplateHelpText')}&nbsp;
        <Link href="#" onClick={handleOnClickDownloadTemplate}>
          {translate('common.download')}
        </Link>
      </FormHelperText>
    </>
  );
}

ImportScoreButton.propTypes = {
  academicYear: PropTypes.number,
  school: PropTypes.number,
  grade: PropTypes.number,
  suffix: PropTypes.string,
  month: PropTypes.number,
  isImportingScore: PropTypes.bool,
  setIsImportingScore: PropTypes.func
};
