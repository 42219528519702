import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import { useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import ThemeButton from 'components/theme/CustomButtons/Button';
import * as VARIABLES from '../../../variables/graduation';

export default function GraduationTable (props) {
  const {
    classes,
    columns,
    rows,
    count,
    limit,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    selection,
    onSelect,
    onSelectAllClick,
    onChangeRowHandler
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isSelected = (id) => selection.indexOf(id) !== -1;
  return (
    <LoadablePaper
      rendered
      loading={false}
    >
      <LoadablePaperContent>
        <Card>
          <CardBody>
            <Toolbar
              className={classes.toolbar}
            >
              <Typography color="inherit" variant="subtitle1" component="div">
                {translate('common.numberSelected', { number: selection.length })}
              </Typography>
            </Toolbar>

            <Divider />

            <Table
              size='small'
              tableHead={columns}
              tableData={rows.map(row => {
                const isItemSelected = isSelected(row.id);
                const checkBox = (
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': row.id }}
                    onChange={e => onSelect(e, row.id)}
                    classes={{
                      checked: classes.tableCheckbox
                    }}
                  />
                );
                const changeButton = (
                  <ThemeButton
                    size="sm"
                    color="primary"
                    type="submit"
                    onClick={() => onChangeRowHandler(row.id)}
                    disabled={ row.graduationStatus !== 0 }
                  >
                    {translate('graduation.change')}
                  </ThemeButton>
                );
                const renderResult = row => {
                  const result = row.resultChanged !== null ? row.resultChanged : row.result;
                  if (result !== null) {
                    return translate(`graduation.resultChoice.${VARIABLES.result[result]}`).toUpperCase();
                  }
                  return '';
                };

                const renderGraduated = graduated => {
                  switch (graduated) {
                    case 1:
                      return translate('graduation.promote').toUpperCase();
                    case 2:
                      return translate('graduation.repeat').toUpperCase();
                    default:
                      return '';
                  }
                };

                const renderReasonForChanged = row => {
                  let reasonForChanged = '';
                  if (row.reasonForChanged) {
                    reasonForChanged = translate(`graduation.reasonChoice.${VARIABLES.reason[row.reasonForChanged]}`);

                    if (row.reasonForChanged === VARIABLES.otherReason && row.otherReason) {
                      reasonForChanged += `: ${row.otherReason}`;
                    }
                  }

                  return reasonForChanged;
                };

                return [
                  checkBox,
                  row.stsId,
                  row.name,
                  row.gender === 'm' ? translate('common.genderType.m') : row.gender === 'f' ? translate('common.genderType.f') : '',
                  row.birthDate,
                  Math.round((row.scoreSemester1 + Number.EPSILON) * 100) / 100,
                  Math.round((row.scoreSemester2 + Number.EPSILON) * 100) / 100,
                  Math.round(((row.scoreSemester1 + row.scoreSemester2) / 2 + Number.EPSILON) * 100) / 100,
                  row.ranking,
                  renderResult(row),
                  renderGraduated(row.graduationStatus),
                  renderReasonForChanged(row),
                  changeButton
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              isNeedCheckBox
              numSelected={selection.length}
              onSelectAllClick={onSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customCellClasses={['center']}
              customClassesForCells={[0, 1]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
}

GraduationTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  columns: PropTypes.array,
  rows: PropTypes.array,
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  selection: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onChangeRowHandler: PropTypes.func
};
