export const GET_USER_GROUPS_REQUEST = 'GET_USER_GROUPS_REQUEST';
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_FAIL = 'GET_USER_GROUPS_FAIL';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const CREATE_EDIT_USER_REQUEST = 'CREATE_EDIT_USER_REQUEST';
export const CREATE_EDIT_USER_SUCCESS = 'CREATE_EDIT_USER_SUCCESS';
export const CREATE_EDIT_USER_FAIL = 'CREATE_EDIT_USER_FAIL';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAIL = 'ACTIVATE_USER_FAIL';
