import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import HeaderLinks from 'layout/header/HeaderLinks';
import Button from 'components/theme/CustomButtons/Button';

import styles from 'assets/theme/jss/material-dashboard-pro-react/components/adminNavbarStyle';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { loadProfile } from 'redux/actions';

const useStyles = makeStyles(styles);

export default function Header (props) {
  const [keycloak] = useKeycloak();
  const classes = useStyles();
  const { color, rtlActive, drawerOpen, onToggleDrawer } = props;
  const dispatch = useDispatch();
  const [isProfileLoaded, setIsProfileLoaded] = React.useState(false);

  const appBarClasses = cx({
    [` ${classes[color]}`]: color
  });
  const sidebarMinimize = `${classes.sidebarMinimize
        } ${
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        })}`;

  if (!isProfileLoaded && keycloak.authenticated) {
    dispatch(loadProfile());
    setIsProfileLoaded(true);
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={sidebarMinimize}>
          {drawerOpen ? (
            <Button
              justIcon
              round
              color="white"
              onClick={onToggleDrawer}
            >
              <ViewList className={classes.sidebarMiniIcon} />
            </Button>
          ) : (
            <Button
              justIcon
              round
              color="white"
              onClick={onToggleDrawer}
            >
              <MoreVert className={classes.sidebarMiniIcon} />
            </Button>
          )}
        </div>

        <Hidden smDown implementation="css">
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title} color="transparent" id="brandText">
              {''}
            </Button>
          </div>
        </Hidden>
        <HeaderLinks />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  drawerOpen: PropTypes.bool,
  onToggleDrawer: PropTypes.func
};
