import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import StudyDayInlineEditTable from 'views/AcademicYear/StudyDayTable/StudyDayInlineEditTable';
import { getTranslate } from 'react-localize-redux';
import GridItem from 'components/theme/Grid/GridItem';
import GridContainer from 'components/theme/Grid/GridContainer';

const StudyDayTable = (props) => {
  const {
    classes,
    studyMonths,
    rows,
    isLoadingTable,
    commitChanges
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (studyMonths.length) {
      const monthColumns = [];
      studyMonths.forEach(studyMonth => {
        monthColumns.push({
          name: studyMonth,
          title: translate('common.month.' + studyMonth)
        });
      });

      setColumns(monthColumns);
    } else {
      setColumns([]);
    }
  }, [studyMonths, translate]);

  return (
    <LoadablePaper
      rendered
      loading={isLoadingTable}
    >
      <LoadablePaperContent>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
              >
                <StudyDayInlineEditTable
                  classes={classes}
                  columns={columns}
                  rows={rows}
                  commitChanges={commitChanges}
                />
              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

StudyDayTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  rows: PropTypes.array,
  studyMonths: PropTypes.array,
  data: PropTypes.array,
  isLoadingTable: PropTypes.bool,
  commitChanges: PropTypes.func
};

export default StudyDayTable;
