import {
  primaryColor,
  primaryBoxShadow,
  grayColor,
  dangerColor,
  whiteColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const AutoCompleteInputStyle = (theme) => ({
  labelRoot: {
    marginBottom: '15px'
  },
  labelRootError: {
    color: `${dangerColor[0]} !important`
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: `${grayColor[5]}!important`,
      borderWidth: '1px !important',
      height: '40px'
    },
    '&:after': {
      borderColor: primaryColor[0]
    },
    '& + p': {
      fontWeight: '300'
    }
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor[0]
    }
  },
  input: {
    color: grayColor[14],
    '&,&::placeholder': {
      fontSize: '1rem',
      fontFamily: window.safari ? [
        'Roboto',
        'sans-serif'
      ].join(',') : [
        'Siemreap',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1'
    },
    '&::placeholder': {
      color: grayColor[3]
    }
  },
  disabled: {
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderColor: 'transparent !important'
    }
  },
  listbox: {
    '& li': {
      fontSize: '1rem',
      padding: '10px 20px',
      margin: '0 5px',
      borderRadius: '2px',
      transition: 'all 150ms linear',
      display: 'block',
      clear: 'both',
      fontWeight: '400',
      lineHeight: '2',
      whiteSpace: 'nowrap',
      color: grayColor[7],
      paddingRight: '30px'
    },
    '& li[data-focus="true"]': {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    }
  }
});

export default AutoCompleteInputStyle;
