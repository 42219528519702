import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { makeStyles } from '@material-ui/core/styles';

import ThemeButton from 'components/theme/CustomButtons/Button';
import RemittanceFilter from 'views/ScholarshipRemittance/Partial/RemittanceFilter';
import RemittanceTable from 'views/ScholarshipRemittance/Partial/RemittanceTable';
import RemittanceDialog from 'views/ScholarshipRemittance/Partial/RemittanceDialog';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';
import { getScholarshipList } from 'redux/actions';
import settings from 'settings';

const useStyles = makeStyles(styles);
const Remittance = () => {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const totalStudent = useSelector(state => state.scholarship.scholarshipList.validTotal);
  const remittanceList = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceList);
  const totalRecords = remittanceList?.total || 0;
  const remittanceListFilter = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceFilter);
  const academicYear = remittanceListFilter?.academicYear;
  const school = remittanceListFilter?.school;
  const [openRemittanceDialog, setOpenRemittanceDialog] = useState(false);
  const [editId, setEditId] = useState(0);

  // set title
  useEffect(() => {
    document.title = `${translate('scholarshipRemittance.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('scholarshipRemittance.pageTitle');
  }, [translate]);

  useEffect(() => {
    if (totalStudent === undefined && school) {
      const params = {
        academicYear,
        school,
        page: settings.startPage,
        limit: settings.rowsPerPage
      };
      dispatch(getScholarshipList(params));
    }
  }, [dispatch, totalStudent, academicYear, school]);

  const handlePayCash = () => {
    setOpenRemittanceDialog(true);
  };

  const handleEditRow = (id) => {
    setEditId(id);
    setOpenRemittanceDialog(true);
  };

  const handleCloseRemittanceDialog = () => {
    setEditId(0);
    setOpenRemittanceDialog(false);
  };

  return (
    <>
      <RemittanceFilter />
      <RemittanceTable onEditRowHandler={handleEditRow} />

      { openRemittanceDialog && (school || editId) &&
        <RemittanceDialog school={school} editId={editId} totalStudent={totalStudent} open={openRemittanceDialog} onClose={handleCloseRemittanceDialog} />
      }

      <div className={classes.actionContainer}>
        <ThemeButton
          color="primary"
          type="submit"
          onClick={handlePayCash}
          disabled={!remittanceList.data || !totalStudent || totalRecords === 3}
        >
          {translate('scholarshipRemittance.addRemittance')}
        </ThemeButton>
      </div>
    </>
  );
};

export default Remittance;
