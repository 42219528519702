import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  DataTypeProvider,
  EditingState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableInlineCellEditing
} from '@devexpress/dx-react-grid-material-ui';
import {
  Plugin,
  Template,
  TemplatePlaceholder
} from '@devexpress/dx-react-core';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { roles as ROLES } from 'variables/user';
import { useKeycloak } from '@react-keycloak/web';

const getRowId = row => row.id;

const EditPropsPanel = () => (
  <Plugin name="EditPropsPanel">
    <Template name="toolbarContent">
      <TemplatePlaceholder />
    </Template>
  </Plugin>
);

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

FocusableCell.propTypes = {
  onClick: PropTypes.func
};

const AttendanceInlineEditTable = (props) => {
  const {
    classes,
    columns,
    columnBands,
    cellComponent,
    rows,
    columnExtensions,
    commitChanges,
    isSorting,
    defaultSorting,
    sorting,
    editingStateColumnExtensions,
    isPostOrArchivedAcademicYear
  } = props;

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const [keycloak] = useKeycloak();

  const [startEditAction, setStartEditAction] = useState('click');
  const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);
  const [genderColumns] = useState(['gender']);

  const GenderFormatter = ({ value }) => ['f', 'm'].includes(value) ? translate('common.genderType.' + value) : '';

  return (
    <div className={classes.tableResponsive}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <DataTypeProvider
          for={genderColumns}
          formatterComponent={GenderFormatter}
          {...props}
        />
        {
          isSorting && (
            <SortingState
              defaultSorting={defaultSorting}
              sorting={sorting}
              columnExtensions={columnExtensions}
            />
          )
        }
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />
        <Table
          cellComponent={FocusableCell}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow />
        <EditPropsPanel
          defaultAction={startEditAction}
          changeAction={setStartEditAction}
          isSelectText={selectTextOnEditStart}
          changeSelectText={setSelectTextOnEditStart}
        />

        {
          (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM) || keycloak.hasRealmRole(ROLES.MANAGE_OWNED_CLASSROOM)) && !isPostOrArchivedAcademicYear && (
            <TableInlineCellEditing
              startEditAction={startEditAction}
              selectTextOnEditStart={selectTextOnEditStart}
            />
          )
        }

        <TableBandHeader
          columnBands={columnBands}
          cellComponent={cellComponent}
        />
      </Grid>
    </div>
  );
};

AttendanceInlineEditTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  columnBands: PropTypes.array,
  columnExtensions: PropTypes.array,
  commitChanges: PropTypes.func,
  isSorting: PropTypes.bool,
  defaultSorting: PropTypes.array,
  sorting: PropTypes.array,
  onSortingChange: PropTypes.func,
  classes: PropTypes.instanceOf(Object),
  cellComponent: PropTypes.any,
  editingStateColumnExtensions: PropTypes.instanceOf(Object),
  isPostOrArchivedAcademicYear: PropTypes.bool
};

export default AttendanceInlineEditTable;
