import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import StudentIcon from '@material-ui/icons/CalendarToday';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import Button from 'components/theme/CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import * as ROUTES from 'variables/routeNames';

import history from 'utilities/history';
import styles from 'assets/sts/jss/views/student/createEditStudentStyle';

import _ from 'lodash';
import Input from 'components/common/Input/Input';
import Typography from '@material-ui/core/Typography';
import StudentSelectSex from 'components/shared/students/SelectSex';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';
import Select from 'components/common/Select/Select';
import Render from 'components/hoc/Render/Render';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import {
  clearUnits,
  getCommunes,
  getDistricts,
  getProvinces, getStudentOptions,
  getVillages,
  getStudentDetailAsync
} from 'redux/actions';
import { getGazetteerChoices } from 'utilities/gazetteer';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const ViewStudent = (props) => {
  const { match } = props;
  const studentId = match.params.studentId;
  const classes = useStyles();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  const dispatch = useDispatch();
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const allDisabilities = useSelector(state => state.studentOption.disability);
  const allHealthProblems = useSelector(state => state.studentOption.healthProblem);
  const allPersonalProblems = useSelector(state => state.studentOption.personalProblem);
  const allEthnicity = useSelector(state => state.studentOption.ethnicity);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const isLoading = useSelector(state => state.auth.isProfileLoading);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const authProfile = useSelector(state => state.auth.profile);
  const studentDetail = useSelector(state => state.student.student);
  const [croppedImage, setCroppedImage] = useState(null);

  const [student, setStudent] = useState({
    firstName: '',
    lastName: '',
    firstNameEN: '',
    lastNameEN: '',
    gender: '',
    birthDate: '',
    birthPlace: '',

    fatherFirstName: '',
    fatherLastName: '',
    fatherOccupation: '',
    fatherPhone: '',

    motherFirstName: '',
    motherLastName: '',
    motherOccupation: '',
    motherPhone: '',

    isFatherAsGuardian: false,
    isMotherAsGuardian: false,
    isOtherAsGuardian: false,
    guardianFirstName: '',
    guardianLastName: '',
    guardianGender: '',
    guardianOccupation: '',
    guardianPhone: '',

    province: '',
    district: '',
    commune: '',
    village: '',

    street: '',
    houseNumber: '',
    phone: '',
    remark: '',

    ethnicity: '',
    disabilities: [],
    healthProblems: [],
    personalProblems: [],
    scholarships: [],
    lateEnrollment: false,
    isTransferIn: false,
    transferInSchoolName: '',
    transferInDate: ''
  });

  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [communeChoices, setCommuneChoices] = useState([]);
  const [villageChoices, setVillageChoices] = useState([]);
  const [studentRelation, setStudentRelation] = useState('');

  // set title
  const changeBrandText = useCallback(() => {
    document.title = `${translate('student.pageEditTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('student.pageEditTitle');
  }, [translate]);

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  useEffect(() => {
    if (studentId) {
      dispatch(getStudentDetailAsync(studentId));
    }
  }, [dispatch, studentId]);

  // fetch Student Option
  const fetchStudentOption = useCallback(
    () => {
      dispatch(getStudentOptions());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingStudentOption) {
      fetchStudentOption();
    }
    // eslint-disable-next-line
  }, [changeBrandText]);

  useEffect(() => {
    let studentInfo = null;

    if (!_.isEmpty(studentDetail)) {
      studentInfo = studentDetail;
    } else if (authProfile && !_.isEmpty(allHealthProblems) && !_.isEmpty(allPersonalProblems) && !_.isEmpty(allDisabilities)) {
      studentInfo = { ...authProfile.student, ...authProfile };
    }

    if (studentInfo) {
      let father = {
        fatherFirstName: '',
        fatherLastName: '',
        fatherOccupation: '',
        fatherPhone: ''
      };
      let mother = {
        motherFirstName: '',
        motherLastName: '',
        motherOccupation: '',
        motherPhone: ''
      };
      let guardian = {
        guardianFirstName: '',
        guardianLastName: '',
        guardianGender: '',
        guardianOccupation: '',
        guardianPhone: ''
      };
      let guardianRelated;
      if (studentInfo.relationships) {
        studentInfo.relationships.forEach(relationship => {
          if (relationship.is_father) {
            father = {
              fatherFirstName: relationship.first_name,
              fatherLastName: relationship.last_name,
              fatherOccupation: relationship.occupation || '',
              fatherPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'father' : guardianRelated;
          }

          if (relationship.is_mother) {
            mother = {
              motherFirstName: relationship.first_name,
              motherLastName: relationship.last_name,
              motherOccupation: relationship.occupation || '',
              motherPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'mother' : guardianRelated;
          }

          if (!relationship.is_father && !relationship.is_mother && relationship.is_guardian) {
            guardian = {
              guardianFirstName: relationship.first_name,
              guardianLastName: relationship.last_name,
              guardianGender: relationship.gender,
              guardianOccupation: relationship.occupation || '',
              guardianPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'other' : guardianRelated;
          }
        });
      }

      let currentStudent = {
        firstName: studentInfo.first_name,
        lastName: studentInfo.last_name,
        firstNameEN: studentInfo.first_name_en || '',
        lastNameEN: studentInfo.last_name_en || '',
        gender: studentInfo.gender,
        birthDate: studentInfo.birth_date,
        birthPlace: studentInfo.birth_place || '',

        province: studentInfo.province ? `${studentInfo.province.id},${studentInfo.province.code}` : '',
        district: studentInfo.district ? `${studentInfo.district.id},${studentInfo.district.code}` : '',
        commune: studentInfo.commune ? `${studentInfo.commune.id},${studentInfo.commune.code}` : '',
        village: studentInfo.village ? `${studentInfo.village.id},${studentInfo.village.code}` : '',

        street: studentInfo.street || '',
        houseNumber: studentInfo.house_number || '',
        phone: studentInfo.phone_number_1 || '',
        remark: studentInfo.remark || '',

        ethnicity: studentInfo.ethnicity ? studentInfo.ethnicity.id : '',
        disabilities: studentInfo.disabilities ? studentInfo.disabilities.map(disability => disability.id) : [],
        healthProblems: studentInfo.health_problems ? studentInfo.health_problems.map(healthProblem => healthProblem.id) : [],
        personalProblems: studentInfo.personal_problems ? studentInfo.personal_problems.map(problem => problem.id) : [],
        scholarships: studentInfo.scholarships ? studentInfo.scholarships.map(scholarship => scholarship.scholarship_id) : [],
        lateEnrollment: studentInfo.late_enrollment === 1,
        isTransferIn: studentInfo.transfer_status === 1,
        transferInSchoolName: getActiveLanguage(localize).code === 'en' ? studentInfo.from_school_name_en : studentInfo.from_school_name,
        transferInDate: studentInfo.transfer_out_date
      };
      currentStudent = Object.assign(currentStudent, father, mother, guardian);

      switch (guardianRelated) {
        case 'father':
          currentStudent.isFatherAsGuardian = true;
          currentStudent.isMotherAsGuardian = false;
          currentStudent.isOtherAsGuardian = false;
          setStudentRelation('father');
          break;
        case 'mother':
          currentStudent.isFatherAsGuardian = false;
          currentStudent.isMotherAsGuardian = true;
          currentStudent.isOtherAsGuardian = false;
          setStudentRelation('mother');
          break;
        case 'other':
          currentStudent.isFatherAsGuardian = false;
          currentStudent.isMotherAsGuardian = false;
          currentStudent.isOtherAsGuardian = true;
          setStudentRelation('other');
          break;
        default:
      }

      if (_.isEmpty(studentInfo.photo)) {
        setCroppedImage(null);
      } else {
        setCroppedImage(studentInfo.photo);
      }
      setStudent(currentStudent);
    }
  }, [translate, authProfile, allDisabilities, allHealthProblems, allPersonalProblems, studentDetail, localize]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    }, [dispatch]
  );

  useEffect(() => {
    fetchProvincesData();
    // eslint-disable-next-line
  }, [changeBrandText]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
  }, [provinces, localize]
  );

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (student.province !== '') {
        dispatch(getDistricts(student.province));
      }
      dispatch(clearUnits('province'));
    }, [dispatch, student.province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
  }, [districts, localize]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(() => {
    if (student.district !== '') {
      dispatch(getCommunes(student.district));
    }
    dispatch(clearUnits('district'));
  }, [dispatch, student.district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes));
  }, [communes, localize]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(() => {
    if (student.commune !== '') {
      dispatch(getVillages(student.commune));
    }
    dispatch(clearUnits('commune'));
  }, [dispatch, student.commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages));
  }, [villages, localize]
  );

  const ethnicityChoices = allEthnicity.map(ethnicity => ({
    label: getActiveLanguage(localize).code === 'en' ? ethnicity.name_en : ethnicity.name,
    value: ethnicity.id
  }));

  ethnicityChoices.unshift({
    label: translate('student.noEthnicity'),
    value: ''
  });

  const form = (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <StudentIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('profile.title')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={9}
                  lg={9}
                  xl={9}
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.lastName}
                        name="lastName"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.firstName}
                        name="firstName"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography
                        variant="h5"
                        classes={{
                          h5: classes.boxTitle
                        }}
                      >
                        {translate('student.keyboardTip')}
                      </Typography>
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        classes.boxGrid
                      }
                    >
                      <GridContainer
                        className={
                          classes.innerGridContainer
                        }
                      >
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabProfile.lastNameEN')}
                            placeholder={translate('student.tabProfile.lastName')}
                            value={student.lastNameEN}
                            name="lastNameEN"
                            disabled
                            helperText={''}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabProfile.firstNameEN')}
                            placeholder={translate('student.tabProfile.fistName')}
                            value={student.firstNameEN}
                            name="firstNameEN"
                            helperText={''}
                            disabled
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <StudentSelectSex
                        require
                        value={student.gender}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <DateTimePicker
                        label={translate('enrollment.birthDate')}
                        name="birthDate"
                        value={student.birthDate}
                        timeFormat={false}
                        placeholder={translate('enrollment.placeholder.birthDate')}
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Input
                        label={translate('student.birthPlace')}
                        name="birthPlace"
                        value={student.birthPlace}
                        disabled
                        inputProps={{
                          multiline: true,
                          rows: 1
                        }}
                        helperText={''}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Box display="flex" justifyContent="center">
                    <Box>
                      <CardMedia
                        className={classes.photoContainer}
                        component="img"
                        alt={translate('student.studentPhoto')}
                        image={croppedImage || '/images/nobody.jpg'}
                        title={translate('student.studentPhoto')}
                      />
                    </Box>
                  </Box>
                </GridItem>

                {/* Father information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.fatherInfoTitle')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.fatherLastName}
                        name="fatherLastName"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.fatherFirstName}
                        name="fatherFirstName"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.occupation')}
                        placeholder={translate('student.tabProfile.placeholder.occupation')}
                        value={student.fatherOccupation}
                        name="fatherOccupation"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.fatherPhone}
                        name="fatherPhone"
                        disabled
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End father information */}

                {/* Mother information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.motherInfoTitle')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.motherLastName}
                        name="motherLastName"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.motherFirstName}
                        name="motherFirstName"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.occupation')}
                        placeholder={translate('student.tabProfile.placeholder.occupation')}
                        value={student.motherOccupation}
                        name="motherOccupation"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        disabled
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.motherPhone}
                        name="motherPhone"
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End mother information */}

                {/* Guardian information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.guardianInfoTitle')}
                  </Typography>
                </GridItem>
                <Render>
                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          disabled
                          checked={studentRelation === 'father'}
                          onChange={() => {
                            setStudentRelation('father');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: true,
                              isMotherAsGuardian: false,
                              isOtherAsGuardian: false
                            });
                          }
                          }
                          value="father"
                          name="father"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.father')}
                    />
                  </div>

                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          disabled
                          checked={studentRelation === 'mother'}
                          onChange={() => {
                            setStudentRelation('mother');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: false,
                              isMotherAsGuardian: true,
                              isOtherAsGuardian: false
                            });
                          }}
                          value="mother"
                          name="mother"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.mother')}
                    />
                  </div>

                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          disabled
                          checked={studentRelation === 'other'}
                          onChange={() => {
                            setStudentRelation('other');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: false,
                              isMotherAsGuardian: false,
                              isOtherAsGuardian: true
                            });
                          }}
                          value="other"
                          name="other"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.other')}
                    />
                  </div>
                </Render>
                {
                  student.isOtherAsGuardian && (
                    <Render>
                      <GridItem
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={
                          classes.boxGrid
                        }
                      >
                        <GridContainer
                          className={
                            classes.innerGridContainer
                          }
                        >
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.lastName')}
                              placeholder={translate('student.tabProfile.lastName')}
                              value={student.guardianLastName}
                              name="guardianLastName"
                              disabled
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.fistName')}
                              placeholder={translate('student.tabProfile.fistName')}
                              value={student.guardianFirstName}
                              name="guardianFirstName"
                              disabled
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Select
                              label={translate('common.gender')}
                              placeholder={translate('common.placeholder.gender')}
                              value={student.guardianGender}
                              name="guardianGender"
                              choices={[
                                { label: translate('common.genderType.f'), value: 'f' },
                                { label: translate('common.genderType.m'), value: 'm' }
                              ]}
                              disabled
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.occupation')}
                              placeholder={translate('student.tabProfile.placeholder.occupation')}
                              value={student.guardianOccupation}
                              name="guardianOccupation"
                              disabled
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.phone')}
                              placeholder={translate('student.tabProfile.placeholder.phone')}
                              value={student.guardianPhone}
                              name="guardianPhone"
                              disabled
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </Render>
                  )
                }
                {/* End guardian information */}

                {/* Current address */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.currentAddress')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.province')}
                        placeholder={translate('gazetteer.placeholder.province')}
                        value={student.province}
                        name="province"
                        choices={provinceChoices}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.district')}
                        placeholder={translate('gazetteer.placeholder.district')}
                        value={student.district}
                        name="district"
                        disabled
                        choices={districtChoices}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.commune')}
                        placeholder={translate('gazetteer.placeholder.commune')}
                        value={student.commune}
                        name="commune"
                        disabled
                        choices={communeChoices}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.village')}
                        placeholder={translate('gazetteer.placeholder.village')}
                        value={student.village}
                        name="village"
                        choices={villageChoices}
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.street')}
                        placeholder={translate('student.tabProfile.placeholder.street')}
                        value={student.street}
                        name="street"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.houseNumber')}
                        placeholder={translate('student.tabProfile.placeholder.houseNumber')}
                        value={student.houseNumber}
                        name="houseNumber"
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.phone}
                        name="phone"
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        disabled
                        label={translate('student.tabProfile.remark')}
                        value={student.remark}
                        name="remark"
                        inputProps={{
                          multiline: true,
                          rows: 1
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End current address */}

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.confidential')}
                  </Typography>
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        disabled
                        label={translate('enrollment.ethnicity')}
                        placeholder={translate('enrollment.placeholder.ethnicity')}
                        value={student.ethnicity}
                        name="ethnicity"
                        choices={ethnicityChoices}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        disabled
                        multiple
                        label={translate('enrollment.disability')}
                        placeholder={translate('enrollment.placeholder.disability')}
                        value={student.disabilities}
                        name="disabilities"
                        choices={allDisabilities.map(disability => ({
                          label: getActiveLanguage(localize).code === 'en' ? disability.name_en : disability.name,
                          value: disability.id,
                          isRenderAsParents: disability.parent_id === 0 && disability.all_children.length === 0,
                          disabled: disability.all_children.length !== 0
                        }))}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        disabled
                        multiple
                        label={translate('enrollment.healthProblem')}
                        placeholder={translate('enrollment.placeholder.healthProblem')}
                        value={student.healthProblems}
                        name="healthProblems"
                        choices={allHealthProblems.map(healthProblem => ({
                          label: getActiveLanguage(localize).code === 'en' ? healthProblem.name_en : healthProblem.name,
                          value: healthProblem.id
                        }))}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        disabled
                        multiple
                        label={translate('enrollment.personalProblem')}
                        placeholder={translate('enrollment.placeholder.personalProblem')}
                        value={student.personalProblems}
                        name="personalProblems"
                        choices={allPersonalProblems.map(personalProblem => ({
                          label: getActiveLanguage(localize).code === 'en' ? personalProblem.name_en : personalProblem.name,
                          value: personalProblem.id
                        }))}
                      />
                    </GridItem>

                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={10}
                  md={10}
                  lg={10}
                  xl={10}
                >
                  <Select
                    disabled
                    multiple
                    label={translate('enrollment.scholarship')}
                    placeholder={translate('enrollment.placeholder.scholarship')}
                    value={student.scholarships}
                    name="scholarships"
                    choices={allScholarships.map(ethnicity => ({
                      label: ethnicity.type,
                      value: ethnicity.id
                    }))}
                  />
                </GridItem>

                {/* enrollment status */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabEnrollment.title')}
                  </Typography>
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <FormControlLabel
                    control={<Checkbox
                      checked={student.lateEnrollment}
                      name="lateEnrollment"
                      classes={{
                        checked: classes.radio
                      }}
                      disabled
                    />}
                    classes={{
                      label: classes.labelRadio
                    }}
                    label={translate('student.tabEnrollment.lateEnrollment')}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <FormControlLabel
                    control={<Checkbox
                      checked={student.isTransferIn}
                      name="isTransferIn"
                      disabled
                    />}
                    classes={{
                      label: classes.labelRadio
                    }}
                    label={translate('student.tabEnrollment.transferIn')}
                  />
                </GridItem>

                {
                  student.isTransferIn && student.transferInSchoolName && (
                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        classes.boxGrid
                      }
                    >
                      <GridContainer
                        className={
                          classes.innerGridContainer
                        }
                      >
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabEnrollment.schoolName')}
                            placeholder={translate('student.tabEnrollment.schoolName')}
                            value={student.transferInSchoolName}
                            name="transferInSchoolName"
                            disabled
                          />
                        </GridItem>

                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <DateTimePicker
                            name='transferInDate'
                            value={student.transferInDate}
                            timeFormat={false}
                            label={translate('student.tabEnrollment.transferredDate')}
                            disabled
                          />
                        </GridItem>

                      </GridContainer>
                    </GridItem>
                  )
                }
              </GridContainer>

              <div
                className={classes.submitButton}
              >
                <Button
                  onClick={() => history.push(ROUTES.STUDENT)}
                >
                  {translate('common.button.cancel')}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <div>
      { (isLoading || isLoadingStudentOption) && <LoadingSpinner/>}
      {form}
    </div>
  );
};

export default ViewStudent;

ViewStudent.propTypes = {
  match: PropTypes.instanceOf(Object)
};
