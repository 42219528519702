import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  transferList: {},
  transferFilter: undefined,
  student: null,
  transfer: null,
  isLoading: false,
  transferToSchoolList: [],
  isLoadingSchoolList: false
};

function getTransferListRequested (state) {
  return {
    ...state,
    transferList: {},
    transferFilter: undefined,
    isLoading: true
  };
}

function getTransferListSuccess (state, action) {
  return {
    ...state,
    transferList: action.transferList,
    transferFilter: action.transferFilter,
    isLoading: false
  };
}

function getTransferListFail (state) {
  return {
    ...state,
    transferList: {},
    transferFilter: undefined,
    isLoading: false
  };
}

function getTransferStudentRequested (state) {
  return {
    ...state,
    student: null,
    isLoading: true
  };
}

function getTransferStudentSuccess (state, action) {
  return {
    ...state,
    student: action.student,
    isLoading: false
  };
}

function getTransferStudentFail (state) {
  return {
    ...state,
    student: null,
    isLoading: false
  };
}

const resetTransferStudent = (state) => {
  return {
    ...state,
    student: null
  };
};

function getTransferReviewRequested (state) {
  return {
    ...state,
    transfer: null,
    isLoading: true
  };
}

function getTransferReviewSuccess (state, action) {
  return {
    ...state,
    transfer: action.transfer,
    isLoading: false
  };
}

function getTransferReviewFail (state) {
  return {
    ...state,
    transfer: null,
    isLoading: false
  };
}

function approveTransferRequested (state) {
  return {
    ...state,
    isLoading: true
  };
}

function approveTransferSuccess (state) {
  return {
    ...state,
    isLoading: false
  };
}

function approveTransferFail (state) {
  return {
    ...state,
    isLoading: false
  };
}

const getTransferToSchoolListRequested = (state) => ({
  ...state,
  isLoadingSchoolList: true
});

const getTransferToSchoolListSuccess = (state, action) => ({
  ...state,
  transferToSchoolList: action.transferToSchoolList,
  isLoadingSchoolList: false
});

const getTransferToSchoolListFail = (state) => ({
  ...state,
  isLoadingSchoolList: false
});

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSFER_LIST_REQUEST:
      return getTransferListRequested(state);
    case actionTypes.GET_TRANSFER_LIST_SUCCESS:
      return getTransferListSuccess(state, action);
    case actionTypes.GET_TRANSFER_LIST_FAIL:
      return getTransferListFail(state);

    case actionTypes.GET_TRANSFER_STUDENT_REQUEST:
      return getTransferStudentRequested(state);
    case actionTypes.GET_TRANSFER_STUDENT_SUCCESS:
      return getTransferStudentSuccess(state, action);
    case actionTypes.GET_TRANSFER_STUDENT_FAIL:
      return getTransferStudentFail(state);
    case actionTypes.RESET_TRANSFER_STUDENT:
      return resetTransferStudent(state);

    case actionTypes.GET_TRANSFER_REVIEW_REQUEST:
      return getTransferReviewRequested(state);
    case actionTypes.GET_TRANSFER_REVIEW_SUCCESS:
      return getTransferReviewSuccess(state, action);
    case actionTypes.GET_TRANSFER_REVIEW_FAIL:
      return getTransferReviewFail(state);

    case actionTypes.APPROVE_TRANSFER_REQUEST:
      return approveTransferRequested(state);
    case actionTypes.APPROVE_TRANSFER_SUCCESS:
      return approveTransferSuccess(state);
    case actionTypes.APPROVE_TRANSFER_FAIL:
      return approveTransferFail(state);

    case actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_REQUEST:
      return getTransferToSchoolListRequested(state);
    case actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_SUCCESS:
      return getTransferToSchoolListSuccess(state, action);
    case actionTypes.GET_TRANSFER_TO_SCHOOL_LIST_FAIL:
      return getTransferToSchoolListFail(state);

    default:
      return state;
  }
};

export default studentReducer;
