import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import ThemeButton from 'components/theme/CustomButtons/Button';

import LoadablePaper from 'components/common/Paper/LoadablePaper';
import AttendanceInlineEditTable from 'views/Attendance/AttendanceInlineEditTable';
import { getTranslate } from 'react-localize-redux';
import GridItem from 'components/theme/Grid/GridItem';
import GridContainer from 'components/theme/Grid/GridContainer';
import { roles as ROLES } from 'variables/user';
import { useKeycloak } from '@react-keycloak/web';
import ImportAttendanceButton from './ImportAttendanceButton';

const AttendanceTable = (props) => {
  const {
    classes,
    columns,
    columnExtensions,
    editingStateColumnExtensions,
    rows,
    femaleStudents,
    columnBands,
    cellComponent,
    commitChanges,
    onHandleSaveAll,
    isPostOrArchivedAcademicYear
  } = props;

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const [keycloak] = useKeycloak();

  const isLoading = useSelector(state => state.student.isLoading);
  const isLoadingAttendance = useSelector(state => state.attendance.isLoading);
  const isSaving = useSelector(state => state.attendance.isSaving);

  return (
    <LoadablePaper
      rendered
      loading={isLoading || isLoadingAttendance}
    >
      <LoadablePaperContent>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem
                xs={12}
              >
                <AttendanceInlineEditTable
                  classes={classes}
                  columns={columns}
                  columnExtensions={columnExtensions}
                  editingStateColumnExtensions={editingStateColumnExtensions}
                  columnBands={columnBands}
                  cellComponent={cellComponent}
                  rows={rows}
                  commitChanges={commitChanges}
                  isPostOrArchivedAcademicYear={isPostOrArchivedAcademicYear}
                />
              </GridItem>
              <GridItem
                xs={12}
              >
                <div>
                  <strong>{translate('student.total')}</strong>
                  <strong className={classes.totalRecord}>{rows.length}</strong>
                  <strong className={classes.totalFemaleRecord}>{translate('student.totalGender.f')}</strong>
                  <strong className={classes.totalRecord}>{femaleStudents.length}</strong>
                </div>
              </GridItem>
              {
                (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM) || keycloak.hasRealmRole(ROLES.MANAGE_OWNED_CLASSROOM)) && !isPostOrArchivedAcademicYear && (
                  <GridItem
                    xs={12}
                  >
                    <div
                      className={classes.allButton + ' ' + classes.textRight}
                    >

                      <ThemeButton
                        color="primary"
                        type="submit"
                        onClick={onHandleSaveAll}
                        disabled={rows.length === 0 || isSaving}
                      >
                        {translate('common.button.saveAll')}
                      </ThemeButton>
                    </div>
                    { rows.length !== 0 &&
                      (
                        <ImportAttendanceButton
                          rows={rows}
                        />
                      )
                    }

                  </GridItem>
                )
              }
            </GridContainer>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

AttendanceTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  rows: PropTypes.array,
  femaleStudents: PropTypes.array,
  commitChanges: PropTypes.func,
  columns: PropTypes.instanceOf(Object),
  columnExtensions: PropTypes.instanceOf(Object),
  editingStateColumnExtensions: PropTypes.instanceOf(Object),
  cellComponent: PropTypes.any,
  onHandleSaveAll: PropTypes.func,
  columnBands: PropTypes.array,
  isPostOrArchivedAcademicYear: PropTypes.bool
};

export default AttendanceTable;
