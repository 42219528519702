import React from 'react';
import PropTypes from 'prop-types';
import {
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing
} from '@devexpress/dx-react-grid-material-ui';

const getRowId = row => row.id;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

FocusableCell.propTypes = {
  onClick: PropTypes.func
};

const StudyDayInlineEditTable = (props) => {
  const {
    classes,
    columns,
    rows,
    commitChanges
  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={commitChanges}
        />
        <Table
          cellComponent={FocusableCell}
        />
        <TableHeaderRow />
        <TableInlineCellEditing
          startEditAction="click"
          selectTextOnEditStart={true}
        />
      </Grid>
    </div>
  );
};

StudyDayInlineEditTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  classes: PropTypes.instanceOf(Object),
  commitChanges: PropTypes.func
};

export default StudyDayInlineEditTable;
