import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  getStudentList,
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';

function getUserGroupsRequested () {
  return {
    type: actionTypes.GET_USER_GROUPS_REQUEST
  };
}

function getUserGroupsSuccess (groups) {
  return {
    type: actionTypes.GET_USER_GROUPS_SUCCESS,
    groups
  };
}

function getUserGroupsFail () {
  return {
    type: actionTypes.GET_USER_GROUPS_FAIL
  };
}

function getUserListRequested () {
  return {
    type: actionTypes.GET_USER_LIST_REQUEST
  };
}

function getUserListSuccess (userList, userFilter) {
  return {
    type: actionTypes.GET_USER_LIST_SUCCESS,
    userList,
    userFilter
  };
}

function getUserListFail () {
  return {
    type: actionTypes.GET_USER_LIST_FAIL
  };
}

const createEditUserRequest = () => {
  return {
    type: actionTypes.CREATE_EDIT_USER_REQUEST
  };
};

const createEditUserSuccess = () => {
  return {
    type: actionTypes.CREATE_EDIT_USER_SUCCESS
  };
};

const createEditUserFail = () => {
  return {
    type: actionTypes.CREATE_EDIT_USER_FAIL
  };
};

function getUserRequested () {
  return {
    type: actionTypes.GET_USER_REQUEST
  };
}

function getUserSuccess (user) {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user
  };
}

function getUserFail () {
  return {
    type: actionTypes.GET_USER_FAIL
  };
}

function activateUserRequested () {
  return {
    type: actionTypes.ACTIVATE_USER_REQUEST
  };
}

function activateUserSuccess () {
  return {
    type: actionTypes.ACTIVATE_USER_SUCCESS
  };
}

function activateUserFail () {
  return {
    type: actionTypes.ACTIVATE_USER_FAIL
  };
}

export const getUserGroups = () => {
  return (dispatch) => {
    dispatch(getUserGroupsRequested());
    axios.get('/groups')
      .then(
        res => {
          const data = res.data;
          dispatch(getUserGroupsSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getUserGroupsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getUserList = (params) => {
  return (dispatch) => {
    dispatch(getUserListRequested());

    axios.get('/users', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta.total;
          dispatch(getUserListSuccess(data, params));
        }
      )
      .catch(e => {
        dispatch(getUserListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const createEditUser = (data, id) => {
  return (dispatch) => {
    dispatch(createEditUserRequest());
    if (id) {
      axios.put(`/users/${id}`, data)
        .then(
          res => {
            dispatch(createEditUserSuccess());
            dispatch(showSuccessNotification('userManagement.editSuccessfulMessage'));
            dispatch(getUser(id));
          }
        )
        .catch(e => {
          dispatch(createEditUserFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    } else {
      axios.post('/users', data)
        .then(
          res => {
            dispatch(createEditUserSuccess());
            dispatch(showSuccessNotification('userManagement.createSuccessfulMessage'));
            history.push(ROUTES.USER);
          }
        )
        .catch(e => {
          dispatch(createEditUserFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    }
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    dispatch(getUserRequested());
    axios.get(`/users/${id}`)
      .then(
        res => {
          const data = res.data.data;
          dispatch(getUserSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getUserFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const activateUser = (id, status, isStudentAcc = false, studentFilter = {}) => {
  return (dispatch, getState) => {
    dispatch(activateUserRequested());
    axios.delete(`/users/activate/${id}`)
      .then(
        res => {
          const { userFilter } = getState().user;
          dispatch(activateUserSuccess());
          dispatch(showSuccessNotification(status ? 'userManagement.deactivateSuccessfulMessage' : 'userManagement.activateSuccessfulMessage'));
          if (isStudentAcc) {
            dispatch(getStudentList(studentFilter));
          } else {
            dispatch(getUserList(userFilter));
          }
        }
      )
      .catch(e => {
        dispatch(activateUserFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
