import { REPORT_TYPES } from 'variables/report';

export const STS_STUDENT_REPORTS = {
  5: {
    name: 'student.classroomStudentMonthlyScoreCard',
    outputFormat: 'XLSX',
    outputFile: 'StudentMonthlyScoreCard.xlsx',
    enableClassroomSelection: true,
    enableMonthSelection: true,
    type: REPORT_TYPES.student
  },
  8: {
    name: 'student.classroomStudentAnnualScoreCard',
    outputFormat: 'XLSX',
    outputFile: 'StudentAnnualScoreCard.xlsx',
    enableClassroomSelection: true,
    type: REPORT_TYPES.student
  },
  50: {
    name: 'student.biography',
    outputFormat: 'XLSX',
    outputFile: 'StudentStudyBiography.xlsx',
    type: REPORT_TYPES.student
  }
};
