export const INDICATOR_TEMPLATES = [
  {
    key: 'IS',
    label: { en: 'Individual Student Report', km: 'របាយការណ៍សិស្សម្នាក់ៗ' },
    params: [
      {
        key: 'IS1',
        name: 'individualStudentReport',
        label: { en: 'Option', km: 'ជម្រើស' },
        type: 'SELECT',
        // Todo: Change the value order to make it consistent with the label, and also change in the server side.
        options: [
          {
            value: '003',
            title: { en: '001 – Students with disabilities', km: '001 – សិស្សដែលមានពិការភាព' }
          },
          {
            value: '004',
            title: { en: '002 – Students with health problems', km: '002 – សិស្សដែលមានបញ្ហាសុខភាព' }
          },
          {
            value: '005',
            title: { en: '003 – Students with personal problems', km: '003 – សិស្សដែលមានបញ្ហាផ្ទាល់ខ្លួន' }
          },
          {
            value: '006',
            title: { en: '004 – Students who have dropped out for the year', km: '004 – សិស្សបោះបង់ការសិក្សាសម្រាប់ឆ្នាំសិក្សា' }
          },
          {
            value: '007',
            title: { en: '005 – Students who have repeated for the year', km: '005 – សិស្សត្រួតថ្នាក់សម្រាប់ឆ្នាំសិក្សា' }
          },
          {
            value: '008',
            title: { en: '006 – Students who have transferred or re-enrolled for the year', km: '006 – សិស្សផ្ទេរឬចូលរៀនវិញសម្រាប់ឆ្នាំសិក្សា' }
          },
          {
            value: '009',
            title: { en: '007 – Students receiving scholarships', km: '007 – សិស្សទទួលបានអាហារូបករណ៍' }
          },
          {
            value: '010',
            title: { en: '008 – Students receiving scholarships who have dropped out', km: '008 – សិស្សអាហារូបករណ៍ដែលបោះបង់ការសិក្សា' }
          },
          {
            value: '011',
            title: { en: '009 – Students receiving scholarship remittance', km: '009 – សិស្សទទួលបានការបើកប្រាក់អាហារូបករណ៍' }
          },
          {
            value: '012',
            title: { en: '010 – Students Grade Result', km: '010 – លទ្ធផលការសិក្សារបស់សិស្ស' }
          },
          {
            value: '001',
            title: { en: '011 – Student absenteeism for the year', km: '011 – អវត្តមានសិស្សសម្រាប់ឆ្នាំសិក្សា' }
          },
          {
            value: '002',
            title: { en: '012 – Critically and Chronically absent students for the year', km: '012 – សិស្សអវត្តមានច្រើនសម្រាប់ឆ្នាំសិក្សា' }
          }
        ]
      }
    ]
  },
  {
    key: 'AG',
    label: { en: 'Aggregate Report', km: 'របាយការណ៍សរុប' },
    params: [
      {
        key: 'AG1',
        name: 'aggregateReport',
        label: { en: 'Option', km: 'ជម្រើស' },
        type: 'SELECT',
        // Todo: Change the value order to make it consistent with the label, and also change in the server side.
        options: [
          {
            value: '003',
            title: { en: '001 - % Disability by region/grade', km: '001 - ភាគរយសិស្សពិការភាព' }
          },
          {
            value: '004',
            title: { en: '002 - % Health Problems by region/grade', km: '002 - ភាគរយសិស្សមានបញ្ហាសុខភាព' }
          },
          {
            value: '005',
            title: { en: '003 - % Personal Problems by region/grade', km: '003 - ភាគរយសិស្សមានបញ្ហាផ្ទាល់ខ្លួន' }
          },
          {
            value: '006',
            title: { en: '004 - % Ethnicity by region/grade', km: '004 - ភាគរយសិស្សតាមក្រុមជនជាតិដើមភាគតិច' }
          },
          {
            value: '007',
            title: { en: '005 - % Dropout by region/grade', km: '005 - ភាគរយសិស្សបោះបង់ការសិក្សា' }
          },
          {
            value: '008',
            title: { en: '006 - % Dropout by region/grade by Ethnicity', km: '006 - ភាគរយសិស្សបោះបង់ការសិក្សាតាមក្រុមជនជាតិដើមភាគតិច' }
          },
          {
            value: '009',
            title: { en: '007 - % Repeaters by region/grade', km: '007 - ភាគរយសិស្សរៀនត្រូតថ្នាក់' }
          },
          {
            value: '010',
            title: { en: '008 - % Repeaters by region/grade by Ethnicity', km: '008 - ភាគរយសិស្សត្រូតថ្នាក់តាមក្រុមជនជាតិដើមភាគតិច' }
          },
          {
            value: '011',
            title: { en: '009 – % Scholarships by region/grade', km: '009 – ភាគរយសិស្សអាហារូបករណ៍' }
          },
          {
            value: '012',
            title: { en: '010 – % Grade result', km: '010 – ភាគរយលទ្ធផលការសិក្សារបស់សិស្ស' }
          },
          {
            value: '013',
            title: { en: '011 – % Scholarship remittance by region/grade', km: '011 – ភាគរយការបើកប្រាក់អាហារូបករណ៍' }
          },
          {
            value: '014',
            title: { en: '012 – % Scholarship funding by region/grade', km: '012 – ភាគរយមូលនិធិអាហារូបករណ៍' }
          },
          {
            value: '001',
            title: { en: '013 – Absenteeism rates by region/grade', km: '013 – អត្រាសិស្សអវត្តមាន' }
          },
          {
            value: '002',
            title: { en: '014 – Absenteeism rates by ethnicity by region/grade', km: '014 – អត្រាសិស្សអវត្តមានតាមក្រុមជនជាតិដើមភាគតិច' }
          }
        ]
      }
    ]
  }
];
