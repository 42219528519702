import { title, whiteColor, blackColor } from 'assets/theme/jss/material-dashboard-pro-react.js';

const errorPageStyles = (theme) => ({
  contentCenter: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    zIndex: '3',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    color: whiteColor,
    padding: '0 15px',
    width: '100%',
    maxWidth: '880px'
  },
  title: {
    ...title,
    [theme.breakpoints.up('md')]: {
      fontSize: '15em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10em'
    },
    color: blackColor,
    letterSpacing: '14px',
    fontWeight: '700'
  },
  subTitle: {
    fontSize: '5em',
    [theme.breakpoints.up('md')]: {
      fontSize: '4em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3em'
    },
    marginTop: '0',
    marginBottom: '8px',
    color: blackColor
  },
  description: {
    [theme.breakpoints.up('md')]: {
      fontSize: '2em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1em'
    },
    marginTop: '0',
    marginBottom: '8px',
    color: blackColor
  }
});

export default errorPageStyles;
