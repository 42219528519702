import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import StudentIcon from '@material-ui/icons/CalendarToday';
import Checkbox from '@material-ui/core/Checkbox';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { editStudent, getStudentDetailAsync } from 'redux/actions/student';

import history from 'utilities/history';
import styles from 'assets/sts/jss/views/student/createEditStudentStyle';

import _ from 'lodash';
import Input from 'components/common/Input/Input';
import Typography from '@material-ui/core/Typography';
import StudentSelectSex from 'components/shared/students/SelectSex';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';
import Select from 'components/common/Select/Select';
import Render from 'components/hoc/Render/Render';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import {
  clearUnits,
  getCommunes,
  getDistricts,
  getProvinces, getStudentOptions,
  getVillages, showErrorNotification
} from 'redux/actions';
import { getGazetteerChoices } from 'utilities/gazetteer';
import settings from 'settings';
import PropTypes from 'prop-types';
import * as ROUTES from 'variables/routeNames';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { ENROLL_STATUS } from 'variables/enrollStatus';
import ImageCropper from 'components/common/ImageCropper/ImageCropper';
import { base64toFile } from 'utilities/canvasUtils';

const useStyles = makeStyles(styles);

const EditStudent = (props) => {
  const { match } = props;
  const studentId = match.params.studentId;
  const classes = useStyles();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  const dispatch = useDispatch();
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const allDisabilities = useSelector(state => state.studentOption.disability);
  const allHealthProblems = useSelector(state => state.studentOption.healthProblem);
  const allPersonalProblems = useSelector(state => state.studentOption.personalProblem);
  const allEthnicity = useSelector(state => state.studentOption.ethnicity);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const studentDetail = useSelector(state => state.student.student);
  const isLoading = useSelector(state => state.student.isLoading);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const enrollmentStatusChoices = _.pickBy(ENROLL_STATUS, (value) => value !== 'dropout');

  const [errorEthnicity, setErrorEthnicity] = useState(false);
  const [errorDisability, setErrorDisability] = useState(false);
  const [errorHealthProblem, setErrorHealthProblem] = useState(false);
  const [errorPersonalProblem, setErrorPersonalProblem] = useState(false);
  const [errorScholarship, setErrorScholarship] = useState(false);
  const [errorEnrollmentStatus, setErrorEnrollmentStatus] = useState(false);
  const [noDisabilityData, setNoDisabilityData] = useState(false);
  const [noHealthProblemData, setNoHealthProblemData] = useState(false);
  const [noPersonalProblemData, setNoPersonalProblemData] = useState(false);
  const [noScholarshipData, setNoScholarshipData] = useState(false);

  const [student, setStudent] = useState({
    firstName: '',
    lastName: '',
    firstNameEN: '',
    lastNameEN: '',
    gender: '',
    birthDate: '',
    birthPlace: '',

    fatherFirstName: '',
    fatherLastName: '',
    fatherOccupation: '',
    fatherPhone: '',

    motherFirstName: '',
    motherLastName: '',
    motherOccupation: '',
    motherPhone: '',

    isFatherAsGuardian: false,
    isMotherAsGuardian: false,
    isOtherAsGuardian: false,
    guardianFirstName: '',
    guardianLastName: '',
    guardianGender: '',
    guardianOccupation: '',
    guardianPhone: '',

    province: '',
    district: '',
    commune: '',
    village: '',

    street: '',
    houseNumber: '',
    phone: '',
    remark: '',

    ethnicity: '',
    disabilities: [],
    healthProblems: [],
    personalProblems: [],
    scholarships: [],
    lateEnrollment: false,
    isTransferIn: false,
    transferInSchoolName: '',
    transferInDate: '',
    enrollmentStatus: ''
  });

  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [communeChoices, setCommuneChoices] = useState([]);
  const [villageChoices, setVillageChoices] = useState([]);
  const [studentRelation, setStudentRelation] = useState('');
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [isEditingPhoto, setIsEditingPhoto] = useState(false);

  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorGender, setErrorGender] = useState(false);
  const [errorBirthDate, setErrorBirthDate] = useState(false);
  const [errorFatherFirstName, setErrorFatherFirstName] = useState(false);
  const [errorFatherLastName, setErrorFatherLastName] = useState(false);
  const [errorMotherFirstName, setErrorMotherFirstName] = useState(false);
  const [errorMotherLastName, setErrorMotherLastName] = useState(false);
  const [errorGuardianFirstName, setErrorGuardianFirstName] = useState(false);
  const [errorGuardianLastName, setErrorGuardianLastName] = useState(false);
  const [errorGuardianGender, setErrorGuardianGender] = useState(false);
  const [errorProvince, setErrorProvince] = useState(false);
  const [errorDistrict, setErrorDistrict] = useState(false);
  const [errorCommune, setErrorCommune] = useState(false);
  const [errorVillage, setErrorVillage] = useState(false);

  // set title
  const changeBrandText = useCallback(() => {
    document.title = `${translate('student.pageEditTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('student.pageEditTitle');
  }, [translate]);

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  // fetch Student Option
  const fetchStudentOption = useCallback(
    () => {
      dispatch(getStudentOptions());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingStudentOption) {
      fetchStudentOption();
    }
    // eslint-disable-next-line
  }, [changeBrandText]);

  const fetchStudentDetail = useCallback(() => {
    if (studentId && !isLoading) {
      dispatch(getStudentDetailAsync(studentId));
    }
  }, [dispatch, studentId, isLoading]);

  useEffect(() => {
    fetchStudentDetail();
    // eslint-disable-next-line
  }, [changeBrandText]);

  useEffect(() => {
    if (!_.isEmpty(studentDetail)) {
      let father = {
        fatherFirstName: '',
        fatherLastName: '',
        fatherOccupation: '',
        fatherPhone: ''
      };
      let mother = {
        motherFirstName: '',
        motherLastName: '',
        motherOccupation: '',
        motherPhone: ''
      };
      let guardian = {
        guardianFirstName: '',
        guardianLastName: '',
        guardianGender: '',
        guardianOccupation: '',
        guardianPhone: ''
      };
      let guardianRelated;
      if (studentDetail.relationships.length > 0) {
        studentDetail.relationships.forEach(relationship => {
          if (relationship.is_father) {
            father = {
              fatherFirstName: relationship.first_name,
              fatherLastName: relationship.last_name,
              fatherOccupation: relationship.occupation || '',
              fatherPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'father' : guardianRelated;
          }

          if (relationship.is_mother) {
            mother = {
              motherFirstName: relationship.first_name,
              motherLastName: relationship.last_name,
              motherOccupation: relationship.occupation || '',
              motherPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'mother' : guardianRelated;
          }

          if (!relationship.is_father && !relationship.is_mother && relationship.is_guardian) {
            guardian = {
              guardianFirstName: relationship.first_name,
              guardianLastName: relationship.last_name,
              guardianGender: relationship.gender,
              guardianOccupation: relationship.occupation || '',
              guardianPhone: relationship.phone_number_1 || ''
            };
            guardianRelated = relationship.is_guardian ? 'other' : guardianRelated;
          }
        });
      }

      let currentStudent = {
        firstName: studentDetail.first_name,
        lastName: studentDetail.last_name,
        firstNameEN: studentDetail.first_name_en || '',
        lastNameEN: studentDetail.last_name_en || '',
        gender: studentDetail.gender,
        birthDate: studentDetail.birth_date,
        birthPlace: studentDetail.birth_place || '',

        province: studentDetail.province ? `${studentDetail.province.id},${studentDetail.province.code}` : '',
        district: studentDetail.district ? `${studentDetail.district.id},${studentDetail.district.code}` : '',
        commune: studentDetail.commune ? `${studentDetail.commune.id},${studentDetail.commune.code}` : '',
        village: studentDetail.village ? `${studentDetail.village.id},${studentDetail.village.code}` : '',

        street: studentDetail.street || '',
        houseNumber: studentDetail.house_number || '',
        phone: studentDetail.phone_number_1 || '',
        remark: studentDetail.remark || '',

        ethnicity: studentDetail.ethnicity ? studentDetail.ethnicity.id : 0,
        disabilities: studentDetail.disabilities ? studentDetail.disabilities.map(disability => disability.id) : [],
        healthProblems: studentDetail.health_problems ? studentDetail.health_problems.map(healthProblem => healthProblem.id) : [],
        personalProblems: studentDetail.personal_problems ? studentDetail.personal_problems.map(problem => problem.id) : [],

        scholarships: studentDetail.scholarships ? studentDetail.scholarships.map(scholarship => scholarship.scholarship_id) : [],
        lateEnrollment: studentDetail.late_enrollment === 1,
        isTransferIn: studentDetail.transfer_status === 1,
        transferInSchoolName: getActiveLanguage(localize).code === 'en' ? studentDetail.from_school_name_en : studentDetail.from_school_name,
        transferInDate: studentDetail.transfer_out_date,
        enrollmentStatus: studentDetail.enrollment_status
      };

      if (_.isEmpty(studentDetail.disabilities)) {
        setNoDisabilityData(true);
      }

      if (_.isEmpty(studentDetail.health_problems)) {
        setNoHealthProblemData(true);
      }

      if (_.isEmpty(studentDetail.personal_problems)) {
        setNoPersonalProblemData(true);
      }

      if (_.isEmpty(studentDetail.scholarships)) {
        setNoScholarshipData(true);
      }

      currentStudent = Object.assign(currentStudent, father, mother, guardian);

      switch (guardianRelated) {
        case 'father':
          currentStudent.isFatherAsGuardian = true;
          currentStudent.isMotherAsGuardian = false;
          currentStudent.isOtherAsGuardian = false;
          setStudentRelation('father');
          break;
        case 'mother':
          currentStudent.isFatherAsGuardian = false;
          currentStudent.isMotherAsGuardian = true;
          currentStudent.isOtherAsGuardian = false;
          setStudentRelation('mother');
          break;
        case 'other':
          currentStudent.isFatherAsGuardian = false;
          currentStudent.isMotherAsGuardian = false;
          currentStudent.isOtherAsGuardian = true;
          setStudentRelation('other');
          break;
        default:
      }

      if (_.isEmpty(studentDetail.photo)) {
        setCroppedImage(null);
        setFile(null);
      } else {
        setCroppedImage(studentDetail.photo);
        setFile(base64toFile(studentDetail.photo));
      }

      setStudent(currentStudent);
    }
    // eslint-disable-next-line
  }, [studentDetail]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    }, [dispatch]
  );

  useEffect(() => {
    fetchProvincesData();
    // eslint-disable-next-line
  }, [changeBrandText]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
  }, [provinces, localize]
  );

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (student.province !== '') {
        dispatch(getDistricts(student.province));
      }
      dispatch(clearUnits('province'));
      // eslint-disable-next-line
    }, [dispatch, student.province, translate]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
  }, [districts, localize]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(() => {
    if (student.district !== '') {
      dispatch(getCommunes(student.district));
    }
    dispatch(clearUnits('district'));
    // eslint-disable-next-line
  }, [dispatch, student.district, translate]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes));
  }, [communes, localize]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(() => {
    if (student.commune !== '') {
      dispatch(getVillages(student.commune));
    }
    dispatch(clearUnits('commune'));
    // eslint-disable-next-line
  }, [dispatch, student.commune, translate]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages));
  }, [villages, localize]
  );

  const onBrowseImage = () => {
    inputFile.current.click();
  };

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setIsEditingPhoto(true);
      e.target.value = '';
    }
  };

  const onEditImage = () => {
    if (file) {
      setFile(file);
      setIsEditingPhoto(true);
    }
  };

  const onSetCroppedImage = (image) => {
    setCroppedImage(image);
    setIsEditingPhoto(false);
  };

  const onBeforeCloseImageCropper = () => {
    setIsEditingPhoto(false);
  };

  const onDeleteImage = () => {
    setFile(null);
    setCroppedImage(null);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setStudent({ ...student, [name]: value });
  };

  const onChangeDateHandler = (value, params) => {
    const date = typeof value === 'object' ? value.format(settings.dateFormat) : value;
    setStudent({ ...student, [params]: date });
  };

  const isFieldsValid = () => {
    let isCanSave = true;
    if (_.isEmpty(student.firstName)) {
      isCanSave = false;
      setErrorFirstName(true);
    } else {
      setErrorFirstName(false);
    }

    if (_.isEmpty(student.lastName)) {
      isCanSave = false;
      setErrorLastName(true);
    } else {
      setErrorLastName(false);
    }

    if (_.isEmpty(student.gender)) {
      isCanSave = false;
      setErrorGender(true);
    } else {
      setErrorGender(false);
    }

    if (_.isEmpty(student.birthDate)) {
      isCanSave = false;
      setErrorBirthDate(true);
    } else {
      setErrorBirthDate(false);
    }

    if (student.isFatherAsGuardian || !_.isEmpty(student.fatherOccupation) || !_.isEmpty(student.fatherPhone)) {
      if (_.isEmpty(student.fatherFirstName)) {
        isCanSave = false;
        setErrorFatherFirstName(true);
      } else {
        setErrorFatherFirstName(false);
      }

      if (_.isEmpty(student.fatherLastName)) {
        isCanSave = false;
        setErrorFatherLastName(true);
      } else {
        setErrorFatherLastName(false);
      }
    } else {
      setErrorFatherFirstName(false);
      setErrorFatherLastName(false);
    }

    if (student.isMotherAsGuardian || !_.isEmpty(student.motherOccupation) || !_.isEmpty(student.motherOccupation)) {
      if (_.isEmpty(student.motherFirstName)) {
        isCanSave = false;
        setErrorMotherFirstName(true);
      } else {
        setErrorMotherFirstName(false);
      }

      if (_.isEmpty(student.motherLastName)) {
        isCanSave = false;
        setErrorMotherLastName(true);
      } else {
        setErrorMotherLastName(false);
      }
    } else {
      setErrorMotherFirstName(false);
      setErrorMotherLastName(false);
    }

    if (student.isOtherAsGuardian || !_.isEmpty(student.guardianOccupation) || !_.isEmpty(student.guardianPhone)) {
      if (_.isEmpty(student.guardianFirstName)) {
        isCanSave = false;
        setErrorGuardianFirstName(true);
      } else {
        setErrorGuardianFirstName(false);
      }

      if (_.isEmpty(student.guardianLastName)) {
        isCanSave = false;
        setErrorGuardianLastName(true);
      } else {
        setErrorGuardianLastName(false);
      }

      if (student.guardianGender === '') {
        isCanSave = false;
        setErrorGuardianGender(true);
      } else {
        setErrorGuardianGender(false);
      }
    } else {
      setErrorGuardianFirstName(false);
      setErrorGuardianLastName(false);
    }

    if (!_.isEmpty(student.province)) {
      if (_.isEmpty(student.village)) {
        isCanSave = false;
        setErrorProvince(true);
        setErrorDistrict(true);
        setErrorCommune(true);
        setErrorVillage(true);
      } else {
        setErrorProvince(false);
        setErrorDistrict(false);
        setErrorCommune(false);
        setErrorVillage(false);
      }
    } else {
      setErrorProvince(false);
      setErrorDistrict(false);
      setErrorCommune(false);
      setErrorVillage(false);
    }

    if (student.ethnicity === '') {
      isCanSave = false;
      setErrorEthnicity(true);
    } else {
      setErrorEthnicity(false);
    }

    if (_.isEmpty(student.disabilities) && noDisabilityData === false) {
      isCanSave = false;
      setErrorDisability(true);
    } else {
      setErrorDisability(false);
    }

    if (_.isEmpty(student.healthProblems) && noHealthProblemData === false) {
      isCanSave = false;
      setErrorHealthProblem(true);
    } else {
      setErrorHealthProblem(false);
    }

    if (_.isEmpty(student.personalProblems) && noPersonalProblemData === false) {
      isCanSave = false;
      setErrorPersonalProblem(true);
    } else {
      setErrorPersonalProblem(false);
    }

    if (_.isEmpty(student.scholarships) && noScholarshipData === false) {
      isCanSave = false;
      setErrorScholarship(true);
    } else {
      setErrorScholarship(false);
    }

    if (!student.enrollmentStatus && student.enrollmentStatus !== 0) {
      isCanSave = false;
      setErrorEnrollmentStatus(true);
    } else {
      setErrorEnrollmentStatus(false);
    }

    return isCanSave;
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    if (isFieldsValid()) {
      const data = {
        user: {
          first_name_en: student.firstNameEN,
          last_name_en: student.lastNameEN,
          first_name: student.firstName,
          last_name: student.lastName,
          gender: student.gender,
          gazetteer_id: student.village ? student.village.split(',')[0] : undefined,
          birth_date: student.birthDate,
          birth_place: student.birthPlace,
          street: student.street,
          house_number: student.houseNumber,
          phone_number_1: student.phone,
          remark: student.remark
        },
        options: {
          ethnicity: student.ethnicity === 0 ? '' : student.ethnicity,
          disabilities: student.disabilities,
          personal_problems: student.personalProblems,
          health_problems: student.healthProblems,
          scholarships: student.scholarships
        },
        late_enrollment: student.lateEnrollment,
        transfer_status: student.isTransferIn,
        enrollment_status: student.enrollmentStatus
      };

      if (student.fatherFirstName && student.fatherLastName) {
        data.father = {
          first_name: student.fatherFirstName,
          last_name: student.fatherLastName,
          gender: 'm',
          occupation: student.fatherOccupation,
          phone_number_1: student.fatherPhone
        };
      }

      if (student.motherFirstName && student.motherLastName) {
        data.mother = {
          first_name: student.motherFirstName,
          last_name: student.motherLastName,
          gender: 'f',
          occupation: student.motherOccupation,
          phone_number_1: student.motherPhone
        };
      }

      if (student.isFatherAsGuardian) {
        data.guardian = {
          is_father: true
        };
      }

      if (student.isMotherAsGuardian) {
        data.guardian = {
          is_mother: true
        };
      }

      if (student.isOtherAsGuardian && student.guardianFirstName && student.guardianLastName && student.guardianGender) {
        data.guardian = {
          is_other: true,
          guardian_fields: {
            first_name: student.guardianFirstName,
            last_name: student.guardianLastName,
            gender: student.guardianGender,
            occupation: student.guardianOccupation,
            phone_number_1: student.guardianPhone
          }
        };
      }

      if (croppedImage) {
        data.photo = croppedImage;
      }
      dispatch(editStudent(studentId, data, translate('enrollment.editSuccessfulMessage')));
    } else {
      dispatch(showErrorNotification(translate('common.error.form')));
    }
  };

  const handleNoDataToggle = (e, params) => {
    const value = e.target.checked;
    switch (params) {
      case 'healthProblems':
        setNoHealthProblemData(value);
        if (value === true) {
          setErrorHealthProblem(false);
          student.healthProblems = [];
        }
        break;
      case 'disabilities':
        setNoDisabilityData(e.target.checked);
        if (value === true) {
          setErrorDisability(false);
          student.disabilities = [];
        }
        break;
      case 'personalProblems':
        setNoPersonalProblemData(e.target.checked);
        if (value === true) {
          setErrorPersonalProblem(false);
          student.personalProblems = [];
        }
        break;
      case 'scholarships':
        setNoScholarshipData(e.target.checked);
        if (value === true) {
          setErrorScholarship(false);
          student.scholarships = [];
        }
        break;
      default:
    }
  };

  const ethnicityChoices = allEthnicity.map(ethnicity => ({
    label: getActiveLanguage(localize).code === 'en' ? ethnicity.name_en : ethnicity.name,
    value: ethnicity.id
  }));

  ethnicityChoices.unshift({
    label: translate('student.noEthnicity'),
    value: 0
  });

  const form = (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <StudentIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('student.edit')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={9}
                  lg={9}
                  xl={9}
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.lastName}
                        name="lastName"
                        onChange={handleInputChange}
                        error={errorLastName}
                        helperText={errorLastName ? translate('common.error.require') : ''}
                        require
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.firstName}
                        name="firstName"
                        onChange={handleInputChange}
                        error={errorFirstName}
                        helperText={errorFirstName ? translate('common.error.require') : ''}
                        require
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Typography
                        variant="h5"
                        classes={{
                          h5: classes.boxTitle
                        }}
                      >
                        {translate('student.keyboardTip')}
                      </Typography>
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        classes.boxGrid
                      }
                    >
                      <GridContainer
                        className={
                          classes.innerGridContainer
                        }
                      >
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabProfile.lastNameEN')}
                            placeholder={translate('student.tabProfile.lastName')}
                            value={student.lastNameEN}
                            name="lastNameEN"
                            onChange={handleInputChange}
                            helperText={''}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabProfile.firstNameEN')}
                            placeholder={translate('student.tabProfile.fistName')}
                            value={student.firstNameEN}
                            name="firstNameEN"
                            onChange={handleInputChange}
                            helperText={''}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <StudentSelectSex
                        require
                        value={student.gender}
                        onChangeHandler={handleInputChange}
                        errorGender={errorGender}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <DateTimePicker
                        label={translate('enrollment.birthDate')}
                        name="birthDate"
                        value={student.birthDate}
                        onChange={e => onChangeDateHandler(e, 'birthDate')}
                        timeFormat={false}
                        placeholder={translate('enrollment.placeholder.birthDate')}
                        error={errorBirthDate}
                        helperText={errorBirthDate ? translate('common.error.require') : ''}
                        require
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Input
                        label={translate('student.birthPlace')}
                        name="birthPlace"
                        value={student.birthPlace}
                        onChange={handleInputChange}
                        inputProps={{
                          multiline: true,
                          rows: 1
                        }}
                        helperText={''}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Box display="flex" justifyContent="center">
                    <Box>
                      <CardMedia
                        className={classes.photoContainer}
                        component="img"
                        alt={translate('student.studentPhoto')}
                        image={croppedImage || '/images/nobody.jpg'}
                        title={translate('student.studentPhoto')}
                      />
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={onFileChange}
                        accept="image/x-png,image/gif,image/jpeg"
                        ref={inputFile}
                      />
                      <Box display="flex" justifyContent="center">
                        <Box>
                          <IconButton
                            onClick={onEditImage}
                            disabled={!file}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={onBrowseImage}
                          >
                            <PhotoCameraIcon />
                          </IconButton>
                          <IconButton
                            onClick={onDeleteImage}
                            disabled={!file}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {isEditingPhoto &&
                      <ImageCropper
                        setCroppedImage={onSetCroppedImage}
                        onBeforeClose={onBeforeCloseImageCropper}
                        file={file}
                      />
                      }
                    </Box>
                  </Box>
                </GridItem>

                {/* Father information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.fatherInfoTitle')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.fatherLastName}
                        name="fatherLastName"
                        onChange={handleInputChange}
                        error={errorFatherLastName}
                        helperText={errorFatherLastName ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.fatherFirstName}
                        name="fatherFirstName"
                        onChange={handleInputChange}
                        error={errorFatherFirstName}
                        helperText={errorFatherFirstName ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.occupation')}
                        placeholder={translate('student.tabProfile.placeholder.occupation')}
                        value={student.fatherOccupation}
                        name="fatherOccupation"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.fatherPhone}
                        name="fatherPhone"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End father information */}

                {/* Mother information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.motherInfoTitle')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.lastName')}
                        placeholder={translate('student.tabProfile.lastName')}
                        value={student.motherLastName}
                        name="motherLastName"
                        onChange={handleInputChange}
                        error={errorMotherLastName}
                        helperText={errorMotherLastName ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.fistName')}
                        placeholder={translate('student.tabProfile.fistName')}
                        value={student.motherFirstName}
                        name="motherFirstName"
                        onChange={handleInputChange}
                        error={errorMotherFirstName}
                        helperText={errorMotherFirstName ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.occupation')}
                        placeholder={translate('student.tabProfile.placeholder.occupation')}
                        value={student.motherOccupation}
                        name="motherOccupation"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.motherPhone}
                        name="motherPhone"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End mother information */}

                {/* Guardian information */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.guardianInfoTitle')}
                  </Typography>
                </GridItem>
                <Render>
                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={studentRelation === 'father'}
                          onChange={() => {
                            setStudentRelation('father');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: true,
                              isMotherAsGuardian: false,
                              isOtherAsGuardian: false
                            });
                          }
                          }
                          value="father"
                          name="father"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.father')}
                    />
                  </div>

                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={studentRelation === 'mother'}
                          onChange={() => {
                            setStudentRelation('mother');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: false,
                              isMotherAsGuardian: true,
                              isOtherAsGuardian: false
                            });
                          }}
                          value="mother"
                          name="mother"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.mother')}
                    />
                  </div>

                  <div
                    className={
                      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={studentRelation === 'other'}
                          onChange={() => {
                            setStudentRelation('other');
                            setStudent({
                              ...student,
                              isFatherAsGuardian: false,
                              isMotherAsGuardian: false,
                              isOtherAsGuardian: true
                            });
                          }}
                          value="other"
                          name="other"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.labelRadio,
                        root: classes.labelRoot
                      }}
                      label={translate('student.guardian.other')}
                    />
                  </div>
                </Render>
                {
                  student.isOtherAsGuardian && (
                    <Render>
                      <GridItem
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={
                          classes.boxGrid
                        }
                      >
                        <GridContainer
                          className={
                            classes.innerGridContainer
                          }
                        >
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.lastName')}
                              placeholder={translate('student.tabProfile.lastName')}
                              value={student.guardianLastName}
                              name="guardianLastName"
                              onChange={handleInputChange}
                              error={errorGuardianLastName}
                              helperText={errorGuardianLastName ? translate('common.error.require') : ''}
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.fistName')}
                              placeholder={translate('student.tabProfile.fistName')}
                              value={student.guardianFirstName}
                              name="guardianFirstName"
                              onChange={handleInputChange}
                              error={errorGuardianFirstName}
                              helperText={errorGuardianFirstName ? translate('common.error.require') : ''}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Select
                              label={translate('common.gender')}
                              placeholder={translate('common.placeholder.gender')}
                              value={student.guardianGender}
                              name="guardianGender"
                              onChange={handleInputChange}
                              choices={[
                                { label: translate('common.genderType.f'), value: 'f' },
                                { label: translate('common.genderType.m'), value: 'm' }
                              ]}
                              error={errorGuardianGender}
                              helperText={errorGuardianGender ? translate('common.error.require') : ''}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.occupation')}
                              placeholder={translate('student.tabProfile.placeholder.occupation')}
                              value={student.guardianOccupation}
                              name="guardianOccupation"
                              onChange={handleInputChange}
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              label={translate('student.tabProfile.phone')}
                              placeholder={translate('student.tabProfile.placeholder.phone')}
                              value={student.guardianPhone}
                              name="guardianPhone"
                              onChange={handleInputChange}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </Render>
                  )
                }
                {/* End guardian information */}

                {/* Current address */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.tabProfile.currentAddress')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.province')}
                        placeholder={translate('gazetteer.placeholder.province')}
                        value={student.province}
                        name="province"
                        onChange={handleInputChange}
                        choices={provinceChoices}
                        error={errorProvince}
                        helperText={errorProvince ? translate('common.error.requireVillage') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.district')}
                        placeholder={translate('gazetteer.placeholder.district')}
                        value={student.district}
                        name="district"
                        onChange={handleInputChange}
                        disabled={isLoadingGazetteer || districts.length === 0}
                        choices={districtChoices}
                        error={errorDistrict}
                        helperText={errorDistrict ? translate('common.error.requireVillage') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.commune')}
                        placeholder={translate('gazetteer.placeholder.commune')}
                        value={student.commune}
                        name="commune"
                        onChange={handleInputChange}
                        disabled={isLoadingGazetteer || communes.length === 0}
                        choices={communeChoices}
                        error={errorCommune}
                        helperText={errorCommune ? translate('common.error.requireVillage') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        label={translate('gazetteer.village')}
                        placeholder={translate('gazetteer.placeholder.village')}
                        value={student.village}
                        name="village"
                        onChange={handleInputChange}
                        disabled={isLoadingGazetteer || villages.length === 0}
                        choices={villageChoices}
                        error={errorVillage}
                        helperText={errorVillage ? translate('common.error.requireVillage') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.street')}
                        placeholder={translate('student.tabProfile.placeholder.street')}
                        value={student.street}
                        name="street"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.houseNumber')}
                        placeholder={translate('student.tabProfile.placeholder.houseNumber')}
                        value={student.houseNumber}
                        name="houseNumber"
                        onChange={handleInputChange}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.phone')}
                        placeholder={translate('student.tabProfile.placeholder.phone')}
                        value={student.phone}
                        name="phone"
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('student.tabProfile.remark')}
                        value={student.remark}
                        name="remark"
                        onChange={handleInputChange}
                        inputProps={{
                          multiline: true,
                          rows: 1
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {/* End current address */}

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.boxTitle
                    }}
                  >
                    {translate('student.confidential')}
                  </Typography>
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    classes.boxGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Select
                        require
                        label={translate('enrollment.ethnicity')}
                        placeholder={translate('enrollment.placeholder.ethnicity')}
                        value={student.ethnicity}
                        name="ethnicity"
                        onChange={handleInputChange}
                        choices={ethnicityChoices}
                        error={errorEthnicity}
                        helperText={errorEthnicity ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={8}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Select
                        require
                        multiple
                        label={translate('enrollment.disability')}
                        placeholder={translate('enrollment.placeholder.disability')}
                        value={student.disabilities}
                        name="disabilities"
                        onChange={handleInputChange}
                        choices={allDisabilities.map(disability => ({
                          label: getActiveLanguage(localize).code === 'en' ? disability.name_en : disability.name,
                          value: disability.id,
                          isRenderAsParents: disability.parent_id === 0 && disability.all_children.length === 0,
                          disabled: disability.all_children.length !== 0
                        }))}
                        disabled={noDisabilityData}
                        error={errorDisability}
                        helperText={errorDisability ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={4}
                      md={2}
                      lg={2}
                      xl={2}
                    >
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={noDisabilityData}
                                onChange={e => handleNoDataToggle(e, 'disabilities')}
                                color="primary"
                              />
                            }
                            label={translate('common.no')}
                            className={classes.toggleStyle}
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>

                    <GridItem
                      xs={8}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Select
                        require
                        multiple
                        label={translate('enrollment.healthProblem')}
                        placeholder={translate('enrollment.placeholder.healthProblem')}
                        value={student.healthProblems}
                        name="healthProblems"
                        onChange={handleInputChange}
                        choices={allHealthProblems.map(healthProblem => ({
                          label: getActiveLanguage(localize).code === 'en' ? healthProblem.name_en : healthProblem.name,
                          value: healthProblem.id
                        }))}
                        disabled={noHealthProblemData}
                        error={errorHealthProblem}
                        helperText={errorHealthProblem ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={4}
                      md={2}
                      lg={2}
                      xl={2}
                    >
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={noHealthProblemData}
                                onChange={e => handleNoDataToggle(e, 'healthProblems')}
                                color="primary"
                              />
                            }
                            label={translate('common.no')}
                            className={classes.toggleStyle}
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>

                    <GridItem
                      xs={8}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Select
                        require
                        multiple
                        label={translate('enrollment.personalProblem')}
                        placeholder={translate('enrollment.placeholder.personalProblem')}
                        value={student.personalProblems}
                        name="personalProblems"
                        onChange={handleInputChange}
                        choices={allPersonalProblems.map(personalProblem => ({
                          label: getActiveLanguage(localize).code === 'en' ? personalProblem.name_en : personalProblem.name,
                          value: personalProblem.id
                        }))}
                        disabled={noPersonalProblemData}
                        error={errorPersonalProblem}
                        helperText={errorPersonalProblem ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={4}
                      md={2}
                      lg={2}
                      xl={2}
                    >
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={noPersonalProblemData}
                                onChange={e => handleNoDataToggle(e, 'personalProblems')}
                                color="primary"
                              />
                            }
                            label={translate('common.no')}
                            className={classes.toggleStyle}
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={10}
                  md={10}
                  lg={10}
                  xl={10}
                >
                  <Select
                    require
                    multiple
                    label={translate('enrollment.scholarship')}
                    placeholder={translate('enrollment.placeholder.scholarship')}
                    value={student.scholarships}
                    name="scholarships"
                    onChange={handleInputChange}
                    choices={allScholarships.map(ethnicity => ({
                      label: ethnicity.type,
                      value: ethnicity.id
                    }))}
                    disabled={noScholarshipData}
                    error={errorScholarship}
                    helperText={errorScholarship ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noScholarshipData}
                            onChange={e => handleNoDataToggle(e, 'scholarships')}
                            color="primary"
                          />
                        }
                        label={translate('common.no')}
                        className={classes.toggleStyle}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

                {/* enrollment status */}
                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Select
                    require
                    label={translate('enrollment.enrollmentStatus')}
                    placeholder={translate('enrollment.placeholder.enrollmentStatus')}
                    value={student.enrollmentStatus}
                    name="enrollmentStatus"
                    onChange={handleInputChange}
                    choices={
                      Object.keys(enrollmentStatusChoices).map(status => (
                        {
                          label: translate(`enrollment.status.${ENROLL_STATUS[status]}`),
                          value: status
                        }
                      ))
                    }
                    error={errorEnrollmentStatus}
                    helperText={errorEnrollmentStatus ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <FormControlLabel
                    control={<Checkbox
                      checked={student.lateEnrollment}
                      name="lateEnrollment"
                      onChange={() => {
                        setStudent({
                          ...student,
                          lateEnrollment: !student.lateEnrollment
                        });
                      }}
                      classes={{
                        checked: classes.radio
                      }}
                    />}
                    classes={{
                      label: classes.labelRadio
                    }}
                    label={translate('student.tabEnrollment.lateEnrollment')}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <FormControlLabel
                    control={<Checkbox
                      checked={student.isTransferIn}
                      name="isTransferIn"
                      onChange={() => {
                        setStudent({
                          ...student,
                          isTransferIn: !student.isTransferIn
                        });
                      }}
                      disabled={!!student.transferInSchoolName}
                    />}
                    classes={{
                      label: classes.labelRadio
                    }}
                    label={translate('student.tabEnrollment.transferIn')}
                  />
                </GridItem>

                {
                  student.isTransferIn && student.transferInSchoolName && (
                    <GridItem
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={
                        classes.boxGrid
                      }
                    >
                      <GridContainer
                        className={
                          classes.innerGridContainer
                        }
                      >
                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <Input
                            label={translate('student.tabEnrollment.schoolName')}
                            placeholder={translate('student.tabEnrollment.schoolName')}
                            value={student.transferInSchoolName}
                            name="transferInSchoolName"
                            disabled
                          />
                        </GridItem>

                        <GridItem
                          xs={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <DateTimePicker
                            name='transferInDate'
                            value={student.transferInDate}
                            timeFormat={false}
                            label={translate('student.tabEnrollment.transferredDate')}
                            disabled
                          />
                        </GridItem>

                      </GridContainer>
                    </GridItem>
                  )
                }
              </GridContainer>

              <div
                className={classes.submitButton}
              >
                <Button
                  className={classes.cancelButton}
                  onClick={() => history.push(ROUTES.STUDENT)}
                >
                  {translate('common.button.cancel')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleEdit}
                >
                  {translate('common.button.save')}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <div>
      {isLoading && <LoadingSpinner/>}
      {form}
    </div>
  );
};

EditStudent.propTypes = {
  match: PropTypes.instanceOf(Object)
};

export default EditStudent;
