export const status = {
  0: 'inactive',
  1: 'active',
  INACTIVE: 0,
  ACTIVE: 1
};

export const degree = {
  1: {
    code: 'pre-school',
    grade: [-3, -2, -1, 0]
  },
  2: {
    code: 'primarySchool',
    grade: [1, 2, 3, 4, 5, 6]
  },
  3: {
    code: 'lowerSecondarySchoolG7-G9',
    grade: [7, 8, 9]
  },
  4: {
    code: 'upperSecondarySchoolG10-G12Only',
    grade: [10, 11, 12]
  },
  5: {
    code: 'upperSecondarySchoolG7-G12',
    grade: [7, 8, 9, 10, 11, 12]
  },
  PRE: 1,
  PRIMARY: 2,
  LOWER_SECONDARY: 3,
  UPPER_SECONDARY_G10_12: 4,
  UPPER_SECONDARY_G7_12: 5
};

export const accomplishmentStatus = {
  1: 'completedPreparation',
  2: 'completedRunning',
  3: 'completedClosing',
  COMPLETED_PRE: 1,
  COMPLETED_RUNNING: 2,
  COMPLETED_CLOSING: 3
};
