import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import { requestCreateNewSchool } from 'redux/actions/school';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);
export default function CreateNewButton (props) {
  const {
    disabled
  } = props;
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const schoolFilter = useSelector(state => state.school.schoolFilter);
  const isSearching = useSelector((state) => state.school.isSearching);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [emisSchoolCode, setEmisSchoolCode] = useState('');
  const [errorEmisSchoolCodeText, setErrorEmisSchoolCodeText] = useState('');

  const [errorEmisSchoolCode, setErrorEmisSchoolCode] = useState(false);

  const onConfirm = () => {
    if (emisSchoolCode) {
      setErrorEmisSchoolCode(false);
      setErrorEmisSchoolCodeText('enrollment.notMatchEmisSchoolCode');
      dispatch(requestCreateNewSchool({
        emisSchoolCode,
        academicYearId: schoolFilter.academic_year
      }));
    } else {
      setErrorEmisSchoolCodeText();
      setErrorEmisSchoolCode(true);
      setErrorEmisSchoolCodeText('common.error.require');
    }
  };

  const onClose = () => {
    setIsOpenDialog(false);
    setEmisSchoolCode('');
  };

  const onCreateNew = () => {
    setIsOpenDialog(true);
  };

  return (
    <LoadablePaper
      rendered
    >
      <LoadablePaperContent>
        <Dialog
          open={isOpenDialog}
          className={classes.dialogHelp}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
        >
          <DialogTitle>
            <div className={classes.secondGrid}>
              <h6 className={classes.confirmDialogHeader}>
                {translate('school.confirmSchoolCode')}
              </h6>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <GridItem xs={12}>
              <Input
                require
                label={translate('enrollment.emisSchoolCode')}
                value={emisSchoolCode}
                name="emisSchoolCode"
                onChange={e => setEmisSchoolCode(e.target.value)}
                error={errorEmisSchoolCode}
                helperText={errorEmisSchoolCode ? translate(errorEmisSchoolCodeText) : ''}
              />
            </GridItem>
          </DialogContent>

          <DialogActions
            className={classes.dialogAction}
          >
            <div className={classes.normalSaveClose}>
              <ThemeButton
                variant="contained"
                className={classes.buttonDefault}
                onClick={onClose}
              >
                {translate('common.button.close')}
              </ThemeButton>
              <ThemeButton
                color="primary"
                variant="contained"
                onClick={onConfirm}
                disabled={isSearching}
              >
                {translate('common.button.confirm')}
              </ThemeButton>
            </div>
          </DialogActions>
        </Dialog>
        <ThemeButton
          color="primary"
          className={classes.allButton}
          onClick={onCreateNew}
          disabled={disabled}
        >
          {translate('common.button.new')}
        </ThemeButton>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

CreateNewButton.propTypes = {
  disabled: PropTypes.bool
};
