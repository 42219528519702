import {
  dangerColor,
  warningColor,
  whiteColor

} from 'assets/theme/jss/material-dashboard-pro-react';

const importDetailStyle = () => ({
  errorCell: {
    backgroundColor: dangerColor[4],
    color: whiteColor
  },
  errorRow: {
    backgroundColor: warningColor[3]
  },
  errorDuplicateInFileColor: {
    backgroundColor: warningColor[5]
  },
  errorFormat: {
    backgroundColor: dangerColor[3],
    color: whiteColor
  }
});

export default importDetailStyle;
