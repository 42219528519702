import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import ClassroomIcon from '@material-ui/icons/Ballot';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import View from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Table from 'components/common/Table/Table';
import * as ROUTES from 'variables/routeNames';

import styles from 'assets/sts/jss/views/classroom/classroomStyle';
import Select from 'components/common/Select/Select';
import * as VARIABLES from 'variables/classroom';
import * as ACADEMICYEARSTATUS from 'variables/academicYearStatus';
import {
  getAcademicYears,
  resetAcademicYear
} from 'redux/actions/academicYear';
import {
  createEditClassroom,
  getClassroomList,
  deleteClassroom,
  resetClassroom
} from 'redux/actions/classroom';
import { getSchool, getSchoolList, resetSchool } from 'redux/actions/school';
import * as moment from 'moment';
import settings from 'settings';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import * as helpers from 'utilities/helpers';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  clearUnits,
  getCommunes, getDistricts,
  getProvinces, getVillages,
  showErrorNotification
} from 'redux/actions';
import queryString from 'query-string';
import { roles as ROLES, groups as GROUPS } from 'variables/user';
import { useKeycloak } from '@react-keycloak/web';
import { getSchoolName } from 'utilities/helpers';
import { allChoices, getGazetteerChoices } from '../../utilities/gazetteer';
import {
  districtOfficerGroup,
  provincialOfficerGroup
} from '../../utilities/permission';
import Autocomplete
  from '../../components/common/AutocompleteInput/AutocompleteInput';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(styles);
const ALL = 'all';

const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const Classroom = (props) => {
  const { location, history } = props;
  const academicYearId = queryString.parse(location.search).academicYear;
  const schoolId = queryString.parse(location.search).school;

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('grade');
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const isLoadingSchool = useSelector(state => state.school.isLoading);
  const isLoading = useSelector(state => state.classroom.isLoading);
  const schoolList = useSelector(state => state.school.schoolList);
  const classroomList = useSelector(state => state.classroom.classroomList);
  const classroomFilter = useSelector(state => state.classroom.classroomFilter);
  const authProfile = useSelector(state => state.auth.profile);
  const currentSchool = useSelector((state) => state.school.school);
  const [keycloak] = useKeycloak();

  const [province, setProvince] = useState(classroomFilter?.province || ALL);
  const [district, setDistrict] = useState(classroomFilter?.district || ALL);
  const [commune, setCommune] = useState(classroomFilter?.commune || ALL);
  const [village, setVillage] = useState(classroomFilter?.village || ALL);
  const [provinceChoices, setProvinceChoices] = useState(allChoices(translate));
  const [districtChoices, setDistrictChoices] = useState(allChoices(translate));
  const [communeChoices, setCommuneChoices] = useState(allChoices(translate));
  const [villageChoices, setVillageChoices] = useState(allChoices(translate));
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [academicYear, setAcademicYear] = useState(academicYearId ? parseInt(academicYearId) : classroomFilter?.academicYear || '');
  const [school, setSchool] = useState(schoolId ? parseInt(schoolId) : classroomFilter?.school || '');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [gazetteerId, setGazetteerId] = useState('');
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [isSearching, setIsSearching] = useState(false);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [target, setTarget] = useState({});
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const disabledProvinceSelection = isProvincialOfficer || isDistrictOfficer;
  const disabledDistrictSelection = isDistrictOfficer;

  // set title
  useEffect(() => {
    document.title = `${translate('classroom.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('classroom.pageTitle');
  }, [translate]);

  useEffect(() => {
    if (classroomFilter && classroomFilter.school) {
      const schoolObj = schoolList.find(s => s.id === school);
      if (schoolObj) {
        setSelectedSchool({
          value: schoolObj.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, schoolObj)
        });
      }
    }
  }, [classroomFilter, schoolList, localize, school, translate]);

  // fetch provinces
  useEffect(() => {
    dispatch(clearUnits());
    dispatch(getProvinces());
  }, [dispatch]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces, translate));
  }, [provinces, localize, translate]);

  // fetch districts after province is selected
  useEffect(() => {
    dispatch(clearUnits());
    if (province !== ALL) {
      dispatch(getDistricts(province));
    }
  }, [dispatch, province]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
  }, [districts, localize, translate]);

  // fetch communes after district is selected
  useEffect(() => {
    dispatch(clearUnits('district'));
    if (district !== ALL) {
      dispatch(getCommunes(district));
    }
  }, [dispatch, district]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes, translate));
  }, [communes, localize, translate]);

  // fetch villages after commune is selected
  useEffect(() => {
    dispatch(clearUnits('commune'));
    if (commune !== ALL) {
      dispatch(getVillages(commune));
    }
  }, [dispatch, commune]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages, translate));
  }, [villages, localize, translate]);

  const fetchData = useCallback(
    () => {
      if (schoolId) {
        dispatch(getSchool(schoolId));
      } else {
        dispatch(resetSchool());
      }
    },
    [dispatch, schoolId]
  );

  useEffect(
    () => {
      if (!isLoadingSchool) {
        fetchData();
      }
    },
    // eslint-disable-next-line
      [fetchData]);

  useEffect(() => {
    if (authProfile && currentSchool && schoolId) {
      if ((authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER) && authProfile.school.emis_code !== currentSchool.emis_code) {
        history.push(ROUTES.HOME);
      }

      if (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        if (authProfile.province.code !== currentSchool.province.code) {
          history.push(ROUTES.HOME);
        }
      }

      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER) {
        if (authProfile.district.code !== currentSchool.district.code) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [schoolId, currentSchool, authProfile, history]);

  // set default school
  useEffect(() => {
    const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
    if (isManagementOrTeacher || (schoolId && academicYearId) || (classroomFilter && classroomFilter.school && classroomFilter.academicYear)) {
      setIsSearching(true);
    } else {
      if (authProfile && authProfile.groups[0] !== GROUPS.SCHOOL_MANAGEMENT && authProfile.groups[0] === GROUPS.TEACHER) {
        dispatch(resetSchool());
        dispatch(resetClassroom());
        dispatch(resetAcademicYear());
      }
    }
    // eslint-disable-next-line
  }, [dispatch, schoolId, academicYearId, authProfile]);

  // set default gazetteer selection
  useEffect(() => {
    if (authProfile && !managementOrTeacher) {
      if (authProfile.province && province === ALL) {
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }
      if (authProfile.district && (district && district === ALL)) {
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
    }
  }, [authProfile, province, district, managementOrTeacher]);

  // fetch Academic Years
  useEffect(() => {
    dispatch(getAcademicYears());
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      let defaultAcademicYearId = academicYears[0].id;
      let isCheckId = false;
      const choice = academicYears.map(academic => {
        const academicStartDate = moment(academic.start_date,
          settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        if (academic.status === ACADEMICYEARSTATUS.RUNNING && !isCheckId) {
          defaultAcademicYearId = academic.id;
          isCheckId = true;
        }
        return {
          value: academic.id,
          label: studyYear
        };
      });
      setAcademicYearChoices(choice);
      if (!academicYearId && !classroomFilter) {
        setAcademicYear(defaultAcademicYearId);
      }
    }
    // eslint-disable-next-line
  }, [academicYears, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      let gazetteer = province && province !== 'all' ? province.split(',')[1] : '';
      if (village && village !== 'all') {
        gazetteer = village.split(',')[1];
      } else if (commune && commune !== 'all') {
        gazetteer = commune.split(',')[1];
      } else if (district && district !== 'all') {
        gazetteer = district.split(',')[1];
      }

      const params = isManagementOrTeacher
        ? {
          academic_year_id: academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }

      if (gazetteer) {
        params.gazetteer = gazetteer;
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, academicYear, province, district, commune, village]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      if (isManagementOrTeacher) {
        setSchool(schoolList[0].id);
        setSelectedSchool({
          value: schoolList[0].id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, schoolList[0])
        });
      }
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, authProfile, translate]);

  // get classroom list
  // eslint-disable-next-line
  const setFetchClassroomListParams = () => {
    const schoolObj = schoolList.find(s => s.id === school);
    setGazetteerId(schoolObj.gazetteer_id);
    if (schoolObj) {
      return {
        academicYear,
        school,
        province,
        district,
        commune,
        village,
        page: page + 1,
        limit,
        order,
        orderBy
      };
    }
    return false;
  };

  const fetchClassroomList = useCallback(() => {
    if (isSearching && school && schoolList.length > 0) {
      const params = setFetchClassroomListParams();
      if (params) {
        dispatch(getClassroomList(params));
      }
      setIsSearching(false);
    }
  }, [dispatch, setFetchClassroomListParams, school, isSearching, schoolList]);

  useEffect(() => {
    if (!isLoading && !isLoadingSchool && academicYear) {
      fetchClassroomList();
    }
    // eslint-disable-next-line
  }, [fetchClassroomList, page, limit]);

  useEffect(() => {
    setCount(classroomList.total);
  }, [classroomList]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'province':
        setProvince(value);
        setDistrict(ALL);
        setCommune(ALL);
        setVillage(ALL);
        setSelectedSchool('');
        setSchool('');
        break;
      case 'district':
        setDistrict(value);
        setCommune(ALL);
        setVillage(ALL);
        setSelectedSchool('');
        setSchool('');
        break;
      case 'commune':
        setCommune(value);
        setVillage(ALL);
        setSelectedSchool('');
        setSchool('');
        break;
      case 'village':
        setVillage(value);
        setSelectedSchool('');
        setSchool('');
        break;
      case 'academicYear':
        setAcademicYear(value);
        setSelectedSchool('');
        setSchool('');
        break;
      default:
    }
  };

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setSelectedSchool(school);
      setSchool(school.value);
    } else {
      setSelectedSchool('');
      setSchool('');
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    setIsSearching(true);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setIsSearching(true);
  };

  const labelClassroom = classroom => (
    `${getActiveLanguage(localize).code === 'en' ? classroom.grade_name_en : classroom.grade_name} (${classroom.suffix})`
  );

  const status = (status) => {
    return (
      <Tooltip
        title={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        key={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        className={classes.tooltip}
      >
        {parseInt(status) ? (
          <CheckCircleIcon
            className={cx({
              [classes.successButton]: true,
              [classes.icon]: true
            })}
          />
        ) : (
          <RemoveCircleIcon
            className={cx({
              [classes.inactive]: true,
              [classes.icon]: true
            })}
          />
        )}
      </Tooltip>
    );
  };

  const handleChangePage = (e, page) => {
    setPage(page);
    setIsSearching(true);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
    setIsSearching(true);
  };

  const onHandleConfirm = () => {
    if (target.type === 'deactivate') {
      const data = {
        status: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['0']),
        deactivated: true,
        params: setFetchClassroomListParams()
      };
      dispatch(createEditClassroom(data, target.id, translate('classroom.editSuccessfulMessage')));
    } else if (target.type === 'delete') {
      dispatch(deleteClassroom(target.id, setFetchClassroomListParams(), translate('classroom.deleteSuccessfulMessage')));
    }
    setTarget({});
    setIsConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setIsConfirmBoxOpen(false);
    setTarget({});
  };

  return (
    <>
      {
        isConfirmBoxOpen && (
          <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
            title={translate('common.modal.confirmTitle')}
            onConfirm={() => onHandleConfirm()}
            onCancel={() => onHandelCancel()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={translate('common.modal.yes')}
            cancelBtnText={translate('common.modal.no')}
            showCancel
          >
            {translate('common.modal.describe')}
          </SweetAlert>
        )
      }

      <Card className={classes.cardFilter}>
        <CardHeader color="gold" icon>
          <CardIcon color="gold">
            <ClassroomIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{translate('classroom.pageTitle')}</h4>
        </CardHeader>
        <CardBody>
          <form
            className={classes.gridContainerStyle}
          >
            <GridContainer>
              {
                !managementOrTeacher && (
                  <>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.province')}
                        placeholder={translate('gazetteer.placeholder.province')}
                        value={province}
                        name="province"
                        onChange={e => onChangeHandler(e, 'province')}
                        choices={provinceChoices}
                        disabled={provinces.length === 0 || disabledProvinceSelection}
                        helperText=""
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.district')}
                        placeholder={translate('gazetteer.placeholder.district')}
                        value={district}
                        name="district"
                        onChange={e => onChangeHandler(e, 'district')}
                        choices={districtChoices}
                        disabled={districts.length === 0 || disabledDistrictSelection}
                        helperText=""
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.commune')}
                        placeholder={translate('gazetteer.placeholder.commune')}
                        value={commune}
                        name="commune"
                        onChange={e => onChangeHandler(e, 'commune')}
                        choices={communeChoices}
                        disabled={communes.length === 0}
                        helperText=""
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.village')}
                        placeholder={translate('gazetteer.placeholder.village')}
                        value={village}
                        name="village"
                        onChange={e => onChangeHandler(e, 'village')}
                        choices={villageChoices}
                        disabled={villages.length === 0}
                        helperText=""
                      />
                    </GridItem>
                  </>
                )
              }
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('school.academicYear')}
                  placeholder={translate('school.placeholder.academicYear')}
                  value={academicYear}
                  name="academicYear"
                  onChange={e => onChangeHandler(e, 'academicYear')}
                  choices={academicYearChoices}
                  disabled={academicYearChoices.length === 0}
                />
              </GridItem>
              <GridItem
                xs={12}
                md={6}
              >
                <Autocomplete
                  label={translate('classroom.school')}
                  placeholder={translate('classroom.placeholder.school')}
                  value={selectedSchool}
                  name="school"
                  onChange={onSchoolCodeChangeHandler}
                  options={schoolChoices}
                  filterOptions={schoolFilterOptions}
                  noOptionsText={translate('report.noSchoolChoice')}
                  disabled={schoolChoices.length === 0 || managementOrTeacher}
                  freeSolo={managementOrTeacher}
                  disableClearable={managementOrTeacher}
                />
              </GridItem>
            </GridContainer>

            <div
              className={classes.allButton}
            >
              <ThemeButton
                color="primary"
                type="submit"
                onClick={handleSearch}
              >
                {translate('common.button.search')}
              </ThemeButton>
            </div>
          </form>
        </CardBody>
      </Card>

      <LoadablePaper
        rendered
        loading={isLoading}
      >
        <LoadablePaperContent>
          <Card>
            <CardBody>
              <Table
                size='small'
                tableHead={[
                  { id: 'grade', isNeedSort: true, label: translate('classroom.classroom') },
                  { id: 'teacher', isNeedSort: true, label: translate('classroom.teacher') },
                  { id: 'student', isNeedSort: false, label: translate('classroom.student') },
                  { id: 'score', isNeedSort: false, label: translate('classroom.score') },
                  { id: 'attendance', isNeedSort: false, label: translate('classroom.attendance') },
                  { id: 'status', isNeedSort: false, label: translate('classroom.classroomStatus') },
                  { id: 'action', isNeedSort: false, label: translate('common.action') }
                ]}
                tableData={
                  classroomList.map(classroom => {
                    const teacher = classroom.teacher;
                    const teacherName = teacher ? `${teacher.last_name} ${teacher.first_name}` : '';
                    const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
                    const isSuperDataUser = authProfile && authProfile.groups[0] === GROUPS.SUPER_DATA_USER;
                    const editButton = !isTeacher && !isSuperDataUser && (
                      <Tooltip
                        title={translate('common.button.edit')}
                        key={`edit-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <div>
                          <IconButton
                            color='primary'
                            aria-label='edit'
                            className={classes.tableIconButton}
                            component={Link}
                            to={`${ROUTES.CLASSROOM_EDIT_ROOT}/${classroom.id}`}
                          >
                            <Edit className={classes.icon} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    );
                    const deleteButton = authProfile.groups[0] !== GROUPS.TEACHER && (
                      <Tooltip
                        title={translate('common.button.delete')}
                        key={`delete-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <div>
                          <IconButton
                            color='secondary'
                            aria-label='delete'
                            className={classes.tableIconButton}
                            onClick={() => {
                              if (classroom.isCanDelete) {
                                setTarget({
                                  id: classroom.id,
                                  type: 'delete',
                                  isCanDelete: classroom.isCanDelete
                                });
                                setIsConfirmBoxOpen(true);
                              } else {
                                dispatch(showErrorNotification(
                                  translate('classroom.cannotDelete')));
                              }
                            }}
                            disabled={!classroom.isCanDelete}
                          >
                            <Delete className={classes.icon} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    );

                    const viewButton = authProfile.groups[0] === GROUPS.TEACHER && (
                      <Tooltip
                        title={translate('common.button.view')}
                        key={`view-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <div>
                          <IconButton
                            color='primary'
                            aria-label={translate('common.button.view')}
                            className={classes.tableIconButton}
                            component={Link}
                            to={`${ROUTES.CLASSROOM_VIEW_ROOT}/${classroom.id}`}
                          >
                            <View className={classes.icon} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    );
                    const studentLink = (
                      <Tooltip
                        title={translate('student.title')}
                        key={`view-student-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <IconButton
                          color='primary'
                          aria-label={translate('student.title')}
                          className={cx({
                            [classes.tableIconButton]: true,
                            [classes.openInNewButton]: true
                          })}
                          component={Link}
                          to={`${ROUTES.STUDENT}?academicYear=${academicYear}&school=${school}&grade=${classroom.grade}&suffix=${classroom.suffix}&gazetteerId=${gazetteerId}`}
                          disabled={isTeacher ? teacher && authProfile ? teacher.id !== authProfile.id : true : false}
                        >
                          <OpenInNewIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    );

                    const scoreLink = (
                      <Tooltip
                        title={translate('score.title')}
                        key={`view-score-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <div>
                          <IconButton
                            color='primary'
                            aria-label={translate('score.title')}
                            className={cx({
                              [classes.tableIconButton]: true,
                              [classes.openInNewButton]: true
                            })}
                            component={Link}
                            to={`${ROUTES.SCORE_MANAGEMENT}?academicYear=${academicYear}&school=${school}&grade=${classroom.grade}&suffix=${classroom.suffix}`}
                            disabled={isTeacher ? teacher && authProfile ? teacher.id !== authProfile.id : true : false}
                          >
                            <OpenInNewIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    );

                    const attendanceLink = (
                      <Tooltip
                        title={translate('attendance.title')}
                        key={`view-attendance-${classroom.id}`}
                        className={classes.tooltip}
                      >
                        <div>
                          <IconButton
                            color='primary'
                            aria-label={translate('attendance.title')}
                            className={cx({
                              [classes.tableIconButton]: true,
                              [classes.openInNewButton]: true
                            })}
                            component={Link}
                            to={`${ROUTES.ATTENDANCE_MANAGEMENT}?academicYear=${academicYear}&school=${school}&grade=${classroom.grade}&suffix=${classroom.suffix}`}
                            disabled={isTeacher ? teacher && authProfile ? teacher.id !== authProfile.id : true : false}
                          >
                            <OpenInNewIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    );
                    return [
                      labelClassroom(classroom),
                      teacherName,
                      studentLink,
                      scoreLink,
                      attendanceLink,
                      status(classroom.status),
                      [editButton, deleteButton, viewButton]
                    ];
                  })
                }
                customCellClasses={['center', 'center', 'center', 'center']}
                customClassesForCells={[3, 4, 5, 6]}
                customHeadCellClasses={['center', 'center', 'center', 'center']}
                customHeadClassesForCells={[3, 4, 5, 6]}
                onRequestSort={handleRequestSort}
                count={count}
                limit={limit}
                page={page}
                order={order}
                orderBy={orderBy}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </LoadablePaperContent>
      </LoadablePaper>
      {
        (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM) || keycloak.hasRealmRole(ROLES.MANAGE_OWNED_CLASSROOM)) && (
          <Link to={`${ROUTES.CLASSROOM_SCHOOL_ROOT}/${school}/new`}>
            <ThemeButton
              color="primary"
              className={classes.allButton}
              disabled={!school || !academicYear}
            >
              {translate('common.button.new')}
            </ThemeButton>
          </Link>
        )
      }
    </>
  );
};

Classroom.propTypes = {
  history: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object)
};

export default Classroom;
