export const CHANGE_FILTER_REQUEST = 'CHANGE_FILTER_REQUEST';
export const CHANGE_FILTER_SUCCESS = 'CHANGE_FILTER_SUCCESS';

export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL';

export const GET_DATA_QUERY_SCHOOL_FILTER_OPTION_REQUEST = 'GET_DATA_QUERY_SCHOOL_FILTER_OPTION_REQUEST';
export const GET_DATA_QUERY_SCHOOL_FILTER_OPTION_SUCCESS = 'GET_DATA_QUERY_SCHOOL_FILTER_OPTION_SUCCESS';
export const GET_DATA_QUERY_SCHOOL_FILTER_OPTION_FAIL = 'GET_DATA_QUERY_SCHOOL_FILTER_OPTION_FAIL';

export const DOWNLOAD_STATICAL_REPORT_REQUEST = 'DOWNLOAD_STATICAL_REPORT_REQUEST';
export const DOWNLOAD_STATICAL_REPORT_SUCCESS = 'DOWNLOAD_STATICAL_REPORT_SUCCESS';
export const DOWNLOAD_STATICAL_REPORT_FAIL = 'DOWNLOAD_STATICAL_REPORT_FAIL';
