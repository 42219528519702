import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ClassroomIcon from '@material-ui/icons/Ballot';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

import styles from 'assets/sts/jss/views/classroom/createEditClassroomStyle';
import Select from 'components/common/Select/Select';
import PropTypes from 'prop-types';
import { getClassroom } from 'redux/actions/classroom';
import { getAcademicYear } from 'redux/actions/academicYear';

import * as VARIABLES from 'variables/classroom';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import * as moment from 'moment';
import settings from 'settings';
import Input from 'components/common/Input/Input';
import {
  getSchool,
  getStaffs,
  getStudentOptions
} from 'redux/actions';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import * as ROUTES from 'variables/routeNames';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';

const useStyles = makeStyles(styles);

const ViewClassroom = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoading = useSelector(state => state.classroom.isLoading);
  const classroomId = props.match.params.classroomId;
  const schoolId = props.match.params.schoolId;
  const school = useSelector((state) => state.school.school);
  const classroom = useSelector((state) => state.classroom.classroom);
  const academicYearDetail = useSelector(state => state.academicYear.academicYearDetail);
  const teachers = useSelector((state) => state.staff.staffs);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [teacherChoices, setTeacherChoices] = useState([]);

  const allGrades = useSelector(state => state.studentOption.grade);
  const [academicYear, setAcademicYear] = useState('');
  const [grade, setGrade] = useState('');
  const [suffix, setSuffix] = useState('');
  const [classroomTeacher, setClassroomTeacher] = useState('');
  const [bilingualClass, setBilingualClass] = useState(1);
  const [specialEducation, setSpecialEducation] = useState('');
  const [type, setType] = useState('');

  const [status, setStatus] = useState(1);
  const enableSpecialEducation = Boolean(grade !== '' && grade <= 0);
  const enableType = Boolean(grade !== '' && grade >= 11);

  // set title
  useEffect(() => {
    document.title = `${translate('classroom.view')} - STS`;
    document.getElementById('brandText').innerHTML = translate('classroom.view');
  }, [translate]);

  // fetch School
  const fetchSchool = useCallback(() => {
    if (schoolId) {
      dispatch(getSchool(schoolId));
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    fetchSchool();
  }, [fetchSchool]);

  useEffect(() => {
    if (authProfile && school) {
      if (authProfile.school.emis_code !== school.emis_code) {
        history.push(ROUTES.HOME);
      }
    }
  }, [schoolId, school, authProfile, history]);

  useEffect(() => {
    if (school) {
      setAcademicYear(school.academic_year_id);

      // set school choices
      setSchoolChoices([{
        value: school.id,
        label: getSchoolName(getActiveLanguage(localize).code, translate, school)
      }]);
    }
    // eslint-disable-next-line
  }, [school, localize]);

  useEffect(() => {
    if (classroomId && classroom) {
      setGrade(classroom.grade);
      setSuffix(classroom.suffix);
      setStatus(classroom.status);
      setSpecialEducation(classroom.special_education);
      setBilingualClass(classroom.bilingual);
      setType(classroom.type);
      setClassroomTeacher(classroom.teacher ? classroom.teacher.staff_id : '');
    }
  }, [classroom, classroomId]);

  const fetchData = useCallback(() => {
    if (classroomId) {
      dispatch(getClassroom(classroomId));
    }
  }, [dispatch, classroomId]);

  const fetchAcademicYearSchool = useCallback(() => {
    if (classroom) {
      dispatch(getSchool(classroom.academic_year_school_id));
    }
  }, [dispatch, classroom]);

  useEffect(() => {
    fetchAcademicYearSchool();
  }, [fetchAcademicYearSchool]);

  // fetch Academic Year
  const fetchAcademicYear = useCallback(() => {
    if (school) {
      dispatch(getAcademicYear(school.academic_year_id));
    }
  }, [dispatch, school]);

  useEffect(() => {
    fetchAcademicYear();
  }, [fetchAcademicYear]);

  // fetch Student Option
  const fetchStudentOption = useCallback(() => {
    dispatch(getStudentOptions());
  }, [dispatch]);

  useEffect(() => {
    fetchStudentOption();
  }, [fetchStudentOption]);

  // set school choices
  useEffect(() => {
    let choice;
    if (academicYearDetail) {
      const academicStartDate = moment(academicYearDetail.start_date, settings.dateFormat);
      const academicEndDate = moment(academicYearDetail.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${academicYearDetail.status}`).toUpperCase();
      const studyYear = (academicYearDetail.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
      choice = [{
        value: academicYearDetail.id,
        label: studyYear
      }];
    }
    setAcademicYearChoices(choice);
  }, [academicYearDetail, translate]);

  // set grade choices
  useEffect(() => {
    if (school && allGrades.length > 0) {
      const choices = [];
      const degreeGrades = SCHOOL_DEGREE[school.degree.number].grade;
      degreeGrades.forEach(gradeNumber => {
        const grade = allGrades.find(g => g.number === gradeNumber);
        choices.push({
          value: gradeNumber,
          label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
        });
      });
      setGradeChoices(choices);
    }
  }, [school, allGrades, localize]);

  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [fetchData]);

  const fetchStaffs = useCallback(
    () => {
      dispatch(getStaffs(GROUPS.TEACHER, schoolId));
    },
    [dispatch, schoolId]
  );

  useEffect(
    () => {
      fetchStaffs();
    },
    // eslint-disable-next-line
      [fetchStaffs]);

  useEffect(
    () => {
      setTeacherChoices(teachers.map(teacher => {
        return {
          label: teacher.user ? `${teacher.user.last_name} ${teacher.user.first_name}` : '',
          value: teacher.id
        };
      }));
    }
    // eslint-disable-next-line
      , [teachers]);

  const form = (
    <Card>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <ClassroomIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate(classroomId ? 'classroom.edit' : 'classroom.new')}</h4>
      </CardHeader>
      <CardBody>
        <form
          className={classes.gridContainerStyle}
        >
          <GridContainer>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                disabled
                label={translate('school.academicYear')}
                placeholder={translate('school.placeholder.academicYear')}
                value={academicYear || ''}
                name="academicYear"
                choices={academicYearChoices}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                disabled
                label={translate('classroom.school')}
                placeholder={translate('classroom.placeholder.school')}
                value={school ? school.id : ''}
                name="school"
                choices={schoolChoices}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <GridContainer>
                <GridItem
                  xs={6}
                >
                  <Select
                    disabled
                    label={translate('classroom.grade')}
                    placeholder={translate('classroom.placeholder.grade')}
                    value={grade || ''}
                    name="grade"
                    choices={gradeChoices}
                  />
                </GridItem>
                <GridItem
                  xs={6}
                >
                  <Input
                    disabled
                    label={translate('common.suffix')}
                    placeholder={translate('classroom.placeholder.suffix')}
                    value={suffix || ''}
                    name="schoolName"
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('classroom.teacher')}
                placeholder={translate('classroom.placeholder.teacher')}
                value={classroomTeacher || ''}
                name="teacher"
                choices={teacherChoices}
                disabled
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('common.status')}
                placeholder={translate('common.placeholder.status')}
                value={status || ''}
                name="status"
                choices={
                  [
                    { label: translate(`school.schoolStatus.${VARIABLES.status['1']}`), value: 1 },
                    { label: translate(`school.schoolStatus.${VARIABLES.status['0']}`), value: 0 }
                  ]
                }
                disabled
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('classroom.bilingualClass')}
                placeholder={translate('classroom.placeholder.bilingualClass')}
                value={bilingualClass || ''}
                name="bilingualClass"
                choices={
                  [
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['1']}`), value: 1 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['2']}`), value: 2 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['3']}`), value: 3 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['4']}`), value: 4 }
                  ]
                }
                disabled
              />
            </GridItem>
            {enableSpecialEducation && (
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('classroom.specialEducation')}
                  placeholder={translate('classroom.placeholder.specialEducation')}
                  value={specialEducation || ''}
                  name="specialEducation"
                  choices={
                    [
                      { label: translate(`classroom.specialEducationFlag.${VARIABLES.specialEducationFlag['1']}`), value: 1 },
                      { label: translate(`classroom.specialEducationFlag.${VARIABLES.specialEducationFlag['0']}`), value: 0 }
                    ]
                  }
                  disabled
                />
              </GridItem>
            )}
            {enableType && (
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('classroom.classType')}
                  placeholder={translate('classroom.placeholder.classType')}
                  value={type || ''}
                  name="type"
                  choices={
                    [
                      { label: translate(`classroom.classTypeChoice.${VARIABLES.classType['1']}`), value: 1 },
                      { label: translate(`classroom.classTypeChoice.${VARIABLES.classType['2']}`), value: 2 }
                    ]
                  }
                  disabled
                />
              </GridItem>
            )}
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : form}
    </div>
  );
};

ViewClassroom.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default ViewClassroom;
