import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import settings from 'settings';
import styles from 'assets/sts/jss/views/transfer/transferStyle';
import TransferFilter from 'views/Transfer/Partial/TransferFilter';
import TransferTable from 'views/Transfer/Partial/TransferTable';
import TransferDialog from 'views/Transfer/Partial/TransferDialog';
import ReviewDialog from 'views/Transfer/Partial/ReviewDialog';
import {
  approveTransfer,
  getTransferList,
  getTransferReview,
  getTransferStudent, getTransferToSchoolList,
  resetTransferStudent,
  submitTransferStudent
} from 'redux/actions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import * as VARIABLES from '../../variables/transfer';

const useStyles = makeStyles(styles);

const Transfer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const schoolList = useSelector(state => state.school.schoolList);
  const transferList = useSelector(state => state.transfer.transferList);
  const transferFilter = useSelector(state => state.transfer.transferFilter);
  const transferStudent = useSelector(state => state.transfer.student);
  const transferReview = useSelector(state => state.transfer.transfer);
  const isLoadingTransferList = useSelector(state => state.transfer.isLoading);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isReviewOpenDialog, setIsReviewOpenDialog] = useState(false);

  const [school, setSchool] = useState(transferFilter?.school || '');
  const [academicYear, setAcademicYear] = useState(transferFilter?.academicYear || '');
  const [grade, setGrade] = useState(transferFilter?.grade !== undefined ? transferFilter.grade : '');
  const [suffix, setSuffix] = useState(transferFilter?.suffix || '');
  const [transfer, setTransfer] = useState(transferFilter?.transfer || 0);

  const [errorSchool, setErrorSchool] = useState(false);
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);

  const [stsId, setStsId] = useState('');
  const [confirmStsId, setConfirmStsId] = useState('');
  const [reason, setReason] = useState('');
  const [transferAcademicYear, setTransferAcademicYear] = useState('');
  const [transferSchool, setTransferSchool] = useState('');
  const [transferSchoolCode, setTransferSchoolCode] = useState('');
  const [transferGrade, setTransferGrade] = useState('');
  const [transferSuffix, setTransferSuffix] = useState('');

  const [errorStsId, setErrorStsId] = useState(false);
  const [errorConfirmStsId, setErrorConfirmStsId] = useState(false);
  const [errorReason, setErrorReason] = useState(false);
  const [errorTransferAcademicYear, setErrorTransferAcademicYear] = useState(false);
  const [errorTransferSchool, setErrorTransferSchool] = useState(false);
  const [errorTransferSchoolCode, setErrorTransferSchoolCode] = useState(false);
  const [errorTransferGrade, setErrorTransferGrade] = useState(false);
  const [errorTransferSuffix, setErrorTransferSuffix] = useState(false);

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [errorProvince, setErrorProvince] = useState(false);
  const [errorDistrict, setErrorDistrict] = useState(false);
  const [selectedTransferSchool, setSelectedTransferSchool] = useState('');
  const [isShowGrade, setIsShowGrade] = useState(false);

  // set title
  useEffect(() => {
    document.title = `${translate('transfer.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('transfer.pageTitle');
  }, [translate]);

  // fetch transfer to schools by academic year
  useEffect(() => {
    if (district) {
      const gazetteer = district.split(',')[1];
      dispatch(getTransferToSchoolList({
        academic_year: transferAcademicYear,
        gazetteer: gazetteer,
        orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
        order: 'ASC',
        limit: 9999
      }));
    }
    // eslint-disable-next-line
  }, [dispatch, district]);

  useEffect(() => {
    if (transferList.data && transferList.data.length > 0) {
      const showRows = transferList.data.map(
        (student) => ({
          id: student.id,
          stsId: student.student.emis_student_id,
          name: `${student.student.last_name} ${student.student.first_name}`,
          gender: student.student.gender,
          birthDate: student.student.birth_date,
          fromClassroom: student.from_classroom,
          classroom: student.classroom,
          transferDate: student.transfer_out_date,
          transferFromSchool: student.transfer_from_school,
          transferToSchool: student.transfer_to_school,
          status: student.status
        })
      );
      setCount(transferList && transferList.total ? transferList.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [transferList]);

  useEffect(() => {
    if (!isLoadingTransferList) {
      if (school) {
        const params = {
          school,
          emisSchoolCode: transferFilter?.emisSchoolCode || '',
          academicYear,
          grade,
          suffix,
          transfer
        };
        params.page = page + 1;
        params.limit = limit;
        dispatch(getTransferList(params));
      }
    }
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    if (transferReview) {
      setTransferGrade(transferReview.classroom.grade);
    }
  }, [transferReview]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'grade':
        setGrade(value);
        setSuffix('');
        break;
      case 'suffix':
        setSuffix(value);
        break;
      case 'transfer':
        setTransfer(value);
        break;
      case 'school':
        setSchool(value);
        setGrade('all');
        setSuffix('');
        break;
      case 'stsId':
        setStsId(value);
        break;
      case 'confirmStsId':
        setConfirmStsId(value);
        break;
      case 'reason':
        setReason(value);
        break;
      case 'transferAcademicYear':
        setTransferAcademicYear(value);
        break;
      case 'transferGrade':
        setTransferGrade(value);
        break;
      case 'transferSuffix':
        setTransferSuffix(value);
        break;
      case 'province':
        setProvince(value);
        setDistrict('');
        setTransferSchool('');
        setSelectedTransferSchool('');
        setIsShowGrade(false);
        break;
      case 'district':
        setDistrict(value);
        setTransferSchool('');
        setSelectedTransferSchool('');
        setIsShowGrade(false);
        break;
      default:
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    let canFilter = true;
    setPage(settings.startPage);

    let schoolObj;
    if (!school) {
      canFilter = false;
      setErrorSchool(true);
    } else {
      schoolObj = schoolList.find(s => s.id === school);
      if (schoolObj) {
        setErrorSchool(false);
      } else {
        canFilter = false;
        setErrorSchool(true);
        setSchool('');
        setGrade('all');
        setSuffix('');
      }
    }

    if (!academicYear) {
      canFilter = false;
      setErrorAcademicYear(true);
    } else {
      setErrorAcademicYear(false);
    }

    if (grade === '') {
      canFilter = false;
      setErrorGrade(true);
    } else {
      setErrorGrade(false);
    }
    if (canFilter) {
      const data = {
        school,
        emisSchoolCode: schoolObj.emis_code,
        academicYear,
        grade,
        suffix,
        transfer,
        page: settings.startPage,
        limit
      };
      dispatch(getTransferList(data));
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const onReviewRowHandler = (rowId) => {
    setTransferSuffix('');
    dispatch(getTransferReview(rowId));

    setIsReviewOpenDialog(true);
  };

  const onHandleClose = () => {
    setIsOpenDialog(false);
    setIsReviewOpenDialog(false);
  };

  const handleNewTransferClick = e => {
    e.preventDefault();

    setStsId('');
    setConfirmStsId('');
    setReason('');
    setTransferAcademicYear(academicYear);
    setTransferSchool('');
    setTransferSchoolCode('');
    setErrorStsId(false);
    setErrorConfirmStsId(false);
    setErrorTransferSchool(false);
    setErrorTransferSchoolCode(false);
    setErrorTransferGrade(false);
    setIsShowGrade(false);
    setSelectedTransferSchool('');
    setProvince('');
    setDistrict('');
    setTransferGrade('');
    dispatch(resetTransferStudent());

    setIsOpenDialog(true);
  };

  const handleConfirm = e => {
    e.preventDefault();

    let isValid = true;

    const trimStsId = stsId.trim();
    const trimConfirmStsId = confirmStsId.trim();
    setStsId(trimStsId);
    setConfirmStsId(trimConfirmStsId);

    if (!trimStsId) {
      isValid = false;
      setErrorStsId(true);
    } else {
      setErrorStsId(false);
    }

    if (!trimConfirmStsId || trimStsId !== trimConfirmStsId) {
      isValid = false;
      setErrorConfirmStsId(true);
    } else {
      setErrorConfirmStsId(false);
    }

    if (isValid) {
      dispatch(getTransferStudent(school, trimStsId, translate('transfer.studentNotFound')));
    }
  };

  const handleTransfer = e => {
    e.preventDefault();

    let canTransfer = true;

    if (!reason) {
      canTransfer = false;
      setErrorReason(true);
    } else {
      setErrorReason(false);
    }

    if (!transferAcademicYear) {
      canTransfer = false;
      setErrorTransferAcademicYear(true);
    } else {
      setErrorTransferAcademicYear(false);
    }

    if (!transferSchool || transferSchool === school) {
      canTransfer = false;
      setErrorTransferSchool(true);
    } else {
      setErrorTransferSchool(false);
    }

    if (!province) {
      canTransfer = false;
      setErrorProvince(true);
    } else {
      setErrorProvince(false);
    }

    if (!district) {
      canTransfer = false;
      setErrorDistrict(true);
    } else {
      setErrorDistrict(false);
    }

    if (!transferGrade && transferGrade !== 0 &&
      transferSchool !== VARIABLES.unknownSchools.PRIVATE_SCHOOL &&
      transferSchool !== VARIABLES.unknownSchools.NONE_STS_SCHOOL
    ) {
      canTransfer = false;
      setErrorTransferGrade(true);
    } else {
      setErrorTransferGrade(false);
    }

    if (canTransfer) {
      const params = {
        enrollmentId: transferStudent.id,
        transferSchoolCode,
        reason,
        transferSchool,
        transferGrade
      };
      dispatch(submitTransferStudent(params, translate('transfer.transferSuccess')));
      setIsOpenDialog(false);
    }
  };

  const handleApproveTransfer = e => {
    e.preventDefault();

    let canApprove = true;

    if (!transferSuffix) {
      canApprove = false;
      setErrorTransferSuffix(true);
    } else {
      setErrorTransferSuffix(false);
    }

    if (!transferGrade) {
      canApprove = false;
      setErrorTransferGrade(true);
    } else {
      setErrorTransferGrade(false);
    }

    if (canApprove) {
      const params = {
        academic_year_school_id: transferReview.transfer_to_school.id,
        grade: transferReview.classroom.grade,
        suffix: transferSuffix,
        enrollments: [transferReview.id]
      };
      dispatch(approveTransfer(params, translate('transfer.approveSuccess')));
      setIsReviewOpenDialog(false);
    }
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <TransferFilter
            classes={classes}
            academicYear={academicYear}
            school={school}
            setSchool={setSchool}
            grade={grade}
            suffix={suffix}
            transfer={transfer}
            errorSchool={errorSchool}
            errorAcademicYear={errorAcademicYear}
            errorGrade={errorGrade}
            onChangeHandler={onChangeHandler}
            handleFilter={handleFilter}
          />

          {isOpenDialog &&
            <TransferDialog
              academicYear={academicYear}
              school={school}
              province={province}
              district={district}
              selectedTransferSchool={selectedTransferSchool}
              setSelectedTransferSchool={setSelectedTransferSchool}
              setTransferSchool={setTransferSchool}
              setTransferSchoolCode={setTransferSchoolCode}
              setTransferGrade={setTransferGrade}
              isShowGrade={isShowGrade}
              setIsShowGrade={setIsShowGrade}
              isOpenDialog={isOpenDialog}
              stsId={stsId}
              confirmStsId={confirmStsId}
              transferStudent={transferStudent}
              reason={reason}
              transferAcademicYear={transferAcademicYear}
              transferSchool={transferSchool}
              transferSchoolCode={transferSchoolCode}
              transferGrade={transferGrade}
              errorStsId={errorStsId}
              errorConfirmStsId={errorConfirmStsId}
              errorReason={errorReason}
              errorTransferAcademicYear={errorTransferAcademicYear}
              errorTransferSchool={errorTransferSchool}
              errorTransferSchoolCode={errorTransferSchoolCode}
              errorProvince={errorProvince}
              errorDistrict={errorDistrict}
              errorTransferGrade={errorTransferGrade}
              dialogTitle={translate('student.transfer')}
              onChangeHandler={onChangeHandler}
              onHandleClose={onHandleClose}
              onHandleConfirm={handleConfirm}
              onHandleSave={handleTransfer}
            />
          }

          { isReviewOpenDialog &&
            <ReviewDialog
              academicYear={academicYear}
              isOpenDialog={isReviewOpenDialog}
              dialogTitle={translate('student.transfer')}
              transferStudent={transferReview}
              transferGrade={transferGrade}
              errorTransferGrade={errorTransferGrade}
              transferSuffix={transferSuffix}
              errorTransferSuffix={errorTransferSuffix}
              onChangeHandler={onChangeHandler}
              onHandleClose={onHandleClose}
              onHandleSave={handleApproveTransfer}
            />
          }

          <TransferTable
            count={count}
            rows={rows}
            limit={limit}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onReviewRowHandler={onReviewRowHandler}
          />
        </GridItem>
      </GridContainer>

      <div className={classes.actionContainer}>
        <ThemeButton
          color="primary"
          type="submit"
          onClick={handleNewTransferClick}
          disabled={!school}
        >
          {translate('transfer.newTransfer')}
        </ThemeButton>
      </div>
    </>

  );
};

export default Transfer;
