export const GET_GRADUATION_LIST_REQUEST = 'GET_GRADUATION_LIST_REQUEST';
export const GET_GRADUATION_LIST_SUCCESS = 'GET_GRADUATION_LIST_SUCCESS';
export const GET_GRADUATION_LIST_BY_STATUS_REQUEST = 'GET_GRADUATION_LIST_BY_STATUS_REQUEST';
export const GET_GRADUATION_LIST_BY_STATUS_SUCCESS = 'GET_GRADUATION_LIST_BY_STATUS_SUCCESS';
export const GET_GRADUATION_LIST_FAIL = 'GET_GRADUATION_LIST_FAIL';

export const CHANGE_RESULT_REQUEST = 'CHANGE_RESULT_REQUEST';
export const CHANGE_RESULT_SUCCESS = 'CHANGE_RESULT_SUCCESS';
export const CHANGE_RESULT_FAIL = 'CHANGE_RESULT_FAIL';

export const PROMOTE_GRADUATION_REQUEST = 'PROMOTE_GRADUATION_REQUEST';
export const PROMOTE_GRADUATION_SUCCESS = 'PROMOTE_GRADUATION_SUCCESS';
export const PROMOTE_GRADUATION_FAIL = 'PROMOTE_GRADUATION_FAIL';

export const GET_PROMOTE_TO_SCHOOL_LIST_REQUEST = 'GET_PROMOTE_TO_SCHOOL_LIST_REQUEST';
export const GET_PROMOTE_TO_SCHOOL_LIST_SUCCESS = 'GET_PROMOTE_TO_SCHOOL_LIST_SUCCESS';
export const GET_PROMOTE_TO_SCHOOL_LIST_FAIL = 'GET_PROMOTE_TO_SCHOOL_LIST_FAIL';
