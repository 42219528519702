import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ClassroomIcon from '@material-ui/icons/Ballot';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

import styles from 'assets/sts/jss/views/classroom/createEditClassroomStyle';
import Select from 'components/common/Select/Select';
import PropTypes from 'prop-types';
import { createEditClassroom, getClassroom, getClassroomSubjects } from 'redux/actions/classroom';
import { getAcademicYear } from 'redux/actions/academicYear';

import * as VARIABLES from 'variables/classroom';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import * as moment from 'moment';
import settings from 'settings';
import Input from 'components/common/Input/Input';
import {
  getSchool,
  getStaffs,
  getStudentOptions,
  showErrorNotification
} from 'redux/actions';
import { degree as SCHOOL_DEGREE } from 'variables/school';
import * as ROUTES from 'variables/routeNames';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';
import {
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import _ from 'lodash';

const useStyles = makeStyles(styles);

const CreateEditClassroom = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoading = useSelector(state => state.classroom.isLoading);
  const classroomId = props.match.params.classroomId;
  const schoolId = props.match.params.schoolId;
  const school = useSelector((state) => state.school.school);
  const classroom = useSelector((state) => state.classroom.classroom);
  const academicYearDetail = useSelector(state => state.academicYear.academicYearDetail);
  const teachers = useSelector((state) => state.staff.staffs);
  const authProfile = useSelector(state => state.auth.profile);
  const defaultSubjects = useSelector(state => state.classroom.subjects);
  const classroomFilter = useSelector(state => state.classroom.classroomFilter);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [teacherChoices, setTeacherChoices] = useState([]);

  const allGrades = useSelector(state => state.studentOption.grade);
  const [academicYear, setAcademicYear] = useState('');
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [grade, setGrade] = useState('');
  const [errorGrade, setErrorGrade] = useState(false);
  const [suffix, setSuffix] = useState(null);
  const [errorSuffix, setErrorSuffix] = useState(false);
  const [classroomTeacher, setClassroomTeacher] = useState('');
  const [bilingualClass, setBilingualClass] = useState(1);
  const [errorBilingualClass, setErrorBilingualClass] = useState(false);
  const [specialEducation, setSpecialEducation] = useState('');
  const [errorSpecialEducation, setErrorSpecialEducation] = useState(false);
  const [type, setType] = useState(0);
  const [errorType, setErrorType] = useState(false);

  const [status, setStatus] = useState(1);
  const enableSpecialEducation = Boolean(grade !== '' && grade <= 0);
  const enableType = Boolean(grade !== '' && grade >= 11);
  const [columns, setColumns] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selection, setSelection] = useState([]);
  const [excludedSubjects, setExcludedSubjects] = useState([]);

  // set title
  useEffect(() => {
    document.title = `${translate(classroomId ? 'classroom.edit' : 'classroom.new')} - STS`;
    document.getElementById('brandText').innerHTML = translate('classroom.pageTitle');
  }, [translate, classroomId]);

  // fetch School
  const fetchSchool = useCallback(() => {
    if (schoolId) {
      dispatch(getSchool(schoolId));
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    fetchSchool();
  }, [fetchSchool]);

  useEffect(() => {
    if (authProfile && school) {
      if (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT && authProfile.school.emis_code !== school.emis_code) {
        history.push(ROUTES.HOME);
      }

      if (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        if (authProfile.province.code !== school.province.code) {
          history.push(ROUTES.HOME);
        }
      }

      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER) {
        if (authProfile.district.code !== school.district.code) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [schoolId, school, authProfile, history]);

  useEffect(() => {
    if (school) {
      setAcademicYear(school.academic_year_id);
      // set school choices
      setSchoolChoices([{
        value: school.id,
        label: getSchoolName(getActiveLanguage(localize).code, translate, school)
      }]);
    }
  }, [school, localize, translate]);

  useEffect(() => {
    if (classroomId && classroom) {
      setGrade(classroom.grade);
      setSuffix(classroom.suffix);
      setStatus(classroom.status);
      setSpecialEducation(classroom.special_education);
      setBilingualClass(classroom.bilingual);
      setType(classroom.type || 0);
      setClassroomTeacher(classroom.teacher ? classroom.teacher.staff_id : '');
      setExcludedSubjects(classroom.excluded_subjects);
    }
  }, [classroom, classroomId]);

  useEffect(() => {
    setColumns([
      { name: 'name', title: translate('classroom.subject') }
    ]);
    let classroomSubjects = [];
    if (classroomId && classroom && classroom.grade === grade && classroom.subjects) {
      classroomSubjects = classroom.subjects;
      setExcludedSubjects(classroom.excluded_subjects);
    } else {
      classroomSubjects = _.cloneDeep(defaultSubjects);
      setExcludedSubjects([]);
    }

    const flattenSubjects = [];
    classroomSubjects.forEach(subject => {
      if (subject.academic_year_subjects.length) {
        subject.academic_year_subjects.forEach(academicYearSubject => {
          const exactSubject = academicYearSubject.id !== undefined ? academicYearSubject : academicYearSubject[type];
          flattenSubjects.push({
            id: exactSubject.id,
            name: getActiveLanguage(localize).code === 'en'
              ? exactSubject.name_en
              : exactSubject.name
          });
        });
      }
    });
    setSubjects(flattenSubjects);
    // eslint-disable-next-line
  }, [classroom, classroomId, translate, grade, type, defaultSubjects]);

  const fetchData = useCallback(() => {
    if (classroomId) {
      dispatch(getClassroom(classroomId));
    }
  }, [dispatch, classroomId]);

  const fetchAcademicYearSchool = useCallback(() => {
    if (classroom) {
      dispatch(getSchool(classroom.academic_year_school_id));
    }
  }, [dispatch, classroom]);

  useEffect(() => {
    fetchAcademicYearSchool();
  }, [fetchAcademicYearSchool]);

  // fetch Academic Year
  const fetchAcademicYear = useCallback(() => {
    if (school) {
      dispatch(getAcademicYear(school.academic_year_id));
    }
  }, [dispatch, school]);

  useEffect(() => {
    fetchAcademicYear();
  }, [fetchAcademicYear]);

  // fetch Student Option
  const fetchStudentOption = useCallback(() => {
    dispatch(getStudentOptions());
  }, [dispatch]);

  useEffect(() => {
    fetchStudentOption();
  }, [fetchStudentOption]);

  // set school choices
  useEffect(() => {
    let choice;
    if (academicYearDetail) {
      const academicStartDate = moment(academicYearDetail.start_date, settings.dateFormat);
      const academicEndDate = moment(academicYearDetail.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${academicYearDetail.status}`).toUpperCase();
      const studyYear = (academicYearDetail.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
      choice = [{
        value: academicYearDetail.id,
        label: studyYear
      }];
    }
    setAcademicYearChoices(choice);
  }, [academicYearDetail, translate]);

  // set grade choices
  useEffect(() => {
    if (school && allGrades.length > 0) {
      const choices = [];
      const degreeGrades = SCHOOL_DEGREE[school.degree.number].grade;
      degreeGrades.forEach(gradeNumber => {
        const grade = allGrades.find(g => g.number === gradeNumber);
        choices.push({
          value: gradeNumber,
          label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
        });
      });
      setGradeChoices(choices);
    }
  }, [school, allGrades, localize]);

  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [fetchData]);

  useEffect(() => {
    // eslint-disable-next-line
    let teacherSchoolId = schoolId || classroom?.academic_year_school_id;
    if (teacherSchoolId !== undefined) {
      dispatch(getStaffs(GROUPS.TEACHER, teacherSchoolId));
    }
  }, [dispatch, schoolId, classroom]);

  useEffect(
    () => {
      setTeacherChoices(teachers.map(teacher => {
        return {
          label: teacher.user ? `${teacher.user.last_name} ${teacher.user.first_name}` : '',
          value: teacher.id
        };
      }));
    }
    // eslint-disable-next-line
      , [teachers]);

  useEffect(() => {
    if (grade !== '' && academicYear) {
      const params = {
        grade: grade,
        academic_year_id: academicYear
      };
      dispatch(getClassroomSubjects(params));
    }
    // eslint-disable-next-line
  }, [grade, academicYear]);

  const handleCreateEdit = (e) => {
    e.preventDefault();
    let isCanCreate = true;

    if (!academicYear) {
      setErrorAcademicYear(true);
      isCanCreate = false;
    } else {
      setErrorAcademicYear(false);
    }

    if (grade === '') {
      setErrorGrade(true);
      isCanCreate = false;
    } else {
      setErrorGrade(false);
    }

    if (!suffix) {
      setErrorSuffix(true);
      isCanCreate = false;
    } else {
      setErrorSuffix(false);
    }

    if (bilingualClass === '') {
      setErrorBilingualClass(true);
      isCanCreate = false;
    } else {
      setErrorBilingualClass(false);
    }

    if (enableSpecialEducation && specialEducation === '') {
      setErrorSpecialEducation(true);
      isCanCreate = false;
    } else {
      setErrorSpecialEducation(false);
    }

    if (enableType && (!type || type === '')) {
      setErrorType(true);
      isCanCreate = false;
    } else {
      setErrorType(false);
    }

    if (isCanCreate) {
      const includedSubjects = [];
      subjects.forEach(subject => {
        if (!excludedSubjects.includes(subject.id)) {
          includedSubjects.push(subject.id);
        }
      });
      const exactSubjects = getExactSubjects(defaultSubjects, type, enableType);
      const data = {
        status,
        suffix,
        type: enableType ? type : 0,
        grade,
        academic_year: academicYear,
        bilingual: bilingualClass,
        classroom_teacher: classroomTeacher !== '' ? classroomTeacher : undefined,
        subjects: exactSubjects,
        excluded_subjects: excludedSubjects,
        included_subjects: includedSubjects,
        province: classroomFilter.province,
        district: classroomFilter.district,
        commune: classroomFilter.commune,
        village: classroomFilter.village

      };

      if (school) {
        data.school = school.school_id;
      }

      if (specialEducation) {
        data.special_education = specialEducation;
      }

      dispatch(createEditClassroom(data, classroomId || false, !classroomId ? translate('classroom.createSuccessfulMessage') : translate('classroom.editSuccessfulMessage')));
    } else {
      dispatch(showErrorNotification(translate('common.error.form')));
    }
  };

  const getExactSubjects = (defaultSubjects, type, enableType) => {
    if (enableType) {
      const exactSubjects = [];
      const subjects = _.cloneDeep(defaultSubjects);
      subjects.forEach(subject => {
        if (subject.academic_year_subjects.length) {
          const academicYearSubjects = [];
          subject.academic_year_subjects.forEach(academicYearSubject => {
            const exactSubject = academicYearSubject.id !== undefined ? academicYearSubject : academicYearSubject[type];
            academicYearSubjects.push(exactSubject);
          });
          subject.academic_year_subjects = academicYearSubjects;
          exactSubjects.push(subject);
        }
      });

      return exactSubjects;
    }

    return defaultSubjects;
  };

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'grade': {
        setGrade(value);
        setBilingualClass(value > 3 ? 2 : 1);
        break;
      }
      case 'suffix':
        setSuffix(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'specialEducation':
        setSpecialEducation(value);
        break;
      case 'bilingualClass':
        setBilingualClass(value);
        break;
      case 'type':
        setType(value);
        break;
      case 'classroomTeacher':
        setClassroomTeacher(value);
        break;
      default:
    }
  };

  useEffect(() => {
    const includedSubjectRows = [];
    subjects.forEach((subject, index) => {
      if (!excludedSubjects.includes(subject.id)) {
        includedSubjectRows.push(index);
      }
    });
    setSelection(includedSubjectRows);
    // eslint-disable-next-line
  }, [excludedSubjects]);

  const onSubjectSelectChange = (selectedRows) => {
    const unselectedSubjects = _.cloneDeep(subjects);
    _.pullAt(unselectedSubjects, selectedRows);
    setExcludedSubjects(unselectedSubjects.map(subject => subject.id));
  };

  const form = (
    <Card>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <ClassroomIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate(classroomId ? 'classroom.edit' : 'classroom.new')}</h4>
      </CardHeader>
      <CardBody>
        <form
          className={classes.gridContainerStyle}
        >
          <GridContainer>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                disabled
                label={translate('school.academicYear')}
                placeholder={translate('school.placeholder.academicYear')}
                value={academicYear}
                name="academicYear"
                onChange={e => onChangeHandler(e, 'academicYear')}
                choices={academicYearChoices}
                require
                error={errorAcademicYear}
                helperText={errorAcademicYear ? translate('common.error.require') : ''}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                disabled
                label={translate('classroom.school')}
                placeholder={translate('classroom.placeholder.school')}
                value={school ? school.id : ''}
                name="school"
                onChange={e => onChangeHandler(e, 'school')}
                require
                choices={schoolChoices}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <GridContainer>
                <GridItem
                  xs={6}
                >
                  <Select
                    label={translate('classroom.grade')}
                    placeholder={translate('classroom.placeholder.grade')}
                    value={grade}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={gradeChoices}
                    disabled={gradeChoices.length === 0}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>
                <GridItem
                  xs={6}
                >
                  <Input
                    require
                    label={translate('common.suffix')}
                    placeholder={translate('classroom.placeholder.suffix')}
                    value={suffix || ''}
                    name="schoolName"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    error={errorSuffix}
                    helperText={errorSuffix ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('classroom.teacher')}
                placeholder={translate('classroom.placeholder.teacher')}
                value={classroomTeacher}
                name="teacher"
                choices={teacherChoices}
                onChange={e => onChangeHandler(e, 'classroomTeacher')}
                helperText=""
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('common.status')}
                placeholder={translate('common.placeholder.status')}
                value={status}
                name="status"
                onChange={e => onChangeHandler(e, 'status')}
                choices={
                  [
                    { label: translate(`school.schoolStatus.${VARIABLES.status['1']}`), value: 1 },
                    { label: translate(`school.schoolStatus.${VARIABLES.status['0']}`), value: 0 }
                  ]
                }
                require
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
            >
              <Select
                label={translate('classroom.bilingualClass')}
                placeholder={translate('classroom.placeholder.bilingualClass')}
                value={bilingualClass}
                name="bilingualClass"
                onChange={e => onChangeHandler(e, 'bilingualClass')}
                choices={
                  [
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['1']}`), value: 1 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['2']}`), value: 2 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['3']}`), value: 3 },
                    { label: translate(`classroom.bilingualClassChoice.${VARIABLES.bilingualClass['4']}`), value: 4 }
                  ]
                }
                require
                error={errorBilingualClass}
                helperText={errorBilingualClass ? translate('common.error.require') : ''}
              />
            </GridItem>
            {enableSpecialEducation && (
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('classroom.specialEducation')}
                  placeholder={translate('classroom.placeholder.specialEducation')}
                  value={specialEducation}
                  name="specialEducation"
                  onChange={e => onChangeHandler(e, 'specialEducation')}
                  choices={
                    [
                      { label: translate(`classroom.specialEducationFlag.${VARIABLES.specialEducationFlag['1']}`), value: 1 },
                      { label: translate(`classroom.specialEducationFlag.${VARIABLES.specialEducationFlag['0']}`), value: 0 }
                    ]
                  }
                  require
                  error={errorSpecialEducation}
                  helperText={errorSpecialEducation ? translate('common.error.require') : ''}
                />
              </GridItem>
            )}
            {enableType && (
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('classroom.classType')}
                  placeholder={translate('classroom.placeholder.classType')}
                  value={type}
                  name="type"
                  onChange={e => onChangeHandler(e, 'type')}
                  choices={
                    [
                      { label: translate(`classroom.classTypeChoice.${VARIABLES.classType['1']}`), value: 1 },
                      { label: translate(`classroom.classTypeChoice.${VARIABLES.classType['2']}`), value: 2 }
                    ]
                  }
                  require
                  error={errorType}
                  helperText={errorType ? translate('common.error.require') : ''}
                />
              </GridItem>
            )}
          </GridContainer>

          <div>
            <span>
              {translate('classroom.totalSelectedSubjects', { numberOfSubject: selection.length })}
            </span>
            <Grid
              rows={subjects}
              columns={columns}
            >
              <SelectionState
                selection={selection}
                onSelectionChange={onSubjectSelectChange}
              />
              <IntegratedSelection />
              <VirtualTable />
              <TableHeaderRow />
              <TableSelection selectByRowClick showSelectAll />
            </Grid>
          </div>

          <div
            className={classes.submitButton}
          >
            <Button
              className={classes.cancelButton}
              onClick={() => history.push(ROUTES.CLASSROOM)}
            >
              {translate('common.button.cancel')}
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleCreateEdit}
            >
              {classroomId ? translate('common.button.save') : translate('common.button.create')}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : form}
    </div>
  );
};

CreateEditClassroom.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default CreateEditClassroom;
