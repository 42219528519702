import {
  warningColor

} from 'assets/theme/jss/material-dashboard-pro-react';

const importDetailStyle = () => ({
  errorDuplicate: {
    backgroundColor: warningColor[3]
  },
  errorRow: {
    backgroundColor: warningColor[3]
  }
});

export default importDetailStyle;
