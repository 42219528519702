import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import EnrollIcon from '@material-ui/icons/LibraryBooksOutlined';
import CardBody from 'components/theme/Card/CardBody';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingSpinner
  from 'components/common/LoadingSpinner/LoadingSpinner';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import { makeStyles } from '@material-ui/core';
import { REPORT_STAGES } from 'variables/report';
import { getSchoolList } from 'redux/actions';
import { degree as DEGREE } from 'variables/school';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';

const useStyles = makeStyles(dialogInputStyle);

export default function StartEnrollInput (props) {
  const {
    classes,
    isLoadingAcademic,
    isLoadingStudentOption,
    academicYearChoices,
    allGrades,
    academicYears,
    academicYear,
    selectedSchool,
    emisSchoolCode,
    grade,
    onChangeHandler,
    errorAcademicYear,
    errorSelectedSchool,
    errorEmisSchoolCode,
    errorEmisSchoolCodeText,
    errorGrade,
    handleStartEnrollment,
    isOpenDialog,
    onHandleClose,
    school,
    setSchool,
    isLoading,
    onHandleConfirm,
    isDisableInput,
    handleReset,
    reportStage,
    errorReportStage,
    enrollmentDate,
    onChangeDateHandler
  } = props;

  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const dialogStyle = useStyles();
  const schoolList = useSelector(state => state.school.schoolList);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const isLoadingSchoolList = useSelector(state => state.school.isLoading);
  const authProfile = useSelector(state => state.auth.profile);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);

  useEffect(() => {
    if (academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      const params = isManagementOrTeacher
        ? {
          academic_year_id: academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, academicYear]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      if (isManagementOrTeacher) {
        setSchool(schoolList[0].id);
      }
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, authProfile, setSchool, translate]);

  const getSchoolChoices = schoolId => {
    if (schoolList.length === 0) {
      return [];
    }

    const schoolObj = schoolList.find(s => s.id === parseInt(schoolId));
    if (schoolObj) {
      if (allGrades.length > 0) {
        const schoolDegree = schoolObj.degree.number;
        const degreeMatchGrade = DEGREE[schoolDegree].grade;
        return allGrades
          .sort((a, b) => a.number - b.number)
          .filter(g => degreeMatchGrade.includes(g.number))
          .map(grade => ({
            label: getActiveLanguage(localize).code === 'en'
              ? grade.name_en
              : grade.name,
            value: grade.number
          }));
      }
      return [];
    } else {
      return [];
    }
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic || isLoadingStudentOption || isLoadingSchoolList}
    >
      <LoadablePaperContent>
        <Dialog
          open={isOpenDialog}
          className={dialogStyle.dialogHelp}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
        >
          {!isLoading &&
              (
                <DialogTitle>
                  <div>
                    <div className={dialogStyle.secondGrid}>
                      <h6 className={dialogStyle.confirmDialogHeader}>
                        {school ? translate('enrollment.sureToEnroll') : translate('enrollment.searchNoResult')}
                      </h6>
                    </div>
                  </div>
                </DialogTitle>
              )

          }
          <DialogContent className={isLoading ? dialogStyle.dialogContentLoading : dialogStyle.dialogContent}>
            {
              isLoading
                ? <LoadingSpinner
                  classes={dialogStyle.loading}
                  loadingBoxClass={dialogStyle.loadingBoxClass}
                />
                : school ? (<GridContainer>

                  <GridItem xs={12}>
                    <Input
                      require
                      label={translate('enrollment.emisSchoolCode')}
                      value={emisSchoolCode}
                      name="emisSchoolCode"
                      onChange={e => onChangeHandler(e, 'emisSchoolCode')}
                      error={errorEmisSchoolCode}
                      helperText={errorEmisSchoolCode ? translate(errorEmisSchoolCodeText) : ''}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Input
                      label={translate('common.name')}
                      value={school?.name ? school.name : ''} name="name"
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Input
                      label={translate('school.name_en')}
                      value={school ? school.name_en || '' : ''}
                      name="nameEn"
                      disabled
                    />
                  </GridItem>

                </GridContainer>) : school === 0
                  ? (
                    <p className={classes.schoolNotFound}>
                      {translate('enrollment.noMatchGrade')}
                    </p>
                  )
                  : (
                    <p className={classes.schoolNotFound}>
                      {translate('enrollment.schoolNotFound')}
                    </p>
                  )
            }
          </DialogContent>
          {
            !isLoading && (
              <DialogActions
                className={dialogStyle.dialogAction}
              >
                <div className={dialogStyle.normalSaveClose}>
                  <ThemeButton
                    onClick={onHandleClose}
                    variant="contained"
                    className={dialogStyle.buttonDefault}
                  >
                    {translate('common.button.close')}
                  </ThemeButton>

                  {
                    school
                      ? (
                        <ThemeButton
                          onClick={onHandleConfirm}
                          color="primary"
                          variant="contained"
                        >
                          {translate('common.button.confirm')}
                        </ThemeButton>
                      ) : ''
                  }
                </div>
              </DialogActions>
            )
          }
        </Dialog>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <EnrollIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('enrollment.title')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="academicYear"
                    onChange={e => onChangeHandler(e, 'academicYear')}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0 || isDisableInput}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Select
                    label={translate('enrollment.school')}
                    placeholder={translate('enrollment.placeholder.school')}
                    value={selectedSchool}
                    name="selectedSchool"
                    onChange={e => onChangeHandler(e, 'selectedSchool')}
                    choices={schoolChoices}
                    require
                    error={errorSelectedSchool}
                    helperText={errorSelectedSchool ? translate('common.error.require') : ''}
                    disabled={isDisableInput || managementOrTeacher}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={grade}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={getSchoolChoices(selectedSchool)}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                    disabled={isDisableInput}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Select
                    label={translate('enrollment.reportStage')}
                    placeholder={translate('enrollment.placeholder.reportStage')}
                    value={reportStage}
                    name="reportStage"
                    onChange={e => onChangeHandler(e, 'reportStage')}
                    choices={[
                      { label: translate(`report.reportStage.${REPORT_STAGES['1'].code}`), value: 1 },
                      { label: translate(`report.reportStage.${REPORT_STAGES['2'].code}`), value: 2 }
                    ]}
                    require
                    error={errorReportStage}
                    helperText={errorReportStage ? translate('common.error.require') : ''}
                    disabled={isDisableInput}
                  />
                </GridItem>

              </GridContainer>

              <GridContainer>

                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <GridContainer>
                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <DateTimePicker
                        disabled
                        name='enrollmentDate'
                        value={enrollmentDate}
                        onChange={(e) => onChangeDateHandler(e, 'enrollmentDate')}
                        timeFormat={false}
                        label={translate('enrollment.enrollmentDate')}
                        placeholder={translate('enrollment.placeholder.enrollmentDate')}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Input
                        label={translate('enrollment.enrolledBy')}
                        value={authProfile ? authProfile.last_name && authProfile.first_name ? `${authProfile.last_name} ${authProfile.first_name}` : authProfile.username : ''}
                        name="stsId"
                        disabled
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.startEnrollButton}
                >
                  <div>
                    <ThemeButton
                      className={classes.cancelButton}
                      onClick={handleReset}
                    >
                      {translate('common.button.reset')}
                    </ThemeButton>

                    <ThemeButton
                      color="primary"
                      type="submit"
                      onClick={handleStartEnrollment}
                      disabled={isDisableInput}
                    >
                      {translate('enrollment.startEnrollment')}
                    </ThemeButton>
                  </div>
                </GridItem>

              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
}

StartEnrollInput.propTypes = {
  classes: PropTypes.instanceOf(Object),
  isLoadingAcademic: PropTypes.bool,
  isLoadingStudentOption: PropTypes.bool,
  academicYearChoices: PropTypes.array,
  allGrades: PropTypes.array,
  academicYears: PropTypes.array,
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  emisSchoolCode: PropTypes.string,
  selectedSchool: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]
  ),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChangeHandler: PropTypes.func,
  errorAcademicYear: PropTypes.bool,
  errorEmisSchoolCode: PropTypes.bool,
  errorSelectedSchool: PropTypes.bool,
  errorEmisSchoolCodeText: PropTypes.string,
  errorGrade: PropTypes.bool,
  handleStartEnrollment: PropTypes.func,
  isOpenDialog: PropTypes.bool,
  onHandleClose: PropTypes.func,
  school: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number
  ]
  ),
  isLoading: PropTypes.bool,
  onHandleConfirm: PropTypes.func,
  isDisableInput: PropTypes.bool,
  handleReset: PropTypes.func,
  suffix: PropTypes.string,
  errorSuffix: PropTypes.bool,
  reportStage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errorReportStage: PropTypes.bool,
  setSchool: PropTypes.func,
  enrollmentDate: PropTypes.any,
  onChangeDateHandler: PropTypes.func
};
