import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';

const scoreStyle = (theme) => ({
  ...defaultFormStyle(theme),
  textRight: {
    float: 'right'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  totalRecord: {
    width: '150px',
    height: '40px',
    display: 'inline-block',
    border: '1px solid #E0E0DE',
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: '10px',
    marginLeft: '5px'
  },
  totalFemaleRecord: {
    marginLeft: '10px'
  }
});

export default scoreStyle;
