import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  isSaving: false,
  isCalculating: false,
  monthlyScoreList: [],
  annualScoreList: [],
  scoreFilter: [],
  subjects: [],
  excludedSubjects: [],
  validationStatus: {},
  isImporting: false,
  isImported: false,
  importedResult: {}
};

const getMonthlyScoreListRequest = (state) => ({
  ...state,
  isLoading: true
});

const getMonthlyScoreListSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  monthlyScoreList: action.monthlyScoreList,
  scoreFilter: action.scoreFilter
});

const getMonthlyScoreListFail = (state) => ({
  ...state,
  isLoading: false
});

const getAnnualScoreListRequest = (state) => ({
  ...state,
  isLoading: true
});

const getAnnualScoreListSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  annualScoreList: action.annualScoreList,
  scoreFilter: action.scoreFilter
});

const getAnnualScoreListFail = (state) => ({
  ...state,
  isLoading: false
});

const saveMonthlyScoresRequest = (state) => ({
  ...state,
  isSaving: true
});

const saveMonthlyScoresSuccess = (state, action) => ({
  ...state,
  isSaving: false,
  monthlyScoreList: action.monthlyScoreList
});

const saveMonthlyScoresFail = (state) => ({
  ...state,
  isSaving: false
});

const saveAnnualScoresRequest = (state) => ({
  ...state,
  isSaving: true
});

const saveAnnualScoresSuccess = (state, action) => ({
  ...state,
  isSaving: false,
  annualScoreList: action.annualScoreList
});

const saveAnnualScoresFail = (state) => ({
  ...state,
  isSaving: false
});

const getSubjectsSuccess = (state, action) => ({
  ...state,
  subjects: action.subjects,
  excludedSubjects: action.excludedSubjects,
  validationStatus: {}
});

const getSubjectsFail = (state) => ({
  ...state,
  subjects: []
});

const addValidationStatusSuccess = (state, action) => ({
  ...state,
  validationStatus: action.validationStatus
});

const calculateSemesterScoreRequest = (state) => ({
  ...state,
  isCalculating: true
});

const calculateSemesterScoreSuccess = (state) => ({
  ...state,
  isCalculating: false
});

const calculateSemesterScoreFail = (state) => ({
  ...state,
  isCalculating: false
});

const importStudentScoreRequest = (state) => {
  return {
    ...state,
    isImporting: true
  };
};

const importStudentScoreSuccess = (state, action) => {
  return {
    ...state,
    isImporting: false,
    isImported: true,
    importedResult: action.result
  };
};

const importStudentScoreFail = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: true
  };
};

const resetImportScoreStatesRequest = (state) => {
  return {
    ...state,
    isImporting: false,
    isImported: false,
    importedResult: {}
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MONTHLY_SCORE_LIST_REQUEST:
      return getMonthlyScoreListRequest(state);
    case actionTypes.GET_MONTHLY_SCORE_LIST_SUCCESS:
      return getMonthlyScoreListSuccess(state, action);
    case actionTypes.GET_MONTHLY_SCORE_LIST_FAIL:
      return getMonthlyScoreListFail(state);

    case actionTypes.GET_ANNUAL_SCORE_LIST_REQUEST:
      return getAnnualScoreListRequest(state);
    case actionTypes.GET_ANNUAL_SCORE_LIST_SUCCESS:
      return getAnnualScoreListSuccess(state, action);
    case actionTypes.GET_ANNUAL_SCORE_LIST_FAIL:
      return getAnnualScoreListFail(state);

    case actionTypes.SAVE_MONTHLY_SCORES_REQUEST:
      return saveMonthlyScoresRequest(state);
    case actionTypes.SAVE_MONTHLY_SCORES_SUCCESS:
      return saveMonthlyScoresSuccess(state, action);
    case actionTypes.SAVE_MONTHLY_SCORES_FAIL:
      return saveMonthlyScoresFail(state);

    case actionTypes.SAVE_ANNUAL_SCORES_REQUEST:
      return saveAnnualScoresRequest(state);
    case actionTypes.SAVE_ANNUAL_SCORES_SUCCESS:
      return saveAnnualScoresSuccess(state, action);
    case actionTypes.SAVE_ANNUAL_SCORES_FAIL:
      return saveAnnualScoresFail(state);

    case actionTypes.GET_SUBJECTS_SUCCESS:
      return getSubjectsSuccess(state, action);
    case actionTypes.GET_SUBJECTS_FAIL:
      return getSubjectsFail(state);

    case actionTypes.RESET_SCORE:
      return initialState;

    case actionTypes.ADD_VALIDATION_STATUS_SUCCESS:
      return addValidationStatusSuccess(state, action);

    case actionTypes.CALCULATE_SEMESTER_SCORE_REQUEST:
      return calculateSemesterScoreRequest(state);
    case actionTypes.CALCULATE_SEMESTER_SCORE_SUCCESS:
      return calculateSemesterScoreSuccess(state, action);
    case actionTypes.CALCULATE_SEMESTER_SCORE_FAIL:
      return calculateSemesterScoreFail(state);

    case actionTypes.IMPORT_STUDENT_SCORE_REQUEST:
      return importStudentScoreRequest(state);
    case actionTypes.IMPORT_STUDENT_SCORE_SUCCESS:
      return importStudentScoreSuccess(state, action);
    case actionTypes.IMPORT_STUDENT_SCORE_FAIL:
      return importStudentScoreFail(state);

    case actionTypes.RESET_IMPORT_SCORE_STATE_REQUEST:
      return resetImportScoreStatesRequest(state);

    default:
      return state;
  }
};

export default reducer;
