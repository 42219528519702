import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SchoolIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import {
  getProvinces,
  getDistricts,
  getCommunes,
  getVillages,
  clearUnits
} from 'redux/actions/gazetteerCode';

import styles from 'assets/sts/jss/views/school/createEditSchoolStyle';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';
import PropTypes from 'prop-types';
import { createEditSchool, getSchool } from 'redux/actions/school';
import { getAcademicYears, getAcademicYear } from 'redux/actions/academicYear';
import { getStaffs } from 'redux/actions/staff';

import * as VARIABLES from 'variables/school';
import * as helpers from 'utilities/helpers';
import { groups as GROUPS } from 'variables/user';
import { getGazetteerChoices } from 'utilities/gazetteer';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import * as moment from 'moment';
import settings from 'settings';
import * as ACADEMICSTATUS from 'variables/academicYearStatus';
import { showErrorNotification } from '../../redux/actions';
import * as ROUTES from 'variables/routeNames';

const useStyles = makeStyles(styles);

const CreateEditSchool = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const isLoading = useSelector(state => state.school.isLoading);
  const schoolId = props.match.params.schoolId;
  const school = useSelector((state) => state.school.school);
  const schoolManagers = useSelector((state) => state.staff.staffs);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const academicYearDetail = useSelector(state => state.academicYear.academicYearDetail);
  const authProfile = useSelector(state => state.auth.profile);
  const schoolFilter = useSelector(state => state.school.schoolFilter);

  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [communeChoices, setCommuneChoices] = useState([]);
  const [villageChoices, setVillageChoices] = useState([]);
  const [schoolManagerChoices, setSchoolManagerChoices] = useState([]);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);

  const [schoolName, setSchoolName] = useState('');
  const [errorSchoolName, setErrorSchoolName] = useState(false);

  const [schoolNameEn, setSchoolNameEn] = useState('');
  const [emisSchoolCode, setEmisSchoolCode] = useState('');
  const [errorEmisSchoolCode, setErrorEmisSchoolCode] = useState(false);

  const [schoolManager, setSchoolManager] = useState('');

  const [degree, setDegree] = useState('');
  const [errorDegree, setErrorDegree] = useState(false);

  const [academicYear, setAcademicYear] = useState('');
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);

  const [status, setStatus] = useState(helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']));

  const [province, setProvince] = useState('');
  const [errorProvince, setErrorProvince] = useState(false);

  const [district, setDistrict] = useState('');
  const [errorDistrict, setErrorDistrict] = useState(false);

  const [commune, setCommune] = useState('');
  const [errorCommune, setErrorCommune] = useState(false);

  const [village, setVillage] = useState('');
  const [errorVillage, setErrorVillage] = useState(false);

  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [phoneNumber3, setPhoneNumber3] = useState('');

  const [accomplishmentStatus, setAccomplishmentStatus] = useState(0);

  // set title
  useEffect(() => {
    document.title = `${translate('school.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('school.pageTitle');
  }, [translate]);

  useEffect(() => {
    if (authProfile && school) {
      if (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT && authProfile.school.emis_code !== school.emis_code) {
        history.push(ROUTES.SCHOOL);
      }

      if (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        if (authProfile.province.code !== school.province.code) {
          history.push(ROUTES.SCHOOL);
        }
      }

      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER) {
        if (authProfile.district.code !== school.district.code) {
          history.push(ROUTES.SCHOOL);
        }
      }
    }
  }, [school, authProfile, history]);

  useEffect(
    () => {
      if (school) {
        setSchoolName(school.name || '');
        setSchoolNameEn(school.name_en || '');
        setEmisSchoolCode(school.emis_code);
        setStatus(school.status);
        setDegree(school.degree.number);
        setPhoneNumber1(school.phone_number_1 || '');
        setPhoneNumber2(school.phone_number_2 || '');
        setPhoneNumber3(school.phone_number_3 || '');
        setAcademicYear(school.academic_year_id || schoolFilter.academic_year);
        setSchoolManager(school.principle ? school.principle.staff_id : '');
        setProvince(`${school.province.id},${school.province.code}`);
        setDistrict(`${school.district.id},${school.district.code}`);
        setCommune(`${school.commune.id},${school.commune.code}`);
        setVillage(`${school.village.id},${school.village.code}`);
        setAccomplishmentStatus(school.accomplishment_status || 0);
      } else {
        if (!schoolId) {
          history.push(ROUTES.ERROR_PAGE_ROOT + '/404');
        }
      }
    }
    , [school, schoolId, history, schoolFilter]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    fetchProvincesData();
  }, [fetchProvincesData]);

  useEffect(
    () => {
      setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces));
    }
    // eslint-disable-next-line
      , [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== '') {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
    },
    [dispatch, province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(
    () => {
      setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts));
    }
    // eslint-disable-next-line
      , [districts, localize]);

  // fetch communes after district is selected
  const fetchCommunesData = useCallback(
    () => {
      if (district !== '') {
        dispatch(getCommunes(district));
      }
      dispatch(clearUnits('district'));
    },
    [dispatch, district]
  );

  useEffect(() => {
    fetchCommunesData();
  }, [fetchCommunesData]);

  useEffect(
    () => {
      setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes));
    }
    // eslint-disable-next-line
      , [communes, localize]);

  // fetch villages after commune is selected
  const fetchVillagesData = useCallback(
    () => {
      if (commune !== '') {
        dispatch(getVillages(commune));
      }
      dispatch(clearUnits('commune'));
    },
    [dispatch, commune]
  );

  useEffect(() => {
    fetchVillagesData();
  }, [fetchVillagesData]);

  useEffect(
    () => {
      setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages));
    }
    // eslint-disable-next-line
      , [villages, localize]);

  const fetchStaffs = useCallback(
    () => {
      dispatch(getStaffs(GROUPS.SCHOOL_MANAGEMENT, schoolId, school?.id));
    },
    [dispatch, schoolId, school]
  );

  useEffect(
    () => {
      fetchStaffs();
    },
    // eslint-disable-next-line
      [fetchStaffs]);

  useEffect(
    () => {
      setSchoolManagerChoices(schoolManagers.map(manager => {
        return {
          label: manager.user ? `${manager.user.last_name} ${manager.user.first_name}` : '',
          value: manager.id
        };
      }));
    }
    // eslint-disable-next-line
      , [schoolManagers]);

  const fetchData = useCallback(
    () => {
      if (schoolId) {
        dispatch(getSchool(schoolId));
      }
    },
    [dispatch, schoolId]
  );

  // fetch Academic Years School
  const fetchAcademicYears = useCallback(
    () => {
      if (!schoolId) {
        dispatch(getAcademicYears());
      }
    },
    [dispatch, schoolId]
  );

  const fetchAcademicYear = useCallback(
    () => {
      if (school && school.academic_year_id) {
        dispatch(getAcademicYear(school.academic_year_id));
      }
    },
    [dispatch, school]
  );

  useEffect(() => {
    fetchAcademicYears();
    fetchAcademicYear();
  }, [fetchAcademicYears, fetchAcademicYear]);

  useEffect(() => {
    let choice;
    if (academicYears.length > 0) {
      choice = academicYears.filter(academic =>
        academic.status === ACADEMICSTATUS.DRAFT || academic.status === ACADEMICSTATUS.PRE
      ).map(academic => {
        const academicStartDate = moment(academic.start_date, settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        return {
          value: academic.id,
          label: studyYear
        };
      });
    }
    if (academicYearDetail) {
      const academicStartDate = moment(academicYearDetail.start_date, settings.dateFormat);
      const academicEndDate = moment(academicYearDetail.end_date, settings.dateFormat);
      const academicStatus = translate(`academicYear.status.${academicYearDetail.status}`).toUpperCase();
      const studyYear = (academicYearDetail.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
      choice = [{
        value: academicYearDetail.id,
        label: studyYear
      }];
    }
    setAcademicYearChoices(choice);
  }, [academicYears, academicYearDetail, translate]);

  useEffect(
    () => {
      if (!isLoading) {
        fetchData();
      }
    },
    // eslint-disable-next-line
      [fetchData]);

  const handleCreateEdit = (e) => {
    e.preventDefault();
    let isCanCreate = true;
    if (!schoolId && !schoolName) {
      setErrorSchoolName(true);
      isCanCreate = false;
    } else {
      setErrorSchoolName(false);
    }

    if (!degree) {
      setErrorDegree(true);
      isCanCreate = false;
    } else {
      setErrorDegree(false);
    }

    if (!province) {
      setErrorProvince(true);
      isCanCreate = false;
    } else {
      setErrorProvince(false);
    }

    if (!district) {
      setErrorDistrict(true);
      isCanCreate = false;
    } else {
      setErrorDistrict(false);
    }

    if (!commune) {
      setErrorCommune(true);
      isCanCreate = false;
    } else {
      setErrorCommune(false);
    }

    if (!village) {
      setErrorVillage(true);
      isCanCreate = false;
    } else {
      setErrorVillage(false);
    }

    if (!academicYear) {
      setErrorAcademicYear(true);
      isCanCreate = false;
    } else {
      setErrorAcademicYear(false);
    }

    if (!emisSchoolCode) {
      setErrorEmisSchoolCode(true);
      isCanCreate = false;
    } else {
      setErrorEmisSchoolCode(false);
    }

    let gazetteerId = province ? province.split(',')[0] : '';
    if (village) {
      gazetteerId = village.split(',')[0];
    } else if (commune) {
      gazetteerId = commune.split(',')[0];
    } else if (district) {
      gazetteerId = district.split(',')[0];
    }
    if (isCanCreate) {
      let data;

      if (schoolId) {
        data = {
          status,
          principle: schoolManager || '',
          phone_number_2: phoneNumber2 ? phoneNumber2.trim() : '',
          phone_number_3: phoneNumber3 ? phoneNumber3.trim() : ''
        };
      } else {
        data = {
          name: schoolName ? schoolName.trim() : undefined,
          name_en: schoolNameEn ? schoolNameEn.trim() : undefined,
          code: school.code,
          emis_code: emisSchoolCode ? emisSchoolCode.trim() : undefined,
          school_id: school.id,
          principle: schoolManager || '',
          degree,
          status,
          gazetteer_id: gazetteerId,
          academic_year_id: schoolId ? school.academic_year_id : academicYear,
          phone_number_1: phoneNumber1 ? phoneNumber1.trim() : '',
          phone_number_2: phoneNumber2 ? phoneNumber2.trim() : '',
          phone_number_3: phoneNumber3 ? phoneNumber3.trim() : ''
        };
      }

      data.accomplishment_status = accomplishmentStatus;

      dispatch(createEditSchool(data, schoolId || false, !schoolId ? translate('school.createSuccessfulMessage') : translate('school.editSuccessfulMessage')));
    } else {
      dispatch(showErrorNotification(translate('common.error.form')));
    }
  };

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'schoolName':
        setSchoolName(value);
        break;
      case 'schoolNameEn':
        setSchoolNameEn(value);
        break;
      case 'emisSchoolCode':
        setEmisSchoolCode(value);
        break;
      case 'schoolManager':
        setSchoolManager(value);
        break;
      case 'degree':
        setDegree(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'province':
        setProvince(value);
        setDistrict('');
        setCommune('');
        setVillage('');
        break;
      case 'district':
        setDistrict(value);
        setCommune('');
        setVillage('');
        break;
      case 'commune':
        setCommune(value);
        setVillage('');
        break;
      case 'village':
        setVillage(value);
        break;
      case 'phoneNumber1':
        setPhoneNumber1(value);
        break;
      case 'phoneNumber2':
        setPhoneNumber2(value);
        break;
      case 'phoneNumber3':
        setPhoneNumber3(value);
        break;
      case 'academicYear':
        setAcademicYear(value);
        break;
      default:
    }
  };
  const mediaMatch = window.matchMedia('(max-width: 960px)');

  const form = (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <SchoolIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate(schoolId ? 'school.edit' : 'school.new')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  lg={8}
                >
                  <GridContainer>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Input
                        require
                        label={translate('school.schoolName')}
                        placeholder={translate('school.placeholder.schoolName')}
                        value={schoolName}
                        name="schoolName"
                        onChange={e => onChangeHandler(e, 'schoolName')}
                        error={errorSchoolName}
                        helperText={errorSchoolName ? translate('common.error.require') : ''}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Typography
                        variant="body1"
                        classes={{
                          body1: mediaMatch.matches ? classes.keyboardSwitch600 : classes.keyboardSwitch
                        }}
                      >
                        {translate('common.switchToEn')}
                      </Typography>
                      <Input
                        label={translate('school.schoolNameEn')}
                        placeholder={translate('school.placeholder.schoolNameEn')}
                        value={schoolNameEn}
                        name="schoolNameEn"
                        onChange={e => onChangeHandler(e, 'schoolNameEn')}
                        disabled
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Input
                        label={translate('school.emisSchoolCode')}
                        placeholder={translate('school.placeholder.emisSchoolCode')}
                        value={emisSchoolCode}
                        name="emisSchoolCode"
                        onChange={e => onChangeHandler(e, 'emisSchoolCode')}
                        require
                        error={errorEmisSchoolCode}
                        helperText={errorEmisSchoolCode ? translate('common.error.require') : ''}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('school.schoolManager')}
                        placeholder={translate('school.placeholder.schoolManager')}
                        value={schoolManager}
                        name="schoolManager"
                        onChange={e => onChangeHandler(e, 'schoolManager')}
                        choices={schoolManagerChoices}
                        disabled={authProfile && authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('school.degree')}
                        placeholder={translate('school.placeholder.degree')}
                        value={degree}
                        name="degree"
                        onChange={e => onChangeHandler(e, 'degree')}
                        choices={Object.keys(VARIABLES.degree).map(degree => (
                          {
                            label: translate(`school.schoolDegree.${VARIABLES.degree[degree].code}`),
                            value: degree
                          }
                        ))}
                        require
                        error={errorDegree}
                        helperText={errorDegree ? translate('common.error.require') : ''}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('common.status')}
                        placeholder={translate('common.placeholder.status')}
                        value={status}
                        name="status"
                        onChange={e => onChangeHandler(e, 'status')}
                        choices={
                          [
                            { label: translate(`school.schoolStatus.${VARIABLES.status['1']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']) },
                            { label: translate(`school.schoolStatus.${VARIABLES.status['0']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['0']) }
                          ]
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('school.academicYear')}
                        placeholder={translate('school.placeholder.academicYear')}
                        value={academicYear}
                        name="academicYear"
                        onChange={e => onChangeHandler(e, 'academicYear')}
                        choices={academicYearChoices}
                        disabled
                        require
                        error={errorAcademicYear}
                        helperText={errorAcademicYear ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  lg={4}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{translate('school.accomplishmentStatus.title')}</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={accomplishmentStatus >= 1}
                            onChange={e => setAccomplishmentStatus(e.target.checked ? 1 : 0)}
                            color="primary"
                          />
                        }
                        label={translate('school.accomplishmentStatus.completedPreparation')}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={accomplishmentStatus >= 2}
                            onChange={e => setAccomplishmentStatus(e.target.checked ? 2 : 1)}
                            color="primary"
                            disabled={accomplishmentStatus < 1}
                          />
                        }
                        label={translate('school.accomplishmentStatus.completedRunning')}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={accomplishmentStatus >= 3}
                            onChange={e => setAccomplishmentStatus(e.target.checked ? 3 : 2)}
                            color="primary"
                            disabled={accomplishmentStatus < 2}
                          />
                        }
                        label={translate('school.accomplishmentStatus.completedClosing')}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

                <GridItem
                  xs={12}
                >
                  <Typography
                    variant="h5"
                    classes={{
                      h5: classes.addressTitle
                    }}
                  >
                    {translate('school.addressContact')}
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  className={
                    classes.addressGrid
                  }
                >
                  <GridContainer
                    className={
                      classes.innerGridContainer
                    }
                  >
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.province')}
                        placeholder={translate('gazetteer.placeholder.province')}
                        value={province}
                        name="province"
                        onChange={e => onChangeHandler(e, 'province')}
                        choices={provinceChoices}
                        disabled
                        require
                        error={errorProvince}
                        helperText={errorProvince ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.district')}
                        placeholder={translate('gazetteer.placeholder.district')}
                        value={district}
                        name="district"
                        onChange={e => onChangeHandler(e, 'district')}
                        choices={districtChoices}
                        disabled
                        require
                        error={errorDistrict}
                        helperText={errorDistrict ? translate('common.error.require') : ''}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.commune')}
                        placeholder={translate('gazetteer.placeholder.commune')}
                        value={commune}
                        name="commune"
                        onChange={e => onChangeHandler(e, 'commune')}
                        choices={communeChoices}
                        disabled
                        require
                        error={errorCommune}
                        helperText={errorCommune ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('gazetteer.village')}
                        placeholder={translate('gazetteer.placeholder.village')}
                        value={village}
                        name="village"
                        onChange={e => onChangeHandler(e, 'village')}
                        choices={villageChoices}
                        disabled
                        require
                        error={errorVillage}
                        helperText={errorVillage ? translate('common.error.require') : ''}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Input
                        label={translate('school.phoneNumber1')}
                        placeholder={translate('school.placeholder.phoneNumber1')}
                        value={phoneNumber1}
                        name="phoneNumber1"
                        onChange={e => onChangeHandler(e, 'phoneNumber1')}
                        disabled
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Input
                        label={translate('school.phoneNumber2')}
                        placeholder={translate('school.placeholder.phoneNumber2')}
                        value={phoneNumber2}
                        name="phoneNumber2"
                        onChange={e => onChangeHandler(e, 'phoneNumber2')}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Input
                        label={translate('school.phoneNumber3')}
                        placeholder={translate('school.placeholder.phoneNumber3')}
                        value={phoneNumber3}
                        name="phoneNumber3"
                        onChange={e => onChangeHandler(e, 'phoneNumber3')}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

              </GridContainer>
              <div
                className={classes.submitButton}
              >
                <Button
                  className={classes.cancelButton}
                  onClick={() => history.push(ROUTES.SCHOOL)}
                >
                  {translate('common.button.cancel')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleCreateEdit}
                >
                  {schoolId ? translate('common.button.save') : translate('common.button.create')}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
  return (
    <div>
      {isLoading && <LoadingSpinner/>}
      {form}
    </div>
  );
};

CreateEditSchool.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default CreateEditSchool;
