import Table from 'components/common/Table/Table';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { ENROLL_STATUS } from 'variables/enrollStatus';
import settings from 'settings';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import styles from 'assets/sts/jss/views/enrollment/importDetailStyle';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

const useStyles = makeStyles(styles);

export default function ImportEnrollmentDetail (props) {
  const {
    count,
    rows,
    duplicatedRecords,
    duplicatedRecordsInFile,
    numberTotalRecord
  } = props;

  const classes = useStyles();
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [errorRows, setErrorRows] = useState([]);
  const [errorDuplicateInFile, setErrorDuplicateInFile] = useState([]);
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const columns = [
    { id: 'rowNumber', label: `${translate('enrollment.rowNumber')}`, isNeedSort: false },
    { id: 'lastName', label: `${translate('common.lastName')}`, isNeedSort: false },
    { id: 'firstName', label: `${translate('common.firstName')}`, isNeedSort: false },
    { id: 'gender', label: `${translate('common.gender')}`, isNeedSort: false },
    { id: 'birthDate', label: `${translate('enrollment.birthDate')}`, isNeedSort: false },
    { id: 'enrollmentStatus', label: `${translate('enrollment.enrollmentStatus')}`, isNeedSort: false }
  ];

  useEffect(() => {
    if (rows.length && duplicatedRecords.length) {
      const duplicates = duplicatedRecords.map(duplicatedRecord => {
        return duplicatedRecord.rowNumber;
      });

      setErrorRows(_.map(_.keys(_.pickBy(rows.slice(page * limit, page * limit + limit), (row) => {
        return duplicates.includes(row.rowNumber);
      })), Number));
    }
  }, [rows, duplicatedRecords, page, limit]);

  useEffect(() => {
    if (rows.length && duplicatedRecordsInFile.length) {
      const duplicatesInFile = duplicatedRecordsInFile.map(duplicatedRecordInFile => {
        return duplicatedRecordInFile.rowNumber;
      });

      setErrorDuplicateInFile(_.map(_.keys(_.pickBy(rows.slice(page * limit, page * limit + limit), (row) => {
        return duplicatesInFile.includes(row.rowNumber);
      })), Number));
    }
  }, [rows, duplicatedRecordsInFile, page, limit]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  return (
    <Box mt={2}>
      {
        !rows.length ? (
          <p>{translate('enrollment.successfulImportMessage', { number: numberTotalRecord })}</p>
        ) : (
          <div>
            <p>{translate('enrollment.brokenRecords')}:</p>
            <Table
              size='small'
              tableHead={columns}
              errorRows={errorRows}
              errorDuplicateInFile={errorDuplicateInFile}
              tableData={rows
                .slice(page * limit, page * limit + limit)
                .map(row => {
                  let birthDate;
                  if (row.student.birth_date) {
                    if (moment(row.student.birth_date, settings.dateFormat, true).isValid()) {
                      birthDate = row.student.birth_date;
                    } else {
                      birthDate = [row.student.birth_date, translate('enrollment.invalidFormat')];
                    }
                  } else {
                    birthDate = '';
                  }

                  return [
                    row.rowNumber,
                    row.student.last_name || '',
                    row.student.first_name || '',
                    row.student.gender === 'm' ? translate('common.genderType.m') : row.student.gender === 'f' ? translate('common.genderType.f') : '',
                    birthDate,
                    row.student.enrollment_status || row.student.enrollment_status === 0 ? translate(`enrollment.status.${ENROLL_STATUS[row.student.enrollment_status]}`) : ''
                  ];
                })}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customErrorClasses={classes}
              customClassesForCells={[0]}
            />
            <p>{translate('enrollment.importErrorMessage')}</p>
            <Chip
              size="small"
              label={translate('enrollment.duplicatedRecords')}
              className={classes.errorRow}
            />
            <Chip
              size="small"
              label={translate('enrollment.duplicatedRecordsInFile')}
              className={classes.errorDuplicateInFileColor}
            />
            <Chip
              size="small"
              label={translate('enrollment.notCompletedRecords')}
              className={classes.errorCell}
            />
            <Chip
              size="small"
              label={translate('enrollment.invalidFormat')}
              className={classes.errorFormat}
            />
          </div>
        )
      }
    </Box>
  );
};

ImportEnrollmentDetail.propTypes = {
  count: PropTypes.number,
  rows: PropTypes.array,
  duplicatedRecords: PropTypes.array,
  duplicatedRecordsInFile: PropTypes.array,
  numberTotalRecord: PropTypes.number
};
