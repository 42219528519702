import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification
} from 'redux/actions/notification';

function getStudentOptionsRequested () {
  return {
    type: actionTypes.GET_STUDENT_OPTIONS_REQUEST
  };
}

function getStudentOptionsSuccess (studentOptions) {
  return {
    type: actionTypes.GET_STUDENT_OPTIONS_SUCCESS,
    studentOptions
  };
}

function getStudentOptionsFail () {
  return {
    type: actionTypes.GET_STUDENT_OPTIONS_FAIL
  };
}

export const getStudentOptions = () => {
  return (dispatch) => {
    dispatch(getStudentOptionsRequested());
    axios.get('/students/options')
      .then(
        res => {
          const data = res.data;
          dispatch(getStudentOptionsSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getStudentOptionsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
