import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import LoginIcon from '@material-ui/icons/Input';
import styles from 'assets/theme/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { logout, login } from 'redux/actions';
import Render from 'components/hoc/Render/Render';
import customStyles from 'assets/sts/jss/components/adminNavbarLinksStyle';
import { withLocalize, getTranslate } from 'react-localize-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { Link } from 'react-router-dom';
import * as ROUTES from 'variables/routeNames';
import { STUDENT } from 'variables/user';
import LocaleSelection from 'components/LocaleSelection/LocaleSelection';

const combineStyles = theme => ({
  ...styles(theme),
  ...customStyles(theme)
});
const useStyles = makeStyles(combineStyles);

const HeaderLinks = (props) => {
  const [keycloak] = useKeycloak();
  const dispatch = useDispatch();
  const isLogIn = keycloak.authenticated || false;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const authProfile = useSelector(state => state.auth.profile);

  // style
  const classes = useStyles();

  const switchLangHandler = lang => {
    props.setActiveLanguage(lang);
  };

  return (
    <div>
      <div
        className={classNames(classes.toolbar,
          classes.appBar, classes.appBarShift)}
      >
        <div className={classes.box}>
          {isLogIn && authProfile && (
            <Link to={authProfile?.groups[0] !== STUDENT ? `${ROUTES.USER_EDIT_ROOT}/${authProfile.id}` : `${ROUTES.STUDENT_ACCOUNT_EDIT_ROOT}/${authProfile.username}`}>
              <Typography
                className={classes.welcome}
                variant="body2"
              >
                {authProfile.last_name && authProfile.first_name ? `${authProfile.last_name} ${authProfile.first_name}` : authProfile.username}
              </Typography>
            </Link>
          )
          }
          <LocaleSelection
            handleSelect={switchLangHandler}
          />

          <Render value={isLogIn}>
            <Tooltip title={translate('common.button.logout')}>
              <IconButton
                className={classes.loginButton}
                onClick={() => dispatch(logout())}
              >
                <LogoutIcon/>
              </IconButton>
            </Tooltip>
          </Render>
          <Render value={!isLogIn}>
            <Tooltip title={translate('common.button.login')}>
              <IconButton
                className={classes.loginButton}
                onClick={() => dispatch(login())}
              >
                <LoginIcon/>
              </IconButton>
            </Tooltip>
          </Render>
        </div>
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {
  setActiveLanguage: PropTypes.func.isRequired
};

export default withLocalize(HeaderLinks);
