import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import settings from 'settings';
import { getScholarshipList } from 'redux/actions/scholarship';
import { getStudentOptions } from 'redux/actions';
import _ from 'lodash';
import { Chip } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';

const useStyles = makeStyles(styles);

const ScholarshipTable = ({ onEditRowHandler }) => {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const isLoading = useSelector(state => state.scholarship.isLoading);
  const scholarshipList = useSelector(state => state.scholarship.scholarshipList);
  const transferFilter = useSelector(state => state.scholarship.scholarshipFilter);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const allGrades = useSelector(state => state.studentOption.grade);

  const [school, setSchool] = useState('');
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);

  useEffect(() => {
    if (transferFilter) {
      setSchool(transferFilter.school);
      setLimit(transferFilter.limit);
      setPage(transferFilter.page);
    }
  }, [transferFilter]);

  useEffect(() => {
    if (transferFilter !== undefined && school) {
      const params = {
        school,
        page,
        limit
      };
      dispatch(getScholarshipList(params));
    }
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    if (scholarshipList.data && scholarshipList.data.length > 0) {
      const showRows = scholarshipList.data.map(
        (student) => ({
          id: student.id,
          stsId: student.student.emis_student_id,
          name: `${student.student.last_name} ${student.student.first_name}`,
          gender: student.student.gender,
          birthDate: student.student.birth_date,
          classroom: student.classroom,
          scholarships: student.scholarships,
          poorLevel: student.poor_level
        })
      );
      setCount(scholarshipList && scholarshipList.total ? scholarshipList.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [scholarshipList]);

  useEffect(() => {
    if (!isLoadingStudentOption) {
      dispatch(getStudentOptions());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        <Card>
          <CardBody>
            <Table
              size='small'
              tableHead={[
                { id: 'stsId', isNeedSort: false, label: translate('common.stsId') },
                { id: 'name', isNeedSort: false, label: translate('common.name') },
                { id: 'gender', isNeedSort: false, label: translate('common.gender') },
                { id: 'birthDate', isNeedSort: false, label: translate('enrollment.birthDate') },
                { id: 'classroom', isNeedSort: false, label: translate('school.classroom') },
                { id: 'scholarship', isNeedSort: false, label: translate('enrollment.scholarship') },
                { id: 'poorLevel', isNeedSort: false, label: translate('scholarship.poorLevel') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const editButton = (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={`edit-${row.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        onClick={() => onEditRowHandler(row.id)}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                const renderClassroomLabel = (classroom) => {
                  if (classroom) {
                    const selectedGrade = allGrades.find(g => g.number === classroom.grade);
                    if (selectedGrade !== undefined) {
                      const gradeLabel = getActiveLanguage(localize).code === 'en' ? selectedGrade.name_en : selectedGrade.name;
                      if (classroom.suffix) {
                        return `${gradeLabel} (${classroom.suffix})`;
                      } else {
                        return gradeLabel;
                      }
                    }
                  }
                  return '';
                };
                const renderScholarshipLabel = (scholarships) => {
                  return scholarships?.map(({ scholarship_id: id }) => {
                    const scholarship = _.findLast(allScholarships, { id });
                    return <Chip key={id} size="small" label={scholarship.type} />;
                  });
                };
                return [
                  row.stsId,
                  row.name,
                  row.gender === 'm' ? translate('common.genderType.m') : row.gender === 'f' ? translate('common.genderType.f') : '',
                  row.birthDate,
                  renderClassroomLabel(row.classroom),
                  renderScholarshipLabel(row.scholarships),
                  row.poorLevel ? translate(`scholarship.poorLevel.option.${row.poorLevel}`) : '',
                  [editButton]
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customClassesForCells={[0]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

ScholarshipTable.propTypes = {
  onEditRowHandler: PropTypes.func
};

export default ScholarshipTable;
