import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';

import styles from 'assets/theme/jss/material-dashboard-pro-react/components/tableStyle';
import customStyles from 'assets/sts/jss/components/tableStyle';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const combineStyles = theme => ({
  ...styles(theme),
  ...customStyles(theme)
});
const useStyles = makeStyles(combineStyles);

export default function CustomTable (props) {
  const classes = useStyles();
  const {
    tableHead,
    errorRows,
    errorDuplicateInFile,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    customErrorClasses,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    count,
    limit,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    orderBy,
    order,
    onRequestSort,
    isNeedCheckBox,
    numSelected,
    onSelectAllClick,
    ...rest
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  let checkBoxNum = count;

  if (isNeedCheckBox) {
    if (count > limit) {
      checkBoxNum = limit;
    }
  }

  return (
    <>
      <div className={classes.tableResponsive}>
        <Table
          className={classes.table}
          {...rest}
        >
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor]}>
              <TableRow className={`${classes.tableRow} ${classes.customTableRowHead}`}>
                { isNeedCheckBox && (
                  <TableCell
                    className={`${classes.tableHeadCell} ${classes.customTableCell} ${classes.checkbox}`}
                    key={'select-all-check-box'}
                  >
                    <Checkbox
                      indeterminate={numSelected > 0 && numSelected < checkBoxNum}
                      checked={count > 0 && numSelected === checkBoxNum}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                      classes={{
                        checked: classes.allCheckbox
                      }}
                    />
                  </TableCell>
                )
                }
                {tableHead.map((prop, key) => {
                  const tableCellClasses = `${classes.tableHeadCell
                } ${
                  classes.customTableCell
                } ${
                  cx({
                    [classes.tableShoppingHead]: tableShopping,
                    [classes.tableHeadFontSize]: !tableShopping
                  })}`;
                  return (
                    <TableCell
                      className={tableCellClasses}
                      key={key}
                      align={customHeadClassesForCells.indexOf(key) !== -1 ? customHeadCellClasses[customHeadClassesForCells.indexOf(key)] : 'left'}
                    >
                      {
                        prop.isNeedSort ? (
                          <TableSortLabel
                            active={orderBy === prop.id}
                            direction={order}
                            onClick={createSortHandler(prop.id)}
                          >
                            {prop.label}
                            {orderBy === prop.id ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : prop.label
                      }
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.length === 0
              ? <TableRow>
                <TableCell
                  className={`${classes.customTableCell} ${classes.noDataTableCell}`}
                  colSpan={tableHead ? isNeedCheckBox ? tableHead.length + 1 : tableHead.length : 0}
                >
                  {translate('common.noData')}
                </TableCell>
              </TableRow>
              : tableData.map((prop, key) => {
                let rowColor = '';
                let rowColored = false;
                if (prop.color !== undefined) {
                  rowColor = prop.color;
                  rowColored = true;
                  prop = prop.data;
                }
                const tableRowClasses = cx({
                  [classes.customTableRowBody]: true,
                  [classes.tableRowHover]: hover,
                  [classes[`${rowColor}Row`]]: rowColored,
                  [classes.tableStripedRow]: striped && key % 2 === 0,
                  [customErrorClasses.errorRow]: customErrorClasses.errorRow && errorRows.includes(key),
                  [customErrorClasses.errorDuplicateInFileColor]: customErrorClasses.errorDuplicateInFileColor && errorDuplicateInFile.includes(key)
                });
                return (
                  <TableRow
                    key={key}
                    hover={hover}
                    className={`${classes.tableRow} ${tableRowClasses}`}
                  >
                    {prop.map((prop, key) => {
                      const tableCellClasses = `${classes.customTableCell
                      } ${cx({
                        [classes[colorsColls[coloredColls.indexOf(key)]]]:
                          coloredColls.indexOf(key) !== -1,
                        [customCellClasses[customClassesForCells.indexOf(key)]]:
                          customClassesForCells.indexOf(key) !== -1,
                        [customErrorClasses.errorCell]: customErrorClasses.errorCell && !prop,
                        [customErrorClasses.errorFormat]: customErrorClasses.errorFormat && prop[1] === translate('enrollment.invalidFormat')
                      })}`;
                      return (
                        <TableCell
                          align={customClassesForCells.indexOf(key) !== -1 ? customCellClasses[customClassesForCells.indexOf(key)] : 'left'}
                          className={tableCellClasses}
                          key={key}
                        >
                          {Array.isArray(prop) && customErrorClasses.errorFormat ? prop[0] : prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={limit}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        className={classes.paginationTable}
      />
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
  errorRows: [],
  errorDuplicateInFile: [],
  count: 0,
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customErrorClasses: {},
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  onRequestSort: () => {},
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
  isNeedCheckBox: false
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray'
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  errorRows: PropTypes.array,
  errorDuplicateInFile: PropTypes.array,
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customErrorClasses: PropTypes.object,
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  numSelected: PropTypes.number,
  isNeedCheckBox: PropTypes.bool,
  onSelectAllClick: PropTypes.func
};
