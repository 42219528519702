import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import ScoreInlineEditTable from 'views/Score/Patials/ScoreTable/ScoreInlineEditTable';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import GridItem from 'components/theme/Grid/GridItem';
import GridContainer from 'components/theme/Grid/GridContainer';
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import ImportScoreButton from 'views/Score/Patials/ImportScoreButton/ImportScoreButton';
import styles from 'assets/sts/jss/views/score/scoreStyle';
import { makeStyles } from '@material-ui/core/styles';

import {
  saveMonthlyScores,
  addValidationStatus,
  saveAnnualScores,
  calculateSemesterScore, checkImportStatus
} from 'redux/actions';
import * as SCORE from 'variables/score';
import { roles as ROLES } from 'variables/user';
import SweetAlert from 'react-bootstrap-sweetalert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useKeycloak } from '@react-keycloak/web';
import queryString from 'query-string';
import { ARCHIVED, POST } from 'variables/academicYearStatus';
import DateTimePicker
  from '../../../../components/common/DateTimePicker/DateTimePicker';
import moment from 'moment';

const useStyles = makeStyles(styles);
const ScoreTable = (props) => {
  const classStyles = useStyles();
  const {
    classes,
    location
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const [keycloak] = useKeycloak();

  const defaultColumns = [
    { name: 'emis_student_id', title: translate('common.stsId') },
    { name: 'name', title: translate('common.name') },
    { name: 'gender', title: translate('common.gender') }
  ];
  const defaultColumnExtensions = [
    { columnName: 'emis_student_id', align: 'left', width: 140 },
    { columnName: 'name', align: 'left', width: 140 },
    { columnName: 'gender', align: 'left', width: 80 }
  ];
  const defaultEditingColumnExtensions = [
    { columnName: 'emis_student_id', editingEnabled: false },
    { columnName: 'name', editingEnabled: false },
    { columnName: 'gender', editingEnabled: false }
  ];
  const monthlyScoreList = useSelector((state) => state.score.monthlyScoreList);
  const annualScoreList = useSelector((state) => state.score.annualScoreList);
  const [rows, setRows] = useState([]);
  const [femaleStudents, setFemaleStudents] = useState([]);
  const scoreFilter = useSelector(state => state.score.scoreFilter);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const isLoading = useSelector(state => state.student.isLoading);
  const isLoadingScore = useSelector(state => state.score.isLoading);
  const isSaving = useSelector(state => state.score.isSaving);
  const isCalculating = useSelector(state => state.score.isCalculating);
  const subjects = useSelector(state => state.score.subjects);
  const excludedSubjects = useSelector(state => state.score.excludedSubjects);
  const [subjectMaxScores, setSubjectMaxScores] = useState({});
  const [columns, setColumns] = useState(defaultColumns);
  const [columnBands, setColumnBands] = useState([]);
  const [columnExtensions, setColumnExtensions] = useState(defaultColumnExtensions);
  const [validationRules, setValidationRules] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [canSave, setCanSave] = useState(true);
  const [stepColumns, setStepColumns] = useState([]);
  const [calculatedSemester, setCalculatedSemester] = useState(null);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [isShowImportButton, setIsShowImportButton] = useState(false);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [semesterStartDate, setSemesterStartDate] = useState(null);
  const [errorSemesterStartDate, setErrorSemesterStartDate] = useState(false);
  const [errorInvalidSemesterStartDate, setErrorInvalidSemesterStartDate] = useState(false);
  const [semesterEndDate, setSemesterEndDate] = useState(null);
  const [errorSemesterEndDate, setErrorSemesterEndDate] = useState(false);
  const [errorInvalidSemesterEndDate, setErrorInvalidSemesterEndDate] = useState(false);
  const [errorDateRange, setErrorDateRange] = useState(false);
  const [isImportingScore, setIsImportingScore] = useState(false);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const academicYearId = queryString.parse(location.search).academicYear;
  const academicYear = academicYearId !== undefined ? parseInt(academicYearId) : scoreFilter?. academicYear || '';

  let isPostOrArchivedAcademicYear = false;
  if (academicYears.length > 0 && academicYear) {
    const index = academicYears.findIndex(year => parseInt(academicYear) === year.id);
    if (academicYears[index].status === POST || academicYears[index].status === ARCHIVED) {
      isPostOrArchivedAcademicYear = true;
    } else {
      isPostOrArchivedAcademicYear = false;
    }
  }

  // set score data
  useEffect(() => {
    if (monthlyScoreList || annualScoreList) {
      if (scoreFilter.month === SCORE.ANNUAL_SCORE_OPTION) {
        setRows(annualScoreList);
      } else {
        setRows(monthlyScoreList);
      }
      const totalFemale = monthlyScoreList.filter(a => a.gender === 'f');
      setFemaleStudents(totalFemale);
    }
  }, [monthlyScoreList, annualScoreList, scoreFilter]);

  useEffect(() => {
    if (subjects.length && scoreFilter.month !== SCORE.ANNUAL_SCORE_OPTION) {
      const isEnglish = getActiveLanguage(localize).code === 'en';
      const subjectColumnBands = [];
      const subjectColumns = [];
      const subjectScoreValidateRules = {};
      const maxScores = {};
      const subjectStepColumns = [];

      subjects.forEach(subject => {
        const subjectColumnBand = {
          title: isEnglish ? subject.name_en : subject.name,
          children: []
        };
        subject.academic_year_subjects.forEach(academicYearSubject => {
          if (!excludedSubjects.includes(academicYearSubject.id)) {
            subjectColumnBand.children.push({
              columnName: academicYearSubject.id
            });
            subjectColumns.push({
              name: academicYearSubject.id,
              title: isEnglish
                ? academicYearSubject.name_en
                : academicYearSubject.name
            });
            if (scoreFilter.grade < 1) {
              subjectStepColumns.push(academicYearSubject.id);
            }
            maxScores[academicYearSubject.id] = academicYearSubject.max_score;
            subjectScoreValidateRules[academicYearSubject.id] = maxScoreRule;
          }
        });

        subjectColumnBands.push(subjectColumnBand);
      });

      setColumnBands(subjectColumnBands);
      setColumns([...defaultColumns, ...subjectColumns]);
      setColumnExtensions(defaultColumnExtensions);
      setSubjectMaxScores(maxScores);
      setValidationRules(subjectScoreValidateRules);
      setStepColumns(subjectStepColumns);
    } else {
      if (scoreFilter.month === SCORE.ANNUAL_SCORE_OPTION) {
        const annualColumns = [
          {
            name: 'consolidate_semester_1',
            title: translate('common.consolidatedSemester1')
          },
          {
            name: 'consolidate_semester_2',
            title: translate('common.consolidatedSemester2')
          }
        ];
        if (scoreFilter.grade < 1) {
          setStepColumns(['consolidate_semester_1', 'consolidate_semester_2']);
        } else {
          setStepColumns([]);
        }
        setColumns([...defaultColumns, ...annualColumns]);
      } else {
        setColumns(defaultColumns);
        setColumnExtensions(defaultColumnExtensions);
      }
    }
    // eslint-disable-next-line
  }, [subjects, translate, scoreFilter]);

  useEffect(() => {
    let ableToSave = true;
    if (!rows.length) {
      ableToSave = false;
    } else {
      _.forEach(validationStatus, (status) => {
        if (_.find(status, { isValid: false })) {
          ableToSave = false;
          return false;
        }
      });
    }
    setCanSave(ableToSave);
  }, [rows, validationStatus]);

  useEffect(() => {
    if (scoreFilter.academicYear &&
      scoreFilter.school &&
      scoreFilter.grade !== '' &&
      scoreFilter.suffix &&
      scoreFilter.month
    ) {
      setIsShowImportButton(true);
    } else {
      setIsShowImportButton(false);
    }
  }, [scoreFilter]);

  useEffect(() => {
    if (academicYear && academicYears.length) {
      const foundAcademicYear = academicYears.find(item => item.id === academicYear);
      setSelectedAcademicYear(foundAcademicYear);
    }
  }, [academicYear, academicYears]);

  useEffect(() => {
    checkScoreImportStatus();
    const intervalCall = setInterval(() => {
      checkScoreImportStatus();
    }, 60000);
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line
  }, [dispatch, scoreFilter.grade, scoreFilter.suffix, scoreFilter.month]);

  const checkScoreImportStatus = () => {
    const classroom = classroomList.find(c => c.grade === scoreFilter.grade && c.suffix === scoreFilter.suffix);
    if (classroom) {
      dispatch(checkImportStatus({ classroom_id: classroom.id, month: scoreFilter.month })).then(result => {
        if (result.data) {
          setIsImportingScore(result.data.importing);
        }
      });
    }
  };

  const cellBandHeaderComponent = ({ children, tableRow, tableColumn, column, classes, ...restProps }) => {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        style={{ textAlign: 'center' }}
      >
        <strong>
          {children}
        </strong>
      </TableBandHeader.Cell>
    );
  };

  cellBandHeaderComponent.propTypes = {
    children: PropTypes.any,
    tableRow: PropTypes.any,
    tableColumn: PropTypes.any,
    column: PropTypes.any,
    classes: PropTypes.instanceOf(Object)
  };

  const maxScoreRule = {
    isValid: (value, maxScore) => value !== '' && value <= maxScore && value >= 0
  };

  const validate = (changed, validationStatus) => Object.keys(changed).reduce((status, id) => {
    let rowStatus = validationStatus[id] || {};
    if (changed[id]) {
      rowStatus = {
        ...rowStatus,
        ...Object.keys(changed[id]).reduce((acc, field) => {
          const isValid = validationRules[field] ? validationRules[field].isValid(changed[id][field], subjectMaxScores[field]) : true;
          return {
            ...acc,
            [field]: {
              isValid
            }
          };
        }, {})
      };
    }

    return { ...status, [id]: rowStatus };
  }, {});

  const commitChanges = ({ changed }) => {
    let changedRows;
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      setValidationStatus({ ...validationStatus, ...validate(changed, validationStatus) });
      dispatch(addValidationStatus({ ...validationStatus, ...validate(changed, validationStatus) }));
    }
    setRows(changedRows);
  };

  const handleSaveAll = () => {
    if (rows.length) {
      let data = [];
      if (scoreFilter.month === SCORE.ANNUAL_SCORE_OPTION) {
        dispatch(saveAnnualScores(rows));
      } else {
        rows.forEach(row => {
          // eslint-disable-next-line
          const { id, emis_student_id, name, gender, ...scores } = row;
          const scoreList = Object.entries(scores).map(([key, value]) => {
            return {
              enrollment_id: id,
              subject_id: key,
              score: value,
              month_number: scoreFilter.month
            };
          });

          data.push(scoreList);
        });

        data = [].concat.apply([], data);

        const classroom = classroomList.find(
          c => c.grade === scoreFilter.grade && c.suffix === scoreFilter.suffix
        );
        dispatch(saveMonthlyScores({ data, classroom_id: classroom.id, month: scoreFilter.month }, rows));
      }
    }
  };

  const handleSemesterCalculation = ($semester) => {
    setCalculatedSemester($semester);
    setIsConfirmBoxOpen(true);
  };

  const onHandleConfirmCalculation = () => {
    let canConfirm = true;
    if (!semesterStartDate) {
      canConfirm = false;
      setErrorSemesterStartDate(true);
    } else {
      setErrorSemesterStartDate(false);
    }

    if (!moment(semesterStartDate).isValid()) {
      canConfirm = false;
      setErrorInvalidSemesterStartDate(true);
    } else {
      setErrorInvalidSemesterStartDate(false);
    }

    if (!semesterEndDate) {
      canConfirm = false;
      setErrorSemesterEndDate(true);
    } else {
      setErrorSemesterEndDate(false);
    }

    if (!moment(semesterEndDate).isValid()) {
      canConfirm = false;
      setErrorInvalidSemesterEndDate(true);
    } else {
      setErrorInvalidSemesterEndDate(false);
    }

    if (canConfirm && semesterStartDate && semesterEndDate && semesterStartDate.isAfter(semesterEndDate)) {
      canConfirm = false;
      setErrorDateRange(true);
    } else {
      setErrorDateRange(false);
    }

    if (canConfirm) {
      const months = [];
      const startDate = semesterStartDate;
      while (semesterStartDate <= semesterEndDate) {
        months.push(parseInt(startDate.format('M')));
        startDate.add(1, 'month');
      }
      dispatch(calculateSemesterScore(calculatedSemester, scoreFilter, months));
      setIsConfirmBoxOpen(false);
      setSemesterStartDate(null);
      setSemesterEndDate(null);
      setErrorSemesterStartDate(false);
      setErrorSemesterEndDate(false);
    }
  };

  const onHandelCancelCalculation = () => {
    setIsConfirmBoxOpen(false);
    setSemesterStartDate(null);
    setSemesterEndDate(null);
    setErrorSemesterStartDate(false);
    setErrorSemesterEndDate(false);
  };

  const onChangeDateHandler = (value, dateParams) => {
    switch (dateParams) {
      case 'semesterStartDate':
        setSemesterStartDate(value);
        break;
      case 'semesterEndDate':
        setSemesterEndDate(value);
        break;
      default:
    }
  };

  return (
    <>
      { isConfirmBoxOpen &&
        <SweetAlert warning style={{ display: 'block', marginTop: '-400px', height: '600px', width: '600px' }}
          title={translate('common.modal.confirmTitle')}
          onConfirm={onHandleConfirmCalculation}
          onCancel={onHandelCancelCalculation}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={translate('common.modal.yes')}
          cancelBtnText={translate('common.modal.no')}
          showCancel >
          <GridContainer>
            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <DateTimePicker
                require
                name='semesterStartDate'
                value={semesterStartDate}
                onChange={(e) => onChangeDateHandler(e, 'semesterStartDate')}
                timeFormat={false}
                dateFormat={'MM-YYYY'}
                isValidDate={(currentDate) => currentDate.isAfter(moment(selectedAcademicYear?.start_date, 'DD-MM-YYYY')) &&
                      currentDate.isBefore(moment(selectedAcademicYear?.end_date, 'DD-MM-YYYY')) &&
                      (!semesterEndDate ||
                          (semesterEndDate && !currentDate.isAfter(semesterEndDate)))
                }
                label={translate('score.semesterStartDate')}
                placeholder={translate('common.placeholder.startDate')}
                error={errorSemesterStartDate || errorDateRange || errorInvalidSemesterStartDate}
                helperText={errorSemesterStartDate
                  ? translate('common.error.require')
                  : errorInvalidSemesterStartDate
                    ? translate('common.error.invalidDate')
                    : errorDateRange
                      ? translate('common.error.startDateRange')
                      : ''
                }
              />
            </GridItem>
            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <DateTimePicker
                require
                name='semesterEndDate'
                value={semesterEndDate}
                onChange={(e) => onChangeDateHandler(e, 'semesterEndDate')}
                timeFormat={false}
                dateFormat={'MM-YYYY'}
                isValidDate={(currentDate) => currentDate.isAfter(moment(selectedAcademicYear?.start_date, 'DD-MM-YYYY')) &&
                      currentDate.isBefore(moment(selectedAcademicYear?.end_date, 'DD-MM-YYYY')) &&
                      (!semesterStartDate ||
                          (semesterStartDate && !currentDate.isBefore(semesterStartDate)))
                }
                label={translate('score.semesterEndDate')}
                placeholder={translate('common.placeholder.endDate')}
                error={errorSemesterEndDate || errorDateRange || errorInvalidSemesterEndDate}
                helperText={errorSemesterEndDate
                  ? translate('common.error.require')
                  : errorInvalidSemesterEndDate
                    ? translate('common.error.invalidDate')
                    : errorDateRange
                      ? translate('common.error.endDateRange')
                      : ''
                }
              />
            </GridItem>
          </GridContainer>
          <div style={{ marginBottom: '100px' }}>{translate('score.calculate_confirmation_description')}</div>
        </SweetAlert>
      }
      { isCalculating &&
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit"/>
        </Backdrop>
      }
      <LoadablePaper
        rendered
        loading={isLoading || isLoadingScore}
      >
        <LoadablePaperContent>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem
                  xs={12}
                >
                  <ScoreInlineEditTable
                    classes={classes}
                    columns={columns}
                    columnExtensions={columnExtensions}
                    editingStateColumnExtensions={defaultEditingColumnExtensions}
                    columnBands={columnBands}
                    cellBandHeaderComponent={cellBandHeaderComponent}
                    rows={rows}
                    commitChanges={commitChanges}
                    stepColumns={stepColumns}
                    isPostOrArchivedAcademicYear={isPostOrArchivedAcademicYear}
                  />
                </GridItem>
                <GridItem
                xs={12}
                >
                  <div>
                    <strong>{translate('student.total')}</strong>
                    <strong className={classStyles.totalRecord}>{rows.length}</strong>
                    <strong className={classStyles.totalFemaleRecord}>{translate('student.totalGender.f')}</strong>
                    <strong className={classStyles.totalRecord}>{femaleStudents.length}</strong>
                  </div>
              </GridItem>
                {
                  (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM) || keycloak.hasRealmRole(ROLES.MANAGE_OWNED_CLASSROOM)) && !isPostOrArchivedAcademicYear && (
                    <GridItem
                      xs={12}
                    >
                      { scoreFilter.month === SCORE.ANNUAL_SCORE_OPTION &&
                      <>
                        <ThemeButton
                          color="info"
                          type="submit"
                          onClick={() => handleSemesterCalculation(1)}
                        >
                          {translate('score.calculate_score_for_consolidate_semesterI')}
                        </ThemeButton>
                        <ThemeButton
                          color="info"
                          type="submit"
                          onClick={() => handleSemesterCalculation(2)}
                        >
                          {translate('score.calculate_score_for_consolidate_semesterII')}
                        </ThemeButton>
                      </>
                      }
                      <div
                        className={classes.allButton + ' ' + classes.textRight}
                      >
                        <ThemeButton
                          color="primary"
                          type="submit"
                          onClick={handleSaveAll}
                          disabled={!canSave || isSaving || isImportingScore}
                        >
                          {translate('common.button.saveAll')}
                        </ThemeButton>
                      </div>
                      { isShowImportButton && scoreFilter.month !== SCORE.ANNUAL_SCORE_OPTION && (
                        <ImportScoreButton
                          academicYear={scoreFilter.academicYear}
                          school={scoreFilter.school}
                          grade={scoreFilter.grade}
                          suffix={scoreFilter.suffix}
                          month={scoreFilter.month}
                          isImportingScore={isImportingScore}
                          setIsImportingScore={setIsImportingScore}
                        />
                      )}
                    </GridItem>
                  )
                }
              </GridContainer>
            </CardBody>
          </Card>
        </LoadablePaperContent>
      </LoadablePaper>
    </>
  );
};

ScoreTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  location: PropTypes.any
};

export default ScoreTable;
