import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';
import {
  stsBlueColor,
  success,
  warning
} from 'assets/sts/jss/sts-react';

const homelStyle = (theme) => ({
  ...defaultListingStyle(theme),

  header: {
    width: 'calc(100% +  30px)',
    margin: '-100px 0px 0px -15px',
    background: 'url("/images/home/home.jpg") no-repeat 50% 50%',
    backgroundSize: 'cover',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.contrastText
  },
  headerText: {
    paddingTop: 340
  },
  headerTextValue: {
    height: 105,
    textAlign: 'right',
    background: 'rgba(0, 0, 0, 0.4)',
    color: '#f1f1f1',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      height: 130
    }
  },
  headerLogo: {
    height: 80,
    paddingLeft: 15,
    opacity: 0.7
  },
  heading: {
    fontSize: '1.5rem',
    marginBottom: '5px',

    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.5rem'
    }
  },
  subheading: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem'
    }
  },
  card: {
    height: '100%'
  },
  cardActionArea: {
    height: '100%'
  },
  cardContent: {
    height: '100%'
  },
  cardContentTitle: {
    fontSize: '2.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.9rem'
    }
  },
  cardHeader: {
    height: 105,
    backgroundColor: stsBlueColor,
    color: theme.palette.common.white
  },
  cardHeaderIcon: {
    fontSize: '60px'
  },
  successBg: {
    backgroundColor: success
  },
  warningBg: {
    backgroundColor: warning
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(6)
  },
  logo: {
    marginRight: theme.spacing(1),
    height: '60px',
    '&:first-child': {
      paddingRight: theme.spacing(4)
    }
  }
});

export default homelStyle;
