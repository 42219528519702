export const enrollmentReportStage = 3;

export const result = {
  0: 'failed',
  1: 'passed',
  PASSED: 1,
  FAILED: 0
};
export const reason = {
  1: 'passedRetakeExamination',
  2: 'repeatedTwoAcademicYearConsecutively',
  3: 'others',
  4: 'skipClass'
};
export const graduationAction = {
  promotion: 1,
  repetition: 2
};
export const otherReason = 3;
export const skipClass = 4;

export const unknownSchools = {
  PRIVATE_SCHOOL: -1,
  NONE_STS_SCHOOL: -2
};

export const degree = {
  grade: [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
};
