import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  scholarshipRemittanceList: {},
  scholarshipRemittanceFilter: undefined,
  isLoading: false
};

function getScholarshipRemittanceListRequest (state) {
  return {
    ...state,
    scholarshipRemittanceList: {},
    scholarshipRemittanceFilter: undefined,
    isLoading: true
  };
}

function getScholarshipRemittanceListSuccess (state, action) {
  return {
    ...state,
    scholarshipRemittanceList: action.scholarshipRemittanceList,
    scholarshipRemittanceFilter: action.scholarshipRemittanceFilter,
    isLoading: false
  };
}

function getScholarshipRemittanceListFail (state) {
  return {
    ...state,
    scholarshipRemittanceList: {},
    scholarshipRemittanceFilter: undefined,
    isLoading: false
  };
}

const scholarshipRemittanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_REQUEST:
      return getScholarshipRemittanceListRequest(state);
    case actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_SUCCESS:
      return getScholarshipRemittanceListSuccess(state, action);
    case actionTypes.GET_SCHOLARSHIP_REMITTANCE_LIST_FAIL:
      return getScholarshipRemittanceListFail(state);
    default:
      return state;
  }
};

export default scholarshipRemittanceReducer;
