export const allChoices = (translate) => ([
  { label: translate('gazetteer.all'), value: 'all' }
]);

export const getGazetteerChoices = (activeLanguage, units, translate = undefined) => {
  const gazetteerChoice = units?.map(unit => ({
    label: activeLanguage === 'en' ? unit.name_en : unit.name,
    value: `${unit.id},${unit.code}`
  }));
  return translate ? allChoices(translate).concat(gazetteerChoice) : gazetteerChoice;
};

/**
 *
 * @param activeLanguage
 * @param gazetteerValue comma separate value of id and code (37,0203)
 * @param units
 * @returns {string}
 */
export const getUnitName = (activeLanguage, gazetteerValue, units) => {
  const gazetteerCode = gazetteerValue.split(',')[1];
  const unit = units.find(unit => unit.code === gazetteerCode);
  if (unit) {
    return activeLanguage === 'en' ? unit.name_en : unit.name;
  }
  return '';
};
