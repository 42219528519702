import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';

const transferStyle = (theme) => ({
  ...defaultListingStyle(theme),

  actionContainer: {
    float: 'right'
  }
});

export default transferStyle;
