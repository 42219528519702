import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
  whiteColor,
  grayColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const customInputStyle = {
  disabled: {
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderColor: 'transparent !important'
    }
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: `${grayColor[4]}!important`,
      borderWidth: '1px !important',
      height: '40px'
    },
    '&:after': {
      borderColor: primaryColor[0]
    },
    '& + p': {
      fontWeight: '300'
    }
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor[0]
    }
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor[0]
    }
  },
  labelRoot: {
    ...defaultFont,
    color: `${grayColor[3]} !important`,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    top: '10px',
    letterSpacing: 'unset',
    '& + $underline': {
      marginTop: '0px'
    }
  },
  labelRootError: {
    color: `${dangerColor[0]} !important`
  },
  labelRootSuccess: {
    color: `${successColor[0]} !important`
  },
  formControl: {
    paddingTop: '14px',
    marginTop: '1px',
    position: 'relative',
    verticalAlign: 'unset',
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: grayColor[14]
    }
  },
  whiteUnderline: {
    '&:hover:not($disabled):before,&:before': {
      backgroundColor: whiteColor
    },
    '&:after': {
      backgroundColor: whiteColor
    }
  },
  input: {
    color: grayColor[14],
    '&,&::placeholder': {
      fontSize: '1rem',
      fontFamily: window.safari ? [
        'Roboto',
        'sans-serif'
      ].join(',') : [
        'Siemreap',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1'
    },
    '&::placeholder': {
      color: grayColor[3]
    }
  },
  whiteInput: {
    '&,&::placeholder': {
      color: whiteColor,
      opacity: '1'
    }
  }
};

export default customInputStyle;
