import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ThemeButton from 'components/theme/CustomButtons/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';
import settings from 'settings';
import {
  downloadTemplate,
  importAttendance,
  resetImportAttendanceStates,
  getAttendances
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import dialogInputStyle
  from '../../assets/sts/jss/views/enrollment/dialogInputStyle';
import { getTranslate } from 'react-localize-redux';
import * as moment from 'moment';
import ImportAttendanceDetail from 'views/Attendance/ImportAttendanceDetail';

const useStyles = makeStyles(dialogInputStyle);
export default function ImportAttendanceButton (props) {
  const {
    rows
  } = props;

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const attendanceFilter = useSelector(state => state.attendance.attendanceFilter);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const isImporting = useSelector(state => state.attendance.isImporting);
  const isImported = useSelector(state => state.attendance.isImported);
  const importedResult = useSelector(state => state.attendance.importedResult);
  const dialogStyle = useStyles();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isAgreeToImport, setIsAgreeToImport] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const inputFile = useRef(null);
  const [brokenRecords, setBrokenRecords] = useState([]);
  const [totalGoodRecords, setTotalGoodRecords] = useState(0);
  const [missingFields, setMissingFields] = useState([]);
  const [noMatchedFields, setNoMatchedFields] = useState([]);
  const [missingRowNumber, setMissingRowNumber] = useState(0);
  const [wrongTemplate, setWrongTemplate] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(importedResult).length) {
      setBrokenRecords(importedResult.brokenRecords || []);
      setTotalGoodRecords(importedResult.totalGoodRecords || 0);
      setMissingFields(importedResult.missingFields || []);
      setNoMatchedFields(importedResult.notMatchedFields || []);
      setMissingRowNumber(importedResult.missingRowNumber || 0);
      setWrongTemplate(importedResult.wrongTemplate || false);
    }
  }, [importedResult]);

  const onBrowseClickHandler = () => {
    inputFile.current.click();
  };

  const onChangeFileHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const importFile = event.target.files[0];
    if (importFile) {
      setFile(importFile);
      setFileName(importFile.name);
      setIsOpenDialog(true);
      event.target.value = '';
    }
  };

  const handleOnClickDownloadTemplate = (e) => {
    e.preventDefault();
    const selectedAcademicYear = academicYears.find(academic => academic.id === attendanceFilter.academicYear);
    const academicStartDate = moment(selectedAcademicYear.start_date, settings.dateFormat);
    const academicEndDate = moment(selectedAcademicYear.end_date, settings.dateFormat);
    const classroomObj = classroomList.find(c => c.id === attendanceFilter.classroomId);
    const templateName = academicStartDate.year() + '-' + academicEndDate.year() + '_' +
      classroomObj.grade_name + classroomObj.suffix + '_' + moment().format('YYYYMMDD');
    const filterParams = {
      type: 'attendance',
      classroomId: attendanceFilter.classroomId
    };
    dispatch(downloadTemplate(filterParams, templateName));
  };

  const onCloseDialog = () => {
    if (isImported && !missingFields.length && !noMatchedFields.length && !missingRowNumber && !wrongTemplate) {
      dispatch(getAttendances(attendanceFilter));
    }
    setIsOpenDialog(false);
    setFile(null);
    setIsAgreeToImport(false);
    dispatch(resetImportAttendanceStates());
  };

  const onConfirmImport = () => {
    const data = {
      file: file,
      classroomId: attendanceFilter.classroomId
    };
    dispatch(importAttendance(data));
    setFile(null);
  };

  return (
    <>
      {/* import dialog */}
      <Dialog
        open={isOpenDialog}
        className={dialogStyle.dialogHelp}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
      >
        <DialogTitle>
          <div className={dialogStyle.secondGrid}>
            <h6 className={dialogStyle.confirmDialogHeader}>
              {translate('common.import')}
            </h6>
          </div>
        </DialogTitle>
        <DialogContent className={dialogStyle.dialogContent}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText className={dialogStyle.listItemText} primary={fileName}
              />
            </ListItem>
          </List>
          {
            !isImporting && !isImported && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => { setIsAgreeToImport(e.target.checked); }}
                    name="checkedImportAgreement"
                    color="primary"
                  />
                }
                label={translate('attendance.importAgreement')}
              />
            )
          }

          {
            isImporting && (
              <LinearProgress />
            )
          }
          {
            isImported && (
              <ImportAttendanceDetail
                brokenRecords={brokenRecords}
                totalGoodRecords={totalGoodRecords}
                missingFields={missingFields}
                noMatchedFields={noMatchedFields}
                missingRowNumber={missingRowNumber}
                wrongTemplate={wrongTemplate}
              />
            )
          }
          <Divider />

        </DialogContent>

        <DialogActions
          className={dialogStyle.dialogAction}
        >
          <div className={dialogStyle.normalSaveClose}>
            <ThemeButton
              variant="contained"
              className={dialogStyle.buttonDefault}
              onClick={onCloseDialog}
              disabled={isImporting}
            >
              {translate('common.button.close')}
            </ThemeButton>
            {
              !isImported && !isImporting && (
                <ThemeButton
                  color="primary"
                  variant="contained"
                  onClick={onConfirmImport}
                  disabled={!isAgreeToImport}
                >
                  {translate('common.button.confirm')}
                </ThemeButton>
              )
            }
          </div>
        </DialogActions>
      </Dialog>
      {/* end import dialog */}
      <input
        id="myInput"
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xlsx, .xls"
        onChange={onChangeFileHandler}
      />
      <ThemeButton
        color = "primary"
        type = "submit"
        onClick={onBrowseClickHandler}
        disabled={rows.length === 0}
      >
        {translate('common.import')}
      </ThemeButton>
      <FormHelperText>
        {translate('common.templateHelpText')}&nbsp;
        <Link href="#" onClick={handleOnClickDownloadTemplate}>
          {translate('common.download')}
        </Link>
      </FormHelperText>
    </>
  );
}

ImportAttendanceButton.propTypes = {
  rows: PropTypes.array
};
