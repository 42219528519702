import {
  cardTitle
} from 'assets/theme/jss/material-dashboard-pro-react.js';

import {
  formBackgroundColor,
  formBorderColor,
  secondaryButton
} from 'assets/sts/jss/sts-react';

import buttonStyle from 'assets/theme/jss/material-dashboard-pro-react/components/buttonStyle';
import customCheckboxRadioSwitch from 'assets/theme/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const defaultFormStyle = (theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  gridContainerStyle: {
    margin: '10px 20px'
  },
  submitButton: {
    float: 'right'
  },
  cancelButton: {
    backgroundColor: secondaryButton,
    marginRight: '20px'
  },
  innerGridContainer: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  addressGrid: {
    margin: '10px',
    borderWidth: '1px',
    borderColor: formBorderColor,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    borderRadius: '3px',
    backgroundColor: formBackgroundColor
  },
  ...buttonStyle,
  ...customCheckboxRadioSwitch
});

export default defaultFormStyle;
