import _ from 'lodash';
import { INDICATOR_TEMPLATES } from 'variables/indicator';

export const setDefaultParamValue = (indicator) => {
  const template = _.find(INDICATOR_TEMPLATES, { key: indicator.template });
  template.params.forEach(param => {
    if (param.type === 'SELECT' && param.options) {
      indicator.params[param.name] = param.options[0].value;
    } else if (param.type === 'NUMBER') {
      indicator.params[param.name] = param.defaultValue;
    }
  });
};
