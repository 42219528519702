
const reportLoadingStyle = () => ({
  barStyle: {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    top: '50%',
    left: '0px',
    margin: '0 auto',
    width: '300px',
    backgroundColor: '#ffffff'
  },
  loadingStyle: {
    position: 'fixed',
    zIndex: 1,
    paddingTop: '100px',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.3)'
  }
});

export default reportLoadingStyle;
