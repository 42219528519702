import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Input from 'components/common/Input/Input';
import { getTranslate } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { useSelector } from 'react-redux';

export default function AddNewPopOver (props) {
  const {
    classes,
    searchStsId,
    handleSearch,
    onChangeHandler
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isSearching = useSelector(state => state.enrollment.isSearching);

  return (
    <div
      className={classes.popoverRoot}
    >
      <Grid
        container
        spacing={10}
      >
        <Grid
          item
          xs={12}
          style={{ marginTop: '10px', marginRight: '10px', marginLeft: '10px' }}
        >
          <Input
            label={translate('common.stsId')}
            placeholder={translate('enrollment.placeholder.searchStsId')}
            onChange={e => onChangeHandler(e, 'searchStsId')}
            value={searchStsId}
            name="searchStsId"
          />
        </Grid>
      </Grid>

      <div className={classes.buttonContainer}>
        <ThemeButton
          color="primary"
          onClick={handleSearch}
          variant={'contained'}
          style={{ marginRight: '8px', float: 'right' }}
          disabled={isSearching}
        >
          {translate('common.button.search')}
        </ThemeButton>
      </div>
    </div>
  );
}

AddNewPopOver.propTypes = {
  classes: PropTypes.instanceOf(Object),
  searchStsId: PropTypes.string,
  handleSearch: PropTypes.func,
  onChangeHandler: PropTypes.func
};
