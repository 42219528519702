import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import KeyIcon from '@material-ui/icons/VpnKey';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';

import {
  createEditAcc,
  getAcc
} from 'redux/actions';
import * as ROUTES from 'variables/routeNames';

import styles from 'assets/sts/jss/views/userManagement/createEditUserStyle';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';

const useStyles = makeStyles(styles);
const accDetail = {
  username: '',
  password: '',
  confirmPassword: '',
  isPasswordTemporary: false,
  firstName: '',
  lastName: '',
  firstNameEn: '',
  lastNameEn: '',
  gender: '',
  dob: ''
};

const CreateEditStudentAccount = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const studentAccId = props.match.params.studentAccId;
  const isLoading = useSelector(state => state.studentAccount.isLoading);
  const account = useSelector(state => state.studentAccount.account);

  // const authProfile = useSelector(state => state.auth.profile);

  const [studentAcc, setStudentAcc] = useState(accDetail);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorConfirmMessage, setErrorConfirmMessage] = useState('');

  // set title
  useEffect(() => {
    document.title = `${translate('studentAccountManagement.edit')} - STS`;
    document.getElementById('brandText').innerHTML = translate('studentAccountManagement.title');
  }, [translate]);

  const fetchStudentAcc = useCallback(() => {
    if (studentAccId) {
      dispatch(getAcc(studentAccId));
    }
  }, [dispatch, studentAccId]);

  useEffect(
    () => {
      if (!isLoading) {
        fetchStudentAcc();
      }
    },
    // eslint-disable-next-line
    [fetchStudentAcc]);

  useEffect(() => {
    if (studentAccId && account) {
      setStudentAcc({
        ...studentAcc,
        username: account.username,
        firstName: account.first_name,
        lastName: account.last_name,
        firstNameEn: account.first_name_en || '',
        lastNameEn: account.last_name_en || '',
        gender: account.gender,
        dob: account.birth_date
      });
    }
    // eslint-disable-next-line
  }, [account, studentAccId]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setStudentAcc({ ...studentAcc, [name]: value });
  };

  const handleEdit = e => {
    e.preventDefault();
    let isCanSave = true;
    if (studentAcc.password) {
      if (studentAcc.password.length < 6) {
        setErrorPassword(true);
        isCanSave = false;
      } else {
        setErrorPassword(false);
        if (!studentAcc.confirmPassword) {
          setErrorConfirmPassword(true);
          setErrorConfirmMessage(translate('common.error.require'));
          isCanSave = false;
        } else {
          if (studentAcc.password !== studentAcc.confirmPassword) {
            setErrorConfirmPassword(true);
            setErrorConfirmMessage(translate('common.error.confirmPassword'));
            isCanSave = false;
          } else {
            setErrorConfirmPassword(false);
            setErrorConfirmMessage('');
          }
        }
      }
    }
    if (isCanSave) {
      const data = {
        sts_id: studentAcc.username,
        password: studentAcc.password,
        is_password_temporary: studentAcc.isPasswordTemporary
      };
      dispatch(createEditAcc(data, studentAcc.username));
    }
  };

  const form = (
    <Card>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <KeyIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate('studentAccountManagement.edit')}</h4>
      </CardHeader>
      <CardBody>
        <form
          className={classes.gridContainerStyle}
        >
          <GridContainer>

            <GridItem
              xs={12}
              md={12}
              lg={12}
              xl={12}
            >

              <Typography
                variant="body1"
                classes={{ body1: classes.keyboardSwitch }}
              >
                {translate('common.switchToEn')}
              </Typography>

            </GridItem>

            <GridItem
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className={
                classes.addressGrid
              }
            >

              <GridContainer
                className={
                  classes.innerGridContainer
                }
              >
                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Input
                    label={translate('userManagement.username')}
                    placeholder={translate('userManagement.placeholder.username')}
                    value={studentAcc.username}
                    name="username"
                    autoComplete="off"
                    disabled
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Input
                    label={translate('userManagement.password')}
                    placeholder={translate('userManagement.placeholder.password')}
                    value={studentAcc.password}
                    name="password"
                    onChange={handleInputChange}
                    type="password"
                    autoComplete="off"
                    error={errorPassword}
                    helperText={translate('common.error.password6')}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Input
                    label={translate('userManagement.confirmPassword')}
                    placeholder={translate('userManagement.placeholder.confirmPassword')}
                    value={studentAcc.confirmPassword}
                    name="confirmPassword"
                    onChange={handleInputChange}
                    type="password"
                    autoComplete="off"
                    error={errorConfirmPassword}
                    helperText={errorConfirmMessage}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <FormControlLabel
                    className={classes.passwordTempCheckBox}
                    control={
                      <Checkbox
                        checked={studentAcc.isPasswordTemporary}
                        onChange={(e) => setStudentAcc({ ...studentAcc, isPasswordTemporary: e.target.checked })}
                        name="isPasswordTemporary"
                        classes={{
                          checked: classes.checkBoxPass
                        }}
                      />
                    }
                    label={translate('userManagement.temporaryPassword')}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Input
                label={translate('common.lastName')}
                placeholder={translate('common.placeholder.lastName')}
                value={studentAcc.lastName}
                name="lastName"
                disabled
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Input
                label={translate('common.firstName')}
                placeholder={translate('common.placeholder.firstName')}
                value={studentAcc.firstName}
                name="firstName"
                disabled
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Input
                label={translate('student.tabProfile.lastNameEN')}
                value={studentAcc.lastNameEn}
                name="lastNameEn"
                disabled
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Input
                label={translate('student.tabProfile.firstNameEN')}
                value={studentAcc.firstNameEn}
                name="firstNameEn"
                disabled
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Select
                require
                label={translate('common.gender')}
                placeholder={translate('common.placeholder.gender')}
                value={studentAcc.gender}
                name="gender"
                choices={[
                  { label: translate('common.genderType.f'), value: 'f' },
                  { label: translate('common.genderType.m'), value: 'm' }
                ]}
                disabled
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <DateTimePicker
                label={translate('enrollment.birthDate')}
                name="birthDate"
                value={studentAcc.dob}
                timeFormat={false}
                placeholder={translate('enrollment.placeholder.birthDate')}
                disabled
              />
            </GridItem>

          </GridContainer>

          <div
            className={classes.submitButton}
          >
            <Button
              className={classes.cancelButton}
              onClick={() => history.push(ROUTES.STUDENT)}
            >
              {translate('common.button.cancel')}
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleEdit}
            >
              {translate('common.button.save')}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : form}
    </div>
  );
};

CreateEditStudentAccount.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default CreateEditStudentAccount;
