export const GET_ATTENDANCE_LIST_REQUEST = 'GET_ATTENDANCE_LIST_REQUEST';
export const GET_ATTENDANCE_LIST_SUCCESS = 'GET_ATTENDANCE_LIST_SUCCESS';
export const GET_ATTENDANCE_LIST_FAIL = 'GET_ATTENDANCE_LIST_FAIL';

export const SAVE_ALL_ATTENDANCES_REQUEST = 'SAVE_ALL_ATTENDANCES_REQUEST';
export const SAVE_ALL_ATTENDANCES_SUCCESS = 'SAVE_ALL_ATTENDANCES_SUCCESS';
export const SAVE_ALL_ATTENDANCES_FAIL = 'SAVE_ALL_ATTENDANCES_FAIL';

export const IMPORT_STUDENT_ATTENDANCE_SUCCESS = 'IMPORT_STUDENT_ATTENDANCE_SUCCESS';
export const IMPORT_STUDENT_ATTENDANCE_REQUEST = 'IMPORT_STUDENT_ATTENDANCE_REQUEST';
export const IMPORT_STUDENT_ATTENDANCE_FAIL = 'IMPORT_STUDENT_ATTENDANCE_FAIL';
export const RESET_IMPORT_ATTENDANCE_STATES_REQUEST = 'RESET_IMPORT_ATTENDANCE_STATES_REQUEST';
