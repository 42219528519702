import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';
import {
  formBorderColor,
  formBackgroundColor
} from 'assets/sts/jss/sts-react';

import {
  primaryColor,
  blackColor,
  hexToRgb, goldColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const createEditStudentStyle = (theme) => ({
  ...defaultFormStyle(theme),
  boxTitle: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  boxGrid: {
    margin: '10px 10px 15px 10px',
    paddingTop: '15px !important',
    borderWidth: '1px',
    borderColor: formBorderColor,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    borderRadius: '3px',
    backgroundColor: formBackgroundColor
  },
  radio: {
    color: primaryColor[0] + '!important'
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid ' + primaryColor[0],
    borderRadius: '50%'
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '50%'
  },
  checked: {
    color: primaryColor[0] + '!important'
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '3px'
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '3px'
  },
  physicalDisabilityGridContainer: {
    paddingLeft: '50px !important',
    paddingTop: '15px !important'
  },
  toolbar: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  dialogContent: {
    overflow: 'auto'
  },
  dialogHelp: {
    minWidth: '800px'
  },
  avatar: {
    background: 'none'
  },
  avatarIcon: {
    width: 46,
    height: 46,
    color: goldColor[0]
  },
  firstGrid: {
    width: 55,
    float: 'left',
    display: 'inline-block'
  },
  secondGrid: {
    float: 'left'
  },
  dialogHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: window.safari ? 'Roboto,sans-serif' : 'Roboto,Siemreap,sans-serif',
    fontWeight: 400,
    margin: 0
  },
  dialogDescription: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'normal',
    margin: 0
  },
  toggleStyle: {
    marginTop: '35px'
  },
  photoContainer: {
    height: 200,
    width: 150
  }
});

export default createEditStudentStyle;
