import * as actionTypes from 'redux/actionTypes/notification';
import {
  closeNotification
} from 'redux/actions/notification';

export const initialState = {
  open: false,
  autoHideDuration: actionTypes.DEFAULT_DURATION,
  actionNode: '',
  messageNode: '',
  messageParams: {},
  actionTouchTapCallback: closeNotification,
  requestCloseCallback: closeNotification,
  color: 'black'
};

const openNotification = (state) => ({
  ...state,
  open: true
});

const closeNotificationState = (state) => ({
  ...state,
  open: false
});

const setColor = (state, action) => ({
  ...state,
  color: action.color
});

const setNotificationMessageState = (state, action) => ({
  ...state,
  messageNode: action.messageNode,
  messageParams: action.messageParams
});

const setActionMessage = (state, action) => ({
  ...state,
  actionNode: action.actionNode
});

const setActionTouchTapCallBack = (state, action) => ({
  ...state,
  actionTouchTapCallback: action.actionTouchTapCallback
});

const setRequestCloseCallbackState = (state, action) => ({
  ...state,
  requestCloseCallback: action.requestCloseCallback
});

const setAutoHideDurationState = (state, action) => ({
  ...state,
  autoHideDuration: action.autoHideDuration
});

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_NOTIFICATION:
      return openNotification(state);
    case actionTypes.CLOSE_NOTIFICATION:
      return closeNotificationState(state);
    case actionTypes.SET_COLOR:
      return setColor(state, action);
    case actionTypes.SET_NOTIFICATION_MESSAGE:
      return setNotificationMessageState(state, action);
    case actionTypes.SET_ACTION_MESSAGE:
      return setActionMessage(state, action);
    case actionTypes.SET_ACTION_TOUCH_TAP_CALLBACK:
      return setActionTouchTapCallBack(state, action);
    case actionTypes.SET_REQUEST_CLOSE_CALLBACK:
      return setRequestCloseCallbackState(state, action);
    case actionTypes.SET_AUTO_HIDE_DURATION:
      return setAutoHideDurationState(state, action);
    default:
      return state;
  }
}
