import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getScholarshipFundingListRequest () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_REQUEST
  };
}

function getScholarshipFundingListSuccess (scholarshipFundingList, scholarshipFundingFilter) {
  return {
    type: actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_SUCCESS,
    scholarshipFundingList,
    scholarshipFundingFilter
  };
}

function getScholarshipFundingListFail () {
  return {
    type: actionTypes.GET_SCHOLARSHIP_FUNDING_LIST_FAIL
  };
}

function addScholarshipFundingRequest () {
  return {
    type: actionTypes.ADD_SCHOLARSHIP_FUNDING_REQUEST
  };
}

function addScholarshipFundingSuccess () {
  return {
    type: actionTypes.ADD_SCHOLARSHIP_FUNDING_SUCCESS
  };
}

function addScholarshipFundingFail () {
  return {
    type: actionTypes.ADD_SCHOLARSHIP_FUNDING_FAIL
  };
}

function updateScholarshipFundingRequest () {
  return {
    type: actionTypes.UPDATE_SCHOLARSHIP_FUNDING_REQUEST
  };
}

function updateScholarshipFundingSuccess () {
  return {
    type: actionTypes.UPDATE_SCHOLARSHIP_FUNDING_SUCCESS
  };
}

function updateScholarshipFundingFail () {
  return {
    type: actionTypes.UPDATE_SCHOLARSHIP_FUNDING_FAIL
  };
}

function deleteScholarshipFundingRequest () {
  return {
    type: actionTypes.DELETE_SCHOLARSHIP_FUNDING_REQUEST
  };
}

function deleteScholarshipFundingSuccess () {
  return {
    type: actionTypes.DELETE_SCHOLARSHIP_FUNDING_SUCCESS
  };
}

function deleteScholarshipFundingFail () {
  return {
    type: actionTypes.DELETE_SCHOLARSHIP_FUNDING_FAIL
  };
}

export const getScholarshipFundingList = (rawParams) => {
  return (dispatch) => {
    const params = {
      school_id: rawParams.school,
      page: rawParams.page + 1,
      limit: rawParams.limit
    };

    dispatch(getScholarshipFundingListRequest());
    axios.get('/scholarship_funding', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          dispatch(getScholarshipFundingListSuccess(data, rawParams));
        }
      )
      .catch(e => {
        dispatch(getScholarshipFundingListFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const addScholarshipFunding = (data) => {
  return async (dispatch, getState) => {
    dispatch(addScholarshipFundingRequest());
    data = await axios.post('/scholarship_funding', data)
      .then(
        () => {
          dispatch(addScholarshipFundingSuccess());
          const filter = getState().scholarshipFunding.scholarshipFundingFilter;
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipFunding.addSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(addScholarshipFundingFail());
        dispatch(showErrorNotification('scholarshipFunding.addError'));
      });
    return data;
  };
};

export const updateScholarshipFunding = (id, data) => {
  return async (dispatch, getState) => {
    dispatch(updateScholarshipFundingRequest());
    data = await axios.put(`/scholarship_funding/${id}`, data)
      .then(
        () => {
          const filter = getState().scholarshipFunding.scholarshipFundingFilter;
          dispatch(updateScholarshipFundingSuccess());
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipFunding.updateSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(updateScholarshipFundingFail());
        dispatch(showErrorNotification('scholarshipFunding.updateError'));
      });
    return data;
  };
};

export const deleteScholarshipFunding = (id) => {
  return (dispatch, getState) => {
    dispatch(deleteScholarshipFundingRequest());
    axios.delete(`/scholarship_funding/${id}`)
      .then(
        () => {
          const filter = getState().scholarshipFunding.scholarshipFundingFilter;
          dispatch(deleteScholarshipFundingSuccess());
          dispatch(getScholarshipFundingList(filter));
          dispatch(showSuccessNotification('scholarshipFunding.deleteSuccess'));
          return true;
        }
      )
      .catch(() => {
        dispatch(deleteScholarshipFundingFail());
        dispatch(showErrorNotification('scholarshipFunding.deleteError'));
      });
  };
};
