import { useDispatch, useSelector } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import {
  getMonthlyScores,
  getAnnualScores,
  getSubjects,
  resetScore
} from 'redux/actions/score';
import React, { useCallback, useState, useEffect } from 'react';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Card from 'components/theme/Card/Card';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import CardBody from 'components/theme/Card/CardBody';
import ScoreIcon from '@material-ui/icons/Spellcheck';
import Select from 'components/common/Select/Select';
import ThemeButton from 'components/theme/CustomButtons/Button';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import settings from 'settings';
import * as moment from 'moment';
import {
  getAcademicYears,
  getSchoolList,
  getClassroomListForFilter,
  getStudentOptions
} from 'redux/actions';
import { getSchoolName } from 'utilities/helpers';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as ROUTES from 'variables/routeNames';
import * as SCORE from 'variables/score';
import { groups as GROUPS } from 'variables/user';
import history from 'utilities/history';
import { Link } from 'react-router-dom';

export default function ScoreFilter (props) {
  const {
    classes,
    location
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);

  const authProfile = useSelector(state => state.auth.profile);
  const academicYearId = queryString.parse(location.search).academicYear;
  const schoolId = queryString.parse(location.search).school;
  const gradeValue = queryString.parse(location.search).grade;
  const suffixValue = queryString.parse(location.search).suffix;
  const studentId = queryString.parse(location.search).studentId;
  const currentSchool = useSelector((state) => state.school.school);
  const allGrades = useSelector(state => state.studentOption.grade);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const [months, setMonths] = useState([]);
  const scoreFilter = useSelector(state => state.score.scoreFilter);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const [isSearching, setIsSearching] = useState(false);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);

  const [filterParams, setFilterParams] = useState({
    academicYear: academicYearId !== undefined ? parseInt(academicYearId) : scoreFilter?.academicYear || '',
    school: schoolId !== undefined ? parseInt(schoolId) : scoreFilter?.school || '',
    grade: gradeValue !== undefined ? parseInt(gradeValue) : scoreFilter?.grade || '',
    suffix: suffixValue !== undefined ? suffixValue : scoreFilter?.suffix || '',
    month: suffixValue !== undefined ? '' : scoreFilter?.month || ''
  });
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);
  const [errorSchool, setErrorSchool] = useState(false);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorSuffix, setErrorSuffix] = useState(false);
  const [errorMonth, setErrorMonth] = useState(false);

  // user role restriction
  useEffect(() => {
    if (authProfile && currentSchool && schoolId) {
      if ((authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER) && authProfile.school.emis_code !== currentSchool.emis_code) {
        history.push(ROUTES.HOME);
      }

      if (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        if (authProfile.province.code !== currentSchool.province.code) {
          history.push(ROUTES.HOME);
        }
      }

      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER) {
        if (authProfile.district.code !== currentSchool.district.code) {
          history.push(ROUTES.HOME);
        }
      }
    }
  }, [schoolId, currentSchool, authProfile]);

  // fetch Academic Years
  useEffect(() => {
    dispatch(getAcademicYears());
    dispatch(getStudentOptions());
  }, [dispatch]);

  // set academic year choices, and set default selected
  useEffect(() => {
    if (academicYears.length > 0) {
      let defaultRunningAcademicYearId = null;
      const choices = academicYears.filter(academic => [ACADEMIC_STATUS.RUNNING, ACADEMIC_STATUS.ARCHIVED, ACADEMIC_STATUS.POST].includes(academic.status))
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          if (academic.status === ACADEMIC_STATUS.RUNNING) {
            defaultRunningAcademicYearId = academic.id;
          }
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choices);

      // set default selected academicYear
      if (choices.length > 0 && filterParams.academicYear === '') {
        setFilterParams({
          ...filterParams,
          academicYear: defaultRunningAcademicYearId || choices[0].value
        });
      }

      const defaultMonths = SCORE.months.map(month => {
        return {
          value: month.value,
          label: translate('common.month.' + month.label)
        };
      });

      const additionalMonths = [
        {
          value: 13,
          label: translate('common.semester1')
        },
        {
          value: 14,
          label: translate('common.semester2')
        },
        {
          value: SCORE.ANNUAL_SCORE_OPTION,
          label: translate('common.annual')
        }
      ];

      setMonths([...defaultMonths, ...additionalMonths]);
    }
  }, [academicYears, translate, filterParams]);

  // fetch schools by academic year
  useEffect(() => {
    if (filterParams.academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      const params = isManagementOrTeacher
        ? {
          academic_year_id: filterParams.academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: filterParams.academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, filterParams.academicYear]);

  // set school choices
  useEffect(() => {
    if (filterParams.academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school),
          order: 'ASC',
          limit: 9999
        };
      });
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
    }
    // eslint-disable-next-line
  }, [filterParams.academicYear, schoolList, localize, translate, authProfile]);

  useEffect(() => {
    // set default selected school if there is only one
    if (schoolChoices.length === 1) {
      setFilterParams({
        ...filterParams,
        school: schoolChoices[0].value
      });
    }
    // eslint-disable-next-line
  }, [schoolChoices]);

  // fetch classes by school
  useEffect(() => {
    if (filterParams.school) {
      const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
      const param = {
        school: filterParams.school
      };
      if (isTeacher) {
        param.teacher_staff_id = authProfile.userable_id;
      }
      dispatch(getClassroomListForFilter(param, authProfile));
    }
  }, [dispatch, filterParams.school, authProfile]);

  // set grade choices
  useEffect(() => {
    if (filterParams.school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          if (grade !== undefined) {
            choices.push({
              value: classroom.grade,
              label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
            });
          }
        }
      });
      setGradeChoices(choices);

      // set default selected grade if there is only one
      if (choices.length === 1) {
        setFilterParams({
          ...filterParams,
          grade: choices[0].value
        });
      }
    } else {
      setGradeChoices([]);
    }
    // eslint-disable-next-line
  }, [filterParams.school, classroomList, allGrades, localize]);

  // set suffix choices
  useEffect(() => {
    if (filterParams.grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === filterParams.grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);

      // set default selected grade if there is only one
      if (choices.length === 1) {
        setFilterParams({
          ...filterParams,
          suffix: choices[0].value
        });
      }
    } else {
      setSuffixChoices([]);
    }
    // eslint-disable-next-line
  }, [filterParams.grade, classroomList]);

  const fetchScores = useCallback(() => {
    if (isSearching && filterParams.suffix !== '' && classroomList) {
      const classroom = classroomList.find(c => c.grade === filterParams.grade && c.suffix === filterParams.suffix);
      if (classroom) {
        dispatch(getSubjects(classroom.id));
        const params = {
          ...filterParams,
          classroomId: classroom.id,
          studentId: studentId
        };
        if (filterParams.month === SCORE.ANNUAL_SCORE_OPTION) {
          dispatch(getAnnualScores(params));
        } else {
          dispatch(getMonthlyScores(params));
        }
        setIsSearching(false);
      }
    }
  }, [dispatch, isSearching, classroomList, filterParams, studentId]);

  useEffect(() => {
    fetchScores();
  }, [fetchScores]);

  useEffect(() => {
    if (suffixValue !== undefined) {
      dispatch(resetScore());
    }
  }, [dispatch, suffixValue]);

  const onChangeHandler = e => {
    const { name, value } = e.target;
    setFilterParams({ ...filterParams, [name]: value });
  };

  const handleFilterScore = e => {
    e.preventDefault();
    let canFilter = true;
    if (filterParams.academicYear === '') {
      setErrorAcademicYear(true);
      canFilter = false;
    } else {
      setErrorAcademicYear(false);
    }

    if (filterParams.school === '') {
      setErrorSchool(true);
      canFilter = false;
    } else {
      setErrorSchool(false);
    }

    if (filterParams.grade === '') {
      setErrorGrade(true);
      canFilter = false;
    } else {
      setErrorGrade(false);
    }

    if (filterParams.suffix === '') {
      setErrorSuffix(true);
      canFilter = false;
    } else {
      setErrorSuffix(false);
    }

    if (filterParams.month === '') {
      setErrorMonth(true);
      canFilter = false;
    } else {
      setErrorMonth(false);
    }

    if (filterParams.grade !== '' && filterParams.suffix !== '') {
      const classroom = classroomList.find(c => c.grade === filterParams.grade && c.suffix === filterParams.suffix);
      if (!classroom) {
        setErrorGrade(true);
        setErrorSuffix(true);
        canFilter = false;
      }
    }

    if (canFilter) {
      setIsSearching(canFilter);
    }
  };

  return (
    <LoadablePaper
      rendered
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <ScoreIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('score.title')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={filterParams.academicYear}
                    name="academicYear"
                    onChange={e => onChangeHandler(e, 'academicYear')}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0 || studentId}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={filterParams.school}
                    name="school"
                    onChange={onChangeHandler}
                    choices={schoolChoices}
                    disabled={schoolChoices.length === 0 || managementOrTeacher || studentId}
                    require
                    error={errorSchool}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={filterParams.grade}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={gradeChoices}
                    disabled={gradeChoices.length === 0 || studentId}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('graduation.placeholder.suffix')}
                    value={filterParams.suffix}
                    name="suffix"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0 || studentId}
                    require
                    error={errorSuffix}
                    helperText={errorSuffix ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('score.monthOrSemester')}
                    placeholder={translate('graduation.placeholder.result')}
                    value={filterParams.month}
                    name="month"
                    onChange={e => onChangeHandler(e, 'month')}
                    choices={months}
                    disabled={months.length === 0}
                    require
                    error={errorMonth}
                    helperText={errorMonth ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton + ' ' + classes.textRight}
              >
                {studentId !== undefined && (
                  <ThemeButton
                    component={Link}
                    to={ROUTES.SCORE_MANAGEMENT}
                  >
                    {translate('common.button.reset')}
                  </ThemeButton>
                )
                }
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilterScore}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

ScoreFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  location: PropTypes.any
};
