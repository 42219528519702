import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ScoreFilter from 'views/Score/Patials/ScoreFilter/ScoreFilter';
import ScoreTable from 'views/Score/Patials/ScoreTable/ScoreTable';
import { makeStyles } from '@material-ui/core';
import styles from 'assets/sts/jss/views/score/scoreStyle';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const Score = (props) => {
  const classes = useStyles();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const { location } = props;

  // set title
  useEffect(() => {
    document.title = `${translate('score.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('score.title');
  }, [translate]);

  return (
    <>
      <ScoreFilter
        classes={classes}
        location={location}
      />
      <ScoreTable
        classes={classes}
        location={location}
      />
    </>
  );
};

Score.propTypes = {
  location: PropTypes.any
};

export default Score;
