import { groups as GROUPS } from 'variables/user';

export const superDataUserGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.SUPER_DATA_USER;
};

export const dataUserGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.DATA_USER;
};

export const provincialOfficerGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER;
};

export const districtOfficerGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.DISTRICT_OFFICER;
};

export const schoolManagementGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT;
};

export const teacherGroup = (authProfile) => {
  return authProfile && authProfile.groups[0] === GROUPS.TEACHER;
};
