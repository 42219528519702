import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardHeader from 'components/theme/Card/CardHeader';

import styles from 'assets/sts/jss/views/dataQuery/dataQueryStyle';
import { download, getExportHistoryList } from 'redux/actions';

const useStyles = makeStyles(styles);

const columnExtensions = [
  { columnName: 'name', width: 350 }
];

const ExportHistoryCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const { exportHistoryList } = useSelector((state) => state.exportHistory);

  useEffect(() => {
    dispatch(getExportHistoryList());
  }, [dispatch]);

  return (
    <>
      <Card>
        <CardHeader color="gold" icon>
          <h4 className={classes.cardIconTitle}>{translate('managementReport.downloadHistory')}</h4>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          {
            exportHistoryList.length === 0 ? (
              <p>{translate('common.noData')}</p>
            ) : (
              <Grid
                columns={[
                  { name: 'name', title: translate('managementReport.report') },
                  { name: 'created_at', title: translate('managementReport.download.created_at') },
                  { name: 'progress', title: translate('managementReport.download.progress') },
                  { name: 'status', title: translate('managementReport.download.status') },
                  { name: 'result', title: translate('managementReport.download.result') },
                  { name: 'action', title: translate('common.download') }
                ]}
                rows={
                  exportHistoryList.map(exportHistory => {
                    const action = exportHistory.result === 'success' && (
                      <IconButton component="span" onClick={() => dispatch(download(exportHistory))}>
                        <DownloadIcon />
                      </IconButton>
                    );
                    return {
                      name: exportHistory.name,
                      created_at: exportHistory.created_at,
                      progress: `${exportHistory.progress} %`,
                      status: translate(`managementReport.download.statuses.${exportHistory.status}`),
                      result: exportHistory.result && translate(`managementReport.download.results.${exportHistory.result}`),
                      action
                    };
                  })
                }
              >
                <Table columnExtensions={columnExtensions} />
                <TableHeaderRow />
              </Grid>
            )
          }
        </CardBody>
      </Card>
    </>
  );
};

export default ExportHistoryCard;
