import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import dialogInputStyle from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import LinearProgress from '@material-ui/core/LinearProgress';
import ImportEnrollmentDetail from 'views/Enrollment/Partial/ImportEnrollmentDetail/ImportEnrollmentDetail';
import Divider from '@material-ui/core/Divider';

import { importEnrollStudent, resetImportStates } from 'redux/actions';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as ENROLLMENT from 'variables/enrollment';

const useStyles = makeStyles(dialogInputStyle);

export default function ImportEnrollmentInput (props) {
  const {
    enrollmentDate
  } = props;

  const dialogStyle = useStyles();
  const dispatch = useDispatch();

  const isImporting = useSelector(state => state.enrollment.isImporting);
  const isImported = useSelector(state => state.enrollment.isImported);
  const importedResult = useSelector(state => state.enrollment.importedResult);
  const localize = useSelector((state) => state.localize);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [numberTotalRecord, setNumberTotalRecord] = useState(0);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [duplicatedRecords, setDuplicatedRecords] = useState([]);
  const [duplicatedRecordsInFile, setDuplicatedRecordsInFile] = useState([]);
  const inputFile = useRef(null);
  const translate = getTranslate(localize);

  useEffect(() => {
    if (Object.keys(importedResult).length) {
      setNumberTotalRecord(importedResult.numberTotalRecord || 0);
      setCount(importedResult.brokenRecords ? importedResult.brokenRecords.length : 0);
      setRows(importedResult.brokenRecords ? importedResult.brokenRecords : []);
      setDuplicatedRecords(importedResult.duplicatedRecords ? importedResult.duplicatedRecords : []);
      setDuplicatedRecordsInFile(importedResult.duplicatedRecordsInFile ? importedResult.duplicatedRecordsInFile : []);
    }
  }, [importedResult]);

  const onBrowseClickHandler = () => {
    inputFile.current.click();
  };

  const onChangeFileHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const importFile = event.target.files[0];
    if (importFile) {
      setFile(importFile);
      setFileName(importFile.name);
      setIsOpenDialog(true);
      event.target.value = '';
    }
  };

  const onCloseDialog = () => {
    setIsOpenDialog(false);
    setFile(null);
    dispatch(resetImportStates());
  };

  const onConfirmImport = () => {
    const data = {
      enrollmentDate,
      file: file
    };
    dispatch(importEnrollStudent(data, translate('enrollment.importSuccess')));
    setFile(null);
  };

  return (
    <LoadablePaper
      rendered
    >
      <LoadablePaperContent>
        <Dialog
          open={isOpenDialog}
          className={dialogStyle.dialogHelp}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
        >
          <DialogTitle>
            <div className={dialogStyle.secondGrid}>
              <h6 className={dialogStyle.confirmDialogHeader}>
                {translate('common.import')}
              </h6>
            </div>
          </DialogTitle>
          <DialogContent className={dialogStyle.dialogContent}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText className={dialogStyle.listItemText}
                  primary={fileName}
                />
              </ListItem>
            </List>
            {
              isImporting && (
                <LinearProgress />
              )
            }
            <Divider />
            {
              isImported && (
                <ImportEnrollmentDetail
                  count={count}
                  rows={rows}
                  duplicatedRecords={duplicatedRecords}
                  duplicatedRecordsInFile={duplicatedRecordsInFile}
                  numberTotalRecord={numberTotalRecord}
                />
              )
            }
          </DialogContent>

          <DialogActions
            className={dialogStyle.dialogAction}
          >
            <div className={dialogStyle.normalSaveClose}>
              <ThemeButton
                variant="contained"
                className={dialogStyle.buttonDefault}
                onClick={onCloseDialog}
                disabled={isImporting}
              >
                {translate('common.button.close')}
              </ThemeButton>
              {
                !isImported && !isImporting && (
                  <ThemeButton
                    color="primary"
                    variant="contained"
                    onClick={onConfirmImport}
                  >
                    {translate('common.button.confirm')}
                  </ThemeButton>
                )
              }
            </div>
          </DialogActions>
        </Dialog>
        <input id="myInput"
          type="file"
          ref={inputFile}
          style={{ display: 'none' }}
          accept=".xlsx, .xls"
          onChange={onChangeFileHandler}
        />
        <ThemeButton
          color="primary"
          type="submit"
          onClick={onBrowseClickHandler}
        >
          {translate('common.import')}
        </ThemeButton>

        <FormHelperText >
          {translate('common.templateHelpText')}&nbsp;
          <a
            download
            href = {'enrollmentTemplate/' + ENROLLMENT.IMPORT_ENROLLMENT_TEMPLATE_FILE_NAME}
          >
            {translate('common.download')}
          </a>
        </FormHelperText>

      </LoadablePaperContent>
    </LoadablePaper>
  );
}

ImportEnrollmentInput.propTypes = {
  enrollmentDate: PropTypes.any
};
