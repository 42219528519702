export const transfer = {
  0: 'in',
  1: 'out'
};

export const status = {
  0: 'pending',
  1: 'completed'
};

export const unknownSchools = {
  PRIVATE_SCHOOL: -1,
  NONE_STS_SCHOOL: -2
};
