import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import SchoolIcon from '@material-ui/icons/AccountBalance';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import View from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';

import { Link } from 'react-router-dom';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Table from 'components/common/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import * as ROUTES from 'variables/routeNames';

import styles from 'assets/sts/jss/views/school/schoolStyle';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';
import * as VARIABLES from 'variables/school';
import * as ACADEMICYEARSTATUS from 'variables/academicYearStatus';
import { getProvinces, getDistricts, getCommunes, getVillages, clearUnits } from 'redux/actions/gazetteerCode';
import {
  getAcademicYears, getQueueStatus
} from 'redux/actions/academicYear';
import { getSchoolList } from 'redux/actions/school';
import { allChoices, getGazetteerChoices } from 'utilities/gazetteer';
import * as moment from 'moment';
import settings from 'settings';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import * as helpers from 'utilities/helpers';
import { useKeycloak } from '@react-keycloak/web';
import { roles as ROLES, groups as GROUPS } from 'variables/user';
import CreateNewButton from 'views/School/Partial/CreateNewButton';
import {
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup,
  teacherGroup
} from 'utilities/permission';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { QUEUE_STATUS } from '../../variables/academicYear';

const useStyles = makeStyles(styles);
const ALL = 'all';

const School = () => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const communes = useSelector((state) => state.gazetteerCode.communes);
  const villages = useSelector(state => state.gazetteerCode.villages);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const isLoading = useSelector(state => state.school.isLoading);
  const schoolList = useSelector(state => state.school.schoolList);
  const schoolFilter = useSelector(state => state.school.schoolFilter);
  const [keycloak] = useKeycloak();
  const authProfile = useSelector(state => state.auth.profile);

  const [province, setProvince] = useState(schoolFilter.province || ALL);
  const [district, setDistrict] = useState(schoolFilter.district || ALL);
  const [commune, setCommune] = useState(schoolFilter.commune || ALL);
  const [village, setVillage] = useState(schoolFilter.village || ALL);
  const [provinceChoices, setProvinceChoices] = useState(allChoices(translate));
  const [districtChoices, setDistrictChoices] = useState(allChoices(translate));
  const [communeChoices, setCommuneChoices] = useState(allChoices(translate));
  const [villageChoices, setVillageChoices] = useState(allChoices(translate));
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [academicYear, setAcademicYear] = useState(schoolFilter.academic_year || '');
  const [schoolStatus, setSchoolStatus] = useState(schoolFilter.status || 'all');
  const [accomplishmentStatus, setAccomplishmentStatus] = useState(schoolFilter.accomplishment_status || 'all');
  const [code, setCode] = useState(schoolFilter.emis_code || '');
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [isSearching, setIsSearching] = useState(true);
  const [schoolName, setSchoolName] = useState(schoolFilter.school_name || '');
  const [canCreateNew, setCanCreateNew] = useState(false);
  const [isDuplicateRecord, setIsDuplicateRecord] = useState(schoolFilter?.isDuplicateRecord || false);
  const [isCopyingSchool, setIsCopyingSchool] = useState(false);

  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);
  const isTeacher = teacherGroup(authProfile);
  const isManagementOrTeacher = isSchoolManagement || isTeacher;
  const disabledProvinceSelection = isProvincialOfficer || isDistrictOfficer || isManagementOrTeacher;
  const disabledDistrictSelection = isDistrictOfficer || isManagementOrTeacher;

  // set title
  useEffect(() => {
    document.title = `${translate('school.pageTitle')} - STS`;
    document.getElementById('brandText').innerHTML = translate('school.pageTitle');
  }, [translate]);

  // set default selection
  useEffect(() => {
    if (authProfile) {
      if (authProfile.province) {
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }
      if (authProfile.district) {
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
      if (authProfile.commune) {
        setCommune(`${authProfile.commune.id},${authProfile.commune.code}`);
      }
      if (authProfile.village) {
        setVillage(`${authProfile.village.id},${authProfile.village.code}`);
      }
      if (authProfile.school) {
        setCode(authProfile.school.emis_code);
      }
    }
  }, [authProfile]);

  // fetch Academic Years School
  useEffect(() => {
    dispatch(getAcademicYears());
  }, [dispatch]);

  useEffect(() => {
    if (academicYears.length > 0) {
      let academicYearId = academicYears[0].id;
      let isCheckId = false;
      const choice = academicYears.map(academic => {
        const academicStartDate = moment(academic.start_date,
          settings.dateFormat);
        const academicEndDate = moment(academic.end_date, settings.dateFormat);
        const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
        const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
        if (academic.status === ACADEMICYEARSTATUS.RUNNING && !isCheckId) {
          academicYearId = academic.id;
          isCheckId = true;
        }
        return {
          value: academic.id,
          label: studyYear
        };
      });
      setAcademicYearChoices(choice);
      setAcademicYear(academicYear || academicYearId);
    }
  // eslint-disable-next-line
  }, [academicYears, translate]);

  // fetch provinces
  useEffect(() => {
    dispatch(clearUnits());
    dispatch(getProvinces());
  }, [dispatch]);

  useEffect(() => {
    setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, provinces, translate));
  }, [provinces, localize, translate]);

  // fetch districts after province is selected
  useEffect(() => {
    dispatch(clearUnits());
    if (province !== ALL) {
      dispatch(getDistricts(province));
    }
  }, [dispatch, province]);

  useEffect(() => {
    setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
  }, [districts, localize, translate]);

  // fetch communes after district is selected
  useEffect(() => {
    dispatch(clearUnits('district'));
    if (district !== ALL) {
      dispatch(getCommunes(district));
    }
  }, [dispatch, district]);

  useEffect(() => {
    setCommuneChoices(getGazetteerChoices(getActiveLanguage(localize).code, communes, translate));
  }, [communes, localize, translate]);

  // fetch villages after commune is selected
  useEffect(() => {
    dispatch(clearUnits('commune'));
    if (commune !== ALL) {
      dispatch(getVillages(commune));
    }
  }, [dispatch, commune]);

  useEffect(() => {
    setVillageChoices(getGazetteerChoices(getActiveLanguage(localize).code, villages, translate));
  }, [villages, localize, translate]);

  // get school list
  // eslint-disable-next-line
  const setFetchSchoolListParams = () => {
    let gazetteer = province && province !== 'all' ? province.split(',')[1] : '';
    if (village && village !== 'all') {
      gazetteer = village.split(',')[1];
    } else if (commune && commune !== 'all') {
      gazetteer = commune.split(',')[1];
    } else if (district && district !== 'all') {
      gazetteer = district.split(',')[1];
    }
    const params = {
      status: schoolStatus !== 'all' ? schoolStatus : undefined,
      accomplishment_status: accomplishmentStatus !== 'all' ? accomplishmentStatus : undefined,
      duplicate_record: isDuplicateRecord || undefined,
      province,
      district,
      commune,
      village,
      page: page + 1,
      limit,
      order,
      orderBy
    };
    if (academicYear) {
      params.academic_year = academicYear;
    }
    if (gazetteer) {
      params.gazetteer = gazetteer;
    }
    if (code) {
      params.emis_code = code.trim();
    }

    if (schoolName) {
      params.school_name = schoolName.trim();
    }
    return params;
  };

  const fetchSchoolList = useCallback(() => {
    if (isSearching) {
      dispatch(getSchoolList(setFetchSchoolListParams()));
      setIsSearching(false);
    }
  }, [dispatch, setFetchSchoolListParams, isSearching]);

  useEffect(() => {
    if (!isLoading && academicYear) {
      fetchSchoolList();
    }
    // eslint-disable-next-line
  }, [fetchSchoolList, page, limit]);

  useEffect(() => {
    setCount(schoolList.total);
  }, [schoolList]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'province':
        setProvince(value);
        setDistrict(ALL);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'district':
        setDistrict(value);
        setCommune(ALL);
        setVillage(ALL);
        break;
      case 'commune':
        setCommune(value);
        setVillage(ALL);
        break;
      case 'village':
        setVillage(value);
        break;
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'schoolStatus':
        setSchoolStatus(value);
        break;
      case 'code':
        setCode(value);
        break;
      case 'schoolName':
        setSchoolName(value);
        break;
      case 'accomplishmentStatus':
        setAccomplishmentStatus(value);
        break;
      default:
    }
  };

  useEffect(() => {
    const academicYearObj = academicYears.find(obj => obj.id === schoolFilter.academic_year);
    if (academicYearObj && (
      academicYearObj.status === ACADEMICYEARSTATUS.DRAFT ||
      academicYearObj.status === ACADEMICYEARSTATUS.PRE
    )) {
      setCanCreateNew(true);
    } else {
      setCanCreateNew(false);
    }
  }, [keycloak, schoolFilter, academicYears]);

  useEffect(() => {
    checkAcademicYearSchoolCopyStatus();
    const intervalCall = setInterval(() => {
      checkAcademicYearSchoolCopyStatus();
    }, 60000);
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line
  }, [dispatch, academicYear]);

  const checkAcademicYearSchoolCopyStatus = () => {
    dispatch(getQueueStatus({ academic_year_id: academicYear })).then(result => {
      if (result.data) {
        setIsCopyingSchool(result.data.queueStatus === QUEUE_STATUS.COPYING_SCHOOL);
      }
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setIsSearching(true);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setIsSearching(true);
  };

  const viewAcademicClassroom = (school) => {
    const classroomButton = (
      <Tooltip
        title={translate('common.button.view')}
        key={translate('common.button.view')}
        className={classes.tooltip}
      >
        <IconButton
          color='primary'
          aria-label='edit'
          className={cx({
            [classes.tableIconButton]: true,
            [classes.openInNewButton]: true
          })}
          component={Link}
          to={`${ROUTES.CLASSROOM}?academicYear=${academicYear}&school=${school.id}`}
        >
          <OpenInNewIcon className={classes.icon}/>
        </IconButton>
      </Tooltip>
    );
    if (keycloak.hasRealmRole(ROLES.MANAGE_CLASSROOM)) {
      return classroomButton;
    }

    switch (authProfile.groups[0]) {
      case GROUPS.TEACHER:
      case GROUPS.SCHOOL_MANAGEMENT:
        if (authProfile.school.emis_code === school.emis_code) {
          return classroomButton;
        }
        break;
      case GROUPS.PROVINCIAL_OFFICER:
        if (authProfile.province.code === school.province.code) {
          return classroomButton;
        }
        break;
      case GROUPS.DISTRICT_OFFICER:
        if (authProfile.district.code === school.district.code) {
          return classroomButton;
        }
        break;
      default:
    }
  };

  const status = (status) => {
    return (
      <Tooltip
        title={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        key={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        className={classes.tooltip}
      >
        {status ? (
          <CheckCircleIcon
            className={cx({
              [classes.successButton]: true,
              [classes.icon]: true
            })}
          />
        ) : (
          <RemoveCircleIcon
            className={cx({
              [classes.inactive]: true,
              [classes.icon]: true
            })}
          />
        )

        }
      </Tooltip>
    );
  };

  const handleChangePage = (e, page) => {
    setPage(page);
    setIsSearching(true);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
    setIsSearching(true);
  };

  return (
    <>
      <Card className={classes.cardFilter}>
        <CardHeader color="gold" icon>
          <CardIcon color="gold">
            <SchoolIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{translate('school.pageTitle')}</h4>
        </CardHeader>
        <CardBody>
          <form
            className={classes.gridContainerStyle}
          >
            <GridContainer>
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.province')}
                  placeholder={translate('gazetteer.placeholder.province')}
                  value={province}
                  name="province"
                  onChange={e => onChangeHandler(e, 'province')}
                  choices={provinceChoices}
                  disabled={provinces.length === 0 || disabledProvinceSelection}
                  helperText=""
                />
              </GridItem>
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.district')}
                  placeholder={translate('gazetteer.placeholder.district')}
                  value={district}
                  name="district"
                  onChange={e => onChangeHandler(e, 'district')}
                  choices={districtChoices}
                  disabled={districts.length === 0 || disabledDistrictSelection}
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.commune')}
                  placeholder={translate('gazetteer.placeholder.commune')}
                  value={commune}
                  name="commune"
                  onChange={e => onChangeHandler(e, 'commune')}
                  choices={communeChoices}
                  disabled={communes.length === 0 || isManagementOrTeacher}
                  helperText=""
                />
              </GridItem>
              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('gazetteer.village')}
                  placeholder={translate('gazetteer.placeholder.village')}
                  value={village}
                  name="village"
                  onChange={e => onChangeHandler(e, 'village')}
                  choices={villageChoices}
                  disabled={villages.length === 0 || isManagementOrTeacher}
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('common.status')}
                  placeholder={translate('common.placeholder.status')}
                  value={schoolStatus}
                  name="schoolStatus"
                  onChange={e => onChangeHandler(e, 'schoolStatus')}
                  choices={
                    [
                      { label: translate('common.all'), value: 'all' },
                      { label: translate(`school.schoolStatus.${VARIABLES.status['1']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['1']) },
                      { label: translate(`school.schoolStatus.${VARIABLES.status['0']}`), value: helpers.getKeyByValue(VARIABLES.status, VARIABLES.status['0']) }
                    ]
                  }
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('school.academicYear')}
                  placeholder={translate('school.placeholder.academicYear')}
                  value={academicYear}
                  name="academicYear"
                  onChange={e => onChangeHandler(e, 'academicYear')}
                  choices={academicYearChoices}
                  disabled={academicYears.length === 0}
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Input
                  label={translate('school.emisSchoolCode')}
                  placeholder={translate('school.placeholder.emisSchoolCode')}
                  value={code}
                  name="code"
                  onChange={e => onChangeHandler(e, 'code')}
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Input
                  label={translate('school.schoolName')}
                  placeholder={translate('school.placeholder.schoolName')}
                  value={schoolName}
                  name="schoolName"
                  onChange={e => onChangeHandler(e, 'schoolName')}
                  helperText=""
                />
              </GridItem>

              <GridItem
                xs={12}
                md={6}
              >
                <Select
                  label={translate('school.accomplishmentStatus.title')}
                  placeholder={translate('school.placeholder.accomplishmentStatus')}
                  value={accomplishmentStatus}
                  name="accomplishmentStatus"
                  onChange={e => onChangeHandler(e, 'accomplishmentStatus')}
                  choices={
                    [
                      { label: translate('common.all'), value: 'all' },
                      { label: translate(`school.accomplishmentStatus.not.${VARIABLES.accomplishmentStatus['1']}`), value: 0 },
                      { label: translate(`school.accomplishmentStatus.not.${VARIABLES.accomplishmentStatus['2']}`), value: 1 },
                      { label: translate(`school.accomplishmentStatus.not.${VARIABLES.accomplishmentStatus['3']}`), value: 2 }
                    ]
                  }
                  helperText=""
                />
              </GridItem>
              {!isManagementOrTeacher && (
                <GridItem
                  xs={12}
                  md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => { setIsDuplicateRecord(e.target.checked); }}
                        name="duplicateRecord"
                        color="primary"
                        checked={isDuplicateRecord}
                      />
                    }
                    label={translate('school.duplicate_record')}
                    className={classes.marginTopMd}
                  />
                </GridItem>
              )}
            </GridContainer>

            <div className={classes.allButton}>
              <ThemeButton
                color="primary"
                type="submit"
                onClick={handleSearch}
              >
                {translate('common.button.search')}
              </ThemeButton>
            </div>
          </form>
        </CardBody>
      </Card>

      <LoadablePaper
        rendered
        loading={isLoading}
      >
        <LoadablePaperContent>
          <Card>
            <CardBody>
              <Table
                size='small'
                tableHead={[
                  { id: 'code', isNeedSort: true, label: translate('school.code') },
                  { id: 'name', isNeedSort: true, label: translate('school.name_kh') },
                  { id: 'name_en', isNeedSort: true, label: translate('school.name_en') },
                  { id: 'emis_code', isNeedSort: true, label: translate('school.emisSchoolCode') },
                  { id: 'degree', isNeedSort: true, label: translate('school.degree') },
                  { id: 'schoolPrinciple', isNeedSort: false, label: translate('school.manager') },
                  { id: 'classroom', isNeedSort: false, label: translate('school.classroom') },
                  { id: 'status', isNeedSort: false, label: translate('common.status') },
                  { id: 'accomplishmentStatus', isNeedSort: false, label: translate('school.accomplishmentStatus.title') },
                  { id: 'action', isNeedSort: false, label: translate('common.action') }
                ]}
                tableData={
                  schoolList.map(school => {
                    const editButton = (
                      <Tooltip
                        title={translate('common.button.edit')}
                        key={translate('common.button.edit')}
                        className={classes.tooltip}
                      >
                        <span>
                          <IconButton
                            color='primary'
                            aria-label='edit'
                            className={classes.tableIconButton}
                            component={Link}
                            to={`${ROUTES.SCHOOL_EDIT_ROOT}/${school.id}`}
                          >
                            <Edit className={classes.icon} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    );
                    const viewButton = (
                      <Tooltip
                        title={translate('common.button.view')}
                        key={translate('common.button.view')}
                        className={classes.tooltip}
                      >
                        <span>
                          <IconButton
                            color='primary'
                            aria-label='edit'
                            className={classes.tableIconButton}
                            component={Link}
                            to={`${ROUTES.SCHOOL_VIEW_ROOT}/${school.id}`}
                          >
                            <View className={classes.icon} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    );
                    let actionButton = [];
                    if (keycloak.hasRealmRole(ROLES.MANAGE_SCHOOL)) {
                      actionButton = [editButton];
                    } else {
                      switch (authProfile.groups[0]) {
                        case GROUPS.SCHOOL_MANAGEMENT:
                          actionButton = authProfile.school.emis_code === school.emis_code ? [viewButton, editButton] : [viewButton];
                          break;
                        case GROUPS.PROVINCIAL_OFFICER:
                          actionButton = authProfile.province.code === school.province.code ? [viewButton, editButton] : [viewButton];
                          break;
                        case GROUPS.DISTRICT_OFFICER:
                          actionButton = authProfile.district.code === school.district.code ? [viewButton, editButton] : [viewButton];
                          break;
                        default:
                          actionButton = [viewButton];
                      }
                    }
                    return [
                      school.code,
                      school.name,
                      school.name_en,
                      school.emis_code,
                      translate(`school.schoolDegree.${VARIABLES.degree[school.degree.number].code}`),
                      school.principle ? `${school.principle.last_name} ${school.principle.first_name}` : '',
                      viewAcademicClassroom(school),
                      status(school.status),
                      school.accomplishment_status ? translate(`school.accomplishmentStatus.${VARIABLES.accomplishmentStatus[school.accomplishment_status]}`) : '',
                      actionButton
                    ];
                  })
                }
                customCellClasses={['center', 'center', 'center']}
                customClassesForCells={[5, 6, 7]}
                customHeadCellClasses={['center', 'center', 'center', 'center']}
                customHeadClassesForCells={[5, 6, 7]}
                onRequestSort={handleRequestSort}
                count={count}
                limit={limit}
                page={page}
                order={order}
                orderBy={orderBy}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </LoadablePaperContent>
      </LoadablePaper>
      <div className={classes.schoolCopyStatusContainer}>
        {isCopyingSchool && (
          <Chip
            icon={<CircularProgress size={20} color='#ffff' className={classes.circularProgress}/>}
            label={translate('school.copyStatus')}
            clickable
            color="primary"
            className={classes.schoolCopyStatusChip}
          />
        )}
        {
          keycloak.hasRealmRole(ROLES.MANAGE_SCHOOL) && (
            <CreateNewButton disabled={!canCreateNew} />
          )
        }
      </div>
    </>
  );
};

export default School;
