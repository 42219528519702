import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentOptions, updateScholarshipStudent } from 'redux/actions';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import {
  Avatar,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ScholarshipIcon from '@material-ui/icons/School';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Select from 'components/common/Select/Select';

const useStyles = makeStyles(styles);

const EditStudentDialog = ({ id, editStudent, open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const scholarshipList = useSelector(state => state.scholarship.scholarshipList.data);
  const allDisabilities = useSelector(state => state.studentOption.disability);
  const allHealthProblems = useSelector(state => state.studentOption.healthProblem);
  const allPersonalProblems = useSelector(state => state.studentOption.personalProblem);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const [student, setStudent] = useState(editStudent || undefined);

  const [disabilities, setDisabilities] = useState([]);
  const [healthProblems, setHealthProblems] = useState([]);
  const [personalProblems, setPersonalProblems] = useState([]);
  const [scholarships, setScholarships] = useState([]);
  const [poorLevel, setPoorLevel] = useState('');

  const [noDisabilityData, setNoDisabilityData] = useState(false);
  const [noHealthProblemData, setNoHealthProblemData] = useState(false);
  const [noPersonalProblemData, setNoPersonalProblemData] = useState(false);
  const [noScholarshipData, setNoScholarshipData] = useState(false);
  const [noPoorLevelData, setNoPoorLevelData] = useState(false);

  const [errorDisability, setErrorDisability] = useState(false);
  const [errorHealthProblem, setErrorHealthProblem] = useState(false);
  const [errorPersonalProblem, setErrorPersonalProblem] = useState(false);
  const [errorScholarship, setErrorScholarship] = useState(false);
  const [errorPoorLevel, setErrorPoorLevel] = useState(false);

  useEffect(() => {
    if (id && scholarshipList && scholarshipList.length) {
      const scholarship = _.findLast(scholarshipList, { id });
      setStudent(scholarship);
    }
  }, [id, scholarshipList]);

  useEffect(() => {
    if (student) {
      if (_.isEmpty(student.disabilities)) {
        setNoDisabilityData(true);
      } else {
        setDisabilities(student.disabilities.map(disability => disability.id));
      }

      if (_.isEmpty(student.health_problems)) {
        setNoHealthProblemData(true);
      } else {
        setHealthProblems(student.health_problems.map(healthProblem => healthProblem.id));
      }

      if (_.isEmpty(student.personal_problems)) {
        setNoPersonalProblemData(true);
      } else {
        setPersonalProblems(student.personal_problems.map(problem => problem.id));
      }

      if (_.isEmpty(student.scholarships)) {
        setNoScholarshipData(true);
      } else {
        setScholarships(student.scholarships.map(scholarship => scholarship.scholarship_id));
      }

      if (!student.poor_level) {
        setNoPoorLevelData(true);
      } else {
        setPoorLevel(student.poor_level || '');
      }
    }
  }, [student]);

  useEffect(() => {
    if (!isLoadingStudentOption) {
      dispatch(getStudentOptions());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const onChangeHandler = e => {
    const { name, value } = e.target;

    switch (name) {
      case 'disabilities':
        setDisabilities(value);
        break;
      case 'healthProblems':
        setHealthProblems(value);
        break;
      case 'personalProblems':
        setPersonalProblems(value);
        break;
      case 'scholarships':
        setScholarships(value);
        break;
      case 'poorLevel':
        setPoorLevel(value);
        break;
      default:
    }
  };

  const handleNoDataToggle = e => {
    const { name, checked } = e.target;

    switch (name) {
      case 'noDisabilityData':
        setNoDisabilityData(checked);
        if (checked) {
          setDisabilities([]);
        }
        break;
      case 'noHealthProblemData':
        setNoHealthProblemData(checked);
        if (checked) {
          setHealthProblems([]);
        }
        break;
      case 'noPersonalProblemData':
        setNoPersonalProblemData(checked);
        if (checked) {
          setPersonalProblems([]);
        }
        break;
      case 'noScholarshipData':
        setNoScholarshipData(checked);
        if (checked) {
          setScholarships([]);
        }
        break;
      case 'noPoorLevelData':
        setNoPoorLevelData(checked);
        if (checked) {
          setPoorLevel('');
        }
        break;
      default:
    }
  };

  const handleSave = e => {
    e.preventDefault();

    let canSave = true;

    if (!disabilities.length && noDisabilityData === false) {
      canSave = false;
      setErrorDisability(true);
    } else {
      setErrorDisability(false);
    }

    if (!healthProblems.length && noHealthProblemData === false) {
      canSave = false;
      setErrorHealthProblem(true);
    } else {
      setErrorHealthProblem(false);
    }

    if (!personalProblems.length && noPersonalProblemData === false) {
      canSave = false;
      setErrorPersonalProblem(true);
    } else {
      setErrorPersonalProblem(false);
    }

    if (!scholarships.length && noScholarshipData === false) {
      canSave = false;
      setErrorScholarship(true);
    } else {
      setErrorScholarship(false);
    }

    if (poorLevel === '' && noPoorLevelData === false) {
      canSave = false;
      setErrorPoorLevel(true);
    } else {
      setErrorPoorLevel(false);
    }

    if (canSave) {
      dispatch(updateScholarshipStudent(student.id, {
        poor_level: poorLevel,
        options: {
          disabilities,
          scholarships,
          health_problems: healthProblems,
          personal_problems: personalProblems
        }
      })).then(result => {
        if (result) {
          onClose();
        }
      });
    }
  };

  if (!student) {
    return null;
  }

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <ScholarshipIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('scholarship.student')}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
          >
            <Input
              label={translate('common.stsId')}
              value={student.student.emis_student_id}
              name="stsId"
              disabled
            />
          </GridItem>

          <GridItem
            xs={12}
            md={6}
          >
            <Input
              require
              label={translate('common.lastName')}
              value={student.student.last_name}
              name="lastName"
              disabled
            />
          </GridItem>
          <GridItem
            xs={12}
            md={6}
          >
            <Input
              require
              label={translate('common.firstName')}
              value={student.student.first_name}
              name="firstName"
              disabled
            />
          </GridItem>

          <GridItem
            xs={8}
            md={4}
          >
            <Select
              require
              multiple
              label={translate('enrollment.disability')}
              placeholder={translate('enrollment.placeholder.disability')}
              value={disabilities}
              name="disabilities"
              onChange={onChangeHandler}
              choices={
                allDisabilities.map(disability => ({
                  label: getActiveLanguage(localize).code === 'en' ? disability.name_en : disability.name,
                  value: disability.id,
                  isRenderAsParents: disability.parent_id === 0 && disability.all_children.length === 0,
                  disabled: disability.all_children.length !== 0
                }))
              }
              disabled={noDisabilityData}
              error={errorDisability}
              helperText={errorDisability ? translate('common.error.require') : ''}
            />
          </GridItem>

          <GridItem
            xs={4}
            md={2}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noDisabilityData}
                      name="noDisabilityData"
                      onChange={handleNoDataToggle}
                      color="primary"
                    />
                  }
                  label={translate('common.no')}
                  className={classes.toggleStyle}
                />
              </FormGroup>
            </FormControl>
          </GridItem>

          <GridItem
            xs={8}
            md={4}
          >
            <Select
              require
              multiple
              label={translate('enrollment.healthProblem')}
              placeholder={translate('enrollment.placeholder.healthProblem')}
              value={healthProblems}
              name="healthProblems"
              onChange={onChangeHandler}
              choices={
                allHealthProblems.map(healthProblem => ({
                  label: getActiveLanguage(localize).code === 'en' ? healthProblem.name_en : healthProblem.name,
                  value: healthProblem.id
                }))
              }
              disabled={noHealthProblemData}
              error={errorHealthProblem}
              helperText={errorHealthProblem ? translate('common.error.require') : ''}
            />
          </GridItem>

          <GridItem
            xs={4}
            md={2}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noHealthProblemData}
                      name="noHealthProblemData"
                      onChange={handleNoDataToggle}
                      color="primary"
                    />
                  }
                  label={translate('common.no')}
                  className={classes.toggleStyle}
                />
              </FormGroup>
            </FormControl>
          </GridItem>

          <GridItem
            xs={8}
            md={4}
          >
            <Select
              require
              multiple
              label={translate('enrollment.personalProblem')}
              placeholder={translate('enrollment.placeholder.personalProblem')}
              value={personalProblems}
              name="personalProblems"
              onChange={onChangeHandler}
              choices={
                allPersonalProblems.map(personalProblem => ({
                  label: getActiveLanguage(localize).code === 'en' ? personalProblem.name_en : personalProblem.name,
                  value: personalProblem.id
                }))
              }
              disabled={noPersonalProblemData}
              error={errorPersonalProblem}
              helperText={errorPersonalProblem ? translate('common.error.require') : ''}
            />
          </GridItem>

          <GridItem
            xs={4}
            md={2}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noPersonalProblemData}
                      name="noPersonalProblemData"
                      onChange={handleNoDataToggle}
                      color="primary"
                    />
                  }
                  label={translate('common.no')}
                  className={classes.toggleStyle}
                />
              </FormGroup>
            </FormControl>
          </GridItem>

          <GridItem
            xs={8}
            md={4}
          >
            <Select
              require
              multiple
              label={translate('enrollment.scholarship')}
              placeholder={translate('enrollment.placeholder.scholarship')}
              value={scholarships}
              name="scholarships"
              onChange={onChangeHandler}
              choices={
                allScholarships.map(scholarship => ({
                  label: scholarship.type,
                  value: scholarship.id
                }))
              }
              disabled={noScholarshipData}
              error={errorScholarship}
              helperText={errorScholarship ? translate('common.error.require') : ''}
            />
          </GridItem>

          <GridItem
            xs={4}
            md={2}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noScholarshipData}
                      name="noScholarshipData"
                      onChange={handleNoDataToggle}
                      color="primary"
                    />
                  }
                  label={translate('common.no')}
                  className={classes.toggleStyle}
                />
              </FormGroup>
            </FormControl>
          </GridItem>

          <GridItem
            xs={8}
            md={4}
          >
            <Select
              label={translate('scholarship.poorLevel')}
              placeholder={translate('scholarship.placeholder.poorLevel')}
              value={poorLevel}
              name="poorLevel"
              onChange={onChangeHandler}
              choices={[
                { label: translate('scholarship.poorLevel.option.level_1'), value: 'level_1' },
                { label: translate('scholarship.poorLevel.option.level_2'), value: 'level_2' },
                { label: translate('scholarship.poorLevel.option.other'), value: 'other' }
              ]}
              disabled={noPoorLevelData}
              error={errorPoorLevel}
              helperText={errorPoorLevel ? translate('common.error.require') : ''}
            />
          </GridItem>
          <GridItem
            xs={4}
            md={2}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noPoorLevelData}
                      name="noPoorLevelData"
                      onChange={handleNoDataToggle}
                      color="primary"
                    />
                  }
                  label={translate('common.no')}
                  className={classes.toggleStyle}
                />
              </FormGroup>
            </FormControl>
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>
          <ThemeButton
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            {translate('common.button.save')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

EditStudentDialog.propTypes = {
  id: PropTypes.any,
  editStudent: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default EditStudentDialog;
