import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  exportHistoryList: [],
  loading: false
};

function getExportHistoryListRequested (state) {
  return {
    ...state,
    loading: true
  };
}

function getExportHistoryListSuccess (state, action) {
  return {
    ...state,
    exportHistoryList: action.exportHistoryList,
    loading: false
  };
}

function getExportHistoryListFail (state) {
  return {
    ...state,
    loading: false
  };
}

const exportHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXPORT_HISTORY_REQUEST:
      return getExportHistoryListRequested(state);
    case actionTypes.GET_EXPORT_HISTORY_SUCCESS:
      return getExportHistoryListSuccess(state, action);
    case actionTypes.GET_EXPORT_HISTORY_FAIL:
      return getExportHistoryListFail(state);
    default:
      return state;
  }
};

export default exportHistoryReducer;
