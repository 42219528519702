import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import AttendanceIcon from '@material-ui/icons/AssignmentInd';

import {
  getAcademicYears,
  getClassroomListForFilter,
  getSchoolList,
  getStudentOptions
} from 'redux/actions';
import * as moment from 'moment';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import CardBody from 'components/theme/Card/CardBody';
import Card from 'components/theme/Card/Card';
import settings from 'settings';
import { getSchoolName } from 'utilities/helpers';
import { groups as GROUPS } from 'variables/user';
import * as ROUTES from 'variables/routeNames';
import { Link } from 'react-router-dom';

const AttendanceListFilter = (props) => {
  const {
    classes,
    setFilterParams,
    filterParams,
    onChangeHandler,
    handleFilter,
    errorAcademicYear,
    errorSchool,
    errorGrade,
    errorSuffix,
    studentId
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const allGrades = useSelector(state => state.studentOption.grade);
  const authProfile = useSelector(state => state.auth.profile);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const isLoadingSchoolList = useSelector(state => state.school.isLoading);
  const isLoadingClassroomList = useSelector(state => state.classroom.isLoading);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);

  // fetch Academic Years
  useEffect(() => {
    dispatch(getAcademicYears());
    dispatch(getStudentOptions());
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      let defaultRunningAcademicYearId = null;
      const choices = academicYears.filter(academic => [ACADEMIC_STATUS.RUNNING, ACADEMIC_STATUS.ARCHIVED, ACADEMIC_STATUS.POST].includes(academic.status))
        .map(academic => {
          const academicStartDate = moment(academic.start_date,
            settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          if (academic.status === ACADEMIC_STATUS.RUNNING) {
            defaultRunningAcademicYearId = academic.id;
          }
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choices);

      // set default selected academicYear
      if (choices.length > 0 && filterParams.academicYear === '') {
        setFilterParams({
          ...filterParams,
          academicYear: defaultRunningAcademicYearId || choices[0].value
        });
      }
    }
  }, [academicYears, translate, setFilterParams, filterParams]);

  // fetch schools by academic year
  useEffect(() => {
    if (filterParams.academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      const params = isManagementOrTeacher
        ? {
          academic_year_id: filterParams.academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: filterParams.academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, filterParams.academicYear, authProfile]);

  // set school choices
  useEffect(() => {
    if (filterParams.academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
    }
    // eslint-disable-next-line
  }, [filterParams.academicYear, schoolList, authProfile]);

  useEffect(() => {
    // set default selected school if there is only one
    if (schoolChoices.length === 1) {
      setFilterParams({
        ...filterParams,
        school: schoolChoices[0].value
      });
    }
    // eslint-disable-next-line
  }, [schoolChoices]);

  // fetch classes by school
  useEffect(() => {
    if (filterParams.school) {
      const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
      const param = {
        school: filterParams.school
      };
      if (isTeacher) {
        param.teacher_staff_id = authProfile.userable_id;
      }
      dispatch(getClassroomListForFilter(param));
    }
  }, [dispatch, filterParams.school, authProfile]);

  // set grade choices
  useEffect(() => {
    if (filterParams.school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          if (grade !== undefined) {
            choices.push({
              value: classroom.grade,
              label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
            });
          }
        }
      });
      setGradeChoices(choices);

      // set default selected grade if there is only one
      if (choices.length === 1) {
        setFilterParams({
          ...filterParams,
          grade: choices[0].value
        });
      }
    } else {
      setGradeChoices([]);
    }
    // eslint-disable-next-line
  }, [filterParams.school, classroomList, allGrades, localize]);

  // set suffix choices
  useEffect(() => {
    if (filterParams.grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === filterParams.grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);

      // set default selected grade if there is only one
      if (choices.length === 1) {
        setFilterParams({
          ...filterParams,
          suffix: choices[0].value
        });
      }
    } else {
      setSuffixChoices([]);
    }
    // eslint-disable-next-line
  }, [filterParams.grade, classroomList]);

  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic || isLoadingSchoolList || isLoadingClassroomList}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <AttendanceIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('attendance.title')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={filterParams.academicYear}
                    name="academicYear"
                    onChange={onChangeHandler}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0 || studentId}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={filterParams.school}
                    name="school"
                    onChange={onChangeHandler}
                    choices={schoolChoices}
                    disabled={schoolChoices.length === 0 || managementOrTeacher || studentId}
                    require
                    error={errorSchool}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={filterParams.grade}
                    name="grade"
                    onChange={onChangeHandler}
                    choices={gradeChoices}
                    disabled={gradeChoices.length === 0 || studentId}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('graduation.placeholder.suffix')}
                    value={filterParams.suffix}
                    name="suffix"
                    onChange={onChangeHandler}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0 || studentId}
                    require
                    error={errorSuffix}
                    helperText={errorSuffix ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton + ' ' + classes.textRight}
              >
                {studentId !== undefined && (
                  <ThemeButton
                    component={Link}
                    to={ROUTES.ATTENDANCE_MANAGEMENT}
                  >
                    {translate('common.button.reset')}
                  </ThemeButton>
                )}
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilter}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

AttendanceListFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  filterParams: PropTypes.instanceOf(Object),
  setFilterParams: PropTypes.func,
  errorAcademicYear: PropTypes.bool,
  errorSchool: PropTypes.bool,
  errorGrade: PropTypes.bool,
  errorSuffix: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  handleFilter: PropTypes.func,
  studentId: PropTypes.string
};

export default AttendanceListFilter;
