import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import DataSourceCard from './Partial/DataSourceCard';
import ReportTypeCard from './Partial/ReportTypeCard';
import ExportHistoryCard from './Partial/ExportHistoryCard';
import { LinearProgress, makeStyles } from '@material-ui/core';
import reportLoadingStyle from 'assets/sts/jss/views/report/reportLoadingStyle';

const useStyles = makeStyles(reportLoadingStyle);

const ManagementReport = () => {
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isDownloading = useSelector(state => state.dataQuery.isDownloading);
  const reportLoadingStyle = useStyles();

  // set title
  useEffect(() => {
    document.title = `${translate('managementReport.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('managementReport.title');
  }, [translate]);

  return (
    <>
      <GridContainer>
        <GridItem
          xs={12}
          md={4}
        >
          <DataSourceCard />
        </GridItem>
        <GridItem
          xs={12}
          md={8}
        >
          <ReportTypeCard />
          <ExportHistoryCard />
        </GridItem>
      </GridContainer>
      {
        isDownloading && (
          <div className={reportLoadingStyle.loadingStyle}>
            <LinearProgress className={reportLoadingStyle.barStyle}/>
          </div>
        )
      }
    </>
  );
};

export default ManagementReport;
