import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import ThemeButton from 'components/theme/CustomButtons/Button';
import * as VARIABLES from '../../../variables/transfer';

const TransferTable = (props) => {
  const {
    count,
    rows,
    limit,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    onReviewRowHandler
  } = props;
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoading = useSelector(state => state.student.isLoading);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);
  const allGrades = useSelector(state => state.studentOption.grade);
  const schoolFilter = useSelector(state => state.transfer.transferFilter?.school || '');

  return (
    <LoadablePaper
      rendered
      loading={isLoading || isLoadingAcademic || isLoadingStudentOption}
    >
      <LoadablePaperContent>
        <Card>
          <CardBody>
            <Table
              size='small'
              tableHead={[
                { id: 'stsId', isNeedSort: false, label: translate('common.stsId') },
                { id: 'name', isNeedSort: false, label: translate('common.name') },
                { id: 'gender', isNeedSort: false, label: translate('common.gender') },
                { id: 'birthDate', isNeedSort: false, label: translate('enrollment.birthDate') },
                { id: 'classroom', isNeedSort: false, label: translate('school.classroom') },
                { id: 'transferDate', isNeedSort: false, label: translate('transfer.date') },
                { id: 'status', isNeedSort: false, label: translate('common.status') },
                { id: 'fromSchool', isNeedSort: false, label: translate('transfer.fromSchool') },
                { id: 'toSchool', isNeedSort: false, label: translate('transfer.toSchool') },
                { id: 'toGrade', isNeedSort: false, label: translate('transfer.toGrade') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const reviewButton = (
                  row.status === 0 && row.transferToSchool?.id === schoolFilter &&
                  <ThemeButton
                    key={`review-${row.id}`}
                    size="sm"
                    color="primary"
                    type="submit"
                    onClick={() => onReviewRowHandler(row.id)}
                  >
                    {translate('transfer.review')}
                  </ThemeButton>
                );
                const renderClassroomLabel = (classroom) => {
                  if (classroom) {
                    const selectedGrade = allGrades.find(g => g.number === classroom.grade);
                    if (selectedGrade !== undefined) {
                      const gradeLabel = getActiveLanguage(localize).code === 'en' ? selectedGrade.name_en : selectedGrade.name;
                      if (classroom.suffix) {
                        return `${gradeLabel} (${classroom.suffix})`;
                      } else {
                        return gradeLabel;
                      }
                    }
                  }
                  return '';
                };
                return [
                  row.stsId,
                  row.name,
                  row.gender === 'm' ? translate('common.genderType.m') : row.gender === 'f' ? translate('common.genderType.f') : '',
                  row.birthDate,
                  renderClassroomLabel(row.fromClassroom),
                  row.transferDate,
                  translate(`transfer.statusChoice.${VARIABLES.status[row.status]}`).toUpperCase(),
                  // eslint-disable-next-line camelcase
                  getActiveLanguage(localize).code === 'en' ? row.transferFromSchool?.name_en : row.transferFromSchool?.name,
                  // eslint-disable-next-line camelcase
                  getActiveLanguage(localize).code === 'en' ? row.transferToSchool?.name_en : row.transferToSchool?.name,
                  renderClassroomLabel(row.classroom),
                  [reviewButton]
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customCellClasses={['center']}
              customClassesForCells={[0]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

TransferTable.propTypes = {
  rows: PropTypes.array,
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  onReviewRowHandler: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func
};

export default TransferTable;
