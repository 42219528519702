import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification
} from 'redux/actions/notification';

function getProvincesRequested () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_PROVINCES_REQUEST
  };
}

function getProvincesSuccess (provinces) {
  return {
    type: actionTypes.GET_GAZETTER_CODE_PROVINCES_SUCCESS,
    provinces
  };
}

function getProvincesFail () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_PROVINCES_FAIL
  };
}

function getDistrictsRequested () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_DISTRICTS_REQUEST
  };
}

function getDistrictsSuccess (districts) {
  return {
    type: actionTypes.GET_GAZETTER_CODE_DISTRICTS_SUCCESS,
    districts
  };
}

function getDistrictsFail () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_DISTRICTS_FAIL
  };
}

function getCommunesRequested () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_COMMUNES_REQUEST
  };
}

function getCommunesSuccess (communes) {
  return {
    type: actionTypes.GET_GAZETTER_CODE_COMMUNES_SUCCESS,
    communes
  };
}

function getCommunesFail () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_COMMUNES_FAIL
  };
}

function getVillagesRequested () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_VILLAGES_REQUEST
  };
}

function getVillagesSuccess (villages) {
  return {
    type: actionTypes.GET_GAZETTER_CODE_VILLAGES_SUCCESS,
    villages
  };
}

function getVillagesFail () {
  return {
    type: actionTypes.GET_GAZETTER_CODE_VILLAGES_FAIL
  };
}

export const clearUnits = (uniteLevel) => {
  return (dispatch) => {
    switch (uniteLevel) {
      case 'province':
        dispatch(getDistrictsSuccess([]));
        dispatch(getCommunesSuccess([]));
        dispatch(getVillagesSuccess([]));
        break;
      case 'district':
        dispatch(getCommunesSuccess([]));
        dispatch(getVillagesSuccess([]));
        break;
      case 'commune':
        dispatch(getVillagesSuccess([]));
        break;
      default:
        dispatch(getDistrictsSuccess([]));
        dispatch(getCommunesSuccess([]));
        dispatch(getVillagesSuccess([]));
        break;
    }
  };
};

export const getProvinces = () => {
  return (dispatch) => {
    dispatch(getProvincesRequested());
    axios.get('/gazetteer/get')
      .then(
        res => {
          const data = res.data.data;
          dispatch(getProvincesSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getProvincesFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getDistricts = (parentCode) => {
  return (dispatch) => {
    dispatch(getDistrictsRequested());
    const params = {
      state: 'district'
    };
    if (parentCode) {
      params.parent_code = parentCode.split(',')[1];
    }
    axios.get('/gazetteer/get', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getDistrictsSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getDistrictsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getCommunes = (parentCode) => {
  return (dispatch) => {
    dispatch(getCommunesRequested());
    const params = {
      state: 'commune'
    };
    if (parentCode) {
      params.parent_code = parentCode.split(',')[1];
    }
    axios.get('/gazetteer/get', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getCommunesSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getCommunesFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getVillages = (parentCode) => {
  return (dispatch) => {
    dispatch(getVillagesRequested());
    const params = {
      state: 'village'
    };
    if (parentCode) {
      params.parent_code = parentCode.split(',')[1];
    }
    axios.get('/gazetteer/get', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getVillagesSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getVillagesFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
