export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const PROFILE_LOAD_REQUESTED = 'PROFILE_LOAD_REQUESTED';
export const PROFILE_LOAD_SUCCEEDED = 'PROFILE_LOAD_SUCCEEDED';
export const PROFILE_LOAD_FAILED = 'PROFILE_LOAD_FAILED';

export const HEARTBEAT_ACTIVATED = 'HEARTBEAT_ACTIVATED';
export const HEARTBEAT_DEACTIVATED = 'HEARTBEAT_DEACTIVATED';

export const TOKEN_REFRESH_SUCCEEDED = 'TOKEN_REFRESH_SUCCEEDED';
export const TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED';
