import * as actionTypes from 'redux/actionTypes';

const ALL = 'all';

export const initialState = {
  filter: {
    dataSource: {
      academicYear: ''
    },
    indicators: [],
    province: ALL,
    district: ALL,
    commune: ALL,
    village: ALL,
    degree: ALL,
    grade: ALL,
    round: '',
    result: '',
    aggregateBy: '',
    fileType: 'xlsx'
  },
  statistics: {},
  schoolFilterOptions: {},
  isLoading: false,
  isDownloading: false
};

function changeFilterRequested (state) {
  return {
    ...state
  };
}

function changeFilterSuccess (state, action) {
  return {
    ...state,
    filter: action.filter
  };
}

function getStatisticsRequested (state) {
  return {
    ...state,
    isLoading: true
  };
}

function getStatisticsSuccess (state, action) {
  return {
    ...state,
    statistics: action.statistics,
    isLoading: false
  };
}

function getStatisticsFail (state) {
  return {
    ...state,
    isLoading: false
  };
}

function getDataQuerySchoolFilterOptionRequested (state) {
  return {
    ...state
  };
}

function getDataQuerySchoolFilterOptionSuccess (state, action) {
  return {
    ...state,
    schoolFilterOptions: action.schoolFilterOptions
  };
}

function getDataQuerySchoolFilterOptionFail (state) {
  return {
    ...state
  };
}

function downloadStaticalReportSuccess (state, action) {
  return {
    ...state,
    isDownloading: false
  };
}

function downloadStaticalReportFail (state) {
  return {
    ...state,
    isDownloading: false
  };
}

function downloadStaticalReportRequested (state) {
  return {
    ...state,
    isDownloading: true
  };
}

const dataQueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_FILTER_REQUEST:
      return changeFilterRequested(state);
    case actionTypes.CHANGE_FILTER_SUCCESS:
      return changeFilterSuccess(state, action);

    case actionTypes.GET_STATISTICS_REQUEST:
      return getStatisticsRequested(state);
    case actionTypes.GET_STATISTICS_SUCCESS:
      return getStatisticsSuccess(state, action);
    case actionTypes.GET_STATISTICS_FAIL:
      return getStatisticsFail(state);

    case actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_REQUEST:
      return getDataQuerySchoolFilterOptionRequested(state);
    case actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_SUCCESS:
      return getDataQuerySchoolFilterOptionSuccess(state, action);
    case actionTypes.GET_DATA_QUERY_SCHOOL_FILTER_OPTION_FAIL:
      return getDataQuerySchoolFilterOptionFail(state);

    case actionTypes.DOWNLOAD_STATICAL_REPORT_REQUEST:
      return downloadStaticalReportRequested(state);
    case actionTypes.DOWNLOAD_STATICAL_REPORT_SUCCESS:
      return downloadStaticalReportSuccess(state, action);
    case actionTypes.DOWNLOAD_STATICAL_REPORT_FAIL:
      return downloadStaticalReportFail(state);
    default:
      return state;
  }
};

export default dataQueryReducer;
