import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import customSelectStyle from 'assets/theme/jss/material-dashboard-pro-react/customSelectStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const styles = () => ({
  ...customSelectStyle
});

const CustomSelect = (props) => {
  const {
    name,
    value,
    onChange,
    placeholder,
    label,
    className,
    labelClassName,
    choices,
    disabled,
    helperText,
    error,
    require,
    multiple,
    ...rest
  } = props;
  const classes = makeStyles(styles)();
  const helpTextClasses = cx({
    [classes.labelRootError]: error
  });

  const allChoices = [];
  if (choices) {
    choices.forEach(
      choice => {
        let parentTitle;
        if (choice.isRenderAsParents) {
          parentTitle = (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: multiple ? classes.multiSelectMenuItemSelected : classes.selectMenuItemSelected
              }}
              value={choice.value}
              key={`${choice.value}-parents`}
              disabled={true}
            >
              {choice.label}
            </MenuItem>
          );
        }
        const menuItem = (
          <MenuItem
            classes={{
              root: multiple ? classes.multiSelectMenuItem : classes.selectMenuItem,
              selected: multiple ? classes.multiSelectMenuItemSelected : classes.selectMenuItemSelected
            }}
            value={choice.value}
            key={choice.value}
            disabled={choice.disabled}
          >
            {
              multiple && !choice.disabled && (
                <Checkbox checked={value.indexOf(choice.value) > -1}/>
              )
            }
            {choice.label}
          </MenuItem>
        );
        if (parentTitle) {
          allChoices.push(parentTitle);
        }
        allChoices.push(menuItem);
      }
    );
  }

  return (
    <div
      className={className}
    >
      <InputLabel
        required={require}
        htmlFor={name}
        className={`${cx({
              [labelClassName]: true
            })}`}
      >
        {label}
      </InputLabel>
      <FormControl
        fullWidth
        error={error}
        className={error ? classes.selectFormControlError : classes.selectFormControl}
      >
        <InputLabel
          id={`${name}-label`}
          className={`${cx({
              [classes.placeholder]: true
            })}`}
          classes={{
            shrink: classes.shrinkLabel
          }}
        >
          {placeholder}
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select,
            disabled: classes.disableSelect
          }}
          value={value}
          onChange={onChange}
          id={name}
          inputProps={{
            name: `${name}`,
            id: `${name}`,
            placeholder: `${placeholder},`
          }}
          disabled={disabled}
          multiple={multiple}
          renderValue={multiple
            ? (selected) => {
              const selectedLabels = selected.map(oneSelected => {
                const index = choices.findIndex(choice => oneSelected === choice.value);
                return choices[index]?.label;
              });
              return (
                <div className={classes.chips}>
                  {selectedLabels.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              );
            }
            : undefined}
          {...rest}
        >
          {allChoices}
        </Select>
        {helperText !== undefined ? (
          <FormHelperText id={`${name}-text`} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

CustomSelect.defaultProps = {
  disabled: false,
  placeholder: '',
  require: false,
  label: undefined,
  multiple: false
};

CustomSelect.propTypes = {
  require: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  choices: PropTypes.array,
  multiple: PropTypes.bool
};

export default CustomSelect;
