import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import settings from 'settings';
import {
  deleteScholarshipRemittance,
  getScholarshipRemittanceList,
  getStudentOptions
} from 'redux/actions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import { formatCurrency } from 'utilities/helpers';

const useStyles = makeStyles(styles);

const RemittanceTable = ({ onEditRowHandler }) => {
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const classes = useStyles();

  const isLoading = useSelector(state => state.scholarship.isLoading);
  const remittanceList = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceList);
  const remittanceFilter = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceFilter);
  const isLoadingStudentOption = useSelector(state => state.studentOption.isLoading);

  const [school, setSchool] = useState('');
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(settings.rowsPerPage);
  const [page, setPage] = useState(settings.startPage);
  const [deleteId, setDeleteId] = useState(0);
  const [confirmBoxOpen, setConfirmBoxOpen] = useState(false);

  useEffect(() => {
    if (remittanceFilter) {
      setSchool(remittanceFilter.school);
      setLimit(remittanceFilter.limit);
      setPage(remittanceFilter.page);
    }
  }, [remittanceFilter]);

  useEffect(() => {
    if (remittanceFilter !== undefined && school) {
      const params = {
        school,
        page,
        limit
      };
      dispatch(getScholarshipRemittanceList(params));
    }
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    if (remittanceList.data && remittanceList.data.length > 0) {
      const showRows = remittanceList.data.map(
        (remittance) => ({
          id: remittance.id,
          round: remittance.round,
          remittedAmount: remittance.remitted_amount,
          requiredFund: remittance.required_fund,
          remittedAt: moment(remittance.remitted_at).format(settings.dateFormat)
        })
      );
      setCount(remittanceList && remittanceList.total ? remittanceList.total : 0);
      setRows(showRows);
    } else {
      setCount(0);
      setRows([]);
    }
  }, [remittanceList]);

  useEffect(() => {
    if (!isLoadingStudentOption) {
      dispatch(getStudentOptions());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  const handleDeleteRow = (id) => {
    setDeleteId(id);
    setConfirmBoxOpen(true);
  };

  const onHandleConfirm = () => {
    dispatch(deleteScholarshipRemittance(deleteId));
    setDeleteId(0);
    setConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setDeleteId(0);
    setConfirmBoxOpen(false);
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        { confirmBoxOpen && (
          <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
            title={translate('common.modal.confirmTitle')}
            onConfirm={() => onHandleConfirm()}
            onCancel={() => onHandelCancel()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={translate('common.modal.yes')}
            cancelBtnText={translate('common.modal.no')}
            showCancel
          >
            {translate('common.modal.describe')}
          </SweetAlert>
        )}

        <Card>
          <CardBody>
            <Table
              size='small'
              tableHead={[
                { id: 'round', isNeedSort: false, label: translate('scholarshipRemittance.round') },
                { id: 'remittedAmount', isNeedSort: false, label: translate('scholarshipRemittance.remittedAmount') },
                { id: 'requiredFund', isNeedSort: false, label: translate('scholarshipRemittance.requiredFund') },
                { id: 'remittedAt', isNeedSort: false, label: translate('scholarshipRemittance.remittedAt') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const editButton = (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={`edit-${row.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        onClick={() => onEditRowHandler(row.id)}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                const deleteButton = (
                  <Tooltip
                    title={translate('common.button.delete')}
                    key={`delete-${row.id}`}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='secondary'
                        aria-label='delete'
                        className={classes.tableIconButton}
                        onClick={() => handleDeleteRow(row.id)}
                      >
                        <Delete className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
                return [
                  translate(`scholarshipRemittance.round.option.${row.round}`),
                  formatCurrency(row.remittedAmount),
                  formatCurrency(row.requiredFund),
                  row.remittedAt,
                  [editButton, deleteButton]
                ];
              })}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customClassesForCells={[0]}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

RemittanceTable.propTypes = {
  onEditRowHandler: PropTypes.func
};

export default RemittanceTable;
