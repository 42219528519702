import axios from 'axios';
import settings from 'settings';
import keycloak from 'utilities/keycloak';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';

const HEADER_ACCEPT = 'Accept';
const HEADER_CONTENT_TYPE = 'Content-Type';
const HEADER_AUTHORIZATION = 'Authorization';

const AUTHORIZATION_BEARER = 'Bearer';
const CONTENT_TYPE_JSON = 'application/json';
const TOKEN_VALIDITY = 450;

// Add a request interceptor
const axiosInstance = axios.create({
  baseURL: settings.baseURL
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let { token } = keycloak;
    config.headers = {
      [HEADER_ACCEPT]: CONTENT_TYPE_JSON,
      [HEADER_CONTENT_TYPE]: CONTENT_TYPE_JSON
    };
    if (token) {
      try {
        const tokenRefreshed = await keycloak.updateToken(TOKEN_VALIDITY);
        token = tokenRefreshed ? keycloak.token : token;
        config.headers = {
          ...config.headers,
          [HEADER_AUTHORIZATION]: `${AUTHORIZATION_BEARER} ${token}`
        };
      } catch (e) {
        keycloak.logout({ redirectUri: window.location.origin });
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403 || error.response.status === 429) {
      history.push(`${ROUTES.ERROR_PAGE_ROOT}/${error.response.status}`);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
