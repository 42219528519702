import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import PrivateRoute from 'components/router/PrivateRoute';
import routes from 'layout/routes';
import Home from 'views/Home/Home';
import { withStyles } from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button';
import { showNotification, errorReceived } from 'redux/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorPage from 'views/ErrorView/ErrorPage';
import * as ROUTES from 'variables/routeNames';

const getRoutes = (routes) => routes.map((prop, key) => {
  if (prop.collapse) {
    return getRoutes(prop.views);
  }
  if (prop.path && prop.path !== '/') {
    if (prop.private) {
      return (
        <PrivateRoute
          exact
          name={prop.location}
          path={prop.path}
          key={key}
          roles={prop.roles}
          hideGroups={prop.hideFromGroups || []}
        />
      );
    }
    return (
      <Route
        exact
        path={prop.path}
        component={prop.component}
        key={key}
      />
    );
  }
  return null;
});

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    marginTop: '70px',
    padding: '30px 15px'
  }
});

class Main extends PureComponent {
  static propTypes = {
    errorReceived: PropTypes.func,
    showNotification: PropTypes.func,
    history: PropTypes.object,
    classes: PropTypes.object
  };

  componentDidCatch (error, info) {
    const errorReport = {
      name: error ? error.name : info.value,
      message: error ? error.message : info.componentStack,
      stack: error ? error.stack : info.componentStack
    };

    this.props.errorReceived(errorReport);

    this.props.showNotification(
      <div>
        <p>
          {'An error occurred: ' + errorReport.name}
        </p>

        <Link to={ROUTES.ERRORS}>
          <Button variant="outlined"
            color="secondary"
          >
            {'Show Details'}
          </Button> </Link>
      </div>);

    this.props.history.push('/');
  }

  render () {
    const { classes } = this.props;
    return (
      <main className={classes.content}>
        <Switch>
          <Route
            exact
            path="/"
            component={Home}
          />
          {getRoutes(routes)}
          <Route component={ErrorPage} />
        </Switch>
      </main>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ errorReceived, showNotification }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main)));
