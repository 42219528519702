import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import RemittanceIcon from '@material-ui/icons/MonetizationOn';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import {
  getScholarshipFundingList,
  remitScholarshipRemittance,
  updateScholarshipRemittance
} from 'redux/actions';
import Select from 'components/common/Select/Select';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import { round as ROUND } from 'variables/scholarship';
import * as moment from 'moment';
import settings from 'settings';
import { getSchoolName, getAvailableScholarshipFunding, getScholarshipNextRound, formatCurrency } from 'utilities/helpers';
import { groups as GROUPS } from 'variables/user';
import _ from 'lodash';

const useStyles = makeStyles(styles);

const RemittanceDialog = ({ school, editId, totalStudent, open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const authProfile = useSelector(state => state.auth.profile);
  const isSchoolManagement = authProfile && authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT;

  const remittanceList = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceList);
  const fundingList = useSelector(state => state.scholarshipFunding.scholarshipFundingList);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const remittanceFilter = useSelector(state => state.scholarshipRemittance.scholarshipRemittanceFilter);

  const [academicYear, setAcademicYear] = useState(remittanceFilter?.academicYear || '');
  const [editRemittance, setEditRemittance] = useState(undefined);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);

  const [round, setRound] = useState(ROUND.ROUND_1);
  const [remittedAmount, setRemittedAmount] = useState(0);

  const [availableFund, setAvailableFund] = useState(0);
  const [requiredFund, setRequiredFund] = useState(0);
  const [hasAvailableFund, setHasAvailableFund] = useState(true);

  useEffect(() => {
    if (fundingList.data === undefined && school) {
      const params = {
        academicYear,
        school,
        page: settings.startPage,
        limit: settings.rowsPerPage
      };
      dispatch(getScholarshipFundingList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, school]);

  useEffect(() => {
    if (remittanceList.data && remittanceList.data.length) {
      if (editId) {
        const remittance = _.findLast(remittanceList.data, { id: editId });
        setEditRemittance(remittance);
      } else {
        const selectedRound = getScholarshipNextRound(remittanceList.data);
        setRound(selectedRound);
      }
    }
  }, [editId, remittanceList]);

  useEffect(() => {
    if (editRemittance) {
      setRound(editRemittance.round);
      setRemittedAmount(editRemittance.remitted_amount);
    }
  }, [editRemittance]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choices = academicYears.filter(academic => academic.status === ACADEMIC_STATUS.RUNNING)
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choices);

      // set default selected academicYear
      if (choices.length > 0 && academicYear === '') {
        setAcademicYear(choices[0].value);
      }
    }
  }, [academicYear, academicYears, translate]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, isSchoolManagement, translate]);

  const onChangeHandler = e => {
    const { value } = e.target;
    setRemittedAmount(value);
    setRequiredFund(value * totalStudent);
  };

  const handleConfirm = e => {
    e.preventDefault();

    if (checkAvailableFund()) {
      if (editId) {
        dispatch(updateScholarshipRemittance(editId, {
          remitted_amount: remittedAmount,
          required_fund: requiredFund
        })).then(result => {
          if (result) {
            onClose();
          }
        });
      } else {
        dispatch(remitScholarshipRemittance({
          academic_year_id: academicYear,
          school_id: school,
          round,
          remitted_amount: remittedAmount,
          required_fund: requiredFund
        })).then(result => {
          if (result) {
            onClose();
          }
        });
      }
    }
  };

  const checkAvailableFund = () => {
    if (editId) {
      setHasAvailableFund(true);
      const oldRequiredFund = parseInt(editRemittance.required_fund);
      if (oldRequiredFund >= requiredFund) {
        return true;
      }

      const availableFundInRound = _.findLast(fundingList.data, { round });
      if (parseInt(availableFundInRound.received) >= requiredFund) {
        return true;
      }

      if (round === ROUND.ROUND_1) {
        setAvailableFund(parseInt(availableFundInRound.received));
        setHasAvailableFund(false);
        return false;
      }

      const tempLeftOver = parseInt(availableFundInRound.leftover) + oldRequiredFund;
      if (tempLeftOver >= requiredFund) {
        return true;
      }

      setAvailableFund(tempLeftOver);
      setHasAvailableFund(false);
      return false;
    }

    const availableFund = parseInt(getAvailableScholarshipFunding(round, fundingList.data));
    setAvailableFund(availableFund);
    if (availableFund < requiredFund) {
      setHasAvailableFund(false);
      return false;
    }
    setHasAvailableFund(true);
    return true;
  };

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <RemittanceIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('scholarshipRemittance.addRemittance')}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('enrollment.academicYear')}
              placeholder={translate('enrollment.placeholder.academicYear')}
              value={academicYear}
              name="academicYear"
              onChange={onChangeHandler}
              choices={academicYearChoices}
              disabled
              helperText=""
            />
          </GridItem>

          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('classroom.school')}
              placeholder={translate('classroom.placeholder.school')}
              value={schoolChoices.length > 0 ? school : ''}
              name="school"
              onChange={onChangeHandler}
              choices={schoolChoices}
              disabled
              helperText=""
            />
          </GridItem>

          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('scholarshipRemittance.round')}
              placeholder={translate('scholarshipRemittance.placeholder.round')}
              value={round}
              name="round"
              onChange={onChangeHandler}
              choices={[
                { label: translate('scholarshipRemittance.round.option.round_1'), value: 'round_1' },
                { label: translate('scholarshipRemittance.round.option.round_2'), value: 'round_2' },
                { label: translate('scholarshipRemittance.round.option.round_3'), value: 'round_3' }
              ]}
              disabled
              helperText=""
            />
          </GridItem>
          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('scholarshipRemittance.remittedAmount')}
              placeholder={translate('scholarshipRemittance.placeholder.round')}
              value={remittedAmount}
              name="remittedAmount"
              onChange={onChangeHandler}
              choices={[
                { label: 0, value: 0 },
                { label: '80,000', value: 80000 },
                { label: '120,000', value: 120000 },
                { label: '160,000', value: 160000 },
                { label: '240,000', value: 240000 }
              ]}
              helperText=""
              require
            />
          </GridItem>
        </GridContainer>
        { !hasAvailableFund &&
          <p className={classes.infoMessage}>{translate('scholarshipRemittance.notAvailableFund', {
            availableFund: formatCurrency(availableFund),
            requiredFund: formatCurrency(requiredFund)
          })}</p>
        }
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>
          <ThemeButton
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            {translate('common.button.save')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

RemittanceDialog.propTypes = {
  school: PropTypes.number.isRequired,
  editId: PropTypes.number,
  totalStudent: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default RemittanceDialog;
