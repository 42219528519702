import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import KeyIcon from '@material-ui/icons/VpnKey';

import { useDispatch, useSelector } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';

import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import CardBody from 'components/theme/Card/CardBody';
import Card from 'components/theme/Card/Card';
import Input from 'components/common/Input/Input';
import { groups as GROUPS } from 'variables/user';

import {
  clearUnits, getDistricts,
  getProvinces,
  getUserGroups,
  getRawSchoolList, getUserList
} from 'redux/actions';
import { getGazetteerChoices } from 'utilities/gazetteer';

const ALL = 'all';
const UserListFilter = (props) => {
  const {
    classes,
    limit,
    page,
    userGroup,
    username,
    province,
    district,
    school,
    setUserGroup,
    setUsername,
    setProvince,
    setDistrict,
    setSchool
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const isLoadingUserGroups = useSelector(state => state.user.isLoadingGroup);
  const isLoadingRawSchoolList = useSelector(state => state.school.isLoading);
  const groups = useSelector(state => state.user.groups);
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const rawSchoolList = useSelector(state => state.school.rawSchoolList);
  const authProfile = useSelector(state => state.auth.profile);

  const [userGroupChoices, setUserGroupChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [isShowGazetteer, setIsShowGazetteer] = useState(true);
  const [isShowSchool, setIsShowSchool] = useState(true);

  useEffect(() => {
    if (authProfile && authProfile.groups) {
      if (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER || authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT) {
        setIsShowGazetteer(false);
      }
      if (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT) {
        setIsShowSchool(false);
      }
    }
    // eslint-disable-next-line
  }, [authProfile]);

  // get user groups
  const fetchGroups = useCallback(() => {
    dispatch(getUserGroups());
  }, [dispatch]);
  //
  useEffect(() => {
    if (!isLoadingUserGroups) {
      fetchGroups();
    }
    // eslint-disable-next-line
  }, [fetchGroups]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const availableGroups = [];
      const choices = Object.keys(GROUPS)
        .filter(index => {
          const groupObj = groups.find(g => g.name === GROUPS[index]);
          if (groupObj) {
            availableGroups[groupObj.name] = groupObj.id;
          }
          return groupObj;
        })
        .map(index => {
          return {
            label: translate(`useGroups.${GROUPS[index]}`),
            value: availableGroups[GROUPS[index]]
          };
        }
        );
      choices.unshift({ value: ALL, label: translate('common.all') });
      setUserGroupChoices(choices);
    }
  }, [groups, translate]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingGazetteer) {
      fetchProvincesData();
    }
    // eslint-disable-next-line
  }, [fetchProvincesData]);

  useEffect(
    () => {
      let filterProvinces = provinces;
      if (authProfile && authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER) {
        filterProvinces = filterProvinces.filter(p => p.id === authProfile.province.id);
      }
      setProvinceChoices(getGazetteerChoices(getActiveLanguage(localize).code, filterProvinces, translate));
    }
    // eslint-disable-next-line
      , [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== ALL) {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
      setDistrict(ALL);
    },
    [dispatch, province, setDistrict]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(
    () => {
      setDistrictChoices(getGazetteerChoices(getActiveLanguage(localize).code, districts, translate));
    }
    // eslint-disable-next-line
      , [districts, localize]);

  // get raw school lists
  const fetchRawSchoolList = useCallback(() => {
    let gazetteer;
    if (district !== ALL) {
      gazetteer = district.split(',')[1];
    } else if (district === ALL && province !== ALL) {
      gazetteer = province.split(',')[1];
    }
    const param = { gazetteer };
    dispatch(getRawSchoolList(param));
    setSchool(ALL);
  }, [dispatch, province, district, setSchool]);

  useEffect(() => {
    if (!isLoadingRawSchoolList) {
      fetchRawSchoolList();
    }
    // eslint-disable-next-line
  }, [fetchRawSchoolList]);

  useEffect(
    () => {
      if (rawSchoolList.length > 0) {
        const choices = rawSchoolList.map(s => {
          return {
            label: getActiveLanguage(localize).code === 'en'
              ? `${s.name_en} (${s.degree.name_en})`
              : `${s.name} (${s.degree.name})`,
            value: s.id
          };
        });
        choices.unshift({ value: ALL, label: translate('common.all') });
        setSchoolChoices(choices);
      } else {
        setSchoolChoices([{ value: ALL, label: translate('common.all') }]);
      }
    }
    // eslint-disable-next-line
      , [rawSchoolList, localize]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'userGroup':
        setUserGroup(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'province':
        setProvince(value);
        break;
      case 'school':
        setSchool(value);
        break;
      case 'district':
        setDistrict(value);
        break;
      default:
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const groupObj = groups.find(g => g.id === userGroup);
    let gazetteer;
    if (district !== ALL) {
      gazetteer = district.split(',')[1];
    } else if (district === ALL && province !== ALL) {
      gazetteer = province.split(',')[1];
    }
    const params = {
      user_group: userGroup === ALL ? undefined : userGroup,
      staff_group: groupObj ? groupObj.name : undefined,
      username: username !== '' ? username.trim() : undefined,
      gazetteer,
      province,
      district,
      school: school === ALL ? undefined : school,
      limit,
      page: page + 1
    };
    dispatch(getUserList(params));
  };

  return (
    <LoadablePaper
      rendered
      loading={false}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <KeyIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('userManagement.title')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('userManagement.userGroup')}
                    placeholder={translate('userManagement.placeholder.userGroup')}
                    value={userGroup}
                    name="userGroup"
                    onChange={e => onChangeHandler(e, 'userGroup')}
                    choices={userGroupChoices}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Input
                    label={translate('userManagement.username')}
                    placeholder={translate('userManagement.placeholder.username')}
                    value={username}
                    name="username"
                    onChange={e => onChangeHandler(e, 'username')}
                  />
                </GridItem>

                {
                  isShowGazetteer && (
                    <>
                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.province')}
                          placeholder={translate('gazetteer.placeholder.province')}
                          value={province}
                          name="province"
                          onChange={e => onChangeHandler(e, 'province')}
                          choices={provinceChoices}
                        />
                      </GridItem>

                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.district')}
                          placeholder={translate('gazetteer.placeholder.district')}
                          value={district}
                          name="district"
                          onChange={e => onChangeHandler(e, 'district')}
                          choices={districtChoices}
                          disabled={isLoadingGazetteer || districts.length === 0}
                        />
                      </GridItem>
                    </>
                  )
                }

                {
                  isShowSchool &&
                    <GridItem
                      xs={12}
                      md={6}
                    >
                      <Select
                        label={translate('classroom.school')}
                        placeholder={translate('classroom.placeholder.school')}
                        value={school}
                        name="school"
                        onChange={e => onChangeHandler(e, 'school')}
                        choices={schoolChoices}
                        disabled={isLoadingRawSchoolList || rawSchoolList.length === 0}
                      />
                    </GridItem>
                }
              </GridContainer>

              <div
                className={classes.allButton}
              >
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilter}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

UserListFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  limit: PropTypes.number,
  page: PropTypes.number,
  userGroup: PropTypes.string,
  username: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  school: PropTypes.string,
  setUserGroup: PropTypes.func,
  setUsername: PropTypes.func,
  setProvince: PropTypes.func,
  setDistrict: PropTypes.func,
  setSchool: PropTypes.func
};

export default UserListFilter;
