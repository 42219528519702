import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const ScoreTableCell = (props) => {
  const cellProps = props;
  const {
    onClick,
    tableRow: { rowId },
    column: { name: columnName }
  } = cellProps;
  const validationStatus = useSelector((state) => state.score.validationStatus);
  const columnStatus = validationStatus[rowId]?.[columnName];
  const valid = !columnStatus || columnStatus.isValid;
  const style = {
    ...(!valid ? { border: '1px solid red' } : null)
  };

  return (
    <Table.Cell
      {...props}
      style={style}
      tabIndex={0}
      onFocus={onClick}
    />
  );
};

export default ScoreTableCell;
