import * as actionTypes from 'redux/actionTypes';
import moment from 'moment';

export function errorSelected (index) {
  return (dispatch, getState) => {
    const state = getState().error;

    dispatch({
      type: actionTypes.ERROR_SELECTED,
      error: state.errors[index]
    });
  };
}

export function errorReceived (error) {
  return {
    type: actionTypes.ERROR_RECEIVED,
    error: {
      ...error,
      message: error.message,
      date: moment()
    }
  };
}
