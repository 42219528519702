import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import settings from 'settings';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';

import {
  getAcademicYears,
  getScholarshipFundingList,
  getSchoolList
} from 'redux/actions';
import * as moment from 'moment';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import FundingIcon from '@material-ui/icons/LocalAtm';
import CardBody from 'components/theme/Card/CardBody';
import Card from 'components/theme/Card/Card';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/sts/jss/views/scholarship/scholarshipStyle';

const useStyles = makeStyles(styles);

const FundingFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const isLoadingAcademic = useSelector(state => state.academicYear.isLoadingGet);
  const authProfile = useSelector(state => state.auth.profile);

  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isSchoolManagement = authProfile && authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT;

  const fundingFilter = useSelector(state => state.scholarshipFunding.scholarshipFundingFilter);
  const [school, setSchool] = useState(fundingFilter?.school || '');
  const [academicYear, setAcademicYear] = useState(fundingFilter?.academicYear || '');

  const [errorSchool, setErrorSchool] = useState(false);
  const [errorAcademicYear, setErrorAcademicYear] = useState(false);

  // fetch Academic Years
  useEffect(() => {
    if (!isLoadingAcademic) {
      dispatch(getAcademicYears());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choices = academicYears.filter(academic => academic.status === ACADEMIC_STATUS.RUNNING)
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choices);

      // set default selected academicYear
      if (choices.length > 0 && academicYear === '') {
        setAcademicYear(choices[0].value);
      }
    }
  }, [academicYear, academicYears, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (academicYear && isSchoolManagement) {
      const params = {
        academic_year_id: academicYear,
        school_id: authProfile.school_id
      };
      dispatch(getSchoolList(params, isSchoolManagement));
    }
  }, [dispatch, academicYear, authProfile, isSchoolManagement]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      if (isSchoolManagement) {
        setSchool(schoolList[0].id);
      }
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, isSchoolManagement, setSchool, translate]);

  useEffect(() => {
    if (academicYear && school) {
      const params = {
        school,
        page: settings.startPage,
        limit: settings.rowsPerPage
      };
      dispatch(getScholarshipFundingList(params));
    }
  }, [academicYear, school, dispatch]);

  const onChangeHandler = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'academicYear':
        setAcademicYear(value);
        break;
      case 'school':
        setSchool(value);
        break;
      default:
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    let canFilter = true;

    if (!academicYear) {
      canFilter = false;
      setErrorAcademicYear(true);
    } else {
      setErrorAcademicYear(false);
    }

    if (!school) {
      canFilter = false;
      setErrorSchool(true);
    } else {
      setErrorSchool(false);
    }

    if (canFilter) {
      const params = {
        school,
        page: settings.startPage,
        limit: fundingFilter?.limit || settings.rowsPerPage
      };
      dispatch(getScholarshipFundingList(params));
    }
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <FundingIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('scholarshipFunding.pageTitle')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="academicYear"
                    onChange={onChangeHandler}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={school}
                    name="school"
                    onChange={onChangeHandler}
                    choices={schoolChoices}
                    disabled={schoolChoices.length === 0 || isSchoolManagement}
                    require
                    error={errorSchool}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton}
              >
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilter}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

export default FundingFilter;
