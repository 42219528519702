import defaultListingStyle from 'assets/sts/jss/components/defaultListingStyle';

const graduationStyle = (theme) => ({
  ...defaultListingStyle(theme),

  toolbar: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  actionContainer: {
    float: 'right'
  }
});

export default graduationStyle;
