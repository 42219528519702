import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';

import UserListFilter from 'views/UserManagement/Partial/UserListFilter';

import { getTranslate } from 'react-localize-redux';
import styles from 'assets/sts/jss/views/userManagement/userManagementStyle';
import UserTable from './Partial/UserTable';
import * as ROUTES from 'variables/routeNames';
import ThemeButton from 'components/theme/CustomButtons/Button';
import { Link } from 'react-router-dom';
import settings from 'settings';
import { getUserList } from 'redux/actions';

const useStyles = makeStyles(styles);

const ALL = 'all';
const UserManagement = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const userFilter = useSelector(state => state.user.userFilter);
  const groups = useSelector(state => state.user.groups);
  const isLoading = useSelector(state => state.user.isLoading);

  const [limit, setLimit] = useState(userFilter.limit ? userFilter.limit : settings.rowsPerPage);
  const [page, setPage] = useState(userFilter.page ? userFilter.page - 1 : settings.startPage);
  const [userGroup, setUserGroup] = useState(userFilter.user_group ? userFilter.user_group : ALL);
  const [username, setUsername] = useState(userFilter.username ? userFilter.username : '');
  const [province, setProvince] = useState(userFilter.province ? userFilter.province : ALL);
  const [district, setDistrict] = useState(userFilter.district ? userFilter.district : ALL);
  const [school, setSchool] = useState(userFilter.school ? userFilter.school : ALL);

  const fetchUser = useCallback(() => {
    const groupObj = groups.find(g => g.id === userGroup);
    let gazetteer;
    if (district !== ALL) {
      gazetteer = district.split(',')[1];
    } else if (district === ALL && province !== ALL) {
      gazetteer = province.split(',')[1];
    }
    const params = {
      user_group: userGroup === ALL ? undefined : userGroup,
      staff_group: groupObj ? groupObj.name : undefined,
      username: username !== '' ? username.trim() : undefined,
      gazetteer,
      province,
      district,
      school: school === ALL ? undefined : school,
      limit,
      page: page + 1
    };
    dispatch(getUserList(params));
    // eslint-disable-next-line
  }, [dispatch, limit, page]);

  useEffect(() => {
    if (!isLoading) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [fetchUser, limit, page]);

  // set title
  const changeBrandText = useCallback(() => {
    document.title = `${translate('userManagement.title')} - STS`;
    document.getElementById('brandText').innerHTML = translate('userManagement.title');
  }, [translate]);

  useEffect(() => {
    changeBrandText();
  }, [changeBrandText]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <UserListFilter
          classes={classes}
          limit={limit}
          page={page}
          userGroup={userGroup}
          username={username}
          province={province}
          district={district}
          school={school}
          setUserGroup={setUserGroup}
          setUsername={setUsername}
          setProvince={setProvince}
          setDistrict={setDistrict}
          setSchool={setSchool}
        />

        <UserTable
          classes={classes}
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
        />

        <Link to={ROUTES.USER_NEW}>
          <ThemeButton
            color="primary"
            className={classes.allButton}
          >
            {translate('common.button.new')}
          </ThemeButton>
        </Link>
      </GridItem>
    </GridContainer>
  );
};

export default UserManagement;
