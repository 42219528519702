import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';
import { warning } from 'assets/sts/jss/sts-react';

const createEditAcademicYearStyle = (theme) => ({
  ...defaultFormStyle(theme),
  moveToNextStatus: {
    display: 'inline-block'
  },
  nextStatusButton: {
    backgroundColor: warning
  },
  firstArrow: {
    marginTop: '-2px'
  },
  secondArrow: {
    marginTop: '-2px',
    marginLeft: '-14px'
  }
});

export default createEditAcademicYearStyle;
