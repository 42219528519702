import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  logInError: undefined,
  logOutError: undefined,
  isProfileLoading: false,
  profile: undefined,
  isHeartBeatActive: false,
  heartBeatTimer: undefined,
  tokenValid: false
};

const logInSucceeded = (state) => ({
  ...state,
  logInError: undefined
});

const logInFailed = (state, action) => ({
  ...state,
  logInError: action.logInError
});

const logOutSucceeded = (state) => ({
  ...state,
  logOutError: undefined,
  profile: undefined
});

const logOutFailed = (state, action) => ({
  ...state,
  logOutError: action.logOutError
});

const profileLoadRequested = (state) => ({
  ...state,
  isProfileLoading: true
});

const profileLoadSucceeded = (state, action) => ({
  ...state,
  isProfileLoading: false,
  profile: action.profile
});

const profileLoadFailed = (state) => ({
  ...state,
  isProfileLoading: false
});

const heartBeatActivated = (state, action) => ({
  ...state,
  isHeartBeatActive: true,
  heartBeatTimer: action.heartBeatTimer
});

const heartBeatDeactivated = (state) => ({
  ...state,
  isHeartBeatActive: false,
  heartBeatTimer: undefined
});

const tokenRefreshSucceeded = (state) => ({
  ...state,
  tokenValid: true
});

const tokenRefreshFailed = (state) => ({
  ...state,
  tokenValid: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCEEDED:
      return logInSucceeded(state, action);
    case actionTypes.LOGIN_FAILED:
      return logInFailed(state, action);
    case actionTypes.LOGOUT_SUCCEEDED:
      return logOutSucceeded(state, action);
    case actionTypes.LOGOUT_FAILED:
      return logOutFailed(state, action);
    case actionTypes.PROFILE_LOAD_REQUESTED:
      return profileLoadRequested();
    case actionTypes.PROFILE_LOAD_SUCCEEDED:
      return profileLoadSucceeded(state, action);
    case actionTypes.PROFILE_LOAD_FAILED:
      return profileLoadFailed(state, action);
    case actionTypes.HEARTBEAT_ACTIVATED:
      return heartBeatActivated(state, action);
    case actionTypes.HEARTBEAT_DEACTIVATED:
      return heartBeatDeactivated(state, action);
    case actionTypes.TOKEN_REFRESH_SUCCEEDED:
      return tokenRefreshSucceeded(state, action);
    case actionTypes.TOKEN_REFRESH_FAILED:
      return tokenRefreshFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
