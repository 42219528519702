import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/enrollment/dialogInputStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import EnrollIcon from '@material-ui/icons/LibraryBooksOutlined';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import Select from 'components/common/Select/Select';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';
import { ENROLL_STATUS, TRANSFER_STATUS } from 'variables/enrollStatus';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner
  from 'components/common/LoadingSpinner/LoadingSpinner';
import { enrollStudent, resetStudentResult, showErrorNotification } from 'redux/actions';
import settings from 'settings';
import _ from 'lodash';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { degree as DEGREE } from 'variables/school';

const useStyles = makeStyles(styles);

export default function DialogInput (props) {
  const classes = useStyles();
  const {
    dialogTitle,
    setDialogTitle,
    isOpenDialog,
    setIsOpenDialog,
    editId,
    action,
    rows,
    enrollmentDate,
    isEnrollBySearch,
    setIsSaveAndNew,
    setAction,
    setOpen,
    setEditId,
    academicYear,
    allGrades,
    currentGrade,
    school,
    academicYears
  } = props;

  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const authProfile = useSelector(state => state.auth.profile);
  const studentResult = useSelector(state => state.enrollment.studentResult);
  const isEnrolling = useSelector(state => state.enrollment.isEnrolling);
  const allScholarships = useSelector(state => state.studentOption.scholarship);
  const allDisabilities = useSelector(state => state.studentOption.disability);
  const allHealthProblems = useSelector(state => state.studentOption.healthProblem);
  const allPersonalProblems = useSelector(state => state.studentOption.personalProblem);
  const allEthnicity = useSelector(state => state.studentOption.ethnicity);
  const enrollmentStatusChoices = _.pickBy(ENROLL_STATUS, (value) => value !== 'dropout');

  const [stsId, setStsId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [lastName, setLastName] = useState('');
  const [errorLastName, setErrorLastName] = useState(false);
  const [birthDate, setBirthDate] = useState('');
  const [age, setAge] = useState('');
  const [grade, setGrade] = useState(currentGrade);
  const [errorGrade, setErrorGrade] = useState(false);
  const [errorBirthDate, setErrorBirthDate] = useState(false);
  const [gender, setGender] = useState('');
  const [errorGender, setErrorGender] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState('');
  const [errorEnrollmentStatus, setErrorEnrollmentStatus] = useState(false);
  const [transferStatus, setTransferStatus] = useState('');
  const [errorTransferStatus, setErrorTransferStatus] = useState(false);
  const [ethnicity, setEthnicity] = useState('');
  const [disabilities, setDisabilities] = useState([]);
  const [healthProblems, setHealthProblems] = useState([]);
  const [personalProblems, setPersonalProblems] = useState([]);
  const [scholarships, setScholarships] = useState([]);
  const [editDropOutId, setEditDropOutId] = useState('');
  const nameOfEnrollmentBy = authProfile.last_name && authProfile.first_name
    ? `${authProfile.last_name} ${authProfile.first_name}`
    : authProfile.username;
  const [errorEthnicity, setErrorEthnicity] = useState(false);
  const [errorDisability, setErrorDisability] = useState(false);
  const [errorHealthProblem, setErrorHealthProblem] = useState(false);
  const [errorPersonalProblem, setErrorPersonalProblem] = useState(false);
  const [errorScholarship, setErrorScholarship] = useState(false);
  const [noDisabilityData, setNoDisabilityData] = useState(false);
  const [noHealthProblemData, setNoHealthProblemData] = useState(false);
  const [noPersonalProblemData, setNoPersonalProblemData] = useState(false);
  const [noScholarshipData, setNoScholarshipData] = useState(false);

  const calculateAge = dob => {
    const academicYearDate = academicYears.find(a => a.id === (academicYear));
    const academicYearStartDate = moment(academicYearDate.start_date, settings.dateFormat);
    const studentAge = academicYearStartDate.diff(moment(dob, settings.dateFormat), 'year');
    const numberOfMonth = academicYearStartDate.diff(moment(dob, settings.dateFormat), 'month');
    let age = studentAge;
    if (studentAge === 5) {
      if (numberOfMonth >= 70) {
        age = studentAge + 1;
      }
    }
    setAge(age);
  };

  const setNeededEditFields = useCallback(
    () => {
      if (editId !== '') {
        const index = rows.findIndex(row => row.id === editId);
        const neededEditRow = rows[index];
        setStsId(neededEditRow.stsId || '');
        setFirstName(neededEditRow.firstName);
        setGrade(currentGrade);
        setLastName(neededEditRow.lastName);
        setGender(neededEditRow.gender);
        setBirthDate(neededEditRow.birthDate);
        setEnrollmentStatus(neededEditRow.enrollmentStatus);
        setTransferStatus(neededEditRow.transferStatus === '' ? '' : neededEditRow.transferStatus);
        setEthnicity(_.isEmpty(neededEditRow.ethnicity) ? 0 : neededEditRow.ethnicity.id);
        if (_.isEmpty(neededEditRow.disabilities)) {
          setDisabilities([]);
          setNoDisabilityData(true);
        } else {
          setDisabilities(neededEditRow.disabilities.map(disability => disability.id));
        }

        if (_.isEmpty(neededEditRow.healthProblems)) {
          setHealthProblems([]);
          setNoHealthProblemData(true);
        } else {
          setHealthProblems(neededEditRow.healthProblems.map(problem => problem.id));
        }

        if (_.isEmpty(neededEditRow.personalProblems)) {
          setPersonalProblems([]);
          setNoPersonalProblemData(true);
        } else {
          setPersonalProblems(neededEditRow.personalProblems.map(problem => problem.id));
        }

        if (_.isEmpty(neededEditRow.scholarships)) {
          setScholarships([]);
          setNoScholarshipData(true);
        } else {
          setScholarships(neededEditRow.scholarships.map(scholarship => scholarship));
        }

        calculateAge(neededEditRow.birthDate);
      } else {
        resetEnrollField();
      }
    },
    // eslint-disable-next-line
      [editId]
  );

  useEffect(() => {
    setNeededEditFields();
  }, [setNeededEditFields]);

  useEffect(() => {
    if (studentResult !== '' && studentResult !== 'enrolled' && studentResult !== undefined) {
      setStsId(studentResult.emis_student_id);
      setFirstName(studentResult.first_name);
      setLastName(studentResult.last_name);
      setGrade(studentResult.grade);
      setBirthDate(studentResult.birth_date);
      setGender(studentResult.gender);
      setTransferStatus(studentResult.transfer_status === '' ? '' : studentResult.transfer_status);
      setEthnicity(studentResult.ethnicity ? studentResult.ethnicity : 0);
      setDisabilities(studentResult.disabilities);
      setHealthProblems(studentResult.health_problems);
      setPersonalProblems(studentResult.personal_problems);

      if (studentResult.is_drop_out_last_year) {
        setEnrollmentStatus(4);
      }

      if (studentResult.previous_enroll_status !== undefined && studentResult.current_enroll_id) {
        setEnrollmentStatus(studentResult.previous_enroll_status);
        setAction('edit');
        setDialogTitle(translate('enrollment.edit'));
        setEditDropOutId(studentResult.current_enroll_id);
      }
      if (_.isEmpty(studentResult.disabilities)) {
        setNoDisabilityData(true);
      }

      if (_.isEmpty(studentResult.health_problems)) {
        setNoHealthProblemData(true);
      }

      if (_.isEmpty(studentResult.personal_problems)) {
        setNoPersonalProblemData(true);
      }

      if (_.isEmpty(studentResult.scholarships)) {
        setNoScholarshipData(true);
      }
      calculateAge(studentResult.birth_date);
    }
    // eslint-disable-next-line
  }, [studentResult]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'grade':
        setGrade(value);
        break;
      case 'enrollmentStatus':
        setEnrollmentStatus(value);
        break;
      case 'transferStatus':
        setTransferStatus(value);
        break;
      case 'ethnicity':
        setEthnicity(value);
        break;
      case 'disabilities':
        setDisabilities(value);
        break;
      case 'healthProblems':
        setHealthProblems(value);
        break;
      case 'personalProblems':
        setPersonalProblems(value);
        break;
      case 'scholarships':
        setScholarships(value);
        break;
      default:
    }
  };
  const onChangeDateHandler = (value, params) => {
    const date = typeof value === 'object' ? value.format(settings.dateFormat) : value;
    setBirthDate(date);
    calculateAge(value);
  };

  const commitChanges = () => {
    const data = {
      stsId,
      firstName,
      lastName,
      gender,
      birthDate,
      grade,
      enrollmentStatus,
      transferStatus,
      ethnicity: ethnicity === 0 ? '' : ethnicity,
      disabilities,
      healthProblems,
      personalProblems,
      scholarships,
      enrollmentDate,
      enrollmentBy: authProfile.id
    };
    switch (action) {
      case 'create': {
        if (!validateEnrollField()) {
          dispatch(showErrorNotification(translate('common.error.form')));
          return;
        }
        dispatch(enrollStudent(data, translate('enrollment.enrollSuccess')));
        break;
      }
      case 'edit': {
        if (!validateEnrollField()) {
          dispatch(showErrorNotification(translate('common.error.form')));
          return;
        }
        const id = editId === '' ? editDropOutId : editId;
        dispatch(enrollStudent(data, translate('enrollment.editSuccessfulMessage'), id));
        setEditId('');
        setEditDropOutId('');
        break;
      }
      default:
    }
  };

  const validateEnrollField = () => {
    let isCanSave = true;
    if (!firstName) {
      isCanSave = false;
      setErrorFirstName(true);
    } else {
      setErrorFirstName(false);
    }

    if (grade === null) {
      isCanSave = false;
      setErrorGrade(true);
    } else {
      setErrorGrade(false);
    }

    if (!lastName) {
      isCanSave = false;
      setErrorLastName(true);
    } else {
      setErrorLastName(false);
    }

    if (!birthDate) {
      isCanSave = false;
      setErrorBirthDate(true);
    } else {
      setErrorBirthDate(false);
    }

    if (!gender) {
      isCanSave = false;
      setErrorGender(true);
    } else {
      setErrorGender(false);
    }

    if (enrollmentStatus === '') {
      isCanSave = false;
      setErrorEnrollmentStatus(true);
    } else {
      setErrorEnrollmentStatus(false);
    }

    if (transferStatus === '') {
      isCanSave = false;
      setErrorTransferStatus(true);
    } else {
      setErrorTransferStatus(false);
    }

    if (ethnicity === '') {
      isCanSave = false;
      setErrorEthnicity(true);
    } else {
      setErrorEthnicity(false);
    }

    if (!disabilities.length && noDisabilityData === false) {
      isCanSave = false;
      setErrorDisability(true);
    } else {
      setErrorDisability(false);
    }

    if (!healthProblems.length && noHealthProblemData === false) {
      isCanSave = false;
      setErrorHealthProblem(true);
    } else {
      setErrorHealthProblem(false);
    }

    if (!personalProblems.length && noPersonalProblemData === false) {
      isCanSave = false;
      setErrorPersonalProblem(true);
    } else {
      setErrorPersonalProblem(false);
    }

    if (!scholarships.length && noScholarshipData === false) {
      isCanSave = false;
      setErrorScholarship(true);
    } else {
      setErrorScholarship(false);
    }

    return isCanSave;
  };

  const resetEnrollField = () => {
    setStsId('');
    setFirstName('');
    setLastName('');
    setGender('');
    setBirthDate('');
    setEnrollmentStatus('');
    setTransferStatus('');
    setEthnicity('');
    setDisabilities([]);

    setHealthProblems([]);
    setPersonalProblems([]);
    setScholarships([]);

    setErrorFirstName(false);
    setErrorLastName(false);
    setErrorGender(false);
    setErrorGrade(false);
    setErrorBirthDate(false);
    setErrorEnrollmentStatus(false);
    setErrorTransferStatus(false);
    setErrorScholarship(false);
    setErrorPersonalProblem(false);
    setErrorDisability(false);
    setErrorEthnicity(false);
    setErrorHealthProblem(false);
    setNoScholarshipData(false);
    setNoPersonalProblemData(false);
    setNoHealthProblemData(false);
    setNoDisabilityData(false);
  };

  const onHandleClose = () => {
    setIsOpenDialog(false);
    setAction('create');
    setDialogTitle(translate('enrollment.new'));
    setEditId('');
    dispatch(resetStudentResult());
    resetEnrollField();
  };

  const onHandleSave = () => {
    commitChanges();
    setIsSaveAndNew(false);
    if (validateEnrollField()) {
      setIsOpenDialog(false);
      setAction('create');
      setDialogTitle(translate('enrollment.new'));
      setOpen(false);
      dispatch(resetStudentResult());
      resetEnrollField();
    }
  };

  const onHandleSaveNew = () => {
    commitChanges();
    setIsOpenDialog(true);
    setAge('');
    if (validateEnrollField()) {
      setAction('create');
      setDialogTitle(translate('enrollment.new'));
      setIsSaveAndNew(true);
      setOpen(false);
      dispatch(resetStudentResult());
      resetEnrollField();
    }
  };

  const handleNoDataToggle = (e, params) => {
    const value = e.target.checked;
    switch (params) {
      case 'healthProblems':
        setNoHealthProblemData(value);
        if (value === true) {
          setErrorHealthProblem(false);
          setHealthProblems([]);
        }
        break;
      case 'disabilities':
        setNoDisabilityData(e.target.checked);
        if (value === true) {
          setErrorDisability(false);
          setDisabilities([]);
        }
        break;
      case 'personalProblems':
        setNoPersonalProblemData(e.target.checked);
        if (value === true) {
          setErrorPersonalProblem(false);
          setPersonalProblems([]);
        }
        break;
      case 'scholarships':
        setNoScholarshipData(e.target.checked);
        if (value === true) {
          setErrorScholarship(false);
          setScholarships([]);
        }
        break;
      default:
    }
  };
  const ethnicityChoices = allEthnicity.map(ethnicity => ({
    label: getActiveLanguage(localize).code === 'en' ? ethnicity.name_en : ethnicity.name,
    value: ethnicity.id
  }));

  ethnicityChoices.unshift({
    label: translate('student.noEthnicity'),
    value: 0
  });
  const getGradeChoices = school => {
    if (allGrades.length > 0) {
      const schoolDegree = school.degree.number;
      const degreeMatchGrade = DEGREE[schoolDegree].grade;
      return allGrades
        .sort((a, b) => a.number - b.number)
        .filter(g => degreeMatchGrade.includes(g.number))
        .map(grade => ({
          label: getActiveLanguage(localize).code === 'en'
            ? grade.name_en
            : grade.name,
          value: grade.number
        }));
    } else {
      return [];
    }
  };
  return (
    <Dialog
      open={isOpenDialog}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <EnrollIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>

          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {dialogTitle}
            </h6>
            <p className={classes.dialogDescription}>
              {`${translate('enrollment.enrollmentDate')}: ${enrollmentDate}, ${translate('enrollment.enrolledBy')}: ${nameOfEnrollmentBy}`}
            </p>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={isEnrolling ? classes.dialogContentLoading : classes.dialogContent}>
        {
          isEnrolling
            ? <LoadingSpinner
              classes={classes.loading}
              loadingBoxClass={classes.loadingBoxClass}
            />
            : (
              <GridContainer>
                <GridItem
                  xs={12}
                >
                  <Input
                    label={translate('common.stsId')}
                    placeholder={translate('enrollment.placeholder.stsId')}
                    value={stsId}
                    name="stsId"
                    disabled
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Input
                    require
                    label={translate('common.lastName')}
                    placeholder={translate('common.placeholder.lastName')}
                    value={lastName}
                    name="lastName"
                    onChange={e => onChangeHandler(e, 'lastName')}
                    error={errorLastName}
                    helperText={errorLastName ? translate('common.error.require') : ''}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Input
                    require
                    label={translate('common.firstName')}
                    placeholder={translate('common.placeholder.firstName')}
                    value={firstName}
                    name="firstName"
                    onChange={e => onChangeHandler(e, 'firstName')}
                    error={errorFirstName}
                    helperText={errorFirstName ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    require
                    label={translate('common.gender')}
                    placeholder={translate('common.placeholder.gender')}
                    value={gender}
                    name="gender"
                    onChange={e => onChangeHandler(e, 'gender')}
                    choices={[
                      { label: translate('common.genderType.f'), value: 'f' },
                      { label: translate('common.genderType.m'), value: 'm' }
                    ]}
                    error={errorGender}
                    helperText={errorGender ? translate('common.error.require') : ''}
                  />
                </GridItem>
                <GridItem
                  xs={6}
                  md={3}
                >
                  <DateTimePicker
                    require
                    name='birthDate'
                    value={birthDate}
                    onChange={(e) => onChangeDateHandler(e)}
                    timeFormat={false}
                    label={translate('enrollment.birthDate')}
                    placeholder={translate('enrollment.placeholder.birthDate')}
                    error={errorBirthDate}
                    helperText={errorBirthDate ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={6}
                  md={3}
                >
                  <Input
                    label={translate('common.age')}
                    value={age}
                    name="age"
                    readOnly
                  />
                </GridItem>
                {editId && (
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('enrollment.grade')}
                      placeholder={translate('enrollment.placeholder.grade')}
                      value={grade}
                      name="grade"
                      onChange={e => onChangeHandler(e, 'grade')}
                      choices={getGradeChoices(school)}
                      require
                      error={errorGrade}
                      helperText={errorGrade ? translate('common.error.require') : ''}
                    />
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    require
                    label={translate('enrollment.enrollmentStatus')}
                    placeholder={translate('enrollment.placeholder.enrollmentStatus')}
                    value={enrollmentStatus}
                    name="enrollmentStatus"
                    onChange={e => onChangeHandler(e, 'enrollmentStatus')}
                    choices={
                      Object.keys(enrollmentStatusChoices).map(status => (
                        {
                          label: translate(`enrollment.status.${ENROLL_STATUS[status]}`),
                          value: status
                        }
                      ))
                    }
                    error={errorEnrollmentStatus}
                    helperText={errorEnrollmentStatus ? translate('common.error.require') : ''}
                    disabled={studentResult !== '' && studentResult !== 'enrolled' && studentResult !== undefined && studentResult.is_drop_out_last_year}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    require
                    label={translate('enrollment.transferStatus')}
                    placeholder={translate('enrollment.placeholder.transferStatus')}
                    value={transferStatus}
                    name="transferStatus"
                    onChange={e => onChangeHandler(e, 'transferStatus')}
                    choices={
                      Object.keys(TRANSFER_STATUS).map(status => (
                        {
                          label: translate(`transfer.status.${TRANSFER_STATUS[status]}`),
                          value: status
                        }
                      ))
                    }
                    error={errorTransferStatus}
                    helperText={errorTransferStatus ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    require
                    label={translate('enrollment.ethnicity')}
                    placeholder={translate('enrollment.placeholder.ethnicity')}
                    value={ethnicity}
                    name="ethnicity"
                    onChange={e => onChangeHandler(e, 'ethnicity')}
                    choices={ethnicityChoices}
                    error={errorEthnicity}
                    helperText={errorEthnicity ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={8}
                  md={4}
                >
                  <Select
                    require
                    multiple
                    label={translate('enrollment.disability')}
                    placeholder={translate('enrollment.placeholder.disability')}
                    value={disabilities}
                    name="disabilities"
                    onChange={e => onChangeHandler(e, 'disabilities')}
                    choices={
                      allDisabilities.map(disability => ({
                        label: getActiveLanguage(localize).code === 'en' ? disability.name_en : disability.name,
                        value: disability.id,
                        isRenderAsParents: disability.parent_id === 0 && disability.all_children.length === 0,
                        disabled: disability.all_children.length !== 0
                      }))
                    }
                    disabled={noDisabilityData}
                    error={errorDisability}
                    helperText={errorDisability ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={4}
                  md={2}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noDisabilityData}
                            onChange={e => handleNoDataToggle(e, 'disabilities')}
                            color="primary"
                          />
                        }
                        label={translate('common.no')}
                        className={classes.toggleStyle}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

                <GridItem
                  xs={8}
                  md={4}
                >
                  <Select
                    require
                    multiple
                    label={translate('enrollment.healthProblem')}
                    placeholder={translate('enrollment.placeholder.healthProblem')}
                    value={healthProblems}
                    name="healthProblems"
                    onChange={e => onChangeHandler(e, 'healthProblems')}
                    choices={
                      allHealthProblems.map(healthProblem => ({
                        label: getActiveLanguage(localize).code === 'en' ? healthProblem.name_en : healthProblem.name,
                        value: healthProblem.id
                      }))
                    }
                    disabled={noHealthProblemData}
                    error={errorHealthProblem}
                    helperText={errorHealthProblem ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={4}
                  md={2}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noHealthProblemData}
                            onChange={e => handleNoDataToggle(e, 'healthProblems')}
                            color="primary"
                          />
                        }
                        label={translate('common.no')}
                        className={classes.toggleStyle}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

                <GridItem
                  xs={8}
                  md={4}
                >
                  <Select
                    require
                    multiple
                    label={translate('enrollment.personalProblem')}
                    placeholder={translate('enrollment.placeholder.personalProblem')}
                    value={personalProblems}
                    name="personalProblems"
                    onChange={e => onChangeHandler(e, 'personalProblems')}
                    choices={
                      allPersonalProblems.map(personalProblem => ({
                        label: getActiveLanguage(localize).code === 'en' ? personalProblem.name_en : personalProblem.name,
                        value: personalProblem.id
                      }))
                    }
                    disabled={noPersonalProblemData}
                    error={errorPersonalProblem}
                    helperText={errorPersonalProblem ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={4}
                  md={2}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noPersonalProblemData}
                            onChange={e => handleNoDataToggle(e, 'personalProblems')}
                            color="primary"
                          />
                        }
                        label={translate('common.no')}
                        className={classes.toggleStyle}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

                <GridItem
                  xs={8}
                  md={4}
                >
                  <Select
                    require
                    multiple
                    label={translate('enrollment.scholarship')}
                    placeholder={translate('enrollment.placeholder.scholarship')}
                    value={scholarships}
                    name="scholarships"
                    onChange={e => onChangeHandler(e, 'scholarships')}
                    choices={
                      allScholarships.map(scholarship => ({
                        label: scholarship.type,
                        value: scholarship.id
                      }))
                    }
                    disabled={noScholarshipData}
                    error={errorScholarship}
                    helperText={errorScholarship ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={4}
                  md={2}
                >
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noScholarshipData}
                            onChange={e => handleNoDataToggle(e, 'scholarships')}
                            color="primary"
                          />
                        }
                        label={translate('common.no')}
                        className={classes.toggleStyle}
                      />
                    </FormGroup>
                  </FormControl>
                </GridItem>

              </GridContainer>
            )
        }
      </DialogContent>
      {
        !isEnrolling && (
          <DialogActions
            className={classes.dialogAction}
          >
            {
              (dialogTitle !== translate('enrollment.edit') || isEnrollBySearch) && (
                <ThemeButton
                  onClick={onHandleSaveNew}
                  color="primary"
                  variant="contained"
                  className="fLeft"
                >
                  {translate('common.button.enrollNew')}
                </ThemeButton>
              )
            }

            <div className={classes.normalSaveClose}>
              <ThemeButton
                onClick={onHandleClose}
                variant="contained"
                className={classes.buttonDefault}
              >
                {translate('common.button.close')}
              </ThemeButton>

              <ThemeButton
                onClick={onHandleSave}
                color="primary"
                variant="contained"
              >
                {dialogTitle !== translate('enrollment.edit') ? translate('common.button.enroll') : translate('common.button.save')}
              </ThemeButton>
            </div>
          </DialogActions>
        )
      }
    </Dialog>
  );
}

DialogInput.propTypes = {
  enrollmentDate: PropTypes.any,
  isEnrollBySearch: PropTypes.bool,
  dialogTitle: PropTypes.string,
  setDialogTitle: PropTypes.func,
  isOpenDialog: PropTypes.bool,
  setIsOpenDialog: PropTypes.func,
  academicYears: PropTypes.array,
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  allGrades: PropTypes.array,
  editId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currentGrade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  action: PropTypes.string,
  rows: PropTypes.array,
  setIsSaveAndNew: PropTypes.func,
  setAction: PropTypes.func,
  setOpen: PropTypes.func,
  setEditId: PropTypes.func
};
