import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { primaryColor } from 'assets/theme/jss/material-dashboard-pro-react';
import PropTypes from 'prop-types';

const DELAY = 0;

const LoadingSpinner = (props) => {
  window.setTimeout(() => {
    [].map.call(
      document.getElementsByClassName('loading'),
      (ele) => `${ele.className} show`
    );
  }, DELAY);

  return (
    <div className={`${props.classes || ''} loading`}>
      <div className={`${props.loadingBoxClass || ''} loading-box`}>
        <CircularProgress size={100}style={{
          color: primaryColor[0]
        }}/>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  classes: PropTypes.string,
  loadingBoxClass: PropTypes.string
};

export default LoadingSpinner;
