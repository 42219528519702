import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import ScholarshipIcon from '@material-ui/icons/School';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Input from 'components/common/Input/Input';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getScholarshipStudent } from 'redux/actions';

const useStyles = makeStyles(styles);

const AddStudentDialog = ({ school, open, onClose, onConfirm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const [stsId, setStsId] = useState('');
  const [confirmStsId, setConfirmStsId] = useState('');

  const [errorStsId, setErrorStsId] = useState(false);
  const [errorConfirmStsId, setErrorConfirmStsId] = useState(false);

  const onChangeHandler = e => {
    const { name, value } = e.target;

    switch (name) {
      case 'stsId':
        setStsId(value);
        break;
      case 'confirmStsId':
        setConfirmStsId(value);
        break;
      default:
    }
  };

  const handleConfirm = e => {
    e.preventDefault();

    let isValid = true;

    const trimStsId = stsId.trim();
    const trimConfirmStsId = confirmStsId.trim();
    setStsId(trimStsId);
    setConfirmStsId(trimConfirmStsId);

    if (!trimStsId) {
      isValid = false;
      setErrorStsId(true);
    } else {
      setErrorStsId(false);
    }

    if (!trimConfirmStsId || trimStsId !== trimConfirmStsId) {
      isValid = false;
      setErrorConfirmStsId(true);
    } else {
      setErrorConfirmStsId(false);
    }

    if (isValid) {
      dispatch(getScholarshipStudent(school, trimStsId)).then(result => {
        if (result) {
          onConfirm(result);
        }
      });
    }
  };

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <ScholarshipIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate('scholarship.addStudent')}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
            md={6}
          >
            <Input
              require
              label={translate('common.stsId')}
              placeholder={translate('transfer.placeholder.stsId')}
              value={stsId}
              name="stsId"
              onChange={onChangeHandler}
              error={errorStsId}
              helperText={errorStsId ? translate('common.error.require') : ''}
            />
          </GridItem>
          <GridItem
            xs={12}
            md={6}
          >
            <Input
              require
              label={translate('transfer.confirmStsId')}
              placeholder={translate('transfer.placeholder.stsId')}
              value={confirmStsId}
              name="confirmStsId"
              onChange={onChangeHandler}
              error={errorConfirmStsId}
              helperText={errorConfirmStsId
                ? confirmStsId ? translate('transfer.invalidConfirmStsId') : translate('common.error.require')
                : ''
              }
            />
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>
          <ThemeButton
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            {translate('common.button.next')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddStudentDialog.propTypes = {
  school: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default AddStudentDialog;
