import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/sts/jss/views/transfer/transferDialogStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import FundingIcon from '@material-ui/icons/LocalAtm';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import DialogActions from '@material-ui/core/DialogActions';
import ThemeButton from 'components/theme/CustomButtons/Button';
import Input from 'components/common/Input/Input';
import DateTimePicker from 'components/common/DateTimePicker/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { addScholarshipFunding, updateScholarshipFunding } from 'redux/actions';
import Select from 'components/common/Select/Select';
import * as ACADEMIC_STATUS from 'variables/academicYearStatus';
import * as moment from 'moment';
import settings from 'settings';
import { formatCurrency, getSchoolName, getScholarshipNextRound } from 'utilities/helpers';
import { groups as GROUPS } from 'variables/user';
import { round as ROUND } from 'variables/scholarship';
import _ from 'lodash';

const useStyles = makeStyles(styles);

const AddFundDialog = ({ school, editId, open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const authProfile = useSelector(state => state.auth.profile);
  const isSchoolManagement = authProfile && authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT;

  const scholarshipFundingList = useSelector(state => state.scholarshipFunding.scholarshipFundingList);
  const academicYears = useSelector(state => state.academicYear.academicYears);
  const schoolList = useSelector(state => state.school.schoolList);
  const transferFilter = useSelector(state => state.scholarship.scholarshipFilter);
  const [academicYear, setAcademicYear] = useState(transferFilter?.academicYear || '');
  const [editFunding, setEditFunding] = useState(undefined);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);

  const [round, setRound] = useState(ROUND.ROUND_1);

  const [receivedFund, setReceivedFund] = useState('');
  const [errorReceivedFund, setErrorReceivedFund] = useState(false);

  const [receivedAt, setReceivedAt] = useState('');
  const [errorReceivedAt, setErrorReceivedAt] = useState(false);

  useEffect(() => {
    if (editId && scholarshipFundingList.data && scholarshipFundingList.data.length) {
      const funding = _.findLast(scholarshipFundingList.data, { id: editId });
      setEditFunding(funding);
    } else {
      setReceivedAt(moment(new Date()).format(settings.dateFormat));
      const selectedRound = getScholarshipNextRound(scholarshipFundingList?.data || []);
      setRound(selectedRound);
    }
  }, [editId, scholarshipFundingList]);

  useEffect(() => {
    if (editFunding) {
      setRound(editFunding.round);
      setReceivedFund(editFunding.received);
      setReceivedAt(moment(editFunding.received_at).format(settings.dateFormat));
    }
  }, [editFunding]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choices = academicYears.filter(academic => academic.status === ACADEMIC_STATUS.RUNNING)
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choices);

      // set default selected academicYear
      if (choices.length > 0 && academicYear === '') {
        setAcademicYear(choices[0].value);
      }
    }
  }, [academicYear, academicYears, translate]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
    } else {
      setSchoolChoices([]);
    }
  }, [academicYear, schoolList, localize, isSchoolManagement, translate]);

  const onChangeHandler = e => {
    const { name, value } = e.target;

    switch (name) {
      case 'round':
        setRound(value);
        break;
      case 'receivedFund':
        validCurrency(value);
        break;
      default:
    }
  };

  const onChangeDateHandler = (value) => {
    const date = typeof value === 'object' ? value.format(settings.dateFormat) : value;
    setReceivedAt(date);
  };

  const validCurrency = value => {
    const regex = /^\d*$/;
    // remove comma(s)
    const exactValue = value.replace(/,/g, '');
    if (exactValue === '' || regex.test(exactValue)) {
      setReceivedFund(exactValue);
    }
  };

  const handleConfirm = e => {
    e.preventDefault();
    if (isFieldsValid()) {
      if (editId) {
        dispatch(updateScholarshipFunding(editId, {
          round,
          received: receivedFund,
          received_at: receivedAt
        })).then(result => {
          if (result) {
            onClose();
          }
        });
      } else {
        dispatch(addScholarshipFunding({
          academic_year_id: academicYear,
          school_id: school,
          round,
          received: receivedFund,
          received_at: receivedAt
        })).then(result => {
          if (result) {
            onClose();
          }
        });
      }
    }
  };

  const isFieldsValid = () => {
    let isValid = true;

    if (_.isEmpty(receivedFund)) {
      isValid = false;
      setErrorReceivedFund(true);
    } else {
      setErrorReceivedFund(false);
    }

    if (_.isEmpty(receivedAt)) {
      isValid = false;
      setErrorReceivedAt(true);
    } else {
      setErrorReceivedAt(false);
    }

    return isValid;
  };

  return (
    <Dialog
      open={open}
      className={classes.dialogHelp}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>
        <div>
          <div className={classes.firstGrid}>
            <Avatar className={classes.avatar}>
              <FundingIcon
                className={classes.avatarIcon}
              />
            </Avatar>
          </div>
          <div className={classes.secondGrid}>
            <h6 className={classes.dialogHeader}>
              {translate(`scholarshipFunding.${editId ? 'editFund' : 'addFund'}`)}
            </h6>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <GridContainer>
          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('enrollment.academicYear')}
              placeholder={translate('enrollment.placeholder.academicYear')}
              value={academicYear}
              name="academicYear"
              onChange={onChangeHandler}
              choices={academicYearChoices}
              disabled
              helperText=""
            />
          </GridItem>

          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('classroom.school')}
              placeholder={translate('classroom.placeholder.school')}
              value={schoolChoices.length > 0 ? school : ''}
              name="school"
              onChange={onChangeHandler}
              choices={schoolChoices}
              disabled
              helperText=""
            />
          </GridItem>

          <GridItem
            xs={12}
            md={6}
          >
            <Select
              label={translate('scholarshipRemittance.round')}
              placeholder={translate('scholarshipRemittance.placeholder.round')}
              value={round}
              name="round"
              onChange={onChangeHandler}
              choices={[
                { label: translate('scholarshipRemittance.round.option.round_1'), value: 'round_1' },
                { label: translate('scholarshipRemittance.round.option.round_2'), value: 'round_2' },
                { label: translate('scholarshipRemittance.round.option.round_3'), value: 'round_3' }
              ]}
              disabled
              helperText=""
            />
          </GridItem>
          <GridItem
            xs={12}
            md={6}
          >
            <Input
              label={translate('scholarshipFunding.received')}
              value={formatCurrency(receivedFund)}
              placeholder={translate('scholarshipFunding.placeholder.received')}
              name="receivedFund"
              onChange={onChangeHandler}
              error={errorReceivedFund}
              helperText={errorReceivedFund ? translate('common.error.require') : ''}
              require
            />
          </GridItem>
          <GridItem
            xs={12}
            md={6}
          >
            <DateTimePicker
              label={translate('scholarshipFunding.receivedAt')}
              value={receivedAt}
              placeholder={translate('scholarshipFunding.placeholder.receivedAt')}
              name="receivedAt"
              timeFormat={false}
              onChange={e => onChangeDateHandler(e)}
              error={errorReceivedAt}
              helperText={errorReceivedAt ? translate('common.error.require') : ''}
              require
            />
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions
        className={classes.dialogAction}
      >
        <div className={classes.normalSaveClose}>
          <ThemeButton
            onClick={onClose}
            variant="contained"
            className={classes.buttonDefault}
          >
            {translate('common.button.close')}
          </ThemeButton>
          <ThemeButton
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            {translate('common.button.save')}
          </ThemeButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddFundDialog.propTypes = {
  school: PropTypes.number.isRequired,
  editId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default AddFundDialog;
