import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import KeyIcon from '@material-ui/icons/VpnKey';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Card from 'components/theme/Card/Card';
import CardBody from 'components/theme/Card/CardBody';
import CardIcon from 'components/theme/Card/CardIcon';
import CardHeader from 'components/theme/Card/CardHeader';
import Button from 'components/theme/CustomButtons/Button';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';

import {
  clearUnits, getDistricts,
  getProvinces,
  getUserGroups,
  getRawSchoolList,
  createEditUser,
  getUser, clearRawSchoolList
} from 'redux/actions';
import { getGazetteerChoices } from 'utilities/gazetteer';
import * as ROUTES from 'variables/routeNames';
import { groups as GROUPS, DEGREES } from 'variables/user';

import styles from 'assets/sts/jss/views/userManagement/createEditUserStyle';
import { getSchoolName } from 'utilities/helpers';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Autocomplete from 'components/common/AutocompleteInput/AutocompleteInput';
import {
  provincialOfficerGroup,
  districtOfficerGroup,
  schoolManagementGroup
} from 'utilities/permission';

const useStyles = makeStyles(styles);

const schoolFilterOptions = createFilterOptions({
  stringify: (option) => option.code + option.label
});

const CreateEditUser = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const userId = props.match.params.userId;
  const isLoading = useSelector(state => state.user.isLoading);
  const isLoadingUserGroups = useSelector(state => state.user.isLoadingGroup);
  const groups = useSelector(state => state.user.groups);
  const isLoadingGazetteer = useSelector(state => state.gazetteerCode.isLoading);
  const provinces = useSelector((state) => state.gazetteerCode.provinces);
  const districts = useSelector((state) => state.gazetteerCode.districts);
  const rawSchoolList = useSelector(state => state.school.rawSchoolList);
  const user = useSelector(state => state.user.user);
  const authProfile = useSelector(state => state.auth.profile);
  const isProvincialOfficer = provincialOfficerGroup(authProfile);
  const isDistrictOfficer = districtOfficerGroup(authProfile);
  const isSchoolManagement = schoolManagementGroup(authProfile);
  const disabledProvinceSelection = isProvincialOfficer || isDistrictOfficer;
  const disabledDistrictSelection = isDistrictOfficer;

  const [username, setUsername] = useState('');
  const [errorUsername, setErrorUsername] = useState(false);
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorConfirmMessage, setErrorConfirmMessage] = useState('');
  const [isPasswordTemporary, setIsPasswordTemporary] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [lastName, setLastName] = useState('');
  const [errorLastName, setErrorLastName] = useState(false);
  const [gender, setGender] = useState('');
  const [errorGender, setErrorGender] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [phone, setPhone] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);

  const [userGroup, setUserGroup] = useState('');
  const [errorUserGroup, setErrorUserGroup] = useState(false);
  const [province, setProvince] = useState('');
  const [errorProvince, setErrorProvince] = useState(false);
  const [district, setDistrict] = useState('');
  const [errorDistrict, setErrorDistrict] = useState(false);
  const [school, setSchool] = useState('');
  const [errorSchool, setErrorSchool] = useState(false);
  const [degree, setDegree] = useState('');
  const [errorDegree, setErrorDegree] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');

  const [userGroupChoices, setUserGroupChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [degreeChoices] = useState(DEGREES);
  const [isShowGazetteer, setIsShowGazetteer] = useState(false);
  const [isShowDistrict, setIsShowDistrict] = useState(false);
  const [isShowSchool, setIsShowSchool] = useState(false);
  const [isShowGroup, setIsShowGroup] = useState(false);
  const [isShowDegree, setIsShowDegree] = useState(false);

  const isAuth = userId && authProfile && parseInt(userId) === parseInt(authProfile.id);
  // set title
  useEffect(() => {
    document.title = `${translate(userId ? 'userManagement.edit' : 'userManagement.new')} - STS`;
    document.getElementById('brandText').innerHTML = translate('userManagement.title');
  }, [translate, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearRawSchoolList());
    };
  }, [dispatch]);

  // get user groups
  const fetchGroups = useCallback(() => {
    dispatch(getUserGroups());
  }, [dispatch]);
  //
  useEffect(() => {
    if (!isLoadingUserGroups) {
      fetchGroups();
    }
    // eslint-disable-next-line
  }, [fetchGroups]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const availableGroups = [];
      const choices = Object.keys(GROUPS)
        .filter(index => {
          const groupObj = groups.find(g => g.name === GROUPS[index]);
          if (groupObj) {
            availableGroups[groupObj.name] = groupObj.id;
          }
          return groupObj;
        })
        .map(index => {
          return {
            label: translate(`useGroups.${GROUPS[index]}`),
            value: availableGroups[GROUPS[index]]
          };
        }
        );

      if (isAuth) {
        const groupName = authProfile.groups[0];
        const authGroup = {
          label: translate(`useGroups.${groupName}`),
          value: authProfile.keycloak_user_group
        };
        choices.unshift(authGroup);
      }
      setUserGroupChoices(choices);
      if (authProfile?.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile?.groups[0] === GROUPS.TEACHER) {
        setUserGroup(choices[0].value);
      } else {
        setIsShowGroup(true);
      }
    }
  }, [groups, translate, isAuth, authProfile]);

  // fetch provinces
  const fetchProvincesData = useCallback(
    () => {
      dispatch(getProvinces());
      dispatch(clearUnits());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoadingGazetteer) {
      fetchProvincesData();
    }
    // eslint-disable-next-line
  }, [fetchProvincesData]);

  useEffect(
    () => {
      let choices = getGazetteerChoices(getActiveLanguage(localize).code, provinces, translate);
      choices.shift();
      if (authProfile && (isProvincialOfficer || isDistrictOfficer || isSchoolManagement)) {
        choices = choices.filter(c => c.value === `${authProfile.province.id},${authProfile.province.code}`);
        setProvince(`${authProfile.province.id},${authProfile.province.code}`);
      }
      setProvinceChoices(choices);
    }
    // eslint-disable-next-line
      , [provinces, localize]);

  // fetch districts after province is selected
  const fetchDistrictsData = useCallback(
    () => {
      if (province !== '') {
        dispatch(getDistricts(province));
      }
      dispatch(clearUnits('province'));
    },
    [dispatch, province]
  );

  useEffect(() => {
    fetchDistrictsData();
  }, [fetchDistrictsData]);

  useEffect(
    () => {
      let choices = getGazetteerChoices(getActiveLanguage(localize).code, districts, translate);
      choices.shift();
      if (authProfile && (isDistrictOfficer || isSchoolManagement)) {
        choices = choices.filter(c => c.value === `${authProfile.district.id},${authProfile.district.code}`);
        setDistrict(`${authProfile.district.id},${authProfile.district.code}`);
      }
      setDistrictChoices(choices);
      if (district && districts.length) {
        const selectedDistrict = districts.find(item => item.code === district.split(',')[1]);
        if (!selectedDistrict) {
          setDistrict('');
        }
      }
    }
    // eslint-disable-next-line
      , [districts, localize]);

  // fetch schools by academic year
  useEffect(() => {
    if (district !== '') {
      const gazetteer = district && district !== '' ? district.split(',')[1] : '';
      const params = { gazetteer };
      dispatch(getRawSchoolList(params));
    }
    // eslint-disable-next-line
  }, [dispatch, district]);

  useEffect(
    () => {
      if (rawSchoolList.length > 0 && district !== '') {
        if (school) {
          const foundSchool = rawSchoolList.find(item => item.id === school);
          if (!foundSchool) {
            setSchool('');
            setSelectedSchool('');
          } else {
            setSelectedSchool({
              label: getSchoolName(getActiveLanguage(localize).code, translate, foundSchool),
              value: foundSchool.id,
              code: foundSchool.emis_code
            });
          }
        }

        const choices = rawSchoolList.map(s => {
          return {
            label: getSchoolName(getActiveLanguage(localize).code, translate, s),
            value: s.id,
            code: s.emis_code
          };
        });
        setSchoolChoices(choices);
        if (authProfile?.groups[0] === GROUPS.SCHOOL_MANAGEMENT) {
          setSchool(choices[0].value);
        }
      } else {
        setSchoolChoices([]);
      }
    }
    // eslint-disable-next-line
      , [district, rawSchoolList, localize]);

  const fetchUser = useCallback(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(
    () => {
      if (!isLoading) {
        fetchUser();
      }
    },
    // eslint-disable-next-line
      [fetchUser]);

  useEffect(() => {
    if (userId && user && userId.toString() === user.id.toString()) {
      setUsername(user.username);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setGender(user.gender);
      setEmail(user.email || '');
      setPhone(user.phone_number_1 || '');
      setUserGroup(user.keycloak_user_group);
      if (user.province) {
        setIsShowGazetteer(true);
        setProvince(`${user.province.id},${user.province.code}`);
      } else {
        setIsShowGazetteer(false);
        setProvince('');
      }
      if (user.district) {
        setIsShowDistrict(true);
        setDistrict(`${user.district.id},${user.district.code}`);
      } else {
        setIsShowDistrict(false);
        setDistrict('');
      }
      if (user.school_id) {
        setIsShowSchool(true);
        setSchool(user.school_id);
      } else {
        setIsShowSchool(false);
        setSchool('');
        setSelectedSchool('');
      }
      if (user.school_degree && user.school_degree !== '0') {
        setIsShowDegree(true);
        setDegree(user.school_degree);
      } else {
        setIsShowDegree(false);
        setDegree('');
      }
    }
  }, [user, userId]);

  const onChangeHandler = (e, params) => {
    const value = e.target.value;
    switch (params) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'userGroup':
        setUserGroup(value);
        if (value && groups.length > 0) {
          const groupObj = groups.find(g => g.id === value);
          if (groupObj) {
            const groupName = groupObj.name;
            const isShowGazetteerTmp = groupName !== GROUPS.DEMIS &&
                groupName !== GROUPS.DATA_USER &&
                groupName !== GROUPS.SUPER_DATA_USER;
            const isShowSchoolTmp = groupName !== GROUPS.DEMIS &&
                groupName !== GROUPS.PROVINCIAL_OFFICER &&
                groupName !== GROUPS.DISTRICT_OFFICER &&
                groupName !== GROUPS.DATA_USER &&
                groupName !== GROUPS.SUPER_DATA_USER;
            const isShowDegreeTmp = groupName === GROUPS.DATA_USER || groupName === GROUPS.SUPER_DATA_USER;
            let isShowDistrictTmp = false;
            if (isShowGazetteerTmp) {
              isShowDistrictTmp = groupName === GROUPS.DISTRICT_OFFICER || groupName === GROUPS.SCHOOL_MANAGEMENT || groupName === GROUPS.TEACHER;
            }
            setIsShowGazetteer(isShowGazetteerTmp);
            setIsShowSchool(isShowSchoolTmp);
            setIsShowDistrict(isShowDistrictTmp);
            setIsShowDegree(isShowDegreeTmp);
            if (isShowGazetteerTmp) {
              setSchool('');
              setSelectedSchool('');
              if (!isShowDistrictTmp) {
                setDistrict('');
              }
            }
          }
        }
        break;
      case 'username':
        setUsername(value);
        break;
      case 'province':
        setProvince(value);
        break;
      case 'district':
        setDistrict(value);
        break;
      case 'degree':
        setDegree(value);
        break;
      default:
    }
  };

  const onSchoolCodeChangeHandler = (event, school) => {
    if (school) {
      setSelectedSchool(school);
      setSchool(school.value);
    } else {
      setSelectedSchool('');
      setSchool('');
    }
  };

  const handleCreateEdit = e => {
    e.preventDefault();
    if (validateForm()) {
      const groupObj = groups.find(g => g.id === userGroup);
      const data = {
        username: username.trim(),
        password: password !== '' ? password : undefined,
        is_password_temporary: isPasswordTemporary,
        first_name: firstName !== '' ? firstName : undefined,
        last_name: lastName !== '' ? lastName : undefined,
        gender,
        email: email !== '' ? email : undefined,
        phone_number_1: phone !== '' ? phone : undefined,
        user_group: userGroup,
        staff_group: groupObj ? groupObj.name : undefined,
        school_id: school !== '' ? school : undefined,
        school_degree: degree !== '' ? degree : undefined
      };
      if (groupObj && groupObj.name === GROUPS.PROVINCIAL_OFFICER) {
        data.gazetteer_id = province !== '' ? province.split(',')[0] : undefined;
      } else if (groupObj && groupObj.name === GROUPS.DISTRICT_OFFICER) {
        data.gazetteer_id = district !== '' ? district.split(',')[0] : undefined;
      }
      if (userId) {
        dispatch(createEditUser(data, userId));
      } else {
        dispatch(createEditUser(data));
      }
    }
  };

  const validateForm = () => {
    let isCanSave = true;
    if (!username) {
      setErrorUsername(true);
      isCanSave = false;
    } else {
      setErrorUsername(false);
    }

    if (password) {
      if (password.length < 6) {
        setErrorPassword(true);
        isCanSave = false;
      } else {
        setErrorPassword(false);
        if (!confirmPassword) {
          setErrorConfirmPassword(true);
          setErrorConfirmMessage(translate('common.error.require'));
          isCanSave = false;
        } else {
          if (password !== confirmPassword) {
            setErrorConfirmPassword(true);
            setErrorConfirmMessage(translate('common.error.confirmPassword'));
            isCanSave = false;
          } else {
            setErrorConfirmPassword(false);
            setErrorConfirmMessage('');
          }
        }
      }
    }

    if (!lastName) {
      setErrorLastName(true);
      isCanSave = false;
    } else {
      setErrorLastName(false);
    }

    if (!firstName) {
      setErrorFirstName(true);
      isCanSave = false;
    } else {
      setErrorFirstName(false);
    }

    if (!gender) {
      setErrorGender(true);
      isCanSave = false;
    } else {
      setErrorGender(false);
    }

    if (email) {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setErrorEmail(false);
      } else {
        setErrorEmail(true);
        isCanSave = false;
      }
    }

    if (!phone) {
      setErrorPhone(true);
      isCanSave = false;
    } else {
      setErrorPhone(false);
    }

    if (!userGroup) {
      setErrorUserGroup(true);
      isCanSave = false;
    } else {
      setErrorUserGroup(false);
      if (groups.length > 0) {
        const groupObj = groups.find(g => g.id === userGroup);
        switch (groupObj?.name) {
          case GROUPS.SCHOOL_MANAGEMENT:
          case GROUPS.TEACHER:
            if (!province) {
              setErrorProvince(true);
              isCanSave = false;
            } else {
              setErrorProvince(false);
            }

            if (!district) {
              setErrorDistrict(true);
              isCanSave = false;
            } else {
              setErrorDistrict(false);
            }

            if (!school) {
              setErrorSchool(true);
              isCanSave = false;
            } else {
              setErrorSchool(false);
            }
            break;
          case GROUPS.DISTRICT_OFFICER:
            if (!district) {
              setErrorDistrict(true);
              isCanSave = false;
            } else {
              setErrorDistrict(false);
            }
            /* falls through */
          case GROUPS.PROVINCIAL_OFFICER:
            if (!province) {
              setErrorProvince(true);
              isCanSave = false;
            } else {
              setErrorProvince(false);
            }
            break;
          case GROUPS.SUPER_DATA_USER:
          case GROUPS.DATA_USER:
            if (!degree) {
              setErrorDegree(true);
              isCanSave = false;
            } else {
              setErrorDegree(false);
            }
            break;
          default:
        }
      }
    }

    return isCanSave;
  };

  const form = (
    <Card>
      <CardHeader color="gold" icon>
        <CardIcon color="gold">
          <KeyIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{translate(userId ? 'userManagement.edit' : 'userManagement.new')}</h4>
      </CardHeader>
      <CardBody>
        <form
          className={classes.gridContainerStyle}
        >
          <GridContainer>

            <GridItem
              xs={12}
            >

              <Typography
                variant="body1"
                classes={{ body1: classes.keyboardSwitch }}
              >
                {translate('common.switchToEn')}
              </Typography>

            </GridItem>

            <GridItem
              xs={12}
              className={
                classes.addressGrid
              }
            >

              <GridContainer
                className={
                  classes.innerGridContainer
                }
              >
                <GridItem
                  xs={12}
                  md={3}
                >
                  <Input
                    label={translate('userManagement.username')}
                    placeholder={translate('userManagement.placeholder.username')}
                    value={username}
                    name="offAutoCompleteUsername"
                    onChange={e => onChangeHandler(e, 'username')}
                    require
                    error={errorUsername}
                    helperText={errorUsername ? translate('common.error.require') : ''}
                    disabled={!!userId}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Input
                    label={translate('userManagement.password')}
                    placeholder={translate('userManagement.placeholder.password')}
                    value={password}
                    name="password"
                    onChange={e => onChangeHandler(e, 'password')}
                    type="password"
                    error={errorPassword}
                    helperText={translate('common.error.password6')}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Input
                    label={translate('userManagement.confirmPassword')}
                    placeholder={translate('userManagement.placeholder.confirmPassword')}
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={e => onChangeHandler(e, 'confirmPassword')}
                    type="password"
                    error={errorConfirmPassword}
                    helperText={errorConfirmMessage}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <FormControlLabel
                    className={classes.passwordTempCheckBox}
                    control={
                      <Checkbox
                        checked={isPasswordTemporary}
                        onChange={(e) => setIsPasswordTemporary(e.target.checked)}
                        name="isPasswordTemporary"
                        classes={{
                          checked: classes.checkBoxPass
                        }}
                      />
                    }
                    label={translate('userManagement.temporaryPassword')}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Input
                label={translate('common.lastName')}
                placeholder={translate('common.placeholder.lastName')}
                value={lastName}
                name="lastName"
                onChange={e => onChangeHandler(e, 'lastName')}
                error={errorLastName}
                helperText={errorLastName ? translate('common.error.require') : ''}
                require
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Input
                label={translate('common.firstName')}
                placeholder={translate('common.placeholder.firstName')}
                value={firstName}
                name="firstName"
                onChange={e => onChangeHandler(e, 'firstName')}
                error={errorFirstName}
                helperText={errorFirstName ? translate('common.error.require') : ''}
                require
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Select
                require
                label={translate('common.gender')}
                placeholder={translate('common.placeholder.gender')}
                value={gender}
                name="gender"
                onChange={e => onChangeHandler(e, 'gender')}
                choices={[
                  { label: translate('common.genderType.f'), value: 'f' },
                  { label: translate('common.genderType.m'), value: 'm' }
                ]}
                error={errorGender}
                helperText={errorGender ? translate('common.error.require') : ''}
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Input
                label={translate('userManagement.email')}
                placeholder={translate('userManagement.placeholder.email')}
                value={email}
                name="email"
                onChange={e => onChangeHandler(e, 'email')}
                type="email"
                error={errorEmail}
                helperText={errorEmail ? translate('common.error.email') : ''}
              />
            </GridItem>

            <GridItem
              xs={12}
              md={6}
            >
              <Input
                label={translate('student.tabProfile.phone')}
                placeholder={translate('userManagement.placeholder.phone')}
                value={phone}
                name="phone"
                onChange={e => onChangeHandler(e, 'phone')}
                require
                error={errorPhone}
                helperText={errorPhone ? translate('common.error.require') : ''}
              />
            </GridItem>

            {
              isShowGroup && (
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('userManagement.userGroup')}
                    placeholder={translate('userManagement.placeholder.userGroup')}
                    value={userGroup}
                    name="userGroup"
                    onChange={e => onChangeHandler(e, 'userGroup')}
                    choices={userGroupChoices}
                    require
                    error={errorUserGroup}
                    helperText={errorUserGroup ? translate('common.error.require') : ''}
                    disabled={isAuth}
                  />
                </GridItem>
              )
            }

            {
              (isShowGazetteer) && (
                <>
                  <GridItem
                    xs={12}
                    md={6}
                  >
                    <Select
                      label={translate('gazetteer.province')}
                      placeholder={translate('gazetteer.placeholder.province')}
                      value={province}
                      name="province"
                      onChange={e => onChangeHandler(e, 'province')}
                      choices={provinceChoices}
                      require
                      error={errorProvince}
                      helperText={errorProvince ? translate('common.error.require') : ''}
                      disabled={isAuth || disabledProvinceSelection}
                    />
                  </GridItem>

                  {
                    (isShowDistrict) && (
                      <GridItem
                        xs={12}
                        md={6}
                      >
                        <Select
                          label={translate('gazetteer.district')}
                          placeholder={translate('gazetteer.placeholder.district')}
                          value={district}
                          name="district"
                          onChange={e => onChangeHandler(e, 'district')}
                          choices={districtChoices}
                          disabled={isLoadingGazetteer || districts.length === 0 || isAuth || disabledDistrictSelection}
                          require
                          error={errorDistrict}
                          helperText={errorDistrict ? translate('common.error.require') : ''}
                        />
                      </GridItem>
                    )
                  }
                </>
              )
            }

            {
              isShowSchool && (
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Autocomplete
                    label={translate('classroom.school')}
                    placeholder={translate('userManagement.placeholder.school')}
                    value={selectedSchool}
                    name="school"
                    onChange={onSchoolCodeChangeHandler}
                    options={schoolChoices}
                    filterOptions={schoolFilterOptions}
                    disabled={schoolChoices.length === 0 || isAuth}
                    require
                    error={errorSchool}
                    noOptionsText={translate('report.noSchoolChoice')}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>
              )
            }

            {
              isShowDegree && (
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('userManagement.degree')}
                    placeholder={translate('userManagement.placeholder.degree')}
                    value={degree}
                    name="degree"
                    onChange={e => onChangeHandler(e, 'degree')}
                    choices={degreeChoices.map(degree => {
                      return {
                        label: translate(degree.label),
                        value: degree.value
                      };
                    })}
                    disabled={degreeChoices.length === 0 || isAuth}
                    require
                    error={errorDegree}
                    helperText={errorDegree ? translate('common.error.require') : ''}
                  />
                </GridItem>
              )
            }

          </GridContainer>

          <div
            className={classes.submitButton}
          >
            <Button
              className={classes.cancelButton}
              onClick={() => history.push(ROUTES.USER)}
            >
              {translate('common.button.cancel')}
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleCreateEdit}
            >
              {userId ? translate('common.button.save') : translate('common.button.create')}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : form}
    </div>
  );
};

CreateEditUser.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object)
};

export default CreateEditUser;
