import customDropdownStyle from 'assets/theme/jss/material-dashboard-pro-react/components/customDropdownStyle';
import {
  stsBlueColor,
  activeColor
} from 'assets/sts/jss/sts-react';

const DRAWER_WIDTH = 250;

const adminNavbarLinksStyle = (theme) => ({
  ...customDropdownStyle(theme),
  customLanguage: {
    display: 'inline-block'
  },
  separator: {
    display: 'inline-block',
    paddingTop: '4px'
  },
  languageButton: {
    padding: 0,
    fontSize: '0.8rem'
  },
  languageActive: {
    padding: 0,
    fontSize: '0.8rem',
    color: `${activeColor} !important`
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: 'calc(100% - 70px)',
    left: '70px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH - 70, // marginLeft insteadof left for transition
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
    padding: '0 8px',
    top: 0,
    ...theme.mixins.toolbar
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 34,
    minWidth: 200,
    background: stsBlueColor,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12
  },
  backgroundBox: {
    minWidth: 50,
    marginRight: theme.spacing(1)
  },
  backgroundTaskBox: {
    minWidth: 'auto'
  },
  welcome: {
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
    maxWidth: 200,
    minWidth: 50,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  loginButton: {
    margin: '0 8px',
    color: activeColor
  }
});

export default adminNavbarLinksStyle;
