import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import CardBody from 'components/theme/Card/CardBody';
import Table from 'components/common/Table/Table';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { activateUser } from 'redux/actions';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import * as ROUTES from 'variables/routeNames';
import * as VARIABLES from 'variables/school';
import SweetAlert from 'react-bootstrap-sweetalert';

const UserTable = (props) => {
  const {
    limit,
    page,
    setLimit,
    setPage,
    classes
  } = props;

  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);
  const isLoading = useSelector(state => state.user.isLoading);
  const groups = useSelector(state => state.user.groups);
  const userList = useSelector(state => state.user.userList);
  const authProfile = useSelector(state => state.auth.profile);

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [target, setTarget] = useState('');
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [currentTargetStatus, setCurrentTargetStatus] = useState(true);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    e.preventDefault();
    setLimit(e.target.value);
  };

  useEffect(() => {
    if (userList && userList.length > 0) {
      setRows(userList);
      setCount(userList.total);
    } else {
      setRows([]);
      setCount(0);
    }
  }, [userList]);

  const status = (status) => {
    return (
      <Tooltip
        title={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        key={translate(`school.schoolStatus.${VARIABLES.status[status]}`)}
        className={classes.tooltip}
      >
        {status ? (
          <CheckCircleIcon
            className={cx({
              [classes.successButton]: true,
              [classes.icon]: true
            })}
          />
        ) : (
          <RemoveCircleIcon
            className={cx({
              [classes.inactive]: true,
              [classes.icon]: true
            })}
          />
        )

        }
      </Tooltip>
    );
  };
  const onHandleConfirm = () => {
    dispatch(activateUser(target, currentTargetStatus));
    setTarget('');
    setIsConfirmBoxOpen(false);
  };

  const onHandelCancel = () => {
    setIsConfirmBoxOpen(false);
    setTarget('');
  };

  return (
    <LoadablePaper
      rendered
      loading={isLoading}
    >
      <LoadablePaperContent>
        {
          isConfirmBoxOpen && (
            <SweetAlert warning style={{ display: 'block', marginTop: '-200px' }}
              title={translate('common.modal.confirmTitle')}
              onConfirm={() => onHandleConfirm()}
              onCancel={() => onHandelCancel()}
              confirmBtnCssClass={classes.button + ' ' + classes.success}
              cancelBtnCssClass={classes.button + ' ' + classes.danger}
              confirmBtnText={translate('common.modal.yes')}
              cancelBtnText={translate('common.modal.no')}
              showCancel
            />
          )
        }
        <Card>
          <CardBody>

            <Table
              size='small'
              tableHead={[
                { id: 'username', isNeedSort: false, label: translate('userManagement.username') },
                { id: 'lastName', isNeedSort: false, label: translate('common.lastName') },
                { id: 'firstName', isNeedSort: false, label: translate('common.firstName') },
                { id: 'userGroup', isNeedSort: false, label: translate('userManagement.userGroup') },
                { id: 'school', isNeedSort: false, label: translate('school.title') },
                { id: 'status', isNeedSort: false, label: translate('common.status') },
                { id: 'action', isNeedSort: false, label: translate('common.action') }
              ]}
              tableData={rows?.map(row => {
                const isAuth = row.id && authProfile && parseInt(row.id) === parseInt(authProfile.id);
                const editButton = (
                  <Tooltip
                    title={translate('common.button.edit')}
                    key={translate('common.button.edit')}
                    className={classes.tooltip}
                  >
                    <div>
                      <IconButton
                        color='primary'
                        aria-label='edit'
                        className={classes.tableIconButton}
                        component={Link}
                        to={`${ROUTES.USER_EDIT_ROOT}/${row.id}`}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                );

                const deactivateButton = (
                  <Tooltip
                    title={row.status ? translate('common.button.deactivate') : translate('common.button.activate')}
                    key={row.status ? translate('common.button.deactivate') : translate('common.button.activate')}
                    className={classes.tooltip}
                    placement="bottom"
                  >
                    <span>
                      <IconButton
                        aria-label='deactivate'
                        className={classes.tableIconButton}
                        disabled={isAuth}
                        onClick={() => {
                          setTarget(row.id);
                          setCurrentTargetStatus(row.status);
                          setIsConfirmBoxOpen(true);
                        }}
                      >
                        {
                          row.status
                            ? (<RemoveCircleIcon className={cx({ [classes.inactive]: !isAuth, [classes.icon]: true })} />)
                            : (<CheckCircleIcon className={cx({ [classes.successButton]: true, [classes.icon]: true })} />)
                        }
                      </IconButton>
                    </span>
                  </Tooltip>
                );

                const groupObj = groups.find(g => g.id === row.keycloak_user_group);
                const groupName = groupObj ? translate(`useGroups.${groupObj.name}`) : '';
                const school = row.school;
                let schoolName = '';
                if (school) {
                  schoolName = getActiveLanguage(localize).code === 'en'
                    ? `${school.name_en} (${school.degree.name_en})`
                    : `${school.name} (${school.degree.name})`;
                }

                return [
                  row.username,
                  row.last_name,
                  row.first_name,
                  groupName,
                  schoolName,
                  status(row.status),
                  [editButton, deactivateButton]
                ];
              })}
              customCellClasses={['center', 'center']}
              customClassesForCells={[4, 5]}
              customHeadCellClasses={['center', 'center']}
              customHeadClassesForCells={[4, 5]}
              count={count}
              limit={limit}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
};

UserTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  limit: PropTypes.number,
  page: PropTypes.number,
  setLimit: PropTypes.func,
  setPage: PropTypes.func
};

export default UserTable;
