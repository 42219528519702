export const groups = {
  DEMIS: 'demis',
  PROVINCIAL_OFFICER: 'provincial_officer',
  DISTRICT_OFFICER: 'district_officer',
  SCHOOL_MANAGEMENT: 'school_management',
  TEACHER: 'teacher',
  DATA_USER: 'data_user',
  SUPER_DATA_USER: 'super_data_user'
};

export const STUDENT = 'student';

export const roles = {
  MANAGE_ACADEMIC_YEARS: 'sts_manage_academic_years',
  VIEW_ACADEMIC_YEARS: 'sts_view_academic_years',
  MANAGE_SCHOOL: 'sts_manage_school',
  VIEW_SCHOOL: 'sts_view_school',
  PARTLY_EDIT_SCHOOL: 'sts_partly_edit_school',
  MANAGE_USER: 'sts_manage_user',
  MANAGE_OWN_PROFILE: 'sts_manage_own_profile',
  MANAGE_CLASSROOM: 'sts_manage_classroom',
  MANAGE_OWNED_CLASSROOM: 'sts_manage_owned_classroom',
  MANAGE_STUDENT: 'sts_manage_student',
  MANAGE_OWNED_STUDENT: 'sts_manage_owned_student',
  VIEW_OWNED_CLASSROOM: 'sts_view_owned_classroom',
  ENROLL_STUDENT: 'sts_enroll_student',
  ENROLL_OWNED_STUDENT: 'sts_enroll_owned_student',
  GRADUATE_STUDENT: 'sts_graduate_student',
  GRADUATE_OWNED_STUDENT: 'sts_graduate_owned_student',
  TRANSFER_STUDENT: 'sts_transfer_student',
  TRANSFER_OWNED_STUDENT: 'sts_transfer_owned_student',
  MANAGE_STUDENT_ACCOUNT: 'sts_manage_student_account',
  MANAGE_OWN_STUDENT_ACCOUNT: 'sts_manage_own_student_account',
  STUDENT_MANAGE_OWN_ACCOUNT: 'sts_student_manage_own_account',
  VIEW_CLASSROOM: 'sts_view_classroom',
  VIEW_CLASSROOM_REPORT: 'sts_view_classroom_report',
  VIEW_SCHOOL_REPORT: 'sts_view_school_report',
  VIEW_STUDENT: 'sts_view_student',
  VIEW_SCORE: 'sts_view_score',
  VIEW_ATTENDANCE: 'sts_view_attendance',
  MANAGE_SCHOLARSHIP: 'sts_manage_scholarship'
};

export const DEGREES = [
  {
    label: 'userManagement.degrees.all',
    value: 'all'
  },
  {
    label: 'userManagement.degrees.pre',
    value: 1
  },
  {
    label: 'userManagement.degrees.pri',
    value: 2
  },
  {
    label: 'userManagement.degrees.sec',
    value: 3
  }
];
