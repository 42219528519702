import DashboardIcon from '@material-ui/icons/Dashboard';
import Holiday from '@material-ui/icons/QueryBuilder';
import Academic from '@material-ui/icons/CalendarToday';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MessageIcon from '@material-ui/icons/Message';
import SchoolIcon from '@material-ui/icons/AccountBalance';
import ClassroomIcon from '@material-ui/icons/Ballot';
import StudentIcon from '@material-ui/icons/AccountCircle';
import GraduationIcon from '@material-ui/icons/CardMembership';
import ScholarshipIcon from '@material-ui/icons/School';
import RemittanceIcon from '@material-ui/icons/MonetizationOn';
import FundingIcon from '@material-ui/icons/LocalAtm';
import EnrollIcon from '@material-ui/icons/LibraryBooksOutlined';
import TransferIcon from '@material-ui/icons/SwapHorizontalCircle';
import ScoreIcon from '@material-ui/icons/Spellcheck';
import StudentProfileIcon from '@material-ui/icons/AssignmentInd';
import AttendanceIcon from '@material-ui/icons/HowToRegRounded';
import KeyIcon from '@material-ui/icons/VpnKey';
import ReportIcon from '@material-ui/icons/Map';
import DataQueryIcon from '@material-ui/icons/InsertChart';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import NationalPublicHoliday from 'views/NationalPublicHoliday/NationalPublicHoliday';
import Dashboard from 'views/Dashboard/Dashboard';
import Home from 'views/Home/Home';
import About from 'views/About/About';
import Contact from 'views/Contact/Contact';
import AcademicYear from 'views/AcademicYear/AcademicYear';
import CreateEditAcademicYear from 'views/AcademicYear/CreateEditAcademicYear';
import School from 'views/School/School';
import ViewSchool from 'views/School/ViewSchool';
import CreateEditSchool from 'views/School/CreateEditSchool';
import Classroom from 'views/Classroom/Classroom';
import ViewClassroom from 'views/Classroom/ViewClassroom';
import CreateEditClassroom from 'views/Classroom/CreateEditClassroom';
import Student from 'views/Student/Student';
import EditStudent from 'views/Student/EditStudent';
import Graduation from 'views/Graduation/Graduation';
import Scholarship from 'views/Scholarship/Scholarship';
import Remittance from 'views/ScholarshipRemittance/Remittance';
import Funding from 'views/ScholarshipFunding/Funding';
import Transfer from 'views/Transfer/Transfer';
import Enrollment from 'views/Enrollment/Enrollment';
import Score from 'views/Score/Score';
import ErrorView from 'views/ErrorView/ErrorView';
import ErrorPage from 'views/ErrorView/ErrorPage';
import Attendance from 'views/Attendance';
import UserManagement from 'views/UserManagement/UserManagement';
import CreateEditUser from 'views/UserManagement/CreateEditUser';
import Report from 'views/Report/Report';
import ManagementReport from 'views/DataQuery/ManagementReport';
// import studentAccountManagement from 'views/StudentAccountManagement/StudentAccountManagement';
import CreateEditStudentAccount from 'views/StudentAccountManagement/CreateEditStudentAccount';
import ViewStudent from 'views/Student/ViewStudent';

import * as ROUTES from 'variables/routeNames';
import { roles as ROLES, groups as GROUPS } from 'variables/user';

const Routes = [
  {
    path: ROUTES.HOME,
    name: 'home',
    icon: HomeIcon,
    component: Home,
    location: 'Home/Home',
    private: false,
    sidebarVisible: true
  },
  {
    path: ROUTES.DASHBOARD,
    name: 'dashboard',
    icon: DashboardIcon,
    component: Dashboard,
    location: 'Dashboard/Dashboard',
    private: false,
    sidebarVisible: false
  },
  {
    path: ROUTES.ABOUT,
    name: 'about',
    icon: InfoIcon,
    component: About,
    location: 'About/About',
    private: false,
    sidebarVisible: false
  },
  {
    path: ROUTES.CONTACT,
    name: 'contact',
    icon: MessageIcon,
    component: Contact,
    location: 'Contact/Contact',
    private: false,
    sidebarVisible: false
  },
  {
    title: 'management',
    private: true,
    sidebarVisible: true
  },
  {
    path: ROUTES.ACADEMIC_YEAR,
    name: 'academicYear',
    icon: Academic,
    component: AcademicYear,
    location: 'AcademicYear/AcademicYear',
    private: true,
    sidebarVisible: true,
    roles: ROLES.MANAGE_ACADEMIC_YEARS
  },
  {
    path: ROUTES.ACADEMIC_YEAR_NEW,
    name: 'academicYear',
    component: CreateEditAcademicYear,
    location: 'AcademicYear/CreateEditAcademicYear',
    private: true,
    sidebarVisible: false,
    roles: ROLES.MANAGE_ACADEMIC_YEARS
  },
  {
    path: ROUTES.ACADEMIC_YEAR_EDIT,
    name: 'academicYear',
    icon: Academic,
    component: CreateEditAcademicYear,
    location: 'AcademicYear/CreateEditAcademicYear',
    private: true,
    sidebarVisible: false,
    roles: ROLES.MANAGE_ACADEMIC_YEARS
  },
  {
    path: ROUTES.NATIONAL_PUBLIC_HOLIDAY,
    name: 'nationalPublicHoliday',
    icon: Holiday,
    component: NationalPublicHoliday,
    location: 'NationalPublicHoliday/NationalPublicHoliday',
    private: true,
    sidebarVisible: false
  },
  {
    path: ROUTES.SCHOOL,
    name: 'school',
    icon: SchoolIcon,
    component: School,
    location: 'School/School',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.MANAGE_SCHOOL, ROLES.VIEW_SCHOOL]
  },
  {
    path: ROUTES.SCHOOL_VIEW,
    name: 'school',
    icon: SchoolIcon,
    component: ViewSchool,
    location: 'School/ViewSchool',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_SCHOOL, ROLES.VIEW_SCHOOL]
  },
  {
    path: ROUTES.SCHOOL_NEW,
    name: 'school',
    icon: SchoolIcon,
    component: CreateEditSchool,
    location: 'School/CreateEditSchool',
    private: true,
    sidebarVisible: false,
    roles: ROLES.MANAGE_SCHOOL
  },
  {
    path: ROUTES.SCHOOL_EDIT,
    name: 'school',
    icon: SchoolIcon,
    component: CreateEditSchool,
    location: 'School/CreateEditSchool',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_SCHOOL, ROLES.PARTLY_EDIT_SCHOOL]
  },
  {
    path: ROUTES.ENROLLMENT,
    name: 'enrollment',
    icon: EnrollIcon,
    component: Enrollment,
    location: 'Enrollment/Enrollment',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.ENROLL_STUDENT, ROLES.ENROLL_OWNED_STUDENT]
  },
  {
    path: ROUTES.CLASSROOM_SCHOOL,
    name: 'classroom',
    icon: ClassroomIcon,
    component: Classroom,
    location: 'Classroom/Classroom',
    private: true,
    sidebarVisible: false
  },
  {
    path: ROUTES.CLASSROOM,
    name: 'classroom',
    icon: ClassroomIcon,
    component: Classroom,
    location: 'Classroom/Classroom',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_CLASSROOM,
      ROLES.MANAGE_OWNED_CLASSROOM,
      ROLES.VIEW_OWNED_CLASSROOM,
      ROLES.VIEW_CLASSROOM
    ]
  },
  {
    path: ROUTES.CLASSROOM_VIEW,
    name: 'classroom',
    icon: ClassroomIcon,
    component: ViewClassroom,
    location: 'Classroom/ViewClassroom',
    private: true,
    sidebarVisible: false,
    roles: ROLES.VIEW_OWNED_CLASSROOM
  },
  {
    path: ROUTES.CLASSROOM_NEW,
    name: 'classroom',
    icon: ClassroomIcon,
    component: CreateEditClassroom,
    location: 'Classroom/CreateEditClassroom',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_CLASSROOM, ROLES.MANAGE_OWNED_CLASSROOM]
  },
  {
    path: ROUTES.CLASSROOM_EDIT,
    name: 'classroom',
    icon: ClassroomIcon,
    component: CreateEditClassroom,
    location: 'Classroom/CreateEditClassroom',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_CLASSROOM, ROLES.MANAGE_OWNED_CLASSROOM]
  },
  {
    path: ROUTES.STUDENT,
    name: 'student',
    icon: StudentIcon,
    component: Student,
    location: 'Student/Student',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.MANAGE_STUDENT, ROLES.MANAGE_OWNED_STUDENT, ROLES.VIEW_STUDENT]
  },
  {
    path: ROUTES.STUDENT_CLASSROOM,
    name: 'student',
    icon: StudentIcon,
    component: Student,
    location: 'Student/Student',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_STUDENT, ROLES.MANAGE_OWNED_STUDENT]
  },
  {
    path: ROUTES.STUDENT_EDIT,
    name: 'student',
    icon: StudentIcon,
    component: EditStudent,
    location: 'Student/EditStudent',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_STUDENT, ROLES.MANAGE_OWNED_STUDENT]
  },
  {
    path: ROUTES.VIEW_STUDENT_INFO,
    name: 'student',
    icon: StudentIcon,
    component: ViewStudent,
    location: 'Student/ViewStudent',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_STUDENT, ROLES.MANAGE_OWNED_STUDENT, ROLES.VIEW_STUDENT]
  },
  {
    path: ROUTES.SCORE_MANAGEMENT,
    name: 'score_management',
    icon: ScoreIcon,
    component: Score,
    location: 'Score/Score',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_CLASSROOM,
      ROLES.MANAGE_OWNED_CLASSROOM,
      ROLES.VIEW_OWNED_CLASSROOM,
      ROLES.VIEW_SCORE
    ]
  },
  {
    path: ROUTES.SCORE,
    name: 'score',
    icon: ScoreIcon,
    component: Score,
    location: 'Score/Score',
    private: true,
    sidebarVisible: true,
    roles: ROLES.STUDENT_MANAGE_OWN_ACCOUNT
  },

  {
    path: ROUTES.VIEW_STUDENT,
    name: 'profile',
    icon: StudentProfileIcon,
    component: ViewStudent,
    location: 'Student/ViewStudent',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.STUDENT_MANAGE_OWN_ACCOUNT]
  },
  {
    path: ROUTES.ATTENDANCE_MANAGEMENT,
    name: 'attendance_management',
    icon: AttendanceIcon,
    component: Attendance,
    location: 'Attendance/index',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_CLASSROOM,
      ROLES.MANAGE_OWNED_CLASSROOM,
      ROLES.VIEW_OWNED_CLASSROOM,
      ROLES.VIEW_ATTENDANCE
    ]
  },
  {
    path: ROUTES.ATTENDANCE,
    name: 'attendance',
    icon: AttendanceIcon,
    component: Attendance,
    location: 'Attendance/index',
    private: true,
    sidebarVisible: true,
    roles: ROLES.STUDENT_MANAGE_OWN_ACCOUNT
  },
  {
    path: ROUTES.TRANSFER,
    name: 'transfer',
    icon: TransferIcon,
    component: Transfer,
    location: 'Transfer/Transfer',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.TRANSFER_STUDENT, ROLES.TRANSFER_OWNED_STUDENT]
  },
  {
    path: ROUTES.GRADUATION,
    name: 'graduation',
    icon: GraduationIcon,
    component: Graduation,
    location: 'Graduation/Graduation',
    private: true,
    sidebarVisible: true,
    roles: [ROLES.GRADUATE_STUDENT, ROLES.GRADUATE_OWNED_STUDENT]
  },
  {
    collapse: true,
    name: 'scholarshipManagement',
    state: 'scholarshipManagement',
    icon: ScholarshipIcon,
    private: true,
    sidebarVisible: true,
    roles: ROLES.MANAGE_SCHOLARSHIP,
    views: [
      {
        path: ROUTES.SCHOLARSHIP,
        name: 'scholarship',
        icon: StudentIcon,
        component: Scholarship,
        location: 'Scholarship/Scholarship',
        private: true,
        sidebarVisible: true,
        roles: ROLES.MANAGE_SCHOLARSHIP
      },
      {
        path: ROUTES.SCHOLARSHIP_REMITTANCE,
        name: 'scholarshipRemittance',
        icon: RemittanceIcon,
        component: Remittance,
        location: 'ScholarshipRemittance/Remittance',
        private: true,
        sidebarVisible: true,
        roles: ROLES.MANAGE_SCHOLARSHIP
      },
      {
        path: ROUTES.SCHOLARSHIP_FUNDING,
        name: 'scholarshipFunding',
        icon: FundingIcon,
        component: Funding,
        location: 'ScholarshipFunding/Funding',
        private: true,
        sidebarVisible: true,
        roles: ROLES.MANAGE_SCHOLARSHIP
      }
    ]
  },
  {
    title: 'stsData',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_SCHOOL,
      ROLES.VIEW_SCHOOL,
      ROLES.VIEW_CLASSROOM_REPORT,
      ROLES.VIEW_SCHOOL_REPORT
    ]
  },
  {
    path: ROUTES.REPORT,
    name: 'report',
    icon: ReportIcon,
    component: Report,
    location: 'Report/Report',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_SCHOOL,
      ROLES.VIEW_SCHOOL,
      ROLES.VIEW_CLASSROOM_REPORT,
      ROLES.VIEW_SCHOOL_REPORT
    ]
  },
  {
    path: ROUTES.MANAGEMENT_REPORT,
    name: 'managementReport',
    icon: DataQueryIcon,
    component: ManagementReport,
    location: 'DataQuery/ManagementReport',
    private: true,
    sidebarVisible: true,
    roles: [
      ROLES.MANAGE_SCHOOL,
      ROLES.VIEW_SCHOOL,
      ROLES.VIEW_CLASSROOM_REPORT,
      ROLES.VIEW_SCHOOL_REPORT
    ],
    hideFromGroups: [
      GROUPS.TEACHER
    ]
  },
  {
    title: 'administration',
    private: true,
    sidebarVisible: true,
    roles: ROLES.MANAGE_USER
  },
  {
    path: ROUTES.USER,
    name: 'userManagement',
    icon: KeyIcon,
    component: UserManagement,
    location: 'UserManagement/UserManagement',
    private: true,
    sidebarVisible: true,
    roles: ROLES.MANAGE_USER
  },
  {
    path: ROUTES.USER_NEW,
    name: 'userManagement',
    component: CreateEditUser,
    location: 'UserManagement/CreateEditUser',
    private: true,
    sidebarVisible: false,
    roles: ROLES.MANAGE_USER
  },
  {
    path: ROUTES.USER_EDIT,
    name: 'userManagement',
    component: CreateEditUser,
    location: 'UserManagement/CreateEditUser',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_USER, ROLES.MANAGE_OWN_PROFILE]
  },
  // {
  //   path: ROUTES.STUDENT_ACCOUNT,
  //   name: 'studentAccountManagement',
  //   icon: SupervisedUserCircleIcon,
  //   component: studentAccountManagement,
  //   location: 'StudentAccountManagement/StudentAccountManagement',
  //   private: true,
  //   sidebarVisible: true,
  //   roles: [ROLES.MANAGE_STUDENT_ACCOUNT]
  // },
  {
    path: ROUTES.STUDENT_ACCOUNT_EDIT,
    name: 'studentAccountManagement',
    component: CreateEditStudentAccount,
    location: 'StudentAccountManagement/CreateEditStudentAccount',
    private: true,
    sidebarVisible: false,
    roles: [ROLES.MANAGE_STUDENT_ACCOUNT, ROLES.MANAGE_OWN_STUDENT_ACCOUNT, ROLES.STUDENT_MANAGE_OWN_ACCOUNT]
  },
  {
    path: ROUTES.ERRORS,
    name: 'errors',
    component: ErrorView,
    location: 'ErrorView/ErrorView',
    private: false,
    sidebarVisible: false
  },
  {
    path: ROUTES.ERROR_PAGE,
    name: 'errorPage',
    component: ErrorPage,
    location: 'ErrorView/ErrorPage',
    private: false,
    sidebarVisible: false
  }
];

export default Routes;
