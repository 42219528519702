export const GET_STUDENT_LIST_REQUEST = 'GET_STUDENT_LIST_REQUEST';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_FAIL = 'GET_STUDENT_LIST_FAIL';

export const GET_STUDENT_DETAIL_REQUEST = 'GET_STUDENT_DETAIL_REQUEST';
export const GET_STUDENT_DETAIL_SUCCESS = 'GET_STUDENT_DETAIL_SUCCESS';
export const GET_STUDENT_DETAIL_FAIL = 'GET_STUDENT_DETAIL_FAIL';

export const EDIT_STUDENT_REQUEST = 'EDIT_STUDENT_REQUEST';
export const EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAIL = 'EDIT_STUDENT_FAIL';

export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAIL = 'DELETE_STUDENT_FAIL';

export const CHANGE_CLASSROOM_REQUEST = 'CHANGE_CLASSROOM_REQUEST';
export const CHANGE_CLASSROOM_SUCCESS = 'CHANGE_CLASSROOM_SUCCESS';
export const CHANGE_CLASSROOM_FAIL = 'CHANGE_CLASSROOM_FAIL';

export const STUDENT_DROP_OUT_REQUEST = 'STUDENT_DROP_OUT_REQUEST';
export const STUDENT_DROP_OUT_SUCCESS = 'STUDENT_DROP_OUT_SUCCESS';
export const STUDENT_DROP_OUT_FAIL = 'STUDENT_DROP_OUT_FAIL';

export const AUTO_FILL_FILTER_BY_GAZETTEER_REQUEST = 'AUTO_FILL_FILTER_BY_GAZETTEER_REQUEST';
export const AUTO_FILL_FILTER_BY_GAZETTEER_SUCCESS = 'AUTO_FILL_FILTER_BY_GAZETTEER_SUCCESS';
export const AUTO_FILL_FILTER_BY_GAZETTEER_FAIL = 'AUTO_FILL_FILTER_BY_GAZETTEER_FAIL';
