import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import LoadablePaperContent from 'components/common/Paper/LoadablePaperContent';
import Card from 'components/theme/Card/Card';
import CardHeader from 'components/theme/Card/CardHeader';
import CardIcon from 'components/theme/Card/CardIcon';
import GraduationIcon from '@material-ui/icons/CardMembership';
import CardBody from 'components/theme/Card/CardBody';
import GridContainer from 'components/theme/Grid/GridContainer';
import GridItem from 'components/theme/Grid/GridItem';
import Select from 'components/common/Select/Select';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import ThemeButton from 'components/theme/CustomButtons/Button';
import LoadablePaper from 'components/common/Paper/LoadablePaper';
import {
  getAcademicYears,
  getClassroomListForFilter,
  getSchoolList,
  getStudentOptions
} from 'redux/actions';
import * as moment from 'moment';
import settings from 'settings';
import * as STATUS from 'variables/academicYearStatus';
import { groups as GROUPS } from 'variables/user';
import { getSchoolName } from 'utilities/helpers';

export default function GraduationFilter (props) {
  const {
    classes,
    isLoadingAcademic,
    isLoadingStudentOption,
    academicYears,
    academicYear,
    grade,
    suffix,
    filterResult,
    onChangeHandler,
    errorAcademicYear,
    errorGrade,
    errorSuffix,
    handleFilterGraduation,
    school,
    setSchool,
    errorSchool
  } = props;
  const dispatch = useDispatch();
  const localize = useSelector((state) => state.localize);
  const translate = getTranslate(localize);

  const schoolList = useSelector(state => state.school.schoolList);
  const classroomList = useSelector(state => state.classroom.classroomListForFilter);
  const allGrades = useSelector(state => state.studentOption.grade);
  const [academicYearChoices, setAcademicYearChoices] = useState([]);
  const [schoolChoices, setSchoolChoices] = useState([]);
  const [gradeChoices, setGradeChoices] = useState([]);
  const [suffixChoices, setSuffixChoices] = useState([]);
  const authProfile = useSelector(state => state.auth.profile);
  const managementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);

  // fetch academic years
  useEffect(() => {
    dispatch(getAcademicYears());
    dispatch(getStudentOptions());
  }, [dispatch]);

  // set academic year choices
  useEffect(() => {
    if (academicYears.length > 0) {
      const choice = academicYears
        .filter(a => a.status === STATUS.POST)
        .map(academic => {
          const academicStartDate = moment(academic.start_date, settings.dateFormat);
          const academicEndDate = moment(academic.end_date, settings.dateFormat);
          const academicStatus = translate(`academicYear.status.${academic.status}`).toUpperCase();
          const studyYear = (academic.name || `${academicStartDate.year()}-${academicEndDate.year()}`) + ` (${academicStatus})`;
          return {
            value: academic.id,
            label: studyYear
          };
        });
      setAcademicYearChoices(choice);
    }
  }, [academicYears, translate]);

  // fetch schools by academic year
  useEffect(() => {
    if (academicYear) {
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      const isProvince = authProfile && (authProfile.groups[0] === GROUPS.PROVINCIAL_OFFICER);
      const isDistrict = authProfile && (authProfile.groups[0] === GROUPS.DISTRICT_OFFICER);

      const params = isManagementOrTeacher
        ? {
          academic_year_id: academicYear,
          school_id: authProfile.school.id
        }
        : {
          academic_year: academicYear,
          orderBy: getActiveLanguage(localize).code === 'en' ? 'name_en' : 'name',
          order: 'ASC',
          limit: 9999
        };
      if (isProvince || isDistrict) {
        params.gazetteer = authProfile.province.code;
        if (isDistrict) {
          params.gazetteer = authProfile.district.code;
        }
      }
      dispatch(getSchoolList(params, isManagementOrTeacher));
    }
    // eslint-disable-next-line
  }, [dispatch, academicYear]);

  // set school choices
  useEffect(() => {
    if (academicYear && schoolList.length > 0) {
      const choice = schoolList.map(school => {
        return {
          value: school.id,
          label: getSchoolName(getActiveLanguage(localize).code, translate, school)
        };
      });
      setSchoolChoices(choice);
      const isManagementOrTeacher = authProfile && (authProfile.groups[0] === GROUPS.SCHOOL_MANAGEMENT || authProfile.groups[0] === GROUPS.TEACHER);
      if (isManagementOrTeacher) {
        setSchool(schoolList[0].id);
      }
    }
  }, [academicYear, schoolList, localize, setSchool, authProfile, translate]);

  // fetch classes by school
  useEffect(() => {
    if (school) {
      const isTeacher = authProfile && authProfile.groups[0] === GROUPS.TEACHER;
      const param = {
        school: school
      };
      if (isTeacher) {
        param.teacher_staff_id = authProfile.userable_id;
      }
      dispatch(getClassroomListForFilter(param));
    }
  }, [dispatch, school, authProfile]);

  // set grade choices
  useEffect(() => {
    if (school && classroomList.length > 0) {
      const choices = [];
      const classrooms = classroomList.filter(classroom => classroom.grade !== null);
      classrooms.forEach(classroom => {
        if (choices.filter(c => c.value === classroom.grade).length === 0) {
          const grade = allGrades.find(g => g.number === classroom.grade);
          choices.push({
            value: classroom.grade,
            label: getActiveLanguage(localize).code === 'en' ? grade.name_en : grade.name
          });
        }
      });
      setGradeChoices(choices);
    }
  }, [school, classroomList, allGrades, localize]);

  // set suffix choices
  useEffect(() => {
    if (grade !== '' && classroomList.length > 0) {
      const choices = classroomList
        .filter(classroom => classroom.grade === grade)
        .sort((a, b) => (a.suffix > b.suffix) ? 1 : -1)
        .map(classroom => ({
          value: classroom.suffix,
          label: classroom.suffix
        }));
      setSuffixChoices(choices);
    }
  }, [grade, classroomList]);

  return (
    <LoadablePaper
      rendered
      loading={isLoadingAcademic && isLoadingStudentOption}
    >
      <LoadablePaperContent>
        <Card>
          <CardHeader color="gold" icon>
            <CardIcon color="gold">
              <GraduationIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('graduation.pageTitle')}</h4>
          </CardHeader>
          <CardBody>
            <form
              className={classes.gridContainerStyle}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('enrollment.academicYear')}
                    placeholder={translate('enrollment.placeholder.academicYear')}
                    value={academicYear}
                    name="academicYear"
                    onChange={e => onChangeHandler(e, 'academicYear')}
                    choices={academicYearChoices}
                    disabled={academicYears.length === 0}
                    require
                    error={errorAcademicYear}
                    helperText={errorAcademicYear ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('classroom.school')}
                    placeholder={translate('classroom.placeholder.school')}
                    value={school}
                    name="school"
                    onChange={e => onChangeHandler(e, 'school')}
                    choices={schoolChoices}
                    disabled={schoolChoices.length === 0 || managementOrTeacher}
                    require
                    error={errorSchool}
                    helperText={errorSchool ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Select
                    label={translate('enrollment.grade')}
                    placeholder={translate('enrollment.placeholder.grade')}
                    value={grade}
                    name="grade"
                    onChange={e => onChangeHandler(e, 'grade')}
                    choices={gradeChoices}
                    disabled={gradeChoices.length === 0}
                    require
                    error={errorGrade}
                    helperText={errorGrade ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={3}
                >
                  <Select
                    label={translate('common.suffix')}
                    placeholder={translate('graduation.placeholder.suffix')}
                    value={suffix}
                    name="suffix"
                    onChange={e => onChangeHandler(e, 'suffix')}
                    choices={suffixChoices}
                    disabled={suffixChoices.length === 0}
                    require
                    error={errorSuffix}
                    helperText={errorSuffix ? translate('common.error.require') : ''}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={6}
                >
                  <Select
                    label={translate('graduation.result')}
                    placeholder={translate('graduation.placeholder.result')}
                    value={filterResult}
                    name="filterResult"
                    onChange={e => onChangeHandler(e, 'filterResult')}
                    choices={[
                      { label: translate('common.all'), value: 'all' },
                      { label: translate('graduation.resultChoice.passed'), value: 1 },
                      { label: translate('graduation.resultChoice.failed'), value: 0 }
                    ]}
                    helperText={''}
                  />
                </GridItem>
              </GridContainer>

              <div
                className={classes.allButton}
              >
                <ThemeButton
                  color="primary"
                  type="submit"
                  onClick={handleFilterGraduation}
                >
                  {translate('common.button.search')}
                </ThemeButton>
              </div>
            </form>
          </CardBody>
        </Card>
      </LoadablePaperContent>
    </LoadablePaper>
  );
}

GraduationFilter.propTypes = {
  classes: PropTypes.instanceOf(Object),
  isLoadingAcademic: PropTypes.bool,
  isLoadingStudentOption: PropTypes.bool,
  academicYears: PropTypes.array,
  academicYear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  school: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  grade: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suffix: PropTypes.string,
  filterResult: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChangeHandler: PropTypes.func,
  errorAcademicYear: PropTypes.bool,
  errorSchool: PropTypes.bool,
  errorGrade: PropTypes.bool,
  errorSuffix: PropTypes.bool,
  handleFilterGraduation: PropTypes.func,
  setSchool: PropTypes.func
};
