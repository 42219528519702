import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification, showSuccessNotification
} from 'redux/actions/notification';
import history from 'utilities/history';
import * as ROUTES from 'variables/routeNames';
import { getSubjects } from 'redux/actions/score';

function getClassroomListRequested () {
  return {
    type: actionTypes.GET_CLASSROOM_LIST_REQUEST
  };
}

function getClassroomListSuccess (classroomList, classroomFilter) {
  return {
    type: actionTypes.GET_CLASSROOM_LIST_SUCCESS,
    classroomList,
    classroomFilter
  };
}

function getClassroomListFail () {
  return {
    type: actionTypes.GET_CLASSROOM_LIST_FAIL
  };
}

function getClassroomListForFilterSuccess (classroomListForFilter) {
  return {
    type: actionTypes.GET_CLASSROOM_LIST_FOR_FILTER_SUCCESS,
    classroomListForFilter
  };
}

function getClassroomRequested () {
  return {
    type: actionTypes.GET_CLASSROOM_REQUEST
  };
}

function getClassroomSuccess (classroom) {
  return {
    type: actionTypes.GET_CLASSROOM_SUCCESS,
    classroom
  };
}

function getClassroomFail () {
  return {
    type: actionTypes.GET_CLASSROOM_FAIL
  };
}

function createEditClassroomRequested () {
  return {
    type: actionTypes.CREATE_EDIT_CLASSROOM_REQUEST
  };
}

function createEditClassroomSuccess (classroomList) {
  return {
    type: actionTypes.CREATE_EDIT_CLASSROOM_SUCCESS,
    classroomList
  };
}

function createEditClassroomFail () {
  return {
    type: actionTypes.CREATE_EDIT_CLASSROOM_FAIL
  };
}

function deleteClassroomRequested () {
  return {
    type: actionTypes.GET_CLASSROOM_REQUEST
  };
}

function deleteClassroomSuccess (classroom) {
  return {
    type: actionTypes.GET_CLASSROOM_SUCCESS,
    classroom
  };
}

function deleteClassroomFail () {
  return {
    type: actionTypes.GET_CLASSROOM_FAIL
  };
}

function getSubjectsRequested () {
  return {
    type: actionTypes.GET_CLASSROOM_SUBJECTS_REQUEST
  };
}

function getSubjectsSuccess (subjects) {
  return {
    type: actionTypes.GET_CLASSROOM_SUBJECTS_SUCCESS,
    subjects
  };
}

function getSubjectsFail () {
  return {
    type: actionTypes.GET_CLASSROOM_SUBJECTS_FAIL
  };
}

export const getClassroomList = (rawParams) => {
  return (dispatch) => {
    const params = {
      academicYear: rawParams.academicYear,
      school: rawParams.school,
      page: rawParams.page,
      limit: rawParams.limit,
      order: rawParams.order,
      orderBy: rawParams.orderBy
    };
    dispatch(getClassroomListRequested());
    axios.get('/classrooms', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta?.total;
          dispatch(getClassroomListSuccess(data, rawParams));
        }
      )
      .catch(e => {
        dispatch(getClassroomListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getClassroomListForFilter = (params) => {
  return (dispatch) => {
    axios.get('/classrooms', { params })
      .then(
        res => {
          const data = res.data.data;
          dispatch(getClassroomListForFilterSuccess(data));
        }
      )
      .catch(e => {
        dispatch(showErrorNotification('error.gettingClasses'));
      });
  };
};

export const getClassroom = (id) => {
  return (dispatch) => {
    dispatch(getClassroomRequested());
    axios.get(`/classrooms/${id}`)
      .then(
        res => {
          const data = res.data.data;
          dispatch(getClassroomSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getClassroomFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const createEditClassroom = (data, id = false, successfulMessage) => {
  return (dispatch) => {
    dispatch(createEditClassroomRequested());
    if (id) {
      let params;
      let deactivated;
      if (data.params) {
        params = JSON.parse(JSON.stringify(data.params));
        delete data.params;
      } else {
        params = data;
      }
      if (data.deactivated) {
        deactivated = data.deactivated;
        delete data.deactivated;
      }
      axios.put(`/classrooms/${id}`, data)
        .then(
          res => {
            dispatch(createEditClassroomSuccess(data));
            dispatch(getSubjects(id));
            dispatch(showSuccessNotification(successfulMessage));
            if (deactivated) {
              dispatch(getClassroomList(params));
            } else {
              dispatch(getClassroom(id));
            }
          }
        )
        .catch(e => {
          dispatch(createEditClassroomFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    } else {
      axios.post('/classrooms', data)
        .then(
          res => {
            dispatch(createEditClassroomSuccess(data));
            dispatch(showSuccessNotification(successfulMessage));
            history.push(ROUTES.CLASSROOM);
          }
        )
        .catch(e => {
          dispatch(createEditClassroomFail(e));
          dispatch(showErrorNotification(e.response.data.message));
        });
    }
  };
};

export const deleteClassroom = (id, params, successfulMessage) => {
  return (dispatch) => {
    dispatch(deleteClassroomRequested());
    axios.delete(`/classrooms/${id}`)
      .then(
        res => {
          dispatch(deleteClassroomSuccess());
          dispatch(showSuccessNotification(successfulMessage));
          dispatch(getClassroomList(params));
        }
      )
      .catch(e => {
        dispatch(deleteClassroomFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const resetClassroom = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_CLASSROOM });
  };
};

export const getClassroomSubjects = (params) => {
  return (dispatch) => {
    dispatch(getSubjectsRequested());
    axios.get('/get-subjects', { params })
      .then(
        res => {
          const subjects = res.data;
          dispatch(getSubjectsSuccess(subjects));
        }
      )
      .catch(e => {
        dispatch(getSubjectsFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const checkImportStatus = (params) => {
  return () => {
    return axios.get('/check-import-status', { params });
  };
};
