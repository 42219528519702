import defaultFormStyle from 'assets/sts/jss/components/defaultFormStyle';
import {
  formBorderColor,
  formBackgroundColor,
  stsBlueColor
} from 'assets/sts/jss/sts-react';

import {
  goldColor,
  infoColor,
  whiteColor
} from 'assets/theme/jss/material-dashboard-pro-react';

const createEditStudentStyle = (theme) => ({
  ...defaultFormStyle(theme),
  boxGrid: {
    margin: '10px 0 15px 0',
    paddingTop: '15px !important',
    borderWidth: '1px',
    borderColor: formBorderColor,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    borderRadius: '3px',
    backgroundColor: formBackgroundColor
  },
  dialogContentStuAcc: {
    overflow: 'hidden'
  },
  dialogContent: {
    overflow: 'auto'
  },
  normalSaveClose: {
    float: 'right',
    display: 'inline-block'
  },
  buttonDefault: {
    color: 'white',
    backgroundColor: '#616161',
    marginRight: 20
  },
  dialogAction: {
    display: 'unset',
    padding: '0 24px',
    marginBottom: 10
  },
  dialogHelp: {
    minWidth: '800px'
  },
  avatar: {
    background: 'none'
  },
  avatarIcon: {
    width: 46,
    height: 46,
    color: goldColor[0]
  },
  firstGrid: {
    width: 55,
    float: 'left',
    display: 'inline-block'
  },
  secondGrid: {
    float: 'left'
  },
  dialogHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: window.safari ? 'Roboto,sans-serif' : 'Roboto,Siemreap,sans-serif',
    fontWeight: 400,
    margin: 0
  },
  dialogDescription: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'normal',
    margin: 0
  },
  summaryLabel: {
    display: 'inline-block',
    marginRight: 10,
    width: '95px',
    textAlign: 'right'
  },
  studentNotFound: {
    fontSize: '1.5rem',
    textAlign: 'center'
  },
  dialogContentLoading: {
    minWidth: '500px',
    minHeight: '500px',
    overflow: 'hidden !important',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  loading: {
    position: 'unset',
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  loadingBoxClass: {
    position: 'unset'
  },
  checkBoxPass: {
    color: `${stsBlueColor} !important`
  },
  infoMessage: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    padding: '5px',
    borderRadius: '3px'
  }
});

export default createEditStudentStyle;
