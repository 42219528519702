import * as actionTypes from 'redux/actionTypes';
import axios from 'utilities/httpService';
import {
  showErrorNotification,
  showSuccessNotification
} from 'redux/actions/notification';

function getGraduationListRequested () {
  return {
    type: actionTypes.GET_GRADUATION_LIST_REQUEST
  };
}

function getGraduationListByStatusRequested () {
  return {
    type: actionTypes.GET_GRADUATION_LIST_BY_STATUS_REQUEST
  };
}

function getGraduationListSuccess (graduationList, graduationFilter) {
  return {
    type: actionTypes.GET_GRADUATION_LIST_SUCCESS,
    graduationList,
    graduationFilter
  };
}

function getGraduationListByStatusSuccess (graduationListByStatus) {
  return {
    type: actionTypes.GET_GRADUATION_LIST_BY_STATUS_SUCCESS,
    graduationListByStatus
  };
}

function getGraduationListFail () {
  return {
    type: actionTypes.GET_GRADUATION_LIST_FAIL
  };
}

const changeResultRequest = () => {
  return {
    type: actionTypes.CHANGE_RESULT_REQUEST
  };
};

const changeResultSuccess = () => {
  return {
    type: actionTypes.CHANGE_RESULT_SUCCESS
  };
};

const changeResultFail = () => {
  return {
    type: actionTypes.CHANGE_RESULT_FAIL
  };
};

const promoteGraduationRequest = () => {
  return {
    type: actionTypes.PROMOTE_GRADUATION_REQUEST
  };
};

const promoteGraduationSuccess = () => {
  return {
    type: actionTypes.PROMOTE_GRADUATION_SUCCESS
  };
};

const promoteGraduationFail = () => {
  return {
    type: actionTypes.PROMOTE_GRADUATION_FAIL
  };
};

function getPromoteToSchoolListRequested () {
  return {
    type: actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_REQUEST
  };
}

function getPromoteToSchoolListSuccess (promoteToSchoolList) {
  return {
    type: actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_SUCCESS,
    promoteToSchoolList
  };
}

function getPromoteToSchoolListFail () {
  return {
    type: actionTypes.GET_PROMOTE_TO_SCHOOL_LIST_FAIL
  };
}

/**
 * @param rawParams The array list of filter parameters
 * @param isByStatus The flag to use for promotion/repetition action
 * @returns {function(...[*]=)}
 */
export const getGraduationList = (rawParams, isByStatus = false) => {
  return (dispatch) => {
    const params = {
      academic_year_id: rawParams.academicYear,
      emis_school_code: rawParams.emisSchoolCode,
      grade: rawParams.grade,
      result: rawParams.filterResult !== 'all' ? rawParams.filterResult : undefined,
      page: rawParams.page,
      limit: rawParams.limit
    };

    if (rawParams.suffix) {
      params.suffix = rawParams.suffix;
    }

    if (isByStatus) {
      dispatch(getGraduationListByStatusRequested());
    } else {
      dispatch(getGraduationListRequested());
    }
    axios.get('/graduations', { params })
      .then(
        res => {
          const data = {
            data: res.data.data
          };
          data.total = res.data.total;
          if (isByStatus) {
            dispatch(getGraduationListByStatusSuccess(data));
          } else {
            dispatch(getGraduationListSuccess(data, rawParams));
          }
        }
      )
      .catch(e => {
        dispatch(getGraduationListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const changeResult = (id, data, successfulMessage) => {
  return (dispatch, getState) => {
    const formData = {
      result: data.result,
      reasonForChanged: data.reasonForChanged,
      otherReasonForChanged: data.otherReasonForChanged
    };
    dispatch(changeResultRequest());
    axios.put(`/enrollments/change-result/${id}`, formData)
      .then(
        res => {
          dispatch(changeResultSuccess());
          dispatch(showSuccessNotification(successfulMessage));

          const { graduationFilter } = getState().graduation;
          dispatch(getGraduationList(graduationFilter));
        }
      )
      .catch(e => {
        dispatch(changeResultFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const promoteGraduation = (data, successfulMessage) => {
  return (dispatch, getState) => {
    const formData = {
      current_grade: data.grade,
      enrollments: data.selection,
      academic_year_id: data.promoteAcademicYear,
      emis_school_code: data.promoteSchoolCode,
      grade: data.promoteGrade,
      entry_status: data.graduationAction,
      enrolled_date: data.enrollmentDate,
      enrolled_by: data.enrollmentBy
    };
    dispatch(promoteGraduationRequest());
    axios.post('/enrollments/graduation', formData)
      .then(
        res => {
          dispatch(promoteGraduationSuccess());
          dispatch(showSuccessNotification(successfulMessage));

          const { graduationFilter } = getState().graduation;
          dispatch(getGraduationList(graduationFilter));
        }
      )
      .catch(e => {
        dispatch(promoteGraduationFail());
        dispatch(showErrorNotification(e.message));
      });
  };
};

export const getPromoteToSchoolList = (params) => {
  return (dispatch) => {
    dispatch(getPromoteToSchoolListRequested());
    axios.get('/schools', { params })
      .then(
        res => {
          const data = res.data.data;
          data.total = res.data.meta.total;
          dispatch(getPromoteToSchoolListSuccess(data));
        }
      )
      .catch(e => {
        dispatch(getPromoteToSchoolListFail(e));
        dispatch(showErrorNotification(e.message));
      });
  };
};
