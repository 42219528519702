import * as actionTypes from 'redux/actionTypes';

export const initialState = {
  isLoading: false,
  schoolList: [],
  rawSchoolList: [],
  school: undefined,
  schoolFilter: [],
  isSearching: false
};

const getSchoolListRequested = (state) => ({
  ...state,
  isLoading: true
});

const getSchoolListSuccess = (state, action) => ({
  ...state,
  schoolList: action.schoolList,
  schoolFilter: action.filter,
  isLoading: false
});

const getSchoolListFail = (state) => ({
  ...state,
  isLoading: false
});

const getSchoolRequested = (state) => ({
  ...state,
  school: undefined,
  isLoading: true
});

const getSchoolSuccess = (state, action) => ({
  ...state,
  school: action.school,
  isLoading: false
});

const getSchoolFail = (state) => ({
  ...state,
  isLoading: false
});

const createEditSchoolRequested = (state) => ({
  ...state,
  isLoading: true
});

const createEditSchoolSuccess = (state) => ({
  ...state,
  isLoading: false
});

const createEditSchoolFail = (state) => ({
  ...state,
  isLoading: false
});

const deleteSchoolRequested = (state) => ({
  ...state,
  isLoading: true
});

const deleteSchoolSuccess = (state) => ({
  ...state,
  isLoading: false
});

const deleteSchoolFail = (state) => ({
  ...state,
  isLoading: false
});

const getRawSchoolListRequested = (state) => ({
  ...state,
  isLoading: true
});

const getRawSchoolListSuccess = (state, action) => ({
  ...state,
  rawSchoolList: action.rawSchoolList,
  isLoading: false
});

const getRawSchoolListFail = (state) => ({
  ...state,
  isLoading: false
});

const getRawSchoolByEmisCodeRequested = (state) => ({
  ...state,
  isSearching: true
});

const getRawSchoolByEmisCodeSuccess = (state, action) => ({
  ...state,
  school: action.school,
  isSearching: false
});

const getRawSchoolByEmisCodeFail = (state) => ({
  ...state,
  isSearching: false
});

const clearRawSchoolListSuccess = (state) => ({
  ...state,
  rawSchoolList: []
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHOOL_LIST_REQUEST:
      return getSchoolListRequested(state);
    case actionTypes.GET_SCHOOL_LIST_SUCCESS:
      return getSchoolListSuccess(state, action);
    case actionTypes.GET_SCHOOL_LIST_FAIL:
      return getSchoolListFail(state);

    case actionTypes.GET_SCHOOL_REQUEST:
      return getSchoolRequested(state);
    case actionTypes.GET_SCHOOL_SUCCESS:
      return getSchoolSuccess(state, action);
    case actionTypes.GET_SCHOOL_FAIL:
      return getSchoolFail(state);

    case actionTypes.CREATE_EDIT_SCHOOL_REQUEST:
      return createEditSchoolRequested(state);
    case actionTypes.CREATE_EDIT_SCHOOL_SUCCESS:
      return createEditSchoolSuccess(state);
    case actionTypes.CREATE_EDIT_SCHOOL_FAIL:
      return createEditSchoolFail(state);

    case actionTypes.DELETE_SCHOOL_REQUEST:
      return deleteSchoolRequested(state);
    case actionTypes.DELETE_SCHOOL_SUCCESS:
      return deleteSchoolSuccess(state);
    case actionTypes.DELETE_SCHOOL_FAIL:
      return deleteSchoolFail(state);

    case actionTypes.RESET_SCHOOL:
      return initialState;
    case actionTypes.RESET_RAW_SCHOOL_LIST:
      return clearRawSchoolListSuccess(state);

    case actionTypes.GET_RAW_SCHOOL_LIST_REQUEST:
      return getRawSchoolListRequested(state);
    case actionTypes.GET_RAW_SCHOOL_LIST_SUCCESS:
      return getRawSchoolListSuccess(state, action);
    case actionTypes.GET_RAW_SCHOOL_LIST_FAIL:
      return getRawSchoolListFail(state);

    case actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_REQUEST:
      return getRawSchoolByEmisCodeRequested(state);
    case actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_SUCCESS:
      return getRawSchoolByEmisCodeSuccess(state, action);
    case actionTypes.GET_RAW_SCHOOL_BY_EMIS_CODE_FAIL:
      return getRawSchoolByEmisCodeFail(state);

    default:
      return state;
  }
};

export default reducer;
